import { z } from "zod";

/**
 * Desired destination is wherever this meeting is being held.
 */
export const MeetingDestination = z.object({
  type: z.literal("meeting"),
  /**
   * ID of the meeting link that corresponds to the meeting.
   *
   * We don't actually have a "meeting" type, and currently manage meeting room locations
   * via a meeting link.
   */
  meetingLinkId: z.string(),
  /**
   * Meeting link code.
   *
   * This is not technically necessary to identify a meeting, as the meeting link ID is unique.
   * We currently require this to stay on the safe side (see `AccessLinkId.ts`), but it could
   * be relaxed (for appropriate code paths) if we can make sure that the security concerns are
   * still met.
   *
   * For example, if a server already has a meeting link ID, it shouldn't need a code.
   * However, meeting destinations derived from access links should have a code.
   */
  code: z.string(),
});
export type MeetingDestination = z.infer<typeof MeetingDestination>;
