import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { ClientCalendarEvent } from "../../../../shared/Models/calendar/client/ClientCalendarEvent.js";
import { ClientPersonalCalendarEvent } from "../../../../shared/Models/calendar/client/ClientPersonalCalendarEvent.js";
import { CalendarEventSummary } from "../../../../shared/Models/calendar/external/CalendarEvent.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface AllRoomEventsPayload {
  roomId: number;
  events: ClientCalendarEvent[];
}

export interface AllTeleRoomEventsPayload {
  teleRoomId: string;
  events: ClientPersonalCalendarEvent[];
}
export interface DeleteQuickBookPayload {
  roomId: number;
  eventId: string;
}

export interface RenameQuickBookPayload {
  roomId: number;
  eventId: string;
  newName: string;
}

export interface RequestRoomEventsPayload {
  roomId: number;
  subscribe: boolean;
}

export interface RequestTeleRoomEventsPayload {
  teleRoomId: string;
  subscribe: boolean;
}

export interface CurrentEventsByRoom {
  roomId: number;
  currentEvents: CalendarEventSummary[];
  nextEvent?: CalendarEventSummary;
}

const slice = createSlice({
  name: "calendarEvent",
  initialState: {
    eventsByRoom: {} as { [roomId: number]: ClientCalendarEvent[] },
    eventsByTeleRoom: {} as { [teleRoomId: string]: ClientPersonalCalendarEvent[] },

    // These event summaries are a small amount of information about the current and next event, sent to
    // everyone in a room (unlike eventsByRoom, which is more detailed, covers 48 hours, and is sent and
    // updated only when you open a calendar)
    currentEventsByRoom: {} as {
      [roomId: number]: CurrentEventsByRoom;
    },

    selectedEventId: undefined as string | undefined,
    activeRequest: false,
  },
  reducers: {
    requestRoomEvents: (state, action: PayloadAction<RequestRoomEventsPayload>) => {},
    unsubscribeFromRoomEvents: (state, action: PayloadAction<number>) => {},

    requestTeleRoomEvents: (state, action: PayloadAction<RequestTeleRoomEventsPayload>) => {},
    unsubscribeFromTeleRoomEvents: (state, action: PayloadAction<string>) => {},

    quickBookRoom: (state, action: PayloadAction<number>) => {},
    deleteQuickBook: (state, action: PayloadAction<DeleteQuickBookPayload>) => {},
    renameQuickBook: (state, action: PayloadAction<RenameQuickBookPayload>) => {},

    selectEvent: (state, action: PayloadAction<string>) => {
      state.selectedEventId = action.payload;
    },
    clearSelectedEvent: (state) => {
      state.selectedEventId = undefined;
    },

    setActiveRequest: (state, action: PayloadAction<boolean>) => {
      state.activeRequest = action.payload;
    },
    setAllRoomEvents: (state, action: PayloadAction<AllRoomEventsPayload>) => {
      const { roomId, events } = action.payload;
      state.eventsByRoom[roomId] = events;
      if (state.selectedEventId && !events.some((e) => e.id === state.selectedEventId)) {
        state.selectedEventId = undefined;
      }
    },
    setAllTeleRoomEvents: (state, action: PayloadAction<AllTeleRoomEventsPayload>) => {
      const { teleRoomId, events } = action.payload;
      state.eventsByTeleRoom[teleRoomId] = events;
      if (state.selectedEventId && !events.some((e) => e.id === state.selectedEventId)) {
        state.selectedEventId = undefined;
      }
    },
    upsertRoomEvent: (
      state,
      action: PayloadAction<{ roomId: number; event: ClientCalendarEvent }>
    ) => {
      const { roomId, event } = action.payload;
      const events = state.eventsByRoom[roomId];
      if (events) {
        const i = events.findIndex((ev) => ev.id === event.id);
        if (i >= 0) {
          events[i] = event;
        } else {
          events.push(event);
        }
      } else {
        state.eventsByRoom[roomId] = [event];
      }
    },
    removeRoomEvent: (state, action: PayloadAction<{ roomId: number; eventId: string }>) => {
      const { roomId, eventId } = action.payload;
      const events = state.eventsByRoom[roomId];
      if (events) {
        const i = events.findIndex((ev) => ev.id === eventId);
        if (i >= 0) {
          events.splice(i, 1);
        }
      }
      if (state.selectedEventId === eventId) {
        state.selectedEventId = undefined;
      }
    },

    setCurrentEventsByRoom: (state, action: PayloadAction<CurrentEventsByRoom>) => {
      const eventsByRoom = action.payload;
      state.currentEventsByRoom[eventsByRoom.roomId] = eventsByRoom;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.calendar.calendarEvents;
export const selectors = {
  selectEventsByRoom: (roomId: number) => (state: RootState) =>
    selectSlice(state).eventsByRoom[roomId],
  selectByRoomAndId: defaultMemoize((roomId: number, eventId?: string) =>
    createSelector(selectSlice, (slice) =>
      slice.eventsByRoom[roomId]?.find((ev) => eventId !== undefined && ev.id === eventId)
    )
  ),
  selectSelectedEventId: (state: RootState) => selectSlice(state).selectedEventId,
  selectActiveRequest: (state: RootState) => selectSlice(state).activeRequest,
  selectCurrentEventsByRoom: (roomId?: number) => (state: RootState) =>
    roomId ? selectSlice(state).currentEventsByRoom[roomId] : undefined,
  selectEventsByTeleRoom: (teleRoomId: string) => (state: RootState) =>
    selectSlice(state).eventsByTeleRoom[teleRoomId],
  selectByTeleRoomAndId: defaultMemoize((teleRoomId: string, eventId?: string) =>
    createSelector(selectSlice, (slice) =>
      slice.eventsByTeleRoom[teleRoomId]?.find((ev) => eventId !== undefined && ev.id === eventId)
    )
  ),
};
