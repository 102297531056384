import { logger } from "../../shared/infra/logger.js";

export interface PostProcObject {
  // Whether this object is in an acceptable state for callers to use.
  isOK?: () => boolean;
  cleanup: () => void;
}

export class PostProcObjectCache<T extends PostProcObject> {
  private cachedItems: T[] = [];
  private preloadingItems = 0;
  private closed = false;

  constructor(
    private factory: () => Promise<T>,
    public readonly minSize = 0,
    public readonly maxSize = 1
  ) {}

  public async get(): Promise<T> {
    while (this.cachedItems.length > 0) {
      const res = this.cachedItems.shift();
      if (res && (res.isOK === undefined || res.isOK())) {
        // Try to pre-create a few more items if necessary
        this.preload();
        return res;
      }
    }
    return await this.factory();
  }

  public preload() {
    if (this.closed) return;

    while (this.cachedItems.length + this.preloadingItems < this.minSize) {
      this.preloadingItems++;
      this.factory()
        .then((item) => {
          this.preloadingItems--;
          if (!this.closed && this.cachedItems.length + this.preloadingItems < this.maxSize) {
            // Enough space is left
            this.cachedItems.push(item);
          } else {
            // The cache is full (maybe an item was put() in while we were constructing this one?)
            // or has been closed; destroy the item
            try {
              item.cleanup();
            } catch (err) {
              logger.warn({ err }, `PostProcObjectCache.preload: cleanup() call failed`);
            }
          }
        })
        .catch((err) => {
          this.preloadingItems--;
          logger.warn({ err }, `PostProcObjectCache.preload: pre-creating item failed`);
        });
    }
  }

  public get cachedItemsCount(): number {
    return this.cachedItems.length;
  }

  public get preloadingItemsCount(): number {
    return this.preloadingItems;
  }

  public async createNew(): Promise<T> {
    return await this.factory();
  }

  public put(item: T) {
    if (
      (item.isOK === undefined || item.isOK()) &&
      !this.closed &&
      this.cachedItems.length + this.preloadingItems < this.maxSize
    ) {
      // Enough space is left; add it to the cache
      this.cachedItems.push(item);
    } else {
      // The item is bad or the cache is full or has been closed; destroy the item
      try {
        item.cleanup();
      } catch (err) {
        logger.warn({ err }, `PostProcObjectCache.put: cleanup() call failed`);
      }
    }
  }

  public clean() {
    while (this.cachedItems.length) {
      try {
        this.cachedItems.shift()?.cleanup();
      } catch (err) {
        logger.warn({ err }, `PostProcObjectCache.close: cleanup() call failed`);
      }
    }
  }

  public close() {
    this.closed = true;
    this.clean();
  }

  public dump(): any {
    return {
      cachedItemsCount: this.cachedItemsCount,
      preloadingItemsCount: this.preloadingItemsCount,
      minSize: this.minSize,
      maxSize: this.maxSize,
      closed: this.closed,
    };
  }
}

export class PostProcSyncObjectCache<T extends PostProcObject> {
  private cachedItems: T[] = [];
  private closed = false;

  constructor(
    private factory: () => T,
    public readonly minSize = 0,
    public readonly maxSize = 1
  ) {}

  public get(): T {
    while (this.cachedItems.length > 0) {
      const res = this.cachedItems.shift();
      if (res && (res.isOK === undefined || res.isOK())) {
        // Try to pre-create a few more items if necessary
        this.preload();
        return res;
      }
    }
    return this.factory();
  }

  public createNew(): T {
    return this.factory();
  }

  public preload() {
    if (this.closed) return;

    while (this.cachedItems.length < this.minSize) {
      try {
        this.cachedItems.push(this.factory());
      } catch (err) {
        logger.warn({ err }, `PostProcObjectCache.preload: pre-creating item failed`);
        break;
      }
    }
  }

  public preloadMax() {
    if (this.closed) return;

    while (this.cachedItems.length < this.maxSize) {
      try {
        this.cachedItems.push(this.factory());
      } catch (err) {
        logger.warn({ err }, `PostProcObjectCache.preloadMax: pre-creating item failed`);
        break;
      }
    }
  }

  public get cachedItemsCount(): number {
    return this.cachedItems.length;
  }

  public put(item: T) {
    if (
      (item.isOK === undefined || item.isOK()) &&
      !this.closed &&
      this.cachedItems.length < this.maxSize
    ) {
      // Enough space is left; add it to the cache
      this.cachedItems.push(item);
    } else {
      // The item is bad or the cache is full or has been closed; destroy the item
      try {
        item.cleanup();
      } catch (err) {
        logger.warn({ err }, `PostProcObjectCache.put: cleanup() call failed`);
      }
    }
  }

  public clean() {
    while (this.cachedItems.length) {
      try {
        this.cachedItems.shift()?.cleanup();
      } catch (err) {
        logger.warn({ err }, `PostProcObjectCache.close: cleanup() call failed`);
      }
    }
  }

  public close() {
    this.closed = true;
    this.clean();
  }

  public dump() {
    return {
      cachedItemsCount: this.cachedItemsCount,
      minSize: this.minSize,
      maxSize: this.maxSize,
      closed: this.closed,
    };
  }
}
