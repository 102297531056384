import { z } from "zod";
import { numberId, stringDate, stringId } from "../Models/zodTypes.js";

export const RoomInviteType = z.enum(["Stage", "Backstage", "Meeting"]);
export type RoomInviteType = z.infer<typeof RoomInviteType>;

export const RoomInviteResult = z.enum(["Accept", "Decline", "Cancel"]);
export type RoomInviteResult = z.infer<typeof RoomInviteResult>;

export const RoomInvite = z.object({
  id: stringId(),
  roamId: numberId(),
  roomId: numberId(),
  sectionId: stringId(),
  roomEventId: stringId().optional(),
  // Only one of guestEmail or guestOccupantId should be set
  // guestEmail will take precedence, and is preferred since it's more durable.
  guestEmail: z.string().email().optional(),
  guestOccupantId: stringId().optional(),
  guestName: z.string().optional(),
  guestImageUrl: z.string().url().optional(),
  hostPersonId: numberId(),
  hostEmail: z.string().email(),
  inviteType: RoomInviteType,
  inviteResult: RoomInviteResult.optional(),
  message: z.string().optional(),
  roamName: z.string().optional(),
  hostPersonName: z.string().optional(),
  roomName: z.string().optional(),
  roomOwnerId: numberId().optional(),
  oneTimeLinkQuery: z.string().optional(),
  created: stringDate().optional(), // Undefined on creation
  updated: stringDate().optional(),
});
export type RoomInvite = z.infer<typeof RoomInvite>;
