import { Popup } from "./Popup.js";

export const initialState = {
  // Everything in this array is indicated somehow on the screen. If attached to an anchor, it's
  // shown there. Otherwise, it is shown in the dock.
  popups: new Array<Popup>(),
  activeAnchoredPopupId: undefined as number | undefined,
  doNotPop: false,

  popDockIndex: undefined as number | undefined,
  popDockCounter: 0,
};
export type InteractionState = typeof initialState;
