import { z } from "zod";

export const EnterRoamRequestStatus = z.enum([
  /** The request has been sent, but no response has been received. */
  "requested",
  /** The request was approved. */
  "approved",
  /** The request was denied. */
  "denied",
  /**
   * The request was canceled.
   *
   * This is generally expected to be caused by the requester.
   * For example, we may provide an explicit cancel option or they might quit Roam.
   */
  "canceled",
]);
export type EnterRoamRequestStatus = z.infer<typeof EnterRoamRequestStatus>;

const TERMINAL_STATUSES = new Set<EnterRoamRequestStatus>(["approved", "denied", "canceled"]);
export const isTerminalStatus = (status: EnterRoamRequestStatus): boolean => {
  return TERMINAL_STATUSES.has(status);
};
