import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { Rectangle } from "electron";
import { z } from "zod";
import { createSelector } from "../../../helpers/redux.js";
import { createStorageKey } from "../../../injection/storage/IStorage.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

export type Orientation = "landscape" | "portrait";

export interface SelectedScreenShareSource {
  screenKey: string;
  windowKey: WindowKey;
  boardId: string;
  // The id of the MediaStream
  streamId?: string;

  // We only get the sourceId and sourceName if it's Electron
  sourceId?: string;
  sourceName?: string;
  sourceType?: "window" | "screen";
  displayId?: string;
  orientation?: Orientation;
  audio: boolean;
}

export interface StartScreenSharePayload {
  screenKey: string;
  windowKey: WindowKey;
}

export interface StartScreenShareWaylandPayload extends StartScreenSharePayload {
  audio: boolean;
}

export interface StopScreenSharePayload {
  boardId?: string;
  reason: string;
}

export interface ScreenShareSource {
  id: string;
  name: string;
  displayId?: string;
  thumbnailURL?: string;
  appIconURL?: string;
  orientation?: Orientation;
  removed: boolean;
}

export interface ScreenShareInfo {
  displayBounds: Rectangle;
  displayWorkArea: Rectangle;
  topWindow: boolean;
  // Will be undefined if the window's position is unknown
  // (On Linux, especially on Wayland, sometimes we do not know the
  // window's position and have no way to find it. In these cases we
  // want to still show the floating window -- and perhaps the overlay
  // window -- on the default display, but e.g. the cursors should be
  // hidden.)
  windowBounds?: Rectangle;
}

export interface ShareControlsWindowPosition {
  width: number;
  height: number;
  position: "top" | "bottom";
  hasAnyOpenDropdown: boolean;
}

export const shareControlsPositionKey = createStorageKey(
  "shareControlsPosition",
  z.enum(["top", "bottom"])
);

const slice = createSlice({
  name: "stream",
  initialState: {
    screenShareSources: [] as ScreenShareSource[],
    screenShareSourceAppNames: {} as { [sourceId: string]: string },
    screenShareInfo: undefined as ScreenShareInfo | undefined,
    numDeviceDisplays: undefined as number | undefined,
    activeSource: undefined as SelectedScreenShareSource | undefined,
    supportsTransparency: true,
    shareControlsWindowPosition: undefined as ShareControlsWindowPosition | undefined,
    shareControlsWindowAnchored: true,
  },
  reducers: {
    startScreenShare: (state, action: PayloadAction<StartScreenSharePayload>) => {},
    startScreenShareWeb: (state, action: PayloadAction<StartScreenSharePayload>) => {},
    startScreenShareForOnlyScreen: (state, action: PayloadAction<StartScreenSharePayload>) => {},
    startScreenShareLinuxWayland: (
      state,
      action: PayloadAction<StartScreenShareWaylandPayload>
    ) => {},
    stopScreenShare: (state, action: PayloadAction<StopScreenSharePayload>) => {},
    screenShareSourceSelected: (state, action: PayloadAction<SelectedScreenShareSource>) => {},

    screenShareStarted: (state, action: PayloadAction<SelectedScreenShareSource>) => {
      state.activeSource = action.payload;
    },
    screenShareStopped: (state) => {
      state.activeSource = undefined;
    },
    screenShareStreamChange: (state, action: PayloadAction<string>) => {
      if (state.activeSource) {
        state.activeSource.streamId = action.payload;
      }
    },

    setScreenShareSources: (state, action: PayloadAction<ScreenShareSource[]>) => {
      state.screenShareSources = action.payload;
    },
    setNumDeviceDisplays: (state, action: PayloadAction<number>) => {
      state.numDeviceDisplays = action.payload;
    },
    setScreenShareSourceAppNames: (
      state,
      action: PayloadAction<{ [sourceId: string]: string }>
    ) => {
      state.screenShareSourceAppNames = action.payload;
    },
    setScreenShareInfo: (state, action: PayloadAction<ScreenShareInfo | undefined>) => {
      state.screenShareInfo = action.payload;
    },
    setSupportsTransparency: (state, action: PayloadAction<boolean>) => {
      state.supportsTransparency = action.payload;
    },
    setShareControlsWindowPosition: (state, action: PayloadAction<ShareControlsWindowPosition>) => {
      state.shareControlsWindowPosition = action.payload;
    },
    setShareControlsWindowAnchored: (state, action: PayloadAction<boolean>) => {
      state.shareControlsWindowAnchored = action.payload;
    },
  },
});
export const { actions: StreamActions, reducer: streamReducer } = slice;

const selectSlice = (state: RootState) => state.holo.stream;
export const StreamSelectors = {
  selectScreenShareSources: createSelector(selectSlice, (slice) => slice.screenShareSources),
  selectNumDeviceDisplays: createSelector(selectSlice, (slice) => slice.numDeviceDisplays),
  selectActiveSource: createSelector(selectSlice, (slice) => slice.activeSource),
  selectScreenShareSourceAppNames: createSelector(
    selectSlice,
    (slice) => slice.screenShareSourceAppNames
  ),
  selectScreenShareInfo: (state: RootState) => selectSlice(state).screenShareInfo,
  selectIsSharingDisplay: createSelector(selectSlice, (slice) => slice.activeSource !== undefined),
  selectSupportsTransparency: (state: RootState) => selectSlice(state).supportsTransparency,
  selectSupportsOverlays: (state: RootState) => {
    // If we don't have transparency support (happens on Linux on X11 when not running a
    // compositor), we cannot show an overlay window without covering the entire screen
    // (Note that this can change at runtime)
    if (!selectSlice(state).supportsTransparency) return false;
    // On Linux, on Wayland compositors that do not honor setIgnoreMouseEvents, we cannot show an
    // overlay window without making it impossible to click
    const linuxEnvironmentInfo = state.app.linuxEnvironmentInfo;
    if (linuxEnvironmentInfo && !linuxEnvironmentInfo.supportsIgnoreMouseEvents) return false;
    // Otherwise an overlay window can safely be shown
    return true;
  },
  selectShareControlsWindowPosition: (state: RootState) =>
    selectSlice(state).shareControlsWindowPosition,
  selectShareControlsWindowAnchored: (state: RootState) =>
    selectSlice(state).shareControlsWindowAnchored,
};
