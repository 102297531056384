import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { defaultMemoize } from "reselect";
import { Item } from "../../../../shared/Models/Item.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface ShelfItemsPayload {
  shelfId: string;
  itemsByLevel: {
    [level: number]: string[];
  };
}

export interface PlaceItemPayload {
  itemId: string;
  shelfId: string;
  level?: number;
  position?: number;
}

export interface MoveItemPayload {
  itemId: string;
  shelfId: string;
  sourceLevel: number;
  sourcePosition: number;
  destLevel: number;
  destPosition: number;
}

export interface MoveLevelPayload {
  shelfId: string;
  sourceLevel: number;
  destLevel: number;
}

export interface RemoveItemPayload {
  itemId: string;
  shelfId: string;
}

export interface PlaceItemResult {
  itemId: string;
  shelfId: string;
  success: boolean;
  detail?: string;
}

export interface ShelfItems {
  [shelfId: string]: {
    [level: number]: string[];
  };
}

const slice = createSlice({
  name: "shelfItem",
  initialState: {
    shelfItems: {} as ShelfItems,
    lastShelfItems: undefined as ShelfItems | undefined,
    shelfItemError: undefined as string | undefined,
  },
  reducers: {
    // Server calls
    placeItem: (state, action: PayloadAction<PlaceItemPayload>) => {},
    moveItem: (state, action: PayloadAction<MoveItemPayload>) => {
      // Optimistically update, but the whole thing will be refreshed
      const { shelfId, itemId, sourceLevel, sourcePosition, destLevel, destPosition } =
        action.payload;
      state.lastShelfItems = cloneDeep(state.shelfItems);

      const shelf = state.shelfItems[shelfId];
      if (!shelf) throw new Error("Missing Shelf");

      const sourceRow = shelf[sourceLevel];
      if (!sourceRow) throw new Error("Missing sourceRow");

      sourceRow.splice(sourcePosition, 1);
      const destRow = shelf[destLevel];
      if (destRow) {
        destRow.splice(destPosition, 0, itemId);
      } else {
        shelf[destLevel] = [itemId];
      }
    },
    moveLevel: (state, action: PayloadAction<MoveLevelPayload>) => {
      const { shelfId, sourceLevel, destLevel } = action.payload;
      state.lastShelfItems = cloneDeep(state.shelfItems);

      const shelf = state.shelfItems[shelfId];
      if (!shelf) throw new Error("Missing shelf");

      const items = shelf[sourceLevel];
      const destItems = shelf[destLevel];

      shelf[sourceLevel] = destItems ?? [];
      shelf[destLevel] = items ?? [];
    },
    removeItem: (state, action: PayloadAction<RemoveItemPayload>) => {},
    undoOptimisticChange: (state) => {
      if (state.lastShelfItems) {
        state.shelfItems = state.lastShelfItems;
        state.lastShelfItems = undefined;
      }
    },

    setShelfItems: (state, action: PayloadAction<ShelfItemsPayload>) => {
      // Levels may not have an entry if it has no items.
      const { shelfId, itemsByLevel } = action.payload;
      state.shelfItems[shelfId] = itemsByLevel;
    },
    setShelfItemError: (state, action: PayloadAction<string>) => {
      state.shelfItemError = action.payload;
    },

    // Status
    placeItemResult: (state, action: PayloadAction<PlaceItemResult>) => {},
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.things.shelfItem;
const selectItemSlice = (state: RootState) => state.things.item;
export const selectors = {
  selectShelfItems: (shelfId?: string) => (state: RootState) =>
    shelfId ? state.things.shelfItem.shelfItems[shelfId] : undefined,
  selectShelfLevelItems: defaultMemoize(
    (shelfId?: string, level?: number) =>
      createDeepEqualSelector(selectSlice, selectItemSlice, (slice, itemSlice) => {
        if (shelfId === undefined || level === undefined) {
          return [];
        }
        const ids = slice.shelfItems[shelfId]?.[level];
        const ret = new Array<Item>();
        if (ids) {
          for (const id of ids) {
            const item = itemSlice.items.entities[id];
            if (item) ret.push(item);
          }
        }
        return ret.slice(0, 6); // only render the first 6 items to handle old shelves with lots of items
      }),
    {
      maxSize: 5,
    }
  ),
  selectShelfItemError: (state: RootState) => state.things.shelfItem.shelfItemError,
};

export const ShelfItemSelectors = selectors;
export const ShelfItemActions = actions;
