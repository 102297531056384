import { AsyncLock } from "../../../shared/helpers/lock.js";
import { logger } from "../../../shared/infra/logger.js";
import { ClientContainerInfo } from "../../injection/IClientContainer.js";

export class VideoFrameWatcher {
  public video: HTMLVideoElement;
  // Set to true if the video is properly set up and playing
  private started = false;
  private onFrame: () => Promise<void>;
  // If a call to onFrame() is currently in progress, this is a promise that will resolve when it finishes
  private readonly lock = new AsyncLock();
  private tickTimeoutId: ReturnType<typeof setTimeout> | undefined;
  private lastTime: number | undefined;

  constructor(
    private containerInfo: ClientContainerInfo,
    onFrame: () => Promise<void>
  ) {
    // eslint-disable-next-line no-restricted-globals
    this.video = document.createElement("video");
    this.video.muted = true;
    this.onFrame = onFrame;

    // On web, try to handle the timeupdate event
    setTimeout(() => this.tryHandleTimeupdate(), 0);
  }

  private tryHandleTimeupdate() {
    if (this.containerInfo.containerType === "web") {
      // Chrome seems to throttle setTimeout() to once per second in background tabs.
      // The timeupdate event does slightly better (~4 FPS) in this case.
      // We only need to do this on web because on Electron we disable the throttling.
      this.video.addEventListener("timeupdate", () => {
        // Only do an update if the page is hidden; if the page is visible the normal timer will handle it
        // eslint-disable-next-line no-restricted-globals
        if (document.hidden) {
          void this.update();
        }
      });
    }
  }

  public async start(stream: MediaStream) {
    await this.lock.guard(async () => {
      if (this.started) return;
      this.video.srcObject = stream;
      try {
        await this.video.play();
      } catch (err: any) {
        // Unset srcObject -- it should only be set to non-null if the video is actually playing
        this.video.srcObject = null;
        throw err;
      }
      this.started = true;
      if (!this.tickTimeoutId) {
        this.tickTimeoutId = setTimeout(() => this.tick(), 0);
      }
    });
  }

  public async stop() {
    const warnTimeoutId = setTimeout(() => {
      logger.warn(`VideoFrameWatcher.update: acquiring the lock is taking a long time`);
    }, 5 * 1000);
    await this.lock.guard(async () => {
      clearTimeout(warnTimeoutId);
      if (!this.started) return;
      this.started = false;
      // If a tick() is scheduled, clear it
      if (this.tickTimeoutId) {
        clearTimeout(this.tickTimeoutId);
        this.tickTimeoutId = undefined;
      }
      // Finally, pause the video
      this.video.srcObject = null;
      this.video.pause();
    });
  }

  public getCurrentStream(): MediaStream | undefined {
    return this.video.srcObject !== null ? (this.video.srcObject as MediaStream) : undefined;
  }

  private async tick() {
    this.tickTimeoutId = undefined;
    const start = performance.now();
    await this.update();
    if (this.started && !this.tickTimeoutId) {
      // If there are no other tick()s scheduled and we're start()ed, schedule a new tick()
      const end = performance.now();
      // Try to get ~30 FPS, but wait at least 15ms to let the UI update
      this.tickTimeoutId = setTimeout(() => this.tick(), Math.max(33 - (end - start), 15));
    }
  }

  private async update() {
    await this.lock.guard(async () => {
      if (this.started && this.video.currentTime !== this.lastTime) {
        // We have a new frame
        this.lastTime = this.video.currentTime;
        try {
          await this.onFrame();
        } catch (err) {
          logger.error({ err }, `Error updating video`);
        }
      }
    });
  }
}
