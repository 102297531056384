import { TFLite } from "../../../../assets/tflite/tflite-simd.js";
import {
  compileShader,
  createPipelineStageProgram,
  createTexture,
  glsl,
  readPixelsAsync,
} from "../../webglHelper.js";
import { SegmentationConfig, inputResolutions } from "../segmentationHelper.js";

export const buildResizingStage = (
  gl: WebGL2RenderingContext,
  vertexShader: WebGLShader,
  positionBuffer: WebGLBuffer,
  texCoordBuffer: WebGLBuffer,
  segmentationConfig: SegmentationConfig,
  tflite: TFLite
) => {
  const fragmentShaderSource = glsl`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      outColor = texture(u_inputFrame, v_texCoord);
    }
  `;

  // TFLite memory will be accessed as float32
  // eslint-disable-next-line no-underscore-dangle
  const tfliteInputMemoryOffset = tflite._getInputMemoryOffset() / 4;

  const [outputWidth, outputHeight] = inputResolutions[segmentationConfig.inputResolution];
  const outputPixelCount = outputWidth * outputHeight;

  const fragmentShader = compileShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);
  const program = createPipelineStageProgram(
    gl,
    vertexShader,
    fragmentShader,
    positionBuffer,
    texCoordBuffer
  );
  const inputFrameLocation = gl.getUniformLocation(program, "u_inputFrame");
  const outputTexture = createTexture(gl, gl.RGBA8, outputWidth, outputHeight);

  const frameBuffer = gl.createFramebuffer();
  gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer);
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, outputTexture, 0);
  const outputPixels = new Uint8Array(outputPixelCount * 4);

  gl.useProgram(program);
  gl.uniform1i(inputFrameLocation, 0);

  const render = async () => {
    gl.viewport(0, 0, outputWidth, outputHeight);
    gl.useProgram(program);
    gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

    // eslint-disable-next-line no-restricted-globals
    if (document.hidden) {
      gl.readPixels(0, 0, outputWidth, outputHeight, gl.RGBA, gl.UNSIGNED_BYTE, outputPixels);
    } else {
      // Downloads pixels asynchronously from GPU while rendering the current frame
      await readPixelsAsync(
        gl,
        0,
        0,
        outputWidth,
        outputHeight,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        outputPixels
      );
    }

    for (let i = 0; i < outputPixelCount; i++) {
      const tfliteIndex = tfliteInputMemoryOffset + i * 3;
      const outputIndex = i * 4;

      const outputPixels0 = outputPixels[outputIndex];
      const outputPixels1 = outputPixels[outputIndex + 1];
      const outputPixels2 = outputPixels[outputIndex + 2];

      if (
        outputPixels0 === undefined ||
        outputPixels1 === undefined ||
        outputPixels2 === undefined
      ) {
        throw new Error("Missing output pixel data");
      }

      tflite.HEAPF32[tfliteIndex] = outputPixels0 / 255;
      tflite.HEAPF32[tfliteIndex + 1] = outputPixels1 / 255;
      tflite.HEAPF32[tfliteIndex + 2] = outputPixels2 / 255;
    }
  };

  const cleanUp = () => {
    gl.deleteFramebuffer(frameBuffer);
    gl.deleteTexture(outputTexture);
    gl.deleteProgram(program);
    gl.deleteShader(fragmentShader);
  };

  return { render, cleanUp };
};
