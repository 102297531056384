import axios from "axios";
import ports from "../../../common/ports.json";
import { serverAxiosConfig } from "../server/serverAxios.js";
import {
  allEnvironments,
  Environment,
  environmentLaunchDarklyClientSideIds,
  environmentMatomoSiteIds,
  isDevMode,
  overrideEnvironment,
} from "./environment.js";
import {
  crmServiceLocations,
  dingDongServiceLocations,
  emailsServiceLocations,
  listeningServiceLocations,
  mediaServiceLocations,
  roamBackendServiceLocations,
  roamWSServiceLocations,
} from "./serviceLocations.js";

export const environment: Environment =
  overrideEnvironment && allEnvironments.includes(overrideEnvironment)
    ? overrideEnvironment
    : isDevMode
      ? "local"
      : "dev";

export const matomoSiteIdForEnv = environmentMatomoSiteIds[environment];

export const launchDarklyClientIdForEnv = environmentLaunchDarklyClientSideIds[environment];

export const baseListeningURL = listeningServiceLocations[environment];

export const listeningHttp = axios.create({
  ...serverAxiosConfig,
  baseURL: baseListeningURL,
  // TODO: security token
  headers: {
    "Content-Type": "application/json",
  },
  transformResponse: [
    (data): any => {
      if (typeof data === "string") {
        return JSON.parse(data, dateReviver);
      }
      return data;
    },
  ],
});

export const baseMediaURL = mediaServiceLocations[environment];

export const mediaHttp = axios.create({
  ...serverAxiosConfig,
  baseURL: baseMediaURL,
  headers: {
    "Content-Type": "application/json",
  },
  transformResponse: [
    (data): any => {
      if (typeof data === "string") {
        return JSON.parse(data, dateReviver);
      }
      return data;
    },
  ],
});

// Web workers know the URL they're hosted from as self.location
const location: Location | undefined =
  typeof window !== "undefined"
    ? window?.location
    : typeof self !== "undefined"
      ? self?.location
      : undefined;

// Allows accessing Roam Middle End from multiple ports locally
export const baseRoamURL = location
  ? location?.hostname?.includes("ngrok") || location?.hostname?.includes("ts.net")
    ? `https://${location.hostname}`
    : location?.hostname === "localhost" && location?.port !== "3002" // electron
      ? location?.port === `${ports.ROAM_MIDDLEEND_PORT_2}`
        ? `http://localhost:${ports.ROAM_MIDDLEEND_PORT_2}`
        : `http://localhost:${ports.ROAM_MIDDLEEND_PORT}`
      : roamWSServiceLocations[environment]
  : process.env.ROAM_CLIENT_HOSTNAME
    ? `https://${process.env.ROAM_CLIENT_HOSTNAME}`
    : roamWSServiceLocations[environment];

// This is used when developing locally to set up
// ngrok and should be overridden
// See roam/backend/src/calendar/developer-setup.md
export const baseWebhooksURL =
  typeof process !== "undefined" ? process?.env?.WEBHOOK_BASE_URL ?? baseRoamURL : baseRoamURL;

// Similar to baseWebhooksURL for dev
export const baseOauthURL =
  typeof process !== "undefined" ? process.env.OAUTH_BASE_URL ?? baseRoamURL : baseRoamURL;

export const assetBaseUrl =
  typeof process !== "undefined" ? process.env.ASSET_BASE_URL ?? baseRoamURL : baseRoamURL;

export const baseRoamBackendURL =
  location?.hostname === "localhost" ? location.origin : roamBackendServiceLocations[environment];

export const roamHttp = axios.create({
  ...serverAxiosConfig,
  baseURL: baseRoamURL,
  headers: {
    "Content-Type": "application/json",
  },
  transformResponse: [
    (data): any => {
      if (typeof data === "string") {
        return JSON.parse(data, dateReviver);
      }
      return data;
    },
  ],
});

export const baseDingDongURL =
  location?.hostname === "localhost" ? location.origin : dingDongServiceLocations[environment];

export const baseCrmURL =
  location?.hostname === "localhost" ? location.origin : crmServiceLocations[environment];

export const baseEmailsURL =
  location?.hostname === "localhost" ? location.origin : emailsServiceLocations[environment];

// From https://stackoverflow.com/a/3143231
const dateFormat =
  /^(\d{4}-[01]\d-[0-3]\dT[0-2](?:\d:[0-5]){2}\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2](?:\d:[0-5]){2}\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$/;

export const dateReviver = (_: any, value: unknown) => {
  if (typeof value === "string" && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
};
