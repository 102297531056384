import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { createDeepEqualSelector } from "../../helpers/redux.js";
import { WindowKey } from "../../injection/windows/WindowKey.js";
import { RootState } from "../../store/reducers.js";
import { NewPanel, Panel, WindowPanelState } from "../types/Panel.js";

export interface OpenPanelPayload {
  panel: NewPanel;
  windowKey: WindowKey;
}

export interface InternalOpenPanelPayload {
  panel: Panel;
  windowKey: WindowKey;
}

// Manages the user focusing on something (chat, board, screenshare, future TBD) such that it takes
// over the main window area and the map or meeting room videos are floated above it until they
// unfocus
const slice = createSlice({
  name: "panel",
  initialState: {
    stateByWindow: {} as Record<WindowKey, WindowPanelState>,
    panels: {} as Record<number, Panel>,
  },
  reducers: {
    openPanel: (state, action: PayloadAction<OpenPanelPayload>) => {
      // logic in saga
    },
    closePanel: (state, action: PayloadAction<number>) => {
      const panelId = action.payload;
      delete state.panels[panelId];
      for (const windowState of Object.values(state.stateByWindow)) {
        const i = windowState.panelIds.indexOf(panelId);
        if (i !== undefined && i >= 0) {
          windowState.panelIds.splice(i, 1);
          if (windowState.panelIds.length === 0) {
            windowState.open = false;
          }
        }
      }
    },
    updatePanel: (state, action: PayloadAction<Panel>) => {
      const panel = action.payload;
      state.panels[panel.panelId] = panel;
    },
    closePanelsInWindow: (state, action: PayloadAction<WindowKey>) => {
      const windowState = state.stateByWindow[action.payload];
      if (windowState) {
        windowState.open = false;
        windowState.panelIds = [];
      }
    },

    internalOpenPanel: (state, action: PayloadAction<InternalOpenPanelPayload>) => {
      const { windowKey, panel } = action.payload;
      state.panels[panel.panelId] = panel;
      const windowState = state.stateByWindow[windowKey];
      if (windowState) {
        windowState.open = true;
        windowState.panelIds = [panel.panelId];
      } else {
        state.stateByWindow[windowKey] = {
          open: true,
          panelIds: [panel.panelId],
        };
      }
    },
  },
});
export const { actions, reducer } = slice;
export const PanelActions = actions;

const selectSlice = (state: RootState) => state.panel;
export const selectors = {
  selectPanelsAreOpen: (windowKey?: WindowKey) => (state: RootState) =>
    windowKey !== undefined && !!selectSlice(state).stateByWindow[windowKey]?.open,
  selectPanelsInWindow: defaultMemoize((windowKey?: WindowKey) =>
    createDeepEqualSelector(selectSlice, (slice): Panel[] => {
      if (windowKey === undefined) return [] as Panel[];
      const windowState = slice.stateByWindow[windowKey];
      if (!windowState || !windowState.open) return [] as Panel[];
      return windowState.panelIds
        .map((panelId) => slice.panels[panelId])
        .filter((p): p is Panel => !!p);
    })
  ),
  selectPanelStateByWindow: (windowKey?: WindowKey) => (state: RootState) =>
    windowKey && selectSlice(state).stateByWindow[windowKey],
  selectPanelById: (panelId?: number) => (state: RootState) =>
    panelId ? selectSlice(state).panels[panelId] : undefined,
  selectAllPanels: createDeepEqualSelector(selectSlice, (slice) => Object.values(slice.panels)),
};
export const PanelSelectors = selectors;
