import { combineReducers } from "redux";
import { reducer as calendarEventReducer } from "./slices/calendarEventSlice.js";
import { reducer as settingsReducer } from "./slices/calendarSettingsSlice.js";
import { reducer as personalCalendarReducer } from "./slices/personalCalendarSlice.js";

export const reducer = combineReducers({
  settings: settingsReducer,
  calendarEvents: calendarEventReducer,
  personalCalendars: personalCalendarReducer,
});
