import { z } from "zod";

export const Cue = z.object({
  speaker: z.string(),
  startTime: z.number(),
  endTime: z.number(),
  text: z.string(),
});
export type Cue = z.infer<typeof Cue>;

export const Transcription = z.object({
  savedMeetingId: z.string(),
  cues: Cue.array(),

  // Set to true when the saved meeting has a marked end time.
  completed: z.boolean().optional(),
});
export type Transcription = z.infer<typeof Transcription>;

export const TranscriptionToken = z.object({
  startTime: z.number(),
  text: z.string(),
});
export type TranscriptionToken = z.infer<typeof TranscriptionToken>;

/**
 * This is the raw format for a transcription for a single speaker. This can be passed into an
 * interleaver to get a full `Transcription` object.
 */
export const TranscriptionTrack = z.object({
  speaker: z.string(),
  tokens: TranscriptionToken.array(),
});
export type TranscriptionTrack = z.infer<typeof TranscriptionTrack>;

/** This is the exact format that is stored in S3. */
export const WhisperSegments = z.object({
  Start: z.string(),
  Segments: z
    .object({
      Text: z.string(),
      Start: z.number(),
      Tokens: z
        .object({
          Text: z.string(),
          Start: z.number(),
          End: z.number(),
        })
        .array(),
    })
    .array(),
});
export type WhisperSegments = z.infer<typeof WhisperSegments>;
