import { inspect } from "util";
import {
  BenchmarkResult,
  PipelineClientState,
  VideoPipelineName,
  VideoPipelineOverride,
} from "../../../shared/Models/VideoPipeline.js";
import {
  MULTIPLE_VIDEO_PIPELINES_ACTIVE,
  MULTIPLE_VIDEO_PIPELINES_PRIORITIES,
} from "../../../shared/featureflags/RoamFlagConfigs.js";
import { logger } from "../../../shared/infra/logger.js";
import { ClientContainerInfo } from "../../injection/IClientContainer.js";
import { IRedux } from "../../injection/redux/IRedux.js";
import { IStorage, createStorageKey } from "../../injection/storage/IStorage.js";
import { AppActions } from "../../store/slices/appSlice.js";
import { FeatureFlagSelectors } from "../../store/slices/featureFlagSlice.js";
import { IVideoPipelineCache } from "../video/interfaces/IVideoPipelineCache.js";
import { videoWorkerSupported } from "../video/worker/VideoWorkerProxy.js";
import { PipelineBenchmark } from "./PipelineBenchmark.js";
import { pipelineClientInfoString } from "./benchmarkEval.js";
import { IPipelineBenchmarkService } from "./interfaces/IPipelineBenchmarkService.js";

interface Injected {
  info(): ClientContainerInfo;
  storage(): IStorage;
  redux(): IRedux;
  videoPipelineCache(): IVideoPipelineCache;
}

const videoPipelineClientState = createStorageKey("videoPipelineClientState", PipelineClientState);

export class PipelineBenchmarkService implements IPipelineBenchmarkService {
  constructor(private container: Injected) {}

  private lastResults = new Map<VideoPipelineName, BenchmarkResult>();
  private clientState?: PipelineClientState;
  private benchmarkInProgress = false;
  private autoSelectInProgress = false;

  public async init() {
    await this.ensureStateLoaded();
  }

  public async runForPipeline(pipelineName: VideoPipelineName): Promise<BenchmarkResult> {
    if (this.benchmarkInProgress) {
      throw new Error("cannot start multiple benchmarks at once");
    }
    this.benchmarkInProgress = true;
    try {
      logger.info(`PipelineBenchmarkService: running benchmark for pipeline ${pipelineName}`);

      if (
        this.container.videoPipelineCache().pipelineRequiresWorker(pipelineName) &&
        !videoWorkerSupported()
      ) {
        const pipelineVersion = this.container
          .videoPipelineCache()
          .currentVersionForPipeline(pipelineName);
        if (!pipelineVersion) {
          throw new Error(`no version found for pipeline ${pipelineName}`);
        }
        return {
          status: "failure",
          error: "Web workers are required but not supported in this client",
          pipelineName,
          pipelineVersion,
        };
      }

      const benchmark = new PipelineBenchmark(this.container, pipelineName);
      const result = await benchmark.run();

      this.lastResults.set(pipelineName, result);
      logger.info(`got result ${inspect(result)}`);
      return result;
    } finally {
      this.benchmarkInProgress = false;
    }
  }

  public async performAutoSelect(forceRerun = false): Promise<VideoPipelineName | undefined> {
    if (this.autoSelectInProgress) {
      return;
    }
    const clientInfo = pipelineClientInfoString(this.container.redux().getState());
    logger.info("PipelineBenchmarkService: starting autoselect");
    this.autoSelectInProgress = true;
    this.container.redux().dispatch(AppActions.setAutomaticVideoPipeline("selecting"));
    let benchmarksRun = 0;
    try {
      await this.ensureStateLoaded();
      if (!this.clientState) {
        throw new Error("clientState undefined after loaded - unexpected");
      }

      if (this.clientState.pipelineOverride && !forceRerun) {
        return this.clientState.pipelineOverride === "disabled"
          ? undefined
          : this.clientState.pipelineOverride;
      }

      const pipelinePriorityOrder = this.getPipelinePriorityOrder();
      logger.info(
        `PipelineBenchmarkService: pipeline priority order is ${inspect(pipelinePriorityOrder)}`
      );

      const needsRun = new Set<VideoPipelineName>(pipelinePriorityOrder);

      if (!forceRerun) {
        for (const pipelineName of needsRun) {
          // If we have a recent result for the same pipeline version, we don't need to run the
          // benchmark again
          const currentVersion = this.container
            .videoPipelineCache()
            .currentVersionForPipeline(pipelineName);
          const previousResult = this.clientState.supportByPipeline?.[pipelineName];

          if (previousResult !== undefined) {
            const daysSinceResult = (Date.now() - previousResult.timestamp) / (1000 * 60 * 60 * 24);
            if (
              previousResult.pipelineVersion === currentVersion &&
              previousResult.clientInfo === clientInfo &&
              (daysSinceResult < 30 || previousResult.supported)
            ) {
              // If an affirmative result was for the same client or a negative result is less than
              // 30 days old, don't run the benchmark again
              needsRun.delete(pipelineName);
            }
          }
        }
      }

      const results = new Array<BenchmarkResult>();
      while (needsRun.size > 0) {
        const pipelineName = this.getNextBenchmarkToRun(needsRun, forceRerun);
        if (!pipelineName) {
          break;
        }
        needsRun.delete(pipelineName);
        const result = await this.runForPipeline(pipelineName);
        results.push(result);

        const supported = result.status === "success" && result.supported;
        benchmarksRun++;
        this.clientState.supportByPipeline[pipelineName] = {
          supported,
          pipelineVersion: result.pipelineVersion,
          timestamp: Date.now(),
          clientInfo,
        };

        // Save state as we go so that if someone tries to enter a meeting before we're done, we let
        // them use the best pipeline we know about
        await this.saveState();
        this.updateRedux();
      }

      const auto = this.getAutoSelection();
      const effective = this.getEffectivePipeline();

      if (results.length > 0) {
        this.reportResultsToServer(results).catch((err) =>
          logger.error({ err }, "Error reporting benchmark results")
        );
      }

      logger.info(
        `PipelineBenchmarkService: autoselect complete. ${benchmarksRun} benchmarks run. Auto is now ${auto}, effective ${effective}`
      );
      return effective;
    } catch (err) {
      logger.error(
        { err },
        "PipelineBenchmarkService error running benchmarks. Results not reported."
      );
      throw err;
    } finally {
      this.autoSelectInProgress = false;
      this.updateRedux();
    }
  }

  public getAutoSelectInProgress() {
    return this.autoSelectInProgress;
  }

  public getPipelinePriorityOrder(): VideoPipelineName[] {
    if (this.container.info().containerType === "pipelines") {
      return [
        "mediapipe",
        "mediapipeNoWorker",
        "mediapipeMedium",
        "mediapipeMediumNoWorker",
        "mediapipeBasic",
        "mediapipeBasicNoWorker",
        "tflite",
      ];
    }
    const flagPrioritiesActive = FeatureFlagSelectors.selectFlag(MULTIPLE_VIDEO_PIPELINES_ACTIVE)(
      this.container.redux().getState()
    );
    if (!flagPrioritiesActive) {
      return ["tflite"];
    } else {
      const flagPrioritiesString = FeatureFlagSelectors.selectFlag(
        MULTIPLE_VIDEO_PIPELINES_PRIORITIES
      )(this.container.redux().getState());
      const pipelines = new Array<VideoPipelineName>();
      for (const str of flagPrioritiesString.split(",")) {
        const result = VideoPipelineName.safeParse(str);
        if (result.success) {
          pipelines.push(result.data);
        } else {
          logger.warn(`getPipelinePriorityOrder: skipping unknown pipeline ${str}`);
        }
      }
      if (pipelines.length > 0) {
        return pipelines;
      } else {
        logger.error(
          "getPipelinePriorityOrder: no pipelines parsed from string flag, using default"
        );
        return ["mediapipe", "mediapipeMediumNoWorker", "mediapipeBasicNoWorker"];
      }
    }
  }

  private getNextBenchmarkToRun(
    needsRun: Set<VideoPipelineName>,
    force = false
  ): VideoPipelineName | undefined {
    if (!this.clientState) {
      return undefined;
    }

    if (needsRun.has("mediapipeBasic")) {
      return "mediapipeBasic";
    }
    if (needsRun.has("mediapipeMedium")) {
      return "mediapipeMedium";
    }
    const exclude = new Set<VideoPipelineName>();

    if (this.container.info().containerType !== "pipelines" && !force) {
      if (this.clientState.supportByPipeline["mediapipeBasic"]?.supported === false) {
        exclude.add("mediapipe");
        exclude.add("mediapipeMedium");
      }
      if (this.clientState.supportByPipeline["mediapipeBasicNoWorker"]?.supported === false) {
        exclude.add("mediapipe");
        exclude.add("mediapipeMedium");
        exclude.add("mediapipeNoWorker");
        exclude.add("mediapipeMediumNoWorker");
      }
      if (this.clientState.supportByPipeline["mediapipeMedium"]?.supported === false) {
        exclude.add("mediapipe");
      }
      if (this.clientState.supportByPipeline["mediapipeMediumNoWorker"]?.supported === false) {
        exclude.add("mediapipe");
        exclude.add("mediapipeNoWorker");
      }
    }

    for (const p of needsRun) {
      if (!exclude.has(p)) {
        return p;
      }
    }
    return undefined;
  }

  public getLastResults() {
    return this.lastResults;
  }

  public getAutoSelection() {
    if (!this.clientState) {
      return undefined;
    }
    const pipelinePriorities = this.getPipelinePriorityOrder();
    for (const pipelineName of pipelinePriorities) {
      const supported = this.clientState.supportByPipeline[pipelineName]?.supported;
      if (supported) {
        return pipelineName;
      }
    }
    return undefined;
  }

  public getEffectivePipeline() {
    if (!this.clientState) {
      throw new Error("cannot get effective pipeline before loading client state");
    }
    const override = this.clientState.pipelineOverride;
    if (override) {
      if (override === "disabled") {
        return undefined;
      } else {
        return override;
      }
    }
    return this.getAutoSelection();
  }

  public getOverridePipeline() {
    if (!this.clientState) {
      throw new Error("cannot get effective pipeline before loading client state");
    }
    return this.clientState.pipelineOverride;
  }

  public async setOverridePipeline(pipelineName?: VideoPipelineOverride): Promise<void> {
    if (!this.clientState) {
      throw new Error("cannot get effective pipeline before loading client state");
    }
    this.clientState.pipelineOverride = pipelineName;
    this.updateRedux();
    await this.saveState();
  }

  private async ensureStateLoaded() {
    if (this.clientState) {
      return;
    }

    try {
      this.clientState = this.container.storage().getValue(videoPipelineClientState);
    } catch (err) {
      logger.error({ err }, "Error loading pipeline benchmarks from local storage. Resetting");
    }

    if (this.clientState) {
      for (const [pipelineNameStr, result] of Object.entries(this.clientState.supportByPipeline)) {
        const pipelineName = VideoPipelineName.parse(pipelineNameStr);
        const currentVersion = this.container
          .videoPipelineCache()
          .currentVersionForPipeline(pipelineName);
        if (currentVersion && result.pipelineVersion < currentVersion) {
          this.clientState.supportByPipeline[pipelineName] = undefined;
        }
      }
      this.updateRedux();
    } else {
      this.clientState = {
        supportByPipeline: {},
      };
    }
  }

  private async reportResultsToServer(results: BenchmarkResult[]) {
    this.container.redux().dispatch(
      AppActions.reportVideoBenchmarkResults({
        results,
        clientState: this.clientState,
      })
    );
  }

  private async saveState() {
    if (this.clientState) {
      this.container.storage().setValue(videoPipelineClientState, this.clientState);
    }
  }

  private updateRedux() {
    const autoResult = this.getAutoSelection();
    this.container.redux().dispatch(AppActions.setAutomaticVideoPipeline(autoResult));
    const effectivePipeline = this.getEffectivePipeline();
    this.container.redux().dispatch(AppActions.setEffectiveVideoPipeline(effectivePipeline));
    const overridePipeline = this.getOverridePipeline();
    this.container.redux().dispatch(AppActions.setOverrideVideoPipeline(overridePipeline));
  }
}
