import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import {
  evaluateFlag,
  ExtractFlagReturnType,
  Flag,
  FlagState,
  FlagValue,
} from "../../../shared/featureflags/RoamFlagConfigs.js";
import { RootState } from "../reducers.js";

// WARNING: Any edits should likely be ported to featureFlagSlice too
// NOTE: Flag overrides are purposefully excluded from this file.

const adapter = createEntityAdapter<FlagState>({
  selectId: (flag) => flag.key,
});

export interface FeatureFlagContext {
  roamId: number;
  launchDarkyHash: string;
}

const slice = createSlice({
  name: "settingsFeatureFlags",
  initialState: {
    context: undefined as FeatureFlagContext | undefined,
    flags: adapter.getInitialState(),
    flagsSynced: false as boolean,
  },
  reducers: {
    initialize: (state, action: PayloadAction<FeatureFlagContext>) => {
      state.context = action.payload;
      state.flagsSynced = false;
      adapter.removeAll(state.flags);
    },
    reset: (state) => {
      state.context = undefined;
      state.flagsSynced = false;
      adapter.removeAll(state.flags);
    },
    updateFlags: (state, action: PayloadAction<FlagState[]>) => {
      adapter.upsertMany(state.flags, action.payload);
      state.flagsSynced = true;
    },
    setFlagsSynced: (state) => {
      state.flagsSynced = true;
    },
    syncFlags: (_) => {},
  },
});

export const { actions, reducer } = slice;

const adapterSelectors = adapter.getSelectors(
  (state: RootState) => state.settingsFeatureFlags.flags
);
export const selectors = {
  selectFlag: defaultMemoize(
    <F extends Flag<any, any>>(flag: F) =>
      (state: RootState): ExtractFlagReturnType<F> => {
        const defaultValue = evaluateFlag(flag, flag.defaultValue as FlagValue, flag.defaultValue);
        return evaluateFlag(
          flag,
          adapterSelectors.selectById(state, flag.key)?.value,
          defaultValue
        );
      },
    { maxSize: 50 }
  ),
  selectFlagsSynced: (state: RootState) => state.settingsFeatureFlags.flagsSynced,
  selectContext: (state: RootState) => state.settingsFeatureFlags.context,
};
export const SettingsFeatureFlagSelectors = selectors;
