import { z } from "zod";
import { stringId } from "../zodTypes.js";
import { LinkType } from "./LinkType.js";

/**
 * Identifies an access link.
 */
export const AccessLinkId = z.object({
  linkType: LinkType,
  /**
   * The ID of this access link.
   *
   * NOTE: This is actually a UUID, but originally typed as a stringId.
   */
  id: stringId(),
  /**
   * An arbitrary code.
   *
   * For access links that provide authentication (`providesAuthentication` is true in the corresponding `LinkConfiguration`),
   * this is intended as an additional security mechanism.
   * Those are expressed as base64urls.
   *
   * For other access links, there are (currently) no restrictions and the code can be used in any way.
   */
  code: z.string().min(1),
});
export type AccessLinkId = z.infer<typeof AccessLinkId>;
