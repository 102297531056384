import { Dimensions } from "@floating-ui/react";
import { logger } from "../../../shared/infra/logger.js";
import { IVideoPipeline } from "./interfaces/IVideoPipeline.js";
import { IVideoPipelineContainer, WebGLFactory } from "./interfaces/IVideoPipelineContainer.js";
import {
  PipelineError,
  PipelineSetupError,
  WebGLContextLostError,
  WebGLRecreateNeededError,
  toPipelineErrorRequired,
} from "./interfaces/VideoPipelineError.js";

/*
 * VideoPipelineContainer sets up and stores a video pipeline, including:
 * - A Canvas or OffscreenCanvas and an associated WebGL context
 * - WebGL programs and associated textures, buffers, etc.
 * - References to models and code for calling them (TFLite, MediaPipe)
 * - Functions for executing the pipeline using the stored objects
 *
 * The WebGL context is permanently associated with the VideoPipelineContainer, so if this context
 * is lost or damaged, the entire VideoPipelineContainer must be torn down and can't be reused.
 *
 * VideoPipelineContainer can be used for any pipeline in the main thread or a worker thread.
 * The pipeline itself is created via a WebGLFactory passed to the VideoPipelineContainer
 * constructor.
 *
 * As long as the container returns isOK() true, it can be cached once a caller is done with it. On the
 * other hand, once it is not OK, it cannot be used again and must be destroyed.
 *
 */
export class VideoPipelineContainer implements IVideoPipelineContainer {
  public canvas: OffscreenCanvas | HTMLCanvasElement;
  public context: WebGL2RenderingContext | null;
  public pipeline: IVideoPipeline | undefined;

  private initialPipelineLoad: Promise<void> | undefined;
  private contextLostListener?: (e: Event) => void;
  private closed = false;
  private recreateWebgl = false;

  constructor(
    private glFactory: WebGLFactory,
    private offscreenCanvas: boolean
  ) {
    if (this.offscreenCanvas) {
      this.canvas = new OffscreenCanvas(1280, 720);
    } else {
      if (typeof WorkerGlobalScope !== "undefined" && self instanceof WorkerGlobalScope) {
        throw new PipelineSetupError("must use offscreenCanvas in web worker");
      }
      // eslint-disable-next-line no-restricted-globals
      this.canvas = document.createElement("canvas");
      // Size the canvas based on the width/height we request for the camera
      this.canvas.width = 1280;
      this.canvas.height = 720;
    }
    // Firefox wants you to get a context before creating the stream
    // For eslint: TypeScript seems to have definitions that should cause this type assertion to be
    // unnecessary as eslint says, but in fact it produces a TypeScript error
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    this.context = this.canvas.getContext("webgl2") as WebGL2RenderingContext | null;

    // NOTE: To test losing the WebGL context, uncomment this snippet
    // if (this.context) {
    //   const loseExt = this.context.getExtension("WEBGL_lose_context");
    //   setTimeout(() => {
    //     loseExt?.loseContext();
    //     setTimeout(() => loseExt?.restoreContext(), 1000);
    //   }, 5000);
    // }

    if (this.context) {
      // Listen for WebGL context lost/restored events
      this.contextLostListener = (e: Event) => {
        // Tell the browser to try to restore the context
        e.preventDefault();
        logger.warn(
          `VideoPipelineContainer: WebGL context lost: ${(e as WebGLContextEvent).statusMessage}`
        );
      };
      this.canvas.addEventListener("webglcontextlost", this.contextLostListener);

      this.canvas.addEventListener("webglcontextrestored", async (e) => {
        logger.warn(
          `VideoPipelineContainer: WebGL context restored: ${
            (e as WebGLContextEvent).statusMessage
          }`
        );
        this.recreateWebgl = true;
      });
    }

    this.setupWebGLPipeline();
  }

  public onScreenCanvas(): HTMLCanvasElement | undefined {
    if (this.offscreenCanvas) {
      return undefined;
    }
    return this.canvas as HTMLCanvasElement;
  }

  private setupWebGLPipeline() {
    if (this.initialPipelineLoad) return;

    this.initialPipelineLoad = (async () => {
      try {
        if (!this.context) {
          throw new PipelineSetupError("context not set");
        }
        this.pipeline = await this.glFactory(
          {
            width: this.canvas.width,
            height: this.canvas.height,
          },
          this.context
        );
      } catch (err) {
        this.pipeline?.cleanUp();
        this.pipeline = undefined;
        throw err;
      }
    })();
    this.initialPipelineLoad.catch((err) => {
      logger.warn({ err }, `Failed to load pipeline`);
    });
  }

  public async waitForPipeline(sourceDimensions: Dimensions): Promise<IVideoPipeline> {
    if (!this.context || this.initialPipelineLoad === undefined) {
      throw new PipelineSetupError(
        "Could not create WebGL context -- post-processing is not supported"
      );
    }
    if (this.closed) {
      throw new PipelineError("attempt to wait for closed pipeline");
    }
    await this.initialPipelineLoad;
    if (this.context.isContextLost()) {
      throw new WebGLContextLostError();
    }

    if (
      this.canvas.width !== sourceDimensions.width ||
      this.canvas.height !== sourceDimensions.height
    ) {
      // Size changed; need to recreate pipeline
      this.canvas.width = sourceDimensions.width;
      this.canvas.height = sourceDimensions.height;
      this.pipeline?.cleanUp();
      this.pipeline = undefined;
    }

    try {
      this.pipeline ??= await this.glFactory(
        {
          width: this.canvas.width,
          height: this.canvas.height,
        },
        this.context
      );
    } catch (err) {
      throw toPipelineErrorRequired(err);
    }

    return this.pipeline;
  }

  public async render(source: TexImageSource) {
    if (!this.pipeline) {
      throw new PipelineError("attempt to render uninitialized pipeline");
    }
    if (this.closed) {
      throw new PipelineError("attempt to render closed pipeline");
    }
    if (this.recreateWebgl) {
      throw new WebGLRecreateNeededError();
    }
    await this.pipeline.renderInternal(source);
  }

  public isOK(): boolean {
    if (!this.context || this.recreateWebgl || this.context.isContextLost() || this.closed) {
      return false;
    }

    try {
      // It appears it's possible for a canvas to be in such a bad state that a
      // VideoFrame cannot be constructed. If we are in that state, this
      // container cannot be used
      new VideoFrame(this.canvas, { timestamp: 0 }).close();
      return true;
    } catch {
      return false;
    }
  }

  public cleanup() {
    if (this.closed) {
      return;
    }
    this.closed = true;
    this.pipeline?.cleanUp();
    this.pipeline = undefined;
    void this.initialPipelineLoad
      ?.then(() => {
        this.pipeline?.cleanUp();
        this.pipeline = undefined;
      })
      .catch(() => {});

    if (this.contextLostListener) {
      // Stop trying to restore the context if it is lost
      this.canvas.removeEventListener("webglcontextlost", this.contextLostListener);
    }
  }

  public dump(): any {
    return {
      hasPipeline: !!this.pipeline,
      recreateWebgl: this.recreateWebgl,
    };
  }
}
