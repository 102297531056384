import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccessLinkDetails } from "../../../../shared/Models/AccessLinks/AccessLink.js";
import { DisplayProfile } from "../../../../shared/Models/DisplayProfile.js";
import { RootState } from "../../../store/reducers.js";
import { UserStatusSelectors } from "../../../userlocation/store/slices/userStatusSlice.js";
import { selectors as OccupantSelectors } from "./occupantSlice.js";
import { selectors as WorldSelectors } from "./worldSlice.js";

export interface PersonRoamPayload {
  roamId: number;
  personId: number;
}

export interface AlertHostPayload {
  visitorName: string;
}

export interface VisitorMemberAccess {
  occupantId: string;
  givenMemberAccess: boolean;
}

const slice = createSlice({
  name: "visiting",
  initialState: {
    currentLinkDetails: undefined as AccessLinkDetails | undefined,
    currentLinkErrorMessage: undefined as string | undefined,

    adHocProfile: {} as DisplayProfile,
    defaultProfile: {} as DisplayProfile,
  },
  reducers: {
    requestCurrentLinkDetails: () => {},
    setCurrentLinkDetails: (state, action: PayloadAction<AccessLinkDetails>) => {
      state.currentLinkDetails = action.payload;
    },
    clearCurrentLinkDetails: (state) => {
      state.currentLinkDetails = undefined;
    },
    setCurrentLinkErrorMessage: (state, action: PayloadAction<string>) => {
      state.currentLinkErrorMessage = action.payload;
    },
    clearCurrentLinkErrorMessage: (state) => {
      state.currentLinkErrorMessage = undefined;
    },

    setAdHocName: (state, action: PayloadAction<string | undefined>) => {
      state.adHocProfile.name = action.payload;
    },
    setAdHocProfile: (state, action: PayloadAction<DisplayProfile>) => {
      const { name, imageName, audioEntranceName, audioExitName } = action.payload;
      if (name) {
        state.adHocProfile.name = name;
      }
      if (imageName) {
        state.adHocProfile.imageName = imageName;
      }
      if (audioEntranceName) {
        state.adHocProfile.audioEntranceName = audioEntranceName;
      }
      if (audioExitName) {
        state.adHocProfile.audioExitName = audioExitName;
      }
    },
    restoreAdHocProfile: () => {},

    computeDefaultProfile: () => {},
    setDefaultProfile: (state, action: PayloadAction<DisplayProfile>) => {
      state.defaultProfile = action.payload;
    },

    setVisitorMemberAccess: (_state, _action: PayloadAction<VisitorMemberAccess>) => {},

    removeBlinders: (_state, _action: PayloadAction<string>) => {},
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.world.visiting;
export const selectors = {
  selectCurrentLinkDetails: (state: RootState) => selectSlice(state).currentLinkDetails,
  selectCurrentLinkErrorMessage: (state: RootState) => selectSlice(state).currentLinkErrorMessage,
  selectAdHocProfile: (state: RootState) => selectSlice(state).adHocProfile,
  selectDefaultProfile: (state: RootState) => selectSlice(state).defaultProfile,
  selectHostLocation: (state: RootState) => {
    const currentOccupant = WorldSelectors.selectActiveOccupant(state);
    if (!currentOccupant) return undefined;
    const host = OccupantSelectors.selectByPersonId(currentOccupant?.hostId)(state);
    if (!host) return undefined;
    const currentVisitingLink = selectors.selectCurrentLinkDetails(state);
    if (!currentVisitingLink) return undefined;
    return UserStatusSelectors.selectMostSpecificLocation({
      email: currentOccupant?.hostEmail,
      occupantId: host?.id,
      personId: currentVisitingLink?.hostPersonId,
    })(state);
  },
};

export const VisitingSelectors = selectors;
export const VisitingActions = actions;
