import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { ConfidentialMessageContent, ItemContent } from "../../../../shared/Models/ChatMessage.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

export interface ChatId {
  chatId: string;
  threadTimestamp?: number;
}

export interface SetChatHoveredPayload extends ChatId {
  hovered: boolean;
}

export interface UpsertPendingConfidentialTextPayload {
  messageId: string;
  text: string;
}

export interface DiscardPendingConfidentialTextsPayload {
  messageIds: string[];
}

export interface RequestConfidentialTextsPayload {
  windowKey?: WindowKey;
  chatId: string;
  messages: Array<{
    messageId: string;
    timestamp: number;
  }>;
}

export interface UpsertConfidentialTextsPayload {
  chatId: string;
  texts: ConfidentialText[];
}

export interface DiscardConfidentialTextsPayload {
  chatId: string;
}

export interface ConfidentialText {
  timestamp: number;
  text: string;
  items?: ItemContent[];
}

const textAdapter = createEntityAdapter<ConfidentialText>({
  selectId: (t) => t.timestamp,
});

const slice = createSlice({
  name: "confidential",
  initialState: {
    hoveredChats: [] as ChatId[],
    confidentialTexts: {} as { [chatKey: string]: EntityState<ConfidentialText> },
    pendingConfidentialTexts: {} as { [messageId: string]: string },
  },
  reducers: {
    setChatHovered: (state, action: PayloadAction<SetChatHoveredPayload>) => {
      const { chatId, threadTimestamp, hovered } = action.payload;

      const match = (c: ChatId) => c.chatId === chatId && c.threadTimestamp === threadTimestamp;

      if (hovered) {
        if (!state.hoveredChats.some(match)) {
          state.hoveredChats.push({ chatId, threadTimestamp });
        }
      } else {
        state.hoveredChats = state.hoveredChats.filter((c) => !match(c));
      }
    },

    upsertPendingConfidentialText: (
      state,
      action: PayloadAction<UpsertPendingConfidentialTextPayload>
    ) => {
      const { messageId, text } = action.payload;
      state.pendingConfidentialTexts[messageId] = text;
    },

    discardPendingConfidentialTexts: (
      state,
      action: PayloadAction<DiscardPendingConfidentialTextsPayload>
    ) => {
      for (const messageId of action.payload.messageIds) {
        delete state.pendingConfidentialTexts[messageId];
      }
    },

    requestConfidentialTexts: (state, action: PayloadAction<RequestConfidentialTextsPayload>) => {},

    upsertConfidentialTexts: (state, action: PayloadAction<UpsertConfidentialTextsPayload>) => {
      const { chatId, texts } = action.payload;
      const chatTexts = state.confidentialTexts[chatId];
      if (chatTexts) {
        textAdapter.upsertMany(chatTexts, texts);
      } else {
        state.confidentialTexts[chatId] = textAdapter.upsertMany(
          textAdapter.getInitialState(),
          texts
        );
      }
    },

    discardConfidentialTexts: (state, action: PayloadAction<DiscardConfidentialTextsPayload>) => {
      const { chatId } = action.payload;
      const chatTexts = state.confidentialTexts[chatId];
      if (chatTexts) {
        textAdapter.removeAll(chatTexts);
      }
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.chat.confidential
);

const adapterSelectors = textAdapter.getSelectors();

export const selectors = {
  selectChatHovered: defaultMemoize(
    (chatId?: string, threadTimestamp?: number) =>
      createDeepEqualSelector(selectSlice, (state) => {
        if (!chatId) return false;
        return state.hoveredChats.some(
          (c) => c.chatId === chatId && c.threadTimestamp === threadTimestamp
        );
      }),
    {
      maxSize: 5,
    }
  ),

  selectPendingConfidentialText: defaultMemoize(
    (messageId?: string) =>
      createDeepEqualSelector(selectSlice, (state) => {
        if (!messageId) return undefined;
        return state.pendingConfidentialTexts[messageId];
      }),
    {
      maxSize: 5,
    }
  ),

  selectConfidentialMessage: defaultMemoize(
    (
      chatId?: string,
      timestamp?: number
    ): ((state: RootState) => ConfidentialMessageContent | undefined) =>
      createDeepEqualSelector(selectSlice, (state) => {
        if (!chatId || !timestamp) return undefined;

        const chatTexts = state.confidentialTexts[chatId];
        if (!chatTexts) return undefined;

        return adapterSelectors.selectById(chatTexts, timestamp);
      }),
    {
      maxSize: 50,
    }
  ),

  selectHasConfidentialTexts: defaultMemoize(
    (chatId?: string, timestamps?: number[]) =>
      createDeepEqualSelector(selectSlice, (state) => {
        if (!chatId || !timestamps) return [];

        const chatTexts = state.confidentialTexts[chatId];
        if (!chatTexts) return [];

        return timestamps.filter(
          (timestamp) => adapterSelectors.selectById(chatTexts, timestamp) !== undefined
        );
      }),
    { maxSize: 5 }
  ),
};

export const ConfidentialActions = actions;
export const ConfidentialSelectors = selectors;
