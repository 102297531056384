import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { CryptoAddress } from "../../../../shared/Models/CryptoAddress.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export const selectId = (cryptoAddress: { email: string; address: string }) =>
  `${cryptoAddress.email}:${cryptoAddress.address}`;

export interface RemoveCryptoAddressPayload {
  email: string;
  address: string;
}

const adapter = createEntityAdapter<CryptoAddress>({ selectId });

const slice = createSlice({
  name: "cryptoAddress",
  initialState: adapter.getInitialState(),

  reducers: {
    requestMyCryptoAddresses: () => {},
    serverRemoveCryptoAddress: (state, _: PayloadAction<RemoveCryptoAddressPayload>) => {},

    upsertCryptoAddresses: adapter.upsertMany.bind(adapter),
    upsertCryptoAddress: adapter.upsertOne.bind(adapter),
    removeCryptoAddress: adapter.removeOne.bind(adapter),
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
};

const adapterSelectors = adapter.getSelectors((state: RootState) => state.anyWorld.cryptoAddresses);
export const selectors = {
  selectMyCryptoAddressesByEmail: defaultMemoize((email?: string) =>
    createDeepEqualSelector(
      (state: RootState) => state,
      (state) =>
        email
          ? adapterSelectors.selectAll(state).filter((address) => address.email === email)
          : undefined
    )
  ),
};
