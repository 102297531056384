import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ClientSurveyTrigger } from "../../../../shared/Models/SurveyTriggers.js";
import { SurveyQuestion, SurveyResponse } from "../../../../shared/Models/Surveys.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const slice = createSlice({
  name: "survey",
  initialState: {
    queuedSurveys: [] as SurveyQuestion[],
  },
  reducers: {
    queueSurvey: (state, action: PayloadAction<SurveyQuestion>) => {
      const newSurvey = action.payload;

      // Remove any survey with the same ID.
      const newQueuedSurveys = state.queuedSurveys.filter((survey) => survey.id !== newSurvey.id);

      newQueuedSurveys.push(newSurvey);
      state.queuedSurveys = newQueuedSurveys;
    },
    popSurvey: (state, action: PayloadAction) => {
      state.queuedSurveys = state.queuedSurveys.slice(1);
    },

    maybeTriggerSurvey: (state, action: PayloadAction<ClientSurveyTrigger>) => {},
    completeSurvey: (state, action: PayloadAction<SurveyResponse>) => {},
  },
});

export const { actions, reducer } = slice;
export const SurveyActions = actions;
export const SurveyReducer = reducer;

const selectSlice = (state: RootState) => state.anyWorld.survey;
export const selectors = {
  selectActiveSurvey: createSelector(selectSlice, (slice) => slice.queuedSurveys[0]),
};
export const SurveySelectors = selectors;
