import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { z } from "zod";
import {
  evaluateFlag,
  ExtractFlagReturnType,
  Flag,
  FlagState,
  FlagValue,
  isUnknownFlag,
  UnknownFlag,
} from "../../../shared/featureflags/RoamFlagConfigs.js";
import { createSelector } from "../../helpers/redux.js";
import { RootState } from "../reducers.js";

// WARNING: Any edits should likely be ported to settingsFeatureFlagSlice too

const adapter = createEntityAdapter<FlagState>({
  selectId: (flag) => flag.key,
});

const overrideAdapter = createEntityAdapter<FlagState>({
  selectId: (flag) => flag.key,
});

const unknownFlagsAdapter = createEntityAdapter<UnknownFlag>({
  selectId: (flag) => flag.key,
});

const meetingOverrideAdapter = createEntityAdapter<FlagState>({
  selectId: (flag) => flag.key,
});

export interface FeatureFlagContext {
  roamId: number;
  launchDarkyHash: string;
}

export const FlagsUpdate = z.object({
  flags: FlagState.array(),
  unknownFlags: UnknownFlag.array(),
  fromCache: z.boolean().optional(),
});
export type FlagsUpdate = z.infer<typeof FlagsUpdate>;

const slice = createSlice({
  name: "featureFlags",
  initialState: {
    globalHash: undefined as string | undefined,
    context: undefined as FeatureFlagContext | undefined,
    flags: adapter.getInitialState(),
    localFlagOverrides: overrideAdapter.getInitialState(),
    unknownFlags: unknownFlagsAdapter.getInitialState(),
    meetingFlagOverrides: meetingOverrideAdapter.getInitialState(),
    flagsSynced: false as boolean,
    loadedCachedFlags: false as boolean,
  },
  reducers: {
    /**
     * Initializes global feature flags.
     */
    initializeGlobal: (state, action: PayloadAction<string>) => {
      state.globalHash = action.payload;
    },
    initialize: (state, action: PayloadAction<FeatureFlagContext>) => {
      state.context = action.payload;
      state.flagsSynced = false;
      // NOTE: We don't clear flags because we may have loaded cached flags from storage.
      // Wait until we actually get flags from the server to change anything.
    },
    resetLocalOverrides: (state) => {
      overrideAdapter.removeAll(state.localFlagOverrides);
    },
    updateFlags: (state, action: PayloadAction<FlagsUpdate>) => {
      adapter.upsertMany(state.flags, action.payload.flags);
      unknownFlagsAdapter.removeAll(state.unknownFlags);
      unknownFlagsAdapter.upsertMany(state.unknownFlags, action.payload.unknownFlags);
      state.flagsSynced = true;
      if (action.payload.fromCache) {
        state.loadedCachedFlags = true;
      }
    },
    updateLocalOverrides: (state, action: PayloadAction<FlagState[]>) => {
      overrideAdapter.upsertMany(state.localFlagOverrides, action.payload);
    },
    setMeetingFlagOverrides: (state, action: PayloadAction<FlagState[] | undefined>) => {
      if (action.payload !== undefined) {
        meetingOverrideAdapter.setAll(state.meetingFlagOverrides, action.payload);
      } else {
        meetingOverrideAdapter.removeAll(state.meetingFlagOverrides);
      }
    },
    setFlagsSynced: (state) => {
      state.flagsSynced = true;
    },
    syncFlags: (_) => {},
    loadCachedFlags: () => {},
  },
});

export const { actions, reducer } = slice;

const adapterSelectors = adapter.getSelectors(
  (featureFlags: ReturnType<typeof reducer>) => featureFlags.flags
);
const overrideSelectors = overrideAdapter.getSelectors(
  (featureFlags: ReturnType<typeof reducer>) => featureFlags.localFlagOverrides
);
const meetingOverrideSelectors = meetingOverrideAdapter.getSelectors(
  (featureFlags: ReturnType<typeof reducer>) => featureFlags.meetingFlagOverrides
);
const unknownFlagSelectors = unknownFlagsAdapter.getSelectors(
  (featureFlags: ReturnType<typeof reducer>) => featureFlags.unknownFlags
);
export const selectors = {
  selectFlag: defaultMemoize(
    <F extends Flag<any, any>>(flag: F) =>
      createSelector(
        (state: RootState) => state.featureFlags,
        (featureFlags): ExtractFlagReturnType<F> => {
          // Check to see if we have a local override for this flag.
          // NOTE: If the override is the wrong type, ignore it.
          // Priority is local override, then meeting override, then the real flag value,
          // followed by the default value.
          const override = evaluateFlag(
            flag,
            overrideSelectors.selectById(featureFlags, flag.key)?.value,
            undefined
          );
          if (override !== undefined) {
            return override as ExtractFlagReturnType<F>;
          }
          const meetingOverride = evaluateFlag(
            flag,
            meetingOverrideSelectors.selectById(featureFlags, flag.key)?.value,
            undefined
          );
          if (meetingOverride !== undefined) {
            return meetingOverride as ExtractFlagReturnType<F>;
          }

          // No override exists, so return the real flag value.
          const defaultValue = evaluateFlag(
            flag,
            flag.defaultValue as FlagValue,
            flag.defaultValue
          );
          return evaluateFlag(
            flag,
            adapterSelectors.selectById(featureFlags, flag.key)?.value,
            defaultValue
          );
        }
      ),
    { maxSize: 50 }
  ),
  selectFlagsSynced: (state: RootState) => state.featureFlags.flagsSynced,
  selectGlobalHash: (state: RootState) => state.featureFlags.globalHash,
  selectContext: (state: RootState) => state.featureFlags.context,
  selectUnknownFlags: createSelector(
    (state: RootState) => state.featureFlags,
    (featureFlags): UnknownFlag[] => {
      return unknownFlagSelectors.selectAll(featureFlags).map((unknownFlag) => {
        const meetingOverride: FlagState | undefined = meetingOverrideSelectors.selectById(
          featureFlags,
          unknownFlag.key
        );
        if (meetingOverride !== undefined) {
          return meetingOverride;
        }
        return unknownFlag;
      });
    }
  ),
  selectHasCachedFlags: (state: RootState) => state.featureFlags.loadedCachedFlags,
  selectFlagsToCache: (state: RootState): FlagsUpdate => ({
    flags: adapterSelectors.selectAll(state.featureFlags),
    unknownFlags: unknownFlagSelectors.selectAll(state.featureFlags),
  }),
};
export const FeatureFlagActions = actions;
export const FeatureFlagSelectors = selectors;

export const isFlagsUpdate = (val: unknown): val is FlagsUpdate => {
  if (typeof val !== "object") return false;
  const maybeFlagsUpdate = val as FlagsUpdate;
  if (!Array.isArray(maybeFlagsUpdate.flags) || !Array.isArray(maybeFlagsUpdate.unknownFlags)) {
    return false;
  }
  for (const maybeFlagsState of maybeFlagsUpdate.flags) {
    if (!FlagState.safeParse(maybeFlagsState).success) {
      return false;
    }
  }
  for (const maybeUnknownFlag of maybeFlagsUpdate.unknownFlags) {
    if (!isUnknownFlag(maybeUnknownFlag)) {
      return false;
    }
  }
  return true;
};
