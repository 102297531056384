import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { Roam } from "../../../../shared/Models/Roam.js";
import { RoamThemeOverrides } from "../../../../shared/Models/RoamThemeOverrides.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const adapter = createEntityAdapter<Roam>();

export interface PrimaryImageUploadCompletePayload {
  id: number;
  primaryImageName: string;
}

export interface ImageUploadCompletePayload {
  id: number;
  imageName: string;
  inverseImageName?: string;
}

export interface RequestCreatePayload {
  name: string;
  displayName: string;
  accountId: number;
}

export interface RequestDeletePayload {
  roamId: number;
}

export interface CreateRoamResult {
  success: boolean;
  errorMessage: string | undefined;
  roamId?: number;
  launchDarklyHash?: string;
}

const slice = createSlice({
  name: "roam",
  initialState: {
    roams: adapter.getInitialState(),
    createResult: undefined as CreateRoamResult | undefined,
    roamsLoaded: false,
    deletedRoams: [] as Roam[],
  },
  reducers: {
    setAll: (state, action: PayloadAction<Roam[]>) => {
      adapter.setAll(state.roams, action.payload);
      state.roamsLoaded = true;
    },
    upsertMany: (state, action: PayloadAction<Roam[]>) => {
      // Custom implementation instead of adapter.upsertMany because when roam.parcelHexId was being
      // set to undefined, the value wasn't being updated on existing roams

      const roams = action.payload;
      for (const roam of roams) {
        if (!roam.id) {
          continue;
        }
        if (state.roams.entities[roam.id]) {
          state.roams.entities[roam.id] = roam;
        } else {
          adapter.addOne(state.roams, roam);
        }
      }
      state.roamsLoaded = true;
    },
    removeRoam: (state, action: PayloadAction<Roam>) => {
      adapter.removeOne(state.roams, action.payload.id);
      state.deletedRoams.push(action.payload);
    },
    requestRoams: (_) => {},
    setPrimaryImageName: (state, action: PayloadAction<PrimaryImageUploadCompletePayload>) => {},
    setImageNames: (state, action: PayloadAction<ImageUploadCompletePayload>) => {},
    requestCreate: (_, action: PayloadAction<RequestCreatePayload>) => {},
    setCreateResult: (state, action: PayloadAction<CreateRoamResult>) => {
      state.createResult = action.payload;
    },
    requestDelete: (_, action: PayloadAction<RequestDeletePayload>) => {},
    clearCreateResult: (state) => {
      state.createResult = undefined;
    },
    setDisplayName: (state, action: PayloadAction<{ roamId: number; displayName: string }>) => {},
    setDefaultHomeFloorId: (
      _,
      action: PayloadAction<{ roamId: number; defaultHomeFloorId: number }>
    ) => {},
    setThemeOverrides: (
      _,
      action: PayloadAction<{ roamId: number; themeOverrides: RoamThemeOverrides }>
    ) => {},
    setDefaultAudioEntranceName: (
      _,
      action: PayloadAction<{ roamId: number; defaultAudioEntranceName: string }>
    ) => {},
    setDefaultAudioExitName: (
      _,
      action: PayloadAction<{ roamId: number; defaultAudioExitName: string }>
    ) => {},
    setForceAudioEntranceTone: (
      _,
      action: PayloadAction<{ roamId: number; forceAudioEntranceTone: boolean }>
    ) => {},
    setForceAudioExitTone: (
      _,
      action: PayloadAction<{ roamId: number; forceAudioExitTone: boolean }>
    ) => {},
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.anyWorld.roam;
const adapterSelectors = adapter.getSelectors();
export const selectors = {
  selectAll: createDeepEqualSelector(selectSlice, (slice) =>
    adapterSelectors.selectAll(slice.roams)
  ),
  selectById: (id?: number) => (state: RootState) =>
    id ? adapterSelectors.selectById(selectSlice(state).roams, id) : undefined,
  selectByIds: defaultMemoize(
    (ids: number[]) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        const idSet = new Set(ids);
        return adapterSelectors.selectAll(slice.roams).filter((roam) => idSet.has(roam.id));
      }),
    { equalityCheck: equal }
  ),
  selectByAccountIds: defaultMemoize(
    (ids: number[]) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        const idSet = new Set(ids);
        return adapterSelectors.selectAll(slice.roams).filter((roam) => idSet.has(roam.accountId));
      }),
    { equalityCheck: equal }
  ),
  selectByAccountId: defaultMemoize((accountId: number) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      adapterSelectors.selectAll(slice.roams).filter((roam) => roam.accountId === accountId)
    )
  ),
  selectCreateResult: (state: RootState) => selectSlice(state).createResult,
  selectRoamsLoaded: (state: RootState) => selectSlice(state).roamsLoaded,
  selectDeletedRoams: (state: RootState) => selectSlice(state).deletedRoams,
};

export const RoamSelectors = selectors;
export const RoamActions = actions;
