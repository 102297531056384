import { FaceLandmarker } from "@mediapipe/tasks-vision";
import { isDevMode, isElectron } from "../../../../../shared/api/environment.js";
import { logger } from "../../../../../shared/infra/logger.js";
import {
  PipelineError,
  TouchupPipelineError,
  plainToPipelineError,
  toTouchupPipelineErrorRequired,
} from "../../interfaces/VideoPipelineError.js";
import { IFaceMeshWorkerProxy } from "./IFaceMeshWorkerProxy.js";
import { FaceMeshMainMessage, FaceMeshWorkerMessage } from "./faceMeshWorkerMessages.js";

const FramesSkippedIntervalMs = 5 * 1000; // 5s
// If more than this many frames are skipped in an interval, consider it an error and disable the
// pipeline
const FramesSkippedErrorThreshold = 10;

interface Resolver {
  resolve: () => void;
  reject: (err: any) => void;
}

export class FaceMeshWorkerProxy implements IFaceMeshWorkerProxy {
  private faceLandmarker?: FaceLandmarker;
  private worker: Worker;
  private initPromise: Promise<void>;
  private initResolver?: Resolver;
  private initTimeout?: NodeJS.Timeout;
  private initialized = false;
  private closed = false;

  private latestResults?: Float32Array;
  private latestResultsTimestamp = 0;
  private pendingResultsTimestamp?: number;

  private lastError: PipelineError | undefined;

  constructor() {
    this.worker = new Worker(
      isDevMode || isElectron ? "./faceMeshWorker.js" : `./faceMeshWorker-${__version}.js`
    );
    this.worker.onmessage = (event: MessageEvent<FaceMeshWorkerMessage>) =>
      this.handleWorkerMessage(event);
    this.worker.onerror = (err) => this.handleWorkerError(err);

    this.initPromise = new Promise<void>((resolve, reject) => {
      this.initResolver = {
        resolve,
        reject,
      };
    });
    this.initTimeout = setTimeout(() => {
      if (this.initResolver) {
        logger.error("initializing worker timed out");
        this.initResolver.reject("initialization timed out");
      }
    }, 5000);
    this.worker.postMessage({ message: "init" } as FaceMeshMainMessage);
  }

  // Initialization begins in the constructor but finishes asynchronously. Callers should
  // call this method and wait for it to resolve before using the worker.
  public async waitForWorker(): Promise<void> {
    if (this.closed) {
      throw new Error("cannot wait for closed worker");
    }
    return this.initPromise;
  }

  private handleWorkerMessage(event: MessageEvent<FaceMeshWorkerMessage>) {
    const data = event.data;
    switch (data.message) {
      case "initComplete": {
        this.initialized = true;
        if (this.initResolver) {
          this.initResolver.resolve();
        }
        this.initResolver = undefined;
        if (this.initTimeout) {
          clearTimeout(this.initTimeout);
          this.initTimeout = undefined;
        }
        break;
      }
      case "initFailed": {
        if (this.initResolver) {
          this.initResolver.reject(plainToPipelineError(data.err));
          this.initResolver = undefined;
        }
        if (this.initTimeout) {
          clearTimeout(this.initTimeout);
          this.initTimeout = undefined;
        }
        break;
      }
      case "pipelineError": {
        const error = plainToPipelineError(data.err);
        logger.error({ error }, "FaceMeshWorker error");
        break;
      }
      case "results":
        if (data.results) {
          this.latestResults = new Float32Array(data.results);
          this.latestResultsTimestamp = data.timestamp;
        }
        this.pendingResultsTimestamp = undefined;
        performance.mark("VideoPipelineFace-end");
        performance.measure(
          "VideoPipelineFace",
          "VideoPipelineFace-start",
          "VideoPipelineFace-end"
        );
        break;
    }
  }

  private postMessage(msg: FaceMeshMainMessage, transfer: Transferable[] = []) {
    this.worker.postMessage(msg, transfer);
  }

  private handleWorkerError(event: ErrorEvent) {
    logger.error({ event }, "FaceMeshWorker error");

    const error = toTouchupPipelineErrorRequired(event.error);
    if (this.initResolver) {
      this.initResolver.reject(error);
      this.initResolver = undefined;
    }
    if (this.initTimeout) {
      clearTimeout(this.initTimeout);
      this.initTimeout = undefined;
    }

    // If we're not initializing when the error occurs, it will be thrown if (and when)
    // infer() is called. Otherwise we'll see the error in the logs but it has probably
    // not affected the pipeline.
    this.lastError = error;
  }

  public infer(frame: VideoFrame): void {
    if (!this.initialized) {
      throw new TouchupPipelineError("worker is not initialized");
    }
    if (this.closed) {
      throw new TouchupPipelineError("worker is closed");
    }
    if (this.lastError) {
      throw this.lastError;
    }
    if (this.pendingResultsTimestamp) {
      try {
        this.frameSkipped(frame.timestamp);
        return;
      } finally {
        frame.close();
      }
    }
    this.pendingResultsTimestamp = frame.timestamp;
    performance.mark("VideoPipelineFace-start");
    this.postMessage({ message: "infer", frame }, [frame]);
  }

  public getLatestResults(): Float32Array | undefined {
    return this.latestResults;
  }

  private framesSkipped = 0;
  private framesSkippedIntervalStart: number | undefined;
  private frameSkipped(timestamp: number) {
    if (
      !this.framesSkippedIntervalStart ||
      Date.now() - this.framesSkippedIntervalStart > FramesSkippedIntervalMs
    ) {
      this.framesSkippedIntervalStart = Date.now();
      this.framesSkipped = 0;
    }
    this.framesSkipped++;

    if (this.framesSkipped > FramesSkippedErrorThreshold) {
      throw new TouchupPipelineError("FaceMeshWorker skipped too many frames and cannot keep up");
    }

    logger.warn(
      `FaceMeshWorkerProxy: discarding request for ${timestamp} because ${this.pendingResultsTimestamp} pending`
    );
  }

  public terminate() {
    this.postMessage({ message: "stop" });
    this.closed = true;
    this.worker.terminate();
  }
}
