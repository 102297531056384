/* cspell:ignore ection */
import { z } from "zod";

/**
 * A type of Section, identified solely by FloorId
 * For now in an auditorium, all Stage and Backstage members
 * are in the same FloorSection (this may or may not change)
 *
 * Not to be confused with a section of an audience. That's actually
 * another type of Section: FloorSection and AudienceSection
 */
export const FloorSection = z.object({
  kind: z.literal("floor"),
  floorId: z.number().int().nonnegative(),
});
export type FloorSection = z.infer<typeof FloorSection>;

/**
 * A type of Section, identified by floorId, roomId and section number (within the audience,
 * not to be confused with capital-S *S*ection, which is a superclass of AudienceSection)
 */
export const AudienceSection = z.object({
  kind: z.literal("audience"),
  floorId: z.number().int().nonnegative(),
  roomId: z.number().int().nonnegative(),
  sectionNumber: z.number().int().nonnegative(),
});
export type AudienceSection = z.infer<typeof AudienceSection>;

/**
 * Either a FloorSection or an AudienceSection. These are the atomic units managed by section servers
 */
export const Section = z.discriminatedUnion("kind", [FloorSection, AudienceSection]);
export type Section = z.infer<typeof Section>;
