import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { ChatKeyString, getChatKey, itemIdsFromMessage } from "../../../../shared/helpers/chat.js";
import { GroupMention, UserMention } from "../../../../shared/helpers/mentions.js";
import { logger } from "../../../../shared/infra/logger.js";
import { ChatAddress } from "../../../../shared/Models/ChatAddress.js";
import {
  ChatMessageContent,
  ItemContent,
  MessageSendStatus,
  PendingChatMessage,
  SentChatMessage,
  TextContent,
} from "../../../../shared/Models/ChatMessage.js";
import { Group } from "../../../../shared/Models/Group.js";
import {
  findItemsInRange,
  insertRange,
  NumberRange,
  NumberRanges,
  rangeContaining,
} from "../../../../shared/ranges/ranges.js";
import { TypeNarrowError } from "../../../../shared/TypeNarrowError.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";
import { isUnread, selectSlice as selectChatStatusSlice } from "./chatStatusSlice.js";

/*
 * Messages are assigned a canonical timestamp by the server as part of message delivery, and once
 * that timestamp is present (chatId, timestamp) is used as the identifier for the message. Messages
 * that have been produced on the client but not delivered yet do not have this identifier, so
 * instead they are identified by their string messageId, a GUID assigned by the client. In some
 * cases, we need to refer either to a pending message or a delivered message in the same context,
 * and in this case we use CombinedMessageId. If it's a number, it refers to a timestamp of a
 * delivered message, and if a string it is the messageId of a pending message. This is a bit
 * yucky but avoids the additional overhead of creating an object for each messageId.
 */
export type CombinedMessageId = number | string;

export const isPendingMessageId = (id: CombinedMessageId) => typeof id === "string";

export type MessageContextMenu = MentionContextMenu;

export interface MentionContextMenu {
  type: "mention";
  group: Group;
  missingMentions: UserMention[];
  mentionedGroup?: GroupMention;
  hasPermission: boolean;
  membersOfRoam: boolean;
}

export interface SendMessagePayload {
  chatId: string;
  threadTimestamp?: number;
  messageId: string;
  content: ChatMessageContent;
  confidentialText?: string;
  confidentialItems?: ItemContent[];
}

export interface UpdatePendingSendStatusPayload {
  chatId: string;
  threadTimestamp: number | undefined; // if message is in a thread
  messageId: string;
  sendStatus: MessageSendStatus;
}

export interface RetryPendingMessagePayload {
  chatId: string;
  threadTimestamp: number | undefined;
  messageId: string;
}

// Must all be in the same chat
export interface SetMessagesPayload {
  chatId: string;
  threadTimestamp?: number; // if in a thread
  messages: SentChatMessage[];
  timestampRange?: NumberRange;
  fromRealTimeMessage?: boolean;
  chatMutedByRecipient?: boolean;
}

export interface MessageReceivedPayload {
  message: SentChatMessage;
  senderAddress: ChatAddress;
  chatMutedByRecipient: boolean;
  extraAddresses?: ChatAddress[];
  fromNotification: boolean;
  subscribedToThread?: boolean;
}

export interface RemoveMessagePayload {
  chatId: string;
  threadTimestamp: number | undefined;
  timestamp: number;
  skipProcessing?: boolean;
}

export interface SubscribeOrRequestMessagesPayload {
  chatId: string;
  threadTimestamp?: number;

  // Position to load messages
  timestamp?: number;
  direction: "before" | "around" | "onOrAfter";

  // If true, start loading messages from the beginning and subscribe
  subscribe?: boolean;
  subscribeMessages?: boolean;
}

export interface ChatOrThreadId {
  chatId: string;
  threadTimestamp?: number;
}

export interface DiscardOldestMessagesPayload {
  chatId: string;
  threadTimestamp?: number;
  retainCount: number;
}

export interface DiscardAllMessagesPayload {
  chatId: string;
  threadTimestamp?: number;
}

export interface DiscardMessagesPayload {
  chatId: string;
  threadTimestamp?: number;
  discardTimestamps: number[];
}

export interface SingleMessageReceivedPayload {
  message: SentChatMessage;
  senderAddress: ChatAddress;
  chatMutedByRecipient?: boolean;
  soundAllowed: boolean;
}

export interface DeleteMessagePayload {
  message: SentChatMessage;
  windowKey?: WindowKey;
}

export interface EditMessagePayload {
  message: SentChatMessage;
  newContent: TextContent;
  windowKey?: WindowKey;
}

export interface AddContextMenuPayload {
  messageId: string;
  menu: MessageContextMenu;
}

export interface RemoveContextMenu {
  messageId: string;
  menuIndex: number;
}

export interface DismissPreviewPayload {
  chatId: string;
  threadTimestamp?: number;
  timestamp: number;
}

const messageAdapter = createEntityAdapter<SentChatMessage>({
  selectId: (m) => m.timestamp,
  sortComparer: (a, b) => {
    if (!a.timestamp || !b.timestamp || a.timestamp === b.timestamp) return 0;
    if (a.timestamp > b.timestamp) return 1;
    return -1;
  },
});

const pendingMessageAdapter = createEntityAdapter<PendingChatMessage>({
  selectId: (m) => m.messageId,
  sortComparer: (a, b) => {
    if (!a.clientTimestamp || !b.clientTimestamp || a.clientTimestamp === b.clientTimestamp)
      return 0;
    if (a.clientTimestamp > b.clientTimestamp) return 1;
    return -1;
  },
});

const setMessages = (
  state: MessageSlice,
  chatId: string,
  threadTimestamp: number | undefined,
  messages: SentChatMessage[],
  range?: NumberRange
) => {
  const chatKey = getChatKey(chatId, threadTimestamp);
  const chatMessages = state.messages[chatKey];
  if (chatMessages) {
    const filteredMessages = messages.filter((m) => {
      const existingVersion = chatMessages.messages.entities[m.timestamp]?.version;
      return !existingVersion || m.version > existingVersion;
    });

    chatMessages.messages = messageAdapter.setMany(chatMessages.messages, filteredMessages);
    if (range) {
      chatMessages.ranges = insertRange(chatMessages.ranges, range);
    }
    chatMessages.pendingMessages = pendingMessageAdapter.removeMany(
      chatMessages.pendingMessages,
      filteredMessages.map((msg) => msg.messageId)
    );
  } else {
    state.messages[chatKey] = {
      ranges: range ? [range] : [],
      messages: messageAdapter.setAll(messageAdapter.getInitialState(), messages),
      pendingMessages: pendingMessageAdapter.getInitialState(),
    };
  }
};

export interface Messages {
  // The ranges of loaded messages. These are not guaranteed to be up-to-date (edits, reactions,
  // thread summaries etc.) but they are contiguous and can be displayed.
  ranges: NumberRanges;

  messages: EntityState<SentChatMessage>;
  pendingMessages: EntityState<PendingChatMessage>;
}

const emptyMessageState = (): Messages => ({
  ranges: [],
  messages: messageAdapter.getInitialState(),
  pendingMessages: pendingMessageAdapter.getInitialState(),
});

const initialState = {
  messages: {} as { [chatKey: string]: Messages },
  contextMenus: {} as { [messageId: string]: MessageContextMenu[] },
};

const slice = createSlice({
  name: "message",
  initialState,
  reducers: {
    resetState: () => initialState,
    sendMessage: (state, action: PayloadAction<SendMessagePayload>) => {},
    retryPendingMessage: (state, action: PayloadAction<RetryPendingMessagePayload>) => {},
    deleteMessage: (state, action: PayloadAction<DeleteMessagePayload>) => {},
    editMessage: (state, action: PayloadAction<EditMessagePayload>) => {},
    messageDeleted: (state, action: PayloadAction<DeleteMessagePayload>) => {
      const { message } = action.payload;
      if (!message.chatId) return;
      const chatKey = getChatKey(message.chatId, message.threadTimestamp);
      const chatMessages = state.messages[chatKey];
      if (chatMessages) {
        chatMessages.messages = messageAdapter.setOne(chatMessages.messages, {
          ...message,
          content: { contentType: "deleted" },
        });
      }
    },
    removeMessage: (state, action: PayloadAction<RemoveMessagePayload>) => {
      const { chatId, threadTimestamp, timestamp } = action.payload;
      const chatKey = getChatKey(chatId, threadTimestamp);
      const chatMessages = state.messages[chatKey];
      if (chatMessages) {
        chatMessages.messages = messageAdapter.removeOne(chatMessages.messages, timestamp);
      }
    },
    discardOldestMessages: (state, action: PayloadAction<DiscardOldestMessagesPayload>) => {
      const { chatId, threadTimestamp, retainCount } = action.payload;
      const chatKey = getChatKey(chatId, threadTimestamp);
      const chatMessages = state.messages[chatKey];
      if (chatMessages) {
        const deleteThroughIndex = chatMessages.messages.ids.length - retainCount;
        if (deleteThroughIndex > 0) {
          const deleteIds = chatMessages.messages.ids.slice(0, deleteThroughIndex);
          chatMessages.messages = messageAdapter.removeMany(chatMessages.messages, deleteIds);
        }
      }
    },
    discardAllMessages: (state, action: PayloadAction<DiscardAllMessagesPayload>) => {
      const { chatId, threadTimestamp } = action.payload;
      const chatKey = getChatKey(chatId, threadTimestamp);
      const chatMessages = state.messages[chatKey];
      if (chatMessages) {
        chatMessages.messages = messageAdapter.removeAll(chatMessages.messages);
      }
    },
    discardMessages: (state, action: PayloadAction<DiscardMessagesPayload>) => {
      const { chatId, threadTimestamp, discardTimestamps } = action.payload;
      const chatKey = getChatKey(chatId, threadTimestamp);
      const chatMessages = state.messages[chatKey];
      if (chatMessages && discardTimestamps.length > 0) {
        chatMessages.messages = messageAdapter.removeMany(chatMessages.messages, discardTimestamps);
      }
    },
    setPendingMessage: (state, action: PayloadAction<PendingChatMessage>) => {
      const message = action.payload;
      const { chatId, threadTimestamp } = message;

      const chatKey = getChatKey(chatId, threadTimestamp);
      let chatMessages = state.messages[chatKey];
      if (!chatMessages) {
        chatMessages = emptyMessageState();
      }

      // If the corresponding SentChatMessage is already in state, discard the pending message to
      // avoid a race where both end up in state.
      if (
        messageSelectors
          .selectAll(chatMessages.messages)
          .find((m) => m.messageId === message.messageId)
      ) {
        return;
      }

      chatMessages.pendingMessages = pendingMessageAdapter.setOne(
        chatMessages.pendingMessages,
        message
      );
      state.messages[chatKey] = chatMessages;
    },
    setMessages: (state, action: PayloadAction<SetMessagesPayload>) => {
      const { chatId, threadTimestamp, messages, timestampRange } = action.payload;
      setMessages(state, chatId, threadTimestamp, messages, timestampRange);
    },
    messageReceived: (state, action: PayloadAction<MessageReceivedPayload>) => {
      const { message } = action.payload;
      setMessages(state, message.chatId, message.threadTimestamp, [message]);
    },

    // CAUTION: this reducer does not update last message times on chats and does not move pending
    // messages to sent. It should only be used to populate messages one-off when needed for direct
    // lookup (e.g. in setting parent messages for thread summaries), not when messages are
    // retrieved for a chat or received from the server.
    setMessagesBypassingProcessing: (state, action: PayloadAction<SentChatMessage[]>) => {
      for (const message of action.payload) {
        const { chatId, threadTimestamp } = message;
        const chatKey = getChatKey(chatId, threadTimestamp);
        let chatMessages = state.messages[chatKey];
        if (!chatMessages) {
          chatMessages = emptyMessageState();
        }
        chatMessages.messages = messageAdapter.setOne(chatMessages.messages, message);
        state.messages[chatKey] = chatMessages;
      }
    },
    clearMessagesForChatId: (state, action: PayloadAction<ChatOrThreadId>) => {
      const { chatId, threadTimestamp } = action.payload;
      delete state.messages[getChatKey(chatId, threadTimestamp)];
    },
    maybeSendNativeNotification: (state, action: PayloadAction<SingleMessageReceivedPayload>) => {},
    maybeMuteAudio: (state, action: PayloadAction<SingleMessageReceivedPayload>) => {},
    updatePendingSendStatus: (state, action: PayloadAction<UpdatePendingSendStatusPayload>) => {
      const { chatId, threadTimestamp, messageId, sendStatus } = action.payload;
      const chatKey = getChatKey(chatId, threadTimestamp);
      const chatMessages = state.messages[chatKey];
      if (!chatMessages) {
        logger.error(`chat state not obtained for ${chatId} ${threadTimestamp}, unexpected`);
        return;
      }
      const message = chatMessages.pendingMessages.entities[messageId];
      if (message) {
        message.sendStatus = sendStatus;
      }
    },
    setTemporaryMessages: (state, action: PayloadAction<PendingChatMessage[]>) => {
      // Temporary messages are set to help with previewing draft content in blast. Only
      // one group can be set at a time, and they're removed when a new group is set.
      const messages = action.payload;
      const chatMessages = emptyMessageState();
      chatMessages.pendingMessages = pendingMessageAdapter.setMany(
        chatMessages.pendingMessages,
        messages
      );
      state.messages["TEMP"] = chatMessages;
    },
    addContextMenu: (state, action: PayloadAction<AddContextMenuPayload>) => {
      const { messageId, menu } = action.payload;
      const existingMenus = state.contextMenus[messageId] ?? [];
      state.contextMenus[messageId] = [...existingMenus, menu];
    },
    removeContextMenu: (state, action: PayloadAction<RemoveContextMenu>) => {
      const { messageId, menuIndex } = action.payload;
      const existingMenus = state.contextMenus[messageId];
      if (!existingMenus || existingMenus.length <= menuIndex) return;

      const newMenus = [...existingMenus];
      newMenus.splice(menuIndex, 1);
      if (newMenus.length === 0) {
        delete state.contextMenus[messageId];
      } else {
        state.contextMenus[messageId] = newMenus;
      }
    },
    dismissPreview: (state, action: PayloadAction<DismissPreviewPayload>) => {},
  },
});

export const { actions, reducer, getInitialState } = slice;
export type MessageSlice = ReturnType<typeof getInitialState>;

const selectSlice = (state: RootState) => state.chat.message;
const messageSelectors = messageAdapter.getSelectors();
const pendingMessageSelectors = pendingMessageAdapter.getSelectors();
const selectSummaries = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.chat.thread.summaries
);

const latestMessage = (
  slice: MessageSlice,
  chatId: string | undefined,
  threadTimestamp: number | undefined
): SentChatMessage | undefined => {
  if (!chatId) return undefined;
  const chatMessages = slice.messages[getChatKey(chatId, threadTimestamp)];
  if (!chatMessages || chatMessages.messages.ids.length === 0) return undefined;

  // Find a non-deleted message
  for (let i = chatMessages.messages.ids.length - 1; i >= 0; i--) {
    const id = chatMessages.messages.ids[i];
    if (!id) throw new TypeNarrowError();
    const message = messageSelectors.selectById(chatMessages.messages, id);
    if (message?.content.contentType !== "deleted") {
      return message;
    }
  }
};

export const selectors = {
  selectMessagesByChatId: defaultMemoize(
    (chatId: string, threadTimestamp: number | undefined) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        const chatMessages = slice.messages[getChatKey(chatId, threadTimestamp)];
        if (!chatMessages) return undefined;
        return messageSelectors.selectAll(chatMessages.messages);
      }),
    { maxSize: 2 }
  ),
  selectPendingMessagesByChatId: defaultMemoize((chatId: string) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      const chatMessages = slice.messages[getChatKey(chatId, undefined)];
      if (!chatMessages?.pendingMessages) return undefined;
      return pendingMessageSelectors.selectAll(chatMessages.pendingMessages);
    })
  ),
  selectUnreadCountByChatSince: defaultMemoize(
    (chatId: string | undefined, senderAddressIds: string[], timestamp: number | undefined) =>
      createSelector(selectSlice, (slice) => {
        if (chatId === undefined) return 0;
        const chatMessages = slice.messages[chatId];
        if (!chatMessages) return 0;
        return messageSelectors
          .selectAll(chatMessages.messages)
          .filter(
            (m) =>
              m.timestamp &&
              (!timestamp || m.timestamp > timestamp) &&
              !senderAddressIds.includes(m.addressId)
          ).length;
      })
  ),
  // Returns both sent and pending message IDs, ordered by timestamp
  selectCombinedMessageIdsByChat: defaultMemoize(
    (
      chatId: string | undefined,
      threadTimestamp: number | undefined,
      anchorTimestamp: number | undefined
    ) =>
      createDeepEqualSelector(
        selectSlice,
        selectSummaries,
        (
          slice,
          summaries
        ): {
          messageIds?: CombinedMessageId[];
          activeRange?: NumberRange;
          highWatermarkRange?: NumberRange;
        } => {
          if (!chatId) {
            return {};
          }
          const chatKey = getChatKey(chatId, threadTimestamp);
          const chatMessages = slice.messages[chatKey];
          if (!chatMessages) {
            return {};
          }

          const range = rangeContaining(chatMessages.ranges, anchorTimestamp ?? "last");
          if (!range) {
            return {};
          }

          const allPendingMessages = pendingMessageSelectors.selectAll(
            chatMessages.pendingMessages
          );

          const { startIndex: msgStart, endIndex: msgEnd } = findItemsInRange(
            chatMessages.messages.ids,
            range,
            (id: string | number) => (typeof id === "number" ? id : -1 /* not possible */)
          );

          const { startIndex: pendingStart, endIndex: pendingEnd } = findItemsInRange(
            allPendingMessages,
            range,
            (msg: PendingChatMessage) => msg.clientTimestamp
          );

          let m = msgStart;
          let p = pendingStart;
          const result = new Array<number | string>();
          while (m < msgEnd || p < pendingEnd) {
            const messageTimestamp = chatMessages.messages.ids[m];
            const pendingMessage = allPendingMessages[p];

            if (
              messageTimestamp &&
              typeof messageTimestamp === "number" &&
              messageTimestamp < (pendingMessage?.clientTimestamp ?? Number.POSITIVE_INFINITY)
            ) {
              if (
                chatMessages.messages.entities[messageTimestamp]?.content?.contentType !==
                  "deleted" ||
                summaries.entities[getChatKey(chatId, messageTimestamp)] !== undefined
              ) {
                result.push(messageTimestamp);
              }
              m++;
            } else if (pendingMessage) {
              result.push(pendingMessage.messageId);
              p++;
            } else {
              // Should not happen
              break;
            }
          }

          return {
            messageIds: result,
            highWatermarkRange: range,
          };
        }
      ),
    {
      // A thread and the channel behind it can be open at once
      maxSize: 2,
    }
  ),
  selectPendingMessageById:
    (chatKey: ChatKeyString | undefined, messageId: string | undefined) => (state: RootState) => {
      if (!chatKey || !messageId) return undefined;
      const chatMessages = selectSlice(state).messages[chatKey];
      if (!chatMessages) return undefined;
      return chatMessages.pendingMessages?.entities[messageId];
    },

  selectMessageByTimestamp:
    (chatKey: ChatKeyString | undefined, timestamp: number | undefined) => (state: RootState) => {
      if (!chatKey || !timestamp) return undefined;
      const chatMessages = selectSlice(state).messages[chatKey];
      if (!chatMessages) return undefined;
      return chatMessages.messages.entities[timestamp];
    },
  // deprecated(timestamp-message-ids)
  deprecatedSelectMessageByMessageId: defaultMemoize(
    (
      chatId: string | undefined,
      threadTimestamp: number | undefined,
      messageId: string | undefined
    ) =>
      createSelector(selectSlice, (slice) => {
        if (!chatId || !messageId) return undefined;
        const chatMessages = slice.messages[getChatKey(chatId, threadTimestamp)];
        if (!chatMessages) return undefined;
        return messageSelectors
          .selectAll(chatMessages.messages)
          .find((m) => m.messageId === messageId);
      }),
    { maxSize: 150 }
  ),
  selectSurroundingItemIds: defaultMemoize(
    (
      chatId: string | undefined,
      threadTimestamp: number | undefined,
      timestamp: number | undefined
    ) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        if (!chatId || !timestamp) return undefined;
        const chatKey = getChatKey(chatId, threadTimestamp);
        const chatMessages = slice.messages[chatKey];
        if (!chatMessages) return undefined;
        const { ids, entities } = chatMessages.messages;
        const targetIndex = ids.indexOf(timestamp);
        if (targetIndex < 0) return undefined;
        const targetMessage = entities[timestamp];
        if (!targetMessage) return undefined;

        const itemIds = new Array<string>();

        // Move downward to find the lowest item (if any) that should be included in the range
        let i = targetIndex;
        while (i >= 0) {
          const timestamp = ids[i - 1];
          if (!timestamp) break;
          const message = entities[timestamp];
          if (!message) break;
          if (
            message.addressId !== targetMessage.addressId ||
            itemIdsFromMessage(message).length === 0
          )
            break;
          i--;
        }

        // Now move back upward adding items as long as they should be included
        while (i < ids.length) {
          const timestamp = ids[i];
          if (!timestamp) break;
          const message = entities[timestamp];
          if (!message) break;
          const messageItemIds = itemIdsFromMessage(message);
          if (messageItemIds.length === 0 || message.addressId !== targetMessage.addressId) break;
          itemIds.push(...messageItemIds);
          i++;
        }

        return itemIds;
      })
  ),

  // Used if you have either a number for a real message or a string with the messageId of a pending
  // message.
  selectMessageByCombinedId:
    (chatKey: ChatKeyString | undefined, messageId: CombinedMessageId | undefined) =>
    (state: RootState) => {
      if (!chatKey || !messageId) return undefined;
      const slice = selectSlice(state);
      const chatMessages = slice.messages[chatKey];
      if (!chatMessages) return undefined;

      if (typeof messageId === "number") {
        return messageSelectors.selectById(chatMessages.messages, messageId);
      } else {
        return pendingMessageSelectors.selectById(chatMessages.pendingMessages, messageId);
      }
    },

  selectLatestByChatId:
    (chatId: string | undefined, threadTimestamp: number | undefined) => (state: RootState) => {
      return latestMessage(selectSlice(state), chatId, threadTimestamp);
    },
  selectLatestUnreadByChatId: defaultMemoize(
    (chatId: string | undefined, threadTimestamp: number | undefined) =>
      createSelector(selectSlice, selectChatStatusSlice, (slice, chatStatusSlice) => {
        if (!isUnread(chatStatusSlice, chatId, threadTimestamp)) {
          return undefined;
        }

        return latestMessage(slice, chatId, threadTimestamp);
      })
  ),
  selectMaxCombinedTimestampForChat: defaultMemoize(
    (chatId: string | undefined, threadTimestamp: number | undefined) =>
      createSelector(selectSlice, (slice) => {
        if (!chatId) return undefined;
        const chatKey = getChatKey(chatId, threadTimestamp);
        const chatMessages = slice.messages[chatKey];
        if (!chatMessages) return undefined;

        const maxChatTimestamp =
          chatMessages.messages.ids.length > 0
            ? (chatMessages.messages.ids[chatMessages.messages.ids.length - 1] as number)
            : 0;
        const pendingMessages = pendingMessageSelectors.selectAll(chatMessages.pendingMessages);
        const maxPendingTimestamp =
          pendingMessages.length > 0
            ? pendingMessages[pendingMessages.length - 1]?.clientTimestamp ?? 0
            : 0;
        return Math.max(maxChatTimestamp, maxPendingTimestamp);
      })
  ),
  selectContextMenusByMessageId: (messageId?: string) => (state: RootState) => {
    if (!messageId) return undefined;
    return selectSlice(state).contextMenus[messageId];
  },
};

export const MessageActions = actions;
export const MessageSelectors = selectors;
