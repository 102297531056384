import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import {
  crpc_addPb_CreateProfileBadgeField,
  crpc_addPb_GetProfileBadgeFieldsForAddressId,
  crpc_addPb_RemoveProfileBadgeField,
} from "../../../../shared/AddressMessages/ClientRpcProfileBadgeField.js";
import {
  ProfileBadgeField,
  ProfileBadgeTextKey,
} from "../../../../shared/Models/ProfileBadgeField.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

type ProfileBadgeFields = {
  // See ./externalSocialLinksSlice.ts for SocialNetwork stuff
  [f in ProfileBadgeTextKey]?: Omit<ProfileBadgeField, "key" | "addressId">;
};
type AddressIdToProfileBadgeFields = { [sn in string]?: ProfileBadgeFields };

const slice = createSlice({
  name: "profileBadgeFields",
  initialState: {
    addressIdToFields: {} as AddressIdToProfileBadgeFields,
  },

  reducers: {
    createProfileBadgeField: (
      state,
      action: PayloadAction<Omit<crpc_addPb_CreateProfileBadgeField, "kind">>
    ) => {},
    deleteProfileBadgeField: (
      state,
      action: PayloadAction<Omit<crpc_addPb_RemoveProfileBadgeField, "kind">>
    ) => {
      const { addressId, key } = action.payload;
      // In addition to sending a remote request, also delete the link from
      // the local store immediately
      const keys = state.addressIdToFields[addressId];
      if (keys) {
        keys[key] = undefined;
      }
    },

    getProfileBadgeFieldsForAddressId: (
      state,
      action: PayloadAction<Omit<crpc_addPb_GetProfileBadgeFieldsForAddressId, "kind">>
    ) => {},

    setProfileBadgeField: (state, action: PayloadAction<ProfileBadgeField>) => {
      const { addressId, key, value } = action.payload;
      // See ./externalSocialLinkSlice.ts for other key types
      if (key.keyType !== "text") {
        return;
      }
      const keys: ProfileBadgeFields = state.addressIdToFields[addressId] ?? {};
      keys[key.key] = { value };
      state.addressIdToFields[addressId] = keys;
    },
    setProfileBadgeFields: (state, action: PayloadAction<ProfileBadgeField[]>) => {
      for (const { addressId, key, value } of action.payload) {
        // See ./externalSocialLinkSlice.ts for other key types
        if (key.keyType !== "text") {
          continue;
        }
        const keys: ProfileBadgeFields = state.addressIdToFields[addressId] ?? {};
        keys[key.key] = { value };
        state.addressIdToFields[addressId] = keys;
      }
    },
  },
});

export const { actions, reducer } = slice;
export const ProfileBadgeFieldActions = actions;
export const ProfileBadgeFieldReducer = reducer;

const selectSlice = (state: RootState) => state.anyWorld.profileBadgeFields;
export const selectors = {
  /**
   * See ./externalSocialLinkSlice.ts for that key type
   */
  selectProfileBadgeField: defaultMemoize((addressId: string, key: ProfileBadgeTextKey) =>
    createSelector(selectSlice, (slice) => {
      return slice.addressIdToFields[addressId]?.[key];
    })
  ),
};

export const ProfileBadgeFieldSelectors = selectors;
