import { z } from "zod";
import { RoamThemeOverrides } from "./RoamThemeOverrides.js";
import { numberId } from "./zodTypes.js";

export const RoamType = z.enum(["ROAM", "ROAM_UNIVERSITY"]);
export type RoamType = z.infer<typeof RoamType>;

export const Roam = z.object({
  id: numberId(),
  accountId: numberId(),
  externalId: z.string().optional(),
  name: z.string().nonempty(),
  displayName: z.string(),
  primaryImageAbsoluteUrl: z.string().url().optional(),
  primaryImageName: z.string().optional(),
  imageAbsoluteUrl: z.string().url().optional(),
  imageName: z.string().optional(),
  inverseImageAbsoluteUrl: z.string().url().optional(),
  inverseImageName: z.string().optional(),
  themeOverrides: RoamThemeOverrides.optional(),
  launchDarklyHash: z.string().nonempty().optional(),
  defaultHomeFloorId: numberId().optional(),
  roamType: RoamType.optional(),
  defaultAudioEntranceName: z.string().optional(),
  defaultAudioEntranceUrl: z.string().optional(),
  forceAudioEntranceTone: z.boolean().optional(),
  defaultAudioExitName: z.string().optional(),
  defaultAudioExitUrl: z.string().optional(),
  forceAudioExitTone: z.boolean().optional(),
});
export type Roam = z.infer<typeof Roam>;
