import { VideoPipelineName, VideoPipelineStats } from "../../../../shared/Models/VideoPipeline.js";
import { isDevMode, isElectron } from "../../../../shared/api/environment.js";
import { Dimensions } from "../../../../shared/geometry/Dimensions.js";
import { getErrorMessage } from "../../../../shared/helpers/errors.js";
import { logger } from "../../../../shared/infra/logger.js";
import { PostProcObject } from "../../PostProcObjectCache.js";
import { PostProcVideoSpec } from "../interfaces/IVideoPipelineLauncher.js";
import {
  PipelineError,
  PipelineErrorHandler,
  plainToPipelineError,
} from "../interfaces/VideoPipelineError.js";
import { VideoWorkerMainMessage, VideoWorkerWorkerMessage } from "./VideoWorkerMessage.js";

export const videoWorkerSupported = () =>
  typeof MediaStreamTrackProcessor !== "undefined" &&
  typeof MediaStreamTrackGenerator !== "undefined" &&
  typeof TransformStream !== "undefined" &&
  typeof VideoFrame !== "undefined";

interface Resolver {
  resolve: () => void;
  reject: (err: any) => void;
}

/*
 * VideoWorkerProxy creates a web worker with the root class VideoWorker and allows callers to
 * communicate with it.
 *
 */
export class VideoWorkerProxy implements PostProcObject {
  private worker: Worker;
  private stopResolver?: Resolver;
  private stopPromise?: Promise<void>;
  private initResolver?: Resolver;
  private initPromise: Promise<void>;
  private initTimeout?: NodeJS.Timeout;
  private initialized = false;
  private stopped = true;
  private closed = false;

  private onPipelineError?: PipelineErrorHandler;
  private onStats?: (stats: VideoPipelineStats) => void;

  constructor(private pipelineName: VideoPipelineName) {
    this.worker = new Worker(
      isDevMode || isElectron ? "./videoPipelineWorker.js" : `./videoPipelineWorker-${__version}.js`
    );
    this.worker.onmessage = (event: MessageEvent<VideoWorkerWorkerMessage>) =>
      this.handleWorkerMessage(event);
    this.worker.onerror = (event: ErrorEvent) => this.handleWorkerError(event);

    this.initPromise = new Promise<void>((resolve, reject) => {
      this.initResolver = {
        resolve,
        reject,
      };
    });
    this.initTimeout = setTimeout(() => {
      if (this.initResolver) {
        logger.error("initializing worker timed out");
        this.initResolver.reject("initialization timed out");
      }
    }, 5000);
    this.postMessage({ message: "init", pipelineName });
  }

  public setCallbacks(
    onPipelineError?: PipelineErrorHandler,
    onStats?: (stats: VideoPipelineStats) => void
  ) {
    this.onPipelineError = onPipelineError;
    this.onStats = onStats;
  }

  // Initialization begins in the constructor but finishes asynchronously. Callers should
  // await the result of this method before using the worker. If it rejects, the worker was not
  // initialized successfully or has since been closed.
  public waitForWorker(): Promise<void> {
    if (this.closed) {
      throw new Error("cannot wait for closed worker");
    }
    return this.initPromise;
  }

  private handleWorkerMessage(event: MessageEvent<VideoWorkerWorkerMessage>) {
    const m = event.data;
    switch (m.message) {
      case "initComplete": {
        this.initialized = true;
        if (this.initResolver) {
          this.initResolver.resolve();
          this.initResolver = undefined;
        }
        if (this.initTimeout) {
          clearTimeout(this.initTimeout);
          this.initTimeout = undefined;
        }
        break;
      }
      case "initFailed": {
        if (this.initResolver) {
          this.initResolver.reject(plainToPipelineError(m.err));
          this.initResolver = undefined;
        }
        if (this.initTimeout) {
          clearTimeout(this.initTimeout);
          this.initTimeout = undefined;
        }
        break;
      }
      case "stopped": {
        if (this.stopResolver) {
          this.stopped = true;
          this.stopResolver.resolve();
          this.stopResolver = undefined;
          this.stopPromise = undefined;
        }
        break;
      }
      case "pipelineError": {
        if (this.onPipelineError) {
          const pipelineError = plainToPipelineError(m.err);
          this.onPipelineError(pipelineError);
        }
        break;
      }
      case "stats": {
        if (this.onStats) {
          this.onStats(m.stats);
        }
      }
    }
  }

  public setStreamsAndSpec(
    spec: PostProcVideoSpec,
    dimensions: Dimensions,
    source: ReadableStream<VideoFrame>,
    destination: WritableStream<VideoFrame>,
    streamId: string
  ) {
    if (!this.initialized) {
      throw new Error("worker not initialized");
    }
    if (this.closed) {
      throw new Error("the worker has been destroyed");
    }
    this.stopped = false;
    const msg: VideoWorkerMainMessage = {
      message: "setStreamsAndSpec",
      spec,
      source,
      destination,
      dimensions,
      streamId,
    };
    const transfer: Transferable[] = [source, destination];
    if (spec?.foregroundOverlays) {
      transfer.push(...spec.foregroundOverlays);
    }
    this.postMessage(msg, transfer);
  }

  public setSpec(spec: PostProcVideoSpec) {
    if (!this.initialized) {
      throw new Error("worker not initialized");
    }
    if (this.closed) {
      throw new Error("the worker has been destroyed");
    }
    this.stopped = false;
    const msg: VideoWorkerMainMessage = {
      message: "setSpec",
      spec,
    };
    this.postMessage(msg, []);
  }

  public isOK(): boolean {
    return !this.closed;
  }

  private handleWorkerError(event: ErrorEvent) {
    logger.error({ event }, "Unhandled error from VideoWorker.");

    // If this event happens, there was some kind of issue we didn't handle through any other means,
    // so for safety we raise a PipelineError.
    const err = new PipelineError(getErrorMessage(event.error));
    this.onPipelineError?.(err);
  }

  public async stop() {
    if (this.stopped || this.closed) {
      return;
    }
    if (this.stopPromise) {
      return this.stopPromise;
    }
    this.stopPromise = new Promise<void>((resolve, reject) => {
      this.stopResolver = { resolve, reject };
    });
    const msg: VideoWorkerMainMessage = {
      message: "stop",
    };
    this.postMessage(msg);
    return this.stopPromise;
  }

  private postMessage(msg: VideoWorkerMainMessage, transfer: Transferable[] = []) {
    this.worker.postMessage(msg, transfer);
  }

  public dump() {
    return {
      initialized: this.initialized,
      stopped: this.stopped,
      closed: this.closed,
    };
  }

  public cleanup() {
    this.initResolver?.reject("worker is being destroyed");
    this.stopResolver?.reject("worker is being destroyed");
    this.closed = true;
    const msg: VideoWorkerMainMessage = {
      message: "terminate",
    };
    this.worker.postMessage(msg);
    this.worker.terminate();
  }
}
