import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FoundersTour } from "../../../../shared/Models/FoundersTour.js";
import { insertSorted } from "../../../../shared/helpers/sorting.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

/**
 * Comparison function for foundersTours.
 */
const sortComparer = (a: FoundersTour, b: FoundersTour): number => {
  // Sort in ascending order by start time
  return a.startTime - b.startTime;
};

export type CreateFoundersTourPayload = {
  externalEventId: string;
  name: string;
  locale: string;
  timeZone: string;
  subsource: string;
} & (
  | {
      email: string;
      inviteeUri?: never;
    }
  | {
      email?: never;
      inviteeUri: string;
    }
);

export type MarkFoundersTourCompletePayload = {
  foundersTourId: string;
  tourComplete: boolean;
};

export enum FoundersTourModalStep {
  Info = 1,
  Calendar,
  Final,
}

const slice = createSlice({
  name: "foundersTour",
  initialState: {
    foundersTours: undefined as FoundersTour[] | undefined,
    foundersTourModalStep: FoundersTourModalStep.Info,
  },
  reducers: {
    // These action only operate on local state, and are called in response to
    // responses from CRPCs
    setFoundersTours: (state, action: PayloadAction<FoundersTour[]>) => {
      state.foundersTours = action.payload.sort(sortComparer);
    },
    addFoundersTour: (state, action: PayloadAction<FoundersTour>) => {
      const filtered = (state.foundersTours ?? []).filter(
        (foundersTour) => foundersTour.id !== action.payload.id
      );
      state.foundersTours = insertSorted(sortComparer, filtered, action.payload);
    },

    // These actions trigger sagas to make CRPCs
    createFoundersTour: (state, action: PayloadAction<CreateFoundersTourPayload>) => {},
    getMyFoundersTours: () => {},

    // These actions operate on local state *and* send a CRPC to reflect those
    // changes remotely
    deleteFoundersTour: (state, action: PayloadAction<string>) => {
      if (state.foundersTours) {
        state.foundersTours = state.foundersTours.filter(
          (foundersTour) => foundersTour.id !== action.payload
        );
      }
    },
    markFoundersTourComplete: (state, action: PayloadAction<MarkFoundersTourCompletePayload>) => {
      state.foundersTours = state.foundersTours?.map((foundersTour) => {
        if (foundersTour.id !== action.payload.foundersTourId) {
          return foundersTour;
        }

        return {
          ...foundersTour,
          tourComplete: action.payload.tourComplete,
        };
      });
    },

    // These actions only operate on local state
    nextFoundersTourModalStep: (state) => {
      state.foundersTourModalStep = Math.min(
        FoundersTourModalStep.Final,
        state.foundersTourModalStep + 1
      );
    },
    prevFoundersTourModalStep: (state) => {
      state.foundersTourModalStep = Math.max(
        FoundersTourModalStep.Info,
        state.foundersTourModalStep - 1
      );
    },
    resetFoundersTourModalStep: (state) => {
      state.foundersTourModalStep = FoundersTourModalStep.Info;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.anyWorld.foundersTour;
export const selectors = {
  selectAllFounderTours: createSelector(selectSlice, (slice) => {
    return slice.foundersTours;
  }),
  selectFoundersTourModalStep: createSelector(selectSlice, (slice) => {
    return slice.foundersTourModalStep;
  }),
};

export const FoundersTourActions = actions;
export const FoundersTourSelectors = selectors;
