/* eslint-disable no-underscore-dangle */
/* cspell:ignore emscripten,heapu,mlkit,segm,selfiesegmentation */
import { logger } from "../../../../shared/infra/logger.js";
import segm_full_v679 from "../../../assets/tflite/models/segm_full_v679.tflite";
import segm_lite_v681 from "../../../assets/tflite/models/segm_lite_v681.tflite";
import selfiesegmentation from "../../../assets/tflite/models/selfiesegmentation_mlkit-256x256-2021_01_19-v1215.f16.tflite";
import createTFLiteSIMDModule, { TFLite } from "../../../assets/tflite/tflite-simd.js";
import { getTFLiteModelFileName, SegmentationConfig } from "./segmentationHelper.js";

export interface TFResult {
  tflite: TFLite;
  isSIMDSupported: boolean;
}

const models = new Map<string, { model: ArrayBuffer; modelBufferOffset: number }>();

const chooseTFLite = async (
  segmentationConfig: SegmentationConfig
): Promise<TFResult | undefined> => {
  let tfliteSIMD: TFLite | undefined;
  let isSIMDSupported = false;
  let model: ArrayBuffer;
  let modelBufferOffset = 0;

  // edited to remove unused (and unsupported) non-SIMD version
  try {
    const createdTFLiteSIMD = await createTFLiteSIMDModule();
    tfliteSIMD = createdTFLiteSIMD;
    isSIMDSupported = true;
  } catch (err) {
    logger.warn({ err }, `Failed to create TFLite SIMD WebAssembly module.`);
  }
  if (
    (isSIMDSupported && !tfliteSIMD) ||
    (!isSIMDSupported && segmentationConfig.backend === "wasmSimd") ||
    (segmentationConfig.model !== "meet" && segmentationConfig.model !== "mlkit")
  ) {
    return;
  }

  const newSelectedTFLite = segmentationConfig.backend === "wasmSimd" ? tfliteSIMD : undefined;

  if (!newSelectedTFLite) {
    throw new Error(`TFLite backend unavailable: ${segmentationConfig.backend}`);
  }

  const modelFileName = getTFLiteModelFileName(
    segmentationConfig.model,
    segmentationConfig.inputResolution
  );
  logger.info(`Loading tflite model: ${modelFileName}`);

  if (models.has(modelFileName)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    model = models.get(modelFileName)!.model;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    modelBufferOffset = models.get(modelFileName)!.modelBufferOffset;
  } else {
    let modelPath: string;
    if (modelFileName === "segm_full_v679") {
      modelPath = segm_full_v679;
    } else if (modelFileName === "segm_lite_v681") {
      modelPath = segm_lite_v681;
    } else if (modelFileName === "selfiesegmentation_mlkit-256x256-2021_01_19-v1215.f16") {
      modelPath = selfiesegmentation;
    } else {
      throw new Error(`Cannot find model with name '${modelFileName}'.`);
    }
    const modelResponse = await fetch(modelPath);
    model = await modelResponse.arrayBuffer();

    modelBufferOffset = newSelectedTFLite._getModelBufferMemoryOffset();
    models.set(modelFileName, { model, modelBufferOffset });
  }

  newSelectedTFLite.HEAPU8.set(new Uint8Array(model), modelBufferOffset);
  logger.info(`TFLite _loadModel result: ${newSelectedTFLite._loadModel(model.byteLength)}`);

  const selectedTFLite = newSelectedTFLite;

  return { tflite: selectedTFLite, isSIMDSupported };
};

export default chooseTFLite;
