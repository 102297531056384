import { ExternalSocialLink, SocialNetwork } from "../Models/ExternalSocialLink.js";
import { uuid } from "../Models/zodTypes.js";

interface SocialNetworkConverter {
  /**
   * Checks to make sure a username is valid, according to the social network's
   * username rules. Returns the valid username, or undefined if invalid.
   */
  validateUsername(username: string): string | undefined;

  /**
   * Extracts the username from the provided url. Also validates both.
   */
  usernameFromUrl(url: string): string | undefined;

  /**
   * Creates a url from the provided username. Does NOT validate the username.
   */
  urlFromUsername(username: string): string;
}

class LinkedIn implements SocialNetworkConverter {
  validateUsername(username: string): string | undefined {
    return 3 <= username.length && username.length <= 100 && /^[\w-]+$/.test(username)
      ? username
      : undefined;
  }
  usernameFromUrl(url: string): string | undefined {
    const match = /linkedin\.com\/in\/([\w-]+)/.exec(url);
    const username = match?.[1];
    return username && this.validateUsername(username) ? username : undefined;
  }
  urlFromUsername(username: string) {
    return `https://www.linkedin.com/in/${username}/`;
  }
}

class Twitter implements SocialNetworkConverter {
  validateUsername(username: string): string | undefined {
    if (username.startsWith("@")) {
      username = username.substring(1);
    }
    // Twitter support docs list 4 characters as the minimum username length,
    // but there are obvious special exceptions, such as @x, so use 1 as min length
    return 1 <= username.length && username.length <= 15 && /^\w+$/.test(username)
      ? username
      : undefined;
  }
  usernameFromUrl(url: string): string | undefined {
    const match = /(twitter|x).com\/(\w+)/.exec(url);
    const username = match?.[2];
    return username && this.validateUsername(username) ? username : undefined;
  }
  urlFromUsername(username: string): string {
    return `https://twitter.com/${username}`;
  }
}

class Instagram implements SocialNetworkConverter {
  validateUsername(username: string): string | undefined {
    if (username.startsWith("@")) {
      username = username.substring(1);
    }
    return 1 <= username.length && username.length <= 30 && /^[\w.]+$/.test(username)
      ? username
      : undefined;
  }
  usernameFromUrl(url: string): string | undefined {
    const match = /instagram.com\/([\w.]+)/.exec(url);
    const username = match?.[1];
    return username && this.validateUsername(username) ? username : undefined;
  }
  urlFromUsername(username: string): string {
    return `https://www.instagram.com/${username}`;
  }
}

class TikTok implements SocialNetworkConverter {
  validateUsername(username: string): string | undefined {
    if (username.startsWith("@")) {
      username = username.substring(1);
    }
    return 2 <= username.length && username.length <= 24 && /^[\w.]+$/.test(username)
      ? username
      : undefined;
  }
  usernameFromUrl(url: string): string | undefined {
    const match = /tiktok.com\/@([\w.]+)/.exec(url);
    const username = match?.[1];
    return username && this.validateUsername(username) ? username : undefined;
  }
  urlFromUsername(username: string): string {
    return `https://www.tiktok.com/@${username}`;
  }
}

export const converters: { [sn in SocialNetwork]: SocialNetworkConverter } = {
  LinkedIn: new LinkedIn(),
  Twitter: new Twitter(),
  Instagram: new Instagram(),
  TikTok: new TikTok(),
};

export const withLink = (e: Omit<ExternalSocialLink, "id" | "link">): ExternalSocialLink => {
  const link = converters[e.socialNetwork].urlFromUsername(e.username);
  return withId({
    ...e,
    link,
  });
};

// (deprecate-external-social-link-id)
export const withId = (e: Omit<ExternalSocialLink, "id">): ExternalSocialLink => {
  const id = `${e.addressId}_${e.socialNetwork}`;
  return {
    ...e,
    id,
  };
};

export const idToAddressId = (e: string): string => {
  return uuid().parse(e.substring(0, e.indexOf("_")));
};

export const idToSocialNetwork = (e: string): SocialNetwork => {
  return SocialNetwork.parse(e.substring(e.indexOf("_") + 1));
};
