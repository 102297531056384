import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider as BaseThemeProvider } from "styled-components";
import { LIGHT_MODE, NEW_ACCENTS } from "../../shared/featureflags/RoamFlagConfigs.js";
import { UnreachableError } from "../../shared/helpers/UnreachableError.js";
import { selectors as RoamSelectors } from "../anyworld/store/slices/roamSlice.js";
import { useAppearance } from "../hooks/useAppearance.js";
import { useFeatureFlag } from "../hooks/useFeatureFlag.js";
import { selectors as AppSelectors } from "../store/slices/appSlice.js";
import { selectors as WorldSelectors } from "../world/store/slices/worldSlice.js";
import { accentToColor, colorToAccent, accentData as getAccentData } from "./accents.js";
import { Appearance } from "./appearance.js";
import { makeBaseTheme } from "./baseTheme.js";
import { semanticColors as baseSemanticColors } from "./colors.js";
import { lightModeSemanticColors } from "./lightMode.js";

/**
 * Type-safe way to apply overrides to the semanticColors or theme color objects.
 * Ensures no new keys get added to `TObject`. Mutates `object`.
 */
const merge = <TSource, TObject extends TSource>(object: TObject, source: TSource): TObject => {
  return _.merge(object, source);
};

export const ThemeProvider = ({ children }: React.PropsWithChildren) => {
  const currentRoamId = useSelector(WorldSelectors.selectActiveRoamId);
  const roam = useSelector(RoamSelectors.selectById(currentRoamId));

  const semanticColors = _.cloneDeep(baseSemanticColors);

  const useTransparency = useSelector(AppSelectors.selectUseUITransparency);

  const useNewAccents = useFeatureFlag(NEW_ACCENTS);
  const storedAccentColor = roam?.themeOverrides?.accentColor;
  const accentColor =
    useNewAccents && storedAccentColor === accentToColor("black") ? undefined : storedAccentColor;
  const backgroundVisualizationColor = roam?.themeOverrides?.backgroundVisualizationColor;

  const lightModeFlag = useFeatureFlag(LIGHT_MODE);
  const storedAppearance = useAppearance();
  const appearance: Appearance = lightModeFlag ? storedAppearance : "dark";

  switch (appearance) {
    case "dark":
      break;
    case "light":
      merge(semanticColors, lightModeSemanticColors);
      merge(semanticColors.icon, lightModeSemanticColors.text);
      break;
    default:
      throw new UnreachableError(appearance);
  }

  if (useNewAccents) {
    const accent = accentColor ? colorToAccent(accentColor) : undefined;
    // An accentColor is considered "custom" if it doesn't match the
    // accentPrimary in the list of accent colors
    const accentIsCustom =
      (accentColor && !accent) || (accent && accentToColor(accent) !== accentColor);
    // If the accentColor is custom or not specified, we use the "black" theme
    // for all colors. Only when the accent color is chosen from the new list
    // of colors should we use a new accent theme
    const baseAccent = (!accentIsCustom && accent) || "black";
    const accentData = getAccentData(baseAccent, appearance);

    const backgroundAccent = backgroundVisualizationColor
      ? colorToAccent(backgroundVisualizationColor)
      : undefined;
    const backgroundAccentData = getAccentData(backgroundAccent ?? "black", appearance);

    const categoryOverride = {
      accentedPrimary: accentData.accentedPrimary,
      accentedSecondary: accentIsCustom ? accentColor : accentData.accentedSecondary,
    };
    const textIconOverride = {
      ...categoryOverride,
      onAccentedPrimary: accentData.textIconOnAccentedPrimary,
      onAccentedSecondary: accentData.textIconOnAccentedSecondary,
    };
    merge(semanticColors, {
      text: textIconOverride,
      icon: textIconOverride,
      border: categoryOverride,
      background: {
        accentedPrimary: backgroundAccentData.accentedPrimary,
        accentedSecondary: accentIsCustom ? accentColor : backgroundAccentData.accentedSecondary,
        elevatedPrimary: backgroundAccentData.backgroundElevatedPrimary,
        elevatedSecondary: backgroundAccentData.backgroundElevatedSecondary,
        visualization: backgroundVisualizationColor,
      },
      surface: {
        primary: backgroundAccentData.surfacePrimary,
      },
    });
  } else {
    if (accentColor) {
      const categoryOverride = {
        accentedSecondary: accentColor,
      };
      merge(semanticColors, {
        text: categoryOverride,
        icon: categoryOverride,
        background: categoryOverride,
        border: categoryOverride,
      });
    }
    if (backgroundVisualizationColor) {
      merge(semanticColors, {
        background: {
          visualization: backgroundVisualizationColor,
        },
      });
    }
  }

  const theme = makeBaseTheme(semanticColors, { useTransparency });

  if (backgroundVisualizationColor) {
    merge(theme, {
      color: {
        background: {
          visualization: backgroundVisualizationColor,
        },
      },
    });
  }

  return <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>;
};
