import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { Rectangle } from "electron";
import { RootState } from "../reducers.js";

export interface Display {
  id: number;
  bounds: Rectangle;
  workArea: Rectangle;
}

const slice = createSlice({
  name: "display",
  initialState: {
    displays: {} as Record<number, Display>,
  },
  reducers: {
    setAllDisplays: (state, action: PayloadAction<Display[]>) => {
      state.displays = {};
      for (const display of action.payload) {
        state.displays[display.id] = display;
      }
    },
    displayAdded: (state, action: PayloadAction<Display>) => {
      const addedDisplay = action.payload;
      state.displays[addedDisplay.id] = addedDisplay;
    },
    displayChanged: (state, action: PayloadAction<Display>) => {
      const changedDisplay = action.payload;
      state.displays[changedDisplay.id] = changedDisplay;
    },
    displayRemoved: (state, action: PayloadAction<Display>) => {
      const removedDisplay = action.payload;
      delete state.displays[removedDisplay.id];
    },
  },
});
export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.display;
export const selectors = {
  selectAll: (state: RootState) => Object.values(selectSlice(state).displays),
  selectById: (id?: number) => (state: RootState) =>
    id !== undefined ? selectSlice(state).displays[id] : undefined,
};

export const DisplaySelectors = selectors;
export const DisplayActions = actions;
