import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MentionTarget } from "../../../../shared/helpers/mentions.js";
import { EmailChatTarget, StandardGroupChatTarget } from "../../../../shared/Models/ChatAddress.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";
import { targetsAreEqual } from "../../helpers/targets.js";

export interface SetRemoteSearchResultsPayload {
  targets: Array<EmailChatTarget | StandardGroupChatTarget>;
  searchType: string;
}

export interface SetQueryPayload {
  query?: string;
  chatId?: string;
  enableAll?: boolean;
  enableMagicMinutes?: "meeting" | "thread";
}

const slice = createSlice({
  name: "mention",
  initialState: {
    allResult: false,
    localSearchResults: new Array<MentionTarget>(),
    remoteSearchResultsBuckets: {} as {
      [searchType: string]: Array<EmailChatTarget | StandardGroupChatTarget>;
    },
    query: undefined as string | undefined,
  },
  reducers: {
    setQuery: (state, action: PayloadAction<SetQueryPayload>) => {
      const { query } = action.payload;
      state.query = query;
      if (!query) {
        state.localSearchResults = [];
        state.remoteSearchResultsBuckets = {};
      }
    },
    setLocalSearchResults: (state, action: PayloadAction<MentionTarget[]>) => {
      state.localSearchResults = action.payload;
    },
    setRemoteSearchResults: (state, action: PayloadAction<SetRemoteSearchResultsPayload>) => {
      const { targets, searchType } = action.payload;
      state.remoteSearchResultsBuckets[searchType] = targets;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.mention;
export const selectors = {
  selectSearchResults: createDeepEqualSelector(selectSlice, (slice) => {
    const results = new Array<MentionTarget>();
    results.push(...slice.localSearchResults);

    for (const searchType of ["member", "group", "connection", "social", "bot"]) {
      for (const remoteResult of slice.remoteSearchResultsBuckets[searchType] ?? []) {
        // Replace local results in place with remote results, since remote results may be more
        // complete. This must be in-place to avoid re-ordering the results.
        const existing = results.findIndex(
          (r) =>
            (r.targetType === "email" || r.targetType === "standardGroup") &&
            targetsAreEqual(r, remoteResult)
        );
        if (existing !== -1) {
          results[existing] = remoteResult;
        } else {
          results.push(remoteResult);
        }
      }
    }

    return results;
  }),
};

export const MentionSelectors = selectors;
export const MentionActions = actions;
