import { z } from "zod";

const themes = [
  "Caves",
  "Clique",
  "Club",
  "Collab",
  "Compute",
  "Felt",
  "Good_Vibes",
  "Horse",
  "Quartet",
  "Roamverse",
  "Tonally",
  "Waves",
] as const;
export const NotificationTheme = z.enum(themes);
export type NotificationTheme = z.infer<typeof NotificationTheme>;

export const NotificationItem = z.enum([
  "calendarEvent",
  // There was a time when there was both chatIncoming and chatIncomingUnreadOnly
  // that were the same sound but configured separately, but chatIncoming was removed.
  // chatIncomingUnreadOnly was not renamed to retain existing user settings.
  "chatIncomingUnreadOnly",
  "chatOutgoing",
  "inviteOver",
  "knock",
  "wave",
  "someoneEnteredRoom",
  "someoneLeftRoom",
  "guestArrival",
]);
export type NotificationItem = z.infer<typeof NotificationItem>;

export const NotificationOption = z.enum([
  // Notification sound should be inherited from a "parent" configuration/theme.
  "INHERIT",
  // No notification sound.
  "NONE",
  ...themes,
]);
export type NotificationOption = z.infer<typeof NotificationOption>;

export const NotificationConfiguration = z.record(NotificationItem, NotificationOption);
export type NotificationConfiguration = z.infer<typeof NotificationConfiguration>;

export const NotificationSounds = z.record(NotificationItem, z.string().nullable());
export type NotificationSounds = z.infer<typeof NotificationSounds>;
