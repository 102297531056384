import { z } from "zod";

export const numberId = (): z.ZodNumber => z.number().int();
export const stringId = (): z.ZodString => z.string().min(1);
export const timestamp = (): z.ZodNumber => z.number().int().positive();
export const uuid = (): z.ZodEffects<z.ZodString> =>
  z
    .string()
    .uuid()
    .min(1)
    .transform((id) => id.toLowerCase());

/**
 * @deprecated Please use {@link timestamp} instead.
 */
export const stringDate = () =>
  z.preprocess(
    (arg) => (typeof arg === "string" ? new Date(arg) : arg),
    z.date()
  ) as z.ZodEffects<z.ZodDate>;
