export const isValidUuid = (uuid: string): boolean => {
  return /^[\da-z]{8}-[\da-z]{4}-4[\da-z]{3}-[\da-z]{4}-[\da-z]{12}$/.test(uuid);
};

export const cleanEmail = (email: string): string => email.toLowerCase().trim();

export const cleanOptionalEmail = (email?: string): string | undefined =>
  email ? email.toLowerCase().trim() : undefined;

export type ValidationResult<C> = { success: true; ctx: C } | { success: false; reason: string };
