import { ClientContainerInfo } from "../../injection/IClientContainer.js";
import { IRedux } from "../../injection/redux/IRedux.js";
import { ensureAudioContextClosed } from "../helpers.js";
import { PostProcAudioSpec } from "../interfaces/IAudioPipelineLauncher.js";
import { IPostProcAudioPipeline } from "../interfaces/IPostProcAudioPipeline.js";
import { AudioLevelVoiceDetector } from "./AudioLevelVoiceDetector.js";

interface Injected {
  info(): ClientContainerInfo;
  redux(): IRedux;
}

export class VADOnlyPostProcAudioPipeline implements IPostProcAudioPipeline {
  // These should only be modified inside updateInner
  private context: AudioContext | undefined;
  private source: MediaStreamAudioSourceNode | undefined;
  private analyserNode: AnalyserNode | undefined;

  public readonly voiceDetector = new AudioLevelVoiceDetector();

  constructor(private container: Injected) {}

  async update(
    spec: PostProcAudioSpec | undefined,
    rawStream: MediaStream | undefined
  ): Promise<MediaStream | undefined> {
    if (rawStream) {
      // Note: This should all run even if noise suppression is not requested; we need the chain of nodes set up to do VAD
      if (!this.context) {
        this.context = new AudioContext({
          latencyHint: "interactive",
          sampleRate: 48000,
        });
      }

      // Resume the AudioContext if it was suspended
      if (this.context.state === "suspended") {
        await new Promise((resolve, reject) => {
          // AudioContext.resume() hangs if it is called before there has been a user gesture on the page
          // As a precaution, use a short timeout
          let timeoutId: ReturnType<typeof setTimeout> | undefined = setTimeout(() => {
            timeoutId = undefined;
            reject(new Error("AudioContext.resume took too long"));
          }, 1000);
          if (!this.context) throw new Error("this.context is unexpectedly undefined");
          this.context
            .resume()
            .then(resolve)
            .catch(reject)
            .finally(() => {
              if (timeoutId) {
                clearTimeout(timeoutId);
              }
            });
        });
      }
      // Make sure the AudioContext *is* actually now running
      if (this.context.state !== "running") {
        // If it isn't, then trying to proceed will result in no audio flowing. Throw an error; we'll
        // fall back on not using noise suppression (which is preferable).
        throw new Error(`AudioContext has unexpected state '${this.context.state}'`);
      }

      if (!this.analyserNode) {
        this.analyserNode = new AnalyserNode(this.context);
        this.analyserNode.fftSize = 2048;
      }

      this.voiceDetector.start(this.analyserNode);

      if (!this.source || this.source.mediaStream !== rawStream) {
        // Disconnect the old source node (if any)
        this.source?.disconnect();
        this.source = undefined;
        // Create a new source node and connect it
        this.source = this.context.createMediaStreamSource(rawStream);
        this.source.connect(this.analyserNode);
      }
    } else {
      // Suspend the context (if we have one) to relinquish access to the hardware (necessary to avoid
      // audio quality issues with some applications that don't like other things accessing audio)
      await this.context?.suspend();

      this.voiceDetector.stop();

      // Disconnect the source node (if there is one)
      this.source?.disconnect();
      this.source = undefined;
    }

    // We do no processing, just analysis, so never return a stream
    return undefined;
  }

  public async close() {
    this.voiceDetector.stop();

    if (this.context) {
      ensureAudioContextClosed(this.context);
    }
  }

  public dump(): any {
    return {
      hasContext: !!this.context,
      voiceDetector: this.voiceDetector.dump(),
    };
  }
}
