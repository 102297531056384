import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { ItemType } from "../../../../shared/Models/Item.js";
import { SackItem } from "../../../../shared/Models/SackItem.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const sackItemAdapter = createEntityAdapter<SackItem>({
  selectId: (si) => `${si.sackOwner}_${si.itemId}_${si.accountId ?? "none"}`,
  sortComparer: (a, b) => {
    return b.lastUsed - a.lastUsed;
  },
});

export interface UsedItemPayload {
  email: string;
  itemId: string;
}

const slice = createSlice({
  name: "sackItem",
  initialState: sackItemAdapter.getInitialState(),
  reducers: {
    requestSackItems: (state, action: PayloadAction<ItemType[] | undefined>) => {},
    usedItem: (state, action: PayloadAction<UsedItemPayload>) => {},
    upsertSackItems: (state, action: PayloadAction<SackItem[]>) => {
      sackItemAdapter.upsertMany(state, action.payload);
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.things.sackItem;
const adapterSelectors = sackItemAdapter.getSelectors(selectSlice);
export const selectors = {
  selectSackItems: defaultMemoize(
    (accountId: number | undefined, itemTypes?: ItemType[]) =>
      createDeepEqualSelector(
        adapterSelectors.selectAll,
        (state: RootState) => state.things.item,
        (sackItems, itemSlice) => {
          // Ordering of the sackItems is most recently used first.
          const matchingSackItems = new Array<SackItem>();
          for (const sackItem of sackItems) {
            const item = itemSlice.items.entities[sackItem.itemId];
            if (
              item &&
              (!accountId || accountId === sackItem.accountId) &&
              (!itemTypes || itemTypes.includes(item.itemType))
            ) {
              matchingSackItems.push(sackItem);
            }
          }
          return matchingSackItems;
        }
      ),
    {
      equalityCheck: equal,
    }
  ),
};
