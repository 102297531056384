import { Dimensions } from "@floating-ui/react";
import { blankImageBitmap } from "../../../../helpers/imagesWorker.js";
import {
  compileShader,
  createPipelineStageProgram,
  createTextureSimple,
  glsl,
} from "../../webglHelper.js";

export interface BackgroundImageStageMP {
  render(): void;
  updateCoverage(coverage: [number, number]): void;
  updateLightWrapping(lightWrapping: number): void;
  setForegroundOverlays(stages: ImageBitmap[]): void;
  setBackgroundImage(src: ImageBitmap): void;
  cleanUp(): void;
}

/*
 * Replaces the background of the source frame in unit 0 given a mask in unit 1 of the foreground.
 * The background image is set with setBackgroundImage() and one or more overlays to draw on top of
 * everything set with setForegroundOverlays.
 * Writes to null framebuffer.
 *
 */
export const buildBackgroundImageStageMP = (
  gl: WebGL2RenderingContext,
  positionBuffer: WebGLBuffer,
  texCoordBuffer: WebGLBuffer
): BackgroundImageStageMP => {
  const vertexShaderSource = glsl`#version 300 es

    uniform vec2 u_backgroundScale;
    uniform vec2 u_backgroundOffset;

    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;
    out vec2 v_backgroundCoord;

    void main() {
      // Flipping Y is required when rendering to canvas
      gl_Position = vec4(a_position * vec2(1.0, -1.0), 0.0, 1.0);
      v_texCoord = a_texCoord;
      v_backgroundCoord = a_texCoord * u_backgroundScale + u_backgroundOffset;
    }
  `;

  const fragmentShaderSource = glsl`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_personMask;
    uniform sampler2D u_background;
    uniform sampler2D u_foregroundOverlay;
    uniform vec2 u_coverage;
    uniform float u_lightWrapping;
    uniform float u_blendMode;

    in vec2 v_texCoord;
    in vec2 v_backgroundCoord;

    out vec4 outColor;

    void main() {
      vec3 frameColor = texture(u_inputFrame, v_texCoord).rgb;
      vec3 backgroundColor = texture(u_background, v_backgroundCoord).rgb;
      float personMask = texture(u_personMask, v_texCoord).r;
      vec3 overlayColor = texture(u_foregroundOverlay, v_backgroundCoord).rgb;
      float overlayMask = texture(u_foregroundOverlay, v_backgroundCoord).a;
      personMask = smoothstep(u_coverage.x, u_coverage.y, personMask);
      outColor = vec4(mix(mix(backgroundColor, overlayColor, overlayMask), frameColor, personMask), 1.0);
    }
  `;

  const { width: outputWidth, height: outputHeight } = gl.canvas;

  const vertexShader = compileShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = compileShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);
  const program = createPipelineStageProgram(
    gl,
    vertexShader,
    fragmentShader,
    positionBuffer,
    texCoordBuffer
  );
  const backgroundScaleLocation = gl.getUniformLocation(program, "u_backgroundScale");
  const backgroundOffsetLocation = gl.getUniformLocation(program, "u_backgroundOffset");
  const inputFrameLocation = gl.getUniformLocation(program, "u_inputFrame");
  const personMaskLocation = gl.getUniformLocation(program, "u_personMask");
  const backgroundLocation = gl.getUniformLocation(program, "u_background");
  const foregroundOverlayLocation = gl.getUniformLocation(program, "u_foregroundOverlay");
  const coverageLocation = gl.getUniformLocation(program, "u_coverage");
  const lightWrappingLocation = gl.getUniformLocation(program, "u_lightWrapping");
  const blendModeLocation = gl.getUniformLocation(program, "u_blendMode");

  gl.useProgram(program);
  gl.uniform2f(backgroundScaleLocation, 1, 1);
  gl.uniform2f(backgroundOffsetLocation, 0, 0);
  gl.uniform1i(inputFrameLocation, 0);
  gl.uniform1i(personMaskLocation, 1);
  gl.uniform1i(backgroundLocation, 2);
  gl.uniform1i(foregroundOverlayLocation, 3);
  gl.uniform2f(coverageLocation, 0, 1);
  gl.uniform1f(lightWrappingLocation, 0);
  gl.uniform1f(blendModeLocation, 0);

  const utilityCanvas = new OffscreenCanvas(outputWidth, outputHeight);
  const utilityCanvasCtx = utilityCanvas.getContext("2d");
  if (!utilityCanvasCtx) {
    throw new Error("unable to create utilityCanvasCtx");
  }

  const backgroundTexture = createTextureSimple(gl);
  let backgroundImageNeedsUpdate = true;
  let backgroundImageSource = blankImageBitmap(400, 300);

  const foregroundOverlayTexture = createTextureSimple(gl);
  let foregroundOverlays: ImageBitmap[] = [];
  let foregroundOverlaysNeedUpdate = true;

  const render = () => {
    gl.viewport(0, 0, outputWidth, outputHeight);
    gl.useProgram(program);

    if (backgroundImageNeedsUpdate) {
      drawImageFitCover(backgroundImageSource, utilityCanvasCtx, {
        width: outputWidth,
        height: outputHeight,
      });
      gl.activeTexture(gl.TEXTURE2);
      gl.bindTexture(gl.TEXTURE_2D, backgroundTexture);
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, utilityCanvas);
      backgroundImageNeedsUpdate = false;
    }
    if (foregroundOverlaysNeedUpdate) {
      utilityCanvas.width = outputWidth;
      utilityCanvas.height = outputHeight;
      utilityCanvasCtx.clearRect(0, 0, outputWidth, outputHeight);
      for (const img of foregroundOverlays) {
        utilityCanvasCtx.drawImage(img, 0, 0, outputWidth, outputHeight);
      }
      gl.activeTexture(gl.TEXTURE3);
      gl.bindTexture(gl.TEXTURE_2D, foregroundOverlayTexture);
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, utilityCanvas);
      foregroundOverlaysNeedUpdate = false;
    }

    gl.activeTexture(gl.TEXTURE2);
    gl.bindTexture(gl.TEXTURE_2D, backgroundTexture);
    gl.activeTexture(gl.TEXTURE3);
    gl.bindTexture(gl.TEXTURE_2D, foregroundOverlayTexture);

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  };

  const updateCoverage = (coverage: [number, number]) => {
    gl.useProgram(program);
    gl.uniform2f(coverageLocation, coverage[0], coverage[1]);
  };

  const updateLightWrapping = (lightWrapping: number) => {
    gl.useProgram(program);
    gl.uniform1f(lightWrappingLocation, lightWrapping);
  };

  const setForegroundOverlays = (images: ImageBitmap[]) => {
    foregroundOverlays = [...images];
    foregroundOverlaysNeedUpdate = true;
  };

  const setBackgroundImage = (src: ImageBitmap) => {
    backgroundImageSource = src;
    backgroundImageNeedsUpdate = true;
  };

  const cleanUp = () => {
    gl.deleteTexture(backgroundTexture);
    gl.deleteProgram(program);
    gl.deleteShader(fragmentShader);
    gl.deleteShader(vertexShader);
  };

  return {
    render,
    updateCoverage,
    updateLightWrapping,
    setForegroundOverlays,
    setBackgroundImage,
    cleanUp,
  };
};

// Draw an image according to object-fit: cover, meaning the image will be
// drawn maintaining its aspect ratio and ensuring that it completely fills
// the output canvas. The image may be cropped to only show its middle
// portion, meaning its top/bottom or left/right may be clipped.
export const drawImageFitCover = (
  image: ImageBitmap,
  ctx: OffscreenCanvasRenderingContext2D,
  outputDims: Dimensions
) => {
  const { width: outputWidth, height: outputHeight } = outputDims;

  const imageRatio = image.width / image.height;
  const outputRatio = outputWidth / outputHeight;

  let width = outputWidth;
  let height = outputHeight;
  if (imageRatio < outputRatio) {
    height = width / imageRatio;
  } else {
    width = height * imageRatio;
  }

  const offsetX = (outputWidth - width) * 0.5;
  const offsetY = (outputHeight - height) * 0.5;

  ctx.drawImage(image, offsetX, offsetY, width, height);
};
