import { z } from "zod";

/**
 * Desired destination is wherever the host is. Used for personal links.
 */
export const PersonDestination = z.object({
  type: z.literal("person"),
  /**
   * ID of the hostPerson.
   */
  personId: z.number(),
  roamId: z.number(),
});
export type PersonDestination = z.infer<typeof PersonDestination>;
