import { z } from "zod";
import { ChatAddress } from "./ChatAddress.js";
import { ChatMessage } from "./ChatMessage.js";
import { numberId, uuid } from "./zodTypes.js";

export const TeamRoamEmailAddress = "cx@ro.am";

export const TeamRoamChat = z.object({
  chatId: uuid(),
  email: z.string().email(),
  accountId: numberId(),
  lastCustomerMessageTime: z.number().optional(),
  answeredThrough: z.number().optional(),
});
export type TeamRoamChat = z.infer<typeof TeamRoamChat>;

// Fake accountIds used for summaries.
export const UnansweredNoTeamId = -2;
export const UnansweredId = -1;

export const TeamRoamAccountSummary = z.object({
  accountId: z.number(),
  accountName: z.string().optional(),
  unanswered: z.number(),
});
export type TeamRoamAccountSummary = z.infer<typeof TeamRoamAccountSummary>;

export const TeamRoamChatInfo = TeamRoamChat.and(
  z.object({
    teamRoamAddressId: uuid(),
    customerAddress: ChatAddress,
    lastMessage: ChatMessage.optional(),
    teamMemberAddressIds: uuid().array(),
    teamMembers: ChatAddress.array().optional(),
  })
);
export type TeamRoamChatInfo = z.infer<typeof TeamRoamChatInfo>;

export const TeamRoamUpdates = z.object({
  accountSummaries: TeamRoamAccountSummary.array(),

  // Optionally includes comprehensive list for exactly one account
  detailAccountId: z.number().optional(),
  teamRoamChats: z.array(TeamRoamChatInfo).optional(),
});
export type TeamRoamUpdates = z.infer<typeof TeamRoamUpdates>;
