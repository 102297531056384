import { z } from "zod";
import { UserRole } from "./UserRole.js";

/**
 * An access level based on the user's role.
 *
 * A level is intended to allow users with that role, and all roles "above" it.
 */
export const RoleLevel = z.enum(
  // Keep from least to most permissible.
  ["NONE", UserRole.enum.Admin, UserRole.enum.User]
);
export type RoleLevel = z.infer<typeof RoleLevel>;

/**
 * Whether or not the specified [role] is at or above the given [level].
 */
export const atOrAboveRoleLevel = (level: RoleLevel, role?: UserRole): boolean => {
  if (role === undefined) {
    return false;
  }
  if (role !== "Admin" && role !== "User") {
    return false;
  }
  return RoleLevel.options.indexOf(role) <= RoleLevel.options.indexOf(level);
};

/**
 * Returns a display-friendly string for the given [level].
 */
export const displayRoleLevel = (level: RoleLevel): string => {
  switch (level) {
    case "NONE":
      return "None";
    case "Admin":
      return "Admin";
    case "User":
      return "Member";
    default:
      throw new Error("Unhandled displayRoleLevel!");
  }
};
