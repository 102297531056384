import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Position } from "../../../../shared/geometry/Position.js";
import { Rectangle } from "../../../../shared/geometry/Rectangle.js";
import { logger } from "../../../../shared/infra/logger.js";
import { Knock } from "../../../../shared/Models/Interaction.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface KnockOnRoomPayload {
  floorId: number;
  roomId: number;
}

export interface AnswerKnockPayload {
  floorId: number;
  roomId: number;
  knockId: string;
  answer: "comeIn" | "notAvailable";
  message?: string;
  unmute?: boolean;
}

export interface KnockPayload {
  roomId: number;
  knockId: string;
}

const slice = createSlice({
  name: "knock",
  initialState: {
    incomingKnocksByRoom: {} as {
      [roomId: number]: Knock[];
    },
    outgoingKnock: undefined as Knock | undefined,
    occupantPositions: {} as {
      [occupantId: string]: Position;
    },
    knockingRoomRect: undefined as Rectangle | undefined,
    dataForSectionId: undefined as string | undefined,
  },
  reducers: {
    knockOnRoom: (state, action: PayloadAction<KnockOnRoomPayload>) => {},
    answerKnock: (state, action: PayloadAction<AnswerKnockPayload>) => {},
    cancelOutgoingKnock: (state) => {},
    acknowledgeAnyOutgoingKnockResult: (state) => {
      if (state.outgoingKnock?.result) {
        state.outgoingKnock = undefined;
      }
    },
    setOutgoingKnock: (state, action: PayloadAction<Knock | undefined>) => {
      state.outgoingKnock = action.payload;
    },

    setSectionId: (state, action: PayloadAction<string>) => {
      const newSectionId = action.payload;
      if (state.dataForSectionId !== newSectionId) {
        state.outgoingKnock = undefined;
        state.incomingKnocksByRoom = {};
        state.dataForSectionId = newSectionId;
      }
    },

    serverIncomingKnock: (state, action: PayloadAction<Knock>) => {
      const knock = action.payload;
      const current = state.incomingKnocksByRoom[knock.roomId];
      if (!current || current.length === 0) {
        state.incomingKnocksByRoom[knock.roomId] = [knock];
      } else {
        const i = current.findIndex((k) => k.id === knock.id);
        if (i >= 0) {
          current[i] = knock;
        } else {
          current.push(knock);
        }
      }
    },
    serverKnockResult: (state, action: PayloadAction<Knock>) => {
      const knock = action.payload;
      const { id, result, roomId } = knock;

      if (!result?.status) {
        logger.warn(`serverKnockResult: result received with no status`);
        return;
      }

      const roomKnocks = state.incomingKnocksByRoom[roomId];
      if (roomKnocks) {
        const i = roomKnocks.findIndex((k) => k.id === id);
        if (i >= 0) {
          roomKnocks[i] = knock;
        }
      }

      if (state.outgoingKnock?.id === knock.id) {
        if (result.status === "comeIn" || result.status === "left" || result.status === "entered") {
          state.outgoingKnock = undefined;
        } else {
          state.outgoingKnock = knock;
        }
      }
    },
    removeIncomingKnock: (state, action: PayloadAction<KnockPayload>) => {
      const { roomId, knockId } = action.payload;
      const roomKnocks = state.incomingKnocksByRoom[roomId];
      if (roomKnocks) {
        const i = roomKnocks.findIndex((k) => k.id === knockId);
        if (i >= 0) roomKnocks.splice(i, 1);
      }
    },

    setKnockingRoomRect: (state, action: PayloadAction<Rectangle>) => {
      state.knockingRoomRect = action.payload;
    },
  },
});

export const { actions, reducer } = slice;
export const KnockActions = actions;

const selectSlice = (state: RootState) => state.section.knock;
export const selectors = {
  selectOutgoingKnock: (state: RootState) => selectSlice(state).outgoingKnock,
  selectIncomingKnocksByRoom: (roomId?: number) => (state: RootState) =>
    roomId ? selectSlice(state).incomingKnocksByRoom[roomId] : undefined,
  selectKnockingRoom: createSelector(
    selectSlice,
    (state) => state.section.myLocation?.internalRenderMyLocation?.myLocation,
    (slice, myLocation) => {
      if (slice.outgoingKnock) {
        return slice.outgoingKnock.roomId;
      }
      if (myLocation?.kind === "RoomLocation") {
        const roomId = myLocation.roomId;
        if ((slice.incomingKnocksByRoom?.[roomId]?.length || 0) > 0) {
          return roomId;
        }
      }
      return undefined;
    }
  ),
  selectKnockingRoomRect: (state: RootState) => selectSlice(state).knockingRoomRect,
  selectDataForSectionId: (state: RootState) => selectSlice(state).dataForSectionId,
};
export const KnockSelectors = selectors;

export const notAvailableAction = (knock: Knock, message: string) =>
  KnockActions.answerKnock({
    floorId: knock.floorId,
    roomId: knock.roomId,
    knockId: knock.id,
    answer: "notAvailable",
    message,
  });
