import Color from "color";
import { semanticColors } from "./colors.js";

type BackgroundMaterial = "blur" | "lightBlur" | "confidentialBlur";

const blurMaterialStyles = (
  backgroundColor: Color<string>,
  useTransparency: boolean,
  alpha = 0.64,
  blurPx = 24
) => {
  const baseStyle = `
  background-color: ${backgroundColor.string()};
  `;

  const blurStyle = `
  @supports (backdrop-filter: blur(${blurPx}px)) {
    background-color: ${backgroundColor.alpha(alpha).string()};

    // Placing backdrop-filter inside :before makes nested blurring work
    // A component should not both have a blurred background and also
    // overflow/scroll. If used together, the blur will move with the scroll,
    // and the content initially below the fold will not have a blurred
    // background. This is a known CSS issue, and it can be fixed with
    // position: sticky (amongst other changes), but that doesn't work with
    // the backdrop-filter effect. If both blur and overflow/scroll are needed,
    // have a parent component with the background blur and a child component
    // that overflows/scrolls.
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(${blurPx}px);
      z-index: -999999;
      border-radius: inherit;

      // Workaround for Chromium bug where backdrop-filter does not expand
      // after viewport resize (e.g., UserMediaControls video dropdown,
      // expand/contract video preview)
      // See https://bugs.chromium.org/p/chromium/issues/detail?id=1416183
      // See https://bugs.chromium.org/p/chromium/issues/detail?id=1417549
      filter: blur(0);
    }
  }
  `;
  return `
    ${baseStyle}
    ${useTransparency ? blurStyle : ""}
  `;
};

const backgroundMaterialStyles = (
  useTransparency: boolean
): { [backgroundMaterial in BackgroundMaterial]: string } => {
  return {
    blur: blurMaterialStyles(Color(semanticColors.background.elevatedPrimary), useTransparency),
    lightBlur: blurMaterialStyles(Color("#ffffff"), useTransparency, 0.15, 34),
    confidentialBlur: blurMaterialStyles(Color("#211301"), useTransparency),
  };
};

export default backgroundMaterialStyles;
