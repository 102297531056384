import { z } from "zod";
import { numberId } from "../zodTypes.js";
import { AccessLinkId } from "./AccessLinkId.js";

/**
 * Base access link type, which specific types should extend.
 */
export const BaseAccessLink = AccessLinkId.extend({
  // NOTE(vple):
  // Seems like these shouldn't necessarily be required, but the original three access link types have them.
  // Making them optional would involve hunting down the usages and fixing those.
  roamId: numberId(),
  hostPersonId: numberId(),
});
export type BaseAccessLink = z.infer<typeof BaseAccessLink>;
