import { z } from "zod";

// NOTE (vple):
//  Currently, customers cannot set aliases themselves.
//  This is automatically configured via OAuth setup (or manually by us), so it is trusted.
//  Our logic tends to assume that being an alias also means that the domain is verified.
//  If we ever want to make alias customer-configurable, we likely need to update logic to consider
//  verification in addition to alias.

const BaseVerifiedDomain = z.object({
  /** The id of the account that this verified domain is for. */
  accountId: z.number(),
  /**
   * The domain, such as "gmail.com".
   */
  domain: z.string(),
  /**
   * If true, allows verified humans to join the corresponding account for the domain.
   *
   * A human is verified if they have a confirmed email with a matching domain.
   */
  allowSignups: z.boolean(),
  /**
   * If specified, the ID of the verified domain that this domain is an alias of.
   *
   * Chained aliasing is not allowed.
   * You cannot have C -> alias -> B -> alias -> A.
   * Instead, C and B must both alias to A.
   */
  aliasDomainId: z.number().optional(),
  /**
   * Whether or not this domain has been verified.
   *
   * NOTE: (2022-07-15)
   *  Historically, we haven't used this field and it will be false for many existing domains.
   *  It doesn't actually control/verify anything yet.
   *  All `VerifiedDomain` types will have been "verified" (as of today) by either:
   *    - Verified domain is added by a user whose email domain matches the new domain.
   *      These have verified === false.
   *    - Verified domain is added via OAuth flow, where we trust the OAuth provider.
   *      These have verified passed in from OAuth, but we currently skip any domains that the
   *      OAuth provider hasn't verified.
   *      In other words, these always have verified === true.
   */
  verified: z.boolean(),
  /**
   * Whether or not the signup code auth mechanism can be used for this verified domain.
   *
   * This setting may be overridden at the account level.
   */
  allowSignupCode: z.boolean(),
  /**
   * Whether or not the SSO auth mechanism can be used for this verified domain.
   *
   * This setting may be overridden at the account level.
   */
  allowSso: z.boolean(),
  /**
   * Whether to force TURN TLS for anyone with an email in this domain.
   *
   * Needed when security software (EDR or Firewalls) play havoc with
   * UDP traffic without fully blocking it.
   */
  forceTurnTLS: z.boolean(),
});
type BaseVerifiedDomain = z.infer<typeof BaseVerifiedDomain>;

export const NewVerifiedDomain = BaseVerifiedDomain.extend({
  noSync: z.boolean().optional(),
});
export type NewVerifiedDomain = z.infer<typeof NewVerifiedDomain>;

/**
 * A verified domain.
 *
 * A verified domain is a domain that an account has verified ownership/control over.
 * Verified domains allow us to create flows that are available to anyone within that account.
 *
 * Each domain (e.g. "ro.am") may be owned by at most one account.
 */
export const VerifiedDomain = BaseVerifiedDomain.extend({
  id: z.number(),
  /**
   * Prevent this domain from being curated by sync processes.
   *
   * ie, when true, this domain is manually forced into the db and we
   * dont want any automated processes to update/delete it.
   */
  noSync: z.boolean(),
});
export type VerifiedDomain = z.infer<typeof VerifiedDomain>;

// --- UTILITY ---

/**
 * Returns true if the [verifiedDomain]'s domain exactly matches the [email]'s domain.
 */
export const matchesEmail = (verifiedDomain: BaseVerifiedDomain, email: string): boolean => {
  const [_, emailDomain] = email.split("@");
  return verifiedDomain.domain === emailDomain;
};

/**
 * Returns an email address in [verifiedDomain]'s domain from the given [emailOrLocalPart].
 *
 * The local part (the part before the @) of the returned email depends on [emailOrLocalPart]:
 *   - If it has an "@" (i.e. if it is an email address), parse the local part from that email.
 *   - Otherwise, use [emailOrLocalPart] entirely.
 *
 * More simply, for a `VerifiedDomain` with domain "ro.am":
 *
 * <pre>
 *  constructDomainEmail(domain, "vple") === "vple@ro.am"
 *  constructDomainEmail(domain, "vple@wonder.inc") === "vple@ro.am"
 * </pre>
 */
export const constructDomainEmail = (
  verifiedDomain: BaseVerifiedDomain,
  emailOrLocalPart: string
): string => {
  const localPart = emailOrLocalPart.split("@")[0];
  return `${localPart}@${verifiedDomain.domain}`;
};

/**
 * Applies and returns `domainEmail` to each element of `verifiedDomains`.
 */
export const constructDomainEmails = (
  verifiedDomains: BaseVerifiedDomain[],
  emailOrLocalPart: string
): string[] => {
  return verifiedDomains.map((verifiedDomain) =>
    constructDomainEmail(verifiedDomain, emailOrLocalPart)
  );
};
