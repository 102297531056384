import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { RecordingAssignment, SavedRecording } from "../../../../shared/Models/Recording.js";
import { Transcription } from "../../../../shared/Models/Transcriptions.js";
import { createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

export interface UpdateSavedRecordingPayload {
  id: string;
  shareable?: boolean;
}

export interface TranscriptionWithAssociatedIds extends Transcription {
  savedMeetingId: string;
  groupId?: string;
  recordingId?: string;
}

interface State {
  myAssignments: RecordingAssignment[];
  myRecordings: SavedRecording[];
  hasRecordingAssignment: { [roomId: number]: boolean };
  hasOngoingTranscription: { [roomId: number]: boolean };
  myRecordingsStale: boolean;
  lastPlayedSound: "in_progress" | "stopped" | undefined;
  lastPlayedSoundRoomId: number | undefined;
  transcriptions: TranscriptionWithAssociatedIds[];
}

const slice = createSlice({
  name: "recording",
  initialState: {
    myAssignments: [] as RecordingAssignment[],
    myRecordings: [] as SavedRecording[],
    hasRecordingAssignment: {} as { [roomId: number]: boolean },
    hasOngoingTranscription: {} as { [roomId: number]: boolean },
    myRecordingsStale: false,
    lastPlayedSound: undefined as "in_progress" | "stopped" | undefined,
    lastPlayedSoundRoomId: undefined as number | undefined,
    transcriptions: [] as TranscriptionWithAssociatedIds[],
  },
  reducers: {
    setMyAssignments: (state: State, action: PayloadAction<RecordingAssignment[]>) => {
      state.myAssignments = action.payload;
    },
    fetchMyAssignments: () => {},

    setMyRecordings: (state: State, action: PayloadAction<SavedRecording[]>) => {
      state.myRecordings = action.payload;
    },
    fetchMyRecordings: () => {},
    updateSingleMyRecording: (state: State, action: PayloadAction<SavedRecording>) => {
      const recording = action.payload;
      const i = state.myRecordings.findIndex((r) => r.id === recording.id);
      if (i >= 0) {
        state.myRecordings[i] = recording;
      } else {
        // Insert at the start
        state.myRecordings.splice(0, 0, recording);
      }
    },
    setMyRecordingsStale: (state: State, action: PayloadAction<boolean>) => {
      state.myRecordingsStale = action.payload;
    },

    updateSavedRecording: (
      _state: State,
      _action: PayloadAction<UpdateSavedRecordingPayload>
    ) => {},

    setHasRecordingAssignment: (
      state: State,
      action: PayloadAction<{ roomId: number; hasAssignment: boolean }>
    ) => {
      const { roomId, hasAssignment } = action.payload;
      state.hasRecordingAssignment[roomId] = hasAssignment;
    },

    setHasOngoingTranscription: (
      state: State,
      action: PayloadAction<{ roomId: number; hasAssignment: boolean }>
    ) => {
      const { roomId, hasAssignment } = action.payload;

      // Clear the previous state. We only want to keep track of one roomId at a time. If we switch
      // rooms we should always check with the server again for an ongoing transcription, since the
      // state may have changed since the last time we were in the room.
      state.hasOngoingTranscription = {};

      state.hasOngoingTranscription[roomId] = hasAssignment;
    },

    checkHasRecordingAssignment: () => {},

    checkHasOngoingTranscription: () => {},

    // The payload is mustStartNow (see roam/shared/RecordingMessages/ClientRpcRecording.ts)
    // undefined means "try with true, then prompt the user if it fails"
    startRecording: (
      _state: State,
      _action: PayloadAction<{
        mustStartNow: boolean | undefined;
        meetingGuid: string | undefined;
        windowKey: WindowKey;
      }>
    ) => {},
    stopRecording: (_state: State, _action: PayloadAction<{ shred?: boolean }>) => {},

    startTranscription: (_state: State, _action: PayloadAction<{ meetingGuid: string }>) => {},
    stopTranscription: (_state: State, _action: PayloadAction<{ shred?: boolean }>) => {},

    deleteRecording: (_state, _action: PayloadAction<string>) => {},

    setLastPlayedSound: (
      state: State,
      action: PayloadAction<"in_progress" | "stopped" | undefined>
    ) => {
      state.lastPlayedSound = action.payload;
    },
    setLastPlayedSoundRoomId: (state: State, action: PayloadAction<number | undefined>) => {
      state.lastPlayedSoundRoomId = action.payload;
    },

    fetchTranscriptionByGroupId: (_state: State, _action: PayloadAction<string>) => {},
    fetchTranscriptionByRecordingId: (_state: State, _action: PayloadAction<string>) => {},
    addTranscription: (state: State, action: PayloadAction<TranscriptionWithAssociatedIds>) => {
      // We intentionally keep only one transcript in the redux state at once. If the transcript is
      // missing when the download dropdown is clicked we will reload it.
      state.transcriptions = [action.payload];
    },

    showRecordingReminder: (_state: State, _action: PayloadAction<number>) => {},
  },
});
export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.world.recording;
export const selectors = {
  selectMyAssignments: (state: RootState) => selectSlice(state).myAssignments,
  selectMyRecordings: (state: RootState) => selectSlice(state).myRecordings,
  selectMyRecordingsStale: (state: RootState) => selectSlice(state).myRecordingsStale,
  selectHasRecordingAssignment: (roomId?: number) => (state: RootState) =>
    roomId ? selectSlice(state).hasRecordingAssignment[roomId] : false,
  selectHasOngoingTranscription: (roomId?: number) => (state: RootState) =>
    roomId ? selectSlice(state).hasOngoingTranscription[roomId] : false,
  selectLastPlayedSound: (state: RootState) => selectSlice(state).lastPlayedSound,
  selectLastPlayedSoundRoomId: (state: RootState) => selectSlice(state).lastPlayedSoundRoomId,
  selectTranscriptionByGroupId: defaultMemoize((groupId?: string) =>
    createSelector(selectSlice, (slice) =>
      groupId
        ? // TODO: do we have to worry about there being more than one transcription with the same
          //  groupId? Currently these should be one-to-one, but it's possible that could change.
          slice.transcriptions.find((transcription) => transcription.groupId === groupId)
        : undefined
    )
  ),
  selectTranscriptionByRecordingId: defaultMemoize((recordingId?: string) =>
    createSelector(selectSlice, (slice) =>
      recordingId
        ? slice.transcriptions.find((transcription) => transcription.recordingId === recordingId)
        : undefined
    )
  ),
};
