import { z } from "zod";
import {
  AddressIdTargetKey,
  BotTargetKey,
  ClientTargetKey,
  EmailTargetKey,
  OccupantTargetKey,
  TeamRoamTargetKey,
} from "./TargetKey.js";
import { numberId, stringId } from "./zodTypes.js";

export const ChatTargetBase = z.object({
  targetType: z.string(),
  displayName: z.string().nonempty().optional(),
  displayDetail: z.string().optional(),
  displayImageUrl: z.string().nonempty().optional(),
});
export type ChatTargetBase = z.infer<typeof ChatTargetBase>;

/**
 * Display information for a `ChatAddress`.
 *
 * Used when deciding what to render when a user is interacting with that address.
 */
export const ChatDisplayInfo = z.object({
  displayName: z.string().nonempty().optional(),
  displayDetail: z.string().optional(),
  displayImageUrl: z.string().nonempty().optional(),
  // Only used for email targets.
  displayPersonId: numberId().optional(),
});
export type ChatDisplayInfo = z.infer<typeof ChatDisplayInfo>;

export const VerifiedChatDisplayInfo = ChatDisplayInfo.extend({
  displayName: z.string(),
  verified: z.literal(true),
});
export type VerifiedChatDisplayInfo = z.infer<typeof VerifiedChatDisplayInfo>;

export const EmailChatTarget = ChatTargetBase.merge(EmailTargetKey).extend({
  displayPersonId: numberId().optional(),
});
export type EmailChatTarget = z.infer<typeof EmailChatTarget>;

/**
 * Chat target corresponding to a client.
 *
 * For anonymous users (no confirmed emails, a.k.a. visitors), the client chat target is now
 * intended to be used to resolve a chat address, rather than the occupant.
 *
 * See also:
 *  - roam/shared/Models/clientData/ClientData.ts
 *  - roam/middleend/src/clientData/README.md
 */
export const ClientChatTarget = ChatTargetBase.merge(ClientTargetKey).extend({
  email: z.undefined(),
});
export type ClientChatTarget = z.infer<typeof ClientChatTarget>;

// Deprecated (remove-occupant-address)
export const OccupantChatTarget = ChatTargetBase.merge(OccupantTargetKey).extend({
  email: z.string().email().optional(),
});
export type OccupantChatTarget = z.infer<typeof OccupantChatTarget>;

export const TeamRoamChatTarget = ChatTargetBase.merge(TeamRoamTargetKey).extend({
  addressId: stringId(), // address in addressMembers table for people on Team Roam in this chat

  email: z.undefined(),
});
export type TeamRoamChatTarget = z.infer<typeof TeamRoamChatTarget>;

/**
 * Represents the single address contained in a chat for a standard channel. For standard channels,
 * all members are contained in one address. Other channels use different address types.
 */
export const StandardGroupChatTarget = ChatTargetBase.merge(AddressIdTargetKey).extend({
  targetType: z.literal("standardGroup"),
  roamId: numberId(),

  // Whether people who are not a member of this group (but are still in the Roam) can start a new
  // chat with this group. (This is only relevant on the ChatAddress version, not when just a
  // target).
  nonMembersCanStartChat: z.boolean().optional(),

  // Whether the chat with the group should enforce threading in the UI
  // (explicit "New Message" button which opens a threaded view, rather than
  // allowing easy sending of top-level messages to the chat)
  enforceThreadedMode: z.boolean().optional(),

  email: z.undefined(),
});
export type StandardGroupChatTarget = z.infer<typeof StandardGroupChatTarget>;

/**
 * This is a channel that is specifically for a meeting. The channel will include the video
 * recording (if available) as well as a summary of the transcription, and can be used to share the
 * recording/summary and for follow-up discussion.
 */
export const MeetingGroupChatTarget = ChatTargetBase.merge(AddressIdTargetKey).extend({
  targetType: z.literal("meetingGroup"),
  roamId: numberId(),
  email: z.undefined(),
  // TODO: we may want to associate this with a recurring event, e.g., stand-up.
});
export type MeetingGroupChatTarget = z.infer<typeof MeetingGroupChatTarget>;

export const BotChatTarget = ChatTargetBase.merge(BotTargetKey).extend({
  roamId: numberId(),
  integrationId: stringId(),
  botCode: stringId(),
  email: z.undefined(),
});
export type BotChatTarget = z.infer<typeof BotChatTarget>;

/**
 * Contains all information needed to identify, create, and configure a ChatAddress. Includes both
 * the TargetKey which can uniquely identify the address, plus profile and other configuration
 * fields on the address.
 */
export const ChatTarget = z.discriminatedUnion("targetType", [
  EmailChatTarget,
  ClientChatTarget,
  TeamRoamChatTarget,
  StandardGroupChatTarget,
  MeetingGroupChatTarget,
  BotChatTarget,

  // Deprecated (remove-occupant-address)
  OccupantChatTarget,
]);
export type ChatTarget = z.infer<typeof ChatTarget>;

export type ChatTargetType = ChatTarget["targetType"];

export const ChatAddressExtras = z.object({
  addressId: stringId(),
  expired: z.number().optional(),
  sourcePersonId: numberId().optional(),
});
export type ChatAddressExtras = z.infer<typeof ChatAddressExtras>;

export const ChatAddress = ChatTarget.and(ChatAddressExtras).describe(
  "swiftEmbed:ChatAddressExtras"
);

export type ChatAddress = z.infer<typeof ChatAddress>;

export const targetTypeWithMembers: ChatTargetType[] = [
  "teamRoam",
  "standardGroup",
  "meetingGroup",
];
export const targetTypesAsMembers: ChatTargetType[] = ["email"];

// If a target of this type is in the chat, treat it as a "party"/channel style
export const groupTargetTypes: ChatTargetType[] = ["standardGroup", "meetingGroup"];
export type GroupTargetType = ChatTargetType & ("standardGroup" | "meetingGroup");
