export type BackgroundGradient = "green" | "gold" | "silver" | "purple";

const backgroundGradients: { [backgroundGradient in BackgroundGradient]: string } = {
  green: `linear-gradient(279deg, #D4FC79 0%, #96E6A1 100%);`,
  gold: `linear-gradient(96deg, #F6DD91 -0.41%, #C79C1A 102.3%);`,
  silver: `linear-gradient(102deg, #C7C7C7 0%, #EEE 100%);`,
  purple: `linear-gradient(72deg, #2E00AF 19.87%, #6357F5 78.88%);`,
};

const backgroundGradientStyles = Object.fromEntries(
  Object.entries(backgroundGradients).map(([color, gradient]) => [color, `background: ${gradient}`])
) as { [backgroundGradient in BackgroundGradient]: string };

export default backgroundGradientStyles;
export { backgroundGradients };
