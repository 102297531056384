import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { z } from "zod";
import {
  MemberSettings,
  MemberSettingsUpdate,
} from "../../../../shared/Models/AccountMemberSettings.js";
import { AccountAccessSettings } from "../../../../shared/Models/accounts/AccountAccessSettings.js";
import {
  NewVerifiedDomain,
  VerifiedDomain,
} from "../../../../shared/Models/accounts/VerifiedDomain.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface MemberSettingsByAccount {
  [accountId: number]: MemberSettings;
}

export const AddVerifiedDomainPayload = z.object({
  newVerifiedDomain: NewVerifiedDomain,
});
export type AddVerifiedDomainPayload = z.infer<typeof AddVerifiedDomainPayload>;

const accountAccessSettingsAdapter = createEntityAdapter<AccountAccessSettings>({
  selectId: (setting) => setting.accountId,
});
const verifiedDomainAdapter = createEntityAdapter<VerifiedDomain>();

const slice = createSlice({
  name: "memberSettings",
  initialState: {
    accountAccessSettings: accountAccessSettingsAdapter.getInitialState(),

    verifiedDomains: verifiedDomainAdapter.getInitialState(),
    verifiedDomainAccountsLoaded: {} as { [accountId: number]: boolean },
    verifiedDomainError: undefined as string | undefined,

    // NOTE:
    //  Post accounts migration, use verifiedDomains instead of this for getting domains.
    //  Member settings is still usable for allowing member invites and signup UUIDs.
    memberSettingsByAccount: {} as MemberSettingsByAccount,
    updateError: undefined as string | undefined,
  },
  reducers: {
    // Account access settings, CRUD
    /** Fetches account access settings for the specified [accountId]. */
    fetchAccountAccessSettingsByAccountId: (_, action: PayloadAction<number>) => {},
    /** Updates the account access settings for [accountId], creating it if it doesn't exist. */
    upsertAccountAccessSettingsByAccountId: (_, action: PayloadAction<number>) => {},

    // Account access settings, redux state
    upsertAccountAccessSetting: (state, action: PayloadAction<AccountAccessSettings>) => {
      accountAccessSettingsAdapter.upsertOne(state.accountAccessSettings, action.payload);
    },

    // Verified domains, CRUD
    /** Creates and persists a new verified domain. */
    addVerifiedDomain: (_, action: PayloadAction<AddVerifiedDomainPayload>) => {},
    /** Fetches verified domains for the specified account ID. */
    fetchVerifiedDomainsByAccountId: (_, action: PayloadAction<number>) => {},
    /** Updates the specified VerifiedDomain. */
    updateVerifiedDomain: (_, action: PayloadAction<VerifiedDomain>) => {},
    /** Deletes the verified domain with the specified ID. */
    deleteVerifiedDomain: (_, action: PayloadAction<number>) => {},

    // Verified domains, redux state
    /** Adds verified domains to the redux state. */
    upsertVerifiedDomains: (state, action: PayloadAction<VerifiedDomain[]>) => {
      verifiedDomainAdapter.upsertMany(state.verifiedDomains, action.payload);
    },
    /** Removes verified domains from the redux store. */
    removeVerifiedDomains: (state, action: PayloadAction<number[]>) => {
      verifiedDomainAdapter.removeMany(state.verifiedDomains, action.payload);
    },
    setVerifiedDomainError: (state, action: PayloadAction<string | undefined>) => {
      state.verifiedDomainError = action.payload;
    },
    clearVerifiedDomainError: (state) => {
      state.verifiedDomainError = undefined;
    },

    setVerifiedDomainAccountsLoaded: (state, action: PayloadAction<number>) => {
      state.verifiedDomainAccountsLoaded[action.payload] = true;
    },

    setAllMemberSettings: (state, action: PayloadAction<MemberSettingsByAccount>) => {
      const memberSettingsByAccount = action.payload;
      for (const [accountId, settings] of Object.entries(memberSettingsByAccount)) {
        state.memberSettingsByAccount[parseInt(accountId)] = settings;
      }
    },
    setMemberSettings: (
      state,
      action: PayloadAction<{ accountId: number; memberSettings: MemberSettings }>
    ) => {
      const { accountId, memberSettings } = action.payload;
      state.memberSettingsByAccount[accountId] = memberSettings;
    },
    /**
     * Updates access-related fields on an account.
     *
     * NOTE:
     *  While this takes in a `MemberSettingsUpdate`, please be aware that it (intentionally)
     *  does not respect all of the fields on that type.
     *  This is done for convenience/laziness.
     *
     * NOTE(vple):
     *  This is essentially the same as `updateMemberSettings`, but it doesn't update verified domains.
     *  It is the post accounts migration version of that endpoint.
     */
    updateAccountAccessSettings: (_, action: PayloadAction<MemberSettingsUpdate>) => {},
    setUpdateError: (state, action: PayloadAction<string | undefined>) => {
      state.updateError = action.payload;
    },
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
};

export const MemberSettingsActions = actions;

const selectSlice = (state: RootState) => state.anyWorld.memberSettings;
const accountAccessSettingsAdapterSelectors = accountAccessSettingsAdapter.getSelectors();
const verifiedDomainAdapterSelectors = verifiedDomainAdapter.getSelectors();

export const selectors = {
  selectAccountAccessSettingsByAccountId: (accountId: number) => (state: RootState) =>
    accountAccessSettingsAdapterSelectors.selectById(
      selectSlice(state).accountAccessSettings,
      accountId
    ),

  selectByAccountId: (accountId?: number) => (state: RootState) =>
    accountId ? selectSlice(state).memberSettingsByAccount[accountId] : undefined,

  selectUpdateError: (state: RootState) => state.anyWorld.memberSettings.updateError,
  /**
   * Selects all `VerifiedDomain`s for the specified [accountId] that are currently loaded in the
   * redux state.
   */
  selectVerifiedDomainsByAccountId: defaultMemoize((accountId: number) =>
    createDeepEqualSelector(selectSlice, (slice): VerifiedDomain[] => {
      return verifiedDomainAdapterSelectors
        .selectAll(slice.verifiedDomains)
        .filter((verifiedDomain) => verifiedDomain.accountId === accountId);
    })
  ),
  selectVerifiedDomainAccountsLoaded: (accountId: number) => (state: RootState) =>
    selectSlice(state).verifiedDomainAccountsLoaded[accountId] ?? false,
  selectVerifiedDomainError: (state: RootState) => selectSlice(state).verifiedDomainError,
};
export const MemberSettingsSelectors = selectors;
