import { getErrorMessage } from "../../../../shared/helpers/errors.js";

/*
 * Error handling in Video Pipelines:
 *
 * - Errors are described with PipelineError and its subclasses.
 *
 * - Errors that are considered permanent/terminal should be thrown on asynchronous setup calls into
 *   classes so that VideoLauncher's update loop can catch them when it calls update() on the
 *   pipeline. Groups of objects that work together in the pipeline (e.g.
 *   VideoWorkerProxy/VideoWorker and friends) abstract away how they communicate to notify each
 *   other of such errors so that they get thrown on update() calls.
 *
 * - Errors that are considered transient, which arise when we attempt to render individual
 *   frames and may go away without the pipeline being recreated, are described specifically
 *   with PipelineRenderError or their subclasses. These are passed upstream with
 *   PipelineErrorHandlers.
 *
 * - Errors whose occurrence requires the entire pipeline to be recreated should have
 *   requiresRecreate set in their constructor. Once handled, these errors should not be re-thrown
 *   or reported upstream.
 *
 * - PipelineErrors are sent across webworker boundaries through the (somewhat janky)
 *   pipelineErrorToPlain() and plainToPipelineError() functions below. Ordinary serialization used
 *   by web worker messaging does not preserve error classes or properties. This at least causes errors
 *   to get their proper classes back after crossing the boundary. If you define new error types you
 *   must update plainToPipelineError() accordingly (sorry).
 *
 */

export type PipelineErrorHandler = (err: PipelineError | undefined) => void;

export class PipelineError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "PipelineError";
  }
  public requiresRecreate = false;
  public touchupSpecific = false;
  public isTransient = false;
}

export class PipelineRenderError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "PipelineRenderError";
  }
  public requiresRecreate = false;
  public touchupSpecific = false;
  public isTransient = true;
}

export class WebGLContextLostError extends PipelineRenderError {
  constructor() {
    super("WebGL context lost");
    this.name = "WebGLContextLostError";
  }
}

export class PipelineSetupError extends PipelineError {
  constructor(message?: string) {
    super(message ?? "Pipeline setup error");
    this.name = "PipelineSetupError";
  }
}

export class WebGLRecreateNeededError extends PipelineError {
  constructor() {
    super("WebGL context must be recreated");
    this.name = "WebGLRecreateNeededError";
    this.requiresRecreate = true;
  }
}

export class TouchupPipelineError extends PipelineError {
  constructor(message: string) {
    super(message);
    this.name = "TouchupPipelineError";
    this.touchupSpecific = true;
  }
}

export class TouchupPipelineRenderError extends PipelineRenderError {
  constructor(message: string) {
    super(message);
    this.name = "TouchupPipelineRenderError";
  }
  public touchupSpecific = true;
}

export interface PlainPipelineError {
  name: string;
  message?: string;
  isTransient?: boolean;
  requiresRecreate?: boolean;
  touchupSpecific?: boolean;
}

// Converts a PipelineError (or any error, in case we've encountered something else) to a
// PlainPipelineError which can sent to/from web workers and stored in Redux
export const pipelineErrorToPlain = (err: any): PlainPipelineError | undefined => {
  if (!err) {
    return undefined;
  } else if (err instanceof PipelineError) {
    return {
      name: err.name,
      message: err.message,
      requiresRecreate: err.requiresRecreate,
      touchupSpecific: err.touchupSpecific,
      isTransient: err.isTransient,
    };
  } else if (err instanceof Error) {
    return {
      name: err.name,
      message: err.message,
    };
  }
  return {
    name: "Error",
    message: getErrorMessage(err),
  };
};

// Converts a PlainPipelineError to a PipelineError, for use after receiving a PlainPipelineError
// over web worker messages
export const plainToPipelineError = (obj: PlainPipelineError | undefined) => {
  if (!obj) {
    return undefined;
  }
  switch (obj.name) {
    case "PipelineRenderError":
      return new PipelineRenderError(obj.message ?? "Unknown render error");
    case "PipelineSetupError":
      return new PipelineSetupError(obj.message);
    case "WebGLContextLostError":
      return new WebGLContextLostError();
    case "WebGLRecreateNeededError":
      return new WebGLRecreateNeededError();
    case "TouchupPipelineError":
      return new TouchupPipelineError(obj.message ?? "Unknown touchup error");
    case "TouchupPipelineRenderError":
      return new TouchupPipelineRenderError(obj.message ?? "Unknown touchup error");
    default:
      return new PipelineError(obj.message ?? "Unknown pipeline error");
  }
};

// If the object supplied is already a PipelineError, returns it. Otherwise, returns a
// PipelineError based on the supplied object.
export const toPipelineError = (err: any): PipelineError | undefined => {
  if (!err) {
    return undefined;
  }
  return toPipelineErrorRequired(err);
};

export const toPipelineErrorRequired = (err: any): PipelineError => {
  if (err instanceof PipelineError) {
    return err;
  }
  return new PipelineError(getErrorMessage(err) ?? "unknown error");
};

export const toTouchupPipelineErrorRequired = (err: any): TouchupPipelineError => {
  if (err instanceof TouchupPipelineError) {
    return err;
  }
  return new TouchupPipelineError(getErrorMessage(err) ?? "unknown error");
};
