import type { SemanticColorsType } from "./colors.js";
import colors from "./colors.js";

/**
 * https://stackoverflow.com/a/51365037
 */
type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<RecursivePartial<U>>
    : T[P] extends object | undefined
      ? RecursivePartial<T[P]>
      : T[P];
};

/**
 * All the semantic colors that need to be changed for light mode that aren't
 * covered by `./accents.ts`.
 */
export const lightModeSemanticColors: RecursivePartial<SemanticColorsType> = {
  text: {
    primary: colors.black,
    secondary: colors.black500,
    disabled: colors.black300,
    error: colors.red500,
    // accentedPrimary
    // accentedSecondary
    warning: colors.amber800,
    positive: colors.green600,
    sensitive: colors.amber900,
    primaryOnLight: colors.white,
    secondaryOnLight: colors.white500,
    disabledOnLight: colors.white300,
    // onAccentedPrimary
    // onAccentedSecondary
  },
  // Should be the same as text
  icon: {
    primary: colors.black,
    secondary: colors.black500,
    disabled: colors.black300,
    error: colors.red500,
    // accentedPrimary
    // accentedSecondary
    warning: colors.amber800,
    positive: colors.green600,
    sensitive: colors.amber900,
    primaryOnLight: colors.white,
    secondaryOnLight: colors.white500,
    disabledOnLight: colors.white300,
    // onAccentedPrimary
    // onAccentedSecondary
  },
  background: {
    primary: colors.black50,
    secondary: colors.black50,
    elevatedPrimary: colors.white,
    elevatedSecondary: colors.white,
    // elevatedTertiary: colors.white, // NOTE: Same as dark theme!!
    overlayPrimary: colors.black500,
    overlaySecondary: colors.black100,
    primaryInverted: colors.white700,
    primaryOnLight: colors.white50,
    secondaryOnLight: colors.white100,
    // accentedPrimary
    // accentedSecondary
    sensitive: colors.amber900,
  },
  border: {
    primary: colors.black100,
    secondary: colors.black150,
    tertiary: colors.black300,
    focus: colors.black,
    error: colors.red500,
    // accentedPrimary
    // accentedSecondary
    positive: colors.green600,
    negative: colors.red500,
    primaryOnLight: colors.white100,
    secondaryOnLight: colors.white150,
    tertiaryOnLight: colors.white300,
  },
};
