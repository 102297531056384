import { z } from "zod";
import { AccessBadge, AccessBadgeKey, DerivedAccessBadge } from "../Models/AccessBadge.js";
import { AccessBadgeActionSource } from "../Models/AccessBadgeActionSource.js";
import { RoleLevel } from "../Models/RoleLevel.js";

export const crpc_badge_CreateAccessBadge = z.object({
  kind: z.literal("crpc_badge_CreateAccessBadge"),
  accessBadge: AccessBadge,
  source: AccessBadgeActionSource,
});
export type crpc_badge_CreateAccessBadge = z.infer<typeof crpc_badge_CreateAccessBadge>;

export const cresp_badge_CreateAccessBadge = z.object({
  kind: z.literal("cresp_badge_CreateAccessBadge"),
  accessBadge: DerivedAccessBadge,
});
export type cresp_badge_CreateAccessBadge = z.infer<typeof cresp_badge_CreateAccessBadge>;

export const crpc_badge_FetchAccessBadgeByKey = z.object({
  kind: z.literal("crpc_badge_FetchAccessBadgeByKey"),
  key: AccessBadgeKey,
});
export type crpc_badge_FetchAccessBadgeByKey = z.infer<typeof crpc_badge_FetchAccessBadgeByKey>;

export const cresp_badge_FetchAccessBadgeByKey = z.object({
  kind: z.literal("cresp_badge_FetchAccessBadgeByKey"),
  accessBadge: DerivedAccessBadge.optional(),
});
export type cresp_badge_FetchAccessBadgeByKey = z.infer<typeof cresp_badge_FetchAccessBadgeByKey>;

/**
 * Fetches all access badges granted by [granterPersonId].
 */
export const crpc_badge_FetchAccessBadgesByGranterId = z.object({
  kind: z.literal("crpc_badge_FetchAccessBadgesByGranterId"),
  granterPersonId: z.number(),
});
export type crpc_badge_FetchAccessBadgesByGranterId = z.infer<
  typeof crpc_badge_FetchAccessBadgesByGranterId
>;

export const cresp_badge_FetchAccessBadgesByGranterId = z.object({
  kind: z.literal("cresp_badge_FetchAccessBadgesByGranterId"),
  accessBadges: DerivedAccessBadge.array(),
});
export type cresp_badge_FetchAccessBadgesByGranterId = z.infer<
  typeof cresp_badge_FetchAccessBadgesByGranterId
>;

/**
 * Fetches all access badges granted to [granteeEmails], or aliases of those emails.
 */
export const crpc_badge_FetchAccessBadgesByGranteeEmails = z.object({
  kind: z.literal("crpc_badge_FetchAccessBadgesByGranteeEmails"),
  granteeEmails: z.string().array(),
});
export type crpc_badge_FetchAccessBadgesByGranteeEmails = z.infer<
  typeof crpc_badge_FetchAccessBadgesByGranteeEmails
>;

export const cresp_badge_FetchAccessBadgesByGranteeEmails = z.object({
  kind: z.literal("cresp_badge_FetchAccessBadgesByGranteeEmails"),
  accessBadges: DerivedAccessBadge.array(),
});
export type cresp_badge_FetchAccessBadgesByGranteeEmails = z.infer<
  typeof cresp_badge_FetchAccessBadgesByGranteeEmails
>;

export const crpc_badge_RevokeAccessBadge = z.object({
  kind: z.literal("crpc_badge_RevokeAccessBadge"),
  key: AccessBadgeKey,
  source: AccessBadgeActionSource,
});
export type crpc_badge_RevokeAccessBadge = z.infer<typeof crpc_badge_RevokeAccessBadge>;

export const cresp_badge_RevokeAccessBadge = z.object({
  kind: z.literal("cresp_badge_RevokeAccessBadge"),
});
export type cresp_badge_RevokeAccessBadge = z.infer<typeof cresp_badge_RevokeAccessBadge>;

export const crpc_badge_HideAccessBadge = z.object({
  kind: z.literal("crpc_badge_HideAccessBadge"),
  key: AccessBadgeKey,
  hidden: z.boolean(),
});
export type crpc_badge_HideAccessBadge = z.infer<typeof crpc_badge_HideAccessBadge>;

export const cresp_badge_HideAccessBadge = z.object({
  kind: z.literal("cresp_badge_HideAccessBadge"),
});
export type cresp_badge_HideAccessBadge = z.infer<typeof cresp_badge_HideAccessBadge>;

export const crpc_badge_UpdateVisitPermission = z.object({
  kind: z.literal("crpc_badge_UpdateVisitPermission"),
  key: AccessBadgeKey,
  visitPermission: z.boolean(),
});
export type crpc_badge_UpdateVisitPermission = z.infer<typeof crpc_badge_UpdateVisitPermission>;

export const cresp_badge_UpdateVisitPermission = z.object({
  kind: z.literal("cresp_badge_UpdateVisitPermission"),
});
export type cresp_badge_UpdateVisitPermission = z.infer<typeof cresp_badge_UpdateVisitPermission>;

/**
 * Updates the admin configuration controlling who can grant access badges within an account.
 */
export const crpc_badge_UpdateAdminSettings = z.object({
  kind: z.literal("crpc_badge_UpdateAdminSettings"),
  /** The account ID being updated. */
  accountId: z.number(),
  /** The desired account grant level. */
  allowAccessBadgeGrantLevel: RoleLevel,
  /** The person IDs who should be individually allowed to grant access badges. */
  customPersonIds: z.number().array(),
});
export type crpc_badge_UpdateAdminSettings = z.infer<typeof crpc_badge_UpdateAdminSettings>;

export const cresp_badge_UpdateAdminSettings = z.object({
  kind: z.literal("cresp_badge_UpdateAdminSettings"),
});
export type cresp_badge_UpdateAdminSettings = z.infer<typeof cresp_badge_UpdateAdminSettings>;

export const CLIENT_ACCESS_BADGE_REQUESTS = [
  crpc_badge_CreateAccessBadge,
  crpc_badge_FetchAccessBadgeByKey,
  crpc_badge_FetchAccessBadgesByGranterId,
  crpc_badge_FetchAccessBadgesByGranteeEmails,
  crpc_badge_HideAccessBadge,
  crpc_badge_RevokeAccessBadge,
  crpc_badge_UpdateAdminSettings,
  crpc_badge_UpdateVisitPermission,
];

export const CLIENT_ACCESS_BADGE_RESPONSES = [
  cresp_badge_CreateAccessBadge,
  cresp_badge_FetchAccessBadgeByKey,
  cresp_badge_FetchAccessBadgesByGranterId,
  cresp_badge_FetchAccessBadgesByGranteeEmails,
  cresp_badge_HideAccessBadge,
  cresp_badge_RevokeAccessBadge,
  cresp_badge_UpdateAdminSettings,
  cresp_badge_UpdateVisitPermission,
];
