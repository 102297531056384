/* eslint-disable no-restricted-imports */
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, memoize } from "lodash";
import { getChatKey } from "../../../../shared/helpers/chat.js";
import { ChatAddress } from "../../../../shared/Models/ChatAddress.js";
import { SentThreadMessage } from "../../../../shared/Models/ChatMessage.js";
import { ThreadSummary } from "../../../../shared/Models/ThreadSummary.js";
import { createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

export const threadSummaryAdapter = createEntityAdapter<ThreadSummary>({
  selectId: (s) => getChatKey(s.chatId, s.threadTimestamp),
  sortComparer: (a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp,
});
export interface ThreadPayload {
  chatId: string;
  threadTimestamp: number;
}

interface LoadStatus {
  allLoaded: boolean;
  nextBeforeTimestamp?: number;
  loading: boolean;
}

export interface ThreadMessageReceivedPayload {
  message: SentThreadMessage;
  senderAddress: ChatAddress;
}

export interface SubscribeToThreadPayload {
  chatId: string;
  threadTimestamp: number;
  windowKey?: WindowKey;
}

export interface SetSubscriptionStatusPayload {
  chatId: string;
  threads: Array<{
    threadTimestamp: number;
    subscribed: boolean;
  }>;
}

export const upsertThreadSummaries = (
  state: EntityState<ThreadSummary>,
  summaries: ThreadSummary[]
) => {
  // Upserts ThreadSummaries without clobbering lastMessageTimestamp, since lastMessageTimestamp
  // can never decrease
  for (const summary of summaries) {
    const existing = state.entities[getChatKey(summary.chatId, summary.threadTimestamp)];
    const toInsert: ThreadSummary =
      existing && existing.lastMessageTimestamp > summary.lastMessageTimestamp
        ? {
            ...summary,
            lastMessageTimestamp: existing.lastMessageTimestamp,
          }
        : summary;
    threadSummaryAdapter.setOne(state, toInsert);
  }
};

const initialState = {
  // completeness over any scope can be made without information in other fields below.
  summaries: threadSummaryAdapter.getInitialState(),

  // Whether we are subscribed to a thread. If undefined, we don't know and should request this if
  // needed.
  subscribed: {} as { [threadKey: string]: boolean },
};

const slice = createSlice({
  name: "thread",
  initialState,
  reducers: {
    resetState: () => initialState,
    requestSummary: (state, action: PayloadAction<ThreadPayload>) => {},
    setSummaries: (state, action: PayloadAction<ThreadSummary[]>) => {
      const summaries = action.payload;
      upsertThreadSummaries(state.summaries, summaries);
    },
    addReplyToSummary: (state, action: PayloadAction<ThreadMessageReceivedPayload>) => {
      const { message, senderAddress } = action.payload;
      let summary = summarySelectors.selectById(
        state.summaries,
        getChatKey(message.chatId, message.threadTimestamp)
      );
      if (!summary) {
        summary = {
          chatId: message.chatId,
          threadTimestamp: message.threadTimestamp,
          lastMessageTimestamp: message.threadTimestamp,
          messageCount: 0,
          threadParticipants: [],
        };
      }

      if (summary.lastMessageTimestamp < message.timestamp) {
        const newSummary = cloneDeep(summary);

        newSummary.messageCount++;
        newSummary.lastMessageTimestamp = message.timestamp;
        if (
          !newSummary.threadParticipants.some((p) => p.addressId === message.addressId) &&
          senderAddress.displayName
        ) {
          newSummary.threadParticipants.push({
            addressId: message.addressId,
            displayName: senderAddress.displayName,
            displayImageUrl: senderAddress.displayImageUrl,
          });
        }

        threadSummaryAdapter.setOne(state.summaries, newSummary);
      }
    },

    subscribeToThread: (state, action: PayloadAction<SubscribeToThreadPayload>) => {},
    unsubscribeFromThread: (state, action: PayloadAction<SubscribeToThreadPayload>) => {},
    setSubscriptionStatus: (state, action: PayloadAction<SetSubscriptionStatusPayload>) => {
      const { chatId, threads } = action.payload;
      for (const { threadTimestamp, subscribed } of threads) {
        state.subscribed[getChatKey(chatId, threadTimestamp)] = subscribed;
      }
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.thread;
export const summarySelectors = threadSummaryAdapter.getSelectors();
export const selectors = {
  selectSummary: memoize(
    (chatId: string | undefined, threadTimestamp: number | undefined) =>
      createSelector(selectSlice, (slice) => {
        if (!chatId || !threadTimestamp) return undefined;
        const key = getChatKey(chatId, threadTimestamp);
        return summarySelectors.selectById(slice.summaries, key);
      }),
    (chatId, threadTimestamp) => getChatKey(chatId ?? "undefined", threadTimestamp ?? -1)
  ),
  selectSubscribedStatus: memoize(
    (chatId: string | undefined, threadTimestamp: number | undefined) =>
      createSelector(selectSlice, (slice) => {
        if (!chatId || !threadTimestamp) return undefined;
        return slice.subscribed[getChatKey(chatId, threadTimestamp)];
      }),
    (chatId, threadTimestamp) => getChatKey(chatId ?? "undefined", threadTimestamp ?? -1)
  ),
};

export const ThreadActions = actions;
export const ThreadSelectors = selectors;
