import { Platform } from "../../../../../shared/Models/Platform.js";
import { LobbyUserAgent } from "../../../../../shared/Models/lobby/LobbyUserAgent.js";
import { requireValue } from "../../../../../shared/helpers/types/require.js";
import { RootState } from "../../../../store/reducers.js";
import { getClientIdentifiers } from "../../../../store/slices/getClientIdentifiers.js";

/**
 * Derives the `LobbyUserAgent` from [state].
 */
export const getUserAgent = (state: RootState): LobbyUserAgent => {
  const clientIdentifiers = requireValue(getClientIdentifiers(state));
  return {
    clientIdentifiers,
    // TODO: This is deprecated, remove when requireLobbyUserAgentClientIdentifiers is always true.
    platform: getPlatform(state),
  };
};

const getPlatform = (state: RootState): Platform | undefined => {
  switch (state.app.clientPlatform) {
    case "electron":
      return "desktop";
    case "web":
      return "web";
    default:
      return undefined;
  }
};
