export const maxPodSize = 5;
export const maxPodAutoFill = 3;

export const sectionSize = 100; // the number of audience members in a section
export const numColumns = 5; // the number of columns in the audience. where one
// column contains one pod of seats
// should be even to allow a middle aisle

// Re-exporting under different names for clarity of intent. These values
// should be used for laying out the audience section on the screen.
export const sectionColumns = numColumns;
export const podColumns = maxPodSize;
export const sectionRows = Math.ceil(sectionSize / sectionColumns / podColumns);

/*
 * This is the aspect ratio of the audience view on the new map. It was
 * computed as follows:
 *
 * + each seat will be 1 unit tall and wide. In the reference design,
 *   1 unit == 4px.
 * + each pod will contain P seats.
 *   + 3 high.
 *   + Each clickable seat actually takes up 3 units, but only gets displayed
 *     as a single 1 unit dot when not hovered.
 *   + 0 gap in between seats, 0 padding on ends means it will be P * 3 wide
 * + Each row will contain C pods.
 *   + 3 high, same as individual pod.
 *   + 1 gap in between pods means it will be C * P * 3 + (C - 1) * 1 wide.
 * + Each section will contain R rows.
 *   + 2 gap between rows means it will be R * 3 + (R - 1) * 2 high.
 *   + same width as individual row.
 *
 * NOTE: this **must** be consistent with logic in `SectionView.tsx`,
 * specifically `SeatContainer` and `PodContainer`,
 * otherwise things are liable to break.
 * NOTE: Currently only for the NEW_MAP flag.
 */
export const audienceUnitWidth = sectionColumns * podColumns * 3 + (sectionColumns - 1) * 1;
export const audienceUnitHeight = sectionRows * 3 + (sectionRows - 1) * 2;
export const audienceAspectRatio = audienceUnitWidth / audienceUnitHeight;
