import { VideoPipelineName, VideoPipelineStats } from "../../../shared/Models/VideoPipeline.js";
import { sumAndClearMeasures } from "../../helpers/performance.js";

export interface VideoPipelineDeltaStats {
  from: number;
  to: number;
  frames: number;
  fps?: number;
  avgMs?: number;
  segmentFps?: number;
  segmentAvgMs?: number;
  faceFps?: number;
  faceAvgMs?: number;
  pipelineName?: VideoPipelineName;
}

export const zeroVideoPipelineStats = (
  pipelineName: VideoPipelineName,
  streamId: string
): VideoPipelineStats => {
  return {
    streamId,
    timestamp: 0,
    totalFrames: 0,
    totalTime: 0,
    faceFeaturesFrames: 0,
    faceFeaturesTime: 0,
    segmentationFrames: 0,
    segmentationTime: 0,
    webglContextLosses: 0,
    pipelineName,
  };
};

export interface PipelineStatsRawDelta {
  totalFrames: number;
  totalTime: number;
  segmentationFrames: number;
  segmentationTime: number;
  faceFeaturesFrames: number;
  faceFeaturesTime: number;
}

export const accumulatePipelineStats = (stats: VideoPipelineStats): PipelineStatsRawDelta => {
  stats.timestamp = performance.now();

  const { count: totalFrames, total: totalTime } = sumAndClearMeasures("VideoPipelineRender");
  stats.totalFrames += totalFrames;
  stats.totalTime += totalTime;

  const { count: segmentationFrames, total: segmentationTime } =
    sumAndClearMeasures("VideoPipelineSegment");
  stats.segmentationFrames += segmentationFrames;
  stats.segmentationTime += segmentationTime;

  const { count: faceFeaturesFrames, total: faceFeaturesTime } =
    sumAndClearMeasures("VideoPipelineFace");
  stats.faceFeaturesFrames += faceFeaturesFrames;
  stats.faceFeaturesTime += faceFeaturesTime;

  return {
    totalFrames,
    totalTime,
    segmentationFrames,
    segmentationTime,
    faceFeaturesFrames,
    faceFeaturesTime,
  };
};

export const pipelineDeltaStats = (
  a: VideoPipelineStats,
  curr: VideoPipelineStats
): VideoPipelineDeltaStats | undefined => {
  if (curr.totalFrames < a.totalFrames) {
    return undefined;
  }
  const b: VideoPipelineStats = {
    streamId: a.streamId,
    timestamp: curr.timestamp,
    totalFrames: curr.totalFrames ?? a.totalFrames,
    totalTime: curr.totalTime ?? a.totalTime,
    segmentationFrames: curr.segmentationFrames ?? a.segmentationFrames,
    segmentationTime: curr.segmentationTime ?? a.segmentationTime,
    faceFeaturesFrames: curr.faceFeaturesFrames ?? a.faceFeaturesFrames,
    faceFeaturesTime: curr.faceFeaturesTime ?? a.faceFeaturesTime,
    webglContextLosses: curr.webglContextLosses ?? a.webglContextLosses,
    pipelineName: curr.pipelineName ?? a.pipelineName,
  };
  const sec = (b.timestamp - a.timestamp) / 1000;
  if (sec === 0) {
    return undefined;
  }
  const stats: VideoPipelineDeltaStats = {
    from: a.timestamp,
    to: b.timestamp,
    frames: b.totalFrames - a.totalFrames,
    pipelineName: b.pipelineName,
  };

  if (
    a.totalFrames !== undefined &&
    b.totalFrames !== undefined &&
    a.totalTime !== undefined &&
    b.totalTime !== undefined
  ) {
    const frames = b.totalFrames - a.totalFrames;
    stats.fps = frames / sec;
    if (frames > 0) {
      stats.avgMs = (b.totalTime - a.totalTime) / frames;
    }
  }

  if (
    a.segmentationFrames !== undefined &&
    b.segmentationFrames !== undefined &&
    a.segmentationTime !== undefined &&
    b.segmentationTime !== undefined
  ) {
    const frames = b.segmentationFrames - a.segmentationFrames;
    if (frames > 0) {
      stats.segmentFps = frames / sec;
      stats.segmentAvgMs = (b.segmentationTime - a.segmentationTime) / frames;
    }
  }

  if (
    a.faceFeaturesFrames !== undefined &&
    b.faceFeaturesFrames !== undefined &&
    a.faceFeaturesTime !== undefined &&
    b.faceFeaturesTime !== undefined
  ) {
    const frames = b.faceFeaturesFrames - a.faceFeaturesFrames;
    if (frames > 0) {
      stats.faceFps = frames / sec;
      stats.faceAvgMs = (b.faceFeaturesTime - a.faceFeaturesTime) / frames;
    }
  }

  return stats;
};

export const formatDuration = (t?: number, total?: number): string => {
  if (t === undefined || isNaN(t)) {
    return "";
  }
  const dur = `${t.toFixed(2)} ms`;
  const pct = total !== undefined && !isNaN(total) ? ` (${((t / total) * 100).toFixed(1)}%)` : "";
  return dur + pct;
};

export const formatFps = (fps?: number): string => {
  if (fps === undefined || isNaN(fps)) {
    return "";
  }
  return fps.toFixed(1);
};
