import { dumpMediaStream } from "../../../shared/helpers/dumpMediaStream.js";
import { getErrorMessage } from "../../../shared/helpers/errors.js";
import { logger } from "../../../shared/infra/logger.js";
import { ClientContainerInfo } from "../../injection/IClientContainer.js";
import { pickVideoInput } from "../avStreamShared.js";
import { IAvDevices } from "../interfaces/IAvDevices.js";
import { OnNewVideoStreamCallback, OnVideoErrorsCallback } from "../interfaces/IAvPipelines.js";
import { IRawStream, IRawVideoStreamManager } from "../interfaces/IRawStreamManager.js";
import { IVideoPipelineCache } from "./interfaces/IVideoPipelineCache.js";
import { IVideoPipelineExecutor } from "./interfaces/IVideoPipelineExecutor.js";
import {
  IVideoPipelineLauncher,
  PostProcVideoSpec,
  VideoSourceSpec,
} from "./interfaces/IVideoPipelineLauncher.js";
import { PipelineError, toPipelineErrorRequired } from "./interfaces/VideoPipelineError.js";

interface Injected {
  avDevices(): IAvDevices;
  info(): ClientContainerInfo;
  rawVideoStreamManager(): IRawVideoStreamManager;
  videoPipelineCache(): IVideoPipelineCache;
}

/*
 * VideoPipelineLauncher takes the caller's specification for what a video pipeline should do (given
 * as a VideoSourceSpec and PostProcVideoSpec to the setWant method) and arranges for the correct
 * pipeline to be created and started to do it. It is responsible for handling changes to the
 * specification from the caller in subsequent setWant() falls, failures or changes in the source
 * stream, and failures or changes in the pipeline. A single VideoPipelineLauncher can be created
 * for, e.g., the user's video for a meeting and used for its duration.
 *
 * The output of the pipeline is a MediaStream, which is supplied by calling the OnNewVideoStreamCallback
 * supplied to the VideoPipelineLauncher in its constructor. Users of VideoPipelineLauncher must be prepared
 * for this callback to be called at any time with a replacement stream and act accordingly.
 *
 * VideoPipelineLauncher is root of the set of objects for managing postproc for a user's
 * video, i.e.:
 *
 *   - Launcher (durable for e.g. a meeting)--> Executor (may be recreated by launcher)
 *      --> VideoPipelineContainer (cached and may be reused) --> WebGL Context & Render Function
 *
 */
export class VideoPipelineLauncher implements IVideoPipelineLauncher {
  private closed = false;
  private closedPromise: Promise<void>;
  private closedPromiseResolve: () => void = () => {};

  private wantSourceSpec: VideoSourceSpec | undefined;
  private wantPostProcSpec: PostProcVideoSpec | undefined;
  private wakeupLoopCb: (() => void) | undefined;
  private forceReplace = false;

  private lastStream: MediaStream | undefined;

  private transientPipelineError: PipelineError | undefined;
  private transientErrorTimeout: NodeJS.Timeout | undefined;
  private lastPipelineError: PipelineError | undefined;

  private rawStream: IRawStream | undefined;
  private unbindRawStreamEnded: (() => void) | undefined;

  private executor?: IVideoPipelineExecutor;
  private activePipelineName?: string;

  private deviceChangeListener: () => void;

  // Do not instantiate directly in client code. Obtain via AvPipelines.
  constructor(
    private container: Injected,
    private streamId: string,
    private onNewStream: OnNewVideoStreamCallback,
    private onErrors: OnVideoErrorsCallback,
    private onClose: () => void
  ) {
    this.closedPromise = new Promise((resolve) => {
      this.closedPromiseResolve = resolve;
    });

    (async () => {
      while (!this.closed) {
        try {
          await this.loop();
        } catch (err: any) {
          logger.warn({ err }, `VideoPipeline: Error running video pipeline loop`);
          // Wait a bit, then try to restart the pipeline
          await new Promise((resolve) => setTimeout(resolve, 1_000));
        }
      }
    })().catch(() => {});

    this.deviceChangeListener = () => this.wakeupLoopCb?.();
    this.container.avDevices().on("devicechange", this.deviceChangeListener);
  }

  private async maybeReplacePipeline(spec: PostProcVideoSpec) {
    if (
      this.forceReplace ||
      (spec?.pipelineName && spec.pipelineName !== this.activePipelineName)
    ) {
      logger.info("Replacing video pipeline");
      this.forceReplace = false;

      // Could consider not waiting for this to finish
      await this.executor?.close();

      this.executor = undefined;
      this.activePipelineName = undefined;

      logger.info(`VideoPipelineLauncher: Creating new executor for pipeline ${spec.pipelineName}`);

      this.executor = this.container
        .videoPipelineCache()
        .createExecutorForPipeline(spec.pipelineName, this.streamId, (err) =>
          this.handlePipelineError(err)
        );
      this.activePipelineName = spec.pipelineName;
    }
  }

  private handlePipelineError(err: PipelineError | undefined) {
    if (err === undefined) {
      // Getting a call back with an undefined error means by convention that a frame has rendered
      // successfully, so transient errors should be considered cleared
      this.transientPipelineError = undefined;
      if (this.transientErrorTimeout) {
        clearTimeout(this.transientErrorTimeout);
        this.transientErrorTimeout = undefined;
      }
      this.wakeupLoopCb?.();
    } else {
      if (err.requiresRecreate) {
        if (!this.forceReplace) {
          logger.info(
            "VideoPipelineLauncher: received error requiring recreate. Forced replacing."
          );
          this.forceReplace = true;
          this.wakeupLoopCb?.();
        }
      } else if (!this.transientErrorTimeout) {
        this.transientErrorTimeout = setTimeout(() => {
          this.transientPipelineError = err;
          this.wakeupLoopCb?.();
        }, 2_000);
      }
    }
  }

  public setWant(
    rawSpec: VideoSourceSpec | undefined,
    postProcSpec: PostProcVideoSpec | undefined,
    forceReplace = false
  ): void {
    this.wantSourceSpec = rawSpec;
    if (
      !postProcSpec?.virtbg &&
      !postProcSpec?.foregroundOverlays?.length &&
      !postProcSpec?.touchupLevel
    ) {
      // NOTE: This should be updated as properties are added to PostProcVideoSpec
      // If none of the properties we care about are set on the spec, we have nothing to
      // do; map it to undefined
      this.wantPostProcSpec = undefined;
    } else {
      this.wantPostProcSpec = postProcSpec;
    }
    this.forceReplace = forceReplace;

    this.wakeupLoopCb?.();
  }

  // loop() is responsible for ensuring that the VideoPipelineLauncher gets the video stream from
  // wantSourceSpec and performs postprocessing as specified by wantPostProcSpec. As those values
  // change or as changes/errors occur in the source or the pipeline, the loop compares the desired
  // state with the actual state and arranges for any changes needed to bring them into alignment.
  private async loop() {
    let wakeupLoopPromise = Promise.resolve();

    while (!this.closed) {
      await wakeupLoopPromise;
      // Create a new promise (with a new callback) for the next iteration
      wakeupLoopPromise = new Promise((resolve) => {
        this.wakeupLoopCb = () => resolve();
      });

      if (this.closed) break;

      // Get the specifications that we want (important to do this now because they might change while we're running)
      const wantSourceSpec = this.wantSourceSpec;
      const wantPostProcSpec = this.wantPostProcSpec;
      const mediaDevices = this.container.avDevices().mediaDevices();

      // Indicates whether anything has changed that requires invoking onNewErrors again
      let errorsChanged = false;

      let wantDeviceId: string | undefined;
      let expectedGroupId: string | undefined;
      if (wantSourceSpec) {
        // Determine the device ID we want
        wantDeviceId = wantSourceSpec.deviceIds?.find((deviceId) =>
          mediaDevices.some((d) => d.kind === "videoinput" && d.deviceId === deviceId)
        );
        if (!wantDeviceId) {
          switch (wantSourceSpec.fallback) {
            case "auto":
              if (mediaDevices.some((d) => d.kind === "videoinput")) {
                wantDeviceId = pickVideoInput(mediaDevices);
              }
              break;
            case "last":
              wantDeviceId = wantSourceSpec.deviceIds.at(-1);
              break;
          }
        }
        // Determine the groupId that we expect the device to have
        expectedGroupId = mediaDevices.find(
          (d) => d.deviceId === wantDeviceId && d.kind === "videoinput"
        )?.groupId;
      }

      const rawSettings = this.rawStream?.mediaStream.getVideoTracks()?.[0]?.getSettings();
      if (
        !wantSourceSpec ||
        (wantDeviceId && rawSettings?.deviceId !== wantDeviceId) ||
        (expectedGroupId && rawSettings?.groupId !== expectedGroupId) ||
        this.rawStream?.isClosed()
      ) {
        // Either:
        // - We don't want a stream
        // - We don't have the raw stream that we want
        // - The raw stream, if we have one, is closed
        // Remove the "ended" handler
        this.unbindRawStreamEnded?.();
        this.unbindRawStreamEnded = undefined;
        // Close and discard the reference to the stream
        this.rawStream?.close();
        this.rawStream = undefined;
      }
      let rawError;
      if (!this.rawStream && wantSourceSpec) {
        // We don't have a stream, and we want one
        try {
          this.rawStream = await this.container.rawVideoStreamManager().getStream(wantDeviceId);
          if (this.rawStream.isClosed()) {
            this.rawStream = undefined;
            // Trigger the loop to run again and try to grab a new stream
            this.wakeupLoopCb?.();
            throw new Error("Raw stream obtained was immediately closed");
          }
          // If any of the tracks end, run the loop again
          const endedCb = () => this.wakeupLoopCb?.();
          const tracks = this.rawStream.mediaStream.getTracks();
          tracks.forEach((t) => {
            if (t.readyState === "ended") {
              // If the track is already ended, run the loop again (after a short delay to avoid
              // busy looping)
              setTimeout(endedCb, 100);
            }
            t.addEventListener("ended", endedCb);
          });
          this.unbindRawStreamEnded = () => {
            tracks.forEach((t) => t.removeEventListener("ended", endedCb));
          };
        } catch (err: any) {
          rawError = err;
          errorsChanged = true;
        }
      }

      let nextStream: MediaStream | undefined;
      let pipelineError;
      try {
        if (wantPostProcSpec && this.rawStream?.mediaStream) {
          await this.maybeReplacePipeline(wantPostProcSpec);
          if (this.executor) {
            nextStream = await this.executor.update(wantPostProcSpec, this.rawStream?.mediaStream);
          }
        } else {
          // If we don't need any postprocessing or don't have a rawStream (probably because video
          // is muted), close and discard the executor. We will create a new one in
          // maybeReplacePipeline() if needed later in the session.
          await this.executor?.close();
          this.activePipelineName = undefined;
          this.executor = undefined;
          nextStream = this.rawStream?.mediaStream;
        }
      } catch (err: any) {
        logger.warn({ err }, `VideoPipelineLauncher.loop: error setting up post-processing`);
        pipelineError = toPipelineErrorRequired(err);
        this.activePipelineName = undefined;
        await this.executor?.close();
        this.executor = undefined;
      }

      if (nextStream !== this.lastStream) {
        this.lastStream = nextStream;
        try {
          const sourceDeviceId = this.rawStream?.mediaStream
            .getVideoTracks()?.[0]
            ?.getSettings().deviceId;
          this.onNewStream?.({ stream: nextStream, sourceDeviceId });
        } catch (err) {
          logger.warn({ err }, "VideoPipelineLauncher: onNewStream failure");
        }
      }

      if (!pipelineError && this.transientPipelineError) {
        // If our most recent attempt to update the pipeline didn't result in an error but there is
        // a current transientPipelineError, consider that the current pipelineError
        pipelineError = this.transientPipelineError;
      }
      if (pipelineError?.name !== this.lastPipelineError?.name) {
        errorsChanged = true;
      }
      this.lastPipelineError = pipelineError;

      if (errorsChanged && !this.closed) {
        try {
          this.onErrors?.({
            rawError,
            pipelineError,
          });
        } catch (err: any) {
          logger.warn({ err }, "VideoPipelineLauncher.loop: onErrors failed with its own error");
        }
      }
    }

    this.rawStream?.close();
    this.rawStream = undefined;
    await this.executor?.close();
    this.executor = undefined;
  }

  public close(): void {
    if (this.closed) return;
    this.closed = true;
    this.wakeupLoopCb?.();
    this.onClose();
    this.container.avDevices().removeListener("devicechange", this.deviceChangeListener);
  }

  public getStats() {
    return this.executor?.getStats();
  }

  public getPipelineError() {
    return this.lastPipelineError;
  }

  public dump(): any {
    return {
      closed: this.closed,
      executor: this.executor?.dump(),
      lastStream: this.lastStream ? dumpMediaStream(this.lastStream) : undefined,
      pipelineError: this.lastPipelineError ? getErrorMessage(this.lastPipelineError) : undefined,
      rawStream: this.rawStream ? dumpMediaStream(this.rawStream.mediaStream) : undefined,
      wantPostProcSpec: this.wantPostProcSpec,
      wantSourceSpec: this.wantSourceSpec,
    };
  }
}
