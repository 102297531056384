import {
  NotificationConfiguration,
  NotificationItem,
  NotificationOption,
  NotificationSounds,
} from "../../shared/Models/NotificationSounds.js";

const DEFAULT_THEME = "Roamverse";

/**
 * The computed configuration for a theme. Should include a value for every NotificationItem.
 * Value should never be "INHERIT".
 */
export const computeNotificationConfiguration = (
  theme: NotificationOption,
  override: NotificationConfiguration
): NotificationConfiguration => {
  const computed: NotificationConfiguration = {};
  for (const item of NotificationItem.options) {
    const overrideValue = override[item];
    if (overrideValue && overrideValue !== "INHERIT") {
      computed[item] = overrideValue;
    } else if (item === "chatOutgoing") {
      computed[item] = "NONE";
    } else {
      computed[item] = theme === "INHERIT" ? DEFAULT_THEME : theme;
    }
  }
  return computed;
};

/**
 * The paths for the notification sounds based on the configuration.
 */
export const computeNotificationSounds = (
  theme: NotificationOption,
  override: NotificationConfiguration
): NotificationSounds => {
  const computedConfiguration = computeNotificationConfiguration(theme, override);

  const notificationSounds: NotificationSounds = {};
  for (const item of NotificationItem.options) {
    notificationSounds[item] = toSoundPath(computedConfiguration[item], item);
  }
  return notificationSounds;
};

const toSoundPath = (
  theme: NotificationOption | undefined,
  item: NotificationItem
): string | null => {
  if (!theme || theme === "INHERIT") {
    // Shouldn't be possible.
    return null;
  }
  if (theme === "NONE") {
    return null;
  }

  // There was a time when there was both chatIncoming and chatIncomingUnreadOnly
  // that were the same sound but configured separately, but chatIncoming was removed.
  const actualItem = item === "chatIncomingUnreadOnly" ? "chatIncoming" : item;

  const suffix = `_${actualItem.replace(/([A-Z])/g, "_$1").toLowerCase()}`;
  const baseName = `${theme}${suffix}`;
  return `${theme}/${baseName}.mp3`;
};

export const defaultNotificationSounds = (): NotificationSounds => {
  return computeNotificationSounds(DEFAULT_THEME, {});
};
