import { BaseContainer } from "./BaseContainer.js";

export const createContainer = async <I>(
  constFunc: new (...args: any[]) => BaseContainer & I,
  ...args: any[]
): Promise<I> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const container = new constFunc(...args);
  await container.initialize();
  return container;
};
