import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { isEmpty, pick as unsafePick } from "lodash";
import { defaultMemoize } from "reselect";
import {
  ConversationParticipant,
  ConversationPlace,
  conversationKey,
} from "../../../../shared/Models/Conversation.js";
import { srv_conv_ParticipantList } from "../../../../shared/SectionMessages/ServerConversation.js";
import { TypeNarrowError } from "../../../../shared/TypeNarrowError.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import type { StreamType } from "../../../rtc/sfu/SFUConnection.js";
import { NetworkStatus } from "../../../rtc/sfu/SignalTransport.js";
import { RootState } from "../../../store/reducers.js";

export type ConnectedConversationParticipant = ConversationParticipant & {
  // For the current occupant both upload and download status are set. For other occupants only
  // upload status is set.
  uploadNetworkStatus: NetworkStatus;
  downloadNetworkStatus: NetworkStatus;

  // Bookkeeping for metrics and diagnostics.
  // TODO: We may miss out on some load events if we don't clear participants for old conversations
  // we are no longer in. We should consider doing that for several reasons, but for now just deal with it.
  firstSeenTime: number;
  audioLoadTime?: number;
  videoLoadTime?: number;
  audioTimingsSent: boolean;
  videoTimingsSent: boolean;
  fullTimingsSent: boolean;
  // Is the audio/video "muted" by the server for some reason?
  audioServerMuted: boolean;
  videoServerMuted: boolean;

  // Render properties for the displayed video for the participant.
  renderProperties?: {
    [mediaStreamId: string]: {
      size?: { width: number; height: number };
      pixelRatio?: number;
      visible?: boolean;
    };
  };
};

const participantAdapter = createEntityAdapter<ConnectedConversationParticipant>({
  selectId: (participant) => `${participant.conversationKey}-${participant.occupantId}`,
});

export interface ConversationParticipantStream {
  conversationKey: string;
  occupantId: string;
  // ID of the SFU peer servicing this stream
  peerId?: string;

  // IDs of the "camera" MediaStreams (combined audio and video, and separated audio and video)
  streamId?: string;
  audioStreamId?: string;
  videoStreamId?: string;

  // Stream type -> Stream ID -> MediaStream id
  allStreams: { [streamType: string]: { [streamID: string]: string } };
  allAudioStreams: { [streamType: string]: { [streamID: string]: string } };
  allVideoStreams: { [streamType: string]: { [streamID: string]: string } };
}

export const getParticipantCameraStreamId = (
  streamInfo: ConversationParticipantStream | undefined,
  mediaType: "audio" | "video" | "both"
): string | undefined => {
  if (mediaType === "audio" && streamInfo?.audioStreamId !== undefined) {
    return streamInfo?.audioStreamId;
  } else if (mediaType === "video" && streamInfo?.videoStreamId !== undefined) {
    return streamInfo?.videoStreamId;
  }
  return streamInfo?.streamId;
};

export const getParticipantStreamId = (
  streamInfo: ConversationParticipantStream | undefined,
  streamType: string,
  mediaType: "audio" | "video" | "both",
  streamID: string
): string | undefined => {
  if (mediaType !== "both") {
    const map = mediaType === "audio" ? streamInfo?.allAudioStreams : streamInfo?.allVideoStreams;
    if (map?.[streamType]?.[streamID] !== undefined) {
      return map?.[streamType]?.[streamID];
    }
  }
  return streamInfo?.allStreams[streamType]?.[streamID];
};

export interface HybridGroups {
  [groupId: string]: string[]; // occupantIds
}

const streamAdapter = createEntityAdapter<ConversationParticipantStream>({
  selectId: (participant) => `${participant.conversationKey}-${participant.occupantId}`,
});

export interface SetStreamIdsForConversationPayload {
  key: string;
  participantStreamIds: ConversationParticipantStream[];
}

export type ConversationParticipantNetworkStatusUpdate = Pick<
  ConnectedConversationParticipant,
  "conversationKey" | "occupantId" | "uploadNetworkStatus" | "downloadNetworkStatus"
>;

export type ConversationParticipantServerMutedUpdate = Pick<
  ConnectedConversationParticipant,
  "conversationKey" | "occupantId" | "audioServerMuted" | "videoServerMuted"
>;

export interface SetNetworkStatusForConversationPayload {
  participants: ConversationParticipantNetworkStatusUpdate[];
}

export type MediaLoadedForParticipantPayload = {
  place: ConversationPlace;
  occupantId: string;
  time: number;
  audio?: boolean;
  video?: boolean;
};

export type TimingsSentForParticipantPayload = {
  place: ConversationPlace;
  occupantId: string;
  audio?: boolean;
  video?: boolean;
  full?: boolean;
};

export type UpdateVideoRenderPropertiesForParticipantPayload = {
  place: ConversationPlace;
  occupantId: string;
  streamType: StreamType;
  mediaStreamId: string;
  visible?: boolean;
  size?: { width: number; height: number };
  pixelRatio?: number;
};

const networkStatusUpdateToParticipantId = (update: ConversationParticipantNetworkStatusUpdate) =>
  `${update.conversationKey}-${update.occupantId}`;

const serverMutedUpdateToParticipantId = (update: ConversationParticipantServerMutedUpdate) =>
  `${update.conversationKey}-${update.occupantId}`;

const slice = createSlice({
  name: "conversationParticipant",
  initialState: {
    participants: participantAdapter.getInitialState(),
    participantStreams: streamAdapter.getInitialState(),
  },
  reducers: {
    setForConversation: (state, action: PayloadAction<srv_conv_ParticipantList>) => {
      const participants = action.payload.participants;
      const idsPresent = new Set<string>();
      const key = conversationKey(action.payload.place);
      for (const participant of participants) {
        // Shallow copy the participant as we will augment it with network status if one already exists in the store.
        const connectedParticipant = { ...participant } as ConnectedConversationParticipant;

        // Because we use setOne (instead of upsertOne, since we only want to merge networkStatus),
        // explicitly merge the network status for an existing participant.
        const id = participantAdapter.selectId(connectedParticipant);
        const existingParticipant = participantSelectors.selectById(state.participants, id);
        if (existingParticipant) {
          Object.assign(
            connectedParticipant,
            pick(
              existingParticipant,
              "downloadNetworkStatus",
              "uploadNetworkStatus",
              "firstSeenTime",
              "audioLoadTime",
              "videoLoadTime",
              "audioTimingsSent",
              "videoTimingsSent",
              "fullTimingsSent",
              "audioServerMuted",
              "videoServerMuted",
              "renderProperties"
            )
          );
          // Remove (and add back in) so that the ordering is correct
          participantAdapter.removeOne(state.participants, id);
        } else {
          // This is a new participant, set its first seen time to now.
          connectedParticipant.firstSeenTime = Date.now();
          connectedParticipant.audioTimingsSent = false;
          connectedParticipant.videoTimingsSent = false;
          connectedParticipant.fullTimingsSent = false;
          connectedParticipant.audioServerMuted = false;
          connectedParticipant.videoServerMuted = false;
        }

        participantAdapter.setOne(state.participants, connectedParticipant);
        idsPresent.add(`${key}-${participant.occupantId}`);
      }
      for (const participantId of state.participants.ids) {
        const id = participantId.toString();
        if (id.startsWith(`${key}-`) && !idsPresent.has(id)) {
          participantAdapter.removeOne(state.participants, participantId);
        }
      }
    },
    setStreamIdsForConversation: (
      state,
      action: PayloadAction<SetStreamIdsForConversationPayload>
    ) => {
      const participantStreamIds = action.payload.participantStreamIds;
      const idsPresent = new Set<string>();
      for (const participant of participantStreamIds) {
        const id = `${action.payload.key}-${participant.occupantId}`;
        if (!equal(state.participantStreams.entities[id], participant)) {
          streamAdapter.setOne(state.participantStreams, participant);
        }
        idsPresent.add(id);
      }
      for (const participantId of state.participantStreams.ids) {
        const id = participantId.toString();
        if (id.startsWith(`${action.payload.key}-`) && !idsPresent.has(id)) {
          streamAdapter.removeOne(state.participantStreams, participantId);
        }
      }
    },
    setNetworkStatus: (state, action: PayloadAction<SetNetworkStatusForConversationPayload>) => {
      // Set network status for all participants in the payload.
      // TODO: should we clear network status for any participant not in the payload?
      // If we do, then it would be indistinguishable from the case where a new participant
      // joins the conversation and we don't yet know what their network status is.
      // If we don't we will continue treating their network status as the last known status.
      // For now, the second case seems better.
      for (const participant of action.payload.participants) {
        const id = networkStatusUpdateToParticipantId(participant);
        const oldEntity = state.participants.entities[id];
        if (
          oldEntity &&
          (oldEntity.uploadNetworkStatus !== participant.uploadNetworkStatus ||
            oldEntity.downloadNetworkStatus !== participant.downloadNetworkStatus)
        ) {
          participantAdapter.updateOne(state.participants, {
            id,
            changes: {
              uploadNetworkStatus: participant.uploadNetworkStatus,
              downloadNetworkStatus: participant.downloadNetworkStatus,
            },
          });
        }
      }
    },
    setServerMutedStatus: (
      state,
      action: PayloadAction<ConversationParticipantServerMutedUpdate[]>
    ) => {
      // Set muted status for all participants in the payload.
      for (const participant of action.payload) {
        const id = serverMutedUpdateToParticipantId(participant);
        const oldEntity = state.participants.entities[id];
        if (
          oldEntity &&
          (oldEntity.audioServerMuted !== participant.audioServerMuted ||
            oldEntity.videoServerMuted !== participant.videoServerMuted)
        ) {
          participantAdapter.updateOne(state.participants, {
            id,
            changes: {
              audioServerMuted: participant.audioServerMuted,
              videoServerMuted: participant.videoServerMuted,
            },
          });
        }
      }
    },
    mediaLoadedForParticipant: (state, action: PayloadAction<MediaLoadedForParticipantPayload>) => {
      // First look up the participant.
      const id = `${conversationKey(action.payload.place)}-${action.payload.occupantId}`;
      const participant = participantSelectors.selectById(state.participants, id);
      if (!participant) {
        // Unknown participant. For now just ignore this and don't load any data. This could be a race.
        return;
      }

      // Set the participant load times if we haven't seen media of the same type before.
      const changes: Partial<ConnectedConversationParticipant> = {};
      if (action.payload.audio && !participant.audioLoadTime) {
        changes.audioLoadTime = action.payload.time;
      }
      if (action.payload.video && !participant.videoLoadTime) {
        changes.videoLoadTime = action.payload.time;
      }
      if (!isEmpty(changes)) {
        participantAdapter.updateOne(state.participants, {
          id,
          changes,
        });
      }
    },
    timingsSentForParticipant: (state, action: PayloadAction<TimingsSentForParticipantPayload>) => {
      // First look up the participant.
      const id = `${conversationKey(action.payload.place)}-${action.payload.occupantId}`;
      const participant = participantSelectors.selectById(state.participants, id);
      if (!participant) {
        // Unknown participant. For now just ignore this and don't load any data. This could be a race.
        return;
      }
      const changes: Partial<ConnectedConversationParticipant> = {};
      if (action.payload.audio) changes.audioTimingsSent = true;
      if (action.payload.video) changes.videoTimingsSent = true;
      if (action.payload.full) changes.fullTimingsSent = true;
      if (!isEmpty(changes)) {
        participantAdapter.updateOne(state.participants, {
          id,
          changes,
        });
      }
    },
    updateVideoRenderPropertiesForParticipant: (
      state,
      action: PayloadAction<UpdateVideoRenderPropertiesForParticipantPayload>
    ) => {
      // First look up the participant.
      const id = `${conversationKey(action.payload.place)}-${action.payload.occupantId}`;
      const participant = participantSelectors.selectById(state.participants, id);
      if (!participant) {
        // Unknown participant. For now just ignore this and don't load any data. This could be a race.
        return;
      }
      const { mediaStreamId, size, pixelRatio, visible } = action.payload;
      const newProperties = { ...participant.renderProperties?.[mediaStreamId] };
      let changed = false;
      if (
        size !== undefined &&
        (size.width !== newProperties.size?.width || size.height !== newProperties.size?.height)
      ) {
        newProperties.size = size;
        changed = true;
      }
      if (pixelRatio !== undefined && pixelRatio !== newProperties.pixelRatio) {
        newProperties.pixelRatio = pixelRatio;
        changed = true;
      }
      if (visible !== undefined && visible !== newProperties.visible) {
        newProperties.visible = visible;
        changed = true;
      }
      if (changed) {
        participantAdapter.updateOne(state.participants, {
          id,
          changes: {
            renderProperties: {
              ...participant.renderProperties,
              [mediaStreamId]: newProperties,
            },
          },
        });
      }
    },
  },
});

export const { actions, reducer } = slice;

export const selectSlice = (state: RootState) => state.section.conversationParticipant;

const participantSelectors = participantAdapter.getSelectors();
const streamSelectors = streamAdapter.getSelectors();
export const selectors = {
  selectAll: createDeepEqualSelector(selectSlice, (slice) => {
    return participantSelectors.selectAll(slice.participants);
  }),
  selectCountForPlace: defaultMemoize(
    (place: ConversationPlace | undefined) =>
      createSelector(selectSlice, (slice) => {
        if (place === undefined) {
          return 0;
        }
        const key = conversationKey(place);
        return participantSelectors
          .selectAll(slice.participants)
          .filter((participant) => participant.conversationKey === key).length;
      }),
    { equalityCheck: equal }
  ),
  selectForPlace: defaultMemoize(
    ({ myPlace, place }: { myPlace: boolean; place: ConversationPlace | undefined }) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        if (!myPlace || place === undefined) {
          return undefined;
        }
        const key = conversationKey(place);
        return participantSelectors
          .selectAll(slice.participants)
          .filter((participant) => participant.conversationKey === key);
      }),
    { equalityCheck: equal }
  ),
  selectForOccupantIdAndPlace: defaultMemoize(
    ({
      occupantId,
      place,
    }: {
      occupantId: string | undefined;
      place: ConversationPlace | undefined;
    }) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        if (place === undefined || occupantId === undefined) {
          return undefined;
        }
        const key = conversationKey(place);
        return participantSelectors
          .selectAll(slice.participants)
          .find(
            (participant) =>
              participant.conversationKey === key && participant.occupantId === occupantId
          );
      }),
    { equalityCheck: equal }
  ),
  selectOccupantIdsForPlace: defaultMemoize(
    (place: ConversationPlace | undefined) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        if (place === undefined) {
          return [];
        }
        const key = conversationKey(place);
        const sectionServerParticipants = participantSelectors
          .selectAll(slice.participants)
          .filter((participant) => participant.conversationKey === key)
          .map((p) => p.occupantId);
        const streamParticipants = streamSelectors
          .selectAll(slice.participantStreams)
          .filter((participant) => participant.conversationKey === key)
          .map((p) => p.occupantId);
        return [...new Set([...sectionServerParticipants, ...streamParticipants])];
      }),
    { equalityCheck: equal, maxSize: 50 }
  ),
  selectHybridGroupsForPlace: defaultMemoize(
    (place: ConversationPlace | undefined) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        if (place === undefined) {
          return {} as HybridGroups;
        }
        const key = conversationKey(place);
        return participantSelectors
          .selectAll(slice.participants)
          .reduce<HybridGroups>((map, participant) => {
            const groupId = participant.hybridAudioStatus?.groupId;
            if (!groupId) return map;
            if (groupId in map) {
              const mapGroupId = map[groupId];
              if (!mapGroupId) throw new TypeNarrowError();
              return {
                ...map,
                [groupId]: [...mapGroupId, participant.occupantId],
              };
            }
            return {
              ...map,
              [groupId]: [participant.occupantId],
            };
          }, {});
      }),
    { equalityCheck: equal }
  ),
  selectSomeoneInMyPodTalking: defaultMemoize(
    (place: ConversationPlace | undefined) =>
      createSelector(selectSlice, (slice) => {
        if (place === undefined) {
          return false;
        }
        const key = conversationKey(place);
        let someoneTalking = false;
        participantSelectors
          .selectAll(slice.participants)
          .filter((participant) => participant.conversationKey === key)
          .forEach((p) => {
            if (p.avStatus && !p.avStatus?.micDisabled && !p.avStatus?.micMuted) {
              someoneTalking = true;
            }
          });
        return someoneTalking;
      }),
    { equalityCheck: equal }
  ),
  selectStreamIdsForPlace: defaultMemoize(
    ({ myPlace, place }: { myPlace: boolean; place: ConversationPlace | undefined }) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        if (!myPlace || place === undefined) {
          return undefined;
        }
        const key = conversationKey(place);
        return streamSelectors
          .selectAll(slice.participantStreams)
          .filter((participant) => participant.conversationKey === key);
      }),
    { equalityCheck: equal }
  ),
  selectStreamIdForOccupantIdAndPlace: defaultMemoize(
    ({
      occupantId,
      place,
    }: {
      occupantId: string | undefined;
      place: ConversationPlace | undefined;
    }) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        if (place === undefined || occupantId === undefined) {
          return undefined;
        }
        const key = conversationKey(place);
        return streamSelectors
          .selectAll(slice.participantStreams)
          .find(
            (participant) =>
              participant.conversationKey === key && participant.occupantId === occupantId
          );
      }),
    { equalityCheck: equal, maxSize: 50 }
  ),
};
export const ConversationParticipantSelectors = selectors;

// Force us to use the override that verifies the keys are actually on the object.
const pick = <T extends object, U extends keyof T>(obj: T, ...keys: U[]): Pick<T, U> => {
  return unsafePick(obj, keys);
};
