import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { LastLogin } from "../../../../shared/Models/LastLogin.js";
import { Person } from "../../../../shared/Models/Person.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const adapter = createEntityAdapter<Person>();
const lastLoginAdapter = createEntityAdapter<LastLogin>({
  selectId: (ll: LastLogin) => ll.personId,
});

const slice = createSlice({
  name: "adminAllPeople",
  initialState: {
    people: adapter.getInitialState(),
    lastLogins: lastLoginAdapter.getInitialState(),
  },
  reducers: {
    upsertPeople: (state, action: PayloadAction<Person[]>) => {
      adapter.upsertMany(state.people, action.payload);
    },
    removePerson: (state, action: PayloadAction<number>) => {
      adapter.removeOne(state.people, action.payload);
    },
    requestAll: (_, action: PayloadAction<number>) => {},
    upsertLastLogins: (state, action: PayloadAction<LastLogin[]>) => {
      lastLoginAdapter.upsertMany(state.lastLogins, action.payload);
    },
    requestLastLoginsForAccount: (_, action: PayloadAction<number>) => {},
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
};

const selectSlice = (state: RootState) => state.anyWorld.adminAllPeople;
const adapterSelectors = adapter.getSelectors();
const lastLoginAdapterSelectors = lastLoginAdapter.getSelectors();
export const selectors = {
  selectAllArray: createDeepEqualSelector(selectSlice, (slice) => {
    return adapterSelectors.selectAll(slice.people);
  }),
  selectAllEntities: createDeepEqualSelector(selectSlice, (slice) => {
    return adapterSelectors.selectEntities(slice.people);
  }),
  selectAllLastLoginEntities: createDeepEqualSelector(selectSlice, (slice) => {
    return lastLoginAdapterSelectors.selectEntities(slice.lastLogins);
  }),
  selectById: (personId?: number) => (state: RootState) =>
    personId ? adapterSelectors.selectById(selectSlice(state).people, personId) : undefined,
  selectByIds: defaultMemoize(
    (ids?: number[]) => {
      const idSet = new Set(ids || []);
      return createDeepEqualSelector(selectSlice, (slice) => {
        const people = adapterSelectors.selectAll(slice.people);
        const occupantEntities: { [id: number]: Person } = {};
        for (const person of people) {
          if (idSet.has(person.id)) {
            occupantEntities[person.id] = person;
          }
        }
        return occupantEntities;
      });
    },
    { equalityCheck: equal }
  ),
  selectByAccountId: defaultMemoize((accountId: number) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      return adapterSelectors
        .selectAll(slice.people)
        .filter((person) => person.accountId === accountId);
    })
  ),
};
