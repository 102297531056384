import { z } from "zod";

/**
 * See `../hooks/useAppearance.ts` for converting between this and `Appearance`.
 */
export const StoredAppearance = z.enum(["dark", "light", "system"]);
export type StoredAppearance = z.infer<typeof StoredAppearance>;

export const Appearance = StoredAppearance.exclude(["system"]);
export type Appearance = z.infer<typeof Appearance>;
