import { z } from "zod";
import { SentChatMessage } from "./ChatMessage.js";
import { ChatPersonalSettings } from "./ChatPersonalSettings.js";
import { ChatRestrictions } from "./chat/ChatRestrictions.js";
import { uuid } from "./zodTypes.js";

export const ChatType = z.enum([
  "address",
  "meeting",
  "teamRoam",
  "channel",
  "confidential",
  "meetingChannel",
  "story",
]);
export type ChatType = z.infer<typeof ChatType>;

export const isChannelStyleChat = (chatType: ChatType | undefined): boolean => {
  return (
    chatType !== undefined &&
    (chatType === "channel" || chatType === "meetingChannel" || chatType === "meeting")
  );
};

export const isChannelStyleAddressChat = (chatType: ChatType | undefined): boolean => {
  return chatType !== undefined && (chatType === "channel" || chatType === "meetingChannel");
};

export const isNonInboxChat = (chatType: ChatType): boolean => {
  return chatType === "story";
};

export const Chat = z.object({
  id: uuid(),
  chatType: ChatType,
  sortedAddressIds: z.array(uuid()),
  startedTimestamp: z.number().positive().int().optional(),

  // Added by server when sent to a specific client
  clientAddressIds: z.array(uuid()).optional(),
  personalSettings: ChatPersonalSettings.optional(),
  restrictions: ChatRestrictions.optional(),

  // deprecated(chat-threads-2) - lastMessageTime and clientReadThrough now on chatStatusSlice
  clientReadThrough: z.number().positive().int().optional(),
  lastMessage: SentChatMessage.optional(),
  lastMessageTime: z.number().positive().int().optional(),
});
export type Chat = z.infer<typeof Chat>;

export const ChatAddressSummary = z.object({
  id: uuid(),
  chatType: ChatType,
  sortedAddressIds: uuid().array(),
});
export type ChatAddressSummary = z.infer<typeof ChatAddressSummary>;

export const ChatReadThrough = z.object({
  chatId: uuid(),
  readThroughTime: z.number().positive().int().optional(),
});
export type ChatReadThrough = z.infer<typeof ChatReadThrough>;

export const CHAT_MAX_NUM_ADDRESSES = 16;
