import { EventEmitter } from "events";
import { IVoiceDetector } from "../interfaces/IVoiceDetector.js";

export class AudioLevelVoiceDetector extends EventEmitter implements IVoiceDetector {
  // Used for fetching audio data
  private data?: Uint8Array;
  private intervalId?: ReturnType<typeof setInterval>;
  private analyserNode?: AnalyserNode;
  // Used for analyzing the data
  private levelHistory = 0;
  // Last updates sent out
  private lastVoice = false;
  private lastVolume = 0;

  public get voiceStarted(): boolean {
    return this.lastVoice;
  }

  public start(analyserNode: AnalyserNode) {
    if (!this.intervalId || this.analyserNode !== analyserNode) {
      // Either we're not running, or we were using a different AnalyserNode
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = undefined;
      }

      this.analyserNode = analyserNode;
      // Based on the node's sampling rate, determine how often we should
      // run to ensure we get all the samples
      const intervalMs = (1000 * analyserNode.fftSize) / analyserNode.context.sampleRate;
      // Schedule an interval
      this.intervalId = setInterval(() => this.run(analyserNode, intervalMs), intervalMs);
      // Run once to start
      this.run(analyserNode, intervalMs);
    }
  }

  public stop() {
    // Stop running
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
    this.analyserNode = undefined;

    // Clear the history to start analysis fresh next time
    this.levelHistory = 0;

    // Send updates indicating no voice
    this.update(false, 0);
  }

  private run(analyserNode: AnalyserNode, intervalMs: number) {
    // Fetch the data and perform an RMS calculation
    if (this.data?.length !== analyserNode.fftSize) {
      this.data = new Uint8Array(analyserNode.fftSize);
    }
    analyserNode.getByteTimeDomainData(this.data);
    let total = 0;
    for (const value of this.data) {
      const adjustedValue = value - 127.5; // 127.5 seems to be the center point of silence.
      total += adjustedValue * adjustedValue;
    }
    const rootMeanSquare = Math.sqrt(total / this.data.length);

    // Analyze
    const adjustedRMS = (rootMeanSquare - 0.5) / 16;
    // Adjust the moving average to normalize against the measurement interval
    const alpha = (0.2 * intervalMs) / 50;
    this.levelHistory = adjustedRMS * alpha + this.levelHistory * (1 - alpha);
    const volume = this.levelHistory;
    const voice = volume > 0.04;

    // Fan out updates
    this.update(voice, volume);
  }

  private update(voice: boolean, volume: number) {
    if (voice && !this.lastVoice) {
      this.lastVoice = true;
      this.emit("voiceStart");
    } else if (!voice && this.lastVoice) {
      this.lastVoice = false;
      this.lastVolume = 0;
      this.emit("voiceStop");
      this.emit("voiceUpdate", 0);
    }
    if (voice && volume !== this.lastVolume) {
      this.lastVolume = volume;
      this.emit("voiceUpdate", volume);
    }
  }

  public dump(): any {
    return {
      intervalActive: !!this.intervalId,
      levelHistory: this.levelHistory,
      lastVoice: this.lastVoice,
      lastVolume: this.lastVolume,
    };
  }
}
