import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { z } from "zod";
import { AccessLinkId } from "../../../shared/Models/AccessLinks/AccessLinkId.js";
import { RootState } from "../reducers.js";

export const OpenAppAttempt = z.object({
  attemptId: z.string(),
  url: z.string().optional(),
});
export type OpenAppAttempt = z.infer<typeof OpenAppAttempt>;

/**
 * For coordinating attempts to the Electron app from a browser using a link.
 */
const slice = createSlice({
  name: "appLink",
  initialState: {
    lastAttempt: undefined as OpenAppAttempt | undefined,
    lastSuccessfulAttempt: undefined as OpenAppAttempt | undefined,
  },
  reducers: {
    // Electron action when app is opened with link.
    appOpened: (state, action: PayloadAction<string>) => undefined,

    // Web actions when trying to open app, or from server if app is opened.
    setLastSuccessfulAttempt: (state, action: PayloadAction<OpenAppAttempt>) => {
      state.lastSuccessfulAttempt = action.payload;
    },
    loadLastSuccessfulAttempt: () => undefined,
    attemptToOpenApp: (state, action: PayloadAction<OpenAppAttempt>) => {
      state.lastAttempt = action.payload;
    },
    serverAppOpened: (state, action: PayloadAction<string>) => undefined,

    openChatLink: (_, action: PayloadAction<AccessLinkId>) => {},
  },
});
export const { actions, reducer } = slice;
export const AppLinkActions = actions;

const selectSlice = (state: RootState) => state.appLink;
export const selectors = {
  selectLastAttempt: (state: RootState) => selectSlice(state).lastAttempt,
  selectLastSuccessfulAttempt: (state: RootState) => selectSlice(state).lastSuccessfulAttempt,
};
