import { useEffect, useState } from "react";
import { UnreachableError } from "../../shared/helpers/UnreachableError.js";
import { useStorage } from "../injection/storage/useStorage.js";
import { appearanceKey } from "../store/slices/appSlice.js";
import type { Appearance } from "../styles/appearance.js";
import { useWindow } from "./useGlobals.js";

export const useAppearance = (): Appearance => {
  const [storedAppearance] = useStorage(appearanceKey);

  const [systemAppearance, setSystemAppearance] = useState<Appearance>("dark");
  const window = useWindow();

  useEffect(() => {
    const setSystemAppearanceFromQuery = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setSystemAppearance("light");
      } else {
        setSystemAppearance("dark");
      }
    };

    // https://stackoverflow.com/a/57795495
    const mediaQuery = window.matchMedia("(prefers-color-scheme: light)");
    if (mediaQuery.matches) {
      setSystemAppearance("light");
    } else {
      setSystemAppearance("dark");
    }

    mediaQuery.addEventListener("change", setSystemAppearanceFromQuery);
    return () => {
      mediaQuery.removeEventListener("change", setSystemAppearanceFromQuery);
    };
  }, [window]);

  switch (storedAppearance) {
    case undefined:
      return "dark";
    case "dark":
    case "light":
      return storedAppearance;
    case "system":
      return systemAppearance;
    default:
      throw new UnreachableError(storedAppearance);
  }
};
