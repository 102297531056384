import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const couponIdAdapter = createEntityAdapter<string>();

// NOTE: For legacy reasons a lot of things in accountSlice are actually auth related.
// There's no urgency, but it is fine/good to migrate them here over time.
/**
 * Authentication related redux actions & state.
 */
const slice = createSlice({
  name: "authentication",
  initialState: {
    // AuthTokenCoupon IDs that have not yet been redeemed.
    couponIds: couponIdAdapter.getInitialState(),
    electronLoginAttemptId: undefined as string | undefined,
  },
  reducers: {
    createAuthTokenCoupon: () => {},
    /**
     * Redeems all known auth token coupons.
     *
     * Requires an AnyWorld connection to succeed.
     * The caller typically does not need to open one; this fires whenever an AnyWorld connection
     * is established.
     *
     * This should generally be explicitly triggered whenever new auth token coupon IDs are added
     * to the store, as there may be an existing AnyWorld connection.
     * (If there isn't this will no-op, which is fine.)
     */
    redeemAuthTokenCoupons: (state) => {},

    // coupon adapter
    addAuthTokenCouponId: (state, action: PayloadAction<string>) => {
      couponIdAdapter.addOne(state.couponIds, action.payload);
    },
    removeAuthTokenCouponIds: (state, action: PayloadAction<string[]>) => {
      couponIdAdapter.removeMany(state.couponIds, action.payload);
    },

    startElectronLogin: (state) => {},
    setElectronLoginAttemptId: (state, action: PayloadAction<string | undefined>) => {
      state.electronLoginAttemptId = action.payload;
    },
  },
});
export const { actions, reducer } = slice;
export const AuthenticationActions = actions;
export const AuthenticationReducer = reducer;

const selectSlice = (state: RootState) => state.anyWorld.authentication;
const couponIdSelectors = couponIdAdapter.getSelectors();
export const selectors = {
  selectAllAuthTokenCouponIds: createDeepEqualSelector(selectSlice, (slice) => {
    return couponIdSelectors.selectAll(slice.couponIds);
  }),
  selectElectronLoginAttemptId: createSelector(selectSlice, (slice) => {
    return slice.electronLoginAttemptId;
  }),
};

export const AuthenticationSelectors = selectors;
