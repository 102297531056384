import { toArray } from "lodash";

const splitName = (name: string): string[] => {
  // remove undesirable characters before splitting
  return name.replaceAll("#", "").replaceAll("(", "").replaceAll(")", "").trim().split(/\s+/);
};

export const formatNameInitials = (name: string | undefined): string => {
  if (!name) return "";

  const nameParts = splitName(name);
  const firstName = nameParts[0];
  if (!firstName) return "";

  if (nameParts.length === 1) return formatFirstDisplayCharacter(firstName);

  const lastName = nameParts[nameParts.length - 1];
  if (!lastName) return formatFirstDisplayCharacter(firstName);

  // return first_letter + last_letter
  return formatFirstDisplayCharacter(firstName) + formatFirstDisplayCharacter(lastName);
};

const formatFirstDisplayCharacter = (str: string): string => {
  if (!str) return "";

  // Split a string into displayable characters (mostly to handle emoji)
  return toArray(str)[0] ?? "";
};

export const formatFirstName = (name: string | undefined): string => {
  const trimmedName = name?.trim();
  if (!trimmedName) return "";

  const nameParts = trimmedName.split(/\s+/);
  const firstName = nameParts[0];
  if (!firstName) return trimmedName;

  return firstName;
};

export const formatFirstNameAndLastInitial = (name: string | undefined): string => {
  if (!name) return "";

  const nameParts = splitName(name);
  const firstName = nameParts[0];
  if (!firstName) return "";

  const lastName = nameParts[nameParts.length - 1];

  if (!lastName || nameParts.length === 1) return firstName;

  return `${firstName} ${formatFirstDisplayCharacter(lastName)}`;
};
