import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { notUndefined } from "../../../../shared/helpers/typing.js";
import { Collaborator } from "../../../../shared/Models/MeetingStats.js";
import { PersonPreview } from "../../../../shared/Models/RoamPreview.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";
import { selectors as RoamPreviewSelectors } from "../slices/roamPreviewSlice.js";
export interface RequestTopCollaboratorsPayload {
  roamId: number;
}

export interface TopCollaboratorsPayload {
  roamId: number;
  topCollaborators: Collaborator[];
}

const slice = createSlice({
  name: "personalRoamPreview",
  initialState: {
    topCollaborators: {} as { [roamId: number]: Collaborator[] },
  },
  reducers: {
    requestTopCollaborators: (_, action: PayloadAction<RequestTopCollaboratorsPayload>) => {},
    setTopCollaborators: (state, action: PayloadAction<TopCollaboratorsPayload>) => {
      const { roamId, topCollaborators } = action.payload;
      return {
        ...state,
        topCollaborators: Object.fromEntries([
          ...Object.entries(state.topCollaborators),
          [roamId, topCollaborators],
        ]),
      };
    },
  },
});

export const { actions, reducer } = slice;

const selectTopCollaborators = (roamId: number) => (state: RootState) =>
  state.overworld.personalRoamPreview.topCollaborators[roamId];

export const selectors = {
  selectTopCollaborators,
  selectHighlightedPersonPreviews: defaultMemoize(
    (roamId: number) =>
      createDeepEqualSelector(
        selectTopCollaborators(roamId),
        RoamPreviewSelectors.selectPreviewByRoamId(roamId),
        (topCollaborators, roamPreview) => {
          if (topCollaborators === undefined || roamPreview === undefined) {
            return undefined;
          }
          // List of all [personId, PersonPreview] pairs
          const personIdToPreviewList: Array<[number | undefined, PersonPreview]> = Object.values(
            roamPreview.personPreviews
          )
            .flatMap((floor) =>
              floor.map((pp) => {
                return { id: pp.personId, pp };
              })
            )
            .map(({ id, pp }) => [id, pp]);
          // Map of personId => PersonPreview
          const personIdToPreview = new Map(personIdToPreviewList);
          // Set of all people currently present in the roam
          const personIdSet = new Set(personIdToPreview.keys());
          personIdSet.delete(undefined);

          // The top `maxHighlightedPeople` ranked people in the Roam
          const visibleTopCollaborators = topCollaborators
            .filter(({ personId }) => personIdSet.has(personId))
            .sort(({ timeSpentInMeeting: a }, { timeSpentInMeeting: b }) => b - a)
            .slice(0, maxHighlightedPeople)
            .map(({ personId, email }) => {
              return { personId, email };
            });
          // Getting the people previews back from the ids
          const people = visibleTopCollaborators.map(({ personId, email }) => {
            const pp = personIdToPreview.get(personId);
            if (pp === undefined) {
              return undefined;
            }
            return { ...pp, email };
          });
          return people.filter(notUndefined);
        }
      ),
    { maxSize: 50 }
  ),
};

const maxHighlightedPeople = 3;
