import pluralize from "pluralize";
import { ItemType } from "../../../shared/Models/Item.js";
import { UploadDetails } from "./UploadDetails.js";

/** Gets an integer between 0 and 100 */
export const getUploadPercentComplete = (uploads: UploadDetails[]): number => {
  // The upload progress currently only tracks upload to the server,
  // not server to S3. In prod, that's the slow step. In dev, that's
  // super fast but the upload to s3 is slow, so the progress will hit
  // 98% and stick for a while.
  // This doesn't account for files being different sizes.
  let sumProgress = 0;
  uploads.forEach((up) => {
    if (up.status === "Uploading" && up.upload) {
      const progress = up.upload.percentComplete ?? 0;
      sumProgress = sumProgress + progress;
    }
  });
  // Not 100 because there's still another step of uploading to s3.
  return Math.round((0.98 * sumProgress) / uploads.length);
};

const defaultDisplayName = "other file";
const displayNames = new Map<ItemType, string>()
  .set("audio", "audio file")
  .set("photo", "image")
  .set("video", "video")
  .set("pdf", "pdf");

/** Gets a formatted message showing upload progress for the provided files */
export const getUploadingMessage = (uploads: UploadDetails[] | undefined): string | undefined => {
  if (!uploads?.length) return;
  if (uploads.length === 1 && uploads[0]) {
    const upload = uploads[0];
    if (upload.isMine && upload.itemName) {
      return `Uploading ${upload.itemName}... ${getUploadPercentComplete(uploads)}%`;
    }
  }
  const countsByDisplayName = new Map<string, number>();
  uploads.forEach((upload) => {
    if (upload.isMine) {
      const displayName = displayNames.get(upload.itemType) || defaultDisplayName;
      const count = countsByDisplayName.get(displayName) ?? 0;
      countsByDisplayName.set(displayName, count + 1);
    }
  });

  const sortedCounts = [...countsByDisplayName.entries()].sort(
    (entry1, entry2) => entry1[0].localeCompare(entry2[0]) // sort by display name
  );

  const msgPerType: string[] = [];
  for (const [displayName, count] of sortedCounts) {
    if (count) {
      msgPerType.push(pluralize(displayName, count, true));
    }
  }
  if (msgPerType.length > 1) {
    msgPerType[1] = ` and ${msgPerType[1]}`;
  }
  const token = msgPerType.length > 2 ? ", " : "";
  return `Uploading ${msgPerType.join(token)}... ${getUploadPercentComplete(uploads)}%`;
};
