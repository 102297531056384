{
  "currentVersion": 230,
  "minVersion": {
    "web": 224,
    "electron": 224,
    "other": 212
  },
  "releases": [
    {
      "name": "release-0091",
      "version": 224
    },
    {
      "name": "release-0092",
      "version": 225
    },
    {
      "name": "release-0093",
      "version": 228
    },
    {
      "name": "release-0094",
      "version": 229
    },
    {
      "name": "release-0095",
      "version": 230
    }
  ],
  "iOS": [
    {
      "name": "1.0",
      "version": 212
    },
    {
      "name": "1.6",
      "version": 220
    }
  ],
  "android": [
    {
      "name": "1.8.60",
      "version": 212
    }
  ]
}
