import { Action, createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { getChatKey } from "../../../../shared/helpers/chat.js";
import { Mention } from "../../../../shared/helpers/mentions.js";
import { Chat, ChatType, isChannelStyleAddressChat } from "../../../../shared/Models/Chat.js";
import { ChatAddress, ChatTarget, EmailChatTarget } from "../../../../shared/Models/ChatAddress.js";
import {
  ChatMessageContent,
  ConfidentialTextContent,
  ItemContent,
} from "../../../../shared/Models/ChatMessage.js";
import {
  ChatPersonalSettings,
  MuteChatForSec,
} from "../../../../shared/Models/ChatPersonalSettings.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";
import { targetListsAreEqual } from "../../helpers/targets.js";
import { ChatDraftSelectors } from "./chatDraftSlice.js";
import { ChatStatusSelectors } from "./chatStatusSlice.js";

export const CHAT_CONN_ERROR = "None of your email addresses support Messaging.";

const chatAdapter = createEntityAdapter<Chat>();
const addressAdapter = createEntityAdapter<ChatAddress>({ selectId: (p) => p.addressId });

export interface RegularContent {
  type: "regular";
  content: ChatMessageContent;
  mentions: Mention[];
}

export interface ConfidentialContent {
  type: "confidential";
  content: ConfidentialTextContent;
  confidentialText: string;
  confidentialItems?: ItemContent[];
}

export type Content = RegularContent | ConfidentialContent;

export interface TrySendMessagesPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  content: Content;
  senderEmail?: string;
  sendBeforeUploadComplete?: boolean;
  reportPopupId?: number; // optional popupId this was sent from. See comment on SendMessagePayload.
  nonce: number; // used to dedupe sends from the editor
}

export interface TrySendMessagesCompletePayload {
  chatId: string;
  threadTimestamp: number | undefined;
  popupId?: number;
  success: boolean;
}

export interface SendMessagePayload {
  // Can be an existing chatId or a resolved pendingChatId
  chatId: string;
  threadTimestamp: number | undefined;
  content: ChatMessageContent;
  confidentialText?: string;
  confidentialItems?: ItemContent[];
  messageId: string;
}

export interface StartChatPayload {
  content: ChatMessageContent;
  confidentialText?: string;
  confidentialItems?: ItemContent[];
  messageId: string;
  targets: ChatTarget[];
  pendingChatId: string;
  chatType: ChatType;
}

export interface SetAddressInfoPayload {
  email: string;
  displayName: string;
  displayImageUrl?: string;
}

interface SendFromAddressesPayload {
  senderAddresses: ChatAddress[];
  emailsSupportingTeamRoamChat?: string[];
}

export interface SetChatPersonalSettingsPayload {
  chatId: string;
  settings: ChatPersonalSettings;
  windowKey?: WindowKey;
}

export interface SetChatPinnedPayload {
  chatId: string;
  pinned: boolean;
  windowKey?: WindowKey;
}

export interface SetMuteChatUntilPayload {
  chatId: string;
  muteForSec: MuteChatForSec;
  windowKey?: WindowKey;
}

export interface ReorderPinnedChatPayload {
  sourceIndex: number;
  destinationIndex: number;
  windowKey?: WindowKey;
}

export interface ChatPersonalSettingsChangedPayload {
  chatId: string;
  settings: ChatPersonalSettings;
}

export interface FindChatPayload {
  popupId: number;
}

export interface StartChatSuccessPayload {
  chat: Chat;
  addresses: ChatAddress[];
}

const initialState = {
  chats: chatAdapter.getInitialState(),
  sendFromAddresses: undefined as ChatAddress[] | undefined,
  emailsSupportingTeamRoamChat: undefined as string[] | undefined,
  chatConnectionError: undefined as string | undefined,
  startChatError: undefined as string | undefined,
  knownAddresses: addressAdapter.getInitialState(),
  chatsTryingToSend: {} as { [chatKey: string]: boolean },

  // Used to clear the editor and scroll to the bottom of a chat after a successful send
  chatSendNonces: {} as { [chatId: string]: number },
};

const slice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetState: (state) => ({
      ...initialState,
    }),
    addKnownAddress: (state, action: PayloadAction<ChatAddress>) => {
      addressAdapter.upsertOne(state.knownAddresses, action.payload);
    },
    addKnownAddresses: (state, action: PayloadAction<ChatAddress[]>) => {
      addressAdapter.upsertMany(state.knownAddresses, action.payload);
    },

    // Safe to call from renderer window
    trySendMessages: (state, action: PayloadAction<TrySendMessagesPayload>) => {},

    // Not safe to call from renderer window, this action and trySendMessagesComplete can be
    // executed out of order. https://github.com/klarna/electron-redux/issues/52
    trySendMessagesInternal: (state, action: PayloadAction<TrySendMessagesPayload>) => {
      const { chatId, threadTimestamp } = action.payload;
      state.chatsTryingToSend[getChatKey(chatId, threadTimestamp)] = true;
    },

    trySendMessagesComplete: (state, action: PayloadAction<TrySendMessagesCompletePayload>) => {
      const { chatId, threadTimestamp, success } = action.payload;
      const chatKey = getChatKey(chatId, threadTimestamp);
      delete state.chatsTryingToSend[chatKey];
      if (success) {
        state.chatSendNonces[chatKey] = (state.chatSendNonces[chatKey] ?? 0) + 1;
      }
    },

    // server requests
    sendOrStartChat: (state, action: PayloadAction<SendMessagePayload>) => {},
    createTeamRoamChat: (state, action: PayloadAction<ChatAddress>) => {},
    requestStartupInfo: (state) => {
      state.chatConnectionError = undefined;
    },
    findChatForPopup: (state, action: PayloadAction<FindChatPayload>) => {},
    startChat: (state, action: PayloadAction<StartChatPayload>) => {
      state.startChatError = undefined;
    },
    setChatPersonalSettings: (state, action: PayloadAction<SetChatPersonalSettingsPayload>) => {},
    setChatPinned: (state, action: PayloadAction<SetChatPinnedPayload>) => {},
    reorderPinnedChat: (state, action: PayloadAction<ReorderPinnedChatPayload>) => {},
    setMuteChatUntil: (state, action: PayloadAction<SetMuteChatUntilPayload>) => {},
    setAddressInfo: (state, action: PayloadAction<SetAddressInfoPayload>) => {},

    // from server
    startChatSuccess: (state, action: PayloadAction<StartChatSuccessPayload>) => {
      const { chat: newChat, addresses } = action.payload;
      chatAdapter.upsertOne(state.chats, newChat);
      addressAdapter.upsertMany(state.knownAddresses, addresses);
      state.startChatError = undefined;
    },
    sendMessageSuccess: (state, action: PayloadAction<string>) => {}, // messageId
    upsertChats: (state, action: PayloadAction<Chat[]>) => {
      chatAdapter.setMany(state.chats, action.payload);
    },
    upsertKnownAddresses: (state, action: PayloadAction<ChatAddress[]>) => {
      const addresses = action.payload;
      addressAdapter.setMany(state.knownAddresses, addresses);
    },

    setSendFromAddresses: (state, action: PayloadAction<SendFromAddressesPayload>) => {
      const { senderAddresses, emailsSupportingTeamRoamChat } = action.payload;
      state.sendFromAddresses = senderAddresses;
      state.emailsSupportingTeamRoamChat = emailsSupportingTeamRoamChat;
    },
    setChatConnectionError: (state, action: PayloadAction<string>) => {
      state.chatConnectionError = action.payload;
    },
    setStartChatError: (state, action: PayloadAction<string>) => {
      state.startChatError = action.payload;
    },
    clearStartChatError: (state, action: Action) => {
      state.startChatError = undefined;
    },
    setChats: (state, action: PayloadAction<Chat[]>) => {
      chatAdapter.setMany(state.chats, action.payload);
    },
    removeChats: (state, action: PayloadAction<string[]>) => {
      chatAdapter.removeMany(state.chats, action.payload);
    },
    chatPersonalSettingsChanged: (
      state,
      action: PayloadAction<ChatPersonalSettingsChangedPayload>
    ) => {
      const { chatId, settings } = action.payload;
      const chat = state.chats.entities[chatId];
      if (!chat) return;
      chat.personalSettings = settings;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.chat;
const chatSelectors = chatAdapter.getSelectors();
const addressSelectors = addressAdapter.getSelectors();

export const selectPinnedChatIds = createDeepEqualSelector(selectSlice, (slice) =>
  selectPinnedChats(slice)
    .sort((left, right) => {
      if (left.personalSettings?.pinOrder ?? 0 !== right.personalSettings?.pinOrder ?? 0) {
        return (left.personalSettings?.pinOrder ?? 0) < (right.personalSettings?.pinOrder ?? 0)
          ? -1
          : 1;
      }
      return left.id < right.id ? -1 : 1;
    })
    .map((chat) => chat.id)
);

const selectAddressesFromChatInternal = (
  slice: RootState["chat"]["chat"],
  filter: "all" | "excludeSelf" | "forDisplay",
  chatId?: string
): ChatAddress[] | undefined => {
  if (!chatId) return undefined;
  const chat = slice.chats.entities[chatId];
  if (!chat) return undefined;
  const { sortedAddressIds, clientAddressIds } = chat;
  if (!sortedAddressIds || !clientAddressIds) return undefined;

  const eligibleAddressIds =
    filter === "excludeSelf" ||
    (filter === "forDisplay" && !isChannelStyleAddressChat(chat.chatType))
      ? sortedAddressIds.filter((a) => !clientAddressIds.includes(a))
      : sortedAddressIds;

  const results = new Array<ChatAddress>();
  for (const addressId of eligibleAddressIds) {
    const address = slice.knownAddresses.entities[addressId];

    // returning some but not all of the addresses could create an unexpected situation, so just
    // don't return any - if we're encountering this, check why we're finding out about chats
    // but not adding their addresses to knownAddresses
    if (!address) {
      return undefined;
    }
    results.push(address);
  }
  return results;
};

const selectPinnedChats = (slice: RootState["chat"]["chat"]) =>
  chatSelectors
    .selectAll(slice.chats)
    .filter((chat) => chat.personalSettings?.pinOrder !== undefined);

export const selectors = {
  selectAllChatIds: createDeepEqualSelector(
    selectSlice,
    (slice) => chatSelectors.selectIds(slice.chats) as string[]
  ),
  selectTeamRoamChat: defaultMemoize((email: string | undefined) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      if (!email) return undefined;
      const addressId = slice.sendFromAddresses?.find(
        (address) => address.targetType === "email" && address.email === email
      )?.addressId;
      if (!addressId) return undefined;
      return chatSelectors
        .selectAll(slice.chats)
        .find((c) => c.chatType === "teamRoam" && c.sortedAddressIds?.includes(addressId));
    })
  ),
  selectChatById: (chatId?: string) => (state: RootState) => {
    if (!chatId) return undefined;
    return chatSelectors.selectById(selectSlice(state).chats, chatId);
  },
  selectChatsByIds: defaultMemoize((chatIds?: string[]) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      if (!chatIds) return;
      const allChats = chatSelectors.selectAll(slice.chats);
      return allChats.filter((chat) => chatIds.includes(chat.id));
    })
  ),
  selectPinnedChatIds,
  selectAddressesFromChat: defaultMemoize((chatId?: string) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      selectAddressesFromChatInternal(slice, "all", chatId)
    )
  ),
  selectDisplayAddressesFromChat: defaultMemoize(
    (chatId?: string) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        selectAddressesFromChatInternal(slice, "forDisplay", chatId)
      ),
    { maxSize: 500 }
  ),
  selectOtherAddressesFromChat: defaultMemoize(
    (chatId?: string) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        selectAddressesFromChatInternal(slice, "excludeSelf", chatId)
      ),
    { maxSize: 500 }
  ),
  selectOtherAddressFromChat: defaultMemoize(
    (chatId?: string) =>
      createSelector(selectSlice, (slice) => {
        const addresses = selectAddressesFromChatInternal(slice, "excludeSelf", chatId);
        if (!addresses || addresses.length !== 1) {
          // For use when we expect exactly one other address, so if there's not exactly one other
          // return undefined since we don't have a correct value
          return undefined;
        }
        return addresses[0];
      }),
    { maxSize: 10 }
  ),
  selectChatByTargetList: defaultMemoize((targets: ChatTarget[], confidential?: boolean) =>
    createSelector(selectSlice, (slice) => {
      if (targets.length === 0) return undefined;

      const allChats = chatSelectors.selectAll(slice.chats);
      return allChats.find((c) => {
        const chatAddresses = selectAddressesFromChatInternal(slice, "all", c.id);
        const equal =
          (confidential === undefined || confidential === (c.chatType === "confidential")) &&
          chatAddresses &&
          targetListsAreEqual(chatAddresses, targets, slice.sendFromAddresses);
        return equal;
      });
    })
  ),
  selectChatByOtherTarget: defaultMemoize(
    (target: ChatTarget | undefined, confidential?: boolean) =>
      createSelector(selectSlice, (slice) => {
        if (!target) {
          return undefined;
        }
        const allChats = chatSelectors.selectAll(slice.chats);
        return allChats.find((c) => {
          const chatAddresses = selectAddressesFromChatInternal(slice, "excludeSelf", c.id);
          return (
            (confidential === undefined || confidential === (c.chatType === "confidential")) &&
            chatAddresses &&
            targetListsAreEqual(chatAddresses, [target], slice.sendFromAddresses)
          );
        });
      })
  ),
  selectChatWithAlternateConfidentiality: defaultMemoize(
    (chatId: string | undefined, confidential: boolean | undefined) =>
      createSelector(
        selectSlice,
        ChatDraftSelectors.selectDraftByChatId(chatId, undefined),
        (slice, draft) => {
          if (!chatId || confidential === undefined) return undefined;

          let targets: ChatTarget[];
          if (chatId.startsWith("NEW")) {
            if (!draft?.pendingChatInfo) {
              return undefined;
            }
            targets = draft.pendingChatInfo.targets;
          } else {
            const chatTargets = selectAddressesFromChatInternal(slice, "all", chatId);
            if (!chatTargets) return undefined;
            targets = chatTargets;
          }
          if (targets.length === 0) return undefined;

          const allChats = chatSelectors.selectAll(slice.chats);
          return allChats.find((c) => {
            const chatAddresses = selectAddressesFromChatInternal(slice, "all", c.id);
            const equal =
              confidential === (c.chatType === "confidential") &&
              chatAddresses &&
              targetListsAreEqual(chatAddresses, targets, slice.sendFromAddresses);
            return equal;
          });
        }
      )
  ),
  selectSendFromAddresses: (state: RootState) => selectSlice(state).sendFromAddresses,
  selectSendFromAddressIds: createDeepEqualSelector(selectSlice, (slice) =>
    slice.sendFromAddresses?.map((a) => a.addressId)
  ),
  selectKnownAddressById: defaultMemoize(
    (addressId?: string) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        addressId ? addressSelectors.selectById(slice.knownAddresses, addressId) : undefined
      ),
    { maxSize: 500 }
  ),
  selectKnownAddressByEmail: defaultMemoize(
    (email?: string) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        email
          ? addressSelectors.selectAll(slice.knownAddresses).find((a) => a.email === email)
          : undefined
      ),
    { maxSize: 50 }
  ),
  selectKnownAddressForBot: defaultMemoize(
    (roamId?: number, integrationId?: string, botCode?: string) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        roamId && integrationId
          ? addressSelectors
              .selectAll(slice.knownAddresses)
              .find(
                (a) =>
                  a.targetType === "bot" &&
                  a.roamId === roamId &&
                  a.integrationId === integrationId &&
                  a.botCode === (botCode ?? "_")
              )
          : undefined
      ),
    { maxSize: 50 }
  ),
  selectKnownAddresses: createDeepEqualSelector(selectSlice, (slice) =>
    addressSelectors.selectAll(slice.knownAddresses)
  ),
  selectRoamIdForSingleGroupChat: defaultMemoize((chatId?: string) =>
    createSelector(selectSlice, (slice) => {
      const otherAddresses = selectAddressesFromChatInternal(slice, "all", chatId);
      if (otherAddresses?.length === 1) {
        const otherAddress = otherAddresses?.[0];
        if (otherAddress?.targetType === "standardGroup") {
          return otherAddress.roamId;
        }
      }
      return undefined;
    })
  ),
  selectChatConnectionError: createSelector(selectSlice, (slice) => slice.chatConnectionError),
  selectStartChatError: createSelector(selectSlice, (slice) => slice.startChatError),
  selectEmailsSupportingTeamRoamChat: createDeepEqualSelector(
    selectSlice,
    (slice) => slice.emailsSupportingTeamRoamChat
  ),
  selectAddressesSupportingTeamRoamChat: createDeepEqualSelector(selectSlice, (slice) =>
    slice.sendFromAddresses?.filter(
      (a) => a.targetType === "email" && slice.emailsSupportingTeamRoamChat?.includes(a.email)
    )
  ),
  selectChatIsSending: defaultMemoize(
    (chatId: string | undefined, threadTimestamp: number | undefined) =>
      createSelector(selectSlice, (slice) => {
        if (!chatId) return false;
        return slice.chatsTryingToSend[getChatKey(chatId, threadTimestamp)] ?? false;
      })
  ),
  selectChatNonce: defaultMemoize(
    (chatId: string | undefined, threadTimestamp: number | undefined) =>
      createSelector(selectSlice, (slice) => {
        if (!chatId) return 0;
        return slice.chatSendNonces[getChatKey(chatId, threadTimestamp)] ?? 0;
      })
  ),
  selectEnforceThreadedModeForChat: defaultMemoize((chatId?: string) =>
    createSelector(selectSlice, (slice) => {
      const addresses = selectAddressesFromChatInternal(slice, "excludeSelf", chatId);
      if (!addresses || addresses.length !== 1) return false;

      const address = addresses[0];
      if (!address) return false;

      return address.targetType === "standardGroup" && !!address.enforceThreadedMode;
    })
  ),

  selectRecentContacts: defaultMemoize((max?: number) =>
    createDeepEqualSelector(
      selectSlice,
      ChatStatusSelectors.selectAll("all", false),
      (slice, chatStatuses) => {
        max = max ?? 10;
        const emails = new Set<string>();
        const results = new Array<EmailChatTarget>();
        const recentChatIds = chatStatuses.map((s) => s.chatId);
        for (const chatId of recentChatIds) {
          const chat = slice.chats.entities[chatId];
          if (!chat) continue;
          const { sortedAddressIds, clientAddressIds } = chat;
          if (!sortedAddressIds || !clientAddressIds) continue;
          const targets = sortedAddressIds
            .map((a) => slice.knownAddresses.entities[a])
            .filter((a) => a?.targetType === "email")
            .filter((a) => !emails.has(a?.email ?? "")) as EmailChatTarget[];
          for (const target of targets) {
            if (!target?.email) continue;
            results.push(target);
            emails.add(target.email);
            if (results.length >= max) return results;
          }
        }
        return results;
      }
    )
  ),

  selectIsSendFromAddress: (addressId: string | undefined) => (state: RootState) =>
    addressId
      ? selectSlice(state).sendFromAddresses?.some((a) => a.addressId === addressId) ?? false
      : false,
};

export const ChatSelectors = selectors;
export const ChatActions = actions;
