import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { ShelfFocus, ShelfLastView } from "../../../../shared/Models/ShelfFocus.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface RoomFocusPayload {
  roomId: number;
  foci: ShelfFocus[];
}

export interface ClientFocus {
  floorId: number;
  roomId: number;
  shelfId: string;
  itemId?: string;
}

export interface SetLastViewsPayload {
  floorId: number;
  lastViewed: ShelfLastView[];
}

const slice = createSlice({
  name: "shelfFocus",
  initialState: {
    focusByRoom: {} as {
      [roomId: number]: ShelfFocus[];
    },
    localFocus: undefined as ClientFocus | undefined,

    lastViewByFloor: {} as {
      [floorId: number]: ShelfLastView[];
    },
  },
  reducers: {
    // Trigger server actions
    clientFocus: (state, action: PayloadAction<ClientFocus>) => {
      state.localFocus = action.payload;
    },
    clientUnfocus: (state) => {
      state.localFocus = undefined;
    },

    // From server
    setRoomFoci: (state, action: PayloadAction<RoomFocusPayload>) => {
      const { roomId, foci } = action.payload;
      state.focusByRoom[roomId] = foci;
    },

    setLastViewByFloor: (state, action: PayloadAction<SetLastViewsPayload>) => {
      const { floorId, lastViewed } = action.payload;
      state.lastViewByFloor[floorId] = lastViewed;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.things.shelfFocus;
export const selectors = {
  selectRoomShelfFocus: defaultMemoize(
    ({ roomId, shelfId }: { roomId?: number; shelfId?: string }) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        roomId && shelfId ? slice.focusByRoom[roomId]?.filter((f) => f.shelfId === shelfId) : []
      ),
    {
      equalityCheck: equal,
      maxSize: 5,
    }
  ),
  selectLocalFocus: (state: RootState) => state.things.shelfFocus.localFocus,
  selectLastViewByFloor: (floorId?: number) => (state: RootState) =>
    floorId ? state.things.shelfFocus.lastViewByFloor[floorId] : undefined,
};
