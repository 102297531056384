import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { BigMeetingRoom } from "../../../../shared/Models/BigMeetingRoom.js";
import { ConversationPlace } from "../../../../shared/Models/Conversation.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";
import { selectors as ConversationParticipantSelectors } from "./conversationParticipantSlice.js";

export type TalkerStatus = "pinned" | "talker";

export interface PinOccupantPayload {
  floorId: number;
  roomId: number;
  occupantId: string;
}

export interface UnpinOccupantPayload {
  floorId: number;
  roomId: number;
  occupantId: string;
}

export interface MuteOccupantPayload {
  floorId: number;
  roomId: number;
  occupantId: string;
}

export interface MuteAllButMePayload {
  floorId: number;
  roomId: number;
  includePinned: boolean;
}

interface SetPinned {
  roomId: number;
  occupantId: string;
}

interface SetUnpinned {
  roomId: number;
  occupantId: string;
}

export interface ToldToMutePayload {
  roomId: number;
  occupantId: string;
}

const slice = createSlice({
  name: "bigMeetingRoom",
  initialState: {
    rooms: {} as Record<number, BigMeetingRoom>,
  },
  reducers: {
    pinOccupant: (state, action: PayloadAction<PinOccupantPayload>) => {},
    unpinOccupant: (state, action: PayloadAction<UnpinOccupantPayload>) => {},
    muteOccupant: (state, action: PayloadAction<MuteOccupantPayload>) => {},
    muteAllButMe: (state, action: PayloadAction<MuteAllButMePayload>) => {},

    setPinned: (state, action: PayloadAction<SetPinned>) => {
      const { roomId, occupantId } = action.payload;
      const room = state.rooms[roomId];
      if (!room) return;

      if (!room.talkers.includes(occupantId)) {
        room.talkers.push(occupantId);
      }
      if (!room.pinnedTalkers.includes(occupantId)) {
        room.pinnedTalkers.push(occupantId);
      }
    },

    setUnpinned: (state, action: PayloadAction<SetUnpinned>) => {
      const { roomId, occupantId } = action.payload;
      const room = state.rooms[roomId];
      if (!room) return;

      room.pinnedTalkers = room.pinnedTalkers.filter((id) => id !== occupantId);
    },

    setBigMeetingRoom: (state, action: PayloadAction<BigMeetingRoom>) => {
      const confRoom = action.payload;
      state.rooms[confRoom.roomId] = confRoom;
    },
    toldToMute: (state, action: PayloadAction<ToldToMutePayload>) => {},
  },
});
export const { actions: BigMeetingRoomActions, reducer: bigMeetingRoomReducer } = slice;

const selectSlice = (state: RootState) => state.section.bigMeetingRoom;
export const BigMeetingRoomSelectors = {
  selectByRoomId: (roomId?: number) => (state: RootState) =>
    roomId ? selectSlice(state).rooms[roomId] : undefined,
  selectNumTalkerOccupants:
    (roomId?: number) =>
    (state: RootState): number =>
      roomId ? selectSlice(state).rooms[roomId]?.talkers.length ?? 0 : 0,
  selectTalkerOccupantIds: (roomId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      roomId ? slice.rooms[roomId]?.talkers ?? [] : []
    ),
  selectListenerOccupantIds: defaultMemoize((place?: ConversationPlace) =>
    createDeepEqualSelector(
      ConversationParticipantSelectors.selectOccupantIdsForPlace(place),
      selectSlice,
      (allIds, slice) => {
        if (!place || place.kind !== "room") return [];
        const talkers = new Set(slice.rooms[place.roomId]?.talkers ?? []);
        return allIds.filter((id) => !talkers.has(id));
      }
    )
  ),
  selectOccupantPinned: defaultMemoize(
    (roomId?: number, occupantId?: string) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        if (!roomId || !occupantId) return false;
        const confRoom = slice.rooms[roomId];
        if (!confRoom) return false;
        return confRoom.pinnedTalkers.includes(occupantId);
      }),
    { maxSize: 25 }
  ),
  selectIsTalkerInRoom: defaultMemoize((roomId?: number, occupantId?: string) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      if (!roomId || !occupantId) return false;
      const confRoom = slice.rooms[roomId];
      if (!confRoom) return false;
      return confRoom.talkers.includes(occupantId);
    })
  ),
};
