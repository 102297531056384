const whiteColors = {
  white50: "rgba(255, 255, 255, 0.05)",
  white100: "rgba(255, 255, 255, 0.1)",
  white150: "rgba(255, 255, 255, 0.15)",
  white200: "rgba(255, 255, 255, 0.2)",
  white300: "rgba(255, 255, 255, 0.3)",
  white400: "rgba(255, 255, 255, 0.4)",
  white500: "rgba(255, 255, 255, 0.5)",
  white600: "rgba(255, 255, 255, 0.6)",
  white700: "rgba(255, 255, 255, 0.7)",
  white800: "rgba(255, 255, 255, 0.8)",
  white900: "rgba(255, 255, 255, 0.9)",
};

const blackColors = {
  black50: "rgba(0, 0, 0, 0.05)",
  black100: "rgba(0, 0, 0, 0.1)",
  black150: "rgba(0, 0, 0, 0.15)",
  black200: "rgba(0, 0, 0, 0.2)",
  black300: "rgba(0, 0, 0, 0.3)",
  black400: "rgba(0, 0, 0, 0.4)",
  black500: "rgba(0, 0, 0, 0.5)",
  black600: "rgba(0, 0, 0, 0.6)",
  black700: "rgba(0, 0, 0, 0.7)",
  black800: "rgba(0, 0, 0, 0.8)",
  black900: "rgba(0, 0, 0, 0.9)",
};

const graphiteColors = {
  graphite50: "#EBEAEB",
  graphite100: "#DAD9DB",
  graphite150: "#B5B4B8",
  graphite200: "#908E94",
  graphite300: "#6C6970",
  graphite400: "#49474D",
  graphite500: "#333136",
  graphite600: "#29272B",
  graphite700: "#1E1D20",
  graphite800: "#141315",
  graphite900: "#0C0C0E",
};

const greyColors = {
  grey50: "#EDEDED",
  grey100: "#DEDEDE",
  grey150: "#BCBCBC",
  grey200: "#9B9B9B",
  grey300: "#797979",
  grey400: "#585858",
  grey500: "#414141",
  grey600: "#343434",
  grey700: "#272727",
  grey800: "#1A1A1A",
  grey900: "#0D0D0D",
};

const citrusColors = {
  citrus50: "#F9FFF2",
  citrus100: "#F3FEE4",
  citrus200: "#E7FDC6",
  citrus300: "#DAFCA1",
  citrus400: "#CDFB72",
  citrus500: "#BEFA00",
  citrus600: "#AAE000",
  citrus700: "#93C200",
  citrus800: "#789E00",
  citrus900: "#557000",
};

const greenColors = {
  green50: "#C7FFE7",
  green100: "#9DFFCD",
  green200: "#60E7A5",
  green300: "#46D08F",
  green400: "#26BA7B",
  green500: "#00A466",
  green600: "#008E52",
  green700: "#007940",
  green800: "#00642D",
  green900: "#00501C",
};

const amberColors = {
  amber50: "#FFF8E1",
  amber100: "#FFECB3",
  amber200: "#FFE082",
  amber300: "#FFD54F",
  amber400: "#FFCA28",
  amber500: "#FFC107",
  amber600: "#FFB300",
  amber700: "#FFA000",
  amber800: "#FF8F00",
  amber900: "#FF6F00",
};

const pinkColors = {
  pink50: "#FCE4EC",
  pink100: "#F8BBD0",
  pink200: "#F48FB1",
  pink300: "#F06292",
  pink400: "#EC407A",
  pink500: "#E91E63",
  pink600: "#D81B60",
  pink700: "#C2185B",
  pink800: "#AD1457",
  pink900: "#880E4F",
};

const redColors = {
  red50: "#FFEBEE",
  red100: "#FFCDD2",
  red200: "#EF9A9A",
  red300: "#E57373",
  red400: "#EF5350",
  red500: "#F44336",
  red600: "#E53935",
  red700: "#D32F2F",
  red800: "#C62828",
  red900: "#B71C1C",
};

const indigoColors = {
  indigo50: "#F2EFFC",
  indigo100: "#E4DFF9",
  indigo200: "#CBC0F4",
  indigo300: "#B2A0F0",
  indigo400: "#9A7FEC",
  indigo500: "#835CE9",
  indigo600: "#6E31E7",
  indigo700: "#5700C9",
  indigo800: "#3E008E",
  indigo900: "#260058",
};

const blueColors = {
  blue50: "#C1EBFF",
  blue100: "#A8D4FF",
  blue200: "#8DBEFF",
  blue300: "#71A9FF",
  blue400: "#5394FF",
  blue500: "#2C80FF",
  blue600: "#006CF4",
  blue700: "#0059DC",
  blue800: "#0047C6",
  blue900: "#0036AF",
};

const cyanColors = {
  cyan50: "#E0F7FA",
  cyan100: "#B2EBF2",
  cyan200: "#80DEEA",
  cyan300: "#4DD0E1",
  cyan400: "#26C6DA",
  cyan500: "#00BCD4",
  cyan600: "#00ACC1",
  cyan700: "#0097A7",
  cyan800: "#00838F",
  cyan900: "#006064",
};

const allColors = {
  white: "#FFFFFF",
  black: "#000000",
  ...whiteColors,
  ...blackColors,
  ...graphiteColors,
  ...greyColors,
  ...citrusColors,
  ...greenColors,
  ...amberColors,
  ...pinkColors,
  ...redColors,
  ...indigoColors,
  ...blueColors,
  ...cyanColors,
};

const semanticTextAndIconColors = {
  primary: allColors.white,
  secondary: allColors.white500,
  disabled: allColors.white300,
  accentedPrimary: allColors.indigo500,
  accentedSecondary: allColors.citrus500,
  error: allColors.red400,
  warning: allColors.amber500,
  positive: allColors.green300,
  active: allColors.citrus500,
  negative: allColors.red400,
  sensitive: allColors.amber800,
  primaryOnLight: allColors.black,
  secondaryOnLight: allColors.black500,
  disabledOnLight: allColors.black300,
  onAccentedPrimary: allColors.white,
  onAccentedSecondary: allColors.black,
};

const semanticBackgroundColors = {
  primary: allColors.white50,
  secondary: allColors.white100,
  elevatedPrimary: allColors.graphite700,
  elevatedSecondary: allColors.graphite600,
  elevatedTertiary: allColors.white,
  overlayPrimary: allColors.black700,
  overlaySecondary: allColors.black300,
  primaryInverted: allColors.black700,
  primaryOnLight: allColors.black50,
  secondaryOnLight: allColors.black100,
  accentedPrimary: allColors.indigo600,
  accentedSecondary: allColors.citrus500,
  sensitive: allColors.amber800,
  visualization: allColors.indigo700,
};

const semanticSurfaceColors = {
  primary: allColors.graphite900,
  secondary: allColors.white,
};

const semanticBorderColors = {
  primary: allColors.white100,
  secondary: allColors.white150,
  tertiary: allColors.white300,
  focus: allColors.white,
  error: allColors.red400,
  accentedPrimary: allColors.indigo500,
  accentedSecondary: allColors.citrus500,
  positive: allColors.green300,
  negative: allColors.red400,
  primaryOnLight: allColors.black100,
  secondaryOnLight: allColors.black150,
  tertiaryOnLight: allColors.black300,
};

export const semanticColors = {
  text: semanticTextAndIconColors,

  // Icons use the exact same semantic colors as text. I have just given them
  // their own named key to make it obvious that they can be used for icons
  // (rather than either just having a "text" key and hoping people realize
  // that they can be used for icons, or having an ugly key named "textIcon"
  // or "textAndIcon").
  icon: semanticTextAndIconColors,

  background: semanticBackgroundColors,
  surface: semanticSurfaceColors,
  border: semanticBorderColors,
};

export type SemanticColorsType = typeof semanticColors;

export default allColors;
