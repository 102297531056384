import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomEmojiRecord } from "../../../../shared/Models/chat/CustomEmoji.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export type FetchCustomEmojiRecordsPayload = {
  roamId: number;
};

type SetCustomEmojiPayload = {
  emoji: CustomEmojiRecord[];
};

export type DeleteCustomEmojiPayload = {
  roamId: number;
  itemId: string;
  fileId: string;
};

export type StartImportCustomEmojiPayload = {
  roamId: number;
  token: string;
};

export type ImportCustomEmojiPayload = {
  roamId: number;
  code: string;
  url: string;
};

export type ImportResult = {
  status: string;
  done: number;
  total: number;
  result: string;
};

export type ImportStatus = {
  status?: string;
  done?: number;
  total?: number;
  results?: string[];
};

const slice = createSlice({
  name: "customEmojiAdmin",
  initialState: {
    emoji: [] as CustomEmojiRecord[],
    importStatus: {} as ImportStatus,
  },
  reducers: {
    fetchCustomEmojiRecords: (state, action: PayloadAction<FetchCustomEmojiRecordsPayload>) => {},
    setCustomEmojiRecords: (state, action: PayloadAction<SetCustomEmojiPayload>) => {
      const { emoji } = action.payload;
      state.emoji = emoji;
    },
    deleteCustomEmoji: (state, action: PayloadAction<DeleteCustomEmojiPayload>) => {
      const { fileId } = action.payload;
      state.emoji = state.emoji.filter((em) => em.fileId !== fileId);
    },
    startImportCustomEmoji: (state, action: PayloadAction<StartImportCustomEmojiPayload>) => {
      state.importStatus = { status: "Fetching" };
    },
    importCustomEmoji: (state, action: PayloadAction<ImportCustomEmojiPayload>) => {},
    addImportResult: (state, action: PayloadAction<ImportResult>) => {
      const { status, done, total, result } = action.payload;
      const results = state.importStatus?.results ?? [];
      results.push(result);
      state.importStatus = { status, done, total, results };
    },
    completeImport: (state, action: PayloadAction<string | undefined>) => {
      const error = action.payload;
      state.importStatus.status = error ? error : "Complete";
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.customEmojiAdmin;

export const selectors = {
  selectAll: (roamId: number) =>
    createSelector(selectSlice, (customEmojiAdmin) => {
      return customEmojiAdmin.emoji;
    }),
  selectImportStatus: (state: RootState) => selectSlice(state).importStatus,
};

export const CustomEmojiAdminActions = actions;
export const CustomEmojiAdminSelectors = selectors;
