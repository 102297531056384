import React from "react";
import { css, styled } from "../../client/styles/theme.js";
import { BackgroundGradient, backgroundGradients } from "../styles/backgroundGradients.js";

/**
 * Usage examples:

   // Simple button
   <Button>
     Button Text
   </Button>

   // Small button
   <Button small>
     Button Text
   </Button>

   // Large button
   <Button large>
     Button Text
   </Button>

   // Button with icon & text
   <Button>
     <Button.Icon>
       <IoMicOutline />
     </Button.Icon>
     <Button.Text sizingTexts={["Mute", "Unmute"]}>
       Mute
     </Button.Text>
   </Button>
 */

type ButtonTextProps = {
  color?: string;
  uppercase?: boolean;
  multiLine?: boolean;

  // An optional array of strings that may be used with the button text.
  // Provide this so the button is wide enough to fit the widest string, such
  // that if the button text changes, the button will not change width.
  // (e.g., button that toggles between "Mute" and "Unmute")
  sizingTexts?: string[];
};

const SizingTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
`;
SizingTextsContainer.displayName = "SizingTextsContainer";

const SizingText = styled.div``;
SizingText.displayName = "SizingText";

type ButtonTextInnerProps = Omit<ButtonTextProps, "sizingTexts">;

const ButtonTextInner = styled.div<ButtonTextInnerProps>`
  color: ${({ color }) => color || "inherit"};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};

  ${({ multiLine }) =>
    !multiLine &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    `};
`;
ButtonTextInner.displayName = "ButtonTextInner";

const ButtonText = ({
  children,
  sizingTexts,
  ...rest
}: React.PropsWithChildren<ButtonTextProps>) => {
  return (
    <ButtonTextInner {...rest}>
      {sizingTexts && sizingTexts.length > 0 && (
        <SizingTextsContainer>
          {sizingTexts.map((text) => (
            <SizingText key={text} aria-hidden={true}>
              {text}
            </SizingText>
          ))}
        </SizingTextsContainer>
      )}
      {children}
    </ButtonTextInner>
  );
};

type ButtonIconProps = {
  color?: string;
  tiny?: boolean;
  small?: boolean;
  large?: boolean;
};

const ButtonIcon = styled.div<ButtonIconProps>`
  color: ${({ color }) => color || "inherit"};
  display: flex;
  font-size: ${({ tiny, small, large }) =>
    tiny ? "1em" : small ? "1.5em" : large ? "2.5em" : "2em"};
`;
ButtonIcon.displayName = "ButtonIcon";

export type ButtonProps = {
  variant?: "solid" | "outline" | "ghost";
  active?: boolean;
  tiny?: boolean;
  small?: boolean;
  large?: boolean;
  toolbar?: boolean;
  vertical?: boolean;
  capsule?: boolean;
  progress?: number;
  gradient?: BackgroundGradient; // only meant to be used with solid variant
  dangerous?: boolean;
  warning?: boolean;
  inverse?: boolean;

  // I'd prefer to call this property `onLight`, but for any custom properties starting with `on`, React gives the following:
  // "Warning: Unknown event handler property `onLight`. It will be ignored."
  overLight?: boolean;
};

const Button = styled.button.attrs((props) => ({ type: props.type || "button" }))<ButtonProps>`
  position: relative;
  display: flex;
  flex-direction: ${({ toolbar, vertical }) => (toolbar || vertical ? "column" : "row")};
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: ${({ theme, variant, tiny, small, gradient }) =>
    (variant === "ghost" && (tiny || small)) || gradient
      ? "0"
      : `${theme.dimen.border.default} solid transparent`};
  padding: ${({ tiny, small, large, toolbar }) =>
    tiny ? "0.25rem" : small || toolbar ? "0.5rem" : large ? "0.75rem 1.5rem" : "0.5rem 1rem"};

  /* Make the button square if it only has an icon and no text */
  &:has(${ButtonIcon}:only-child) {
    ${({ tiny, small, large, toolbar }) =>
      !tiny &&
      !small &&
      !toolbar &&
      css`
        padding: ${large ? "0.75rem" : "0.5rem"};
      `}
  }

  border-radius: ${({ tiny, small, large, toolbar, capsule }) =>
    capsule
      ? "1000rem"
      : tiny
        ? "0.25rem"
        : small || toolbar
          ? "0.5rem"
          : large
            ? "0.75rem"
            : "0.5rem"};
  ${({ theme, tiny, small, large, toolbar, vertical }) =>
    tiny || toolbar
      ? theme.font.caption2
      : small || vertical
        ? theme.font.caption
        : large
          ? theme.font.body
          : theme.font.subheadline};
  transition:
    color 0.15s ease-in-out,
    background 0.15s ease-in-out;

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
  }

  ${({ theme, variant, active, toolbar, gradient, dangerous, warning, inverse, overLight }) =>
    ((!variant && !toolbar) || variant === "solid") &&
    css`
      background: ${gradient
        ? backgroundGradients[gradient as BackgroundGradient]
        : dangerous
          ? active
            ? theme.color.button.filled.dangerous.activeBackground
            : theme.color.button.filled.dangerous.background
          : warning
            ? active
              ? theme.color.button.filled.warning.activeBackground
              : theme.color.button.filled.warning.background
            : inverse
              ? active
                ? theme.color.button.filled.inverse.activeBackground
                : theme.color.button.filled.inverse.background
              : overLight
                ? active
                  ? theme.color.button.filled.onLight.activeBackground
                  : theme.color.button.filled.onLight.background
                : active
                  ? theme.color.button.filled.activeBackground
                  : theme.color.button.filled.background};
      color: ${dangerous
        ? theme.color.button.filled.dangerous.color
        : warning
          ? theme.color.button.filled.warning.color
          : inverse
            ? theme.color.button.filled.inverse.color
            : overLight
              ? theme.color.button.filled.onLight.color
              : theme.color.button.filled.color};

      &:not(:disabled):hover {
        background: ${gradient
          ? backgroundGradients[gradient as BackgroundGradient]
          : dangerous
            ? active
              ? theme.color.button.filled.dangerous.activeBackground
              : theme.color.button.filled.dangerous.hoverBackground
            : warning
              ? active
                ? theme.color.button.filled.warning.activeBackground
                : theme.color.button.filled.warning.hoverBackground
              : inverse
                ? active
                  ? theme.color.button.filled.inverse.activeBackground
                  : theme.color.button.filled.inverse.hoverBackground
                : overLight
                  ? active
                    ? theme.color.button.filled.onLight.activeBackground
                    : theme.color.button.filled.onLight.hoverBackground
                  : active
                    ? theme.color.button.filled.activeBackground
                    : theme.color.button.filled.hoverBackground};
      }

      &:focus {
        background: ${gradient
          ? backgroundGradients[gradient as BackgroundGradient]
          : dangerous
            ? theme.color.button.filled.dangerous.focusBackground
            : warning
              ? theme.color.button.filled.warning.focusBackground
              : inverse
                ? theme.color.button.filled.inverse.focusBackground
                : overLight
                  ? theme.color.button.filled.onLight.focusBackground
                  : theme.color.button.filled.focusBackground};
      }

      &:disabled {
        background: ${gradient
          ? backgroundGradients[gradient as BackgroundGradient]
          : inverse
            ? theme.color.button.filled.inverse.disabledBackground
            : theme.color.button.filled.disabledBackground};
        color: ${inverse
          ? theme.color.button.filled.inverse.disabledColor
          : theme.color.button.filled.disabledColor};
      }
    `};

  ${({ theme, variant, active, toolbar, dangerous, warning, overLight }) =>
    (variant === "outline" || variant === "ghost" || toolbar) &&
    css`
      background: ${active
        ? overLight
          ? theme.color.button.outlined.onLight.activeBackground
          : theme.color.button.outlined.activeBackground
        : "transparent"};
      color: ${dangerous
        ? theme.color.button.outlined.dangerous.color
        : warning
          ? theme.color.button.outlined.warning.color
          : overLight
            ? active
              ? theme.color.button.outlined.onLight.activeColor
              : theme.color.button.outlined.onLight.color
            : theme.color.button.outlined.color};
      border-color: ${variant === "ghost"
        ? "transparent"
        : dangerous
          ? theme.color.button.outlined.dangerous.border
          : warning
            ? theme.color.button.outlined.warning.border
            : overLight
              ? theme.color.button.outlined.onLight.border
              : theme.color.button.outlined.border};

      &:not(:disabled):hover {
        background: ${active
          ? overLight
            ? theme.color.button.outlined.onLight.activeBackground
            : theme.color.button.outlined.activeBackground
          : overLight
            ? theme.color.button.outlined.onLight.hoverBackground
            : theme.color.button.outlined.hoverBackground};
      }

      &:disabled {
        border-color: ${variant === "ghost" ? "transparent" : theme.color.button.outlined.border};
        color: ${overLight
          ? theme.color.button.outlined.onLight.disabledColor
          : theme.color.button.outlined.disabledColor};
      }
    `};

  ${ButtonIcon} + ${ButtonTextInner} {
    margin-left: 0.5em;
  }

  ${ButtonTextInner} + ${ButtonIcon} {
    margin-left: 0.5em;
  }

  ${({ toolbar }) =>
    toolbar &&
    css`
      min-width: 4rem;

      @media only screen and (max-width: 1200px) {
        min-width: 3.5rem;
      }

      @media only screen and (max-width: 1000px) {
        min-width: 3rem;
      }

      @media only screen and (max-width: 900px) {
        min-width: 2.5rem;
      }

      ${ButtonIcon} {
        font-size: 1.5rem;
      }
    `};

  ${({ toolbar, vertical }) =>
    (toolbar || vertical) &&
    css`
      ${ButtonIcon} + ${ButtonTextInner} {
        margin-left: 0;
        margin-top: 0.125rem;
      }
    `};

  ${({ theme, variant, tiny, small, progress }) =>
    progress !== undefined &&
    progress > 0 &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: ${(1 - Math.max(0, Math.min(1, progress))) * 100}%;
        bottom: 0;
        background: ${variant === "outline" || variant === "ghost"
          ? theme.color.button.outlined.progressBackground
          : theme.color.button.filled.progressBackground};
        /* Margin is button border width * -1 */
        margin: ${variant === "ghost" && (tiny || small) ? "0" : `-${theme.dimen.border.default}`};

        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        border-top-right-radius: ${progress <= 0.98 ? 0 : "inherit"};
        border-bottom-right-radius: ${progress <= 0.98 ? 0 : "inherit"};
      }
    `};
`;
Button.displayName = "Button";

export default Object.assign(Button, {
  Text: ButtonText,
  Icon: ButtonIcon,
});

// Not meant to be used directly as a React component, but to be referenced by other styled-components
export { ButtonTextInner, SizingTextsContainer };
