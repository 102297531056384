import { Environment } from "../../shared/api/environment.js";
import { environment } from "../../shared/api/http.js";

export const isWonderEmail = (email?: string): boolean => {
  if (!email) {
    return false;
  }
  if (email.endsWith("@wonder.inc")) {
    return true;
  }
  if (email.endsWith(".wonder.dev")) {
    return true;
  }
  if (email.endsWith("@ro.am")) {
    return true;
  }
  if (email === "winstein@gmail.com") {
    return true;
  }
  return false;
};

export const isWonderTestEmail = (email: string): boolean => {
  return (
    // cspell:disable-next-line
    (email.startsWith("testaccount") || email.startsWith("testuser")) &&
    (email.endsWith("@wonder.inc") || email.endsWith(".wonder.dev"))
  );
};

export const isWonderDevEmail = (email: string): boolean => {
  return (
    email.includes("wonder.dev") ||
    email.includes("tokens.horse") ||
    email.includes("dream.horse") ||
    email.includes("awonderinvention.com") ||
    email.includes("theholoway.com")
  );
};

export const envEmailDomain: { [env in Environment]: string | undefined } = {
  local: "dev.wonder.dev",
  exp: "exp.wonder.dev",
  dev: "dev.wonder.dev",
  stage: "stage.wonder.dev",
  playground: "playground.wonder.dev",
  prod: undefined,
};

export const prodToEnvEmail = (email: string): string => {
  const envSuffix = envEmailDomain[environment];
  if (envSuffix) {
    return email.replace("wonder.inc", envSuffix);
  }
  return email;
};
