import { CustomEmoji } from "../Models/chat/CustomEmoji.js";

export const CUSTOM_EMOJI_REGEX =
  /:([\d+_a-z-]{1,32}):{2}([\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}):/;
export const CUSTOM_EMOJI_REGEX_GLOBAL = new RegExp(CUSTOM_EMOJI_REGEX, "g");
export const WHOLE_CUSTOM_EMOJI_REGEX =
  /(:[\d+_a-z-]{1,32}::[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}:)/g;

// customEmojiToPlaintext returns a plaintext view of the custom emoji.
// Since there's not much we can do in plaintext, just show the shortcode.
export const customEmojiToPlaintext = (markdown: string) => {
  return markdown.replace(CUSTOM_EMOJI_REGEX_GLOBAL, ":$1:");
};

export const parseCustomEmoji = (match: string[]): CustomEmoji | undefined => {
  if (match.length !== 3 || !match[1] || !match[2]) {
    return undefined;
  }
  return { code: match[1], fileId: match[2] };
};

export const parseWholeCustomEmoji = (str: string): CustomEmoji | undefined => {
  const match = str.match(CUSTOM_EMOJI_REGEX);
  if (!match) return undefined;
  return parseCustomEmoji(match);
};
