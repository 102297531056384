import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AudioItem, StoryItem, VideoItem } from "../../../../shared/Models/Item.js";
import { RootState } from "../../../store/reducers.js";
import { PlaylistSummary } from "../../media/playlist.js";

export interface SetPlaylist {
  itemId: string;
  playlist: PlaylistSummary;
}

type SliceState = {
  playlists: { [itemId: string]: PlaylistSummary };
};

const initialState: SliceState = {
  playlists: {},
};

const slice = createSlice({
  name: "playlist",
  initialState,
  reducers: {
    setPlaylist: (state, action: PayloadAction<SetPlaylist>) => {
      const { itemId, playlist } = action.payload;
      state.playlists[itemId] = playlist;
    },
    fetchPlaylist: (state, action: PayloadAction<AudioItem | VideoItem | StoryItem>) => {},
  },
});

export const { actions: PlaylistActions, reducer: playlistReducer } = slice;

export const PlaylistSelectors = {
  selectPlaylist: (itemId: string | undefined) => (state: RootState) =>
    itemId ? state.holo.playlist.playlists[itemId] : undefined,
};
