/* eslint-disable no-restricted-globals */
import ApplicationError from "../../shared/backend/ApplicationError.js";
import { logger } from "../../shared/infra/logger.js";
import {
  HTMLAudioElementWithExperimentalFeatures,
  HTMLVideoElementWithExperimentalFeatures,
} from "../helpers/media.js";
import { IRedux } from "../injection/redux/IRedux.js";
import { IFakeStreams } from "./interfaces/IFakeStreams.js";

interface Injected {
  redux(): IRedux;
}

export class FakeStreams implements IFakeStreams {
  constructor(private container: Injected) {}

  private videoElement?: HTMLVideoElementWithExperimentalFeatures;
  private videoUrl?: string;
  private videoPromise?: Promise<void>;
  private videoStream?: MediaStream | undefined;

  private audioElement?: HTMLAudioElementWithExperimentalFeatures;
  private audioUrl?: string;
  private audioBgVolume?: number;
  private audioPromise?: Promise<void>;
  private audioStream?: MediaStream | undefined;
  private audioContext?: AudioContext | undefined;
  private gainNode?: GainNode | undefined;
  private audioDestNode?: MediaStreamAudioDestinationNode | undefined;

  private cachedAudio = new Map<string, AudioBuffer>();

  public setVideoOverride(url: string): Promise<void> {
    if (this.videoPromise) {
      return this.videoPromise;
    }
    if (this.videoUrl === url && this.videoStream && this.videoStream.active) {
      return Promise.resolve();
    }
    // videoElement is only created once
    if (!this.videoElement) {
      this.videoElement = document.createElement(
        "video"
      ) as HTMLVideoElementWithExperimentalFeatures;
      this.videoElement.style.display = "none";
      this.videoElement.id = "hiddenVideoElement";
      this.videoElement.autoplay = true;
      this.videoElement.crossOrigin = "anonymous";
      this.videoElement.playsInline = true;
      this.videoElement.loop = true;
      document.body.appendChild(this.videoElement);
    }
    this.videoUrl = url;
    this.videoElement.src = url;
    const el = this.videoElement;
    this.videoPromise = new Promise((resolve, reject) => {
      el.addEventListener("canplay", () => {
        logger.info(`video ${this.videoUrl} can play`);
        el.play()
          .then(() => {
            logger.info(`video ${this.videoUrl} is playing`);
            this.videoStream = el.captureStream();
            this.videoPromise = undefined;
            resolve();
          })
          .catch((err: any) => {
            logger.error({ err }, `error playing video`);
          });
      });
      el.load();
    });
    return this.videoPromise;
  }

  public async setAudioOverride(url: string, volume = 1): Promise<void> {
    if (this.audioPromise) {
      return this.audioPromise;
    }
    if (this.audioUrl === url && this.audioStream && this.gainNode) {
      this.gainNode.gain.value = volume;
      this.audioBgVolume = volume;
      return;
    }

    this.audioPromise = (async () => {
      this.audioUrl = url;
      this.audioBgVolume = volume;
      await this.refreshAudioStream();
    })();

    return this.audioPromise;
  }

  private async refreshAudioStream(): Promise<void> {
    if (!this.audioUrl || !this.audioBgVolume) {
      throw new Error("refreshAudioStream called without this.audioUrl");
    }
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
    }
    const decodedData = await this.getAudioUrl(this.audioUrl);
    const audioSourceNode = this.audioContext.createBufferSource();
    audioSourceNode.buffer = decodedData;
    audioSourceNode.loop = true;
    this.gainNode = this.audioContext.createGain();
    this.gainNode.gain.value = this.audioBgVolume;
    audioSourceNode.connect(this.gainNode);
    this.audioDestNode = this.audioContext.createMediaStreamDestination();
    this.gainNode.connect(this.audioDestNode);
    this.audioStream = this.audioDestNode.stream;
    this.audioPromise = undefined;
    audioSourceNode.start();
  }

  public async preloadAudioUrls(urls: string[]): Promise<void> {
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
    }
    for (const url of urls) {
      if (this.cachedAudio.has(url)) {
        logger.info(`preloadAudioUrls: skipping ${url} because already cached`);
        continue;
      }
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const decodedData = await this.audioContext.decodeAudioData(arrayBuffer);
      this.cachedAudio.set(url, decodedData);
      logger.info(`preloadAudioUrls: cached ${url}`);
    }
  }

  private async getAudioUrl(url: string): Promise<AudioBuffer> {
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
    }
    const cached = this.cachedAudio.get(url);
    if (cached) {
      logger.info(`getAudioUrl: using cached ${url}`);
      return cached;
    }
    const response = await fetch(url);
    if (!response.ok) {
      throw new ApplicationError(`retrieving audio at ${url}: ${response.status}`);
    }
    const arrayBuffer = await response.arrayBuffer();
    const decodedData = await this.audioContext.decodeAudioData(arrayBuffer);
    logger.info(`getAudioUrl: retrieved and caching ${url}`);
    this.cachedAudio.set(url, decodedData);
    return decodedData;
  }

  public async playAudio(url: string, volume = 1): Promise<void> {
    if (!this.audioContext || !this.audioDestNode) {
      logger.error("playAudio returned before audioContext set up");
      return;
    }
    const decodedData = await this.getAudioUrl(url);
    const audioSourceNode = this.audioContext.createBufferSource();
    audioSourceNode.buffer = decodedData;
    const gainNode = this.audioContext.createGain();
    gainNode.gain.value = volume;
    audioSourceNode.connect(gainNode);
    gainNode.connect(this.audioDestNode);
    logger.info(`playing audio ${url} at volume ${volume}`);
    audioSourceNode.start();
  }

  public async clearVideoOverride() {
    this.videoUrl = undefined;
    if (this.videoElement) {
      this.videoElement.pause();
      this.videoStream = undefined;
      this.videoElement.src = "";
    }
  }

  public async clearAudioOverride() {
    this.audioUrl = undefined;
    this.gainNode?.disconnect();
    this.audioDestNode?.disconnect();
    this.audioStream = undefined;
    if (this.audioElement) {
      this.audioElement.pause();
      this.audioElement.src = "";
    }
  }

  public async getOverrideStream(type: "audio" | "video"): Promise<MediaStream | undefined> {
    if (type === "video") {
      return this.videoStream;
    } else if (type === "audio") {
      if (this.audioStream && !this.audioStream.active) {
        await this.refreshAudioStream();
      }
      return this.audioStream;
    }
  }

  public hasFakeAudioStream(): boolean {
    return this.audioStream !== undefined;
  }

  public hasFakeVideoStream(): boolean {
    return this.videoStream !== undefined;
  }
}
