import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { baseRoamURL } from "../../../../shared/api/http.js";
import { defaultPersonPhotoUrl } from "../../../../shared/helpers/assets.js";
import { logger } from "../../../../shared/infra/logger.js";
import { Person } from "../../../../shared/Models/Person.js";
import { PersonAction } from "../../../../shared/Models/PersonAction.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

const adapter = createEntityAdapter<Person>();

export interface StartAddMemberPayload {
  accountId?: number;
  name?: string;
  email?: string;
  homeRoamId?: number;
  homeFloorId?: number;
  specifiedSeat?: {
    roomId: number;
    seatNumber: number;
  };
  windowKey: WindowKey;
}

// Do this on the client rather than the server. Server could send undefined
// fields which shouldn't overwrite any existing data.
// These used to be called as part of the upsert operations, but
// to reduce CPU use we do it before (as we were upserting first, and then)
// calling upsertOne on any changed entities
export const fillInDefaultPhoto = (person: Person) => {
  if (person && (!person.imageAbsoluteUrl || person.imageAbsoluteUrl.length === 0)) {
    person.imageAbsoluteUrl = defaultPersonPhotoUrl(baseRoamURL);
  }
};
export const fillInDefaultPhotos = (people: Person[]) => {
  for (const person of people) {
    if (person && (!person.imageAbsoluteUrl || person.imageAbsoluteUrl.length === 0)) {
      person.imageAbsoluteUrl = defaultPersonPhotoUrl(baseRoamURL);
    }
  }
};

const slice = createSlice({
  name: "myPerson",
  initialState: adapter.getInitialState(),
  reducers: {
    clientAction: (state, action: PayloadAction<PersonAction>) => {},
    startAddMember: (state, action: PayloadAction<StartAddMemberPayload>) => {},

    setAll: (state, action: PayloadAction<Person[]>) => adapter.setAll(state, action),
    removePerson: (state, action: PayloadAction<number>) => adapter.removeOne(state, action),
    removePeople: (state, action: PayloadAction<number[]>) => adapter.removeMany(state, action),
    setPerson: (state, action: PayloadAction<Person>) => adapter.setOne(state, action),
    setPeople: (state, action: PayloadAction<Person[]>) => adapter.setMany(state, action),

    requestMyPeople: (_) => {},
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
};

const adapterSelectors = adapter.getSelectors((state: RootState) => state.anyWorld.myPerson);
export const selectors = {
  selectMyPeopleEntities: adapterSelectors.selectEntities,
  selectMyPersonById: (personId?: number) => (state: RootState) =>
    personId ? adapterSelectors.selectById(state, personId) : undefined,
  selectMyPeopleByIds: defaultMemoize(
    (ids?: number[]) => {
      return createDeepEqualSelector(adapterSelectors.selectEntities, (people) => {
        const personEntities: { [id: number]: Person } = {};
        for (const id of ids ?? []) {
          const person = people[id];
          if (person) {
            personEntities[id] = person;
          } else {
            logger.warn(`Could not find person with id ${id}`);
          }
        }
        return personEntities;
      });
    },
    { equalityCheck: equal }
  ),
  selectAmIRoamgineer: createSelector(adapterSelectors.selectAll, (people) =>
    people.some((person) => person.isRoamgineer)
  ),
};

export const MyPersonSelectors = selectors;
