import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultTitleBarProps, TitleBarProperties } from "../../../shared/helpers/windows.js";
import { TypeNarrowError } from "../../../shared/TypeNarrowError.js";
import { createSelector } from "../../helpers/redux.js";
import { WindowFeatures } from "../../injection/windows/WindowFeatures.js";
import { WindowInstance } from "../../injection/windows/WindowInstance.js";
import { mainWindowKey, meetingWindowKey, WindowKey } from "../../injection/windows/WindowKey.js";
import { RootState } from "../reducers.js";

export type WindowState = "fullScreen" | "maximized" | "minimized" | "normal";
export interface WindowStatePayload {
  windowKey: WindowKey;
  windowState: WindowState;
}

export interface SetWindowDisplayIdPayload {
  windowKey: WindowKey;
  displayId: number | undefined;
}

export interface SetWindowTitlePayload {
  windowKey: WindowKey;
  title?: string;
}

export interface FloatingRequestedHeightPayload {
  section: string;
  heightPx: number;
  floatingContext?: string;
}

export interface OpenWindowPayload {
  windowFeatures?: WindowFeatures;
  windowKey: WindowKey;
}

export interface WindowClosedPayload {
  windowKey: WindowKey;
  windowInstance: WindowInstance;
}

export interface WindowOpenedPayload {
  windowKey: WindowKey;
  windowInstance: WindowInstance;
}

export const bigWindows: WindowKey[] = [mainWindowKey, meetingWindowKey];

export interface WindowInfo {
  displayId?: number;
  state?: WindowState;
  title?: string;
  visible?: boolean;
  windowInstance: WindowInstance;
}

const slice = createSlice({
  name: "window",
  initialState: {
    windows: {} as { [windowKey: WindowKey]: WindowInfo },

    focusedWindow: undefined as WindowKey | undefined,
    lastFocusedWindow: undefined as WindowKey | undefined,

    // The last focused window, not including popup windows
    lastFocusedBigWindow: undefined as WindowKey | undefined,

    titleBarProps: defaultTitleBarProps,

    floatingExpanded: true,
    floatingContext: undefined as string | undefined,
    floatingRequestedHeights: {} as { [section: string]: number },
  },
  reducers: {
    windowStateChanged: (state, action: PayloadAction<WindowStatePayload>) => {
      const { windowKey, windowState } = action.payload;
      const window = state.windows[windowKey];
      if (window) {
        window.state = windowState;
      }
    },

    windowBlurred: (state, action: PayloadAction<WindowKey>) => {
      if (equal(state.focusedWindow, action.payload)) {
        state.focusedWindow = undefined;
      }
    },
    windowClosed: (state, action: PayloadAction<WindowClosedPayload>) => {
      const { windowKey } = action.payload;
      delete state.windows[windowKey];
    },
    windowFocused: (state, action: PayloadAction<WindowKey>) => {
      state.focusedWindow = action.payload;
      state.lastFocusedWindow = action.payload;
      if (bigWindows.includes(action.payload)) {
        state.lastFocusedBigWindow = action.payload;
      }
    },
    windowMoved: (state, action: PayloadAction<WindowKey>) => {},
    windowOpened: (state, action: PayloadAction<WindowOpenedPayload>) => {
      const { windowKey, windowInstance } = action.payload;
      state.windows[windowKey] = { windowInstance };
    },
    windowResized: (state, action: PayloadAction<WindowKey>) => {},
    windowHidden: (state, action: PayloadAction<WindowKey>) => {
      const window = state.windows[action.payload];
      if (window) {
        window.visible = false;
      }
    },
    windowShown: (state, action: PayloadAction<WindowKey>) => {
      const window = state.windows[action.payload];
      if (window) {
        window.visible = true;
      }
    },

    setTitleBarProps: (state, action: PayloadAction<TitleBarProperties>) => {
      state.titleBarProps = action.payload;
    },

    setWindowDisplayId: (state, action: PayloadAction<SetWindowDisplayIdPayload>) => {
      const { windowKey, displayId } = action.payload;
      const window = state.windows[windowKey];
      if (window) {
        window.displayId = displayId;
      }
    },
    setFloatingExpanded: (state, action: PayloadAction<boolean>) => {
      state.floatingExpanded = action.payload;
    },
    setFloatingRequestedHeight: (state, action: PayloadAction<FloatingRequestedHeightPayload>) => {
      const { section, heightPx, floatingContext } = action.payload;
      if (floatingContext) {
        state.floatingContext = floatingContext;
      }
      state.floatingRequestedHeights[section] = heightPx;
    },
    clearFloatingRequestedHeights: (state) => {
      state.floatingRequestedHeights = {};
      state.floatingContext = undefined;
    },

    closeWindow: (state, action: PayloadAction<WindowKey>) => {},
    openWindow: (state, action: PayloadAction<OpenWindowPayload>) => {},
    setWindowTitle: (state, action: PayloadAction<SetWindowTitlePayload>) => {
      const { windowKey, title } = action.payload;
      const window = state.windows[windowKey];
      if (window) {
        window.title = title;
      }
    },
    showWindow: (state, action: PayloadAction<WindowKey>) => {},
  },
});
export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.window;
const selectWindowInfo = (state: RootState, windowKey?: WindowKey) =>
  windowKey !== undefined ? selectSlice(state).windows[windowKey] : undefined;

export const selectors = {
  selectFocusedWindow: (state: RootState) => selectSlice(state).focusedWindow,
  selectFocused: (windowKey?: WindowKey) => (state: RootState) =>
    windowKey !== undefined && selectSlice(state).focusedWindow === windowKey,
  selectLastFocusedWindow: (state: RootState) => selectSlice(state).lastFocusedWindow,
  selectLastFocusedBigWindow: (state: RootState) => selectSlice(state).lastFocusedBigWindow,
  selectLastFocused: (windowKey?: WindowKey) => (state: RootState) =>
    windowKey !== undefined && selectSlice(state).lastFocusedWindow === windowKey,
  selectLastFocusedBig: (windowKey?: WindowKey) => (state: RootState) =>
    windowKey !== undefined && selectSlice(state).lastFocusedBigWindow === windowKey,
  selectTitle: (windowKey?: WindowKey) => (state: RootState) =>
    selectWindowInfo(state, windowKey)?.title,
  selectFullScreen: (windowKey?: WindowKey) => (state: RootState) =>
    selectWindowInfo(state, windowKey)?.state === "fullScreen",
  selectMaximized: (windowKey?: WindowKey) => (state: RootState) =>
    selectWindowInfo(state, windowKey)?.state === "maximized",
  selectOpen: (windowKey?: WindowKey) => (state: RootState) =>
    selectWindowInfo(state, windowKey) !== undefined,
  selectVisible: (windowKey?: WindowKey) => (state: RootState) =>
    selectWindowInfo(state, windowKey)?.visible ?? false,
  selectTitleBarProps: (state: RootState) => selectSlice(state).titleBarProps,
  selectFloatingExpanded: (state: RootState) => selectSlice(state).floatingExpanded,
  selectFloatingRequestedHeight: createSelector(
    (state: RootState) => state.window.floatingRequestedHeights,
    (heights) => {
      // Select the total requested height for all sections. If the key is
      // XXX-YYY, then only use the largest request for each XXX
      const bySection = new Map<string, number>();
      for (const [section, heightsSection] of Object.entries(heights)) {
        if (section.includes("-")) {
          const [secPart, subPart] = section.split("-");
          if (!secPart) throw new TypeNarrowError();

          const current = bySection.get(secPart);
          if (!current || heightsSection > current) {
            bySection.set(secPart, heightsSection);
          }
        } else {
          bySection.set(section, heightsSection);
        }
      }
      return Math.round([...bySection.values()].reduce((prev, current) => prev + current, 0));
    }
  ),
  selectWindowTypeGuess: (slice: RootState): WindowKey => {
    // for use in rare situations where window context cannot be determined
    if (slice.window.focusedWindow) {
      return slice.window.focusedWindow;
    }

    return mainWindowKey;
  },
  selectDisplayId: (windowKey?: WindowKey) => (state: RootState) =>
    selectWindowInfo(state, windowKey)?.displayId,
};

export const WindowSelectors = selectors;
export const WindowActions = actions;
