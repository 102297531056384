import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { z } from "zod";
import { isElectron } from "../../../shared/api/environment.js";
import { UnreachableError } from "../../../shared/helpers/UnreachableError.js";
import { WindowKey } from "../../injection/windows/WindowKey.js";
import { RootState } from "../reducers.js";

export const InspectorDisplayMode = z.enum(["sidepanel", "nativeWindow"]);
export type InspectorDisplayMode = z.infer<typeof InspectorDisplayMode>;

export const WindowInspector = z.enum(["floorElevator", "meetingChat"]);
export type WindowInspector = z.infer<typeof WindowInspector>;

export const SetAllDisplayModesPayload = z.record(WindowInspector, InspectorDisplayMode);
export type SetAllDisplayModesPayload = z.infer<typeof SetAllDisplayModesPayload>;

export interface SetWindowInspectorDisplayModePayload {
  windowKey: WindowKey;
  inspector: WindowInspector;
  displayMode: InspectorDisplayMode;
}

export interface WindowInspectorPayload {
  windowKey: WindowKey;
  inspector: WindowInspector;
}

const defaultDisplayMode: InspectorDisplayMode = isElectron ? "nativeWindow" : "sidepanel";

const slice = createSlice({
  name: "inspector",
  initialState: {
    displayMode: {} as { [windowInspector in WindowInspector]: InspectorDisplayMode },
    windowSidepanels: {} as { [windowKey: WindowKey]: WindowInspector },
    openNativeWindows: [] as WindowInspector[],
  },
  reducers: {
    loadDisplayModes: () => {},
    setAllDisplayModes: (state, action: PayloadAction<SetAllDisplayModesPayload>) => {
      state.displayMode = { ...state.displayMode, ...action.payload };
    },

    setWindowInspectorDisplayMode: (
      state,
      action: PayloadAction<SetWindowInspectorDisplayModePayload>
    ) => {
      const { inspector, displayMode } = action.payload;
      if (displayMode === "nativeWindow" && !isElectron) return;

      state.displayMode[inspector] = displayMode;

      switch (displayMode) {
        case "sidepanel": {
          // Open in sidepanel
          const { windowKey } = action.payload;
          state.windowSidepanels[windowKey] = inspector;

          // Close native window
          state.openNativeWindows = state.openNativeWindows.filter((i) => i !== inspector);
          break;
        }

        case "nativeWindow": {
          // Open native window
          if (!state.openNativeWindows.includes(inspector)) {
            state.openNativeWindows.push(inspector);
          }

          // Close in sidepanel for ALL window keys (if only closing for provided windowKey, the
          // native window and sidepanel would conflict across different windows)
          const windowKeys = Object.keys(state.windowSidepanels) as WindowKey[];
          for (const windowKey of windowKeys) {
            if (state.windowSidepanels[windowKey] === inspector) {
              delete state.windowSidepanels[windowKey];
            }
          }
          break;
        }

        default: {
          throw new UnreachableError(displayMode);
        }
      }
    },

    toggleWindowInspector: (state, action: PayloadAction<WindowInspectorPayload>) => {
      const { windowKey, inspector } = action.payload;

      const displayMode = state.displayMode[inspector] ?? defaultDisplayMode;
      switch (displayMode) {
        case "sidepanel": {
          if (state.windowSidepanels[windowKey] === inspector) {
            delete state.windowSidepanels[windowKey];
          } else {
            state.windowSidepanels[windowKey] = inspector;
          }
          break;
        }

        case "nativeWindow": {
          // The toggle button for native windows should always open or focus the window,
          // never close it. When the native inspector window is open, the toggle button is
          // not in the inspector window itself, but elsewhere such as main, meeting, or
          // shareControls, and thus being focused on that window should cause the inspector
          // to become focused if it was already open. The inspector window can be closed via
          // the native close button on the window itself (or associated keyboard shortcut).
          if (!state.openNativeWindows.includes(inspector)) {
            state.openNativeWindows.push(inspector);
          }
          break;
        }

        default: {
          throw new UnreachableError(displayMode);
        }
      }
    },

    closeWindowInspector: (state, action: PayloadAction<WindowInspectorPayload>) => {
      const { windowKey, inspector } = action.payload;

      const displayMode = state.displayMode[inspector] ?? defaultDisplayMode;
      switch (displayMode) {
        case "sidepanel": {
          if (state.windowSidepanels[windowKey] === inspector) {
            delete state.windowSidepanels[windowKey];
          }
          break;
        }

        case "nativeWindow": {
          state.openNativeWindows = state.openNativeWindows.filter((i) => i !== inspector);
          break;
        }

        default: {
          throw new UnreachableError(displayMode);
        }
      }
    },

    internalNativeWindowClosed: (state, action: PayloadAction<WindowInspector>) => {
      const inspector = action.payload;
      state.openNativeWindows = state.openNativeWindows.filter((i) => i !== inspector);
    },
  },
});
export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.inspector;

export const selectors = {
  selectIsAnySidepanelActive: (windowKey?: WindowKey) => (state: RootState) =>
    windowKey !== undefined ? !!selectSlice(state).windowSidepanels[windowKey] : false,
  selectIsSidepanelActive:
    (inspector: WindowInspector, windowKey?: WindowKey) => (state: RootState) =>
      windowKey !== undefined
        ? selectSlice(state).windowSidepanels[windowKey] === inspector
        : false,

  selectIsNativeWindowOpen: (inspector: WindowInspector) => (state: RootState) =>
    selectSlice(state).openNativeWindows.includes(inspector),

  selectIsInspectorActive:
    (inspector: WindowInspector, windowKey?: WindowKey) => (state: RootState) => {
      const slice = selectSlice(state);
      const displayMode = slice.displayMode[inspector] ?? defaultDisplayMode;
      switch (displayMode) {
        case "sidepanel": {
          return windowKey !== undefined ? slice.windowSidepanels[windowKey] === inspector : false;
        }

        case "nativeWindow": {
          return slice.openNativeWindows.includes(inspector);
        }

        default: {
          throw new UnreachableError(displayMode);
        }
      }
    },

  selectAllDisplayModes: (state: RootState) => selectSlice(state).displayMode,
};

export const InspectorSelectors = selectors;
export const InspectorActions = actions;
