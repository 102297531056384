/* cspell:ignore facetime,camo */
import { baseRoamURL } from "../../shared/api/http.js";

export interface MediaDeviceInfo {
  deviceId: string;
  groupId: string;
  kind: "videoinput" | "audioinput" | "audiooutput";
  label: string;

  // deprioritized means that we want to let the user select the device,
  // but we don't necessarily want to auto-switch to the device.
  // e.g. Continuity camera
  deprioritized?: boolean;
}

export const DefaultDeviceLabelPrefix = "Default - ";

interface WithLabel {
  label: string;
}

export const formatDeviceLabel = (
  label: string,
  deviceId: string,
  allLabels: string[] | WithLabel[] = [],
  defaultPrefix = true
) => {
  // Remove trailing parentheticals in the format of (05ae:8521), e.g.:
  // "FaceTime HD Camera (Built-in) (05ae:8521)" => "FaceTime HD Camera (Built-in)"
  const parensRegex = /(\s*\([\da-f]+:[\da-f]+\)\s*)?$/;

  const all: string[] = allLabels.map((l) => (typeof l === "string" ? l : l.label));
  const allWithoutParens = all.map((l) => l.replace(parensRegex, "").trim());

  // Only remove trailing parentheticals if doing so won't reduce the uniqueness of all labels
  if (new Set(all).size === new Set(allWithoutParens).size) {
    label = label.replace(parensRegex, "").trim() || "Unknown";
  }

  // Prepend "Default - " for default device when needed.
  if (defaultPrefix && deviceId === "default" && !label.toLowerCase().includes("default")) {
    label = `${DefaultDeviceLabelPrefix}${label}`;
  } else if (
    !defaultPrefix &&
    deviceId === "default" &&
    label.startsWith(DefaultDeviceLabelPrefix)
  ) {
    label = label.substring(DefaultDeviceLabelPrefix.length);
  }
  return label;
};

export const combineDeviceLabels = (label1: string, label2: string): string => {
  if (label1 === label2) return label1;

  const longestCommonPrefix = (strings: string[]): string => {
    const firstString = strings[0];
    if (strings.length <= 1 || !firstString) {
      return "";
    }

    let i = 0;
    while (i < firstString.length) {
      const char = firstString[i] || "";
      const match = strings.every((s) => s[i] === char);
      if (match) {
        i += 1;
      } else {
        break;
      }
    }
    return firstString.slice(0, i);
  };

  const prefix = longestCommonPrefix([label1, label2]);
  if (prefix && prefix.endsWith(" ")) {
    return `${label1} & ${label2.substring(prefix.length)}`;
  } else {
    return `${label1} & ${label2}`;
  }
};

export const pickVideoInput = (mediaDevices: MediaDeviceInfo[]): string | undefined => {
  const videoDevices = mediaDevices.filter((d) => d.kind === "videoinput" && !d.deprioritized);
  // Very simple scoring algorithm
  const score = (device: MediaDeviceInfo) => {
    if (/\b(usb|uvc)\b|logitech/i.test(device.label)) {
      // Prefer USB devices
      return 10;
    } else if (/integrated|built-?in|facetime/i.test(device.label)) {
      // Then prefer built-in cameras
      return 5;
    } else if (/\bCamo\b/.test(device.label) || /iphone/i.test(device.label)) {
      // Avoid Camo and Continuity Cam
      return -10;
    } else {
      return 0;
    }
  };
  // Sort by score, descending (highest score first)
  videoDevices.sort((a, b) => score(b) - score(a));
  return videoDevices[0]?.deviceId;
};

export const blankSrc = `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>`;

export const BackgroundBlurCounts = [1, 2, 3, 4, 5, 7, 9, 11, 13, 15, 17, 20];

export const MinBackgroundBlurLevel = 0;
export const MaxBackgroundBlurLevel = BackgroundBlurCounts.length - 1;
export const DefaultBackgroundBlurLevel = 9;

export const backgroundImageURLs: { default: string; [name: string]: string } = {
  default: `${baseRoamURL}/photos/virtbg/Roam_Shelf_Opt2_cropped_BG.png`,
};

export const DefaultCompressionRatio = 4;
export const DefaultCompressionThreshold = -24;
export const DefaultPostGain = 1.3;
