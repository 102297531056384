import { Draft } from "immer";
import { Card } from "../../../../shared/Models/Card.js";
import { CardUpdate } from "../../../../shared/Models/CardUpdate.js";
import { TypeNarrowError } from "../../../../shared/TypeNarrowError.js";
import { cardWithUpdate } from "../../../../shared/helpers/cards.js";
import { cardWithMove, cardWithResize } from "../../../helpers/boards.js";
import { CardSliceState } from "./cardSlice.js";
import { LocalUpdate } from "./cardSlicePayloads.js";

export const nextZForBoard = (state: CardSliceState, boardId: string) => {
  const cards = state.cardsByBoard?.[boardId];
  if (!cards) {
    return 1;
  }
  const highestZ = cards.map((p) => p.z).reduce((x, y) => (x > y ? x : y), 1);
  if (typeof highestZ === "number") {
    return highestZ + 1;
  } else {
    return 1;
  }
};

export const cardToFront = (state: Draft<CardSliceState>, boardId: string, cardId: string) => {
  const highestZ = nextZForBoard(state, boardId);
  const card = state.cardsByBoard?.[boardId]?.find((p) => p.id === cardId);
  if (card && card.z < highestZ) {
    card.z = highestZ + 1;
  }
};

export const upsertCard = (state: Draft<CardSliceState>, card: Card) => {
  let cards = state.cardsByBoard?.[card.boardId];
  if (!cards) {
    cards = [];
    state.cardsByBoard[card.boardId] = cards;
  }
  const i = cards.findIndex((c) => c.id === card.id);
  if (i >= 0) {
    cards[i] = card;
  } else {
    cards.push(card);
  }
};

export const updateLockedCard = (state: Draft<CardSliceState>, card: Card) => {
  const cards = state.cardsByBoard?.[card.boardId];
  if (!cards) return;
  const i = cards.findIndex((c) => c.id === card.id);
  if (i >= 0) {
    const cardsI = cards[i];
    if (!cardsI) throw new TypeNarrowError();
    cardsI.lockOwner = card.lockOwner;
    cardsI.z = card.z;
  }
};

export const removeCard = (state: Draft<CardSliceState>, boardId: string, cardId: string) => {
  const cards = state.cardsByBoard[boardId];
  if (cards) {
    const i = cards.findIndex((c) => c.id === cardId);
    if (i >= 0) cards.splice(i, 1);
  }
};

/**
 * Converts a LocalUpdate, which is an intermediate client state that includes local information
 * like where the mouse operation started, to a CardUpdate, which is the effective update sent to
 * the server.
 */
export const localUpdateToCardUpdate = (update: LocalUpdate): CardUpdate | undefined => {
  const { updateType, board, card } = update;
  if (updateType === "move" || updateType === "resize") {
    const { startX, currentX, startY, currentY } = update;
    const deltaX = currentX - startX;
    const deltaY = currentY - startY;
    let updatedCard;
    if (updateType === "move") {
      updatedCard = cardWithMove({ card, board, deltaX, deltaY });
    } else {
      updatedCard = cardWithResize({ card, board, deltaX, deltaY });
    }
    if (updatedCard.width && updatedCard.height) {
      return {
        updateKind: "position",
        x: updatedCard.x,
        y: updatedCard.y,
        width: updatedCard.width,
        height: updatedCard.height,
      };
    }
  } else if (updateType === "text" && card.cardType === "Text" && update.content) {
    return {
      updateKind: "text",
      textContent: update.content,
    };
  }
  return undefined;
};

export const cardWithLocalUpdate = (card: Card, update: LocalUpdate): Card => {
  const cardUpdate = localUpdateToCardUpdate(update);
  return cardUpdate ? cardWithUpdate(card, cardUpdate) : card;
};
