import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { AccountComplianceSettings } from "../../../../shared/Models/accounts/AccountComplianceSettings.js";
import { ExternalGroupMemberships } from "../../../../shared/Models/chat/ExternalGroupMemberships.js";
import { createSelector } from "../../../helpers/redux.js";

import { AccountMessageRetentionSettings } from "../../../../shared/Models/accounts/AccountMessageRetentionSettings.js";
import { RootState } from "../../../store/reducers.js";

const complianceSettingsAdapter = createEntityAdapter<AccountComplianceSettings>({
  selectId: (settings: AccountComplianceSettings) => settings.accountId,
});
const messageRetentionSettingsAdapter = createEntityAdapter<AccountMessageRetentionSettings>({
  selectId: (settings: AccountMessageRetentionSettings) => settings.accountId,
});

interface SetExternalGroupMembershipsPayload {
  accountId: number;
  memberships: ExternalGroupMemberships;
}

const slice = createSlice({
  name: "complianceSettings",
  initialState: {
    complianceSettings: complianceSettingsAdapter.getInitialState(),
    messageRetentionSettings: messageRetentionSettingsAdapter.getInitialState(),
    externalGroupMemberships: {} as {
      [accountId: number]: ExternalGroupMemberships;
    },
  },
  reducers: {
    // Account compliance settings, CRUD
    /** Fetches account compliance settings for the specified [accountId]. */
    fetchComplianceSettings: (_, action: PayloadAction<number>) => {},
    /** Updates the account compliance settings for [accountId], creating it if it doesn't exist. */
    updateComplianceSettings: (_, action: PayloadAction<AccountComplianceSettings>) => {},

    setComplianceSettings: (state, action: PayloadAction<AccountComplianceSettings>) => {
      complianceSettingsAdapter.setOne(state.complianceSettings, action.payload);
    },

    /** Fetches message retention settings for the specified [accountId]. */
    fetchMessageRetentionSettings: (_, action: PayloadAction<number>) => {},
    updateMessageRetentionSettings: (
      _,
      action: PayloadAction<AccountMessageRetentionSettings>
    ) => {},
    setMessageRetentionSettings: (
      state,
      action: PayloadAction<AccountMessageRetentionSettings>
    ) => {
      messageRetentionSettingsAdapter.setOne(state.messageRetentionSettings, action.payload);
    },

    fetchExternalGroupMemberships: (_, action: PayloadAction<number>) => {},
    setExternalGroupMemberships: (
      state,
      action: PayloadAction<SetExternalGroupMembershipsPayload>
    ) => {
      const { accountId, memberships } = action.payload;
      state.externalGroupMemberships[accountId] = memberships;
    },
  },
});

export const { actions, reducer } = slice;
export const AccountComplianceSettingsActions = actions;

const selectSlice = (state: RootState) => state.anyWorld.compliance;
const complianceSettingsSelectors = complianceSettingsAdapter.getSelectors();
const messageRetentionSelectors = messageRetentionSettingsAdapter.getSelectors();

export const selectors = {
  selectComplianceSettings: defaultMemoize((accountId: number) =>
    createSelector(selectSlice, (slice) => {
      return complianceSettingsSelectors.selectById(slice.complianceSettings, accountId);
    })
  ),
  selectMessageRetentionSettings: defaultMemoize((accountId: number) =>
    createSelector(selectSlice, (slice) => {
      return messageRetentionSelectors.selectById(slice.messageRetentionSettings, accountId);
    })
  ),
  selectExternalGroupMemberships: (accountId: number) => (state: RootState) => {
    return selectSlice(state).externalGroupMemberships[accountId];
  },
};

export const AccountComplianceSettingsSelectors = selectors;
