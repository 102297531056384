import { UserAgent } from "express-useragent";
import { ClientIdentifiers } from "../../../shared/Models/ClientIdentifiers.js";
import { logger } from "../../../shared/infra/logger.js";
import { RootState } from "../reducers.js";
import { selectors as AppSelectors } from "./appSlice.js";

/**
 * Determines `ClientIdentifiers` from [state].
 *
 * If state is invalid and a `ClientIdentifiers` can't be determined, returns undefined.
 */
export const getClientIdentifiers = (state: RootState): ClientIdentifiers | undefined => {
  const guid = AppSelectors.selectClientGuid(state);
  const platform = AppSelectors.selectClientPlatform(state);
  const version = AppSelectors.selectClientVersion(state);

  let browser;
  let os;
  let osVersion;
  let cpuBrand;
  if (platform === "web") {
    // eslint-disable-next-line no-restricted-globals
    const ua = new UserAgent().parse(navigator.userAgent);
    browser = ua.browser;
    os = ua.os;
  } else {
    os = AppSelectors.selectElectronOS(state);
    osVersion = AppSelectors.selectElectronOSVersion(state);
    cpuBrand = AppSelectors.selectCPUBrand(state);
  }

  // Note that browser is allowed to be undefined on Electron
  if (!guid || !platform || !os || !version) {
    logger.error(
      `getClientIdentifiers: missing expected data. guid ${guid}, platform ${platform}, os ${os}, browser ${browser}, version ${version}`
    );
    return undefined;
  }

  // TODO: implement processGuid
  return {
    guid,
    platform,
    os,
    osVersion,
    browser,
    version,
    cpuBrand,
  } as ClientIdentifiers;
};
