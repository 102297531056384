import _ from "lodash";
import { z } from "zod";
import { UnreachableError } from "../../shared/helpers/UnreachableError.js";
import type { Appearance } from "./appearance.js";
import colors from "./colors.js";

/**
 * All the built-in accent colors. "black" is the default in the new theme,
 * "purple" is the default in the old theme.
 */
export const Accent = z.enum([
  "black",
  "red",
  "orange",
  "yellow",
  "green",
  "cyan",
  "blue",
  "purple",
  "pink",
  // TODO(jack): "black-white", once I ask Joe what that means
]);
export type Accent = z.infer<typeof Accent>;

/**
 * Given a color, see which AccentColor it corresponds to. Returns `undefined` if doesn't match any color.
 * This function accounts for all legacy accent color values as well.
 */
export const colorToAccent = (color: string): Accent | undefined => {
  // These cases should correspond to the `accentedPrimary` color of an accent
  // Dates correspond to when the given accent color was deprecated
  switch (color.toUpperCase()) {
    case colors.graphite900:
      return "black";
    case colors.red600:
    case colors.red500: // 2023-11-28, accent
    case colors.red700: // 2023-11-28, background
      return "red";
    // case colors.amber500: // 2023-11-28, accent, now yellow
    case colors.amber900:
    case colors.amber700: // 2023-11-28, background
      return "orange";
    case colors.amber500:
    case colors.citrus500: // 2023-11-28, accent
    case colors.citrus700: // 2023-11-28, background
      return "yellow";
    case colors.green300:
    case colors.green500: // 2023-11-28, accent
    case colors.green700: // 2023-11-28, background
    case "#388E3C": // 2023-05-03, background
      return "green";
    case colors.cyan300:
    case colors.cyan500: // 2023-11-28, accent
    case colors.cyan700: // 2023-11-28, background
      return "cyan";
    case colors.blue700:
    case colors.blue500: // 2023-11-28, accent
    case "#1976D2": // 2023-05-03, background
      return "blue";
    case colors.indigo600:
    case colors.indigo500: // 2023-11-28, accent
    case colors.indigo700: // 2023-11-28, background
      return "purple";
    case colors.pink700:
    case colors.pink500: // 2023-11-28, accent
      return "pink";
    default:
      return undefined;
  }
};

export const accentToColor = (accent: Accent): string => {
  switch (accent) {
    case "black":
      return colors.graphite900;
    default:
      return accentData(accent, "dark").accentedPrimary;
  }
};

/**
 * For a built-in accent color, all the colors that can be customized.
 */
type AccentData = {
  accentedPrimary: string;
  accentedSecondary: string;
  textIconOnAccentedPrimary: string;
  textIconOnAccentedSecondary: string;
  backgroundElevatedPrimary: string;
  backgroundElevatedSecondary: string;
  surfacePrimary: string;
  surfaceSecondary: string;
};

/**
 * The base AccentData when adding a new accent. A new copy needs to be made
 * each time because lodash functions modify their input object.
 */
const baseAccentData = (appearance: Appearance): AccentData => {
  switch (appearance) {
    case "dark":
      return {
        accentedPrimary: colors.indigo500,
        accentedSecondary: colors.blue500,
        textIconOnAccentedPrimary: colors.white,
        textIconOnAccentedSecondary: colors.black,
        backgroundElevatedPrimary: colors.grey700,
        backgroundElevatedSecondary: colors.grey600,
        surfacePrimary: colors.black,
        surfaceSecondary: colors.white,
      };
    case "light":
      return {
        accentedPrimary: colors.indigo600,
        accentedSecondary: colors.blue600,
        textIconOnAccentedPrimary: colors.white,
        textIconOnAccentedSecondary: colors.black,
        backgroundElevatedPrimary: colors.white,
        backgroundElevatedSecondary: colors.white,
        surfacePrimary: colors.white,
        surfaceSecondary: colors.black,
      };
    default:
      throw new UnreachableError(appearance);
  }
};

type AccentMap = { [accent in Accent]: Partial<AccentData> };

const partialAccentDataDark: AccentMap = {
  black: {
    backgroundElevatedPrimary: colors.graphite700,
    backgroundElevatedSecondary: colors.graphite600,
    surfacePrimary: colors.graphite900,
  },
  purple: {},
  red: {
    accentedPrimary: colors.red600,
    accentedSecondary: colors.green300,
  },
  orange: {
    accentedPrimary: colors.amber900,
    accentedSecondary: colors.cyan500,
    textIconOnAccentedPrimary: colors.black,
  },
  yellow: {
    accentedPrimary: colors.amber500,
    accentedSecondary: colors.blue800,
    textIconOnAccentedPrimary: colors.black,
    textIconOnAccentedSecondary: colors.white,
  },
  green: {
    accentedPrimary: colors.green300,
    accentedSecondary: colors.red600,
    textIconOnAccentedPrimary: colors.black,
  },
  cyan: {
    accentedPrimary: colors.cyan300,
    accentedSecondary: colors.amber400,
    textIconOnAccentedPrimary: colors.black,
  },
  blue: {
    accentedPrimary: colors.blue700,
    accentedSecondary: colors.amber500,
  },
  pink: {
    accentedPrimary: colors.pink700,
    accentedSecondary: colors.green400,
  },
};

const partialAccentDataLight: AccentMap = {
  black: {
    surfaceSecondary: colors.graphite900,
  },
  purple: {},
  red: {
    accentedPrimary: colors.red600,
    accentedSecondary: colors.green500,
  },
  orange: {
    accentedPrimary: colors.amber900,
    accentedSecondary: colors.cyan500,
    textIconOnAccentedSecondary: colors.white,
  },
  yellow: {
    accentedPrimary: colors.amber200,
    accentedSecondary: colors.blue300,
    textIconOnAccentedPrimary: colors.black,
  },
  green: {
    accentedPrimary: colors.green200,
    accentedSecondary: colors.red400,
    textIconOnAccentedPrimary: colors.black,
    textIconOnAccentedSecondary: colors.white,
  },
  cyan: {
    accentedPrimary: colors.cyan500,
    accentedSecondary: colors.amber500,
  },
  blue: {
    accentedPrimary: colors.blue500,
    accentedSecondary: colors.amber500,
  },
  pink: {
    accentedPrimary: colors.pink400,
    accentedSecondary: colors.green200,
  },
};

const partialAccentData: { [m in Appearance]: AccentMap } = {
  light: partialAccentDataLight,
  dark: partialAccentDataDark,
};

/**
 * Given an accent color, return the complete accent data for it
 */
export const accentData = (accent: Accent, appearance: Appearance): AccentData => {
  return _.merge(baseAccentData(appearance), partialAccentData[appearance][accent]);
};
