import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatMessage } from "../../../../shared/Models/ChatMessage.js";
import { MeetingChatSender } from "../../../../shared/SectionMessages/ServerMeetingChat.js";
import { RootState } from "../../../store/reducers.js";

export interface EmojiReaction {
  timestamp: number;
  emoji: string;
  text: string;
  occupantId: string;
  roomId: number;
  sectionNumber?: number;
}

export interface ClearOccupantEmojiPayload {
  occupantId: string;
  timestamp: number;
}

export interface ClearSectionEmojiPayload {
  sectionNumber: number;
  timestamp: number;
}

export interface EmojiMessagesReceivedPayload {
  messages: Array<{
    message: ChatMessage;
    sender: MeetingChatSender;
    senderSectionNumber?: number;
  }>;
}

const emojiReactionAdapter = createEntityAdapter<EmojiReaction>({
  selectId: (reaction) => reaction.timestamp,
});

const slice = createSlice({
  name: "reaction",
  initialState: {
    emojiReactions: emojiReactionAdapter.getInitialState(),
    reactionByOccupantId: {} as {
      [occupantId: string]: number;
    },
    reactionsBySectionNumber: {} as {
      [sectionNumber: number]: number[];
    },
    nextIndexBySectionNumber: {} as {
      [sectionNumber: number]: number;
    },
  },
  reducers: {
    occupantEmojiReaction: (state, action: PayloadAction<EmojiReaction>) => {
      const reaction = action.payload;
      emojiReactionAdapter.upsertOne(state.emojiReactions, reaction);
      state.reactionByOccupantId[reaction.occupantId] = reaction.timestamp;
    },
    sectionEmojiReaction: (state, action: PayloadAction<EmojiReaction>) => {
      const reaction = action.payload;
      const { sectionNumber, timestamp } = reaction;
      if (sectionNumber === undefined) return;

      emojiReactionAdapter.upsertOne(state.emojiReactions, reaction);

      const nextIndex = state.nextIndexBySectionNumber[sectionNumber] ?? 0;
      const current = state.reactionsBySectionNumber[sectionNumber];
      if (current) {
        const currentNextIndex = current[nextIndex];
        if (nextIndex < current.length && currentNextIndex) {
          emojiReactionAdapter.removeOne(state.emojiReactions, currentNextIndex);
        }
        current[nextIndex] = timestamp;
        state.nextIndexBySectionNumber[sectionNumber] = (nextIndex + 1) % 4;
      } else {
        state.reactionsBySectionNumber[sectionNumber] = [timestamp];
        state.nextIndexBySectionNumber[sectionNumber] = 1;
      }
    },
    clearOccupantEmoji: (state, action: PayloadAction<ClearOccupantEmojiPayload>) => {
      const { occupantId, timestamp } = action.payload;
      emojiReactionAdapter.removeOne(state.emojiReactions, timestamp);
      if (state.reactionByOccupantId[occupantId] === timestamp) {
        delete state.reactionByOccupantId[occupantId];
      }
    },
    clearSectionEmoji: (state, action: PayloadAction<ClearSectionEmojiPayload>) => {
      const { sectionNumber, timestamp } = action.payload;
      emojiReactionAdapter.removeOne(state.emojiReactions, timestamp);
      const current = state.reactionsBySectionNumber[sectionNumber];
      if (current) {
        const i = current.indexOf(timestamp);
        if (i >= 0) {
          current[i] = 0;
        }
      }
    },
    emojiMessagesReceived: (state, action: PayloadAction<EmojiMessagesReceivedPayload>) => {},
  },
});

export const { actions, reducer } = slice;
export const selectSlice = (state: RootState) => state.section.reaction;
const adapterSelectors = emojiReactionAdapter.getSelectors();
export const selectors = {
  selectReactionByTimestamp: (timestamp?: number) => (state: RootState) =>
    timestamp
      ? adapterSelectors.selectById(selectSlice(state).emojiReactions, timestamp)
      : undefined,
  selectTimestampByOccupantId: (occupantId?: string) => (state: RootState) =>
    occupantId ? selectSlice(state).reactionByOccupantId[occupantId] : undefined,
  selectTimestampsBySectionNumber: (sectionNumber: number) => (state: RootState) =>
    selectSlice(state).reactionsBySectionNumber[sectionNumber],
};
