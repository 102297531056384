import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reducers.js";

export interface BrowserFeatures {
  nativeHls: boolean;
  captureStream: boolean;
}

const slice = createSlice({
  name: "browserFeatures",
  initialState: {} as BrowserFeatures,
  reducers: {
    requestFeatures: (_, action: PayloadAction<void>) => {},
    setFeatures: (state, action: PayloadAction<BrowserFeatures>) => action.payload,
  },
});
export const { actions, reducer } = slice;

export const selectors = {
  supportsNativeHLS: (state: RootState) => state.browserFeatures.nativeHls,
  supportsCaptureStream: (state: RootState) => state.browserFeatures.captureStream,
};
