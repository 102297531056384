/**
 * Throws if [value] is null or undefined.
 */
export const requireValue = <T>(value: T | undefined | null, errorMessage?: string): T => {
  if (value === undefined || value === null) {
    throw new Error(`requireValue: Expected value; ${errorMessage}`);
  }
  return value;
};

/**
 * Throws if [value] is not true.
 */
export const requireTrue = (value: boolean, errorMessage?: string): void => {
  if (!value) {
    throw new Error(errorMessage ?? "requireTrue: Value not true.");
  }
};
