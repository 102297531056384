import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Book,
  Flag,
  Movie,
  Nft,
  SpotifyAlbum,
  SpotifyArtist,
  SpotifyTrack,
} from "../../../../shared/Models/ItemCreator.js";
import { RootState } from "../../../store/reducers.js";

export interface MusicResultsPayload {
  albums: SpotifyAlbum[];
  artists: SpotifyArtist[];
  tracks: SpotifyTrack[];
}

export interface NftResults {
  nfts: Nft[];
  cryptoAddressCount: number;
  unsupportedNftCount: number;
}

const slice = createSlice({
  name: "itemCreator",
  initialState: {
    searchError: undefined as string | undefined,

    movieResults: undefined as Movie[] | undefined,

    bookResults: undefined as Book[] | undefined,

    albumResults: undefined as SpotifyAlbum[] | undefined,
    artistResults: undefined as SpotifyArtist[] | undefined,
    trackResults: undefined as SpotifyTrack[] | undefined,

    nftResults: undefined as NftResults | undefined,

    flags: undefined as Flag[] | undefined,
  },
  reducers: {
    setSearchError: (state, action: PayloadAction<string | undefined>) => {
      state.searchError = action.payload;
    },
    clearSearchError: (state) => {
      state.searchError = undefined;
    },

    createDraftItem: (state, action: PayloadAction<string>) => {},

    searchMovies: (state, action: PayloadAction<string>) => {
      state.movieResults = undefined;
      state.searchError = undefined;
    },
    setMovieResults: (state, action: PayloadAction<Movie[]>) => {
      state.movieResults = action.payload;
      state.searchError = undefined;
    },
    resetMovies: (state) => {
      state.movieResults = undefined;
      state.searchError = undefined;
    },

    searchBooks: (state, action: PayloadAction<string>) => {
      state.bookResults = undefined;
      state.searchError = undefined;
    },
    setBookResults: (state, action: PayloadAction<Book[]>) => {
      state.bookResults = action.payload;
      state.searchError = undefined;
    },
    resetBooks: (state) => {
      state.bookResults = undefined;
      state.searchError = undefined;
    },

    searchMusic: (state, action: PayloadAction<string>) => {
      state.albumResults = undefined;
      state.artistResults = undefined;
      state.trackResults = undefined;
      state.searchError = undefined;
    },
    setMusicResults: (state, action: PayloadAction<MusicResultsPayload>) => {
      const { albums, artists, tracks } = action.payload;
      state.albumResults = albums;
      state.artistResults = artists;
      state.trackResults = tracks;
      state.searchError = undefined;
    },
    resetMusic: (state) => {
      state.albumResults = undefined;
      state.artistResults = undefined;
      state.trackResults = undefined;
      state.searchError = undefined;
    },

    getWebPageMetadata: (state, action: PayloadAction<string>) => {
      state.searchError = undefined;
    },

    getFlags: (state) => {},
    setFlags: (state, action: PayloadAction<Flag[]>) => {
      state.flags = action.payload;
    },

    getOwnedNfts: () => {},
    setNftResults: (state, action: PayloadAction<NftResults | undefined>) => {
      state.nftResults = action.payload;
    },
  },
});

export const { actions, reducer } = slice;
export const selectors = {
  selectSearchError: (state: RootState) => state.things.itemCreator.searchError,

  selectMovieResults: (state: RootState) => state.things.itemCreator.movieResults,

  selectBookResults: (state: RootState) => state.things.itemCreator.bookResults,

  selectAlbumResults: (state: RootState) => state.things.itemCreator.albumResults,
  selectTrackResults: (state: RootState) => state.things.itemCreator.trackResults,
  selectArtistResults: (state: RootState) => state.things.itemCreator.artistResults,

  selectFlags: (state: RootState) => state.things.itemCreator.flags,

  selectNftResults: (state: RootState) => state.things.itemCreator.nftResults,
};
