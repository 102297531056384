import { z } from "zod";
import { logger } from "../infra/logger.js";
import { MeetingLink } from "./AccessLinks/MeetingLink.js";
import { ClientEnterRoamRequest } from "./lobby/EnterRoamRequest.js";
import { numberId, timestamp, uuid } from "./zodTypes.js";

export const RoomType = z.enum([
  "Auditorium",
  "BigMeetingRoom",
  "CommandCenter",
  "MeetingRoom",
  "Office",
  "TeamRoom",
]);
export type RoomType = z.infer<typeof RoomType>;

export const AccessMode = z.enum(["Open", "KnockRequired", "DoNotDisturb"]);
export type AccessMode = z.infer<typeof AccessMode>;

export const AccessModeDetails = z.object({
  temporary: z.boolean(),
  dndReason: z.enum(["Zoom"]).optional(),
});
export type AccessModeDetails = z.infer<typeof AccessModeDetails>;

export const StageMode = z.enum(["Open", "AskToTalk", "Closed", "Backstage"]); // TODO: Remove Backstage once clients have picked up stage navigation changes
export type StageMode = z.infer<typeof StageMode>;

export const QAMode = z.boolean();
export type QAMode = z.infer<typeof QAMode>;

export const WillReturn = z.object({
  returnTime: z.number().positive(),
  absenceReason: z.string().optional(),
});
export type WillReturn = z.infer<typeof WillReturn>;

export const SeatAssignment = z.object({
  seatNumber: z.number().nonnegative(),
  personId: numberId(),
  name: z.string().nonempty().optional(),
  willReturn: WillReturn.optional(),
});
export type SeatAssignment = z.infer<typeof SeatAssignment>;

/** part of some rooms. may also contain a backstage **/
export const Stage = z.object({
  roomId: numberId(),
  stageMode: StageMode, // the modes by which Stage can be accessed by audience
  backstageToggled: z.boolean().optional(),
  QAMode, // true if audience may ask verbal or written questions
});
export type Stage = z.infer<typeof Stage>;

/** a RoomLease represents a claim placed on a meeting room, such as by the meeting link service */
export const RoomLease = z.object({
  roomId: numberId(),
  leaseId: uuid(),
  meetingLinkId: uuid(),
  leaseName: z.string().optional(),
  createdDate: timestamp().optional(),
});
export type RoomLease = z.infer<typeof RoomLease>;

// NOTE(vple): It seems like this model is starting to have a lot going on. At some point, it seems
// like we might want to break it up a bit. At time of this comment, it seems like a few breaking
// points would be:
//  - "primary/core": room data (id, name, type, etc.)
//  - "map rendering": width, height, x, y, assigned seats, etc. (not exactly sure about assigned
//    seats...)
//  - aux/misc/in room?: saved boards
export const Room = z.object({
  id: numberId(),
  name: z.string().optional(),
  floorId: numberId(),
  roomType: RoomType,
  width: z.number().positive(),
  height: z.number().positive(),
  xLocation: z.number().positive(),
  yLocation: z.number().positive(),
  accessMode: AccessMode,
  accessModeDetails: AccessModeDetails.optional(),
  disableCanarySFU: z.boolean(),
  ephemeralRoom: z.boolean().optional(),
  ephemeralPosition: z.number().optional(),
  inactive: z.boolean().optional(),
  // Derived data
  assignedSeats: z.array(SeatAssignment).optional(),
  savedBoards: z.array(z.string()).optional(),
  stage: Stage.optional(),
  leaseName: z.string().optional(),
  enterRoamRequests: z.array(ClientEnterRoamRequest).optional(),
  /**
   * The meeting (link) for which this room has been leased.
   *
   * The returned type is a `MeetingLink` since that is the closest representation we have to a
   * "meeting."
   *
   * Only provided if there is a lease on the room.
   * Having people in a room does not constitute a meeting for the purposes of this field,
   * only if there is a meeting link that is currently in use for the room.
   */
  meeting: MeetingLink.optional(),
  disableMeetingChat: z.boolean().optional(),
});
export type Room = z.infer<typeof Room>;

/**
 * Request object for creating a new room.
 */
export const NewRoom = Room.pick({
  // Please keep fields in sync with cleanNewRoom, below.

  name: true,
  roomType: true,
  // allow room mode?
  // allow width/height?
  // allow x/y location?
  disableCanarySFU: true,
  assignedSeats: true,
});
export type NewRoom = z.infer<typeof NewRoom>;

/**
 * Cleans a NewRoom, returning only properties defined on the type.
 */
export const cleanNewRoom = (newRoom: NewRoom): NewRoom => {
  return {
    name: newRoom.name,
    roomType: newRoom.roomType,
    disableCanarySFU: newRoom.disableCanarySFU,
    assignedSeats: newRoom.assignedSeats,
  };
};

export const getRoomSeats = (room: Room): RoomSeat[] => {
  if (!room.assignedSeats) {
    return [];
  }

  return room.assignedSeats.map((assignedSeat) => ({
    roomId: room.id,
    seatAssignment: assignedSeat,
  }));
};

export const RoomSeat = z.object({
  roomId: numberId(),
  seatAssignment: SeatAssignment,
});
export type RoomSeat = z.infer<typeof RoomSeat>;

export const maxStageParticipants = 10; // indices 0-9 on stage
export const maxBackstageParticipants = 10; // indices 10-19 on backstage
export const maxFloorMicParticipants = 100; // indices 20-119 with floorMic
export const maxInvisibleObservers = 5; // indices 120-124
export const firstBackstageIndex = maxStageParticipants;
export const lastBackstageIndex = firstBackstageIndex + maxBackstageParticipants - 1;
export const firstFloorMicIndex = lastBackstageIndex + 1;
export const lastFloorMicIndex = firstFloorMicIndex + maxFloorMicParticipants - 1;
export const firstInvisibleObserverIndex = lastFloorMicIndex + 1;
export const lastInvisibleObserverIndex = firstInvisibleObserverIndex + maxInvisibleObservers - 1;

/**
 * Find location subkind. If not in Auditorium then return RoomLocation
 * But if in Auditorium then the position is either on stage or
 * backstage, depending on their positionNumber
 *
 * The first maxStageParticipant slots are reserved for stage
 * The remaining maxBackstageParticipant slots are reserved for backstage
 * Anything else is an error
 *
 * @param roomType RoomType, "Auditorium", "Reception", etc
 * @param positionNumber the 0 indexed position in the room
 * @returns "RoomLocation" or "StageLocation" or "BackstageLocation"
 */
export const positionToRoomSubkind = (
  roomType: RoomType,
  positionNumber: number
):
  | "RoomLocation"
  | "StageLocation"
  | "BackstageLocation"
  | "FloorMicLocation"
  | "InvisibleObserverLocation" => {
  if (roomType !== "Auditorium") {
    return "RoomLocation";
  } else {
    if (positionNumber < maxStageParticipants) {
      return "StageLocation";
    } else if (positionNumber < maxStageParticipants + maxBackstageParticipants) {
      return "BackstageLocation";
    } else if (firstFloorMicIndex <= positionNumber && positionNumber <= lastFloorMicIndex) {
      return "FloorMicLocation";
    } else if (
      firstInvisibleObserverIndex <= positionNumber &&
      positionNumber <= lastInvisibleObserverIndex
    ) {
      return "InvisibleObserverLocation";
    } else {
      logger.error(
        `Invalid room subtype encountered roomType ${roomType} positionNumber ${positionNumber}`
      );
      return "RoomLocation";
    }
  }
};

export const isFocusRoomType = (roomType: RoomType | undefined): boolean => {
  return isAllowedRoomType(roomType, [
    "MeetingRoom",
    "BigMeetingRoom",
    "Auditorium",
    "CommandCenter",
  ]);
};

export const isVideoRoomType = (roomType: RoomType | undefined): boolean => {
  return isAllowedRoomType(roomType, ["MeetingRoom", "BigMeetingRoom", "Auditorium"]);
};

export const roomTypeHasMeetingChat = (roomType: RoomType | undefined): boolean => {
  return isAllowedRoomType(roomType, [
    "MeetingRoom",
    "BigMeetingRoom",
    "Auditorium",
    "CommandCenter",
  ]);
};

const isAllowedRoomType = (
  roomType: RoomType | undefined,
  allowedRoomTypes: RoomType[]
): boolean => {
  if (!roomType) {
    return false;
  }
  return allowedRoomTypes.includes(roomType);
};

// See roam/shared/FloorGridLayout.ts for more information about layout
export const numRoomSeatPositions = (room: Room): number => {
  return Math.max(0, room.width * room.height);
};

export const defaultRoomAccessModes: { [roomType in RoomType]: AccessMode } = {
  Office: "KnockRequired",
  TeamRoom: "Open",
  MeetingRoom: "Open",
  Auditorium: "Open",
  CommandCenter: "Open",
  BigMeetingRoom: "Open",
};
