import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import type { Stripe } from "stripe";
import { crpc_payment_UpdateStripeCustomerPaymentMethod } from "../../../../shared/BillingMessages/external/ClientRpcStripe.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export type SetupIntentRequest = {
  requestId: string;
  accountId: number;
};

export type SetupIntentResult = {
  requestId: string;
  /**
   * NOTE:
   * This will be stored in the redux state and we are currently assuming that this level of
   * exposure is fine.
   */
  setupIntent: Stripe.SetupIntent | undefined;
  status: "SUCCESS" | "FAILED" | "IN_PROGRESS";
};

export type DeletePaymentMethodRequest = {
  accountId: number;
  stripePaymentMethodId: string;
};

const setupIntentAdapter = createEntityAdapter<SetupIntentResult>({
  selectId: (result) => result.requestId,
});
const paymentMethodAdapter = createEntityAdapter<Stripe.PaymentMethod>();

const slice = createSlice({
  name: "stripe",
  initialState: {
    publishableKey: undefined as string | undefined,
    setupIntents: setupIntentAdapter.getInitialState(),
    paymentMethods: paymentMethodAdapter.getInitialState(),
    paymentMethodsLoaded: {} as Record<number, boolean>,
    /**
     * Roam account ID -> Array<stripe payment method id>
     */
    paymentMethodIdsByAccountId: {} as Record<number, string[]>,
  },
  reducers: {
    init: (state) => {},
    setPublishableKey: (state, action: PayloadAction<string>) => {
      state.publishableKey = action.payload;
    },

    // Setup Intents, sagas
    /**
     * Requests a new setup intent.
     *
     * Stripe says these are supposed to be short lived, so probably scope this to whatever
     * component actually needs it.
     */
    createSetupIntent: (state, action: PayloadAction<SetupIntentRequest>) => {},

    // Setup Intents, state
    setSetupIntentResult: (state, action: PayloadAction<SetupIntentResult>) => {
      setupIntentAdapter.setOne(state.setupIntents, action.payload);
    },

    // Payment Methods, sagas
    /** Refreshes payment methods for an account ID. */
    refreshPaymentMethods: (state, action: PayloadAction<number>) => {},
    deletePaymentMethod: (state, action: PayloadAction<DeletePaymentMethodRequest>) => {},
    updateCustomerPaymentMethod: (
      state,
      action: PayloadAction<Omit<crpc_payment_UpdateStripeCustomerPaymentMethod, "kind">>
    ) => {},

    // Payment Methods, state
    upsertPaymentMethods: (state, action: PayloadAction<Stripe.PaymentMethod[]>) => {
      paymentMethodAdapter.upsertMany(state.paymentMethods, action.payload);
    },
    setAccountPaymentMethods: (
      state,
      action: PayloadAction<{
        accountId: number;
        paymentMethodIds: string[];
      }>
    ) => {
      const { accountId, paymentMethodIds } = action.payload;
      state.paymentMethodIdsByAccountId[accountId] = paymentMethodIds;
      state.paymentMethodsLoaded[accountId] = true;
    },
  },
});

export const { actions, reducer } = slice;
export const StripeActions = actions;
export const stripeReducer = reducer;

const selectSlice = (state: RootState) => state.billing.stripe;
const setupIntentSelectors = setupIntentAdapter.getSelectors();
const paymentMethodSelectors = paymentMethodAdapter.getSelectors();
export const selectors = {
  selectPublishableKey: (state: RootState) => state.billing.stripe.publishableKey,
  selectSetupIntent: (requestId: string) => (state: RootState) => {
    return setupIntentSelectors.selectById(state.billing.stripe.setupIntents, requestId);
  },
  selectPaymentMethodsLoaded: (accountId: number) => (state: RootState) => {
    return state.billing.stripe.paymentMethodsLoaded[accountId] ?? false;
  },
  selectPaymentMethodsByAccountId: defaultMemoize((accountId: number) =>
    createDeepEqualSelector(selectSlice, (slice): Stripe.PaymentMethod[] => {
      const paymentMethodIds = slice.paymentMethodIdsByAccountId[accountId] ?? [];
      return paymentMethodSelectors
        .selectAll(slice.paymentMethods)
        .filter((pm) => paymentMethodIds.includes(pm.id));
    })
  ),
};
export const StripeSelectors = selectors;
