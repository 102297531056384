import { EntityState, PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { SlackMemberOverrideEntry } from "../../../../shared/ChatComplianceMessages/ClientRpcSlackImport.js";
import {
  GhostGroup,
  GhostGroupInfo,
  GhostGroupMemberAddress,
} from "../../../../shared/Models/GhostGroup.js";
import { GroupNewWithTargets } from "../../../../shared/Models/Group.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface GhostGroupRequestPayload {
  roamId: number;
  fetchAll?: boolean;
}

export interface GhostGroupInfoRequestPayload {
  roamId: number;
  channelId: string;
}

export interface GhostGroupImportPayload {
  channelId: string;
  group: GroupNewWithTargets;
}

export interface GhostGroupMemberAddedPayload {
  channelId: string;
  addedMembers: GhostGroupMemberAddress[];
}

export interface GhostGroupMemberRemovedPayload {
  channelId: string;
  removedEmails: string[];
}

interface GhostGroupImportResult {
  status: "complete" | "pending" | "failed";
  pctComplete: number;
}

interface GhostGroupImportStatus {
  status: "complete" | "pending" | "failed";
  pctComplete: number;
}

const ghostGroupAdapter = createEntityAdapter<GhostGroup>({
  selectId: (res: GhostGroup) => res.id,
});
const ghostGroupSelectors = ghostGroupAdapter.getSelectors();

const slackMemberOverrideAdapter = createEntityAdapter<SlackMemberOverrideEntry>({
  selectId: (res: SlackMemberOverrideEntry) => `${res.roamId}|${res.slackEmail}`,
});
const slackMemberOverrideSelectors = slackMemberOverrideAdapter.getSelectors();

const groupMembersAdapter = createEntityAdapter<GhostGroupMemberAddress>({
  selectId: (chatAddress) => chatAddress.email,
});
const groupMembersInitialState = groupMembersAdapter.getInitialState();
const groupMembersSelector = groupMembersAdapter.getSelectors();

const slice = createSlice({
  name: "slackGhostGroup",
  initialState: {
    ghostGroups: ghostGroupAdapter.getInitialState(),
    groupMembers: {} as { [channelId: string]: EntityState<GhostGroupMemberAddress> },
    slackMemberOverrides: slackMemberOverrideAdapter.getInitialState(),
  },
  reducers: {
    requestGhostGroups: (state, action: PayloadAction<GhostGroupRequestPayload>) => {},
    requestSlackMemberOverrides: (state, action: PayloadAction<number>) => {},

    upsertSlackMemberOverride: (state, action: PayloadAction<SlackMemberOverrideEntry>) => {},

    setSlackMemberOverrides: (state, action: PayloadAction<SlackMemberOverrideEntry[]>) => {
      slackMemberOverrideAdapter.setMany(state.slackMemberOverrides, action.payload);
    },
    setGhostGroups: (state, action: PayloadAction<GhostGroup[]>) => {
      ghostGroupAdapter.setMany(state.ghostGroups, action.payload);
    },
    requestGhostGroupInfo: (state, action: PayloadAction<GhostGroupInfoRequestPayload>) => {
      if (!state.groupMembers[action.payload.roamId]) {
        state.groupMembers[action.payload.roamId] = groupMembersInitialState;
      }
    },
    setGroupMembers: (state, action: PayloadAction<GhostGroupInfo>) => {
      const { id, members } = action.payload;
      let membership = state.groupMembers[id];
      if (!membership) {
        membership = groupMembersInitialState;
      }
      state.groupMembers[id] = groupMembersAdapter.setAll(membership, members);
    },
    groupMembersAdded: (state, action: PayloadAction<GhostGroupMemberAddedPayload>) => {
      const { channelId, addedMembers } = action.payload;
      let membership = state.groupMembers[channelId];
      if (!membership) {
        state.groupMembers[channelId] = membership = groupMembersInitialState;
      }
      groupMembersAdapter.upsertMany(membership, addedMembers);
    },
    groupMembersRemoved: (state, action: PayloadAction<GhostGroupMemberRemovedPayload>) => {
      const { channelId, removedEmails } = action.payload;

      let membership = state.groupMembers[channelId];
      if (!membership) {
        state.groupMembers[channelId] = membership = groupMembersInitialState;
      }
      groupMembersAdapter.removeMany(membership, removedEmails);
    },
    importGroup: (state, action: PayloadAction<GhostGroupImportPayload>) => {},
    addImportResult: (state, action: PayloadAction<GhostGroupImportResult>) => {},
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.slackGhostGroup;
export const selectors = {
  selectGhostGroupsByRoam: defaultMemoize((roamId: number) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      return (
        ghostGroupSelectors.selectAll(slice.ghostGroups).filter((g) => g.roamId === roamId) ?? []
      );
    })
  ),
  selectGroupMembers: (channelId: string) =>
    createSelector(selectSlice, (slice) => {
      const membership = slice.groupMembers[channelId];
      if (!membership) return [];
      return groupMembersSelector.selectAll(membership);
    }),
  selectSlackMemberOverrides: defaultMemoize((roamId: number) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      return (
        slackMemberOverrideSelectors
          .selectAll(slice.slackMemberOverrides)
          .filter((o) => o.roamId === roamId) ?? []
      );
    })
  ),
};
