import { mat4 } from "gl-matrix";
import { compileShader, createProgram, createTexture, glsl } from "../../webglHelper.js";

export type RenderForeheadStage = {
  render(landmarkVertices: Float32Array | undefined): void;
  cleanUp(): void;
};

// Adds to existing face mask in texture 0 with face skin mask in texture 1 and landmarks passed to
// render function
export const buildAddForeheadStage = (
  gl: WebGL2RenderingContext,
  vertexShader: WebGLShader,
  positionBuffer: WebGLBuffer,
  texCoordBuffer: WebGLBuffer,
  outputTexture: WebGLTexture
): RenderForeheadStage => {
  const areaTexture = createTexture(gl, gl.R8, gl.canvas.width, gl.canvas.height);
  const renderAreaStage = buildRenderAreaStage(gl, areaTexture);

  const outputBuffer = gl.createFramebuffer();
  if (!outputBuffer) {
    throw new Error("could not create outputBuffer");
  }
  gl.bindFramebuffer(gl.FRAMEBUFFER, outputBuffer);
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, outputTexture, 0);

  const combineMasksStage = buildCombineMasksStage(
    gl,
    vertexShader,
    positionBuffer,
    texCoordBuffer,
    outputBuffer
  );

  const render = (landmarkVertices: Float32Array | undefined) => {
    renderAreaStage.render(landmarkVertices);
    gl.activeTexture(gl.TEXTURE2);
    gl.bindTexture(gl.TEXTURE_2D, areaTexture);
    combineMasksStage.render();
  };

  const cleanUp = () => {
    renderAreaStage.cleanUp();
    combineMasksStage.cleanUp();
  };

  return {
    render,
    cleanUp,
  };
};

const buildRenderAreaStage = (gl: WebGL2RenderingContext, areaTexture: WebGLTexture) => {
  const vertexShaderSrc = glsl`#version 300 es
    precision highp float;

    in vec3 a_position;
    uniform mat4 u_transformMat;

    void main() {
      gl_Position = u_transformMat * vec4(a_position, 1);
    }
  `;

  const fragmentShaderSrc = glsl`#version 300 es
    precision highp float;

    out vec4 outColor;

    void main() {
      outColor = vec4(1, 0, 0, 1);
    }`;

  const rightForeheadEdgeVertex = 127;
  const rightForeheadTopVertex = 103;
  const leftForeheadEdgeVertex = 356;
  const leftForeheadTopVertex = 332;

  const vertexShader = compileShader(gl, gl.VERTEX_SHADER, vertexShaderSrc);
  const fragmentShader = compileShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSrc);
  const program = createProgram(gl, vertexShader, fragmentShader);
  gl.useProgram(program);

  const foreheadVao = gl.createVertexArray();

  const foreheadVerticesBuffer = gl.createBuffer();
  if (!foreheadVerticesBuffer) {
    throw new Error("unable to create foreheadVerticesBuffer");
  }

  gl.bindVertexArray(foreheadVao);

  const aPosition = gl.getAttribLocation(program, "a_position");

  gl.bindBuffer(gl.ARRAY_BUFFER, foreheadVerticesBuffer);
  gl.enableVertexAttribArray(aPosition);
  gl.vertexAttribPointer(aPosition, 3, gl.FLOAT, false, 0, 0);

  const foreheadIndexBuffer = gl.createBuffer();
  if (!foreheadIndexBuffer) {
    throw new Error("could not create landmarkIndexBuffer");
  }
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, foreheadIndexBuffer);
  const foreheadIndexes = new Uint16Array([0, 1, 2, 0, 2, 3, 0, 3, 5, 3, 4, 5]);
  gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, foreheadIndexes, gl.STATIC_DRAW);

  gl.bindVertexArray(null);

  const destBuffer = gl.createFramebuffer();
  gl.bindFramebuffer(gl.FRAMEBUFFER, destBuffer);
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, areaTexture, 0);

  const mat = mat4.create();
  mat4.translate(mat, mat, [-1, -1, 0]);
  mat4.scale(mat, mat, [2, 2, 0]);
  const matLoc = gl.getUniformLocation(program, "u_transformMat");
  if (!matLoc) {
    throw new Error("did not get matrix location");
  }
  gl.uniformMatrix4fv(matLoc, false, mat);

  const foreheadVertices = new Float32Array([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const render = (landmarkVertices: Float32Array | undefined) => {
    if (!landmarkVertices) {
      return;
    }
    gl.useProgram(program);
    gl.bindVertexArray(foreheadVao);

    // 0. Top left
    foreheadVertices[0] = landmarkVertices[leftForeheadEdgeVertex * 3] ?? 0;

    // 1. Bottom left
    foreheadVertices[3] = landmarkVertices[leftForeheadEdgeVertex * 3] ?? 0;
    foreheadVertices[4] = landmarkVertices[leftForeheadEdgeVertex * 3 + 1] ?? 0;

    // 2. Left bottom edge
    foreheadVertices[6] = landmarkVertices[leftForeheadTopVertex * 3] ?? 0;
    foreheadVertices[7] = landmarkVertices[leftForeheadTopVertex * 3 + 1] ?? 0;

    // 3. Right bottom edge
    foreheadVertices[9] = landmarkVertices[rightForeheadTopVertex * 3] ?? 0;
    foreheadVertices[10] = landmarkVertices[rightForeheadTopVertex * 3 + 1] ?? 0;

    // 4. Bottom right
    foreheadVertices[12] = landmarkVertices[rightForeheadEdgeVertex * 3] ?? 0;
    foreheadVertices[13] = landmarkVertices[rightForeheadEdgeVertex * 3 + 1] ?? 0;

    // 5. Top right
    foreheadVertices[15] = landmarkVertices[rightForeheadEdgeVertex * 3] ?? 0;

    gl.bindBuffer(gl.ARRAY_BUFFER, foreheadVerticesBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, foreheadVertices, gl.STREAM_DRAW);

    gl.bindFramebuffer(gl.FRAMEBUFFER, destBuffer);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.clearColor(0.0, 0.0, 0.0, 0.0);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawElements(gl.TRIANGLES, foreheadIndexes.length, gl.UNSIGNED_SHORT, 0);
  };

  const cleanUp = () => {};

  return { render, cleanUp };
};

// Combines mask in unit 1 intersected with union of 0 and 2
const buildCombineMasksStage = (
  gl: WebGL2RenderingContext,
  vertexShader: WebGLShader,
  positionBuffer: WebGLBuffer,
  texCoordBuffer: WebGLBuffer,
  outputBuffer: WebGLFramebuffer
) => {
  const fragmentShaderSrc = glsl`#version 300 es
    precision highp float;

    uniform sampler2D u_primaryMask;
    uniform sampler2D u_secondaryA;
    uniform sampler2D u_secondaryB;
    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      float p = texture(u_primaryMask, v_texCoord).r;
      float a = texture(u_secondaryA, v_texCoord).r;
      float b = texture(u_secondaryB, v_texCoord).r;
      outColor = vec4(max(min(p, a), min(p, b)), 0.0, 0.0, 1.0);
    }
  `;

  const fragmentShader = compileShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSrc);
  const program = createProgram(gl, vertexShader, fragmentShader);
  gl.useProgram(program);

  const texCoordAttributeLocation = gl.getAttribLocation(program, "a_texCoord");
  const positionAttributeLocation = gl.getAttribLocation(program, "a_position");
  const primaryMaskLocation = gl.getUniformLocation(program, "u_primaryMask");
  const secondaryALocation = gl.getUniformLocation(program, "u_secondaryA");
  const secondaryBLocation = gl.getUniformLocation(program, "u_secondaryB");

  const vao = gl.createVertexArray();
  gl.bindVertexArray(vao);

  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.vertexAttribPointer(positionAttributeLocation, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(positionAttributeLocation);

  gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
  gl.vertexAttribPointer(texCoordAttributeLocation, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(texCoordAttributeLocation);

  gl.uniform1i(primaryMaskLocation, 1);
  gl.uniform1i(secondaryALocation, 0);
  gl.uniform1i(secondaryBLocation, 2);

  gl.bindVertexArray(null);

  const render = () => {
    gl.useProgram(program);
    gl.bindFramebuffer(gl.FRAMEBUFFER, outputBuffer);
    gl.bindVertexArray(vao);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.clearColor(0.0, 0.0, 0.0, 0.0);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  };

  const cleanUp = () => {};
  return {
    render,
    cleanUp,
  };
};
