import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { z } from "zod";
import { crpc_billing_PatchBillingInfo } from "../../../../shared/BillingMessages/external/ClientRpcBillingAccount.js";
import { ExternalBillingAccount } from "../../../../shared/Models/billing/accounts/external/ExternalBillingAccount.js";
import { RootState } from "../../../store/reducers.js";

type OptionalRequestId = {
  requestId?: string;
};

export const RequestStatus = z.enum(["unknown", "pending", "success", "fail"]);
export type RequestStatus = z.infer<typeof RequestStatus>;

export type UpdateBillingInfoPayload = Omit<crpc_billing_PatchBillingInfo, "kind"> &
  OptionalRequestId;

const billingAccountsAdapter = createEntityAdapter<ExternalBillingAccount>();

const slice = createSlice({
  name: "billingAccounts",
  initialState: {
    billingAccounts: billingAccountsAdapter.getInitialState(),
    requestStatuses: {} as Record<string, RequestStatus>,
  },
  reducers: {
    // Saga
    fetchBillingAccountById: (state, action: PayloadAction<number>) => {},
    updateBillingInfo: (state, action: PayloadAction<UpdateBillingInfoPayload>) => {},

    // Redux
    setBillingAccount: (state, action: PayloadAction<ExternalBillingAccount>) => {
      billingAccountsAdapter.setOne(state.billingAccounts, action.payload);
    },
    setRequestStatus: (
      state,
      action: PayloadAction<{
        requestId: string;
        status: RequestStatus;
      }>
    ) => {
      const { requestId, status } = action.payload;
      state.requestStatuses[requestId] = status;
    },
  },
});

export const { actions, reducer } = slice;
export const BillingAccountsActions = actions;
export const billingAccountsReducer = reducer;

const selectSlice = (state: RootState) => state.billing.billingAccounts;
const billingAccountsSelectors = billingAccountsAdapter.getSelectors();
export const selectors = {
  selectBillingAccountById: (accountId: number) => (state: RootState) =>
    billingAccountsSelectors.selectById(selectSlice(state).billingAccounts, accountId),
  selectRequestStatus: (requestId: string) => (state: RootState) =>
    selectSlice(state).requestStatuses[requestId] ?? "unknown",
};
export const BillingAccountsSelectors = selectors;
