import { combineReducers } from "redux";
import { reducer as audienceRequestReducer } from "./slices/audienceRequestSlice.js";
import { bigMeetingRoomReducer } from "./slices/bigMeetingRoomSlice.js";
import { reducer as conversationParticipantReducer } from "./slices/conversationParticipantSlice.js";
import { reducer as conversationReducer } from "./slices/conversationSlice.js";
import { reducer as knockReducer } from "./slices/knockSlice.js";
import { reducer as locationReducer } from "./slices/locationSlice.js";
import { reducer as meetingFeedbackReducer } from "./slices/meetingFeedbackSlice.js";
import { reducer as myLocationReducer } from "./slices/myLocationSlice.js";
import { RaiseHandReducer } from "./slices/raiseHandSlice.js";
import { reducer as reactionReducer } from "./slices/reactionSlice.js";
import { reducer as roomInviteSlice } from "./slices/roomInviteSlice.js";
import { reducer as roomReducer } from "./slices/roomSlice.js";
import { reducer as toneReducer } from "./slices/toneSlice.js";
import { reducer as zoomDndReducer } from "./slices/zoomDndSlice.js";

export const reducer = combineReducers({
  audienceRequests: audienceRequestReducer,
  bigMeetingRoom: bigMeetingRoomReducer,
  conversation: conversationReducer,
  conversationParticipant: conversationParticipantReducer,
  knock: knockReducer,
  location: locationReducer,
  meetingFeedback: meetingFeedbackReducer,
  myLocation: myLocationReducer,
  raiseHand: RaiseHandReducer,
  reaction: reactionReducer,
  room: roomReducer,
  roomInvite: roomInviteSlice,
  tone: toneReducer,
  zoomDnd: zoomDndReducer,
});
