const titleBarHeightMacPx = 56;
const titleBarHeightWin32px = 32;

const smallTitleBarHeightMacPx = 42;
const extraSmallTitleBarHeightMacPx = 30;

const trafficLightControlsMacPx = 103;
const windowControlsWin32Px = 96;

export interface TitleBarProperties {
  customTitleBarHeightPx: number;
  smallTitleBarHeightPx: number;
  extraSmallTitleBarHeightPx: number;
  unifiedTitleBar: boolean;
  windowControlsWidthPx: number;
  drawCustomWindowControls: boolean;
}

export const defaultTitleBarProps: TitleBarProperties = {
  customTitleBarHeightPx: 0,
  smallTitleBarHeightPx: 0,
  extraSmallTitleBarHeightPx: 0,
  unifiedTitleBar: false,
  windowControlsWidthPx: 0,
  drawCustomWindowControls: false,
};

export const titleBarPropertiesForOS = (os: string): TitleBarProperties => {
  switch (os) {
    case "darwin":
      return {
        customTitleBarHeightPx: titleBarHeightMacPx,
        smallTitleBarHeightPx: smallTitleBarHeightMacPx,
        extraSmallTitleBarHeightPx: extraSmallTitleBarHeightMacPx,
        unifiedTitleBar: true,
        windowControlsWidthPx: trafficLightControlsMacPx,
        drawCustomWindowControls: false,
      };
    case "win32":
    case "linux":
      return {
        customTitleBarHeightPx: titleBarHeightWin32px,
        smallTitleBarHeightPx: titleBarHeightWin32px,
        extraSmallTitleBarHeightPx: titleBarHeightWin32px,
        unifiedTitleBar: false,
        windowControlsWidthPx: windowControlsWin32Px,
        drawCustomWindowControls: true,
      };
    default: {
      return defaultTitleBarProps;
    }
  }
};
