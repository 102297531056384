import KrispSDK from "@krispai/javascript-sdk";
import { v4 as uuidv4 } from "uuid";
import { VideoPipelineStats } from "../../shared/Models/VideoPipeline.js";
import { ClientContainerInfo } from "../injection/IClientContainer.js";
import { IClientService } from "../injection/IClientService.js";
import { IRedux } from "../injection/redux/IRedux.js";
import { PostProcObjectCache } from "./PostProcObjectCache.js";
import { AudioPipelineLauncher } from "./audio/AudioPipelineLauncher.js";
import {
  KrispPostProcAudioCachedObject,
  KrispPostProcAudioPipeline,
} from "./audio/KrispPostProcAudioPipeline.js";
import {
  RNNoisePostProcAudioCachedObject,
  RNNoisePostProcAudioPipeline,
} from "./audio/RNNoisePostProcAudioPipeline.js";
import { VADOnlyPostProcAudioPipeline } from "./audio/VADOnlyPostProcAudioPipeline.js";
import { IPipelineBenchmarkService } from "./benchmark/interfaces/IPipelineBenchmarkService.js";
import { IAudioPipelineLauncher } from "./interfaces/IAudioPipelineLauncher.js";
import { IAvDevices } from "./interfaces/IAvDevices.js";
import {
  IAvPipelines,
  OnNewAudioStreamsCallback,
  OnNewVideoStreamCallback,
  OnVideoErrorsCallback,
} from "./interfaces/IAvPipelines.js";
import { IRawAudioStreamManager, IRawVideoStreamManager } from "./interfaces/IRawStreamManager.js";
import { VideoPipelineLauncher } from "./video/VideoPipelineLauncher.js";
import { IVideoPipelineCache } from "./video/interfaces/IVideoPipelineCache.js";
import { IVideoPipelineLauncher } from "./video/interfaces/IVideoPipelineLauncher.js";

interface Injected {
  avDevices(): IAvDevices;
  info(): ClientContainerInfo;
  pipelineBenchmarkService(): IPipelineBenchmarkService;
  rawAudioStreamManager(): IRawAudioStreamManager;
  rawVideoStreamManager(): IRawVideoStreamManager;
  redux(): IRedux;
  videoPipelineCache(): IVideoPipelineCache;
}

/*
 * AvPipelines is a main entry point for callers outside of the AvStream services. It allows
 * callers to create new AudioPipelineLaunchers and VideoPipelineLaunchers, which create and start
 * pipelines matching the caller's specifications. AvPipelines maintains references to these
 * launchers so that it can apply global settings to them.
 *
 *
 */
export class AvPipelines implements IAvPipelines, IClientService {
  private audioPipelineLaunchers = new Map<string, AudioPipelineLauncher>();
  private videoPipelineLaunchers = new Map<string, VideoPipelineLauncher>();

  private krispPostProcAudioObjectCache = new PostProcObjectCache<KrispPostProcAudioCachedObject>(
    () => KrispPostProcAudioPipeline.createObject(this.container),
    1,
    2
  );
  private rnnoisePostProcAudioObjectCache =
    new PostProcObjectCache<RNNoisePostProcAudioCachedObject>(
      () => RNNoisePostProcAudioPipeline.createObject(this.container),
      1,
      2
    );

  constructor(private container: Injected) {}

  public name() {
    return "AvPipelines";
  }

  public newAudioPipelineLauncher(
    onNewStreams: OnNewAudioStreamsCallback,
    useKrispAudio: boolean
  ): IAudioPipelineLauncher {
    const id = uuidv4();

    let postProcAudioPipeline;
    if (this.container.info().containerType !== "web") {
      postProcAudioPipeline = new VADOnlyPostProcAudioPipeline(this.container);
    } else if (useKrispAudio) {
      postProcAudioPipeline = new KrispPostProcAudioPipeline(
        this.container,
        this.krispPostProcAudioObjectCache
      );
    } else {
      postProcAudioPipeline = new RNNoisePostProcAudioPipeline(
        this.container,
        this.rnnoisePostProcAudioObjectCache
      );
    }

    const launcher = new AudioPipelineLauncher(
      this.container,
      onNewStreams,
      () => this.audioPipelineLaunchers.delete(id),
      postProcAudioPipeline
    );
    this.audioPipelineLaunchers.set(id, launcher);
    return launcher;
  }

  public newVideoPipelineLauncher(
    streamId: string,
    onNewStream: OnNewVideoStreamCallback,
    onErrors: OnVideoErrorsCallback
  ): IVideoPipelineLauncher {
    if (this.videoPipelineLaunchers.has(streamId)) {
      throw new Error(`duplicate streamId ${streamId}`);
    }

    const launcher = new VideoPipelineLauncher(
      this.container,
      streamId,
      onNewStream,
      onErrors,
      () => {
        this.videoPipelineLaunchers.delete(streamId);
      }
    );
    this.videoPipelineLaunchers.set(streamId, launcher);
    return launcher;
  }

  public getStatsByStreamId(streamId: string): VideoPipelineStats | undefined {
    return this.videoPipelineLaunchers.get(streamId)?.getStats();
  }

  public forceReopenAllStreams(): void {
    this.container.rawAudioStreamManager().forceReopenAllStreams();
    this.container.rawVideoStreamManager().forceReopenAllStreams();
  }

  public async preloadAudioPostProc() {
    // Pre-create an AudioContext and NoiseNode
    // NOTE: This will trigger a warning that the AudioContext is initially suspended. That's fine;
    // we don't try to resume it and use it until we try to capture the mic, which is usually after
    // the user has entered Roam and thus interacted with the page.
    if (this.container.info().containerType === "web") {
      if (KrispSDK.isSupported()) {
        this.krispPostProcAudioObjectCache.preload();
      } else {
        this.rnnoisePostProcAudioObjectCache.preload();
      }
    }
  }

  public async preloadVideoPostProc() {
    const selection = await this.container.pipelineBenchmarkService().performAutoSelect();
    if (selection !== undefined) {
      this.container.videoPipelineCache().preloadForPipeline(selection);
    }
  }

  public snapshot(): any {
    return {
      audioPipelineLaunchers: Object.fromEntries(
        [...this.audioPipelineLaunchers.entries()].map(([id, runner]) => [id, runner.dump()])
      ),
      videoPipelineLaunchers: Object.fromEntries(
        [...this.videoPipelineLaunchers.entries()].map(([id, runner]) => [id, runner.dump()])
      ),
      krispPostProcAudioObjectCache: {
        cachedItemsCount: this.krispPostProcAudioObjectCache.cachedItemsCount,
        preloadingItemsCount: this.krispPostProcAudioObjectCache.preloadingItemsCount,
      },
      rnnoisePostProcAudioObjectCache: {
        cachedItemsCount: this.rnnoisePostProcAudioObjectCache.cachedItemsCount,
        preloadingItemsCount: this.rnnoisePostProcAudioObjectCache.preloadingItemsCount,
      },
    };
  }
}
