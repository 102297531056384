import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { ExternalProduct } from "../../../../shared/Models/billing/products/ExternalProduct.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const productsAdapter = createEntityAdapter<ExternalProduct>();

const slice = createSlice({
  name: "products",
  initialState: {
    products: productsAdapter.getInitialState(),
  },
  reducers: {
    // Saga
    fetchProductBySku: (state, action: PayloadAction<string>) => {},

    // Redux
    setProduct: (state, action: PayloadAction<ExternalProduct>) => {
      productsAdapter.setOne(state.products, action.payload);
    },
  },
});

export const { actions, reducer } = slice;
export const ProductsActions = actions;
export const productsReducer = reducer;

const productsSelectors = productsAdapter.getSelectors();
export const selectors = {
  selectProductBySku: defaultMemoize(
    (sku: string) =>
      createSelector(
        (state: RootState) => state.billing.products.products,
        (products) => productsSelectors.selectAll(products).find((product) => product.sku === sku)
      ),
    { maxSize: 5 }
  ),
};
export const ProductsSelectors = selectors;
