import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

const slice = createSlice({
  name: "zoomDnd",
  initialState: {
    // Only tracked when a person is in their own office and using the electron client, otherwise
    // false.
    inZoomMeeting: false,
  },
  reducers: {
    setIsInZoomMeeting: (
      state,
      action: PayloadAction<{ isInZoomMeeting: boolean; windowKey: WindowKey }>
    ) => {
      state.inZoomMeeting = action.payload.isInZoomMeeting;
    },
  },
});

export const { actions, reducer } = slice;

export const selectors = {
  selectIsInZoomMeeting: (state: RootState) => state.section.zoomDnd.inZoomMeeting,
};
