/**
 * An application error.
 *
 * Application errors indicate that something went wrong with application logic or state.
 * This is in contrast to things like network errors, database errors, etc.
 *
 * Notably, the error message for an `ApplicationError` is explicitly required to be displayable to
 * end users.
 */
class ApplicationError extends Error {
  /**
   * @param displayMessage  An error message, which may be displayed to end users.
   */
  constructor(
    private displayMessage: string,
    options?: ErrorOptions
  ) {
    // NOTE:
    //      The error message and display message are the same, but we also have an explicitly
    //      named field to make it super obvious that this is intended for display purposes.
    super(displayMessage, options);
    this.name = "ApplicationError";
  }

  /**
   * The display message.
   */
  public getDisplayMessage(): string {
    return this.displayMessage;
  }
}
export default ApplicationError;
