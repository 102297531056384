import { cleanOptionalEmail } from "../../../shared/helpers/validators.js";
import { WindowKey, mainWindowKey, meetingWindowKey } from "../../injection/windows/WindowKey.js";
import { Anchor, AnchorIds, AnchorParent, stringIsAnchorType } from "../types/Anchor.js";
import { Popup } from "../types/Popup.js";

// Returns a key that can be used to uniquely identify an anchor and that can be generated in
// `usePopAnchor` for any possible anchor component. Except for the `unanchored` anchorType, which
// is tied to a specific popup and used only when no other anchor is available, potential anchors
// should be uniquely identifiable by anchorType, anchorParent, occupantId, email, chatId.
export const createAnchorKey = ({
  anchorType,
  anchorParent,
  occupantId,
  email: rawEmail,
  clientUuid,
  chatId,
  pendingChatId,
  fixedPopupId,
  panelId,
}: AnchorIds): string => {
  const email = cleanOptionalEmail(rawEmail);
  if (anchorType === "popupIcon") {
    return `${anchorParent}:${anchorType}:${fixedPopupId}`;
  } else if (anchorType === "recentDraftIcon") {
    return `${anchorParent}:${anchorType}:${chatId ?? ""}:${pendingChatId ?? "  "}`;
  } else {
    return `${anchorParent}:${anchorType}:${occupantId ?? "undefined"}:${email ?? "undefined"}:${
      chatId ?? undefined
    }:${clientUuid ?? "undefined"}`;
  }
};

export const defaultAnchorKey = (anchorParent: AnchorParent, popupId: number): string =>
  createAnchorKey({ anchorType: "popupIcon", anchorParent, fixedPopupId: popupId });

export const anchorKeyToIds = (key: string): AnchorIds | undefined => {
  const parts = key.split(":");
  const anchorParent: AnchorParent | undefined =
    parts[0] === "mainWindow"
      ? "mainWindow"
      : parts[0] === "meetingWindow"
        ? "meetingWindow"
        : undefined;
  if (!anchorParent) return undefined;

  if (parts.length === 3 && parts[1] && parts[2]) {
    if (parts[1] === "popupIcon") {
      const fixedPopupId = parseInt(parts[2]);
      if (isNaN(fixedPopupId)) return undefined;
      return {
        anchorParent,
        anchorType: "popupIcon",
        fixedPopupId,
      };
    }
  } else if (parts.length === 4 && parts[1] && stringIsAnchorType(parts[1])) {
    return {
      anchorParent,
      anchorType: parts[1],
      chatId: parts[2] ?? undefined,
      pendingChatId: parts[3] ?? undefined,
    };
  } else if (parts.length === 6 && parts[1] && stringIsAnchorType(parts[1])) {
    return {
      anchorParent,
      anchorType: parts[1],
      occupantId: parts[2],
      email: parts[3],
      chatId: parts[4],
      clientUuid: parts[5],
    };
  }

  return undefined;
};

export const anchorParentForWindow = (windowKey?: WindowKey): AnchorParent | undefined =>
  windowKey === mainWindowKey
    ? "mainWindow"
    : windowKey === meetingWindowKey
      ? "meetingWindow"
      : undefined;

export const anchorForParent = (p: Popup, parent: AnchorParent) =>
  parent === "mainWindow" ? p.mainWindowAnchor : p.meetingWindowAnchor;

export const popupIconKeyForParent = (p: Popup, parent: AnchorParent) =>
  parent === "mainWindow" ? p.mainWindowPopupIconKey : p.meetingWindowPopupIconKey;

export const anchorForWindow = (p?: Popup, windowKey?: WindowKey) => {
  if (!p) return undefined;
  const parent = anchorParentForWindow(windowKey);
  if (!parent) return undefined;
  return anchorForParent(p, parent);
};

export const emptyPopupIconAnchor = (anchorParent: AnchorParent, popupId: number): Anchor => {
  return {
    anchorType: "popupIcon",
    anchorKey: createAnchorKey({ anchorType: "popupIcon", anchorParent, fixedPopupId: popupId }),
    anchorStatus: "missingClientRect",
    clientRect: undefined,
    anchorParent,
  };
};
