import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionRecord } from "../../../../shared/Models/ActionRecord.js";
import { RootState } from "../../../store/reducers.js";

// Action records for each roam. There may be dupes across
// roams if an action is for an account rather than a specific roam.
export interface ActionRecordsByRoam {
  [roamId: number]: ActionRecord[];
}

export interface ActionRecordCountByRoam {
  [roamId: number]: number;
}

interface ActionRecordsPayload {
  roamId: number;
  totalCount: number;
  actionRecords: ActionRecord[];
}

const slice = createSlice({
  name: "actionRecords",
  initialState: {
    actionRecordsByRoam: {} as ActionRecordsByRoam,
    totalCountsByRoam: {} as ActionRecordCountByRoam,
  },
  reducers: {
    requestMostRecentForRoam: (_, action: PayloadAction<number>) => {},
    requestMoreForRoam: (_, action: PayloadAction<number>) => {},
    addRecords: (state, action: PayloadAction<ActionRecordsPayload>) => {
      const { roamId, totalCount, actionRecords } = action.payload;
      const currentRecords = state.actionRecordsByRoam[roamId] || [];
      // Dedupe just in case.
      for (const record of actionRecords) {
        const found = currentRecords.find((current) => {
          return current.id === record.id;
        });
        if (!found) {
          currentRecords.push(record);
        }
      }
      // Sort newest to oldest.
      currentRecords.sort(
        (r1, r2) => (r2.timestamp?.getTime() || 0) - (r1.timestamp?.getTime() || 0)
      );
      state.actionRecordsByRoam[roamId] = currentRecords;
      state.totalCountsByRoam[roamId] = totalCount;
    },
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
};

export const selectors = {
  selectRecordsByRoam: (roamId: number) => (state: RootState) =>
    roamId ? state.anyWorld.actionRecords.actionRecordsByRoam[roamId] : undefined,
  selectCountByRoam: (roamId: number) => (state: RootState) =>
    roamId ? state.anyWorld.actionRecords.totalCountsByRoam[roamId] : 0,
};
