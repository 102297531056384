/* eslint-disable no-console */
import { isString } from "lodash";
import { Level, LogEvent, pino } from "pino";
import { inspect } from "util";
import { isDevMode, isElectron } from "../api/environment.js";
import { environment } from "../api/http.js";
import { UnreachableError } from "../helpers/UnreachableError.js";
import { getErrorMessage, getErrorStack } from "../helpers/errors.js";
import { contextLogFields } from "./context/context.js";

export interface RoamLogFn {
  (obj: Record<string, any>, msg?: string): void;
  (msg: string): void;
}
export interface RoamLogger {
  debug: RoamLogFn;
  error: RoamLogFn;
  fatal: RoamLogFn;
  info: RoamLogFn;
  trace: RoamLogFn;
  warn: RoamLogFn;

  level: string;
  levels: pino.LevelMapping;
  isLevelEnabled(level: string): boolean;
}

const levels: Record<Level, number> = {
  trace: 10,
  debug: 20,
  info: 30,
  warn: 40,
  error: 50,
  fatal: 60,
};

let logLevel: Level;
switch (environment) {
  case "dev":
  case "exp":
  case "local":
  case "playground":
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    logLevel = typeof process !== "undefined" && process.env.CI ? "info" : "debug";
    break;
  case "stage":
  case "prod":
    logLevel = isElectron ? "debug" : "info";
    break;
  default:
    throw new UnreachableError(environment);
}

const isLevelEnabled = (level: Level) => {
  return levels[level] >= levels[logLevel];
};

const parseLogEvent = (logEvent: LogEvent): [Record<string, any>, string] => {
  if (logEvent.messages.length === 1) {
    if (typeof logEvent.messages[0] === "string") {
      return [{}, logEvent.messages[0]];
    } else if (typeof logEvent.messages[0] === "object") {
      if (typeof logEvent.messages[0].err?.message === "string") {
        // Use the error's message if no log message was provided.
        return [logEvent.messages[0], logEvent.messages[0].err.message];
      } else {
        return [logEvent.messages[0], "No message was provided."];
      }
    }
  } else if (logEvent.messages.length === 2) {
    if (typeof logEvent.messages[0] === "object" && typeof logEvent.messages[1] === "string") {
      return [logEvent.messages[0], logEvent.messages[1]];
    }
  }

  throw new Error(`Log event format unrecognized: ${inspect(logEvent)}`);
};

export const loggers = new Map<string, pino.Logger>();

export type MessageLoggedListener = (
  level: number,
  context: Record<string, any>,
  message: string
) => void;
const messageLoggedListeners = new Set<MessageLoggedListener>();
export const onMessageLogged = (listener: MessageLoggedListener) => {
  messageLoggedListeners.add(listener);
};

let prettyPrintEnabled = false;
if (typeof process !== "undefined") {
  prettyPrintEnabled = process.env.HACKHACK_LOCAL_DEV_MODE === "1";
}

export const createLogger = (name: string, getContextFields: () => Record<string, any>) => {
  const options: pino.LoggerOptions = {
    name,
    level: logLevel,
    transport: prettyPrintEnabled
      ? {
          target: "pino-pretty",
          options: {
            colorize: true,
            include: "error,level,name,pid,time",
          },
        }
      : undefined,
    formatters: {
      level: (label: string, _: number) => ({ level: label }),
    },
    hooks: {
      // Right now error function is only set in
      // Roam Electron, but this makes it so logger.error and
      // logger.fatal in the main process can send
      // an IPC to the logger window to log the error
      logMethod(inputArgs, method, level) {
        let cleanedInputArgs: [Record<string, any>, string, ...any[]];
        const contextFields = getContextFields();

        /**
        The function sig for the log function is tricky; this will try to normalize the args.

        eg: logger.info({}), logger.info({}, "string"), logger.info("string") are all valid

        Here we're making everything logger.info({}, string) before passing the arg along to
        the main log function

        */
        if (typeof inputArgs[0] === "string") {
          cleanedInputArgs = [contextFields, inputArgs[0], ...inputArgs.slice(1)];
        } else if (typeof inputArgs[0] === "object") {
          const context = inputArgs[0] as any;
          let msg = "No log message provided";
          let sliceDepth = 1;
          if (typeof inputArgs[1] === "string") {
            msg = inputArgs[1];
            sliceDepth = 2;
          } else {
            const errMessage = context.err?.message;

            // Fallback to the message from an error if one was provided.
            if (isString(errMessage)) {
              msg = errMessage;
            }
          }

          // backwards compatibility moving err to error key
          if (context.err) {
            context.error = context.err;
            context.err = undefined;
          }

          // if error is type Error, make it DD formatted
          const contextError = context.error;
          if (contextError instanceof Error) {
            context.error = errorToDDPayload(contextError);
          }

          cleanedInputArgs = [
            {
              ...contextFields,
              ...context,
            },
            msg,
            ...inputArgs.slice(sliceDepth),
          ];
        } else {
          cleanedInputArgs = [contextFields, "No log message or error provided"];
        }

        for (const messageLoggedListener of messageLoggedListeners) {
          messageLoggedListener(level, cleanedInputArgs[0], cleanedInputArgs[1]);
        }

        if (isElectron && isDevMode) {
          // Pino prints logs in JSON format by default (which is difficult to read).
          // Normally we use pino-pretty to make the logs readable but its not compatible with Electron.
          // So log messages directly to the console for Electron.
          console.log(cleanedInputArgs[1]);

          const error = cleanedInputArgs[0].error;
          if (error) {
            console.error(getErrorMessage(error));
          }
        } else if (!isElectron) {
          // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-2.html#caveats
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          method.apply(this, cleanedInputArgs);
        }
      },
    },
    // This causes logger.[warn|error|fatal] calls
    // in browser side code to send to datadog if it is configured
    browser: {
      write: {
        trace: (obj) => {
          if (!isLevelEnabled("trace")) return;
          const logObj = obj as { msg: any };
          const arg = getConsoleRepresentation(obj);
          // Write the message using console.debug because Chrome treats console.trace as "info" level.
          // https://developer.chrome.com/docs/devtools/console/api/#debug
          console.debug(logObj.msg, arg);
        },
        debug: (obj) => {
          if (!isLevelEnabled("debug")) return;
          const logObj = obj as { msg: any };
          const arg = getConsoleRepresentation(obj);
          console.debug(logObj.msg, arg);
        },
        info: (obj) => {
          if (!isLevelEnabled("info")) return;
          const logObj = obj as { msg: any };
          if (typeof logObj.msg === "string" && logObj.msg.startsWith("Process Metrics")) return;
          const arg = getConsoleRepresentation(obj);
          console.info(logObj.msg, arg);
        },
        warn: (obj) => {
          if (!isLevelEnabled("warn")) return;
          const logObj = obj as { msg: any };
          const arg = getConsoleRepresentation(obj);
          console.warn(logObj.msg, arg);
        },
        error: (obj) => {
          if (!isLevelEnabled("error")) return;
          const logObj = obj as { err?: Error; msg: any };
          const arg = getConsoleRepresentation(obj);
          console.error(logObj.msg, arg);
        },
        fatal: (obj) => {
          if (!isLevelEnabled("fatal")) return;
          const logObj = obj as { err?: Error; msg: any };
          const arg = getConsoleRepresentation(obj);
          console.error(logObj.msg, arg);
        },
      },
      transmit: {
        level: "info",
        send: (level, logEvent) => {
          const [context, message] = parseLogEvent(logEvent);
          const messageContext = { ...getContextFields(), ...context };
          for (const messageLoggedListener of messageLoggedListeners) {
            messageLoggedListener(levels[level], messageContext, message);
          }
        },
      },
    },
  };
  const logger = pino(options);
  if (!logger.isLevelEnabled) {
    // Polyfill isLevelEnabled for the browser version of pino.
    logger.isLevelEnabled = isLevelEnabled;
  }
  loggers.set(name, logger);
  return logger;
};

/** Convert the logger obj into a string representation that works well in console output. */
const getConsoleRepresentation = (obj: any) => {
  if (obj.err) {
    const { err, msg, ...smallerObj } = obj;
    return `\n${inspect(smallerObj)}\n${getErrorMessage(err)}`;
  } else {
    return "";
  }
};

interface DDErrorPayload {
  kind: string;
  message: string;
  stack?: string;
}

const errorToDDPayload = (error: Error): DDErrorPayload => {
  return {
    kind: error.constructor.name,
    message: error.message,
    stack: getErrorStack(error),
  };
};

export const logger = createLogger("roam", () => contextLogFields()) as RoamLogger;
