export const dumpMediaStream = (stream: MediaStream): any => {
  return {
    id: stream.id,
    active: stream.active,
    tracks: stream.getTracks().map(dumpMediaStreamTrack),
  };
};

export const dumpMediaStreamTrack = (track: MediaStreamTrack): any => {
  return {
    id: track.id,
    kind: track.kind,
    label: track.label,
    enabled: track.enabled,
    contentHint: track.contentHint,
    readyState: track.readyState,
    settings: track.getSettings(),
    constraints: track.getConstraints(),
  };
};
