import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ZoneInteractionButton } from "../../../../shared/ZoneMessages/ClientRpcZone.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const slice = createSlice({
  name: "interactionButtons",
  initialState: {
    buttons: [] as ZoneInteractionButton[],
  },
  reducers: {
    setButtons: (state, action: PayloadAction<ZoneInteractionButton[]>) => {
      state.buttons = action.payload;
    },
    clearButtons: (state) => {
      state.buttons = [];
    },
    retrieveButtons: () => {},
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.zone.interactionButtons;
export const selectors = {
  selectButtons: createSelector(selectSlice, (slice) => slice.buttons),
};
