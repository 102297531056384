import { ChatAddress, ChatTarget } from "../Models/ChatAddress.js";

const normalize = (s: string) => s.trim().toLowerCase();

export const nameIncludes = (name: string | undefined, query: string) => {
  if (!name) return false;

  const nName = normalize(name);
  const nQuery = normalize(query);

  if (nName.startsWith(nQuery)) return true;

  const nParts = nName.split(" ").filter((p) => !!p);
  for (const nPart of nParts) {
    if (nPart.startsWith(nQuery)) return true;
  }
  return false;
};

export const emailIncludes = (email: string | undefined, query: string, exact: boolean) => {
  if (!email) return false;

  const nEmail = normalize(email);
  const nQuery = normalize(query);
  return exact ? nEmail === nQuery : nEmail.startsWith(nQuery);
};

const nameOrEmailMatch = (address: ChatTarget, query: string) => {
  const hasNameMatch = nameIncludes(address.displayName, query);
  const hasEmailMatch = "email" in address && emailIncludes(address.email, query, false);
  return hasNameMatch || hasEmailMatch;
};

export const isMatch = (otherAddresses: ChatAddress[], query: string): boolean => {
  // if the full query matches the name or email of a chat address
  const queryMatch = otherAddresses.some((address) => nameOrEmailMatch(address, query));
  if (queryMatch) {
    return true;
  }

  // if every part of the query matches at least one email or name in a chat address
  // this is intended to capture group chat where you search for "howard peter"; it should find your group chat
  // to howard and peter (and others)
  const queryPartsMatch = query.split(" ").every((queryPart) => {
    return otherAddresses.some((address) => nameOrEmailMatch(address, queryPart));
  });
  if (queryPartsMatch) {
    return true;
  }

  return false;
};

export type MatchType = "none" | "infix" | "prefix";

export const tryMatch = (address: ChatTarget, query: string): MatchType => {
  query = normalize(query);
  if (nameOrEmailMatch(address, query)) {
    return "prefix";
  }
  if (address.displayName && normalize(address.displayName).includes(query)) {
    return "infix";
  }
  return "none";
};
