import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { inspect } from "util";
import {
  MaxBackgroundBlurLevel,
  MediaDeviceInfo,
  MinBackgroundBlurLevel,
} from "../../client/avStream/avStreamShared.js";
import {
  IVideoPipelineLauncher,
  VideoSourceSpec,
  videoPipelineFriendlyName,
} from "../../client/avStream/video/interfaces/IVideoPipelineLauncher.js";
import Button from "../../client/components/Button.js";
import { styled } from "../../client/styles/theme.js";
import { VideoPipelineName } from "../../shared/Models/VideoPipeline.js";
import { logger } from "../../shared/infra/logger.js";
import { usePipelines } from "./PipelinesContext.js";
import {
  initialPostProcState,
  initialSourceSpec,
  postProcReducer,
} from "./pipelineOptionsReducers.js";

interface Props {
  pipelineLauncher: IVideoPipelineLauncher;
  stream?: MediaStream;
}

export const PipelineOptions: React.FC<Props> = ({ pipelineLauncher, stream }: Props) => {
  const container = usePipelines();
  const [sourceSpec, setSourceSpec] = useState<VideoSourceSpec>(initialSourceSpec);
  const [pipelineState, dispatchPostProc] = useReducer(postProcReducer, initialPostProcState);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [recordToFile, setRecordToFile] = useState(false);

  const supportsTouchup = useMemo(() => {
    const features = container.videoPipelineCache().featuresForPipeline(pipelineState.pipeline);
    return features.touchup;
  }, [container, pipelineState.pipeline]);

  // Update pipeline when options change
  useEffect(() => {
    const postProcSpec = pipelineState.pipelineOn ? pipelineState.spec : undefined;
    logger.info(`setting want to ${inspect(postProcSpec)}`);
    pipelineLauncher.setWant(sourceSpec, postProcSpec, false);
  }, [pipelineLauncher, sourceSpec, pipelineState]);

  useEffect(() => {
    container.avDevices().on("devicechange", () => {
      setDevices(
        container
          .avDevices()
          .mediaDevices()
          .filter((d) => d.kind === "videoinput")
      );
    });
    container.avDevices().refreshDeviceList();
    setTimeout(() => {
      setDevices(
        container
          .avDevices()
          .mediaDevices()
          .filter((d) => d.kind === "videoinput")
      );
    }, 1000);
  }, [container]);

  const runAutoSelect = useCallback(
    (force: boolean) => {
      container
        .pipelineBenchmarkService()
        .performAutoSelect(force)
        .then((pipeline) => dispatchPostProc({ action: "setAutoPipeline", pipeline }))
        .catch((err) => logger.error({ err }, "error performing autoselect"));
    },
    [container]
  );

  useEffect(() => {
    if (!pipelineState.autoPipeline) {
      runAutoSelect(false);
    }
  }, [pipelineState.autoPipeline, runAutoSelect]);

  const stopRecordRef = useRef(() => {});
  const toggleRecording = useCallback(() => {
    if (!stream) {
      logger.error("no stream to record");
      return;
    }
    if (recordToFile) {
      setRecordToFile(false);
      const options = { mimeType: "video/webm; codecs=vp9" };
      const recorder = new MediaRecorder(stream, options);
      const chunks: Blob[] = [];
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };
      recorder.onstop = () => {
        const blob = new Blob(chunks, {
          type: "video/webm",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = "test.webm";
        a.click();
        window.URL.revokeObjectURL(url);
      };
      stopRecordRef.current = () => recorder.stop();
      recorder.start();
    } else {
      stopRecordRef.current();
      setRecordToFile(true);
    }
  }, [stream, recordToFile]);

  return (
    <Container>
      <Title>Options</Title>
      <Option>
        <Label htmlFor="pipeline">Pipeline:</Label>
        <Select
          id="pipeline"
          value={pipelineState.spec.pipelineName}
          onChange={(el) => dispatchPostProc({ action: "setPipeline", pipeline: el.target.value })}
        >
          {VideoPipelineName.options.map((v) => (
            <option value={v} key={v}>
              {videoPipelineFriendlyName(v)}
            </option>
          ))}
        </Select>
      </Option>
      <Option>
        <Label>Auto Selected:</Label>
        <ClickableText onClick={() => runAutoSelect(true)}>
          {videoPipelineFriendlyName(pipelineState.autoPipeline)}
        </ClickableText>
      </Option>
      <Option>
        <Label htmlFor="pipelineOn">Active:</Label>
        <Input
          id="active"
          type="checkbox"
          checked={pipelineState.pipelineOn}
          onChange={(_) => dispatchPostProc({ action: "togglePipeline" })}
        />
      </Option>
      <Option>
        <Label htmlFor="blurLevel">Blur Level:</Label>
        <Value>
          <Slider
            type="range"
            id="blurLevel"
            min={MinBackgroundBlurLevel}
            max={MaxBackgroundBlurLevel}
            step="1"
            value={pipelineState.blurLevel}
            onChange={(e) =>
              dispatchPostProc({ action: "setBlurLevel", blurLevel: parseInt(e.target.value) })
            }
          />
        </Value>
      </Option>
      <Option>
        <Label htmlFor="bgImageOn">BG Image:</Label>
        <Input
          id="active"
          type="checkbox"
          checked={pipelineState.bgImageOn}
          onChange={(_) => dispatchPostProc({ action: "toggleBgImage" })}
        />
      </Option>
      {supportsTouchup && (
        <Option>
          <Label htmlFor="touchUpLevel">Touchup Level:</Label>
          <Value>
            <Slider
              type="range"
              id="touchupLevel"
              min={MinBackgroundBlurLevel}
              max={MaxBackgroundBlurLevel}
              step="1"
              value={pipelineState.touchUpLevel}
              onChange={(e) =>
                dispatchPostProc({
                  action: "setTouchUpLevel",
                  touchUpLevel: parseInt(e.target.value),
                })
              }
            />
          </Value>
        </Option>
      )}
      <Option>
        <Label htmlFor="device">Device:</Label>
        <select
          id="pipeline"
          value={sourceSpec.deviceIds[0] ?? ""}
          onChange={(el) => setSourceSpec({ deviceIds: [el.target.value], fallback: "last" })}
        >
          {devices.map((d) => (
            <option key={d.deviceId} value={d.deviceId}>
              {d.label}
            </option>
          ))}
        </select>
      </Option>
      <Option>
        <Label htmlFor="debugStage">Debug Stage:</Label>
        <Value>
          <Slider
            type="range"
            id="blurLevel"
            min={MinBackgroundBlurLevel}
            max={MaxBackgroundBlurLevel}
            step="1"
            value={pipelineState.spec.debugStage ?? 0}
            onChange={(e) =>
              dispatchPostProc({ action: "setDebugStage", stage: parseInt(e.target.value) })
            }
          />
        </Value>
      </Option>
      <Option>
        <Label htmlFor="disableIs">Disable IS:</Label>
        <Input
          id="disableIs"
          type="checkbox"
          checked={pipelineState.spec.disableInsertableStreams}
          onChange={(_) => dispatchPostProc({ action: "toggleDisableInsertableStreams" })}
        />
      </Option>
      <Option>
        <Button onClick={(_) => toggleRecording()} small variant={"outline"}>
          {recordToFile ? "Stop Recording" : "Record"}
        </Button>
      </Option>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.overlayPrimary};
  color: ${({ theme }) => theme.color.text.primary};
  padding: 1em;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
`;
Container.displayName = "Container";

const Title = styled.div`
  font-size: 1em;
  align-self: center;
`;
Title.displayName = "Title";

const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
Option.displayName = "Option";

const Input = styled.input``;
const Slider = styled.input`
  width: 100%;
`;

const Label = styled.label``;

const Value = styled.div`
  width: auto;
`;

const ClickableText = styled.div`
  margin-left: 0.1rem;
  cursor: pointer;
`;
ClickableText.displayName = "ClickableText";

const Select = styled.select`
  max-width: 80%;
`;
Select.displayName = "Select";
