import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import {
  JoinableRoam,
  MemberRoam,
  TeleRoomRoam,
  VisitorRoam,
} from "../../../../shared/Models/AccessibleRoams.js";
import { AccessLink } from "../../../../shared/Models/AccessLinks/AccessLink.js";
import { LINK_CONFIGURATIONS_BY_TYPE } from "../../../../shared/Models/AccessLinks/LinkConfiguration.js";
import { createSelector } from "../../../helpers/redux.js";
import { updateInternal, WantHave } from "../../../store/clientConnectionHelpers.js";
import { RootState } from "../../../store/reducers.js";

export interface AccessibleRoamsPayload {
  memberRoams: MemberRoam[];
  joinableRoams: JoinableRoam[];
  visitorRoams: VisitorRoam[];
  teleRoomRoams: TeleRoomRoam[];
}

export interface AccessLinkPayload {
  accessLink?: AccessLink;
  accessLinkError?: string;
}

const slice = createSlice({
  name: "anyWorld",
  initialState: {
    // AnyWorld
    // follows patterns in docs/clientConnections.md
    internalWantAnyWorldConnected: false,
    internalHaveAnyWorldConnected: false,

    anyWorldConnectionRetryTime: undefined as string | undefined,

    memberRoams: new Array<MemberRoam>(),
    joinableRoams: new Array<JoinableRoam>(),
    visitorRoams: new Array<VisitorRoam>(),
    teleRoomRoams: new Array<TeleRoomRoam>(),
    accessLink: undefined as AccessLink | undefined,
    accessLinkError: undefined as string | undefined,
  },
  reducers: {
    // *** AnyWorld ***

    // follows patterns in docs/clientConnections.md
    // NOTE: Could be merged into the internal action, this is basically just a shell.
    setWantAnyWorldConnected: (state, action: PayloadAction<boolean>) => {},
    /**
     * Updates the want/have state for AnyWorld connection status.
     */
    setInternalAnyWorldConnected: (state, action: PayloadAction<WantHave<boolean>>) => {
      updateInternal(state, "AnyWorldConnected", action.payload);
    },

    setAnyWorldConnectionRetryTime: (state, action: PayloadAction<string | undefined>) => {
      state.anyWorldConnectionRetryTime = action.payload;
    },
    setAccessibleRoams: (state, action: PayloadAction<AccessibleRoamsPayload>) => {
      const { memberRoams, joinableRoams, visitorRoams, teleRoomRoams } = action.payload;
      state.memberRoams = memberRoams;
      state.joinableRoams = joinableRoams;
      state.visitorRoams = visitorRoams;
      state.teleRoomRoams = teleRoomRoams;
    },
    /**
     * Action that is the main entry point for handling an accessLinkQuery.
     * This action should be dispatched, and then setAccessLinkQuerySaga in
     * anyWorldSaga will perform some logic and either immediately act upon
     * the accessLinkQuery without having to set it on a state, or else it will
     * dispatch AwayCacheActions.setAccessLinkQuery to set it on a state and go
     * through the AccessLinkLandingPage flow.
     */
    setAccessLinkQuery: (state, action: PayloadAction<string>) => {},
    setAccessLink: (state, action: PayloadAction<AccessLinkPayload>) => {
      const { accessLink, accessLinkError } = action.payload;
      state.accessLink = accessLink;
      state.accessLinkError = accessLinkError;
    },
    clearAccessLink: (state) => {
      state.accessLink = undefined;
      state.accessLinkError = undefined;
    },
    refreshDerivedAuthorization: (_) => {},
  },
});
export const { actions, reducer } = slice;
export const AnyWorldActions = actions;

const selectSlice = (state: RootState) => state.anyWorld.anyWorld;
export const selectors = {
  selectMemberRoams: (state: RootState) => selectSlice(state).memberRoams,
  selectJoinableRoams: (state: RootState) => selectSlice(state).joinableRoams,
  selectVisitorRoams: (state: RootState) => selectSlice(state).visitorRoams,
  selectTeleRoomRoams: (state: RootState) => selectSlice(state).teleRoomRoams,
  selectAccessLink: (state: RootState) => selectSlice(state).accessLink,
  selectAccessLinkConfiguration: (state: RootState) => {
    const accessLink = selectSlice(state).accessLink;
    if (!accessLink) {
      return undefined;
    }
    return LINK_CONFIGURATIONS_BY_TYPE[accessLink.linkType];
  },
  // follows patterns in docs/clientConnections.md
  selectInternalWantAnyWorldConnected: (state: RootState) =>
    selectSlice(state).internalWantAnyWorldConnected,
  selectInternalHaveAnyWorldConnected: (state: RootState) =>
    selectSlice(state).internalHaveAnyWorldConnected,
  selectAnyWorldConnectionRetryTime: (state: RootState) =>
    selectSlice(state).anyWorldConnectionRetryTime,
  selectRelationshipToRoam: defaultMemoize(
    (roamId?: number) =>
      createSelector(selectSlice, (slice) => {
        if (!roamId) {
          return "none";
        } else if (slice.teleRoomRoams.find((tr) => tr.roamId === roamId)) {
          return "member";
        } else if (slice.memberRoams.find((mr) => mr.roamId === roamId)) {
          return "member";
        } else if (slice.joinableRoams.find((jr) => jr.roamId === roamId)) {
          return "joinable";
        } else if (slice.visitorRoams.find((vr) => vr.roamId === roamId)) {
          return "visitor";
        } else if (slice.accessLink?.roamId === roamId) {
          return "visitor";
        } else {
          return "none";
        }
      }),
    { maxSize: 100 }
  ),
};
export const AnyWorldSelectors = selectors;
