import { z } from "zod";
import { ChatAddress } from "./ChatAddress.js";
import { Person } from "./Person.js";
import { Roam } from "./Roam.js";
import { NonMemberAccount } from "./accounts/Account.js";
import { numberId } from "./zodTypes.js";

/**
 * Unique identifier for an access badge.
 *
 * WARNING: The DerivedAccessBadge model is included on the AccessBadgeGrantedContent on chat
 * messages, so all changes must be backwards compatible, and all new fields must be optional.
 */
export const AccessBadgeKey = z.object({
  /** The person ID of the granter. */
  granterPersonId: numberId(),
  /** The email address of the grantee. */
  granteeEmail: z.string(),
  /** The account that the grantee is given access to. */
  accountId: z.number(),
});
export type AccessBadgeKey = z.infer<typeof AccessBadgeKey>;

/**
 * An access badge allows a granter to give a grantee access to their account.
 *
 * Grantees have limited access—they more or less cannot do anything without the granter present.
 *
 * This was formerly known as the guest list.
 *
 * WARNING: The DerivedAccessBadge model is included on the AccessBadgeGrantedContent on chat
 * messages, so all changes must be backwards compatible, and all new fields must be optional.
 */
export const AccessBadge = AccessBadgeKey.extend({
  /** Whether or not the grantee has acknowledged receipt of this access badge. */
  acknowledged: z.boolean(),

  /** Whether or not this badge is hidden by the recipient. */
  hidden: z.boolean(),

  /** Whether the grantee has permission to visit the granter. */
  visitPermission: z.boolean().optional(),
});
export type AccessBadge = z.infer<typeof AccessBadge>;

/**
 * An `AccessBadge` with additional derived fields.
 *
 * Derived badges are intended for client usage, where the client may need additional data to
 * correctly render a badge or manage state.
 * AKA, a fat badge.
 *
 * WARNING: The DerivedAccessBadge model is included on the AccessBadgeGrantedContent on chat
 * messages, so all changes must be backwards compatible, and all new fields must be optional.
 */
export const DerivedAccessBadge = AccessBadge.extend({
  /**
   * The IDs of the roams that this badge grants access to.
   *
   * Equivalent to the roam IDs under the access badge's account.
   */
  roamIds: z.number().array(),

  /**
   * An alias email of the grantee.
   *
   * Intended to match the email address used to query this access badge, if any.
   * Otherwise, this will be the same value as the `granteeEmail`.
   *
   * For example, if "wonder.inc" and "ro.am" are alias domains, the client might request
   * access badges for grantee "vple@wonder.inc".
   * It could then receive a derived access badge with:
   *    granteeEmail: "vple@ro.am"
   *    granteeAliasEmail: "vple@wonder.inc"
   *
   * This does force clients relying on this field to do some object management (which grantee did
   * I request), but this is not expected to be a problem because clients will typically be asking
   * about the email for a specific occupant (and there won't be multiple occupants with aliased
   * emails).
   *
   * The main point of this is to enable redux state management.
   * If I am trying to display all badges granted to "vple@wonder.inc", it would help to recognize
   * that this aliases to "vple@ro.am".
   * However, the grantee itself is "vple@ro.am" and so redux selectors would fail when filtering
   * by "vple@wonder.inc".
   */
  granteeAliasEmail: z.string(),

  // Probably don't need the whole person but we have it already.
  // Mainly need name & photo.
  granter: Person,
  granterAccount: NonMemberAccount,
  granterHomeRoam: Roam.optional(),

  /**
   * The grantee `Person`, if one exists.
   *
   * This is a `Person` that matches, allowing aliases, the `granteeEmail`.
   *
   * Note that the person's email does not have to match `granteeEmail` or `granteeAliasEmail`.
   * For example, assuming these emails are all aliased, I could:
   *
   *  - Have a person whose email is "vple@domain1.com".
   *  - Grant access to "vple@domain2.com".
   *  - Query access badges that match "vple@domain3.com".
   *
   * Unless you are doing something that should actually be tied to a person, the person's email
   * should likely not be used.
   */
  // The grantee, if one exists.
  // The email address of this person has no guarantee
  grantee: Person.optional(),

  /**
   * Even if the grantee `Person` doesn't exist, we can still have an address for the grantee, e.g.,
   * if the grantee is a Guest rather than a Member. Although this field is optional, it should
   * generally be provided---if no address exists for the grantee email at the time of derivation,
   * it will be created.
   */
  granteeAddress: ChatAddress.optional(),

  /**
   * Whether or not this badge is hidden. Hidden badges are still shown in the guest settings, but
   * are marked as hidden.
   *
   * Note that this field should only be set if the client is the *grantee* of the badge; the
   * granter should NOT be able to see the hidden status.
   */
  hidden: z.boolean().optional(),
});
export type DerivedAccessBadge = z.infer<typeof DerivedAccessBadge>;
