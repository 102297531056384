export enum FeatureControlBitFlags {
  disableConfidential = 1,
  disableGroupChat = 2,
  disableDMs = 4,
  disableExternalChat = 8,
}

export const intToFeatureControlBitmask = (num: number | undefined): FeatureControlBitFlags[] => {
  if (num === undefined) {
    return [];
  }

  const bitmask: FeatureControlBitFlags[] = [];

  for (const flag of Object.keys(FeatureControlBitFlags)) {
    const value = Number.parseInt(flag, 10);
    // eslint-disable-next-line no-bitwise
    if (num & value) {
      bitmask.push(value);
    }
  }

  return bitmask;
};

export const featureControlBitmaskToInt = (
  bitmask: FeatureControlBitFlags[]
): number | undefined => {
  let num = 0;

  for (const flag of bitmask) {
    // eslint-disable-next-line no-bitwise
    num |= flag;
  }

  if (num === 0) {
    return undefined;
  }
  return num;
};

export const isFeatureControlFlagOn = (
  bitmask: number,
  flagToCheck: FeatureControlBitFlags
): boolean => {
  // eslint-disable-next-line no-bitwise,@typescript-eslint/no-unsafe-enum-comparison
  return (bitmask & flagToCheck) === flagToCheck;
};
