import { Buffer } from "buffer";
import { parse, stringify } from "uuid";
import { baseRoamURL } from "../../shared/api/http.js";
import { AccessLink } from "../Models/AccessLinks/AccessLink.js";
import { AccessLinkId } from "../Models/AccessLinks/AccessLinkId.js";
import { AccessLinkStatus } from "../Models/AccessLinks/AccessLinkStatus.js";
import { LINK_CODES_BY_CODE, LINK_CODES_BY_TYPE } from "../Models/AccessLinks/LinkCode.js";
import { LINK_CONFIGURATIONS_BY_TYPE } from "../Models/AccessLinks/LinkConfiguration.js";
import { LinkType } from "../Models/AccessLinks/LinkType.js";
import { MeetingLink } from "../Models/AccessLinks/MeetingLink.js";
import { logger } from "../infra/logger.js";

// cspell:disable-next-line
export const accessLinkIdRegex = /(?<type>[cdopsu])\/(?<id>[\w=-]{2,40})\/(?<code>[\w=-]{2,40})/;

// Regex for the last 2 parts of an access link query.
const accessLinkQueryIdCodeRegex = /(?<id>[\w=-]{2,40})\/(?<code>[\w=-]{2,40})/;

// Amount of time (in seconds) that someone may arrive prior to the start of an
// access link meeting and still enter (assuming their host is available)
export const accessLinkMeetingEarlyArrivalGracePeriod = 5 * 60;

export const parseTypeCode = (typeCode: string | null | undefined): LinkType | undefined => {
  if (!typeCode) {
    return;
  }

  const type = LINK_CODES_BY_CODE[typeCode];
  if (!type) {
    return;
  }

  return type.type;
};

// Takes a linkQuery and, if it is for a link type that provides authorization, returns it. If a
// full URL rather than linkQuery is supplied, properly transforms it to a linkQuery before returning
// it. Otherwise returns undefined.
export const linkAsQueryWithAuthorization = (linkQuery?: string): string | undefined => {
  if (linkQuery) {
    // This handles the case where a full URL is supplied where just a link query is expected. The
    // only case we know of where this was happening was a test, but just to be extra sure we don't
    // break anything, keep this if here and if no errors are logged by 10/15/23, it is safe to
    // remove.
    if (!isValidAccessLinkQuery(linkQuery)) {
      linkQuery = accessLinkQueryFromUrl(linkQuery, baseRoamURL);
      if (!isValidAccessLinkQuery(linkQuery)) {
        return undefined;
      }
      logger.error(`got full url where access link query was expected: ${linkQuery}`);
    }
    const typeCode = parseTypeCodeFromQuery(linkQuery);
    if (typeCode && accessLinkTypeProvidesAuthentication(typeCode)) {
      return linkQuery;
    }
  }
  return undefined;
};

export const parseTypeCodeFromQuery = (query: string | null | undefined): LinkType | undefined => {
  if (!query) {
    return;
  }
  const [typeCode] = query.split("/", 1);
  const linkType = parseTypeCode(typeCode);
  return linkType;
};

export const isValidAccessLinkQuery = (check: string | null | undefined): boolean => {
  if (!check) {
    return false;
  }

  const [typeCode] = check.split("/", 1);
  if (!typeCode) {
    return false;
  }
  const type = parseTypeCode(typeCode);
  if (!type) {
    return false;
  }

  const rest = check.substring(typeCode.length);
  const matchRest = accessLinkQueryIdCodeRegex.exec(rest);
  if (!matchRest) {
    return false;
  }

  return true;
};

export const isAccessLinkIdFormat = (check: string | null | undefined): boolean => {
  if (check) {
    const matchUrl = accessLinkIdRegex.exec(check);
    return !!matchUrl;
  }
  return false;
};

export const parseIdStrFromUrl = (url: string): string | undefined => {
  const matchUrl = accessLinkIdRegex.exec(url);
  if (!matchUrl) return;
  return matchUrl.groups?.["id"];
};

export const getAccessLinkStatus = (accessLink: AccessLink, now?: Date): AccessLinkStatus => {
  const time = now || new Date();
  if (accessLink.linkType === "meeting") {
    if (accessLink.start && accessLink.start > time) return "Pending";
    if (accessLink.end && accessLink.end <= time) return "Expired";
  }
  return "Active";
};

export const isAccessLinkActiveOrSoonToBeActive = (accessLink: AccessLink, now?: Date): boolean => {
  const time = now || new Date();

  const linkStatus = getAccessLinkStatus(accessLink, time);
  if (linkStatus === "Active") {
    return true;
  }

  if (linkStatus === "Pending" && accessLink.linkType === "meeting" && accessLink.start) {
    const millisUntilStart = accessLink.start
      ? Math.max(0, accessLink.start.getTime() - time.getTime())
      : 0;
    return millisUntilStart / 1000 <= accessLinkMeetingEarlyArrivalGracePeriod;
  }

  return false;
};

/**
 * Deserializes an `AccessLinkId` from a [url].
 *
 * [url] is expected to start with [baseUrl].
 * The default here (baseRoamURL) should be fine unless you're client-side.
 *
 * NOTE: This delegates to `accessLinkQueryFromUrl`, which currently only checks for meeting links.
 */
export const accessLinkIdFromUrl = (
  url: string,
  baseUrl: string = baseRoamURL
): AccessLinkId | undefined => {
  const query = accessLinkQueryFromUrl(url, baseUrl);
  if (!query) {
    return undefined;
  }
  return queryToId(query);
};

export const accessLinkQueryFromUrl = (url: string, baseUrl: string): string | undefined => {
  if (!url.startsWith(`${baseUrl}/r/#/p`) && !url.startsWith(`${baseUrl}/r/#/d`)) {
    return undefined;
  }
  const maybeId = url.replace(`${baseUrl}/r/#/`, "");
  return isAccessLinkIdFormat(maybeId) ? maybeId : undefined;
};

/**
 * Decodes an access link [query] string into an `AccessLinkId`.
 * If [query] is not a valid access link query, returns undefined.
 */
export const queryToId = (query: string): AccessLinkId | undefined => {
  if (!isValidAccessLinkQuery(query)) {
    return undefined;
  }

  const [typeCode, encodedId, encodedCode] = query.split("/", 3);
  if (!encodedId || !encodedCode) {
    return undefined;
  }
  const linkType: LinkType | undefined = parseTypeCode(typeCode);
  if (!linkType) return undefined;
  const id = stringify(Buffer.from(encodedId, "base64"));
  const code = encodedCode;

  return {
    linkType,
    id,
    code,
  };
};

export const accessLinkTypeProvidesAuthentication = (linkType: LinkType) => {
  const accessLinkConfiguration = LINK_CONFIGURATIONS_BY_TYPE[linkType];
  return accessLinkConfiguration?.providesAuthentication ?? false;
};

/**
 * Whether a link is for a calendar event that someone started creating but hasn't saved.
 *
 * NOTE:
 *    Not going to rename this now, but think of this more as "incomplete" rather than
 *    "unsaved."
 *    In particular, some providers may let you save an event before some of these details are
 *    determined.
 */
export const isUnsavedEventLink = (
  link: Pick<MeetingLink, "extCalendarProviderId" | "linkName" | "start" | "end">
): boolean => {
  return (
    link.extCalendarProviderId === "google" &&
    link.linkName === "Google Calendar Meeting (not synced)" &&
    !link.start?.getTime() &&
    !link.end?.getTime()
  );
};

export const bufferToAccessLinkBase64 = (buffer: Uint8Array) => {
  // These will always be 16 bytes, which turns into 24 characters, of which the last two
  // are always padding, which we remove
  return Buffer.from(buffer)
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
};

export const idsToQuery = ({ linkType, id, code }: AccessLinkId): string | undefined => {
  const typeCode = LINK_CODES_BY_TYPE[linkType]?.code;
  if (!typeCode) return undefined;
  const idBuffer = parse(id);
  if (!idBuffer) return undefined;
  const idStr = bufferToAccessLinkBase64(new Uint8Array(idBuffer));
  const codeBuffer = Buffer.from(code, "base64");
  if (!codeBuffer) return undefined;
  const codeStr = bufferToAccessLinkBase64(codeBuffer);
  return `${typeCode}/${idStr}/${codeStr}`;
};

export const idsToUrl = (
  { linkType, id, code }: AccessLinkId,
  clientVersion?: "alpha/"
): string | undefined => {
  const query = idsToQuery({ linkType, id, code });
  if (!query) return undefined;
  const baseLinkUrl = baseRoamURL;
  return `${baseLinkUrl}/r/${clientVersion ?? ""}#/${query}`;
};

/**
 * @deprecated See queryToId.
 */
export const queryToIds = (url: string): AccessLinkId | undefined => {
  const match = accessLinkIdRegex.exec(url);
  if (!match) return undefined;
  const typeCode = match.groups?.type;
  const idStr = match.groups?.id;
  const codeStr = match.groups?.code;
  if (!typeCode || !idStr || !codeStr) return undefined;
  const linkType: LinkType | undefined = parseTypeCode(typeCode);
  if (!linkType) return undefined;
  const idBuffer = Buffer.from(idStr, "base64");
  if (!idBuffer) return undefined;
  const id = stringify(idBuffer);
  if (!id) return undefined;
  const code = codeStr;
  return {
    linkType,
    id,
    code,
  };
};
