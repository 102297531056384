import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RoomType } from "../../../shared/Models/Room.js";
import { RootState } from "../../store/reducers.js";
import { TranscriptionChunk } from "../types/Transcription.js";

const MAX_CHUNKS = 1000;

export interface ReceiveTranscriptionChunkPayload {
  chunk: TranscriptionChunk;
}

export interface StartCaptionsUsagePayload {
  key: string;
  captionsStarted: number;
}

export interface FinishCaptionsUsagePayload {
  key: string;
  roomType?: RoomType;
}

const slice = createSlice({
  name: "closedCaption",
  initialState: {
    open: false,
    chunks: [] as TranscriptionChunk[],
    conversations: {} as Record<string, number>,
    captionsStarted: undefined as number | undefined,
  },
  reducers: {
    addTranscriptionChunk: (state, action: PayloadAction<ReceiveTranscriptionChunkPayload>) => {
      // Look for the most recent chunk by the same occupant.
      // Replace it if it was marked as "partial".
      // Else, append the new chunk.
      const next = action.payload.chunk;
      const recent = state.chunks // TODO: Use findLast when upgraded to ES2023
        .slice()
        .reverse()
        .find((last) => last.occupantId === next.occupantId);
      if (recent && recent.partial) {
        Object.assign(recent, next);
      } else if (recent && recent.text === next.text && recent.partial === next.partial) {
        // Skip duplicate captions.
        // This can happen in the theater, where the user has a direct conversation
        // on stage and a different replica conversation when going to the audience.
        // This exposes the user to a race where they may receive the same caption
        // to both conversations. To address that, take the easy way out and compare
        // the text & partial for equality. Note that this will only apply to final
        // captions, since partial captions will fall into the case above.
        // If this incorrectly collapses actually-duplicate utterances, we can add
        // an ID to each transcription chunk at that point.
      } else {
        state.chunks.push(next);
      }
      if (state.chunks.length > MAX_CHUNKS) {
        state.chunks.shift();
      }
    },
    setCaptionsEnabled: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    clearClosedCaption: (state) => {
      state.chunks = [];
    },
    loadCaptionsEnabled: () => {},
    startCaptionsUsage: (state, action: PayloadAction<StartCaptionsUsagePayload>) => {
      const { key, captionsStarted } = action.payload;
      if (Object.keys(state.conversations).length === 0) {
        state.captionsStarted = captionsStarted;
      }
      state.conversations[key] = captionsStarted;
    },
    finishCaptionsUsage: (state, action: PayloadAction<FinishCaptionsUsagePayload>) => {
      const { key } = action.payload;
      delete state.conversations[key];
    },
    resetCaptionsUsage: (state) => {
      state.captionsStarted = undefined;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.transcription.closedCaption;
export const selectors = {
  selectClosedCaptionIsOpen: (state: RootState) => {
    return selectSlice(state).open;
  },
  selectClosedCaption: (state: RootState) => selectSlice(state).chunks,
  selectClosedCaptionHasChunks: (state: RootState) => selectSlice(state).chunks.length > 0,
  selectConversations: (state: RootState) => selectSlice(state).conversations,
  selectCaptionsStarted: (state: RootState) => selectSlice(state).captionsStarted,
};

export const ClosedCaptionActions = actions;
export const ClosedCaptionSelectors = selectors;
