import {
  createEntityAdapter,
  createSlice,
  Draft,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { UnreachableError } from "../../../../shared/helpers/UnreachableError.js";
import {
  AccessMode,
  AccessModeDetails,
  Room,
  RoomSeat,
  RoomType,
  Stage,
} from "../../../../shared/Models/Room.js";
import * as ra from "../../../../shared/Models/RoomActions.js";
import { ClientRect } from "../../../helpers/dom.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

export interface RoomActionPayload {
  floorId: number;
  roomAction: ra.RoomAction;
  windowKey: WindowKey; // for displaying error toasts
}

export interface SetRoomAccessModePayload {
  floorId: number;
  roomId: number;
  accessMode: AccessMode;
  accessModeDetails?: AccessModeDetails;
  windowKey: WindowKey;
}

export interface AllFloorRoomsPayload {
  floorId: number;
  rooms: Room[];
}

export interface NewRoomPayload {
  roomType: RoomType;
  floorId: number;
  seatAssignments?: RoomSeat[];
  noDefaultName?: boolean;
  disableCanarySFU?: boolean;
  windowKey: WindowKey;
  name?: string;
}

export interface MoveOrKnockPayload {
  floorId: number;
  roomId?: number;
  sectionNumber?: number;
  positionNumber?: number;
}

export interface ClickSeatPayload {
  floorId: number;
  roomId?: number; // undefined for reception
  positionNumber: number;
  sectionNumber?: number;
  windowKey: WindowKey;
}

export interface ClickRoomPayload {
  floorId: number;
  roomId: number;
}

export interface ClickReceptionPayload {
  floorId: number;
}

export interface RoomRect {
  roomId: number;
  clientRect: ClientRect;
}

const adapter = createEntityAdapter<Room>();

const applyAction = (state: Draft<EntityState<Room>>, action: ra.RoomAction) => {
  switch (action.actionType) {
    case "UPDATE_ROOM": {
      // Use setOne instead of upsertOne because upsertOne won't overwrite properties that are
      // set in the existing room, but undefined in the upserted room causing an incorrect state.
      adapter.setOne(state, action.room);
      break;
    }
    case "DELETE_ROOM": {
      break;
    }
    case "ADD_ROOM": {
      // Don't add the rooms until they have a valid id. Otherwise it'll
      // look like two rooms were added.
      if (action.room.id > 0) adapter.upsertOne(state, action.room);
      break;
    }
    case "ASSIGN_SEAT": {
      break;
    }
    case "UNASSIGN_SEAT": {
      break;
    }
    case "SET_STAGE_MODE": {
      const stage = state.entities[action.roomId]?.stage;
      if (stage) {
        const newStage: Stage = {
          ...stage,
          stageMode: action.stageMode,
          backstageToggled: action.backstageToggled,
        };
        adapter.updateOne(state, {
          id: action.roomId,
          changes: { stage: newStage },
        });
      }
      break;
    }
    case "SET_QA_MODE": {
      const stage = state.entities[action.roomId]?.stage;
      if (stage) {
        const newStage: Stage = {
          ...stage,
          QAMode: action.QAMode,
        };
        adapter.updateOne(state, {
          id: action.roomId,
          changes: { stage: newStage },
        });
        break;
      }
      break;
    }
    case "UPDATE_FLOOR_ROOMS": {
      // Do nothing.
      break;
    }
    default: {
      throw new UnreachableError(action);
    }
  }
};

const slice = createSlice({
  name: "room",
  initialState: {
    rooms: adapter.getInitialState(),
    editingRoomId: undefined as number | undefined,

    // Map rooms report their location when they're the room you're in
    currentRoomRect: undefined as RoomRect | undefined,
  },

  reducers: {
    // UI-initiated actions
    clientAction: (state, action: PayloadAction<RoomActionPayload>) => {
      applyAction(state.rooms, action.payload.roomAction);
    },

    setRoomAccessMode: (state, action: PayloadAction<SetRoomAccessModePayload>) => {},
    newRoom: (state, action: PayloadAction<NewRoomPayload>) => {},

    moveOrKnock: (state, action: PayloadAction<MoveOrKnockPayload>) => {},
    clickSeat: (state, action: PayloadAction<ClickSeatPayload>) => {},
    clickRoom: (state, action: PayloadAction<ClickRoomPayload>) => {},
    clickReception: (state, action: PayloadAction<ClickReceptionPayload>) => {},

    setCurrentRoomRect: (state, action: PayloadAction<RoomRect>) => {
      state.currentRoomRect = action.payload;
    },

    // Editing
    setEditingRoomId: (state, action: PayloadAction<number>) => {
      state.editingRoomId = action.payload;
    },
    clearEditingRoomId: (state) => {
      state.editingRoomId = undefined;
    },

    // Server messages
    requestFloor: (state, action: PayloadAction<number>) => {},
    setAllFloorRooms: (state, action: PayloadAction<AllFloorRoomsPayload>) => {
      const { floorId, rooms } = action.payload;
      const srvRoomIds = new Set(rooms.map((r) => r.id));
      const deletedRoomIds = [];
      for (const roomId of state.rooms.ids) {
        const room = state.rooms.entities[roomId];
        if (room?.floorId === floorId && !srvRoomIds.has(room.id)) {
          deletedRoomIds.push(roomId);
        }
      }
      adapter.removeMany(state.rooms, deletedRoomIds);
      adapter.setMany(state.rooms, rooms);
    },
    setRoom: (state, action: PayloadAction<Room>) => {
      // Use setOne instead of upsertOne because upsertOne won't overwrite properties that are
      // set in the existing room, but undefined in the upserted room causing an incorrect state.
      adapter.setOne(state.rooms, action.payload);
    },
    requestRoomsForAccount: (state, action: PayloadAction<number>) => {},
  },
});

export const { actions, reducer } = slice;

const selectRooms = (state: RootState) => state.section.room.rooms;
const adapterSelectors = adapter.getSelectors();
const roomSelectors = adapter.getSelectors(selectRooms);

export const selectors = {
  ...roomSelectors,
  selectById: (id?: number) => (state: RootState) =>
    id ? adapterSelectors.selectById(selectRooms(state), id) : undefined,
  selectByIds: defaultMemoize(
    (roomIds: number[]) =>
      createDeepEqualSelector(selectRooms, (rooms) => {
        const roomsById: { [roomId: number]: Room } = {};
        for (const roomId of roomIds) {
          const room = adapterSelectors.selectById(rooms, roomId);
          if (room) {
            roomsById[roomId] = room;
          }
        }
        return roomsById;
      }),
    { equalityCheck: equal }
  ),
  selectByFloorId: defaultMemoize(
    (floorId?: number) =>
      createDeepEqualSelector(selectRooms, (rooms) =>
        adapterSelectors.selectAll(rooms).filter((room) => floorId && room.floorId === floorId)
      ),
    { maxSize: 20 }
  ),
  selectIdsByFloorId: defaultMemoize(
    (floorId?: number) =>
      createDeepEqualSelector(selectRooms, (rooms) =>
        adapterSelectors
          .selectAll(rooms)
          .filter((room) => floorId && room.floorId === floorId)
          .map((room) => room.id)
      ),
    { maxSize: 20 }
  ),
  selectByFloorIds: defaultMemoize((floorIds: number[]) =>
    createDeepEqualSelector(selectRooms, (rooms) => {
      const floorIdSet = new Set(floorIds);
      return adapterSelectors.selectAll(rooms).filter((room) => floorIdSet.has(room.floorId));
    })
  ),
  selectEditingRoomId: (state: RootState): number | undefined => state.section.room.editingRoomId,
  selectCurrentRoomRect: (state: RootState): RoomRect | undefined =>
    state.section.room.currentRoomRect,
  selectRoomOnFloorHasActivity: defaultMemoize(
    (floorId?: number) =>
      createDeepEqualSelector(selectRooms, (rooms) => {
        const roomsOnFloor = adapterSelectors
          .selectAll(rooms)
          .filter((room) => floorId && room.floorId === floorId);
        return {
          active:
            roomsOnFloor.some((r) =>
              (r.assignedSeats ?? []).some((a) => a.willReturn !== undefined)
            ) || roomsOnFloor.some((r) => !!r.enterRoamRequests?.length),
          hasEphemeral: roomsOnFloor.some((r) => r.ephemeralRoom),
        };
      }),
    { maxSize: 20 }
  ),
};

export const RoomSelectors = selectors;
export const RoomActions = actions;
