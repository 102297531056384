import { z } from "zod";

/**
 * Represents a specific room.
 *
 * TODO: Do auditoriums need a separate type?
 */
export const AccessLinkRoomLocation = z.object({
  type: z.literal("room"),
  roamId: z.number(),
  sectionId: z.string(),
  floorId: z.number(),
  roomId: z.number(),
});
export type AccessLinkRoomLocation = z.infer<typeof AccessLinkRoomLocation>;
