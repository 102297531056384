/* cspell:ignore wakeup */
import { dumpMediaStream } from "../../../shared/helpers/dumpMediaStream.js";
import { logger } from "../../../shared/infra/logger.js";
import { ClientContainerInfo } from "../../injection/IClientContainer.js";
import { IRedux } from "../../injection/redux/IRedux.js";
import {
  AudioSourceSpec,
  IAudioPipelineLauncher,
  PostProcAudioSpec,
} from "../interfaces/IAudioPipelineLauncher.js";
import { IAvDevices } from "../interfaces/IAvDevices.js";
import { OnNewAudioStreamsCallback } from "../interfaces/IAvPipelines.js";
import { IPostProcAudioPipeline } from "../interfaces/IPostProcAudioPipeline.js";
import { IRawAudioStreamManager, IRawStream } from "../interfaces/IRawStreamManager.js";
import { IVoiceDetector } from "../interfaces/IVoiceDetector.js";

interface Injected {
  avDevices(): IAvDevices;
  info(): ClientContainerInfo;
  redux(): IRedux;
  rawAudioStreamManager(): IRawAudioStreamManager;
}

/*
 * An AudioPipelineLauncher takes a source (as identified by AvDevices) and a specification for
 * desired postprocessing steps, creates and configures an IVideoPipeline to handle those steps, and
 * then gets samples from the source through the pipeline and into a MediaStream that can, e.g., be
 * sent to the SFU or saved locally.
 *
 * AudioPipelineLauncher gets its instructions from the AudioSourceSpec and PostProcAudioSpec supplied
 * to setWant(). It is responsible for handling changes to these as well as pipelines failures and
 * changes gracefully (for example, some configuration changes require recreating or using an
 * entirely different pipeline, whereas others are just reconfigurations
 * of the existing pipeline.)
 *
 * The output of the pipeline is a MediaStream, which is supplied by calling the OnNewStreamsCallback
 * supplied to the AudioPipelineLauncher in its constructor. Users of AudioPipelineLauncher must be prepared
 * for this callback to be called at any time with a replacement stream and act accordingly.
 *
 */
export class AudioPipelineLauncher implements IAudioPipelineLauncher {
  private closed = false;
  private closedPromise: Promise<void>;
  private closedPromiseResolve: () => void = () => {};

  private wantSourceSpec: AudioSourceSpec | undefined;
  private wantPostProcSpec: PostProcAudioSpec | undefined;
  private wakeupLoopCb: (() => void) | undefined;

  private lastPostProcStream: MediaStream | undefined;

  private rawStream: IRawStream | undefined;
  private rawStreamClone: IRawStream | undefined;
  private unbindRawStreamEnded: (() => void) | undefined;
  private unbindRawStreamCloneEnded: (() => void) | undefined;

  private deviceChangeListener: () => void;

  constructor(
    private container: Injected,
    private onNewStreams: OnNewAudioStreamsCallback,
    private onClose: () => void,
    private postProcPipeline: IPostProcAudioPipeline
  ) {
    this.closedPromise = new Promise((resolve) => {
      this.closedPromiseResolve = resolve;
    });

    (async () => {
      while (!this.closed) {
        try {
          await this.loop();
        } catch (err) {
          logger.warn({ err }, `AudioPipeline: Error running audio pipeline loop`);
          // Wait a bit, then try to restart the pipeline
          await new Promise((resolve) => setTimeout(resolve, 1_000));
        }
      }
    })().catch(() => {});

    this.deviceChangeListener = () => this.wakeupLoopCb?.();
    this.container.avDevices().on("devicechange", this.deviceChangeListener);
  }

  public setWant(
    rawSpec: AudioSourceSpec | undefined,
    postProcSpec: PostProcAudioSpec | undefined
  ): void {
    this.wantSourceSpec = rawSpec;
    this.wantPostProcSpec = postProcSpec;
    this.wakeupLoopCb?.();
  }

  // loop() is responsible for ensuring that the AudioPipelineLauncher gets the audio stream from
  // wantSourceSpec and performs postprocessing as specified by wantPostProcSpec. As those values
  // change or as changes/errors occur in the source or the pipeline, the loop compares the desired
  // state with the actual state and arranges for any changes needed to bring them into alignment.
  private async loop() {
    let wakeupLoopPromise = Promise.resolve();

    while (!this.closed) {
      await wakeupLoopPromise;
      // Create a new promise (with a new callback) for the next iteration
      wakeupLoopPromise = new Promise((resolve) => {
        this.wakeupLoopCb = () => resolve();
      });

      if (this.closed) break;

      // Get the specifications that we want (important to do this now because they might change while we're running)
      const wantSourceSpec = this.wantSourceSpec;
      const wantPostProcSpec = this.wantPostProcSpec;
      const mediaDevices = this.container.avDevices().mediaDevices();

      // Indicates whether anything has changed that requires invoking onNewStreams again
      let changed = false;

      let wantDeviceId: string | undefined;
      let expectedGroupId: string | undefined;
      if (wantSourceSpec) {
        // Determine the device ID we want
        wantDeviceId = wantSourceSpec.deviceIds?.find((deviceId) =>
          mediaDevices.some((d) => d.kind === "audioinput" && d.deviceId === deviceId)
        );
        if (!wantDeviceId) {
          switch (wantSourceSpec.fallback) {
            case "default":
              if (mediaDevices.some((d) => d.kind === "audioinput" && d.deviceId === "default")) {
                wantDeviceId = "default";
              }
              break;
            case "last":
              wantDeviceId = wantSourceSpec.deviceIds.at(-1);
              break;
          }
        }
        // Determine the groupId that we expect the device to have
        expectedGroupId = mediaDevices.find(
          (d) => d.deviceId === wantDeviceId && d.kind === "audioinput"
        )?.groupId;
        if (wantDeviceId === "default" && expectedGroupId) {
          // On at least macOS, Chrome doesn't always switch the stream properly when the default changes (e.g. when
          // switching to AirPods)
          // Find the *actual* device (i.e. the non-"default" device that has the expected groupId), and use that if
          // possible
          // NOTE: On Linux, the "default" device is actually managed by the OS and has a different groupId, so this
          // search will find nothing (hence the fallback on wantDeviceId)
          wantDeviceId =
            mediaDevices.find(
              (d) =>
                d.groupId === expectedGroupId &&
                d.kind === "audioinput" &&
                d.deviceId !== "default" &&
                d.deviceId !== "communications"
            )?.deviceId ?? wantDeviceId;
        }
      }

      const rawSettings = this.rawStream?.mediaStream.getAudioTracks()?.[0]?.getSettings();
      if (
        !wantSourceSpec ||
        (wantDeviceId && rawSettings?.deviceId !== wantDeviceId) ||
        (expectedGroupId && rawSettings?.groupId !== expectedGroupId) ||
        this.rawStream?.isClosed() ||
        this.rawStreamClone?.isClosed()
      ) {
        // Either:
        // - We don't want a stream
        // - We don't have the raw stream that we want
        // - The raw stream, if we have one (or its clone, if we have one) is closed
        // Remove the "ended" handlers
        this.unbindRawStreamEnded?.();
        this.unbindRawStreamEnded = undefined;
        this.unbindRawStreamCloneEnded?.();
        this.unbindRawStreamCloneEnded = undefined;
        // Close and discard the references to the streams
        this.rawStream?.close();
        this.rawStream = undefined;
        this.rawStreamClone?.close();
        this.rawStreamClone = undefined;
        // No matter what else happens, things have changed sufficiently to inform the caller
        changed = true;
      }
      let rawError;
      if (!this.rawStream && wantSourceSpec) {
        // We don't have a stream, and we want one
        try {
          this.rawStream = await this.container.rawAudioStreamManager().getStream(wantDeviceId);
          if (this.rawStream.isClosed()) {
            this.rawStream = undefined;
            // Trigger the loop to run again and try to grab a new stream
            this.wakeupLoopCb?.();
            throw new Error("Raw stream obtained was immediately closed");
          }
          // If any of the tracks end, run the loop again
          const endedCb = () => this.wakeupLoopCb?.();
          const tracks = this.rawStream.mediaStream.getTracks();
          tracks.forEach((t) => t.addEventListener("ended", endedCb));
          this.unbindRawStreamEnded = () => {
            tracks.forEach((t) => t.removeEventListener("ended", endedCb));
          };
        } catch (err: any) {
          rawError = err;
        }
        // Whether it failed or succeeded, we need to tell the caller
        changed = true;
      }

      if (this.rawStream && !this.rawStreamClone) {
        // Clone the stream, and unmute the clone
        this.rawStreamClone = this.rawStream.clone();
        this.rawStreamClone.mediaStream.getTracks().forEach((t) => (t.enabled = true));
        // If any of the tracks end, run the loop again
        const endedCb = () => this.wakeupLoopCb?.();
        const tracks = this.rawStreamClone.mediaStream.getTracks();
        tracks.forEach((t) => t.addEventListener("ended", endedCb));
        this.unbindRawStreamCloneEnded = () => {
          tracks.forEach((t) => t.removeEventListener("ended", endedCb));
        };
        changed = true;
      }

      let postProcStream;
      let postProcError;
      try {
        // Update the post-processing getter (using the clone, which is consistently unmuted)
        postProcStream = await this.postProcPipeline.update(
          wantPostProcSpec,
          this.rawStreamClone?.mediaStream
        );
        if (postProcStream !== this.lastPostProcStream) {
          this.lastPostProcStream = postProcStream;
          changed = true;
        }
      } catch (err: any) {
        postProcError = err;
        logger.warn({ err }, `AudioPipeline.loop: error setting up noise cancellation`);
        // We'll just render the audio without noise-cancellation
        changed = true;
      }

      if (changed && !this.closed) {
        try {
          this.onNewStreams({
            rawStream: this.rawStream?.mediaStream,
            rawError,
            postProcStream,
            postProcError,
          });
        } catch (err) {
          logger.warn({ err }, `AudioPipeline.loop: onNewStreams failed with error`);
        }
      }
    }

    this.rawStream?.close();
    this.rawStream = undefined;
    this.rawStreamClone?.close();
    this.rawStream = undefined;
    await this.postProcPipeline.close();
  }

  public voiceDetector(): IVoiceDetector {
    return this.postProcPipeline.voiceDetector;
  }

  public close(): void {
    if (this.closed) return;
    this.closed = true;
    this.wakeupLoopCb?.();
    this.onClose();
    this.container.avDevices().removeListener("devicechange", this.deviceChangeListener);
  }

  public dump(): any {
    return {
      closed: this.closed,
      postProcPipeline: this.postProcPipeline.dump(),
      wantSourceSpec: this.wantSourceSpec,
      wantPostProcSpec: this.wantPostProcSpec,
      lastPostProcStream: this.lastPostProcStream
        ? dumpMediaStream(this.lastPostProcStream)
        : undefined,
      rawStream: this.rawStream ? dumpMediaStream(this.rawStream.mediaStream) : undefined,
      rawStreamClone: this.rawStreamClone
        ? dumpMediaStream(this.rawStreamClone.mediaStream)
        : undefined,
    };
  }
}
