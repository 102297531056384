/* eslint-disable no-restricted-imports */
import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { memoize } from "lodash";
import {
  TeamRoamAccountSummary,
  TeamRoamChatInfo,
  UnansweredNoTeamId,
} from "../../../../shared/Models/TeamRoam.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";
import { updateCurrentAccountTeamRoamChats, updateMyTeamRoamChats } from "./teamRoamSliceHelper.js";

const accountSummaryAdapter = createEntityAdapter<TeamRoamAccountSummary>({
  selectId: (s) => s.accountId,
  sortComparer: (a, b) => {
    // Alphabetic except for the special unanswered / unanswered-no-team "accounts"
    if (a.accountId > 0 && b.accountId > 0) {
      const accountNameA = a.accountName ?? `Account ID: ${a.accountId}`;
      const accountNameB = b.accountName ?? `Account ID: ${b.accountId}`;
      return accountNameA.localeCompare(accountNameB);
    }
    return a.accountId - b.accountId;
  },
});

const teamRoamChatAdapter = createEntityAdapter<TeamRoamChatInfo>({
  selectId: (s) => s.chatId,
});

export interface CurrentAccountTeamRoamChatsPayload {
  accountId: number;
  teamRoamChats: TeamRoamChatInfo[];
}

export interface AddTeamRoamMemberPayload {
  chatId: string;
  memberEmail: string;
}

export interface RemoveTeamRoamMemberPayload {
  chatId: string;
  memberAddressId: string;
}

export interface UpdateMyTeamRoamChatsPayload {
  myAddressIds: string[];
}

export interface MarkAnsweredPayload {
  chatId: string;
  answeredThrough: number;
}

export type AccountNamesById = {
  [accountId: number]: string;
};

const DefaultAccountId = UnansweredNoTeamId;

const initialState = {
  currentAccountId: DefaultAccountId,
  currentAccountChatIds: new Array<string>(),
  accountFilter: "",
  myChatIds: new Array<string>(),
  teamRoamChats: teamRoamChatAdapter.getInitialState(),
  accountSummaries: accountSummaryAdapter.getInitialState(),
  teamRoamEmail: undefined as string | undefined,
};
export type SliceType = typeof initialState;

const slice = createSlice({
  name: "teamRoam",
  initialState,
  reducers: {
    updateAccountSummaries: (state, action: PayloadAction<TeamRoamAccountSummary[]>) => {
      // Use an upsert so later updates don't need the account names.
      accountSummaryAdapter.upsertMany(state.accountSummaries, action.payload);
    },
    // Side-effect of updateAccountSummaries in case names are missing. Don't just reuse
    // updateAccountSummaries - might cause infinite loop.
    updateAccountNames: (state, action: PayloadAction<AccountNamesById>) => {
      const namesById = action.payload;
      const updatedSummaries = new Array<TeamRoamAccountSummary>();
      for (const accountIdStr of Object.keys(namesById)) {
        const accountId = parseInt(accountIdStr);
        const accountName = namesById[accountId];
        const summary = state.accountSummaries.entities[accountId];
        if (summary) {
          updatedSummaries.push({
            ...summary,
            accountName,
          });
        }
      }
      accountSummaryAdapter.upsertMany(state.accountSummaries, updatedSummaries);
    },
    subscribeSummariesAndMyChats: (state) => {},
    unsubscribeSummariesAndMyChats: (state) => {
      state.currentAccountId = DefaultAccountId;
      state.currentAccountChatIds = [];
    },
    setCurrentAccountId: (state, action: PayloadAction<number>) => {
      const accountId = action.payload;
      if (accountId !== state.currentAccountId) {
        state.currentAccountId = action.payload;
        state.currentAccountChatIds = [];
      }
    },
    setAccountFilter: (state, action: PayloadAction<string>) => {
      state.accountFilter = action.payload;
    },
    setTeamRoamChat: (state, action: PayloadAction<TeamRoamChatInfo>) => {
      teamRoamChatAdapter.setOne(state.teamRoamChats, action.payload);
    },
    setTeamRoamChats: (state, action: PayloadAction<TeamRoamChatInfo[]>) => {
      teamRoamChatAdapter.setMany(state.teamRoamChats, action.payload);
    },
    setTeamRoamEmail: (state, action: PayloadAction<string | undefined>) => {
      state.teamRoamEmail = action.payload;
    },
    getTeamRoamChatDetails: (state, action: PayloadAction<string>) => {},
    replaceCurrentTeamRoamChats: (
      state,
      action: PayloadAction<CurrentAccountTeamRoamChatsPayload>
    ) => {
      const { teamRoamChats, accountId } = action.payload;
      teamRoamChatAdapter.setMany(state.teamRoamChats, teamRoamChats);
      if (accountId === state.currentAccountId) {
        updateCurrentAccountTeamRoamChats(state, teamRoamChats);
      }
    },
    updateMyTeamRoamChats: (state, action: PayloadAction<UpdateMyTeamRoamChatsPayload>) => {
      const { myAddressIds } = action.payload;
      updateMyTeamRoamChats(state, myAddressIds);
    },
    addMemberToTeam: (state, action: PayloadAction<AddTeamRoamMemberPayload>) => {},
    removeMemberFromTeam: (state, action: PayloadAction<RemoveTeamRoamMemberPayload>) => {},
    markChatAnswered: (state, action: PayloadAction<MarkAnsweredPayload>) => {},
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.teamRoam;
const selectChatSlice = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.chat.chat
);
const accountSummarySelectors = accountSummaryAdapter.getSelectors();
const teamRoamChatSelector = teamRoamChatAdapter.getSelectors();
export const selectors = {
  selectFilteredAccountIds: createDeepEqualSelector(selectSlice, (slice) => {
    const allAccountIds = accountSummarySelectors.selectIds(slice.accountSummaries) as number[];
    if (slice.accountFilter.length === 0) {
      return allAccountIds;
    }
    const filter = slice.accountFilter.toLowerCase();
    const filteredAccountIds = allAccountIds.filter((id) => {
      const accountSummary = slice.accountSummaries.entities[id];
      // Check for a name partial match or a perfect accountId match.
      return (
        accountSummary?.accountName?.toLowerCase()?.includes(filter) ||
        accountSummary?.accountId?.toString() === filter.trim()
      );
    });
    return filteredAccountIds;
  }),
  selectAccountSummaryById: memoize((accountId?: number) =>
    createSelector(selectSlice, (slice) => {
      if (!accountId) return;
      return accountSummarySelectors.selectById(slice.accountSummaries, accountId);
    })
  ),
  selectAccountSummaries: createSelector(selectSlice, (slice) =>
    accountSummarySelectors.selectAll(slice.accountSummaries)
  ),
  selectCurrentAccountId: createSelector(selectSlice, (slice) => slice.currentAccountId),
  selectAccountFilter: createSelector(selectSlice, (slice) => slice.accountFilter),
  selectCurrentAccountChatIds: createSelector(selectSlice, (slice) => slice.currentAccountChatIds),
  selectMyChatIds: createSelector(selectSlice, (slice) => slice.myChatIds),
  selectMyUnansweredCount: createSelector(selectSlice, selectChatSlice, (slice, chatSlice) => {
    let unanswered = 0;
    for (const chatId of slice.myChatIds) {
      const teamRoamChat = slice.teamRoamChats.entities[chatId];
      const chat = chatSlice.chats.entities[chatId];
      if (
        chat &&
        teamRoamChat &&
        (chat.lastMessageTime ?? 0) > (teamRoamChat.answeredThrough ?? 0)
      ) {
        unanswered += 1;
      }
    }
    return unanswered;
  }),
  selectChatInfoByChatId: memoize((chatId?: string) =>
    createSelector(selectSlice, (slice) => {
      if (!chatId) return;
      return teamRoamChatSelector.selectById(slice.teamRoamChats, chatId);
    })
  ),
  selectHasChatInfo: memoize((chatId?: string) =>
    createSelector(
      selectSlice,
      (slice) => !!chatId && !!teamRoamChatSelector.selectById(slice.teamRoamChats, chatId)
    )
  ),
  selectTeamRoamEmail: createSelector(selectSlice, (slice) => slice.teamRoamEmail),
};
