import { z } from "zod";
import { numberId, stringId } from "../Models/zodTypes.js";

export const AudienceRequest = z.object({
  id: stringId(),
  sectionId: stringId(),
  stageRoomId: numberId(),
  occupantId: stringId(),
  occupantName: z.string(),
  occupantEmail: z.string().email().optional(),
  occupantImageUrl: z.string().url().optional(),
  createdTimestamp: z.number().optional(),
  goBackstage: z.boolean().optional(),
});
export type AudienceRequest = z.infer<typeof AudienceRequest>;

export const AudienceRequestAnswer = z.enum(["accept", "reject"]);
export type AudienceRequestAnswer = z.infer<typeof AudienceRequestAnswer>;
