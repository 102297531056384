import { TeamRoamChatInfo } from "../../../../shared/Models/TeamRoam.js";
import { SliceType } from "./teamRoamSlice.js";

export const updateCurrentAccountTeamRoamChats = (
  state: SliceType,
  teamRoamChats: TeamRoamChatInfo[]
) => {
  const newChatIds = updateChatIdsPreservingOrder(state.currentAccountChatIds, teamRoamChats);
  state.currentAccountChatIds = newChatIds;
};

export const updateMyTeamRoamChats = (state: SliceType, myAddressIds: string[]) => {
  const myTeamRoamChats = new Array<TeamRoamChatInfo>();
  for (const id of state.teamRoamChats.ids) {
    const chatInfo = state.teamRoamChats.entities[id];
    if (
      chatInfo &&
      chatInfo.teamMemberAddressIds.some((addressId) => myAddressIds.includes(addressId))
    ) {
      myTeamRoamChats.push(chatInfo);
    }
  }

  const newChatIds = updateChatIdsPreservingOrder(state.myChatIds, myTeamRoamChats);
  state.myChatIds = newChatIds;
};

export const updateChatIdsPreservingOrder = (
  currentChatIds: string[],
  replacementChats: TeamRoamChatInfo[]
) => {
  const chatIdsToDelete = new Set<string>(currentChatIds);
  const chatsToAdd = new Array<TeamRoamChatInfo>();
  for (const chat of replacementChats) {
    chatIdsToDelete.delete(chat.chatId);
    if (!currentChatIds.includes(chat.chatId)) {
      chatsToAdd.push(chat);
    }
  }

  // If the chat was already in the list, maintain the ordering to avoid too much UI bounce.
  const newChatIds = new Array<string>();
  for (const currentChatId of currentChatIds) {
    if (!chatIdsToDelete.has(currentChatId)) {
      newChatIds.push(currentChatId);
    }
  }

  // Add new chats sorted by recent message.
  chatsToAdd.sort((c1, c2) => (c2.lastMessage?.timestamp ?? 0) - (c1.lastMessage?.timestamp ?? 0));
  newChatIds.push(...chatsToAdd.map((c) => c.chatId));
  return newChatIds;
};
