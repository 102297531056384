import { z } from "zod";
import { PersonResult } from "../UserLocation.js";
import { CalendarProviderId } from "../calendar/settings/CalendarSettings.js";
import { numberId, stringDate, stringId } from "../zodTypes.js";
import { BaseAccessLink } from "./BaseAccessLink.js";

export const MeetingLinkAccessType = z.enum(["MembersOnly", "AllowVisitors"]);
export type MeetingLinkAccessType = z.infer<typeof MeetingLinkAccessType>;

export const MeetingLinkExternalEventType = z.enum(["Single", "Recurring", "Unknown"]);
export type MeetingLinkExternalEventType = z.infer<typeof MeetingLinkExternalEventType>;

export const MeetingLink = BaseAccessLink.extend({
  linkType: z.literal("meeting"),

  roamId: numberId(),
  hostPersonId: numberId(),
  accessType: MeetingLinkAccessType,

  linkName: z.string().nonempty().optional(),
  /**
   * The start time of the meeting, if any.
   *
   * When specified, the [start] and [end] times restrict when someone can join the meeting.
   *
   * For recurring meetings tied to external events, this field is periodically updated to reflect
   * the current/upcoming meeting.
   */
  start: stringDate().optional(),
  /**
   * The end time of the meeting, if any.
   *
   * When specified, the [start] and [end] times restrict when someone can join the meeting.
   *
   * For recurring meetings tied to external events, this field is periodically updated to reflect
   * the current/upcoming meeting.
   */
  end: stringDate().optional(),
  floorId: numberId(),
  roomId: numberId().optional(),

  created: stringDate().optional(),
  updated: stringDate().optional(),

  // If this link was generated by a third-party calendar plugin, info about the event where
  // the meeting link was added.
  // This event should be the "root" event—if this is a recurring event, the meeting link should
  // be associated with the original root event, rather than an arbitrary instance.
  extCalendarProviderId: CalendarProviderId.optional(),
  extCalendarId: stringId().optional(),
  extEventId: stringId().optional(),
  extEventType: MeetingLinkExternalEventType.optional(),
  extUpdated: z.number().positive().optional(),
  extUrl: z.string().url().optional(),

  // The original calendar event that this meeting link is being derived from.
  //
  // This is somewhat of a band-aid to handle creating meeting links from external calendar events.
  //
  // The underlying issue is that, for meeting links based on external events, we determine
  // meeting link fields (e.g. start, end, among others) based on the calendar event.
  // It's fundamentally necessary that we need an external event, because meeting links are not
  // supposed to give access into roam until the meeting starts (which implies that we know when
  // the event starts).
  // The issue is caused by providers who do not make their calendar events immediately available,
  // i.e. we cannot fetch events from their API.
  // This (currently) occurs for desktop Outlook apps because these apps support an offline/caching
  // mode—the Outlook desktop client can take a bit of time before it syncs a new event to
  // Microsoft's servers.
  // As a result, we can't eagerly fetch the event until Microsoft's sync has occurred.
  //
  // A tangential but related issue: users can create a meeting link for an event and then change
  // the event.
  // Specifically, users can edit an event to be recurring/not recurring—while we already have
  // logic to handle some event updates (e.g. moving the event around), we assume that the
  // event type itself is not changing (otherwise, we wouldn't have a extEventType field).
  // We also do not currently support creating a meeting link for a recurring event instance.
  // While this seems rare (you'd probably always want a link for a recurring link),
  extOriginalCalendarProviderId: CalendarProviderId.optional(),
  extOriginalCalendarId: stringId().optional(),
  extOriginalEventId: stringId().optional(),
  /** Email address of the user who created the meeting link. */
  extOriginalUserEmail: z.string().optional(),

  // Added by the server, populating from the calendarEvent database for the extEventId this meeting is associated with
  numAttendees: z.number().positive().optional(),

  // Added by server, populated from externalCalendarEvents during addDerivedData
  calendarPrivate: z.boolean().optional(),

  // Added by server on send
  url: z.string().url().optional(),
  accessLinkQuery: z.string().optional(),
  instructionsUrl: z.string().url().optional(),

  // Added by server, only for bulk queries.
  // NOTE(vple):
  //  For each meeting link, we are trying to track the external events that the meeting link
  //  corresponds to.
  //  Under simple scenarios, there is only one "root" event—either a single event, or the first
  //  event in a recurring series.
  //  This is normally stored as the external event, above.
  //  When events get edited, this can cause providers to create additional events under the hood
  //  (for example, as suggested [here](https://www.rfc-editor.org/rfc/rfc5545#section-3.8.4.4)).
  //  When this happens, we use additional external event IDs to track all the "root"/"base"
  //  events (not event instances), other than the original root event.
  //  These should correspond to all events that specify a recurrence rule.
  additionalExtEventIds: stringId().array().optional(),
});
export type MeetingLink = z.infer<typeof MeetingLink>;

export const HostDetails = z.object({
  hostName: z.string().nonempty(),
  hostImageAbsoluteUrl: z.string().url().optional(),
  hostLocation: PersonResult.optional(),
});
export type HostDetails = z.infer<typeof HostDetails>;

export const MeetingLinkDetails = MeetingLink.extend({
  roamName: z.string().nonempty(),
  roamImageAbsoluteUrl: z.string().url().optional(),
  hostName: z.string().nonempty(),
  hostImageAbsoluteUrl: z.string().url().optional(),
  floorNumber: z.number().nonnegative().optional(),
  floorName: z.string().optional(),
  hostLocation: PersonResult.optional(),
  /** @deprecated */
  additionalHostDetails: z.array(HostDetails).optional(),
});
export type MeetingLinkDetails = z.infer<typeof MeetingLinkDetails>;
