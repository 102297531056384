import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tryMatch } from "../../../../shared/helpers/search.js";
import { logger } from "../../../../shared/infra/logger.js";
import { CHAT_MAX_NUM_ADDRESSES } from "../../../../shared/Models/Chat.js";
import { ChatTarget } from "../../../../shared/Models/ChatAddress.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";
import { targetsAreEqual } from "../../helpers/targets.js";

export interface ChatTargetQuery {
  text: string;
  // There can be multiple <ChatTargetInput>, so require a unique `inputId` so
  // the suggestions of one don't interfere with the other inputs. An even
  // better enhancement would be to separate out the results for each input
  // rather than just taking the results of the latest, but this should be
  // sufficient for now.
  inputId: string;
  teamRoamOnly?: boolean;
  includeSelf?: boolean;
}

export interface SuggestedTargetPayload {
  targets: ChatTarget[];
  searchType: string;
}

export interface HandlePastePayload {
  text: string;
  existingTargets?: ChatTarget[];
  includeSelf?: boolean;
  allowManualEmailTarget?: boolean;
}

export type SuggestedTargetBuckets = { [searchType: string]: ChatTarget[] };

const slice = createSlice({
  name: "chatTarget",
  initialState: {
    chatTargets: new Array<ChatTarget>(),
    chatTargetQuery: undefined as ChatTargetQuery | undefined,
    suggestedTargetBuckets: {} as SuggestedTargetBuckets,
    suggestedTargets: [] as ChatTarget[],
    searching: false,
    pastedTargets: new Array<ChatTarget>(),
  },
  reducers: {
    setChatTargetQuery: (state, action: PayloadAction<ChatTargetQuery>) => {
      state.chatTargetQuery = action.payload;
    },
    convertTextToTarget: (state) => {},
    handlePaste: (state, action: PayloadAction<HandlePastePayload>) => {},
    setPastedTargets: (state, action: PayloadAction<ChatTarget[]>) => {
      state.pastedTargets = action.payload;
    },

    setSuggestedTargets: (state, action: PayloadAction<SuggestedTargetPayload>) => {
      const { targets, searchType } = action.payload;
      state.suggestedTargetBuckets[searchType] = targets;
    },
    rankSuggestedTargets: (state, action: PayloadAction<ChatTarget[]>) => {
      state.suggestedTargets = action.payload;
    },
    clearSuggestedTargets: (state) => {
      state.suggestedTargetBuckets = {};
      state.suggestedTargets = [];
    },

    setChatTargets: (state, action: PayloadAction<ChatTarget[]>) => {
      state.chatTargets = action.payload;
    },
    addChatTarget: (state, action: PayloadAction<ChatTarget>) => {
      const newTarget = action.payload;
      if (
        state.chatTargets.length < CHAT_MAX_NUM_ADDRESSES &&
        !state.chatTargets.some((t) => targetsAreEqual(t, newTarget))
      ) {
        state.chatTargets.push(newTarget);
      }
      state.chatTargetQuery = undefined;
      state.suggestedTargetBuckets = {};
    },
    removeChatTarget: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      if (state.chatTargets && state.chatTargets.length > index) {
        state.chatTargets.splice(index, 1);
      }
    },
    resetChatTargets: (state) => {
      state.chatTargetQuery = undefined;
      state.chatTargets = new Array<ChatTarget>();
      state.suggestedTargetBuckets = {};
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.chatTarget;
export const selectors = {
  selectChatTargets: createSelector(selectSlice, (slice) => slice.chatTargets),
  selectChatTargetQuery: createSelector(selectSlice, (slice) => slice.chatTargetQuery),
  selectSuggestedTargetBuckets: createSelector(
    selectSlice,
    (slice) => slice.suggestedTargetBuckets
  ),
  selectSuggestedTargets: createSelector(selectSlice, (slice) => slice.suggestedTargets),
  selectSearching: createSelector(selectSlice, (slice) => slice.searching),
  selectPastedChatTargets: createSelector(selectSlice, (slice) => slice.pastedTargets),
};

export const orderSuggestedTargetsByType = (
  suggestedTargetBuckets: SuggestedTargetBuckets
): ChatTarget[] => {
  const results = new Array<ChatTarget>();
  for (const resultType of ["local", "member", "connection", "social", "group", "bot"]) {
    for (const result of suggestedTargetBuckets[resultType] ?? []) {
      // Replace local results in place with remote results, since remote results may be more
      // complete. This must be in-place to avoid re-ordering the results.
      const existing = results.findIndex((r) => targetsAreEqual(r, result));
      if (existing !== -1) {
        results[existing] = result;
      } else {
        results.push(result);
      }
    }
  }
  return results;
};

/**
 * Rank suggested targets by their type and the quality of the match.
 * 1. As a first pass, merge non-local results into the local results.
 * 2. Rank all prefix & exact matches ahead of infix matches.
 *    Note that prefix includes prefix of last name too.
 * 3. Within each partition, rank by type.
 */
export const orderSuggestedTargetsByTypeAndMatch = (
  suggestedTargetBuckets: SuggestedTargetBuckets,
  query: string
): ChatTarget[] => {
  // Merge non-local results into the local results to avoid dupes.
  const results = new Array<ChatTarget>();
  for (const resultType of ["local", "member", "connection", "social", "group", "bot"]) {
    for (const result of suggestedTargetBuckets[resultType] ?? []) {
      // Replace local results in place with remote results, since remote results may be more
      // complete. This must be in-place to avoid re-ordering the results.
      const existing = results.findIndex((r) => targetsAreEqual(r, result));
      if (existing !== -1) {
        results[existing] = result;
      } else {
        results.push(result);
      }
    }
  }

  // Rank all prefix matches ahead of infix matches.
  // Note that prefix includes prefix of last name too.
  // Within each partition, rank by type.
  const prefixMatches = new Array<ChatTarget>();
  const infixMatches = new Array<ChatTarget>();
  for (const result of results) {
    const match = tryMatch(result, query);
    if (match === "prefix") {
      prefixMatches.push(result);
    } else if (match === "infix") {
      infixMatches.push(result);
    } else {
      // All of the results should have at least an infix match.
      // If there's a "none" match, then we're missing some case.
      logger.warn({ query, result }, `Unexpected non-match: ${result.displayName}`);
      infixMatches.push(result);
    }
  }
  return prefixMatches.concat(infixMatches);
};

export const ChatTargetSelectors = selectors;
export const ChatTargetActions = actions;
