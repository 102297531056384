import { z } from "zod";
import { DerivedAccessBadge } from "./AccessBadge.js";
import { LinkType } from "./AccessLinks/LinkType.js";
import { AudienceRequestAnswer } from "./AudienceRequest.js";
import { ChatAddress } from "./ChatAddress.js";
import { Knock } from "./Interaction.js";
import { Location } from "./Location.js";
import { OccupantType } from "./Occupant.js";
import { Room } from "./Room.js";
import { RoomInvite, RoomInviteResult } from "./RoomInvite.js";
import { ClientEnterRoamRequest } from "./lobby/EnterRoamRequest.js";
import { numberId, stringId, uuid } from "./zodTypes.js";

/**
 * Uses a fallback value whenever parsing fails for a schema. This is a modified version of:
 * https://github.com/colinhacks/zod/issues/316#issuecomment-794502697
 *
 * It needs modification so that the returned schema had the correct type (same type as the input
 * schema).
 *
 * Original code:
 *
 * export function fallback<T extends z.Schema<any>>(
 *   schema: T,
 *   value: z.infer<T>
 * ) {
 *   return z.any().transform((val) => {
 *     const safe = schema.safeParse(val);
 *     return safe.success ? safe.data : value;
 *   });
 * }
 *
 * schemaName is inserted into a description on the z.any() node, needed for typegen
 */
const fallback = <T>(
  schema: z.Schema<T>,
  value: z.infer<z.Schema<T>>,
  schemaName: string
): z.Schema<T> => {
  return z
    .any()
    .describe(`fallbackFor:${schemaName}`)
    .transform((val) => {
      const safe = schema.safeParse(val);
      return safe.success ? safe.data : value;
    });
};

// Various message content types
export const EmojiContent = z.object({
  contentType: z.literal("emoji"),
  text: z.string(),
  colons: z.string().optional(),
  fileId: z.string().optional(), // set for custom emoji
});
export type EmojiContent = z.infer<typeof EmojiContent>;

export const KnockContent = z.object({
  contentType: z.literal("knock"),
  knock: Knock,
  knockerName: z.string(),
  knockerEmail: z.string().email().optional(),
  roomName: z.string().optional(),
  roomOwnerEmails: z.string().array(),
});
export type KnockContent = z.infer<typeof KnockContent>;

export const PersonalLobbyGuestContent = z.object({
  contentType: z.literal("personalLobbyGuest"),
  enterRoamRequest: ClientEnterRoamRequest,
});
export type PersonalLobbyGuestContent = z.infer<typeof PersonalLobbyGuestContent>;

export const RequestMuteAudioContent = z.object({
  contentType: z.literal("requestMuteAudio"),
  muteOccupantId: stringId(),
});
export type RequestMuteAudioContent = z.infer<typeof RequestMuteAudioContent>;

export const WaveContent = z.object({
  contentType: z.literal("wave"),
  waverName: z.string().optional(),
  wavedAtName: z.string().optional(),
  roamId: numberId(),
  roamName: z.string(),
  floorNumber: z.number(),
  floorName: z.string().optional(),
  sectionId: stringId(),
});
export type WaveContent = z.infer<typeof WaveContent>;

export const ItemContent = z.object({
  contentType: z.literal("item"),
  itemId: uuid(),
  itemType: z.string().optional(), // ItemType
});
export type ItemContent = z.infer<typeof ItemContent>;

export const VisitEndedContent = z.object({
  contentType: z.literal("visitEnded"),
  roamId: numberId(),
  roamName: z.string(),
  guestName: z.string().nonempty(),
  guestImageUrl: z.string().url().optional(),
  guestEmail: z.string().email().optional(),
  // Will always be present when USE_CLIENT_CHAT_ADDRESS flag is on.
  guestClientUuid: uuid().optional(),
  guestOccupantId: stringId(),
  guestOccupantType: OccupantType,
  hostId: numberId(),
  hostName: z.string().nonempty(),
  hostImageUrl: z.string().url().optional(),
  hostEmail: z.string().email(),
  accessLinkId: uuid().optional(),
  accessLinkName: z.string().optional(),
  accessLinkType: LinkType.optional(),
});
export type VisitEndedContent = z.infer<typeof VisitEndedContent>;

export const VisitStartedContent = VisitEndedContent.extend({
  contentType: z.literal("visitStarted"),
  guestOccupantId: stringId(),
  sectionId: stringId(),
  floorNumber: z.number(),
  floorName: z.string().optional(),
});
export type VisitStartedContent = z.infer<typeof VisitStartedContent>;

export const RoomInviteContent = z.object({
  contentType: z.literal("roomInvite"),
  invite: RoomInvite,
  nudge: z.boolean().optional(),
});
export type RoomInviteContent = z.infer<typeof RoomInviteContent>;

export const RoomInviteResultContent = z.object({
  contentType: z.literal("roomInviteResult"),
  invite: RoomInvite,
  result: RoomInviteResult,
});
export type RoomInviteResultContent = z.infer<typeof RoomInviteResultContent>;

export const RemovedFromRoomContent = z.object({
  contentType: z.literal("removedFromRoom"),
  room: Room,
  removedByName: z.string(),
  removedName: z.string(),
});
export type RemovedFromRoomContent = z.infer<typeof RemovedFromRoomContent>;

export const AskedToLeaveRoomContent = z.object({
  contentType: z.literal("askedToLeaveRoom"),
  roomRemovalToken: stringId(),
  leaverOccupantId: stringId(),
});
export type AskedToLeaveRoomContent = z.infer<typeof AskedToLeaveRoomContent>;

export const StayedInRoomContent = z.object({
  contentType: z.literal("stayedInRoom"),
});
export type StayedInRoomContent = z.infer<typeof StayedInRoomContent>;

export const AudienceRequestAnswerContent = z.object({
  contentType: z.literal("audienceRequestAnswer"),
  occupantId: stringId(),
  occupantName: z.string(),
  stageRoomId: numberId(),
  requestId: stringId(),
  answer: AudienceRequestAnswer,
  newLocation: Location.optional(),
});
export type AudienceRequestAnswerContent = z.infer<typeof AudienceRequestAnswerContent>;

export const AccessBadgeGrantedContent = z.object({
  contentType: z.literal("accessBadgeGranted"),
  accessBadge: DerivedAccessBadge,
});
export type AccessBadgeGrantedContent = z.infer<typeof AccessBadgeGrantedContent>;

export const AddressMembersChangedContent = z.object({
  contentType: z.literal("addressMembersChanged"),
  groupAddress: ChatAddress,
  added: ChatAddress.array(),
  addedAdditionalCount: z.number().optional(),
  removed: ChatAddress.array(),
  removedAdditionalCount: z.number().optional(),
  performedBy: ChatAddress.optional(),
});
export type AddressMembersChangedContent = z.infer<typeof AddressMembersChangedContent>;

export const TextSnippetContent = z.object({
  contentType: z.literal("textSnippet"),
  text: z.string(),
  language: z.string().max(40),
});
export type TextSnippetContent = z.infer<typeof TextSnippetContent>;

export const StoryContent = z.object({
  contentType: z.literal("story"),
  itemId: uuid(),

  // In milliseconds
  expirationTimestamp: z.number().positive(),
});
export type StoryContent = z.infer<typeof StoryContent>;

export const DeletedContent = z.object({
  contentType: z.literal("deleted"),
});

export const InvalidContent = z.object({
  contentType: z.literal("invalidContent"),
});
export type InvalidContent = z.infer<typeof InvalidContent>;

export const EphemeralTextContent = z.object({
  contentType: z.literal("ephemeralText"),
  title: z.string().optional(),

  // For now we only support a text field, but we may want to add markdown, items, etc.
  text: z.string(),
});
export type EphemeralTextContent = z.infer<typeof EphemeralTextContent>;

export const TextPreviewContent = z.object({
  contentType: z.literal("text"),

  markdownText: z.string(),

  items: ItemContent.array().optional(),
  textSnippets: TextSnippetContent.array().optional(),
});
export type TextPreviewContent = z.infer<typeof TextPreviewContent>;

export const ChatMessagePreviewContent = z.discriminatedUnion("contentType", [
  TextPreviewContent,
  DeletedContent,
  InvalidContent,
]);

export type ChatMessagePreviewContent = z.infer<typeof ChatMessagePreviewContent>;

export const ChatMessagePreviewBlock = z.object({
  blockType: z.literal("messagePreview"),

  fromAddress: ChatAddress,
  threadTimestamp: z.number().positive().optional(),
  timestamp: z.number().positive(),
  chatId: stringId(),
  chatName: z.string(),

  messageLink: z.string().url(),

  // Maps to TextContent; only a partial snippet
  content: fallback(
    ChatMessagePreviewContent,
    { contentType: "invalidContent" },
    "ChatMessagePreviewContent"
  ),

  items: ItemContent.array().optional(),
  textSnippets: TextSnippetContent.array().optional(),
});

export type ChatMessagePreviewBlock = z.infer<typeof ChatMessagePreviewBlock>;

export const UnfurledImage = z.object({
  url: z.string().url(),
  type: z.string().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
  alt: z.string().optional(),
});
export type UnfurledImage = z.infer<typeof UnfurledImage>;

export const UnfurledLinkPreviewBlock = z.object({
  blockType: z.literal("unfurledLink"),
  url: z.string().url(),
  title: z.string(),
  description: z.string().optional(),
  favicon: z.string().url().optional(),
  image: UnfurledImage.optional(),
  siteName: z.string().optional(),
});
export type UnfurledLinkPreviewBlock = z.infer<typeof UnfurledLinkPreviewBlock>;

// should probably make this a discriminated union
export const PreviewBlock = z.discriminatedUnion("blockType", [
  ChatMessagePreviewBlock,
  UnfurledLinkPreviewBlock,
]);

export type PreviewBlock = z.infer<typeof PreviewBlock>;

// Special message content types

export const TextContent = z.object({
  contentType: z.literal("text"),
  text: z.string(),

  // Markdown version of "text", only populated if the content includes markdown formatting.
  markdownText: z.string().optional(),

  items: ItemContent.array().optional(),
  textSnippets: TextSnippetContent.array().optional(),

  previews: PreviewBlock.array().optional(),
});
export type TextContent = z.infer<typeof TextContent>;

export const ConfidentialTextContent = z.object({
  contentType: z.literal("confidentialText"),
});
export type ConfidentialTextContent = z.infer<typeof ConfidentialTextContent>;

export const confidentialContentTypes = ["confidentialText"];

export const ChatMessageContent = z.discriminatedUnion("contentType", [
  AccessBadgeGrantedContent,
  AddressMembersChangedContent,
  AskedToLeaveRoomContent,
  AudienceRequestAnswerContent,
  ConfidentialTextContent,
  DeletedContent,
  EmojiContent,
  EphemeralTextContent,
  InvalidContent,
  ItemContent,
  KnockContent,
  PersonalLobbyGuestContent,
  RemovedFromRoomContent,
  RequestMuteAudioContent,
  RoomInviteContent,
  RoomInviteResultContent,
  StayedInRoomContent,
  StoryContent,
  TextContent,
  TextSnippetContent,
  VisitEndedContent,
  VisitStartedContent,
  WaveContent,
]);
export type ChatMessageContent = z.infer<typeof ChatMessageContent>;

export type ChatContentType = ChatMessageContent["contentType"];

export const MessageSendStatus = z.enum(["sending", "sent", "delivered", "error"]);
export type MessageSendStatus = z.infer<typeof MessageSendStatus>;

export const ChatMessage = z.object({
  chatId: stringId().optional(),

  /* Messages that are in threads have a `threadTimestamp` set to the timestamp of the message in
   * the main chat (not in a thread) that they are about.  All messages with the same `threadTimestamp`
   * are in the same thread.
   *
   * Throughout the system, (chatId, threadTimestamp) is used to designate a list of  messages either
   * in the main chat or a specific thread. For chatId abc123, (abc123, undefined) means (only) the
   * messages in the main chat, and (abc123, 12345) means the messages that are in the thread about
   * message timestamp 12345 in the main chat.
   **/
  threadTimestamp: z.number().positive().optional(),

  messageId: uuid(),
  timestamp: z.number().positive().optional(),
  version: z.number().positive().optional(),
  addressId: stringId(),
  sendStatus: MessageSendStatus.optional(),
  delivered: z.boolean().optional(),
  content: fallback(ChatMessageContent, { contentType: "invalidContent" }, "ChatMessageContent"),
  confidentialText: z.string().optional(),
  confidentialItems: ItemContent.array().optional(),
  mentionedAddressIds: stringId().array().optional(),
  allMention: z.boolean().optional(),
});
export type ChatMessage = z.infer<typeof ChatMessage>;

export const PendingChatMessage = ChatMessage.omit({
  timestamp: true,
}).extend({
  chatId: stringId(),
  clientTimestamp: z.number().positive(),
  version: z.number().positive(),
});
export type PendingChatMessage = z.infer<typeof PendingChatMessage>;

// Once a message has been sent, it will have a chatId and timestamp
export const SentChatMessage = ChatMessage.omit({ confidentialText: true }).extend({
  chatId: stringId(),
  timestamp: z.number().positive(),
  // Original message has version 1. Each edit (or deletion) increases version by 1.
  version: z.number().positive(),
});
export type SentChatMessage = z.infer<typeof SentChatMessage>;

export const SentThreadMessage = SentChatMessage.extend({
  threadTimestamp: z.number().positive(),
});
export type SentThreadMessage = z.infer<typeof SentThreadMessage>;

export const MessageDeliveryResult = z.enum([
  "notReady",
  "deliveryAttempted",
  "recipientNotFound",
  "badRequest",
]);
export type MessageDeliveryResult = z.infer<typeof MessageDeliveryResult>;

export const ChatMessageIdentifiers = ChatMessage.pick({
  threadTimestamp: true,
  addressId: true,
}).extend({ chatId: stringId(), timestamp: z.number().positive() });
export type ChatMessageIdentifiers = z.infer<typeof ChatMessageIdentifiers>;

export const ConfidentialMessageContent = z.object({
  text: z.string(),
  items: z.array(ItemContent).optional(),
});
export type ConfidentialMessageContent = z.infer<typeof ConfidentialMessageContent>;
