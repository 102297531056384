import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";
import { WindowSelectors } from "../../../store/slices/windowSlice.js";

export enum InboxTab {
  UnifiedInbox,
  DirectMessages,
  Groups,
  Meetings,
  BookmarkedMessages,
  Guests,
}

export interface SetSmallWidthPayload {
  windowKey: WindowKey;
  smallWidth: boolean;
}

export interface OpenPopupFromListPayload {
  unreadOverride?: boolean;
  offset: number;
  windowKey?: WindowKey;
}

export type GroupsTabMode = "myGroups" | "allGroups";

const slice = createSlice({
  name: "inbox",
  initialState: {
    inboxWindowEnabled: false,
    inboxTab: InboxTab.UnifiedInbox,
    inboxOpenWindow: undefined as WindowKey | undefined,
    selectedIndex: undefined as number | undefined,
    unreadFilter: false,
    groupsTabRoamIdFilter: undefined as number | undefined,

    // If true, the window is too narrow to accommodate inbox popups; anchored popups should be opened
    // instead
    smallWidth: {} as { [windowKey: WindowKey]: boolean },
  },
  reducers: {
    toggleInbox: (state, action: PayloadAction<WindowKey | undefined>) => {
      // logic in saga
    },
    openInbox: (state) => {
      // logic in saga
    },
    closeInbox: (state, action: PayloadAction<WindowKey | undefined>) => {
      // logic in saga
    },
    popOutInbox: (state) => {
      // logic in saga
    },
    unPopInbox: (state) => {
      // logic in saga
    },
    setInboxTab: (state, action: PayloadAction<InboxTab>) => {
      state.inboxTab = action.payload;
      state.selectedIndex = undefined;
    },
    setSmallWidth: (state, action: PayloadAction<SetSmallWidthPayload>) => {
      const { windowKey, smallWidth } = action.payload;
      state.smallWidth[windowKey] = smallWidth;
    },
    setSelectedIndex: (state, action: PayloadAction<number | undefined>) => {
      state.selectedIndex = action.payload;
    },
    setUnreadFilter: (state, action: PayloadAction<boolean>) => {
      state.unreadFilter = action.payload;
    },
    setGroupsTabRoamIdFilter: (state, action: PayloadAction<number | undefined>) => {
      state.groupsTabRoamIdFilter = action.payload;
    },
    openPopupFromList: (state, action: PayloadAction<OpenPopupFromListPayload>) => {
      const { unreadOverride } = action.payload;
      if (unreadOverride !== undefined) {
        state.unreadFilter = unreadOverride;
      }
    },
    // Should only be called from inboxSaga
    internalSetInboxOpenWindow: (state, action: PayloadAction<WindowKey | undefined>) => {
      state.inboxOpenWindow = action.payload;
    },
    internalSetInboxWindowEnabled: (state, action: PayloadAction<boolean>) => {
      state.inboxWindowEnabled = action.payload;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.inbox;
export const selectors = {
  selectInboxWindowEnabled: (state: RootState) => selectSlice(state).inboxWindowEnabled,
  selectInboxTab: (state: RootState) => selectSlice(state).inboxTab,
  selectInboxOpen: (windowKey: WindowKey | undefined) => (state: RootState) =>
    windowKey ? selectSlice(state).inboxOpenWindow === windowKey : false,
  selectInboxOpenWindow: (state: RootState) => selectSlice(state).inboxOpenWindow,
  selectSmallWidth: (windowKey: WindowKey | undefined) => (state: RootState) =>
    windowKey ? selectSlice(state).smallWidth[windowKey] ?? false : false,
  selectAmISelected: (index: number) => (state: RootState) =>
    selectSlice(state).selectedIndex === index,
  selectSelectedIndex: (state: RootState) => selectSlice(state).selectedIndex,
  selectUnreadFilter: (state: RootState) => selectSlice(state).unreadFilter,
  selectGroupsTabRoamIdFilter: (state: RootState) => selectSlice(state).groupsTabRoamIdFilter,
  selectInboxTabForWindow: (windowKey?: WindowKey) => (state: RootState) => {
    const slice = selectSlice(state);
    return windowKey && windowKey === slice.inboxOpenWindow ? slice.inboxTab : undefined;
  },
  selectInboxOpenInFocusedWindow: createSelector(
    selectSlice,
    WindowSelectors.selectFocusedWindow,
    (slice, focusedWindow) => {
      if (!focusedWindow || !slice.inboxOpenWindow) {
        return false;
      }

      return focusedWindow === slice.inboxOpenWindow;
    }
  ),
};

export const InboxSelectors = selectors;
export const InboxActions = actions;
