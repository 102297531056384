import baseStyled, { ThemedStyledInterface, useTheme as baseUseTheme } from "styled-components";
import { makeBaseTheme } from "./baseTheme.js";
import { semanticColors } from "./colors.js";

// Do not export, as real theme should be created in ThemeProvider
const baseTheme = makeBaseTheme(semanticColors);

export const styled = baseStyled as ThemedStyledInterface<typeof baseTheme>;
export const useTheme = baseUseTheme as () => typeof baseTheme;
export type ThemeType = typeof baseTheme;
export { css, keyframes } from "styled-components"; // re-export action for convenience
export { ThemeProvider } from "./ThemeProvider.js";
export { ThemeProviderWithoutStore } from "./ThemeProviderWithoutStore.js";
