/* eslint-disable no-restricted-imports */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { memoize } from "lodash";
import { defaultMemoize } from "reselect";
import { getChatKey } from "../../../../shared/helpers/chat.js";
import { TypingIndicator } from "../../../../shared/Models/TypingIndicator.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";
import { selectors as ChatSelectors } from "./chatSlice.js";

export interface SetTypingIndicatorsPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  typingIndicators: TypingIndicator[];
}

export interface SetThinkingIndicatorsPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  thinking: boolean;
}

export interface SendTypingIndicatorPayload {
  chatId: string;
  threadTimestamp: number | undefined;
}

const slice = createSlice({
  name: "typingIndicator",
  initialState: {
    typingIndicators: {} as { [chatKey: string]: TypingIndicator[] },

    // This is used for Magic Minutes.
    thinkingIndicators: {} as { [chatKey: string]: boolean },

    // Maps addressId to the timestamp of the most recent message from that address.
    lastChatReceived: {} as { [addressId: string]: number },
  },
  reducers: {
    sendTypingIndicator: (state, action: PayloadAction<SendTypingIndicatorPayload>) => {},
    setLastReceived: (state, action: PayloadAction<{ addressId: string; timestamp: number }>) => {
      const { addressId, timestamp } = action.payload;
      const chat = state.lastChatReceived[addressId];
      if (!chat || chat < timestamp) {
        state.lastChatReceived[addressId] = timestamp;
      }
    },

    // From server
    setTypingIndicators: (state, action: PayloadAction<SetTypingIndicatorsPayload>) => {
      const { chatId, threadTimestamp, typingIndicators } = action.payload;
      state.typingIndicators[getChatKey(chatId, threadTimestamp)] = typingIndicators;
    },
    setThinkingIndicator: (state, action: PayloadAction<SetThinkingIndicatorsPayload>) => {
      const { chatId, threadTimestamp, thinking } = action.payload;
      state.thinkingIndicators[getChatKey(chatId, threadTimestamp)] = thinking;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.typingIndicator;
export const selectors = {
  selectTypingByChatId: memoize(
    (chatId: string | undefined, threadTimestamp: number | undefined) =>
      createDeepEqualSelector(
        selectSlice,
        ChatSelectors.selectSendFromAddresses,
        (slice, sendFromAddresses) => {
          if (!chatId) return [];
          const key = getChatKey(chatId, threadTimestamp);
          const indicator = slice.typingIndicators[key];
          if (!indicator) return [];
          return (
            indicator

              // Filter out my send address from the typing indicators
              .filter(
                (i) => !sendFromAddresses?.some((sendFrom) => sendFrom.addressId === i.addressId)
              )

              // Filter out typing indicators for which I've received a more recent message
              .filter((i) => {
                const chatReceived = slice.lastChatReceived[i.addressId];
                return !chatReceived || i.timestamp > chatReceived;
              })
              .map((i) => i.displayName)
              .sort()
          );
        }
      ),
    (chatId, threadTimestamp) => `${chatId ?? "u"}|${threadTimestamp ?? "u"}`
  ),
  selectThinkingIndicatorByChatId: defaultMemoize(
    (chatId: string | undefined, threadTimestamp: number | undefined) =>
      createSelector(selectSlice, (slice) =>
        chatId ? slice.thinkingIndicators[getChatKey(chatId, threadTimestamp)] ?? false : false
      )
  ),
};
export const TypingIndicatorActions = actions;
export const TypingIndicatorSelectors = selectors;
