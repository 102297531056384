import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfYear,
  isToday,
  isTomorrow,
  isYesterday,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";
import { dateReviver } from "../../shared/helpers/dates.js";

const defaultDateTimeFormat: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

const approximateDateFormat: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const shortDateFormat: Intl.DateTimeFormatOptions = {
  weekday: "short",
  month: "short",
  day: "2-digit",
};

const monthDayDateFormat: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
};

const weekdayDateFormat: Intl.DateTimeFormatOptions = {
  weekday: "long",
};

const hourMinuteTimeFormat: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
};

const shortDateTimeFormat: Intl.DateTimeFormatOptions = {
  ...shortDateFormat,
  ...hourMinuteTimeFormat,
};

const shortWeekdayTimeFormat: Intl.DateTimeFormatOptions = {
  ...hourMinuteTimeFormat,
  weekday: "short",
};

const shortMonthWeekdayTimeFormat: Intl.DateTimeFormatOptions = {
  ...hourMinuteTimeFormat,
  month: "short",
  day: "numeric",
};

const dateDividerFormat: Intl.DateTimeFormatOptions = {
  weekday: "long",
  month: "long",
  day: "numeric",
};

const dateDividerWithYearFormat: Intl.DateTimeFormatOptions = {
  ...dateDividerFormat,
  year: "numeric",
};

/**
 * @param format      Given a date, choose
 * @returns A date function that takes in a dateOrString (parsing to be a
 *          Date), and locale/timeZone to format the date in, with the locale
 *          and timeZone being inferred from the browser if not provided.
 */
const wrapFormat = (format: (date: Date) => string | Intl.DateTimeFormatOptions) => {
  return (dateOrString: Date | string | undefined, locale?: string, timeZone?: string): string => {
    if (!dateOrString) {
      return "";
    }

    const date = dateReviver(dateOrString);
    if (!(date instanceof Date)) {
      return "";
    }

    const options = format(date);
    if (typeof options === "string") {
      return options;
    }

    return date.toLocaleString(locale, { ...options, timeZone });
  };
};

export const formatDateTime = wrapFormat(() => defaultDateTimeFormat);
export const formatNumericDate = (epochMs: number) => formatDateTime(new Date(epochMs));
export const formatDate = wrapFormat(() => approximateDateFormat);

// Provides some niceties like "Today", "Yesterday"
const formatApproximateDateInternal = (date: Date) => {
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  }

  const now = new Date();
  const weekAgo = startOfDay(subDays(now, 6));
  if (date >= weekAgo) {
    return weekdayDateFormat;
  }

  // If date is within same calendar year or within 9-10 months, use month/day
  // without year. 9-10 months allows for showing recent months from prior year
  // that have not occurred yet in current calendar year (e.g., display "June"
  // but not "March" from prior year for date in "Feb" of current year)
  if (date >= startOfYear(now) || date >= startOfMonth(subMonths(now, 9))) {
    return monthDayDateFormat;
  }

  return approximateDateFormat;
};
export const formatApproximateDate = wrapFormat(formatApproximateDateInternal);

export const formatDateDividerDate = wrapFormat((date: Date) => {
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  }
  const now = new Date();
  if (now.getFullYear() === date.getFullYear()) {
    return dateDividerFormat;
  }
  return dateDividerWithYearFormat;
});

export const formatChatMessageDateTime = wrapFormat((date: Date) => {
  if (isToday(date)) {
    return hourMinuteTimeFormat;
  }
  const now = new Date();
  const weekAgo = startOfDay(subDays(now, 6));
  if (date >= weekAgo) {
    return shortWeekdayTimeFormat;
  }
  if (now.getFullYear() === date.getFullYear()) {
    return shortMonthWeekdayTimeFormat;
  }
  return defaultDateTimeFormat;
});

// Mirrors the logic in formatApproximateDate, but for future instead of past dates
export const formatApproximateFutureDate = wrapFormat((date: Date) => {
  if (isToday(date)) {
    return "Today";
  } else if (isTomorrow(date)) {
    return "Tomorrow";
  }

  const now = new Date();
  const weekAway = endOfDay(addDays(now, 6));
  if (date <= weekAway) {
    return weekdayDateFormat;
  }

  if (date <= endOfYear(now) || date <= endOfMonth(addMonths(now, 9))) {
    return monthDayDateFormat;
  }

  return approximateDateFormat;
});

export const formatTimeOnly = wrapFormat(() => hourMinuteTimeFormat);

export const formatApproximateDateOrTime = wrapFormat((date: Date) => {
  if (isToday(date)) {
    return hourMinuteTimeFormat;
  } else {
    return formatApproximateDateInternal(date);
  }
});

export const formatApproximateDateAndTime = (date: Date) => {
  const timeString = formatTimeOnly(date);
  if (isToday(date)) {
    return timeString;
  }

  const dateString = formatApproximateDate(date);
  return `${dateString} ${timeString}`;
};

export const formatHeldOnDate = (date: Date) => {
  const timeString = formatTimeOnly(date);
  if (isToday(date)) {
    return `Held at ${timeString}`;
  }
  if (isYesterday(date)) {
    return `Held Yesterday at ${timeString}`;
  }

  const dateString = formatApproximateDate(date);
  return `Held on ${dateString} at ${timeString}`;
};

export const formatDateShort = wrapFormat(() => shortDateFormat);
export const formatDateTimeShort = wrapFormat(() => shortDateTimeFormat);

export const formatDuration = (duration: number) => {
  if (isNaN(duration)) return "";

  const min = Math.floor(duration / 60);
  const sec = duration % 60;

  return `${min}:${sec < 10 ? "0" : ""}${Math.round(sec)}`;
};

export const formatMaxDuration = (duration: number) => {
  if (isNaN(duration)) return "";

  const min = Math.floor(duration / 60);
  // Use floor since we're showing "max".
  const sec = Math.floor(duration % 60);

  return `${min ? `${min}min ` : ""}${sec}sec`;
};

const filenameDatePreformat: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
};
const preformatFilenameDate = wrapFormat(() => filenameDatePreformat);

export const formatDateForFilename = (date: Date) => {
  const preFormattedDate = preformatFilenameDate(date);
  return preFormattedDate ? preFormattedDate.replace(/\//g, "-").replace(/:/g, ".") : undefined;
};
