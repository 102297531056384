import { cloneDeep } from "lodash";
import React from "react";
import { inspect } from "util";
import { Board, BoardDefaultSize } from "../../shared/Models/Board.js";
import { Card } from "../../shared/Models/Card.js";
import { ItemType } from "../../shared/Models/Item.js";
import { Dimensions } from "../../shared/geometry/Dimensions.js";
import { Position } from "../../shared/geometry/Position.js";
import { formatApproximateDateOrTime } from "../../shared/helpers/dateFormatters.js";
import { logger } from "../../shared/infra/logger.js";

export const getConstrainedCard = (board: Board, card: Card, fixedAspectRatio?: number) => {
  if (!board || !card) {
    throw new Error(`Cannot constrain placement because missing board`);
  }

  const newCard = cloneDeep(card);

  if (!newCard.height) {
    newCard.height = 3000;
  }
  if (!newCard.width) {
    newCard.width = 3000;
  }

  if (newCard.height < 1500) {
    newCard.height = 1500;
  }

  if (newCard.width < 1500) {
    newCard.width = 1500;
  }

  if (!newCard.y || newCard.y < 0) {
    newCard.y = 0;
  }
  if (!newCard.x || newCard.x < 0) {
    newCard.x = 0;
  }

  if (board.type === "screenShare") {
    newCard.x = 0;
    newCard.y = 0;
    return newCard;
  }

  if (newCard.width && newCard.width > board.width) {
    newCard.width = board.width;
  }
  if (newCard.height && newCard.height > board.height) {
    newCard.height = board.height;
  }

  if (newCard.y + newCard.height > board.height) {
    newCard.y = board.height - newCard.height;
  }

  if (newCard.x + newCard.width > board.width) {
    newCard.x = board.width - newCard.width;
  }

  if (fixedAspectRatio) {
    if (newCard.width / newCard.height > fixedAspectRatio) {
      newCard.width = newCard.height * fixedAspectRatio;
    } else if (newCard.width / newCard.height < fixedAspectRatio) {
      newCard.height = newCard.width / fixedAspectRatio;
    }
  }

  if (
    newCard.x < 0 ||
    newCard.x > board.width ||
    newCard.y < 0 ||
    newCard.y > board.height ||
    newCard.width < 1 ||
    newCard.height < 1 ||
    newCard.width > board.width ||
    newCard.height > board.height
  ) {
    logger.error(
      `constraintCard: invalid placement ${inspect(newCard)}. returning original value}`
    );
    return card;
  }

  return newCard;
};

export const cardWithMove = ({
  card,
  board,
  deltaX,
  deltaY,
}: {
  card: Card;
  board: Board;
  deltaX: number;
  deltaY: number;
}): Card => {
  if (card.x !== undefined && card.y !== undefined) {
    const x = card.x + deltaX;
    const y = card.y + deltaY;

    return getConstrainedCard(board, { ...card, x, y });
  } else {
    return card;
  }
};

export const cardWithResize = ({
  card,
  board,
  deltaX,
  deltaY,
}: {
  card: Card;
  board: Board;
  deltaX: number;
  deltaY: number;
}): Card => {
  if (card.width && card.height) {
    const width = card.width + deltaX;
    const height = card.height + deltaY;
    const fixedAspectRatio = card.cardType !== "Text" ? card.width / card.height : undefined;
    return getConstrainedCard(board, { ...card, width, height }, fixedAspectRatio);
  } else {
    logger.error(`applySizeToCard: cannot resize card that has no width or height`);
  }
  return card;
};

const maxWidth = Math.min(BoardDefaultSize.width, BoardDefaultSize.width);
// Don't use full board height. Card size is not always exact.
const maxHeight = Math.min(BoardDefaultSize.height, BoardDefaultSize.height) * 0.9;

export const getDefaultItemCardSize = (itemType: ItemType): Dimensions | undefined => {
  if (itemType === "audio") {
    return getCardSize(
      {
        height: 4000,
        width: 10000,
      },
      1
    );
  } else if (itemType === "pdf") {
    return {
      width: 20000,
      height: 15000,
    };
  }
};

const getCardSize = (dimensions: Dimensions, multiplier: number): Dimensions => {
  const width = dimensions.width;
  const height = dimensions.height;
  while (multiplier >= 1) {
    const newWidth = width * multiplier;
    const newHeight = height * multiplier;
    if (newWidth <= maxWidth && newHeight <= maxHeight) {
      return {
        width: newWidth,
        height: newHeight,
      };
    }
    multiplier = multiplier - 1;
  }
  // Dimensions are bigger than max. Limit on height since that blocks UI components.
  const newHeight = maxHeight;
  const newWidth = (width * height) / maxHeight;
  return {
    width: newWidth,
    height: newHeight,
  };
};

export const calculateMousePosition = (
  boardOffset: Position,
  boardToClientRatio: number,
  event: React.MouseEvent
) => {
  return {
    x: (event.clientX - boardOffset.x) * boardToClientRatio,
    y: (event.clientY - boardOffset.y) * boardToClientRatio,
  };
};

export const defaultCardPosition = (
  placementSize: Dimensions,
  board: Board,
  cards: Card[],
  dropPosition?: Position
): Position => {
  if (dropPosition) {
    return {
      // Some arbitrary offsets based on card size.
      x: dropPosition.x - placementSize.width / 2,
      y: dropPosition.y - placementSize.height / 4,
    };
  }

  if (board.type === "screenShare") {
    return { x: 0, y: 0 };
  }

  const offsetX = board.width / 20;
  const offsetY = board.height / 20;
  let x = offsetX;
  let y = offsetY;

  const isOccupied = (testX: number, testY: number) =>
    cards.some((c) => c.x === testX && c.y === testY);
  const willFit = (testX: number, testY: number) =>
    testX + placementSize.width <= board.width && testY + placementSize.height <= board.height;

  if (!willFit(x, y)) {
    // Center the card on the board
    return {
      x: (board.width - placementSize.width) / 2,
      y: (board.height - placementSize.height) / 2,
    };
  }
  while (isOccupied(x, y)) {
    // Place on a diagonal
    x += offsetX;
    y += offsetY;
    if (!willFit(x, y)) {
      // Ran out of space. Try to fit it as best as possible
      if (x + placementSize.width > board.width) {
        x = board.width - placementSize.width;
      }
      if (y + placementSize.height > board.height) {
        y = board.height - placementSize.height;
      }
      break;
    }
  }
  return {
    x,
    y,
  };
};

export const boardDisplayName = (board: Board) => {
  if (board.name) return board.name;
  if (board.type === "screenShare") {
    return "Shared Screen";
  }
  const boardName =
    board.type === "whiteboard"
      ? "Whiteboard"
      : board.type === "holoBoard"
        ? "Media Board"
        : "Board";
  if (board.created) {
    return `${boardName} created ${formatApproximateDateOrTime(new Date(board.created))}`;
  }
  return `Unnamed ${boardName}`;
};
