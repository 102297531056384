import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

// A limited set of icons supported within a toast.
export type ToastIconName =
  | "airpods"
  | "camera"
  | "microphone"
  | "record"
  | "record-mm"
  | "speaker";

export interface Toast {
  id: string;
  message: string;
  status: ToastStatus;
  // Specify if the toast should only show in a particular type of Electron window
  // (undefined will show in all windows)
  windowKey?: WindowKey;
  // An amount of time that the toast should display for before automatically disappearing
  // (undefined will show until explicitly removed)
  // Have a limited set of values (rather than each engineer picking a slightly different number)
  duration?: ToastDuration;
  // An optional icon to be displayed on the toast (if toast status is error,
  // an error icon will be displayed and this parameter will be ignored).
  icon?: ToastIconName;
  // When true, a close button will appear on the toast.
  isUserDismissable?: boolean;
  // Specify if toast should include any action buttons.
  actions?: ToastAction[];
}

export type ToastStatus = "info" | "error";

export enum ToastDuration {
  Short = 4000,
  Long = 8000,
  ExtraLong = 15000,
}

interface ToastAction {
  key: string;
  title: string;
  action: AnyAction;
}

const slice = createSlice({
  name: "toast",
  initialState: {
    toasts: [] as Toast[],
  },
  reducers: {
    showToast: (state, action: PayloadAction<Toast>) => {
      // A new toast should replace an existing toast with the same ID, keeping the ID unique and
      // preventing multiple toasts with the same ID from appearing multiple times.
      state.toasts = state.toasts
        .filter((t) => t.id !== action.payload.id)
        .concat([action.payload]);
    },
    removeToastById: (state, action: PayloadAction<string>) => {
      state.toasts = state.toasts.filter((t) => t.id !== action.payload);
    },
  },
});

export const { actions, reducer } = slice;

export const selectors = {
  selectToasts: (state: RootState) => state.world.toast.toasts,
};

export const toastErrorAction = (message: string, windowKey?: WindowKey) =>
  actions.showToast({
    id: uuidv4(),
    message,
    status: "error",
    duration: ToastDuration.Short,
    isUserDismissable: true,
    windowKey,
  });

export const toastInfoAction = (message: string, windowKey?: WindowKey) =>
  actions.showToast({
    id: uuidv4(),
    message,
    status: "info",
    duration: ToastDuration.Short,
    windowKey,
  });

export const ToastActions = actions;
export const ToastSelectors = selectors;
