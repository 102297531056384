import { z } from "zod";
import { numberId, stringDate, stringId, uuid } from "../Models/zodTypes.js";

export const ItemStatus = z.enum(["Uploading", "Errored", "Ready"]);
export type ItemStatus = z.infer<typeof ItemStatus>;

export const BaseItem = z
  .object({
    id: uuid(),
    data: z.object({}),
    name: z.string().optional(),
    thumbnail: z.string().optional(),
    /*
     * The field thumbnail is overloaded. When the client creates an item, thumbnail is an id.
     * When an item is requested from holo, holo converts the id so thumbnail becomes a URL.
     * Added thumbnailUrl to differentiate between the two. (Although due to back-compat,
     * the overload behavior can't be changed yet.)
     */
    thumbnailUrl: z.string().optional(),
    status: ItemStatus.optional(),
    dateAdded: stringDate().optional(), // Set by server.
    addedInAccount: numberId().optional(), // Set by server.
    addedByEmail: z.string().email().optional(), // Server will try to set it if client doesn't provide it.
    confidential: z.boolean().optional(),
  })
  .describe("goEmbed:BaseItem");
export type BaseItem = z.infer<typeof BaseItem>;

export const MovieItem = BaseItem.extend({
  itemType: z.literal("movie"),
  data: z.object({
    imdbId: stringId(),
    poster: z.string().url().optional(),
  }),
});
export type MovieItem = z.infer<typeof MovieItem>;

export const BookItem = BaseItem.extend({
  itemType: z.literal("book"),
  data: z.object({
    olWorkId: stringId().optional(), // formerly powered by https://openlibrary.org/
    olEditionId: stringId().optional(), // formerly powered by https://openlibrary.org/
    cover: z.string().optional(),
    author: z.string().optional(),
    year: z.string().optional(),
    googleBooksId: z.string().optional(),
    infoLink: z.string().url().optional(),
  }),
});
export type BookItem = z.infer<typeof BookItem>;

export const MusicItem = BaseItem.extend({
  itemType: z.literal("music"),
  data: z.object({
    kind: z.enum(["album", "artist", "track", "show"]),
    spotifyId: stringId(),
    spotifyUri: z.string().optional(),
    spotifyUrl: z.string().optional(),
    artist: z.string().optional(),
    album: z.string().optional(),
    image: z.string().optional(),
  }),
});
export type MusicItem = z.infer<typeof MusicItem>;

export const PhotoData = z.object({
  fileUrl: z.string().optional(),
  fileId: stringId().optional(),
  fileType: z.string().optional(),
  width: z.number().int().optional(),
  height: z.number().int().optional(),
  fileSize: z.number().int().optional(),
});
export type PhotoData = z.infer<typeof PhotoData>;

export const PhotoItem = BaseItem.extend({
  itemType: z.literal("photo"),
  data: PhotoData,
  localSourceUrl: z.string().optional(),
});
export type PhotoItem = z.infer<typeof PhotoItem>;

export const EmojiItem = BaseItem.extend({
  itemType: z.literal("emoji"),
  data: z.object({
    fileUrl: z.string().optional(),
    fileId: stringId().optional(),
    fileType: z.string().optional(),
    width: z.number().int().optional(),
    height: z.number().int().optional(),
    fileSize: z.number().int().optional(),
  }),
  localSourceUrl: z.string().optional(),
});
export type EmojiItem = z.infer<typeof EmojiItem>;

export const VideoData = z.object({
  source: z.enum(["file", "youTube", "recording"]),
  youTubeVideoId: stringId().optional(),
  fileUrl: z.string().optional(),
  fileId: stringId().optional(),
  recordingId: stringId().optional(),
  recordingPwd: z.string().optional(),
  width: z.number().int().optional(),
  height: z.number().int().optional(),
  duration: z.number().optional(),
  fileSize: z.number().int().optional(),
  fileType: z.string().optional(),
});
export type VideoData = z.infer<typeof VideoData>;

export const VideoItem = BaseItem.extend({
  itemType: z.literal("video"),
  data: VideoData,
  localSourceUrl: z.string().optional(),
  mediaToken: z.string().optional(),
});
export type VideoItem = z.infer<typeof VideoItem>;

export const StoryData = z.discriminatedUnion("dataType", [
  PhotoData.extend({
    dataType: z.literal("photo"),
  }),
  VideoData.extend({
    dataType: z.literal("video"),
  }),
]);
export type StoryData = z.infer<typeof StoryData>;

export const StoryItem = BaseItem.extend({
  itemType: z.literal("story"),
  data: StoryData,
  localSourceUrl: z.string().optional(),
  mediaToken: z.string().optional(),
  expiration: z.number().optional(),
});
export type StoryItem = z.infer<typeof StoryItem>;

export const AudioItem = BaseItem.extend({
  itemType: z.literal("audio"),
  data: z.object({
    fileUrl: z.string().optional(),
    fileId: stringId().optional(),
    duration: z.number().optional(),
    fileSize: z.number().int().optional(),
  }),
  localSourceUrl: z.string().optional(),
  mediaToken: z.string().optional(),
});
export type AudioItem = z.infer<typeof AudioItem>;

export const PdfItem = BaseItem.extend({
  itemType: z.literal("pdf"),
  data: z.object({
    fileId: stringId().optional(),
    fileUrl: z.string().optional(),
    fileSize: z.number().int().optional(),
  }),
  localSourceUrl: z.string().optional(),
  localThumbnailUrl: z.string().optional(),
});
export type PdfItem = z.infer<typeof PdfItem>;

export const WebPageItem = BaseItem.extend({
  itemType: z.literal("webPage"),
  pastedUrl: z.string().optional(),
  data: z.object({
    url: z.string().url().optional(),
    title: z.string().optional(),
    image: z.string().optional(),
    description: z.string().optional(),
  }),
});
export type WebPageItem = z.infer<typeof WebPageItem>;

export const validWebPageUrl = (url: string): boolean => {
  try {
    const urlObject = new URL(url);
    return urlObject.protocol === "http:" || urlObject.protocol === "https:";
  } catch {
    return false;
  }
};

export const FlagItem = BaseItem.extend({
  itemType: z.literal("flag"),
  data: z.object({
    code: z.string().nonempty(),
    name: z.string().optional(),
  }),
});
export type FlagItem = z.infer<typeof FlagItem>;

export const NftItem = BaseItem.extend({
  itemType: z.literal("nft"),
  data: z.object({
    collectionName: z.string(),
    tokenAddress: z.string(),
    tokenId: z.string(),

    // image could be either a URL or an ImageService image ID.
    image: z.string(),
    ownerEmail: z.string().email(),
  }),
});
export type NftItem = z.infer<typeof NftItem>;

export const GifItem = BaseItem.extend({
  itemType: z.literal("gif"),
  data: z.any(),
});
export type GifItem = z.infer<typeof GifItem>;

export const BlobItem = BaseItem.extend({
  itemType: z.literal("blob"),
  data: z.object({
    fileUrl: z.string().optional(),
    fileId: stringId().optional(),
    fileType: z.string().optional(),
    fileSize: z.number().int().optional(),
  }),
  localSourceUrl: z.string().optional(),
});
export type BlobItem = z.infer<typeof BlobItem>;

export const Item = z.discriminatedUnion("itemType", [
  MovieItem,
  BookItem,
  PhotoItem,
  MusicItem,
  VideoItem,
  StoryItem,
  AudioItem,
  PdfItem,
  WebPageItem,
  FlagItem,
  NftItem,
  GifItem,
  EmojiItem,
  BlobItem,
]);
export type Item = z.infer<typeof Item>;

export type ItemType = Item["itemType"];

export const itemTypeDisplayNames: {
  [itemType in ItemType]: string;
} = {
  movie: "Movie",
  book: "Book",
  photo: "Image",
  emoji: "Emoji",
  music: "Music",
  video: "Video",
  story: "Story",
  audio: "Audio",
  pdf: "PDF",
  webPage: "Link",
  flag: "Flag",
  nft: "NFT",
  gif: "GIF",
  blob: "File",
};

export const getVideoData = (item: Item): VideoData | undefined => {
  switch (item.itemType) {
    case "video":
      return item.data;
    case "story":
      return item.data.dataType === "video" ? item.data : undefined;
    default:
      return undefined;
  }
};

export const getPhotoData = (item: Item): PhotoData | undefined => {
  switch (item.itemType) {
    case "photo":
      return item.data;
    case "story":
      return item.data.dataType === "photo" ? item.data : undefined;
    default:
      return undefined;
  }
};
