import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import {
  crpc_invoices_FetchAccountPaginatedExternalInvoiceDetailsByInvoiceDate,
  crpc_invoices_FetchSubscriptionInvoiceStatus,
  crpc_invoices_FetchUpcomingExternalInvoice,
} from "../../../../shared/BillingMessages/external/ClientRpcInvoices.js";
import { ExternalInvoiceDetail } from "../../../../shared/Models/billing/invoices/ExternalInvoiceDetail.js";
import { ExternalInvoiceSummary } from "../../../../shared/Models/billing/invoices/ExternalInvoiceSummary.js";
import { ExternalSubscriptionInvoiceStatus } from "../../../../shared/Models/billing/invoices/ExternalSubscriptionInvoiceStatus.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const invoicesAdapter = createEntityAdapter<ExternalInvoiceDetail>({
  selectId: (invoice) => invoice.paymentSystemId,
});
const subscriptionInvoiceStatusAdapter = createEntityAdapter<ExternalSubscriptionInvoiceStatus>({
  selectId: (status) => status.subscriptionId,
});

const slice = createSlice({
  name: "invoice",
  initialState: {
    invoices: invoicesAdapter.getInitialState(),
    // key = subscription ID
    upcomingInvoices: {} as Record<number, ExternalInvoiceSummary>,
    subscriptionStatuses: subscriptionInvoiceStatusAdapter.getInitialState(),
  },
  reducers: {
    // Saga
    fetchAccountPaginatedInvoiceDetailsByInvoiceDate: (
      state,
      action: PayloadAction<
        Omit<crpc_invoices_FetchAccountPaginatedExternalInvoiceDetailsByInvoiceDate, "kind">
      >
    ) => {},
    fetchUpcomingInvoiceBySubscriptionId: (
      state,
      action: PayloadAction<Omit<crpc_invoices_FetchUpcomingExternalInvoice, "kind">>
    ) => {},
    fetchSubscriptionInvoiceStatus: (
      state,
      action: PayloadAction<Omit<crpc_invoices_FetchSubscriptionInvoiceStatus, "kind">>
    ) => {},

    // Redux
    setInvoices: (state, action: PayloadAction<ExternalInvoiceDetail[]>) => {
      invoicesAdapter.setMany(state.invoices, action.payload);
    },
    setUpcomingInvoice: (state, action: PayloadAction<ExternalInvoiceSummary>) => {
      const invoice = action.payload;
      state.upcomingInvoices[invoice.subscriptionId] = invoice;
    },
    setSubscriptionInvoiceStatus: (
      state,
      action: PayloadAction<ExternalSubscriptionInvoiceStatus>
    ) => {
      subscriptionInvoiceStatusAdapter.setOne(state.subscriptionStatuses, action.payload);
    },
  },
});

export const { actions, reducer } = slice;
export const InvoicesActions = actions;
export const invoicesReducer = reducer;

const selectSlice = (state: RootState) => state.billing.invoices;
const invoicesSelectors = invoicesAdapter.getSelectors();
const subscriptionInvoiceStatusSelectors = subscriptionInvoiceStatusAdapter.getSelectors();
export const selectors = {
  selectInvoicesByAccountId: defaultMemoize((accountId: number) =>
    createDeepEqualSelector(selectSlice, (slice): ExternalInvoiceDetail[] => {
      return invoicesSelectors
        .selectAll(slice.invoices)
        .filter((invoice) => invoice.accountId === accountId);
    })
  ),
  selectUpcomingInvoiceBySubscriptionId: (subscriptionId: number) => (state: RootState) =>
    state.billing.invoices.upcomingInvoices[subscriptionId],
  selectSubscriptionInvoiceStatus: (subscriptionId: number) => (state: RootState) =>
    subscriptionInvoiceStatusSelectors.selectById(
      state.billing.invoices.subscriptionStatuses,
      subscriptionId
    ),
};
export const InvoicesSelectors = selectors;
