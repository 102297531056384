/* eslint-disable no-restricted-globals */
import { createContext, useContext } from "react";

/**
 * Provides the correct globals for the window in which a React component is being rendered.
 */
export const GlobalsContext = createContext<{
  document: Document;
  navigator: Navigator;
  window: Window & typeof globalThis;
}>({
  document,
  navigator,
  window,
});

export const useGlobals = () => {
  return useContext(GlobalsContext);
};

export const useDocument = () => {
  return useContext(GlobalsContext).document;
};

export const useNavigator = () => {
  return useContext(GlobalsContext).navigator;
};

export const useWindow = () => {
  return useContext(GlobalsContext).window;
};
