import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import {
  Board,
  BoardDefaultSize,
  EditableBoard,
  EditableBoardType,
} from "../../../../shared/Models/Board.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";
import { CardSelectors } from "./cardSlice.js";

export interface RenameBoardPayload {
  boardId: string;
  name?: string;
}

export interface AddBoardPayload {
  ownerRoomId?: number;
  ownerPersonId?: number;
  startOnScreenKey?: string;
  makeActiveOnScreen?: boolean;
  type: EditableBoardType;
}

const adapter = createEntityAdapter<Board>();

const slice = createSlice({
  name: "board",
  initialState: adapter.getInitialState(),
  reducers: {
    addBoard: (state, action: PayloadAction<AddBoardPayload>) => {},
    deleteBoard: (state, action: PayloadAction<string>) => {},
    renameBoard: (state, action: PayloadAction<RenameBoardPayload>) => {},

    serverAddUpdateBoards: (state, action: PayloadAction<Board[]>) => {
      adapter.upsertMany(state, action.payload);
    },
    serverDeleteBoards: (state, action: PayloadAction<string[]>) => {
      adapter.removeMany(state, action.payload);
    },

    requestBoardsOwnedByRoom: (state, action: PayloadAction<number>) => {},
  },
});

export const { actions: BoardActions, reducer: boardReducer } = slice;

const adapterSelectors = adapter.getSelectors((state: RootState) => state.holo.board);
const selectSlice = (state: RootState) => state.holo.board;
const selectById = defaultMemoize(
  (boardId?: string) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      boardId ? slice.entities?.[boardId] : undefined
    ),
  { maxSize: 10 }
);
export const BoardSelectors = {
  ...adapterSelectors,
  selectById,
  selectNamedBoardsOwnedByRoom: defaultMemoize((roomId?: number) =>
    createDeepEqualSelector(adapterSelectors.selectAll, (boards) =>
      boards.filter(
        (board): board is EditableBoard =>
          board.type !== "screenShare" &&
          roomId !== undefined &&
          board.roomId === roomId &&
          board.name !== undefined
      )
    )
  ),
  selectOpenBoardsOnActiveScreen: createDeepEqualSelector(
    (state: RootState) => state.holo.screen.internalRenderScreenKey.screen,
    (state: RootState) => state.holo.screen.openBoards,
    adapterSelectors.selectAll,
    (screen, openIds, boards) => {
      if (!screen?.screenKey) return undefined;
      const boardIds = openIds[screen.screenKey];
      if (!boardIds) return undefined;
      return boardIds
        .map((id) => boards.find((board) => board.id === id))
        .filter(Boolean) as Board[];
    }
  ),
  selectBoardDimensions: defaultMemoize((boardId?: string) =>
    createDeepEqualSelector(
      selectById(boardId),
      CardSelectors.selectCardsByBoard(boardId),
      (board, cards) => {
        if (!board) {
          return undefined;
        }

        if (board?.type !== "screenShare") {
          return { width: board.width, height: board.height };
        }
        if (
          board?.type === "screenShare" &&
          cards.length === 1 &&
          cards[0]?.width &&
          cards[0].height
        ) {
          return { width: cards[0].width, height: cards[0].height };
        }
        return BoardDefaultSize;
      }
    )
  ),
};
