import { z } from "zod";
import { cleanEmail } from "../helpers/validators.js";
import { numberId, stringId, uuid } from "./zodTypes.js";

export const TargetKeyType = z.enum([
  "email",
  "client",
  "occupant",
  "bot",
  "teamRoam",
  "standardGroup",
  "meetingGroup",
  "addressId",
]);
export type TargetKeyType = z.infer<typeof TargetKeyType>;

export const TargetKeyBase = z.object({
  targetType: TargetKeyType,
});
export type TargetKeyBase = z.infer<typeof TargetKeyBase>;

export const EmailTargetKey = TargetKeyBase.extend({
  targetType: z.literal("email"),
  email: z.string().email(),
});
export type EmailTargetKey = z.infer<typeof EmailTargetKey>;

/**
 * Creates an `EmailTargetKey`.
 *
 * Please prefer this method over manually creating an email target.
 * This is generally preferable for all target keys, but especially useful for the email target
 * key since it also sanitizes the input.
 */
export const emailTarget = (email: string): EmailTargetKey => {
  return {
    targetType: "email",
    email: cleanEmail(email),
  };
};

export const ClientTargetKey = TargetKeyBase.extend({
  targetType: z.literal("client"),
  clientUuid: uuid(),
});
export type ClientTargetKey = z.infer<typeof ClientTargetKey>;

/**
 * Creates a `ClientTargetKey`.
 */
export const clientTarget = (clientUuid: string): ClientTargetKey => {
  return {
    targetType: "client",
    clientUuid,
  };
};

// Deprecated (remove-occupant-address)
export const OccupantTargetKey = TargetKeyBase.extend({
  targetType: z.literal("occupant"),
  occupantId: stringId(),
  roamId: z.number(),
});
export type OccupantTargetKey = z.infer<typeof OccupantTargetKey>;

/**
 * Creates an `OccupantTargetKey`.
 */
export const occupantTarget = (occupantId: string, roamId: number): OccupantTargetKey => {
  return {
    targetType: "occupant",
    occupantId,
    roamId,
  };
};

export const BotTargetKey = TargetKeyBase.extend({
  targetType: z.literal("bot"),
  roamId: numberId(),
  integrationId: stringId(),
  botCode: stringId(),
});
export type BotTargetKey = z.infer<typeof BotTargetKey>;

export const botTarget = (integrationId: string, botCode: string, roamId: number): BotTargetKey => {
  return {
    targetType: "bot",
    integrationId,
    botCode,
    roamId,
  };
};

/**
 * `TargetKey` subtypes that are appropriate to identify a user.
 */
export const UserTargetKey = z.union([EmailTargetKey, ClientTargetKey, OccupantTargetKey]);
export type UserTargetKey = z.infer<typeof UserTargetKey>;

export const isUserTargetKey = (chatTarget: TargetKey): chatTarget is UserTargetKey => {
  switch (chatTarget.targetType) {
    case "email":
    case "client":
    case "occupant":
      return true;
    default:
      return false;
  }
};

/**
 * `TargetKey` subtypes that are appropriate to identify a single "entity".
 */
export const SingleTargetKey = z.union([
  EmailTargetKey,
  ClientTargetKey,
  OccupantTargetKey,
  BotTargetKey,
]);
export type SingleTargetKey = z.infer<typeof SingleTargetKey>;

export const isSingleTargetKey = (chatTarget: TargetKey): chatTarget is SingleTargetKey => {
  switch (chatTarget.targetType) {
    case "email":
    case "client":
    case "occupant":
    case "bot": {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const TeamRoamTargetKey = TargetKeyBase.extend({
  targetType: z.literal("teamRoam"),
  memberEmail: z.string().email(), // member email for customer the chat is with
});
export type TeamRoamTargetKey = z.infer<typeof TeamRoamTargetKey>;

export const AddressIdTargetKey = TargetKeyBase.extend({
  targetType: z.enum(["standardGroup", "meetingGroup", "addressId"]),
  addressId: stringId(),
});
export type AddressIdTargetKey = z.infer<typeof AddressIdTargetKey>;

/**
 * A way for clients and backends to specify ChatAddress recipients for cases when the addressId
 * may or may not be known.
 *
 * Note that a single ChatAddress can be referenced by multiple different keys. For example, an
 * AddressIdTargetKey, EmailTargetKey, and OccupantTargetKey may all lead to the same ChatAddress.
 *
 * Each key, however, can only resolve to up to a single ChatAddress.
 */
export const TargetKey = z.discriminatedUnion("targetType", [
  EmailTargetKey,
  ClientTargetKey,
  OccupantTargetKey,
  BotTargetKey,
  TeamRoamTargetKey,
  AddressIdTargetKey,
]);
export type TargetKey = z.infer<typeof TargetKey>;

/**
 * Direct target keys are those that can be resolved to a ChatAddress with no information from
 * outside of the addresses system.
 *
 * For example, OccupantTargetKey is not direct because it requires information about the occupant
 * from section.
 */
export const DirectTargetKey = z.discriminatedUnion("targetType", [
  EmailTargetKey,
  ClientTargetKey,
  BotTargetKey,
  TeamRoamTargetKey,
  AddressIdTargetKey,
]);
export type DirectTargetKey = z.infer<typeof DirectTargetKey>;
