import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface ToggleShelfPayload {
  floorId: number;
  roomId: number;
  shelfId: string;
}

export interface OpenItemPayload {
  floorId: number;
  roomId: number;
  shelfId: string;
  itemId: string;
}

const slice = createSlice({
  name: "shelfView",
  initialState: {
    openShelfId: undefined as string | undefined,
    openItemId: undefined as string | undefined,
  },
  reducers: {
    toggleShelf: (state, action: PayloadAction<ToggleShelfPayload>) => {
      const { shelfId } = action.payload;
      if (state.openShelfId === shelfId) {
        state.openShelfId = undefined;
        state.openItemId = undefined;
      } else {
        state.openShelfId = shelfId;
        state.openItemId = undefined;
      }
    },
    closeShelf: (state) => {
      state.openShelfId = undefined;
      state.openItemId = undefined;
    },
    closeShelfIfOpenTo: (state, action: PayloadAction<string>) => {},

    openItem: (state, action: PayloadAction<OpenItemPayload>) => {
      const { shelfId, itemId } = action.payload;
      state.openShelfId = shelfId;
      state.openItemId = itemId;
    },
    closeItem: (state) => {
      state.openItemId = undefined;
    },

    escape: (state) => {},
    arrow: (state, action: PayloadAction<string>) => {},
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.things.shelfView;
export const selectors = {
  selectOpenShelfId: createSelector(selectSlice, (slice) => slice.openShelfId),
  selectOpenItemId: createSelector(selectSlice, (slice) => slice.openItemId),
};

export const ShelfViewSelectors = selectors;
export const ShelfViewActions = actions;
