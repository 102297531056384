import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { MediaReplayInfo } from "../../../../shared/Models/MediaReplayInfo.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

type SliceState = {
  allReplayInfo: { [replayId: string]: MediaReplayInfo | undefined };
};

const initialState: SliceState = {
  allReplayInfo: {},
};

const toReplayId = (info: { cardId: string; screenKey: string }) =>
  `${info.cardId}_${info.screenKey}`;

export interface RequestReplayInfoPayload {
  cardId: string;
  boardId: string;
  screenKey: string;
}

const slice = createSlice({
  name: "mediaReplay",
  initialState,
  reducers: {
    sendReplayInfo: (state, action: PayloadAction<MediaReplayInfo>) => {},
    requestReplayInfo: (state, action: PayloadAction<RequestReplayInfoPayload>) => {},
    serverUpdateReplayInfo: (state, action: PayloadAction<MediaReplayInfo>) => {
      const replayInfo = action.payload;
      const replayId = toReplayId(replayInfo);
      state.allReplayInfo[replayId] = replayInfo;
    },
    clearLocalReplayInfo: (state, action: PayloadAction<RequestReplayInfoPayload>) => {
      const replayInfo = action.payload;
      const replayId = toReplayId(replayInfo);
      state.allReplayInfo[replayId] = undefined;
    },
  },
});

export const { actions: MediaReplayActions, reducer: mediaReplayReducer } = slice;

const selectSlice = (state: RootState) => state.holo.mediaReplay;
export const MediaReplaySelectors = {
  replayInfoSelector: defaultMemoize(
    (cardId?: string, screenKey?: string) =>
      createSelector(selectSlice, (slice) => {
        if (cardId && screenKey) {
          const replayId = toReplayId({ cardId, screenKey });
          const replayInfo = slice.allReplayInfo?.[replayId];
          return replayInfo;
        }
      }),
    {
      equalityCheck: equal,
      maxSize: 10,
    }
  ),
};
