import { AsyncRWLock } from "../../shared/helpers/rwLock.js";
import { logger } from "../../shared/infra/logger.js";
import { AVPermissionSelectors } from "../holo/store/slices/avPermissionSlice.js";
import { ClientContainerInfo } from "../injection/IClientContainer.js";
import { IRedux } from "../injection/redux/IRedux.js";
import { RefCountRawStream } from "./RawStream.js";
import { IAvDevices } from "./interfaces/IAvDevices.js";
import { IFakeStreams } from "./interfaces/IFakeStreams.js";
import { IRawStream } from "./interfaces/IRawStreamManager.js";

interface Injected {
  avDevices(): IAvDevices;
  fakeStreams(): IFakeStreams;
  info(): ClientContainerInfo;
  redux(): IRedux;
}

export abstract class RawStreamManager {
  protected lock = new AsyncRWLock();
  protected streamPromises = new Map<string | undefined, Promise<RefCountRawStream>>();
  protected hasCapturedOnce = false;

  constructor(
    protected container: Injected,
    protected type: "audio" | "video"
  ) {}

  public async getStream(deviceId: string | undefined): Promise<IRawStream> {
    return this.lock.readGuard(async () => {
      const key = deviceId;

      while (true) {
        const streamPromise = this.streamPromises.get(key);
        if (!streamPromise) {
          // No entry in the map; break out and start getting a new stream
          break;
        }
        // Found an entry; await it
        const stream = await streamPromise;
        if (!stream.isClosed()) {
          // Still alive; return a clone
          return stream.clone();
        }
        // It was closed in the meantime; restart the loop and check if there's a new promise in the
        // map to await
      }

      const warnTimeoutId = setTimeout(() => {
        const state = this.container.redux().getState();
        if (!state) {
          return;
        }
        const permissionStatus =
          AVPermissionSelectors[
            this.type === "video" ? "selectCameraPermission" : "selectMicrophonePermission"
          ](state);
        logger.warn(
          `RawStreamManager.getStream: getUserMedia() call for ${this.type} device (deviceId ${deviceId}) is taking a long time (permission status: ${permissionStatus})`
        );
      }, 5 * 1000);

      // Start obtaining a new stream
      const streamPromise = this.getStreamInner(deviceId).then(
        (mediaStream) =>
          new RefCountRawStream(mediaStream, () => {
            this.streamPromises.delete(key);
          })
      );
      // Put it in the map so other callers can find it
      this.streamPromises.set(key, streamPromise);
      try {
        // NOTE: It is *critical* to await the Promise before returning, rather than returning
        // the promise directly. We need to be able to catch the error and delete it from
        // streamPromises.
        const stream = await streamPromise;
        // Return a *clone* of the stream, but keep around the original
        const clone = stream.clone();
        // The count started at 1, and clone() incremented it; decrement it back down
        // So now the only "reference" is the clone
        stream.decref();
        if (!this.hasCapturedOnce) {
          // First audio/video stream capture
          // User may have just granted permission; refresh the device list to see if the browser filled it out
          this.hasCapturedOnce = true;
          setTimeout(() => this.container.avDevices().refreshDeviceList());
        }
        return clone;
      } catch (err: any) {
        this.streamPromises.delete(key);
        throw err;
      } finally {
        clearTimeout(warnTimeoutId);
      }
    });
  }

  protected abstract getStreamInner(deviceId: string | undefined): Promise<MediaStream>;

  public forceReopenAllStreams(): void {
    // Acquire a write-lock (which will halt any calls to getStream in the meantime)
    this.lock
      .writeGuard(async () => {
        // Close every stream
        const promises = [...this.streamPromises.values()];
        this.streamPromises.clear();
        await Promise.race([
          // Close all the streams
          Promise.all(
            promises.map(async (streamPromise) => {
              try {
                const stream = await streamPromise;
                // This will also close all clones
                stream.close();
              } catch {
                // Getting the stream failed
                // Shouldn't happen (should be caught elsewhere), but means we have nothing to do
              }
            })
          ),
          // Time out after 5 seconds (sanity check)
          new Promise((resolve) => setTimeout(resolve, 5_000)),
        ]);
      })
      .catch((err) => logger.warn({ err }, `Error closing all streams`));
  }

  public snapshot(): any {
    return {
      streamPromises: [...this.streamPromises.keys()],
      hasCapturedOnce: this.hasCapturedOnce,
    };
  }
}
