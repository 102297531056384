import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { z } from "zod";
import { crpc_badge_UpdateAdminSettings } from "../../../../shared/AnyWorldMessages/ClientRpcAccessBadge.js";
import {
  AccessBadge,
  AccessBadgeKey,
  DerivedAccessBadge,
} from "../../../../shared/Models/AccessBadge.js";
import { AccessBadgeActionSource } from "../../../../shared/Models/AccessBadgeActionSource.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

export const CreateAccessBadgePayload = z.object({
  accessBadge: AccessBadge,
  source: AccessBadgeActionSource,
  windowKey: WindowKey,
});
export type CreateAccessBadgePayload = z.infer<typeof CreateAccessBadgePayload>;

export const RevokeAccessBadgePayload = z.object({
  accessBadgeKey: AccessBadgeKey,
  source: AccessBadgeActionSource,
});
export type RevokeAccessBadgePayload = z.infer<typeof RevokeAccessBadgePayload>;

export const HideAccessBadgePayload = z.object({
  accessBadgeKey: AccessBadgeKey,
  hidden: z.boolean(),
});
export type HideAccessBadgePayload = z.infer<typeof HideAccessBadgePayload>;

export const UpdateVisitPermissionPayload = z.object({
  accessBadgeKey: AccessBadgeKey,
  visitPermission: z.boolean(),
});
export type UpdateVisitPermissionPayload = z.infer<typeof UpdateVisitPermissionPayload>;

export const UpdateAdminSettingsPayload = crpc_badge_UpdateAdminSettings.omit({ kind: true });
export type UpdateAdminSettingsPayload = z.infer<typeof UpdateAdminSettingsPayload>;

export const OpenGuestSettingsPayload = z.object({
  guestEmail: z.string().optional(),
  windowKey: WindowKey,
});
export type OpenGuestSettingsPayload = z.infer<typeof OpenGuestSettingsPayload>;

export const RequestManageableGroupsForGuestPayload = z.object({
  guestAddressId: z.string(),
  accountId: z.number(),
  windowKey: WindowKey,
});
export type RequestManageableGroupsForGuestPayload = z.infer<
  typeof RequestManageableGroupsForGuestPayload
>;

export const SetManageableGroupsForGuestPayload = z.object({
  guestAddressId: z.string(),
  accountId: z.number(),
  groupIds: z.string().array(),
});
export type SetManageableGroupsForGuestPayload = z.infer<typeof SetManageableGroupsForGuestPayload>;

/**
 * Convenience function to construct IDs for the access badge entity adapter.
 *
 * An alternative to this would be to add an id field to the access badge model.
 */
const badgeKey = (accessBadge: AccessBadgeKey): string =>
  manualBadgeKey(accessBadge.granterPersonId, accessBadge.granteeEmail, accessBadge.accountId);

const manualBadgeKey = (granterPersonId: number, granteeEmail: string, accountId: number): string =>
  `${accountId}-${granterPersonId}-${granteeEmail}`;

const adapter = createEntityAdapter<DerivedAccessBadge>({
  selectId: (badge) => badgeKey(badge),
});
const adapterSelectors = adapter.getSelectors();

const slice = createSlice({
  name: "accessBadge",
  initialState: {
    accessBadges: adapter.getInitialState(),
    guestGroupMembership: {} as { [addressId: string]: { [accountId: number]: string[] } },

    // Loaded and updated from settings upon app start.
    showAccessBadgeInformationPopup: true,
  },
  reducers: {
    /**
     * If `showAccessBadgeInformationPopup` is true, shows an info popup before allowing the user
     * to grant an access badge (to make sure that they know what they're doing).
     *
     * If it's false, creates the access badge as normal (i.e. identical to createAccessBadge).
     */
    createAccessBadgeWithInfoPopup: (state, action: PayloadAction<CreateAccessBadgePayload>) => {},

    // RPC - Badges
    createAccessBadge: (state, action: PayloadAction<CreateAccessBadgePayload>) => {},
    fetchAccessBadgeByKey: (state, action: PayloadAction<AccessBadgeKey>) => {},
    fetchAccessBadgesByGranterPersonId: (state, action: PayloadAction<number>) => {},
    /**
     * Fetches access badges granted to the requested email, or any of its aliases.
     *
     * There is (currently) no slice/saga/crpc for fetching the requested email without aliases,
     * as it seems like the client would always want to include aliases.
     */
    fetchAccessBadgesByGranteeEmail: (state, action: PayloadAction<string>) => {},
    fetchAccessBadgesByGranteeEmails: (state, action: PayloadAction<string[]>) => {},
    revokeAccessBadge: (state, action: PayloadAction<RevokeAccessBadgePayload>) => {},
    hideAccessBadge: (state, action: PayloadAction<HideAccessBadgePayload>) => {},
    updateVisitPermission: (state, action: PayloadAction<UpdateVisitPermissionPayload>) => {},
    /**
     * Refreshes access badges granted by the given person ID.
     */
    refreshAccessBadgesByGranterPersonId: (state, action: PayloadAction<number>) => {},
    /**
     * Refreshes access badges granted to the requested emails, or any of their aliases.
     */
    refreshAccessBadgesByGranteeEmails: (state, action: PayloadAction<string[]>) => {},
    /**
     * Refreshes access badges granted to any confirmed emails, or any of their aliases.
     */
    refreshMyGuestBadges: (state) => {},

    // RPC - Admin Settings
    updateAdminSettings: (state, action: PayloadAction<UpdateAdminSettingsPayload>) => {},

    // Adapter
    upsertAccessBadge: (state, action: PayloadAction<DerivedAccessBadge>) => {
      adapter.upsertOne(state.accessBadges, action.payload);
    },
    upsertAccessBadges: (state, action: PayloadAction<DerivedAccessBadge[]>) => {
      adapter.upsertMany(state.accessBadges, action.payload);
    },
    clearAccessBadge: (state, action: PayloadAction<AccessBadgeKey>) => {
      adapter.removeOne(state.accessBadges, badgeKey(action.payload));
    },
    /**
     * Clears all access badges granted by the given person ID.
     */
    clearGranterBadges: (state, action: PayloadAction<number>) => {
      const toRemove = adapterSelectors
        .selectAll(state.accessBadges)
        .filter((badge) => badge.granterPersonId === action.payload)
        .map(badgeKey);
      adapter.removeMany(state.accessBadges, toRemove);
    },
    /**
     * Clears all access badges granted to any of the given alias emails.
     */
    clearGranteeAliasBadges: (state, action: PayloadAction<string[]>) => {
      const emails = action.payload;
      const toRemove = adapterSelectors
        .selectAll(state.accessBadges)
        .filter((badge) => emails.includes(badge.granteeAliasEmail))
        .map(badgeKey);
      adapter.removeMany(state.accessBadges, toRemove);
    },

    // Client preferences
    /**
     * Updates showAccessBadgeInformationPopup slice state.
     *
     * Most callers should not use this action directly, use saveShowAccessBadgeInformationPopup instead.
     */
    setShowAccessBadgeInformationPopup: (state, action: PayloadAction<boolean>) => {
      state.showAccessBadgeInformationPopup = action.payload;
    },
    /**
     * Loads the showAccessBadgeInformationPopup, adding it to this slice's state.
     */
    loadShowAccessBadgeInformationPopup: (state) => {},
    /**
     * Persists showAccessBadgeInformationPopup to client store.
     * Also updates slice state.
     */
    saveShowAccessBadgeInformationPopup: (state, action: PayloadAction<boolean>) => {},

    openGuestSettings: (state, action: PayloadAction<OpenGuestSettingsPayload>) => {},

    requestManageableGroupsForGuest: (
      state,
      action: PayloadAction<RequestManageableGroupsForGuestPayload>
    ) => {},
    setGuestGroupMembership: (state, action: PayloadAction<SetManageableGroupsForGuestPayload>) => {
      const { guestAddressId, accountId, groupIds } = action.payload;

      const accountToGroups = state.guestGroupMembership[guestAddressId] ?? {};
      accountToGroups[accountId] = groupIds;

      state.guestGroupMembership[guestAddressId] = accountToGroups;
    },
  },
});

export const { actions, reducer } = slice;
export const AccessBadgeActions = actions;
export const AccessBadgeReducer = reducer;

const selectSlice = (state: RootState) => state.anyWorld.accessBadge;
export const selectors = {
  /**
   * Selects the access badge with the given [key] in the redux store, if there is one.
   *
   * If [allowAlias] is true, returns an access badge where either the granteeEmail or
   * granteeAliasEmail matches the key's granteeEmail.
   * If there are multiple such badges, returns an arbitrary one.
   */
  selectAccessBadgeByKey: defaultMemoize(
    (accessBadgeKey: AccessBadgeKey, allowAlias = false) => {
      const { granterPersonId, granteeEmail, accountId } = accessBadgeKey;
      return createSelector(selectSlice, (slice): DerivedAccessBadge | undefined => {
        if (!allowAlias) {
          const key = badgeKey(accessBadgeKey);
          return adapterSelectors.selectById(slice.accessBadges, key);
        } else {
          const badges = adapterSelectors
            .selectAll(slice.accessBadges)
            .filter((badge) => badge.accountId === accountId)
            .filter((badge) => badge.granterPersonId === granterPersonId)
            .filter(
              (badge) =>
                badge.granteeEmail === granteeEmail || badge.granteeAliasEmail === granteeEmail
            );
          // This could potentially return multiple badges if someone has created badges for
          // aliased emails.
          // These should functionally be for the same person anyway, so I think it's okay if we
          // return an arbitrary one.
          return badges.length ? badges[0] : undefined;
        }
      });
    },
    { equalityCheck: equal }
  ),
  selectAccessBadgesByGranterPersonId: defaultMemoize((personId: number | undefined) =>
    createDeepEqualSelector(selectSlice, (slice): DerivedAccessBadge[] => {
      const badges =
        personId === undefined
          ? []
          : adapterSelectors
              .selectAll(slice.accessBadges)
              .filter((badge) => badge.granterPersonId === personId);

      const granteeName = (badge: DerivedAccessBadge) =>
        badge.grantee?.name ?? badge.granteeAddress?.displayName ?? badge.granteeEmail;

      return badges.sort((a, b) =>
        granteeName(a).localeCompare(granteeName(b), undefined, { sensitivity: "base" })
      );
    })
  ),
  /**
   * Selects access badges by their grantee alias email.
   *
   * Use this to select badges that were fetched by [email], allowing aliases.
   */
  selectAccessBadgesByGranteeAliasEmail: defaultMemoize((email?: string | undefined) =>
    createDeepEqualSelector(selectSlice, (slice): DerivedAccessBadge[] => {
      return email === undefined
        ? []
        : adapterSelectors
            .selectAll(slice.accessBadges)
            .filter((badge) => badge.granteeAliasEmail === email);
    })
  ),
  selectAccessBadgesByGranteeAliasEmails: defaultMemoize(
    (emails: string[]) =>
      createDeepEqualSelector(selectSlice, (slice): DerivedAccessBadge[] => {
        return adapterSelectors
          .selectAll(slice.accessBadges)
          .filter((badge) => emails.includes(badge.granteeAliasEmail));
      }),
    { equalityCheck: equal }
  ),
  /**
   * Selects all access badges that have a grantee alias email in [emails] and that give access to
   * roam [roamId].
   */
  selectAccessBadgesByGranteeAliasEmailsAndRoamId: defaultMemoize(
    (emails: string[], roamId: number) =>
      createDeepEqualSelector(selectSlice, (slice): DerivedAccessBadge[] => {
        return adapterSelectors
          .selectAll(slice.accessBadges)
          .filter((badge) => emails.includes(badge.granteeAliasEmail))
          .filter((badge) => badge.roamIds.includes(roamId));
      }),
    { equalityCheck: equal }
  ),
  selectShowAccessBadgeInformationPopup: (state: RootState) =>
    selectSlice(state).showAccessBadgeInformationPopup,

  selectManageableGroupsForGuest: defaultMemoize(
    (guestAddressId: string | undefined, accountId: number | undefined) =>
      createDeepEqualSelector(selectSlice, (slice): string[] =>
        guestAddressId && accountId
          ? slice.guestGroupMembership[guestAddressId]?.[accountId] ?? []
          : []
      )
  ),
};
export const AccessBadgeSelectors = selectors;
