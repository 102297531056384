/**
 * The type of a comparison function
 */
type Comparator<T> = (a: T, b: T) => number;

/**
 * Comparator function to compare strings in a case-insensitive way, in the current locale.
 */
export const caseInsensitiveSort: Comparator<string> = (a, b) => {
  return a.localeCompare(b, undefined, { sensitivity: "base" });
};

/**
 * Comparator function generator that sorts based on a specific field
 *
 * @param key The key to sort on
 * @param f   The comparison function to apply to that key's value in the sorted objects
 * @returns   A comparison function that sorts a list of objects based on `f` applied to `o[key]`.
 */
export const byField = <T, K extends keyof T>(key: K, f: Comparator<T[K]>): Comparator<T> => {
  return (a, b) => {
    return f(a[key], b[key]);
  };
};

/**
 * Comparator function generator that sorts by prioritized comparison functions.
 * In order, if a comparison function returns equal, then the next function is
 * used.
 *
 * @param fs The list of functions to compare with in order
 * @return   The comparison function made up by applying fs in order
 */
export const cartesian = <T>(fs: Array<Comparator<T>>): Comparator<T> => {
  return (a, b) => {
    for (const f of fs) {
      const c = f(a, b);
      if (c) {
        return c;
      }
    }
    return 0;
  };
};

/**
 * Comparator function that prioritizes elements where property `p` holds over
 * elements where `p` does not hold.
 *
 * @param p The property to check for each elements
 * @returns A comparison function prioritizing `p`
 */
export const prioritize = <T>(p: (x: T) => boolean): Comparator<T> => {
  return (a, b) => {
    // Convert to numbers
    const pa = p(a) ? 1 : 0;
    const pb = p(b) ? 1 : 0;
    // Invert sort order, since we want 1 to come before 0
    return pb - pa;
  };
};

/**
 * Returns the index an element would have to be placed directly after, if the
 * list were to remain in sorted order, within sub range [start, end)
 *
 * @param f The comparison function
 * @param l The list to check
 * @param a The element to check
 * @param start The inclusive first index of the sub range to check
 * @param end The exclusive last index of the sub range to check
 */
const sortedIndex = <T>(f: Comparator<T>, l: T[], a: T, start: number, end: number): number => {
  const pivot = Math.floor(start + (end - start) / 2);
  if (end - start <= 1) {
    return pivot;
  }

  const b = l[pivot];
  if (b === undefined) {
    throw new Error("method called wrong");
  }

  const c = f(a, b);
  if (c === 0) {
    return pivot;
  } else if (c < 0) {
    return sortedIndex(f, l, a, start, pivot);
  } else {
    return sortedIndex(f, l, a, pivot, end);
  }
};

/**
 * Inserts an element into a sorted list, using the provided comparison
 * function.
 *
 * @param f The comparison function to
 * @param l The list to insert into
 * @param a The item to insert
 * @requires `l` is in sorted order according to `f`
 * @returns A sorted list containing the exact items in `l` previously and `a`.
 *          The returned list is in the same order as `l`, and `a` has only
 *          been inserted.
 */
export const insertSorted = <T>(f: Comparator<T>, l: T[], a: T): T[] => {
  const index = sortedIndex(f, l, a, 0, l.length);
  const out = [...l];
  out.splice(index + 1, 0, a);
  return out;
};
