import { combineReducers } from "redux";
import { AccessBadgeReducer } from "./slices/accessBadgeSlice.js";
import { reducer as accountReducer } from "./slices/accountSlice.js";
import { reducer as actionRecordReducer } from "./slices/actionRecordSlice.js";
import { reducer as adminAllPeopleReducer } from "./slices/adminAllPeopleSlice.js";
import { reducer as adminAllRoamsReducer } from "./slices/adminAllRoamsSlice.js";
import { reducer as advancedSearchReducer } from "./slices/advancedSearchSlice.js";
import { reducer as anyWorldReducer } from "./slices/anyWorldSlice.js";
import { AuthenticationReducer } from "./slices/authenticationSlice.js";
import { reducer as complianceReducer } from "./slices/complianceSettingsSlice.js";
import { reducer as cryptoAddressReducer } from "./slices/cryptoAddressSlice.js";
import { ExternalSocialLinkReducer } from "./slices/externalSocialLinksSlice.js";
import { reducer as foundersTourReducer } from "./slices/foundersTourSlice.js";
import { reducer as groupReducer } from "./slices/groupSlice.js";
import { reducer as groupViewReducer } from "./slices/groupViewSlice.js";
import { reducer as magicMinutesReducer } from "./slices/magicMinutesSlice.js";
import { reducer as meetingStatsReducer } from "./slices/meetingStatsSlice.js";
import { reducer as memberSettingsReducer } from "./slices/memberSettingsSlice.js";
import { reducer as myPersonReducer } from "./slices/myPersonSlice.js";
import { reducer as oauthReducer } from "./slices/oauthSlice.js";
import { reducer as officeReducer } from "./slices/officeSlice.js";
import { ProfileBadgeFieldReducer } from "./slices/profileBadgeSlice.js";
import { reducer as roamReducer } from "./slices/roamSlice.js";
import { reducer as roamaniacReducer } from "./slices/roamaniacSlice.js";
import { SamlReducers } from "./slices/samlSlice.js";
import { reducer as searchReducer } from "./slices/searchSlice.js";
import { SurveyReducer } from "./slices/surveySlice.js";
import { reducer as teleRoomReducer } from "./slices/teleRoomSlice.js";
import { videoBotReducer } from "./slices/videoBotSlice.js";
import { reducer as virtualBackgroundReducer } from "./slices/virtualBackgroundsSlice.js";

export const reducer = combineReducers({
  accessBadge: AccessBadgeReducer,
  account: accountReducer,
  actionRecords: actionRecordReducer,
  adminAllPeople: adminAllPeopleReducer,
  adminAllRoams: adminAllRoamsReducer,
  advancedSearch: advancedSearchReducer,
  anyWorld: anyWorldReducer,
  authentication: AuthenticationReducer,
  compliance: complianceReducer,
  cryptoAddresses: cryptoAddressReducer,
  externalSocialLinks: ExternalSocialLinkReducer,
  foundersTour: foundersTourReducer,
  group: groupReducer,
  groupView: groupViewReducer,
  magicMinutes: magicMinutesReducer,
  meetingStats: meetingStatsReducer,
  memberSettings: memberSettingsReducer,
  myPerson: myPersonReducer,
  oauth: oauthReducer,
  office: officeReducer,
  profileBadgeFields: ProfileBadgeFieldReducer,
  roam: roamReducer,
  roamaniac: roamaniacReducer,
  saml: SamlReducers,
  search: searchReducer,
  survey: SurveyReducer,
  teleRoom: teleRoomReducer,
  videoBot: videoBotReducer,
  virtualBackground: virtualBackgroundReducer,
});
