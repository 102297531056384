import { FaceLandmarker, FaceLandmarkerResult } from "@mediapipe/tasks-vision";
import { mat4 } from "gl-matrix";
import { compileShader, createProgram, glsl } from "../../webglHelper.js";

export type RenderLandmarksStage = {
  render(): void;
  cleanUp(): void;
};

const vertexShaderSrc = glsl`#version 300 es
  precision highp float;

  in vec3 a_position;
  uniform mat4 u_transformMat;

  void main() {
    gl_Position = u_transformMat * vec4(a_position, 1);
  }
`;

const fragmentShaderSrc = glsl`#version 300 es
  precision highp float;

  out vec4 outColor;
  uniform float u_value;

  void main() {
    outColor = vec4(u_value, 0, 0, 1);
  }`;

export const buildRenderFacialFeaturesStage = (
  gl: WebGL2RenderingContext,
  landmarkBuffer: WebGLBuffer,
  outputTexture: WebGLTexture
): RenderLandmarksStage => {
  const vertexShader = compileShader(gl, gl.VERTEX_SHADER, vertexShaderSrc);
  const fragmentShader = compileShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSrc);
  const program = createProgram(gl, vertexShader, fragmentShader);
  gl.useProgram(program);

  const matLoc = gl.getUniformLocation(program, "u_transformMat");
  const valueLoc = gl.getUniformLocation(program, "u_value");
  const aPosition = gl.getAttribLocation(program, "a_position");
  if (!matLoc || !valueLoc) {
    throw new Error("did not get locations");
  }

  const includeIndexBuffer = gl.createBuffer();
  if (!includeIndexBuffer) {
    throw new Error("could not create includeIndexBuffer");
  }
  const excludeIndexBuffer = gl.createBuffer();
  if (!excludeIndexBuffer) {
    throw new Error("could not create excludeIndexBuffer");
  }
  const [includeIndexes, excludeIndexes] = connectionsToUint16Arrays(
    FaceLandmarker.FACE_LANDMARKS_TESSELATION
  );

  const includeVao = gl.createVertexArray();
  gl.bindVertexArray(includeVao);

  gl.enableVertexAttribArray(aPosition);
  gl.bindBuffer(gl.ARRAY_BUFFER, landmarkBuffer);
  gl.vertexAttribPointer(aPosition, 3, gl.FLOAT, false, 0, 0);

  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, includeIndexBuffer);
  gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, includeIndexes, gl.STATIC_DRAW);

  const excludeVao = gl.createVertexArray();
  gl.bindVertexArray(excludeVao);

  gl.enableVertexAttribArray(aPosition);
  gl.bindBuffer(gl.ARRAY_BUFFER, landmarkBuffer);
  gl.vertexAttribPointer(aPosition, 3, gl.FLOAT, false, 0, 0);

  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, excludeIndexBuffer);
  gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, excludeIndexes, gl.STATIC_DRAW);

  gl.bindVertexArray(null);

  const destBuffer = gl.createFramebuffer();
  gl.bindFramebuffer(gl.FRAMEBUFFER, destBuffer);
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, outputTexture, 0);

  const mat = mat4.create();
  mat4.translate(mat, mat, [-1, -1, 0]);
  mat4.scale(mat, mat, [2, 2, 0]);
  gl.uniformMatrix4fv(matLoc, false, mat);

  const render = () => {
    gl.bindFramebuffer(gl.FRAMEBUFFER, destBuffer);
    gl.clearColor(0.0, 0.0, 0.0, 0.0);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.useProgram(program);

    gl.bindVertexArray(includeVao);
    gl.uniform1f(valueLoc, 1.0);
    gl.drawElements(gl.TRIANGLES, includeIndexes.length, gl.UNSIGNED_SHORT, 0);

    gl.bindVertexArray(excludeVao);
    gl.uniform1f(valueLoc, 0.0);
    gl.drawElements(gl.TRIANGLES, excludeIndexes.length, gl.UNSIGNED_SHORT, 0);
  };

  const cleanUp = () => {};

  return { render, cleanUp };
};

export const resultsToFloat32Array = (results: FaceLandmarkerResult): Float32Array | undefined => {
  const face = results.faceLandmarks[0];
  if (!face) {
    return undefined;
  }
  return new Float32Array(face.flatMap((l) => [l.x, l.y, l.z]));
};

interface Connection {
  start: number;
  end: number;
}

// https://github.com/tensorflow/tfjs-models/blob/838611c02f51159afdd77469ce67f0e26b7bbb23/face-landmarks-detection/src/mediapipe-facemesh/keypoints.ts
export const meshVertices: { [key: string]: number[] } = {
  // silhouette: [
  //   10, 338, 297, 332, 284, 251, 389, 356, 454, 323, 361, 288, 397, 365, 379, 378, 400, 377, 152,
  //   148, 176, 149, 150, 136, 172, 58, 132, 93, 234, 127, 162, 21, 54, 103, 67, 109,
  // ],

  lipsUpperOuter: [61, 185, 40, 39, 37, 0, 267, 269, 270, 409, 291],
  lipsUpperMedOut: [76, 184, 74, 73, 72, 11, 302, 303, 304, 408, 306],
  lipsUpperMedIn: [62, 183, 42, 41, 38, 12, 268, 271, 272, 407, 292],
  lipsUpperInner: [78, 191, 80, 81, 82, 13, 312, 311, 310, 415, 308],
  lipsLowerInner: [78, 95, 88, 178, 87, 14, 317, 402, 318, 324, 308],
  lipsLowerMedIn: [62, 96, 89, 179, 86, 15, 316, 403, 319, 325, 292],
  lipsLowerMedOut: [76, 77, 90, 180, 85, 16, 315, 404, 320, 307, 306],
  lipsLowerOuter: [61, 146, 91, 181, 84, 17, 314, 405, 321, 375, 291],

  rightEyeUpper0: [246, 161, 160, 159, 158, 157, 173],
  rightEyeLower0: [33, 7, 163, 144, 145, 153, 154, 155, 133],
  rightEyeUpper1: [247, 30, 29, 27, 28, 56, 190],
  rightEyeLower1: [130, 25, 110, 24, 23, 22, 26, 112, 243],
  rightEyeUpper2: [113, 225, 224, 223, 222, 221, 189],
  // rightEyeLower2: [226, 31, 228, 229, 230, 231, 232, 233, 244],
  // rightEyeLower3: [143, 111, 117, 118, 119, 120, 121, 128, 245],

  rightEyebrowUpper: [156, 70, 63, 105, 66, 107, 55, 193],
  rightEyebrowLower: [35, 124, 46, 53, 52, 65],

  rightEyeIris: [473, 474, 475, 476, 477],

  leftEyeUpper0: [466, 388, 387, 386, 385, 384, 398],
  leftEyeLower0: [263, 249, 390, 373, 374, 380, 381, 382, 362],
  leftEyeUpper1: [467, 260, 259, 257, 258, 286, 414],
  leftEyeLower1: [359, 255, 339, 254, 253, 252, 256, 341, 463],
  leftEyeUpper2: [342, 445, 444, 443, 442, 441, 413],
  // leftEyeLower2: [446, 261, 448, 449, 450, 451, 452, 453, 464],
  // leftEyeLower3: [372, 340, 346, 347, 348, 349, 350, 357, 465],

  leftEyebrowUpper: [383, 300, 293, 334, 296, 336, 285, 417],
  leftEyebrowLower: [265, 353, 276, 283, 282, 295],

  leftEyeIris: [468, 469, 470, 471, 472],

  noseBottom0: [60, 99, 97, 2, 326, 328, 290],
  noseBottom1: [98, 240, 75, 20, 242, 141, 94, 370, 462, 250, 305, 460, 327],
  noseBottom2: [235, 59, 166, 79, 239, 238, 241, 125, 19, 354, 461, 458, 459, 309, 392, 289, 455],
  noseBottom3: [64, 219, 218, 237, 44, 1, 274, 457, 438, 439, 294],
  noseBottom4: [129, 102, 48, 115, 220, 45, 4, 275, 440, 344, 278, 331, 358],
  // noseBottom5: [49, 131, 134, 51, 5, 281, 363, 360, 279],

  // midwayBetweenEyes: [168],
  //
  // noseTip: [1],
  // noseBottom: [2],
  // noseRightCorner: [98],
  // noseLeftCorner: [327],
  //
  // rightCheek: [205],
  // leftCheek: [425],
};

const excludeArray = new Array<number>();
for (const key in meshVertices) {
  if (Object.prototype.hasOwnProperty.call(meshVertices, key)) {
    const v = meshVertices[key];
    if (v) {
      excludeArray.push(...v);
    }
  }
}
const excludeVertices = new Set(excludeArray);

const excludeTriangle = (list: number[]): boolean => {
  let count = 0;
  for (const v of list) {
    if (excludeVertices.has(v)) {
      count++;
    }
  }
  return count === 3;
};

// TODO(tdixon): change this to a constant array to save time
export const connectionsToUint16Arrays = (
  connections: Connection[]
): [Uint16Array, Uint16Array] => {
  const include = new Array<number>();
  const exclude = new Array<number>();
  for (let i = 0; i < connections.length; i += 3) {
    const triangleVertices = connections.slice(i, i + 3).map((c) => c.start);
    if (excludeTriangle(triangleVertices)) {
      exclude.push(...triangleVertices);
    } else {
      include.push(...triangleVertices);
    }
  }
  return [new Uint16Array(include), new Uint16Array(exclude)];
};
