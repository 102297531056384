import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { logger } from "../../../../shared/infra/logger.js";
import { PublicTeleRoom, TeleRoom } from "../../../../shared/Models/TeleRoom.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

interface WindowKeyPayload {
  windowKey: WindowKey;
}

export interface AddUpdateTeleRoomPayload extends WindowKeyPayload {
  teleRoom: TeleRoom;
}

export interface DeleteTeleRoomPayload extends WindowKeyPayload {
  teleRoomId: string;
}
export interface RequestTeleRoomDetailPayload {
  teleRoomId: string;
}

export interface RequestSeveralTeleRoomDetailPayload {
  teleRoomIds: string[];
}

export interface RequestAccountTeleRoomsPayload {
  accountId: number;
}

export interface AccountTeleRoomsPayload {
  accountId: number;
  teleRooms: TeleRoom[];
}

export interface VisibleTeleRoomsPayload {
  visibleTeleRooms: PublicTeleRoom[];
}

export interface RequestUserTeleRoomsPayload {}

export interface TeleRoomDeletedPayload {
  teleRoomId: string;
  accountId: number;
}

const slice = createSlice({
  name: "teleRoom",
  initialState: {
    teleRoomsByAccount: {} as { [accountId: number]: TeleRoom[] },
    visibleTeleRooms: [] as PublicTeleRoom[],
  },
  reducers: {
    requestAccountTeleRooms: (state, action: PayloadAction<RequestAccountTeleRoomsPayload>) => {},
    requestUserTeleRooms: (state, action: PayloadAction<RequestUserTeleRoomsPayload>) => {},
    requestUserVisibleTeleRooms: (state) => {},
    requestTeleRoomDetail: (state, action: PayloadAction<RequestTeleRoomDetailPayload>) => {},
    requestSeveralTeleRoomDetail: (
      state,
      action: PayloadAction<RequestSeveralTeleRoomDetailPayload>
    ) => {},
    addUpdateTeleRoom: (state, action: PayloadAction<AddUpdateTeleRoomPayload>) => {},
    deleteTeleRoom: (state, action: PayloadAction<DeleteTeleRoomPayload>) => {},

    setAccountTeleRooms: (state, action: PayloadAction<AccountTeleRoomsPayload>) => {
      const { accountId, teleRooms } = action.payload;
      state.teleRoomsByAccount[accountId] = teleRooms;
    },
    setVisibleTeleRooms: (state, action: PayloadAction<VisibleTeleRoomsPayload>) => {
      const { visibleTeleRooms } = action.payload;
      state.visibleTeleRooms = visibleTeleRooms;
    },
    setTeleRoom: (state, action: PayloadAction<TeleRoom>) => {
      const teleRoom = action.payload;
      const { accountId, id } = teleRoom;
      if (!accountId) {
        logger.warn("TeleRoomSlice: received teleRoom can't be stored because it has no accountId");
        return;
      }
      const accountTeleRooms = state.teleRoomsByAccount[accountId];
      if (accountTeleRooms) {
        const i = accountTeleRooms.findIndex((tr) => tr.id === id);
        if (i >= 0) {
          accountTeleRooms[i] = teleRoom;
        } else {
          accountTeleRooms.push(teleRoom);
        }
      } else {
        state.teleRoomsByAccount[accountId] = [teleRoom];
      }
    },
    setTeleRoomDeleted: (state, action: PayloadAction<TeleRoomDeletedPayload>) => {
      const { teleRoomId, accountId } = action.payload;
      const accountTeleRooms = state.teleRoomsByAccount[accountId];
      if (accountTeleRooms) {
        const i = accountTeleRooms.findIndex((tr) => tr.id === teleRoomId);
        if (i >= 0) {
          accountTeleRooms.splice(i, 1);
        }
      }
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.anyWorld.teleRoom;

export const selectors = {
  selectAccountTeleRooms: (accountId: number) => (state: RootState) =>
    selectSlice(state).teleRoomsByAccount[accountId],
  selectAll: createDeepEqualSelector(selectSlice, (slice) => {
    const result: TeleRoom[][] = [];
    for (const accountId in slice.teleRoomsByAccount) {
      if (accountId) {
        const teleRooms = slice.teleRoomsByAccount[accountId];
        if (teleRooms) {
          result.push(teleRooms);
        }
      }
    }
    return result.flat();
  }),
  selectAllVisible: (state: RootState) => selectSlice(state).visibleTeleRooms,
  selectOfficeTeleRooms: defaultMemoize((accountId: number, officeId?: string) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      const teleRooms = slice.teleRoomsByAccount[accountId];
      if (!teleRooms) {
        return [];
      }
      return teleRooms.filter((tr) => tr.officeId === officeId);
    })
  ),
  selectTeleRoom: defaultMemoize((accountId?: number, teleRoomId?: string) =>
    createSelector(selectSlice, (slice) => {
      if (!accountId || !teleRoomId) return undefined;
      const teleRooms = slice.teleRoomsByAccount[accountId];
      if (!teleRooms) {
        return undefined;
      }
      return teleRooms.find((r: TeleRoom) => r.id === teleRoomId);
    })
  ),
};
