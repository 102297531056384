import { validate } from "email-validator";

// Validates an email address in the form of "name@domain.com" or "First Last <name@domain.com>"
export const validateEmail = (s: string): string | { email: string; name: string } | undefined => {
  const trimmed = s.trim();
  if (!trimmed) {
    return undefined;
  }

  if (validate(trimmed)) {
    return trimmed;
  }

  const nameEmailRegex = /^(.*?)<(.+?)>\s*$/;
  const match = trimmed.match(nameEmailRegex);
  if (match !== null) {
    const name = match[1]?.trim();
    const possibleEmail = match[2]?.trim();
    if (possibleEmail && validate(possibleEmail)) {
      return name ? { name, email: possibleEmail } : possibleEmail;
    }
  }

  return undefined;
};
