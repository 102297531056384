import React from "react";
import { createRoot } from "react-dom/client";
import { ContainerContext } from "../../client/hooks/useContainer.js";
import { ClientContainerInfo, IClientContainer } from "../../client/injection/IClientContainer.js";
import { ThemeProviderWithoutStore } from "../../client/styles/ThemeProviderWithoutStore.js";
import "../../client/styles/base.css";
import { getErrorMessage } from "../../shared/helpers/errors.js";
import { createContainer } from "../../shared/injection/createContainer.js";
import { IPipelinesContainer } from "./IPipelinesContainer.js";
import { Pipelines } from "./Pipelines.js";
import { PipelinesContainer } from "./PipelinesContainer.js";
import { PipelinesContext } from "./PipelinesContext.js";

// 16:9 720p
const videoConstraints = {
  advanced: [
    {
      frameRate: {
        min: 30,
      },
    },
    {
      height: {
        min: 720,
      },
    },
    {
      width: {
        min: 1280,
      },
    },
    {
      frameRate: {
        max: 30,
      },
    },
    {
      width: {
        max: 1280,
      },
    },
    {
      height: {
        max: 720,
      },
    },
    {
      aspectRatio: {
        exact: 1.77778,
      },
    },
  ],
  height: {
    ideal: 720,
  },
  width: {
    ideal: 1280,
  },
};

const main = async () => {
  const info: ClientContainerInfo = {
    containerType: "pipelines",
    environment: "local",
  };
  const container = await createContainer<IPipelinesContainer>(PipelinesContainer, info);
  container.rawVideoStreamManager().setGlobalVideoSettings(videoConstraints);

  const rootElement = document.getElementById("root");
  if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
      <ThemeProviderWithoutStore>
        <ContainerContext.Provider value={container as unknown as IClientContainer}>
          <PipelinesContext.Provider value={container}>
            <Pipelines container={container} />
          </PipelinesContext.Provider>
        </ContainerContext.Provider>
      </ThemeProviderWithoutStore>
    );
  }
};

main().catch((err) => {
  console.error(getErrorMessage(err));
});
