import { enableMapSet } from "immer";
import { $CombinedState, combineReducers, ReducersMapObject, Store } from "redux";
import { reducer as anyWorldReducer } from "../anyworld/store/reducer.js";
import { developerReducer } from "../apps/store/developerSlice.js";
import { reducer as autoboardingReducer } from "../autoboarding/store/reducer.js";
import { reducer as billingReducer } from "../billing/store/reducer.js";
import { reducer as calendarReducer } from "../calendar/store/reducer.js";
import { reducer as chatReducer } from "../chat/store/reducer.js";
import { reducer as holoReducer } from "../holo/store/reducer.js";
import { reducer as interactionReducer } from "../interaction/store/interactionSlice.js";
import { reducer as notificationReducer } from "../notification/store/reducer.js";
import { reducer as overworldReducer } from "../overworld/store/reducer.js";
import { reducer as panelReducer } from "../panels/store/panelSlice.js";
import { reducer as sectionReducer } from "../section/store/reducer.js";
import { tagsReducer } from "../tags/store/tagsSlice.js";
import { reducer as thingsReducer } from "../things/store/reducer.js";
import { reducer as transcriptionReducer } from "../transcription/store/reducer.js";
import { reducer as userLocationReducer } from "../userlocation/store/reducer.js";
import { reducer as worldReducer } from "../world/store/reducer.js";
import { reducer as zoneReducer } from "../zone/store/reducer.js";
import { reducer as appLinkReducer } from "./slices/appLinkSlice.js";
import { reducer as appReducer } from "./slices/appSlice.js";
import { reducer as awayCacheReducer } from "./slices/awayCacheSlice.js";
import { reducer as browserFeaturesReducer } from "./slices/browserFeatureSlice.js";
import { reducer as displayReducer } from "./slices/displaySlice.js";
import { reducer as featureFlagsReducer } from "./slices/featureFlagSlice.js";
import { reducer as inspectorReducer } from "./slices/inspectorSlice.js";
import { reducer as settingsFeatureFlagsReducer } from "./slices/settingsFeatureFlagSlice.js";
import { reducer as windowReducer } from "./slices/windowSlice.js";

enableMapSet();

export const reducers = {
  anyWorld: anyWorldReducer,
  app: appReducer,
  appLink: appLinkReducer,
  autoboarding: autoboardingReducer,
  awayCache: awayCacheReducer,
  billing: billingReducer,
  browserFeatures: browserFeaturesReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  developer: developerReducer,
  display: displayReducer,
  featureFlags: featureFlagsReducer,
  holo: holoReducer,
  inspector: inspectorReducer,
  interaction: interactionReducer,
  notification: notificationReducer,
  overworld: overworldReducer,
  panel: panelReducer,
  section: sectionReducer,
  settingsFeatureFlags: settingsFeatureFlagsReducer,
  tags: tagsReducer,
  things: thingsReducer,
  transcription: transcriptionReducer,
  userLocation: userLocationReducer,
  window: windowReducer,
  world: worldReducer,
  zone: zoneReducer,
};

const combinedReducers = combineReducers(reducers);
export type RootState = ReturnType<typeof combinedReducers> & {
  readonly [$CombinedState]?: undefined;
};

export const makeRootReducer = (extraReducers: ReducersMapObject) => {
  const combinedReducers = combineReducers({ ...reducers, ...extraReducers });
  return combinedReducers;
};

export type BaseReduxStore = Store<RootState>;
