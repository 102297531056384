import { ChatType, isChannelStyleAddressChat } from "../Models/Chat.js";
import { ChatAddress, ChatTarget } from "../Models/ChatAddress.js";
import {
  ChatContentType,
  ChatMessage,
  ChatMessageContent,
  SentChatMessage,
  SentThreadMessage,
  TextContent,
} from "../Models/ChatMessage.js";
import { ItemType, itemTypeDisplayNames } from "../Models/Item.js";
import { Location, locationToRoomId } from "../Models/Location.js";
import { MeetingChatLocation } from "../Models/MeetingChat.js";
import { RecordingBotEmailAddress } from "../Models/Recording.js";
import { TeamRoamChatInfo } from "../Models/TeamRoam.js";
import { formatNameInitials } from "../formatters/formatName.js";
import { BrandedString } from "./branded.js";
import { customEmojiToPlaintext } from "./customEmoji.js";
import { ampersandJoin } from "./formatters.js";
import { mentionsToPlaintext } from "./mentions.js";
import { floorToSectionId } from "./sections.js";

export const deletableContentTypes: ChatContentType[] = ["text", "emoji", "item", "textSnippet"];

export const meetingChatIdForLocation = (loc: Location): string | undefined => {
  const floorId = loc.section.floorId;
  const roomId = locationToRoomId(loc);
  if (floorId && roomId) {
    return meetingChatIdForMeetingChatLocation({ floorId, roomId });
  }
  return undefined;
};

export const meetingChatIdForMeetingChatLocation = (loc: MeetingChatLocation): string => {
  return `MEETING-${loc.floorId}-${loc.roomId}`;
};

export const locationFromMeetingChatId = (chatId: string): MeetingChatLocation | undefined => {
  const parts = chatId.split("-");
  if (parts.length !== 3 || parts[0] !== "MEETING" || !parts[1] || !parts[2]) {
    return undefined;
  }

  const floorId = parseInt(parts[1]);
  const roomId = parseInt(parts[2]);
  if (isNaN(roomId) || isNaN(floorId)) return undefined;
  return { roomId, floorId };
};

export const sectionIdFromMeetingChatId = (chatId: string): string | undefined => {
  const location = locationFromMeetingChatId(chatId);
  if (!location) return undefined;
  return floorToSectionId(location.floorId);
};

export const fakeAddressIdForOccupantId = (occupantId?: string): string | undefined => {
  return occupantId ? `OCCUPANT-${occupantId}` : undefined;
};

export const occupantIdFromFakeAddressId = (fakeAddressId: string): string => {
  return fakeAddressId.replace("OCCUPANT-", "");
};

// Keep in sync with ChatMessage+Additions.swift::notNewMessage
// Keep in sync with ChatMessage.kt::contentNotNewMessage
export const contentNotNewMessage = (content: ChatMessageContent): boolean => {
  if (content.contentType === "addressMembersChanged") return content.added.length === 0;
  if (content.contentType === "requestMuteAudio") return true;
  if (content.contentType === "roomInviteResult" && content.result === "Accept") return true;
  if (
    content.contentType === "knock" &&
    content.knock.result &&
    (content.knock.result.status === "comeIn" ||
      content.knock.result.status === "notAvailable" ||
      content.knock.result.status === "entered")
  )
    return true;
  if (content.contentType === "audienceRequestAnswer" && content.answer === "accept") return true;
  if (content.contentType === "visitStarted" && content.accessLinkType === "oneTime") return true;
  // Recording bot entering/exiting is special-cased; the user will get different notifications so
  // we don't want to show chat messages
  if (
    (content.contentType === "visitStarted" && content.guestEmail === RecordingBotEmailAddress) ||
    (content.contentType === "visitEnded" && content.guestEmail === RecordingBotEmailAddress)
  )
    return true;
  return false;
};

// For use when addresses is not populated on the chat, which it now isn't in the Redux store
export const displayNameFromChatTypeAndTargets = (
  chatType: ChatType | undefined,
  targetsNotMe: ChatTarget[] | undefined,
  short = false
): string => {
  if (!chatType || !targetsNotMe) {
    return "Chat";
  }
  if (targetsNotMe.length === 0) {
    return "New Chat";
  }
  if (chatType === "teamRoam") {
    if (targetsNotMe.length === 1) {
      return "Team Roam";
    } else {
      const userAddress = targetsNotMe.find((address) => address.targetType === "email");
      return userAddress?.displayName ? `TR & ${userAddress.displayName}` : "TeamRoam";
    }
  } else {
    if (targetsNotMe.length > 1) {
      targetsNotMe = [...targetsNotMe].sort(
        (a, b) => a.displayName?.localeCompare(b?.displayName ?? "") ?? 0
      );
      if (short) {
        return ampersandJoin(targetsNotMe.map((addr) => formatNameInitials(addr.displayName)));
      }
    }
    return targetsNotMe.map((addr) => addr.displayName).join(", ") ?? "";
  }
};

export const teamRoamUnanswered = (chatInfo?: TeamRoamChatInfo) =>
  chatInfo &&
  chatInfo.lastCustomerMessageTime &&
  (!chatInfo.answeredThrough || chatInfo.answeredThrough < chatInfo.lastCustomerMessageTime);

/**
 * The interval (in ms) after which the client should discard typing indicators.
 */
export const TypingIndicatorTimeout = 6_000; /* 6 seconds */

/**
 * The interval (in ms) at which the client should resend its outgoing typing indicator.
 */
export const TypingIndicatorKeepAlive = 2_500; /* 2.5 seconds */

/**
 *  Backwards compatibility wave fix; ensure the wave fields are populated
 * for older clients 10/11/2022 - huffy
 */
export const populateWaveNames = (wave: ChatMessage, addressIds: string[]): void => {
  if (wave.content.contentType === "wave") {
    if (!wave.content.wavedAtName || !wave.content.waverName) {
      if (addressIds.includes(wave.addressId)) {
        wave.content.wavedAtName = "them";
        wave.content.waverName = "You";
      } else {
        wave.content.wavedAtName = "you";
        wave.content.waverName = "They";
      }
    }
  }
};

export const rankSendFromAddresses = (
  senderAddresses: ChatAddress[],
  preferredEmail: string | undefined
): ChatAddress[] => {
  return [...senderAddresses].sort((left, right) => {
    if (preferredEmail && left.targetType === "email" && preferredEmail === left.email) {
      return -1;
    }
    if (preferredEmail && right.targetType === "email" && preferredEmail === right.email) {
      return 1;
    }
    return left.addressId.localeCompare(right.addressId);
  });
};

/**
 * Not definitive. Ultimately up to server to decide if this is a chat is new.
 */
export const bestSendFromAddress = (
  senderAddresses: ChatAddress[] | undefined,
  preferredEmail: string | undefined
): ChatAddress | undefined => {
  if (!senderAddresses || senderAddresses.length === 0) {
    return;
  }
  return rankSendFromAddresses(senderAddresses, preferredEmail)[0];
};

export interface ChatKey {
  chatId: string;
  threadTimestamp: number | undefined;
}

export type ChatKeyString = BrandedString<"ChatKeyString">;

/**
 * Just a helpful way to get a string key for the chat or thread. The result itself is not sent
 * between services.
 */
export const getChatKey = (chatId: string, threadTimestamp: number | undefined): ChatKeyString =>
  (threadTimestamp ? `${chatId}|${threadTimestamp}` : (chatId as ChatKeyString)) as ChatKeyString;

export const chatKeyToString = (key: ChatKey) => getChatKey(key.chatId, key.threadTimestamp);

/**
 * A helper to get a string key for an individual chat message. The result itself is not sent
 * between services.
 */
export const getMessageKey = (
  chatId: string,
  threadTimestamp: number | undefined,
  timestamp: number
): string =>
  threadTimestamp ? `${chatId}|${threadTimestamp}|${timestamp}` : `${chatId}||${timestamp}`;

/**
 * Useful to recover the chatId and threadTimestamp from a chatKey if `getChatKey` is used.
 * (Again, for use in a given module that needs this, not to pass between services.)
 */
export const chatKeyToIds = (chatKey: string): ChatKey | undefined => {
  const parts = chatKey.split("|");
  const chatId = parts[0];
  if (chatId && parts.length === 1) {
    return { chatId, threadTimestamp: undefined };
  } else if (chatId && parts.length === 2) {
    const threadTimestampStr = parts[1];
    if (threadTimestampStr) {
      const threadTimestamp = parseInt(threadTimestampStr);
      if (!isNaN(threadTimestamp)) {
        return { chatId, threadTimestamp };
      }
    }
  }
  return undefined;
};

/**
 * Trim the given message to 200 characters, appending "..." if needed
 *
 * @param message Message to trim
 */
export const mobileTrimMessage = (message: string): string => {
  if (message.length <= 200) return message;
  return message.substring(0, 199).concat("…");
};

export const itemIdsFromMessage = (message: ChatMessage): string[] => {
  if (message.content.contentType === "confidentialText") {
    return message.confidentialItems?.map((item) => item.itemId) ?? [];
  }
  return itemIdsFromContent(message.content);
};

export const itemIdsFromContent = (content: ChatMessageContent): string[] => {
  switch (content.contentType) {
    case "item":
      return [content.itemId];
    case "text":
      return content.items?.map((item) => item.itemId) ?? [];
    case "story":
      return [content.itemId];
    default:
      return [];
  }
};

export const describeTextMessage = (
  content: TextContent,
  opts?: { passThroughCustomEmoji?: boolean }
): string => {
  const itemDisplayToCount = new Map<string, number>();
  content.items?.forEach((item) => {
    const itemTypeDisplayName =
      (item.itemType ? itemTypeDisplayNames[item.itemType as ItemType] : undefined) ?? "Attachment";
    itemDisplayToCount.set(
      itemTypeDisplayName,
      (itemDisplayToCount.get(itemTypeDisplayName) ?? 0) + 1
    );
  });
  if (content.textSnippets && content.textSnippets.length > 0) {
    itemDisplayToCount.set("Text Snippet", content.textSnippets.length);
  }

  const attachmentSegments = new Array<string>();
  for (const [displayName, count] of itemDisplayToCount) {
    attachmentSegments.push(`${count} ${displayName}${count > 1 ? "s" : ""}`);
  }

  const attachmentText = ampersandJoin(attachmentSegments);
  let text = content.text;
  text = mentionsToPlaintext(text);
  if (!opts?.passThroughCustomEmoji) text = customEmojiToPlaintext(text);
  text = text.trim();

  const resultText = new Array<string>();
  if (text) {
    resultText.push(text);
  }
  if (attachmentText) {
    resultText.push(attachmentText);
  }

  return resultText.join(text.endsWith(".") ? " " : ". ");
};

export const chatMessageCanReact = ({
  enableReactions,
  message,
  chatType,
}: {
  enableReactions: boolean;
  message: ChatMessage;
  chatType?: ChatType;
}): boolean => {
  const { content, sendStatus } = message;
  const { contentType } = content;

  return (
    enableReactions &&
    chatType !== "confidential" &&
    (contentType === "text" || contentType === "item" || contentType === "textSnippet") &&
    (!sendStatus || sendStatus === "delivered")
  );
};

export const chatMessageCanReplyInThread = ({
  enableThreads,
  message,
  chatType,
}: {
  enableThreads: boolean;
  message: ChatMessage;
  chatType?: ChatType;
}): boolean => {
  const { content, sendStatus } = message;
  const { contentType } = content;

  return (
    enableThreads &&
    (contentType === "text" ||
      contentType === "item" ||
      contentType === "textSnippet" ||
      contentType === "deleted") &&
    isChannelStyleAddressChat(chatType) &&
    (!sendStatus || sendStatus === "delivered") &&
    message.timestamp !== undefined
  );
};

export const chatMessageCanDelete = ({
  fromMe,
  enableDeletion,
  message,
  chatType,
}: {
  fromMe: boolean;
  enableDeletion: boolean;
  message: ChatMessage;
  chatType?: ChatType;
}): boolean => {
  const { content, sendStatus } = message;
  const { contentType } = content;

  return (
    fromMe &&
    enableDeletion &&
    deletableContentTypes.includes(contentType) &&
    chatType !== "teamRoam" &&
    (!sendStatus || sendStatus === "delivered")
  );
};

export const minTimestamp = (
  messages: Array<{ timestamp: number | undefined }>
): number | undefined => {
  let result: number | undefined;
  for (const message of messages) {
    if (message.timestamp && (result === undefined || (result && message.timestamp < result))) {
      result = message.timestamp;
    }
  }

  return result;
};

export const maxTimestamp = (
  messages: Array<{ timestamp: number | undefined }>
): number | undefined => {
  let result: number | undefined;
  for (const message of messages) {
    if (message.timestamp && (result === undefined || (result && message.timestamp > result))) {
      result = message.timestamp;
    }
  }

  return result;
};

export const isThreadMessage = (message: SentChatMessage): message is SentThreadMessage => {
  return message.threadTimestamp !== undefined;
};
