import { z } from "zod";
import { stringDate, uuid } from "../zodTypes.js";

const BaseAccount = z.object({
  /** Name (and display name) of the account. */
  name: z.string(),
  /** Whether or not members of this account may invite others into the account. */
  allowUserInvite: z.boolean(),
  /** A UUID that, if provided at login/signup, allows a user to join this account. */
  directSignupUuid: uuid().optional(),
  allowAddNewMembers: z.boolean(),
});
type BaseAccount = z.infer<typeof BaseAccount>;

/**
 * A customer account.
 *
 * Typically represents a company or organization.
 */
export const Account = BaseAccount.extend({
  id: z.number().int(),
  createdDate: stringDate(),
  archivedDate: stringDate().optional(),
  billingIssue: z.boolean(),
  ignoreInBillingExceptionsUntil: stringDate().optional(),
});
export type Account = z.infer<typeof Account>;

export const NewAccount = BaseAccount.extend({});
export type NewAccount = z.infer<typeof NewAccount>;

/**
 * An account type that is safe for client side use.
 *
 * This type only allows fields that are safe to expose to anyone, including people who are not
 * members of the corresponding account.
 */
export const NonMemberAccount = Account.pick({ id: true, name: true });
export type NonMemberAccount = z.infer<typeof NonMemberAccount>;

export const PublicAccount = Account.pick({ id: true, name: true, directSignupUuid: true });
export type PublicAccount = z.infer<typeof PublicAccount>;
