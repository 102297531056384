import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { z } from "zod";
import { createStorageKey } from "../../../injection/storage/IStorage.js";
import { updateInternal, WantHave } from "../../../store/clientConnectionHelpers.js";
import { RootState } from "../../../store/reducers.js";

export const hasFilledOutBadgeKey = createStorageKey("hasFilledOutBadge", z.string().array());

const slice = createSlice({
  name: "overworld",
  initialState: {
    // Overworld
    // follows patterns in docs/clientConnections.md
    wantOverworldConnected: false,
    internalWantOverworldConnected: false,
    internalHaveOverworldConnected: false,

    overworldConnectionRetryTime: undefined as string | undefined,
  },
  reducers: {
    // *** Overworld ***

    // follows patterns in docs/clientConnections.md
    setWantOverworldConnected: (state, action: PayloadAction<boolean>) => {
      state.wantOverworldConnected = action.payload;
    },
    setInternalOverworldConnected: (state, action: PayloadAction<WantHave<boolean>>) => {
      updateInternal(state, "OverworldConnected", action.payload);
    },

    setOverworldConnectionRetryTime: (state, action: PayloadAction<string | undefined>) => {
      state.overworldConnectionRetryTime = action.payload;
    },
  },
});
export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.overworld.overworld;
export const selectors = {
  selectWantOverworldConnected: (state: RootState) => selectSlice(state).wantOverworldConnected,
  selectInternalWantOverworldConnected: (state: RootState) =>
    selectSlice(state).internalWantOverworldConnected,
  selectInternalHaveOverworldConnected: (state: RootState) =>
    selectSlice(state).internalHaveOverworldConnected,
  selectOverworldConnectionRetryTime: (state: RootState) =>
    selectSlice(state).overworldConnectionRetryTime,
};

export const OverworldActions = actions;
export const OverworldSelectors = selectors;
