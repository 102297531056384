import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { Item, ItemStatus } from "../../../../shared/Models/Item.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const itemAdapter = createEntityAdapter<Item>();

export interface ItemErrorPayload {
  itemId: string;
  error: string;
}

export interface ItemStatusPayload {
  itemId: string;
  status: ItemStatus;
}

export interface CreateItemPayload {
  item: Item;
  initialStatus?: ItemStatus;
}

export interface ItemErrorMessages {
  [itemId: string]: string;
}

const slice = createSlice({
  name: "item",
  initialState: {
    items: itemAdapter.getInitialState(),
    errorMessages: {} as ItemErrorMessages,
  },
  reducers: {
    // Server calls
    createItem: (state, action: PayloadAction<CreateItemPayload>) => {},
    updateItemStatus: (state, action: PayloadAction<ItemStatusPayload>) => {},

    // From server
    upsertItems: (state, action: PayloadAction<Item[]>) => {
      itemAdapter.upsertMany(state.items, action.payload);
    },

    setItemErrorMessage: (state, action: PayloadAction<ItemErrorPayload>) => {
      const { itemId, error } = action.payload;
      state.errorMessages[itemId] = error;
    },
    clearItemErrorMessages: (state, action: PayloadAction<string>) => {
      delete state.errorMessages[action.payload];
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.things.item;
const itemSelectors = itemAdapter.getSelectors<RootState>((state) => state.things.item.items);
export const selectors = {
  ...itemSelectors,
  selectById: (itemId: string | undefined) => (state: RootState) =>
    itemId ? state.things.item.items.entities[itemId] : undefined,
  selectByIds: defaultMemoize(
    (itemIds: string[] | undefined) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        if (!itemIds) {
          return [];
        }

        const items = [];
        for (const itemId of itemIds) {
          const item = slice.items.entities[itemId];
          if (item) items.push(item);
        }
        return items;
      }),
    {
      equalityCheck: equal,
      maxSize: 5,
    }
  ),
  selectErrorMessages: (state: RootState) => state.things.item.errorMessages,
  selectErrorMessageById: (itemId: string | undefined) => (state: RootState) =>
    itemId ? state.things.item.errorMessages[itemId] : undefined,
  selectErrorMessagesByIds: defaultMemoize(
    (itemIds: string[]) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        const errors: { [itemId: string]: string } = {};
        for (const itemId of itemIds) {
          const error = slice.errorMessages[itemId];
          if (error) errors[itemId] = error;
        }
        return errors;
      }),
    {
      equalityCheck: equal,
      maxSize: 5,
    }
  ),
};

export const ItemSelectors = selectors;
export const ItemActions = actions;
