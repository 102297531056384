import { z } from "zod";
import { numberId, stringDate, stringId } from "./zodTypes.js";

export const BoardType = z.enum(["holoBoard", "screenShare", "whiteboard"]);
export type BoardType = z.infer<typeof BoardType>;

export const EditableBoardType = z.enum(["holoBoard", "whiteboard"]);
export type EditableBoardType = z.infer<typeof EditableBoardType>;

const BaseBoard = z.object({
  id: stringId(),
  type: BoardType.optional(),
  name: z.string().optional(),

  onScreenKey: stringId().optional(),
  created: z.number().positive().optional(),
});

export const ScreenShareBoard = BaseBoard.extend({
  type: z.literal("screenShare"),
  hostOccupantId: stringId(),
});
export type ScreenShareBoard = z.infer<typeof ScreenShareBoard>;

export const HoloBoard = BaseBoard.extend({
  type: z.literal("holoBoard"),

  // Since HoloBoards can persist across sessions, they can be owned either by a room or
  // by a person who has access to them later
  ownerId: numberId().optional(),
  roomId: numberId().optional(),

  height: z.number().positive(),
  width: z.number().positive(),

  lastOpened: stringDate().optional(),
});
export type HoloBoard = z.infer<typeof HoloBoard>;

export const Whiteboard = HoloBoard.extend({
  type: z.literal("whiteboard"),
});
export type Whiteboard = z.infer<typeof Whiteboard>;

export const Board = z.discriminatedUnion("type", [ScreenShareBoard, HoloBoard, Whiteboard]);
export type Board = z.infer<typeof Board>;

export const EditableBoard = z.discriminatedUnion("type", [HoloBoard, Whiteboard]);
export type EditableBoard = z.infer<typeof EditableBoard>;

export const BoardDefaultSize = {
  width: 28000,
  height: 16000,
};
