import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { format, subMonths, subWeeks } from "date-fns";
import { defaultMemoize } from "reselect";
import { UnreachableError } from "../../../../shared/helpers/UnreachableError.js";
import { EmailChatTarget } from "../../../../shared/Models/ChatAddress.js";
import { InboxTab } from "../../../chat/store/slices/inboxSlice.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export type DateFilterType = "" | "week" | "month" | "6months" | "custom";

export interface DateFilter {
  type: DateFilterType;
  before: string;
  after: string;
}

export const emptyDateFilter: DateFilter = {
  type: "",
  before: "",
  after: "",
};

interface SetDateFilterPayload {
  inboxTab: InboxTab;
  dateFilter: DateFilter;
}

interface SetFromTargetsPayload {
  inboxTab: InboxTab;
  fromTargets: EmailChatTarget[];
}

interface SetInGroupNamesPayload {
  inboxTab: InboxTab;
  inGroupNames: string[];
}

interface SetAllPublicGroupsPayload {
  inboxTab: InboxTab;
  allPublicGroups: boolean;
}

interface ClearAllFiltersPayload {
  inboxTab: InboxTab;
}

const slice = createSlice({
  name: "advancedSearch",
  initialState: {
    dateFilter: {} as { [inboxTab in InboxTab]: DateFilter },
    inGroupNames: {} as { [inboxTab in InboxTab]: string[] },
    fromTargets: {} as { [inboxTab in InboxTab]: EmailChatTarget[] },
    allPublicGroups: {} as { [inboxTab in InboxTab]: boolean },
  },
  reducers: {
    setDateFilter: (state, action: PayloadAction<SetDateFilterPayload>) => {
      const { inboxTab, dateFilter } = action.payload;
      state.dateFilter[inboxTab] = dateFilter;
    },
    setFromTargets: (state, action: PayloadAction<SetFromTargetsPayload>) => {
      const { inboxTab, fromTargets } = action.payload;
      state.fromTargets[inboxTab] = fromTargets;
    },
    setInGroupNames: (state, action: PayloadAction<SetInGroupNamesPayload>) => {
      const { inboxTab, inGroupNames } = action.payload;
      state.inGroupNames[inboxTab] = inGroupNames;
    },
    setAllPublicGroups: (state, action: PayloadAction<SetAllPublicGroupsPayload>) => {
      const { inboxTab, allPublicGroups } = action.payload;
      state.allPublicGroups[inboxTab] = allPublicGroups;
    },
    clearAllFilters: (state, action: PayloadAction<ClearAllFiltersPayload>) => {
      const { inboxTab } = action.payload;
      delete state.dateFilter[inboxTab];
      delete state.inGroupNames[inboxTab];
      delete state.fromTargets[inboxTab];
      delete state.allPublicGroups[inboxTab];
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.anyWorld.advancedSearch;
export const selectors = {
  selectDateFilter: defaultMemoize((inboxTab: InboxTab) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      return slice.dateFilter[inboxTab] ?? emptyDateFilter;
    })
  ),
  selectFromTargets: defaultMemoize((inboxTab: InboxTab) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      return slice.fromTargets[inboxTab] ?? [];
    })
  ),
  selectInGroupNames: defaultMemoize((inboxTab: InboxTab) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      return slice.inGroupNames[inboxTab] ?? [];
    })
  ),
  selectSearchTerms: defaultMemoize((inboxTab: InboxTab) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      const terms: string[] = [];

      const now = new Date();
      const formatDate = (date: Date) => format(date, "yyyy-MM-dd");

      const dateFilter = slice.dateFilter[inboxTab] ?? emptyDateFilter;
      switch (dateFilter.type) {
        case "week":
          terms.push(`after:${formatDate(subWeeks(now, 1))}`);
          break;
        case "month":
          terms.push(`after:${formatDate(subMonths(now, 1))}`);
          break;
        case "6months":
          terms.push(`after:${formatDate(subMonths(now, 6))}`);
          break;
        case "custom":
          if (dateFilter.before) terms.push(`before:${dateFilter.before}`);
          if (dateFilter.after) terms.push(`after:${dateFilter.after}`);
          break;
        case "":
          // Do nothing.
          break;
        default:
          throw new UnreachableError(dateFilter.type);
      }

      const fromTargets = slice.fromTargets[inboxTab] ?? [];
      for (const target of fromTargets) {
        terms.push(`from:${target.email}`);
      }

      const inGroupNames = slice.inGroupNames[inboxTab] ?? [];
      for (const groupName of inGroupNames) {
        terms.push(`in:"${groupName}"`);
      }

      const allPublicGroups = slice.allPublicGroups[inboxTab];
      if (allPublicGroups) {
        terms.push(`in:*`);
      } else {
        const inGroupNames = slice.inGroupNames[inboxTab] ?? [];
        for (const groupName of inGroupNames) {
          terms.push(`in:"${groupName}"`);
        }
      }

      return terms;
    })
  ),
  selectAllPublicGroups: (inboxTab: InboxTab) => (state: RootState) =>
    selectSlice(state).allPublicGroups[inboxTab] ?? false,
};

export const AdvancedSearchSelectors = selectors;
export const AdvancedSearchActions = actions;
