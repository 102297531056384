import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PermissionMediaType,
  PermissionStatus,
} from "../../../../shared/Models/client/media/MediaPermissions.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

const slice = createSlice({
  name: "avPermission",
  initialState: {
    screen: "unknown" as PermissionStatus,
    microphone: "unknown" as PermissionStatus,
    camera: "unknown" as PermissionStatus,
  },
  reducers: {
    setPermission: (
      state,
      action: PayloadAction<{ mediaType: PermissionMediaType; status: PermissionStatus }>
    ) => {
      state[action.payload.mediaType] = action.payload.status;
    },

    requestAudioPermission: (state, _: PayloadAction<WindowKey>) => {},
    requestVideoPermission: (state, _: PayloadAction<WindowKey>) => {},
    requestAudioAndVideoPermission: (state, _: PayloadAction<WindowKey>) => {},
    requestScreenSharePermission: (state, _: PayloadAction<void>) => {},

    helpEnableAudio: (state, _: PayloadAction<WindowKey>) => {},
    helpEnableVideo: (state, _: PayloadAction<WindowKey>) => {},
    helpEnableAudioAndVideo: (state, _: PayloadAction<WindowKey>) => {},
    helpEnableScreenShare: (state, _: PayloadAction<void>) => {},
  },
});
export const { actions: AVPermissionActions, reducer: avPermissionReducer } = slice;

export const AVPermissionSelectors = {
  selectScreenPermission: (state: RootState) => state.holo.avPermission.screen,

  selectMicrophonePermission: (state: RootState) => state.holo.avPermission.microphone,

  selectCameraPermission: (state: RootState) => state.holo.avPermission.camera,
};
