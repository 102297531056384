import type { Element, Parent, Text } from "hast";
import { TypeNarrowError } from "../../../shared/TypeNarrowError.js";
import { logger } from "../../../shared/infra/logger.js";

export const buildVisitReplacingHtml = <T extends Record<string, any>>(
  matchRegex: RegExp,
  parseRegexMatch: (matches: RegExpMatchArray) => T | undefined,
  toHref: (param: T) => string,
  className: string
) => {
  const visitReplacingHtml = (node: Element | Parent) => {
    if ("children" in node) {
      for (let i = 0; i < node.children.length; i++) {
        const element = node.children[i];
        if (!element) throw new TypeNarrowError();

        if (element.type === "element") {
          visitReplacingHtml(element);
        } else if (element.type === "text") {
          const text: string = element.value;

          const matches = text.matchAll(matchRegex);
          if (!matches) return;

          let current = 0;
          const replacements = new Array<Text | Element>();
          for (const match of matches) {
            const obj = parseRegexMatch(match);
            if (match.index === undefined) {
              logger.error(`${className} match with no index: ${match}`);
              continue;
            }

            if (!obj) {
              logger.warn(`invalid ${className}: ${JSON.stringify(match)}`);
              continue;
            }

            if (match.index > current) {
              const textNode: Text = {
                type: "text",
                value: text.substring(current, match.index),
              };
              replacements.push(textNode);
            }

            const objNode: Element = {
              type: "element",
              tagName: "a",
              properties: {
                href: toHref(obj),
                className: [className],
              },
              children: [],
            };
            replacements.push(objNode);

            current = match.index + match[0].length;
          }

          if (current < text.length) {
            const textNode: Text = {
              type: "text",
              value: text.substring(current),
            };
            replacements.push(textNode);
          }

          node.children.splice(i, 1, ...replacements);
        }
      }
    }
  };
  return visitReplacingHtml;
};
