import {
  compileShader,
  createPipelineStageProgram,
  createTextureSimple,
  glsl,
} from "../../webglHelper.js";

export type BackgroundImageStage = {
  render(backgroundImage: ImageBitmap): void;
  updateCoverage(coverage: [number, number]): void;
  updateLightWrapping(lightWrapping: number): void;
  setForegroundOverlays(stages: ImageBitmap[]): void;
  cleanUp(): void;
};

export const buildBackgroundImageStage = (
  gl: WebGL2RenderingContext,
  positionBuffer: WebGLBuffer,
  texCoordBuffer: WebGLBuffer,
  personMaskTexture: WebGLTexture
): BackgroundImageStage => {
  const vertexShaderSource = glsl`#version 300 es

    uniform vec2 u_backgroundScale;
    uniform vec2 u_backgroundOffset;

    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;
    out vec2 v_backgroundCoord;

    void main() {
      // Flipping Y is required when rendering to canvas
      gl_Position = vec4(a_position * vec2(1.0, -1.0), 0.0, 1.0);
      v_texCoord = a_texCoord;
      v_backgroundCoord = a_texCoord * u_backgroundScale + u_backgroundOffset;
    }
  `;

  const fragmentShaderSource = glsl`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_personMask;
    uniform sampler2D u_background;
    uniform vec2 u_coverage;
    uniform float u_lightWrapping;
    uniform float u_blendMode;

    in vec2 v_texCoord;
    in vec2 v_backgroundCoord;

    out vec4 outColor;

    void main() {
      vec3 frameColor = texture(u_inputFrame, v_texCoord).rgb;
      vec3 backgroundColor = texture(u_background, v_backgroundCoord).rgb;
      float personMask = texture(u_personMask, v_texCoord).a;
      personMask = smoothstep(u_coverage.x, u_coverage.y, personMask);
      outColor = vec4(mix(backgroundColor, frameColor, personMask), 1.0);
    }
  `;

  const { width: outputWidth, height: outputHeight } = gl.canvas;

  const vertexShader = compileShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = compileShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);
  const program = createPipelineStageProgram(
    gl,
    vertexShader,
    fragmentShader,
    positionBuffer,
    texCoordBuffer
  );
  const backgroundScaleLocation = gl.getUniformLocation(program, "u_backgroundScale");
  const backgroundOffsetLocation = gl.getUniformLocation(program, "u_backgroundOffset");
  const inputFrameLocation = gl.getUniformLocation(program, "u_inputFrame");
  const personMaskLocation = gl.getUniformLocation(program, "u_personMask");
  const backgroundLocation = gl.getUniformLocation(program, "u_background");
  const coverageLocation = gl.getUniformLocation(program, "u_coverage");
  const lightWrappingLocation = gl.getUniformLocation(program, "u_lightWrapping");
  const blendModeLocation = gl.getUniformLocation(program, "u_blendMode");

  gl.useProgram(program);
  gl.uniform2f(backgroundScaleLocation, 1, 1);
  gl.uniform2f(backgroundOffsetLocation, 0, 0);
  gl.uniform1i(inputFrameLocation, 0);
  gl.uniform1i(personMaskLocation, 1);
  gl.uniform1i(backgroundLocation, 2);
  gl.uniform2f(coverageLocation, 0, 1);
  gl.uniform1f(lightWrappingLocation, 0);
  gl.uniform1f(blendModeLocation, 0);

  const backgroundTexture = createTextureSimple(gl);

  let foregroundOverlays: ImageBitmap[] = [];
  let foregroundOverlaysNeedUpdate = true;
  // eslint-disable-next-line no-restricted-globals
  const foregroundCvs = document.createElement("canvas");
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const foregroundCvsCtx = foregroundCvs.getContext("2d")!;

  const render = (backgroundImage: ImageBitmap) => {
    gl.viewport(0, 0, outputWidth, outputHeight);
    gl.useProgram(program);
    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, personMaskTexture);

    if (foregroundOverlaysNeedUpdate) {
      gl.activeTexture(gl.TEXTURE2);
      gl.bindTexture(gl.TEXTURE_2D, backgroundTexture);

      foregroundOverlaysNeedUpdate = false;
      foregroundCvs.width = outputWidth;
      foregroundCvs.height = outputHeight;
      foregroundCvsCtx.clearRect(0, 0, outputWidth, outputHeight);
      drawImageFitCover(backgroundImage);
      for (const img of foregroundOverlays) {
        foregroundCvsCtx.drawImage(img, 0, 0, outputWidth, outputHeight);
      }
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, foregroundCvs);
    }

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  };

  // Draw an image according to object-fit: cover, meaning the image will be
  // drawn maintaining its aspect ratio and ensuring that it completely fills
  // the output canvas. The image may be cropped to only show its middle
  // portion, meaning its top/bottom or left/right may be clipped.
  const drawImageFitCover = (image: ImageBitmap) => {
    const imageRatio = image.width / image.height;
    const outputRatio = outputWidth / outputHeight;

    let width = outputWidth;
    let height = outputHeight;
    if (imageRatio < outputRatio) {
      height = width / imageRatio;
    } else {
      width = height * imageRatio;
    }

    const offsetX = (outputWidth - width) * 0.5;
    const offsetY = (outputHeight - height) * 0.5;

    foregroundCvsCtx.drawImage(image, offsetX, offsetY, width, height);
  };

  const updateCoverage = (coverage: [number, number]) => {
    gl.useProgram(program);
    gl.uniform2f(coverageLocation, coverage[0], coverage[1]);
  };

  const updateLightWrapping = (lightWrapping: number) => {
    gl.useProgram(program);
    gl.uniform1f(lightWrappingLocation, lightWrapping);
  };

  const setForegroundOverlays = (images: ImageBitmap[]) => {
    foregroundOverlays = [...images];
    foregroundOverlaysNeedUpdate = true;
  };

  const cleanUp = () => {
    gl.deleteTexture(backgroundTexture);
    gl.deleteProgram(program);
    gl.deleteShader(fragmentShader);
    gl.deleteShader(vertexShader);
  };

  return {
    render,
    updateCoverage,
    updateLightWrapping,
    setForegroundOverlays,
    cleanUp,
  };
};
