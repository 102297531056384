export enum AutoUpdateStatus {
  DevMode,
  Idle,
  Checking,
  CheckingUserInitiated,
  NoUpdateAvailable,
  Downloading,
  DownloadingUserInitiated,
  UpdateDownloaded,
  Error,
}
