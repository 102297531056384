import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import {
  EmailResult,
  OccupantResult,
  PersonResult,
  UserLocation,
  UserLocationRequest,
  UserLocationResult,
} from "../../../../shared/Models/UserLocation.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const personResultAdapter = createEntityAdapter<PersonResult>({
  selectId: (r: PersonResult) => r.personId,
});
const occupantResultAdapter = createEntityAdapter<OccupantResult>({
  selectId: (r: OccupantResult) => r.occupantId,
});
const emailResultAdapter = createEntityAdapter<EmailResult>({
  selectId: (r: EmailResult) => r.email,
});

const slice = createSlice({
  name: "userStatus",
  initialState: {
    personResults: personResultAdapter.getInitialState(),
    occupantResults: occupantResultAdapter.getInitialState(),
    emailResults: emailResultAdapter.getInitialState(),
  },
  reducers: {
    upsertLocationResults: (state, action: PayloadAction<UserLocationResult[]>) => {
      for (const result of action.payload) {
        switch (result.resultType) {
          case "person":
            personResultAdapter.setOne(state.personResults, result);
            break;
          case "occupant":
            occupantResultAdapter.setOne(state.occupantResults, result);
            break;
          case "email":
            emailResultAdapter.setOne(state.emailResults, result);
            break;
        }
      }
    },
    removeResultsForRequests: (state, action: PayloadAction<UserLocationRequest[]>) => {
      for (const request of action.payload) {
        switch (request.requestType) {
          case "person":
            personResultAdapter.removeOne(state.personResults, request.personId);
            break;
          case "occupant":
            occupantResultAdapter.removeOne(state.occupantResults, request.occupantId);
            break;
          case "email":
            emailResultAdapter.removeOne(state.emailResults, request.email);
            break;
        }
      }
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.userLocation.userStatus;
const personResultSelectors = personResultAdapter.getSelectors();
const occupantResultSelectors = occupantResultAdapter.getSelectors();
const emailResultSelectors = emailResultAdapter.getSelectors();
const selectStatusByEmail = (email?: string) => (state: RootState) =>
  email ? emailResultSelectors.selectById(selectSlice(state).emailResults, email) : undefined;

const selectStatusByOccupant = (occupantId?: string) => (state: RootState) =>
  occupantId
    ? occupantResultSelectors.selectById(selectSlice(state).occupantResults, occupantId)
    : undefined;

const selectStatusByPerson = (personId?: number) => (state: RootState) =>
  personId
    ? personResultSelectors.selectById(selectSlice(state).personResults, personId)
    : undefined;
export const selectors = {
  selectStatusByOccupant,
  selectAllOccupantResults: createDeepEqualSelector(selectSlice, (slice) =>
    occupantResultSelectors.selectAll(slice.occupantResults)
  ),
  selectStatusByPerson,
  selectStatusByEmail,
  selectAllEmailResults: createDeepEqualSelector(selectSlice, (slice) =>
    emailResultSelectors.selectAll(slice.emailResults)
  ),
  selectMostSpecificLocation: defaultMemoize(
    ({
      email,
      occupantId,
      options,
      personId,
    }: {
      email?: string;
      occupantId?: string;
      personId?: number;
      options?: GetMostSpecificLocationOptions;
    }) =>
      createDeepEqualSelector(
        (state) => selectStatusByEmail(email)(state),
        (state) => selectStatusByOccupant(occupantId)(state),
        (state) => selectStatusByPerson(personId)(state),
        (...statuses) => getMostSpecificLocation(statuses, options)
      ),
    { equalityCheck: equal }
  ),
};
export const UserStatusSelectors = selectors;

export interface GetMostSpecificLocationOptions {
  preferRoamId?: number;
  withinSec?: number;
}

const getMostSpecificLocation = (
  results: Array<UserLocationResult | undefined>,
  options?: GetMostSpecificLocationOptions
) => {
  const preferRoamId = options?.preferRoamId;

  let userLocation: UserLocation | undefined;
  for (const result of results) {
    if (result?.status === "found" && result?.locations) {
      for (const location of result.locations) {
        if (!userLocation) {
          userLocation = location;
        } else if (
          userLocation.locationType === "overworld" &&
          location.locationType === "section"
        ) {
          userLocation = location;
        } else if (
          userLocation.locationType === "section" &&
          location.locationType === "section" &&
          location.roamId === preferRoamId
        ) {
          userLocation = location;
        }
      }
    }
  }
  return userLocation;
};
