/**
 * All z-index's should be defined here to give a consistent hierarchy.
 */
enum zIndex {
  BackgroundVisualization = -1_000_000,
  Default,
  // Use auto for SeatGrid so it does not establish a new local stacking context and therefore the
  // text reaction (which will fall outside of the seat grid bounds) can go on top of other seat grids.
  // eslint-disable-next-line @typescript-eslint/no-mixed-enums
  SeatGrid = "auto",
  // Start at a high value to discourage engineers from hardcoding constants directly in CSS.
  SeatGridHeader = 1_000_000,
  AuditoriumStageLeftColumn,
  AuditoriumStagePresenterName,
  ChatMessageFromImageOrInitials,
  BottomSheet,
  MapDragSelection,
  SeatGridOverflowMenu,
  SeatGridDragging,
  SeatGridActive,
  AuditoriumSeatPodHighlighted,
  CardViewPdfControls,
  CardViewStreamVideo,
  CardViewStreamOverlay,
  CardViewTopBar,
  CardViewFormatControls,
  CardViewResizeHandle,
  BoardToggleFullScreen,
  BoardControls,
  BoardPointer,
  BoardToolbar,
  Shelf,
  SeatEmojiReaction,
  SeatGridCursorNotAllowed,
  FloorElevator,
  FloorElevatorDraggingFloor,
  FloorElevatorOverlay,
  PanelsOverlay,
  NavHeader,
  OverworldMessage,
  MapMessage,
  MapMessageTyping,
  FloatingMeetingPanel,
  UserMediaControlsVideoPreview,
  WhiteboardReadOnlyOverlay,
  FullScreenControl,
  RoomActionsOrnament,
  InteractionPopup,
  InteractionPopupFocused,
  DropdownMenuOptions,
  DropdownItemSticky,
  KnockPopup,
  InteractionPopupControls,
  EmojiPicker,
  MeetingFeedback,
  ToastsBar,
  DockToolbar,
  Tooltip,
  ShelfOpen,
  ShelfItemFloating,
  Modal,
  ChatTargetSuggestions,
  WindowTitleBar,
  WindowTitleBarControls,
}

export default zIndex;
