import { createContext, useContext } from "react";
import { IPipelinesContainer } from "./IPipelinesContainer.js";

export const PipelinesContext = createContext<IPipelinesContainer | undefined>(undefined);

export const usePipelines = (): IPipelinesContainer => {
  const container = useContext(PipelinesContext);
  if (!container) {
    throw new Error("no container");
  }
  return container;
};
