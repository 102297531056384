import { cloneDeep } from "lodash";
import { ChatTarget, EmailChatTarget } from "../../../shared/Models/ChatAddress.js";
import { UnreachableError } from "../../../shared/helpers/UnreachableError.js";
import { validateEmail } from "../../../shared/helpers/email.js";

export const targetsAreEqual = (t1: ChatTarget, t2: ChatTarget) => {
  switch (t1.targetType) {
    case "email":
      return t2.targetType === "email" && t1.email === t2.email;
    case "client":
      return t2.targetType === "client" && t1.clientUuid === t2.clientUuid;
    case "occupant":
      return t2.targetType === "occupant" && t1.occupantId === t2.occupantId;
    case "standardGroup":
      return t2.targetType === "standardGroup" && t1.addressId === t2.addressId;
    case "teamRoam":
      return t2.targetType === "teamRoam" && t1.memberEmail === t2.memberEmail;
    case "bot":
      return (
        t2.targetType === "bot" &&
        t1.roamId === t2.roamId &&
        t1.integrationId === t2.integrationId &&
        t1.botCode === t2.botCode
      );
    case "meetingGroup":
      return t2.targetType === "meetingGroup" && t1.addressId === t2.addressId;
    default:
      throw new UnreachableError(t1);
  }
};

export const targetListsAreEqual = (
  list1: ChatTarget[],
  list2: ChatTarget[],
  ignore?: ChatTarget[]
) => {
  const workingList2 = cloneDeep(
    list2.filter((t2) => !ignore?.some((ti) => targetsAreEqual(t2, ti)))
  );
  for (const t1 of list1) {
    if (ignore?.some((ti) => targetsAreEqual(ti, t1))) continue;

    const i = workingList2.findIndex((t2) => targetsAreEqual(t1, t2));
    if (i >= 0) {
      workingList2.splice(i, 1);
    } else {
      return false;
    }
  }

  return workingList2.length === 0;
};

export const targetLongName = (target: ChatTarget) => {
  return target.displayName || "(unknown)";
};

export const targetShortName = (target: ChatTarget) => {
  return target?.displayName?.split(" ")[0] || "(unknown)";
};

export const validatedEmailTarget = (email: string): EmailChatTarget | undefined => {
  const validatedEmail = validateEmail(email);
  if (!validatedEmail) return undefined;
  return typeof validatedEmail === "string"
    ? { targetType: "email", email: validatedEmail, displayDetail: validatedEmail }
    : {
        targetType: "email",
        email: validatedEmail.email,
        displayName: validatedEmail.name,
        displayDetail: validatedEmail.email,
      };
};
