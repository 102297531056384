import { produce } from "immer";
import {
  PostProcVideoSpec,
  VideoSourceSpec,
} from "../../client/avStream/video/interfaces/IVideoPipelineLauncher.js";
import { VideoPipelineName } from "../../shared/Models/VideoPipeline.js";
import sample_image from "../../website/static/news/hero-opengraph.png";

export const initialSourceSpec: VideoSourceSpec = {
  deviceIds: [],
  fallback: "last",
};

export interface PostProcState {
  spec: PostProcVideoSpec;

  // These can be used for display
  blurLevel: number;
  touchUpLevel: number;
  pipelineOn: boolean;
  pipeline?: VideoPipelineName;
  bgImageOn: boolean;
  autoPipeline?: string;
}

export const initialPostProcState: PostProcState = {
  spec: {
    pipelineName: "mediapipeBasicNoWorker",
    disableInsertableStreams: false,
    virtbg: { mode: "blurred", blurLevel: 3 },
    touchupLevel: 5,
  },
  blurLevel: 5,
  touchUpLevel: 5,
  pipelineOn: true,
  bgImageOn: false,
};

export type PostProcAction =
  | { action: "togglePipeline" }
  | { action: "toggleBgImage" }
  | { action: "toggleDisableInsertableStreams" }
  | { action: "setBlurLevel"; blurLevel: number }
  | { action: "setTouchUpLevel"; touchUpLevel: number }
  | { action: "setDebugStage"; stage: number }
  | { action: "setPipeline"; pipeline: string }
  | { action: "setAutoPipeline"; pipeline?: string };

export const postProcReducer = (current: PostProcState, action: PostProcAction): PostProcState =>
  produce(current, (draft) => {
    switch (action.action) {
      case "togglePipeline": {
        draft.pipelineOn = !draft.pipelineOn;
        break;
      }
      case "setPipeline": {
        const result = VideoPipelineName.safeParse(action.pipeline);
        if (result.success) {
          draft.spec.pipelineName = result.data;
          draft.pipeline = result.data;
        }
        break;
      }
      case "setAutoPipeline": {
        draft.autoPipeline = action.pipeline;
        if (draft.pipeline === undefined) {
          const result = VideoPipelineName.safeParse(action.pipeline);
          if (result.success) {
            draft.spec.pipelineName = result.data;
            draft.pipeline = result.data;
          }
        }
        break;
      }
      case "setTouchUpLevel": {
        draft.spec.touchupLevel = action.touchUpLevel;
        draft.touchUpLevel = action.touchUpLevel;
        break;
      }
      case "setDebugStage": {
        draft.spec.debugStage = action.stage;
        break;
      }
      case "setBlurLevel": {
        if (action.blurLevel === 0) {
          draft.spec.virtbg = undefined;
          draft.blurLevel = 0;
        } else {
          draft.blurLevel = action.blurLevel;
          draft.spec.virtbg = {
            mode: "blurred",
            blurLevel: action.blurLevel,
          };
        }
        break;
      }
      case "toggleBgImage": {
        if (draft.bgImageOn) {
          draft.bgImageOn = false;
          if (draft.blurLevel > 0) {
            draft.spec.virtbg = {
              mode: "blurred",
              blurLevel: draft.blurLevel,
            };
          } else {
            draft.spec.virtbg = undefined;
          }
        } else {
          draft.bgImageOn = true;
          draft.spec.virtbg = {
            mode: "static_image",
            imageSrc: sample_image,
          };
        }
        break;
      }
      case "toggleDisableInsertableStreams": {
        draft.spec.disableInsertableStreams = draft.spec.disableInsertableStreams ? false : true;
        break;
      }
    }
  });
