/* eslint-disable no-restricted-imports */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { memoize } from "lodash";
import { Chat } from "../../../../shared/Models/Chat.js";
import { ChatMessageContent } from "../../../../shared/Models/ChatMessage.js";
import { MeetingChatSender } from "../../../../shared/SectionMessages/ServerMeetingChat.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";
import { DeleteMessagePayload, RetryPendingMessagePayload } from "./messageSlice.js";

interface SetMeetingChatPayload {
  chat: Chat;
}

export interface UpdateLastMessagePayload {
  lastMessageTime: number;
  alsoUpdateLastRead?: boolean;
  overwriteNewerTimestamp?: boolean;
}

export interface SendMeetingChatMessagePayload {
  content: ChatMessageContent;
  chatId: string;
  messageId: string;
}

export interface SendEmojiPayload {
  text: string;
  colons: string;
  fileId?: string;
}

export interface MeetingToClear {
  chatId: string;
  startedAt: number; // time in millis
}

export interface UpsertMeetingChatSendersPayload {
  chatId: string;
  senders: MeetingChatSender[];
}

const slice = createSlice({
  name: "meetingChat",
  initialState: {
    meetingsToClear: [] as MeetingToClear[],
    meetingChat: undefined as Chat | undefined,
    senders: {} as { [chatId: string]: { [fakeAddressId: string]: MeetingChatSender } },
  },
  reducers: {
    sendMessage: (state, action: PayloadAction<SendMeetingChatMessagePayload>) => {},
    retryPendingMessage: (state, action: PayloadAction<RetryPendingMessagePayload>) => {},
    deleteMessage: (state, action: PayloadAction<DeleteMessagePayload>) => {},
    sendEmoji: (state, action: PayloadAction<SendEmojiPayload>) => {},

    setMeetingChat: (state, action: PayloadAction<SetMeetingChatPayload>) => {
      const { chat } = action.payload;
      state.meetingChat = chat;
    },
    clearMeetingChat: (state) => {
      state.meetingChat = undefined;
    },
    startClearMeetingMessages: (state, action: PayloadAction<MeetingToClear>) => {
      const meetingToClear = action.payload;
      // Replace an older request to clear messages.
      const previousIndex = state.meetingsToClear.findIndex(
        (m) => m.chatId === meetingToClear.chatId
      );
      if (previousIndex >= 0) {
        state.meetingsToClear.splice(previousIndex, 1);
      }
      state.meetingsToClear.push(meetingToClear);
    },
    doneClearMeetingMessages: (state, action: PayloadAction<string>) => {
      const clearIndex = state.meetingsToClear.findIndex((m) => m.chatId === action.payload);
      if (clearIndex >= 0) {
        state.meetingsToClear.splice(clearIndex, 1);
      }
    },
    clearMeetingChatSenders: (state, action: PayloadAction<string>) => {
      const chatId = action.payload;
      delete state.senders[chatId];
    },
    updateLastMessage: (state, action: PayloadAction<UpdateLastMessagePayload>) => {
      const { lastMessageTime, alsoUpdateLastRead, overwriteNewerTimestamp } = action.payload;

      const chat = state.meetingChat;
      if (
        chat &&
        (!chat.lastMessageTime ||
          chat.lastMessageTime < (lastMessageTime || 0) ||
          overwriteNewerTimestamp)
      ) {
        chat.lastMessageTime = lastMessageTime;
        if (alsoUpdateLastRead) chat.clientReadThrough = lastMessageTime;
      }
    },
    upsertMeetingChatSenders: (state, action: PayloadAction<UpsertMeetingChatSendersPayload>) => {
      const { chatId, senders } = action.payload;
      const chatSenders: { [fakeAddressId: string]: MeetingChatSender } =
        state.senders[chatId] ?? {};

      for (const sender of senders) {
        chatSenders[sender.fakeAddressId] = sender;
      }

      state.senders[chatId] = chatSenders;
    },
    markAsRead: (state) => {
      const chat = state.meetingChat;
      if (chat) {
        chat.clientReadThrough = chat.lastMessageTime;
      }
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.meetingChat;
export const selectors = {
  selectMeetingsToClear: createSelector(selectSlice, (slice) => slice.meetingsToClear),
  selectMeetingToClear: memoize((chatId: string) =>
    createSelector(selectSlice, (slice) => slice.meetingsToClear.find((m) => m.chatId === chatId))
  ),
  selectMeetingChatId: createSelector(selectSlice, (slice) => slice.meetingChat?.id),
  selectMeetingChat: createSelector(selectSlice, (slice) => slice.meetingChat),
  selectMeetingChatSender: (chatId?: string, fakeAddressId?: string) => (state: RootState) =>
    chatId && fakeAddressId ? selectSlice(state).senders[chatId]?.[fakeAddressId] : undefined,
};

export const MeetingChatSelectors = selectors;
export const MeetingChatActions = actions;
