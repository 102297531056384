import { isObject, merge, transform } from "lodash";
import { Mark } from "../Models/Whiteboard.js";

export const applyMarkPatch = (mark: Mark | undefined, patchMark: Partial<Mark>): Mark => {
  const patchedMark = merge({}, mark, patchMark);

  return removeNullProperties(patchedMark);
};

const removeNullProperties = <T extends object>(object: T): T =>
  transform(object, (result: any, value: any, key: any) => {
    if (value === null) return;
    result[key] = isObject(value) ? removeNullProperties(value) : value;
  });
