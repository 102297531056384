import { z } from "zod";

/**
 * The source of an access badge action.
 */
export const AccessBadgeActionSource = z.enum([
  /** Via an occupant action (i.e. the buttons in a chat popup). */
  "OCCUPANT_ACTION",
  /**
   * A system prompt within chat itself.
   *
   * E.g. the reciprocate access badge prompt.
   */
  "CHAT_PROMPT",
  /** Via the "guests" panel in the inbox */
  "GUEST_PANEL",
  /** Via the group external guest flow */
  "GROUP_EXTERNAL_GUEST",
  /** Via the settings page. */
  "SETTINGS",
  /** Via the "people you may know" section on the overworld */
  "SOCIAL_SUGGESTION",
  /** Via the Slack Connect migration tool. */
  "SLACK_MIGRATION",
]);
export type AccessBadgeActionSource = z.infer<typeof AccessBadgeActionSource>;
