import { z } from "zod";

/**
 * Defines a range of numbers.
 */
export const NumberRange = z.object({
  // Inclusive
  start: z.union([z.literal("first"), z.number().positive()]),

  // Exclusive
  end: z.union([z.literal("last"), z.number().positive()]),
});
export type NumberRange = z.infer<typeof NumberRange>;

/**
 * Defines a multiple range of numbers. All ranges are non overlapping and sorted in ascending
 * order. In order to maintain these properties, use only the functions below to modify
 * NumberRanges.
 */
export type NumberRanges = NumberRange[];

export const rangesOverlapOrContiguous = (left?: NumberRange, right?: NumberRange): boolean => {
  if (!left || !right) return false;

  return num(left.start) <= num(right.end) && num(left.end) >= num(right.start);
};

/**
 * Return true if right is after or overlaps with left.
 */
export const rangeOverlapsOrIsAfter = (left?: NumberRange, right?: NumberRange): boolean => {
  if (!left || !right) return false;

  return num(right.start) >= num(left.start) || num(left.start) <= num(right.end);
};

export const combineRanges = (left: NumberRange, right: NumberRange): NumberRange => {
  return {
    start: num(left.start) <= num(right.start) ? left.start : right.start,
    end: num(left.end) >= num(right.end) ? left.end : right.end,
  };
};

export const rangeContaining = (
  ranges: NumberRanges,
  value: number | "first" | "last"
): NumberRange | undefined => {
  for (const range of ranges) {
    if (
      (num(range.start) <= num(value) && num(range.end) > num(value)) ||
      (range.end === "last" && value === "last")
    ) {
      return range;
    }
  }

  return undefined;
};

/**
 * Inserts the given range and combine any overlapping ranges.
 */
export const insertRange = (ranges: NumberRanges, range: NumberRange): NumberRanges => {
  if (Object.isFrozen(ranges) || Object.isSealed(ranges)) {
    ranges = [...ranges];
  }

  // Find the index to insert the new segment, and the segments to merge together
  const index = ranges.findIndex((existingRange) => rangeOverlapsOrIsAfter(range, existingRange));
  if (index === -1) {
    // This segment gets added to the end
    ranges.push(range);
  } else {
    let combineCount = 0;
    while (rangesOverlapOrContiguous(range, ranges[index + combineCount])) {
      combineCount++;
    }

    for (const toCombine of ranges.slice(index, index + combineCount)) {
      range = combineRanges(toCombine, range);
    }

    ranges.splice(index, combineCount, range);
  }

  return ranges;
};

/**
 * Finds the subarray of sortedItems who fall into the given range, returning the startIndex
 * (inclusive) and endIndex (exclusive)
 */
export const findItemsInRange = <T>(
  sortedItems: T[],
  range: NumberRange,
  getValue: (item: T) => number
): { startIndex: number; endIndex: number } => {
  const startIndex = sortedItems.findIndex((item) => getValue(item) >= num(range.start));
  if (startIndex === -1) {
    return { startIndex: sortedItems.length, endIndex: sortedItems.length };
  }

  let endIndex = sortedItems.findIndex((item) => getValue(item) >= num(range.end));
  if (endIndex === -1) {
    endIndex = sortedItems.length;
  }
  return { startIndex, endIndex };
};

export const num = (rangeValue: "first" | "last" | number): number => {
  if (rangeValue === "first") {
    return Number.NEGATIVE_INFINITY;
  }
  if (rangeValue === "last") {
    return Number.POSITIVE_INFINITY;
  }
  return rangeValue;
};
