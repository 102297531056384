import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { z } from "zod";
import {
  IdentityProvider,
  NewIdentityProvider,
} from "../../../../shared/Models/auth/IdentityProvider.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export const NewIdentityProviderStatus = z.enum(["UNKNOWN", "PENDING", "SUCCESS", "FAIL"]);
export type NewIdentityProviderStatus = z.infer<typeof NewIdentityProviderStatus>;

const adapter = createEntityAdapter<IdentityProvider>();

const slice = createSlice({
  name: "saml",
  initialState: {
    identityProviders: adapter.getInitialState(),
    newIdentityProviderStatuses: {} as Record<string, NewIdentityProviderStatus>,
    // General error, mainly for the settings table
    identityProviderError: undefined as string | undefined,
    // provider ID -> error message
    identityProviderWriteErrors: {} as Record<string, string>,
  },
  reducers: {
    // RPC
    createIdentityProvider: (state, action: PayloadAction<NewIdentityProvider>) => {},
    fetchIdentityProvidersByAccountId: (state, action: PayloadAction<number>) => {},
    updateIdentityProvider: (state, action: PayloadAction<IdentityProvider>) => {},
    deleteIdentityProvider: (state, action: PayloadAction<string>) => {},
    refreshIdentityProvidersByAccountId: (state, action: PayloadAction<number>) => {},

    // Adapter
    upsertIdentityProvider: (state, action: PayloadAction<IdentityProvider>) => {
      adapter.upsertOne(state.identityProviders, action.payload);
    },
    upsertIdentityProviders: (state, action: PayloadAction<IdentityProvider[]>) => {
      adapter.upsertMany(state.identityProviders, action.payload);
    },
    removeIdentityProvider: (state, action: PayloadAction<string>) => {
      adapter.removeOne(state.identityProviders, action.payload);
    },
    removeIdentityProvidersByAccountId: (state, action: PayloadAction<number>) => {
      const providers = adapter.getSelectors().selectAll(state.identityProviders);
      const accountProviderIds = providers
        .filter((provider) => provider.accountId === action.payload)
        .map((provider) => provider.id);
      adapter.removeMany(state.identityProviders, accountProviderIds);
    },

    /**
     * Updates the status for creating a new identity provider.
     */
    setNewIdentityProviderStatus: (
      state,
      action: PayloadAction<{ id: string; status: NewIdentityProviderStatus }>
    ) => {
      const { id, status } = action.payload;
      state.newIdentityProviderStatuses[id] = status;
    },
    setIdentityProviderError: (state, action: PayloadAction<string | undefined>) => {
      state.identityProviderError = action.payload;
    },
    clearIdentityProviderError: (state) => {
      state.identityProviderError = undefined;
    },
    setIdentityProviderWriteError: (
      state,
      action: PayloadAction<{ id: string; error: string | undefined }>
    ) => {
      const { id, error } = action.payload;
      if (!error) {
        delete state.identityProviderWriteErrors[id];
      } else {
        state.identityProviderWriteErrors[id] = error;
      }
    },
    clearIdentityProviderWriteError: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      delete state.identityProviderWriteErrors[id];
    },
  },
});

export const { actions, reducer } = slice;
export const SamlActions = slice.actions;
export const SamlReducers = slice.reducer;

const selectSlice = (state: RootState) => state.anyWorld.saml;
const adapterSelectors = adapter.getSelectors();

export const selectors = {
  selectIdentityProviderById: (id: string) => (state: RootState) =>
    adapterSelectors.selectById(selectSlice(state).identityProviders, id),
  selectIdentityProvidersByAccount: defaultMemoize((accountId: number) =>
    createDeepEqualSelector(selectSlice, (slice): IdentityProvider[] => {
      return adapterSelectors
        .selectAll(slice.identityProviders)
        .filter((provider) => provider.accountId === accountId);
    })
  ),
  /**
   * The status of an attempt to create an identity provider with ID [providerId].
   *
   * Only works for create attempts tracked in the current redux store.
   */
  selectNewIdentityProviderStatus: (providerId: string) => (state: RootState) =>
    selectSlice(state).newIdentityProviderStatuses[providerId] ?? "UNKNOWN",

  selectIdentityProviderError: (state: RootState) => selectSlice(state).identityProviderError,
  /**
   * The error message from the attempt to write to identity provider [providerId].
   *
   * Only works for write attempts tracked in the current redux store.
   */
  selectIdentityProviderWriteError: (providerId: string) => (state: RootState) =>
    selectSlice(state).identityProviderWriteErrors[providerId] ?? "",
};
export const SamlSelectors = selectors;
