import data from "@emoji-mart/data/sets/14/apple.json";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { init } from "emoji-mart";
import { CustomEmoji } from "../../../../shared/Models/chat/CustomEmoji.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";
import { customEmojiCategories } from "../../helpers/customEmoji.js";

type SetCustomEmojiPayload = {
  emoji: CustomEmoji[];
};

export type CreateCustomEmojiPayload = {
  emoji: CustomEmoji;
  itemId: string;
  roamId?: number;
};

const slice = createSlice({
  name: "customEmoji",
  initialState: {
    // Emoji :code: to fileId
    emojiFileId: {} as Record<string, string>,
    emoji: [] as CustomEmoji[],
  },
  reducers: {
    fetchCustomEmoji: (state) => {},
    setCustomEmoji: (state, action: PayloadAction<SetCustomEmojiPayload>) => {
      const { emoji } = action.payload;
      const updatedEmoji: CustomEmoji[] = [];
      const emojiFileId: Record<string, string> = {};
      for (const aEmoji of emoji) {
        updatedEmoji.push(aEmoji);
        emojiFileId[aEmoji.code] = aEmoji.fileId;
      }
      state.emoji = updatedEmoji;
      state.emojiFileId = emojiFileId;

      // setCustomEmoji updates the data used by Picker (redux).
      // emoji-mart init() updates the data used by EmojiSearch.
      void init({ data, custom: customEmojiCategories(updatedEmoji) });
    },
    createCustomEmoji: (state, action: PayloadAction<CreateCustomEmojiPayload>) => {},
    addCustomEmoji: (state, action: PayloadAction<CustomEmoji>) => {
      const emoji = action.payload;
      state.emoji.push(emoji);
      state.emojiFileId[emoji.code] = emoji.fileId;
      void init({ data, custom: state.emoji });
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.customEmoji;

export const selectors = {
  selectAllCustomEmoji: createSelector(selectSlice, (customEmoji) => {
    return customEmoji.emoji;
  }),
  selectCustomEmojiByCode: (code: string) =>
    createSelector(selectSlice, (customEmoji) => {
      const fileId = customEmoji.emojiFileId[code];
      if (fileId) {
        return { code, fileId };
      }
    }),
};

export const CustomEmojiActions = actions;
export const CustomEmojiSelectors = selectors;
