import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { Shelf } from "../../../../shared/Models/Shelf.js";
import { TypeNarrowError } from "../../../../shared/TypeNarrowError.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface ShelvesByRoomPayload {
  [roomId: number]: Shelf[];
}

export interface ShelvesForFloorPayload {
  floorId: number;
  shelvesByRoom: {
    [roomId: number]: Shelf[];
  };
}

export interface PersonShelvesPayload {
  personId: number;
  shelves: Shelf[];
}

export interface RoomShelfPayload {
  roomId: number;
  floorId: number;
  shelfId: string;
}

export interface AddPersonShelfPayload {
  roomId: number;
  floorId: number;
  personId: number;
}

export interface AddRoomShelfPayload {
  roomId: number;
  floorId: number;
  name: string;
}

export interface RenameShelfPayload {
  shelfId: string;
  name: string;
}

export interface MoveShelfPayload {
  floorId: number;
  roomId: number;
  shelfId: string;
  sourcePosition: number;
  destPosition: number;
}

export interface AddTemporaryShelfPayload {
  floorId: number;
  roomId: number;
  shelfId: string;
}

export interface RemoveTemporaryShelfPayload {
  floorId: number;
  roomId: number;
  shelfId: string;
}

const slice = createSlice({
  name: "shelf",
  initialState: {
    shelvesByRoom: {} as {
      [roomId: number]: Shelf[];
    },
    shelvesByPerson: {} as {
      [personId: number]: Shelf[];
    },
    shelfEditError: undefined as string | undefined,
    lastShelfByRoom: undefined as
      | {
          [roomId: number]: Shelf[];
        }
      | undefined,
  },
  reducers: {
    // Trigger server actions
    addShelfToRoom: (state, action: PayloadAction<RoomShelfPayload>) => {},
    removeShelfFromRoom: (state, action: PayloadAction<RoomShelfPayload>) => {},
    addNewRoomShelf: (state, action: PayloadAction<AddRoomShelfPayload>) => {},
    addPersonShelfToRoom: (state, action: PayloadAction<AddPersonShelfPayload>) => {},
    renameShelf: (state, action: PayloadAction<RenameShelfPayload>) => {},
    moveShelfInRoom: (state, action: PayloadAction<MoveShelfPayload>) => {
      const { roomId, sourcePosition, destPosition } = action.payload;

      const shelf = state.shelvesByRoom[roomId];

      if (!shelf) {
        return;
      }

      state.lastShelfByRoom = {
        [roomId]: cloneDeep(shelf),
      };
      const removed = shelf.splice(sourcePosition, 1);
      if (removed[0]) {
        shelf.splice(destPosition, 0, removed[0]);
      }
    },
    undoOptimisticMove: (state) => {
      if (state.lastShelfByRoom) {
        for (const roomId in state.lastShelfByRoom) {
          if (Object.prototype.hasOwnProperty.call(state.lastShelfByRoom, roomId)) {
            const lastShelf = state.lastShelfByRoom[roomId];
            if (!lastShelf) throw new TypeNarrowError();
            state.shelvesByRoom[roomId] = lastShelf;
          }
        }
      }
      state.lastShelfByRoom = undefined;
    },

    setShelfEditError: (state, action: PayloadAction<string>) => {
      state.shelfEditError = action.payload;
    },
    clearShelfEditError: (state) => {
      state.shelfEditError = undefined;
    },

    // From server messages
    setShelvesByRoom: (state, action: PayloadAction<ShelvesByRoomPayload>) => {
      const shelvesByRoom = action.payload;
      for (const roomId in shelvesByRoom) {
        if (Object.prototype.hasOwnProperty.call(state.shelvesByRoom, roomId)) {
          const shelf = shelvesByRoom[roomId];
          if (!shelf) throw new TypeNarrowError();
          state.shelvesByRoom[roomId] = shelf;
        }
      }
    },
    setFloorShelves: (state, action: PayloadAction<ShelvesForFloorPayload>) => {
      const { shelvesByRoom } = action.payload;
      state.shelvesByRoom = shelvesByRoom;
    },
    setPersonShelves: (state, action: PayloadAction<PersonShelvesPayload>) => {
      const { personId, shelves } = action.payload;
      state.shelvesByPerson[personId] = shelves;
    },

    addTemporaryShelf: (state, action: PayloadAction<AddTemporaryShelfPayload>) => {},
    removeTemporaryShelf: (state, action: PayloadAction<RemoveTemporaryShelfPayload>) => {},
  },
});

export const { actions, reducer } = slice;

export const selectors = {
  selectShelvesByRoom: (roomId?: number) => (state: RootState) =>
    roomId ? state.things.shelf.shelvesByRoom[roomId] : undefined,
  selectShelvesByPerson: (personId?: number) => (state: RootState) =>
    personId ? state.things.shelf.shelvesByPerson[personId] : undefined,
  selectMyShelves: createDeepEqualSelector(
    (state: RootState) => state.anyWorld.account.authedPeople,
    (state: RootState) => state.things.shelf,
    (authedPeople, slice) => {
      if (!authedPeople.length) return [];
      const allShelves = [];
      let anyDefined = false;
      for (const person of authedPeople) {
        const shelves = slice.shelvesByPerson[person.personId];
        if (shelves !== undefined) {
          anyDefined = true;
          allShelves.push(...shelves);
        }
      }
      return anyDefined ? allShelves : undefined;
    }
  ),
};
