import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SocialSuggestion } from "../../../../shared/Models/SocialSuggestion.js";
import { RootState } from "../../../store/reducers.js";

const slice = createSlice({
  name: "recommendations",
  initialState: {
    suggestions: [] as SocialSuggestion[],
  },
  reducers: {
    fetchSuggestions: (state, action: PayloadAction) => {},
    setSuggestions: (state, action: PayloadAction<SocialSuggestion[]>) => {
      state.suggestions = action.payload;
    },
  },
});
export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.overworld.recommendations;
export const selectors = {
  selectSuggestions: (state: RootState) => selectSlice(state).suggestions,
};
