import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RoamaniacReason } from "../../../../shared/Models/RoamaniacReason.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface CompanyReferralSubmission {
  refereeEmail: string;
  refereeName: string;
  refereeCompany: string;
  subsource: string;
}

const slice = createSlice({
  name: "roamaniac",
  initialState: {
    roamaniacReasonsByEmail: {} as Record<string, RoamaniacReason[]>,
  },
  reducers: {
    requestReasonsByEmails: (state, action: PayloadAction<string[]>) => {},
    setReasonsForEmails: (state, action: PayloadAction<Record<string, RoamaniacReason[]>>) => {
      for (const [email, reasons] of Object.entries(action.payload)) {
        state.roamaniacReasonsByEmail[email] = reasons;
      }
    },
    createReferral: (state, action: PayloadAction<CompanyReferralSubmission>) => {},
  },
});

export const { actions, reducer } = slice;
const selectSlice = (state: RootState) => state.anyWorld.roamaniac;
export const selectors = {
  selectReasonsByEmail: (email?: string) =>
    createSelector(selectSlice, (slice) => {
      if (email) {
        return slice.roamaniacReasonsByEmail[email];
      }
    }),
};

export const RoamaniacActions = actions;
export const RoamaniacSelectors = selectors;
