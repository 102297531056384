import { z } from "zod";
import { Transcription } from "./Transcriptions.js";
import { numberId, stringDate, uuid } from "./zodTypes.js";

// Note: Each SavedRecording/RecordingAssignment has a unique UUID. Having the UUID may be considered to
// grant access to the recorded video, so use with caution.

// Hard limit: No recordings more than 12 hours long (expressed in milliseconds)
export const RecordingMaxLength = 12 * 3600 * 1000;

export const RecordingBotEmailAddress = "recordingbot@wonder.inc";

export const Utterance = z.object({
  speaker: z.string(),
  text: z.string(),
});
export type Utterance = z.infer<typeof Utterance>;

export const SavedRecording = z.object({
  id: uuid(),
  roamId: numberId(),
  location: z.string(), // Contains the floor (and room?) name (not ids since floors/rooms can be deleted)
  startTime: stringDate(),
  endTime: stringDate(),
  hostPersonId: numberId(),
  // Note: Only the fields from here on can be modified after the recording is first created
  // Password used to allow the host to play the recording
  // Used in the Electron app since it can't send the cookies
  hostAccessPwd: z.string(),
  // Password that allows access (if this is empty, password access is disabled, and only the "host"
  // can view it)
  accessPwd: z.string(),
  // Is there actually a recording in S3? true means yes, false means no, null/undefined means that the server
  // doesn't know (this is displayed as "processing" to the client)
  // This can be false if e.g. the bot crashes (hopefully not!), or if the user clicks "Stop Recording" before
  // a bot can enter the room.
  uploaded: z.boolean().optional(),
  // The "exact" time in unix millis when the recording started, recorded by the bot.
  // Note: This is set on a best-effort basis. A recording may have uploaded=true but exactStartTime=undefined
  // in certain edge cases. If this field is undefined, startTime should be used instead as a fallback.
  exactStartTime: z.number().optional(),
  savedMeetingId: uuid().optional(),
  transcription: Transcription.optional(),
});
export type SavedRecording = z.infer<typeof SavedRecording>;

export const RecordingAssignment = z.object({
  id: uuid(),
  roamId: numberId(),
  floorId: numberId().optional(), // Optional for backwards compatibility, but should be initialized whenever possible
  roomId: numberId(),
  location: z.string(), // Contains the floor (and room?) name (not ids since floors/rooms can be deleted)
  startTime: stringDate(),
  hostPersonId: numberId(),
  savedMeetingId: uuid().optional(),
});
export type RecordingAssignment = z.infer<typeof RecordingAssignment>;

export const RecordingBotCookieOptions = z.object({
  path: z.string(),
  maxAge: z.number().optional(),
  httpOnly: z.boolean(),
  signed: z.boolean(),
  domain: z.string(),
  secure: z.boolean(),
});
export type RecordingBotCookieOptions = z.infer<typeof RecordingBotCookieOptions>;

export const RecordingBotCookie = z.object({
  name: z.string(),
  value: z.string(),
  options: RecordingBotCookieOptions,
});
export type RecordingBotCookie = z.infer<typeof RecordingBotCookie>;

// Contains the info the bot needs
export const RecordingBotAssignment = z.object({
  id: uuid(),
  link: z.string(),
  cookies: z.array(RecordingBotCookie),
  hostPersonId: numberId(),
});
export type RecordingBotAssignment = z.infer<typeof RecordingBotAssignment>;
