import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "../../../helpers/redux.js";
import { updateInternal, WantHave } from "../../../store/clientConnectionHelpers.js";
import { RootState } from "../../../store/reducers.js";

export interface ThingsConnectionResult {
  connectedToThings: boolean;
  thingsConnectionError?: string;
}

export interface InventoryOpenResult {
  inventoryPersonId?: number;
  inventoryOpenError?: string;
}

const slice = createSlice({
  name: "shelf",
  initialState: {
    wantThingsConnected: false,
    internalWantThingsConnected: false,
    internalHaveThingsConnected: false,

    thingsConnectedRetryTime: undefined as string | undefined,
  },
  reducers: {
    setWantThingsConnected: (state, action: PayloadAction<boolean>) => {
      state.wantThingsConnected = action.payload;
    },
    setInternalThingsConnected: (state, action: PayloadAction<WantHave<boolean>>) => {
      updateInternal(state, "ThingsConnected", action.payload);
    },
    setThingsConnectedRetryTime: (state, action: PayloadAction<string | undefined>) => {
      state.thingsConnectedRetryTime = action.payload;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.things.things;
export const selectors = {
  selectWantThingsConnected: createSelector(selectSlice, (slice) => slice.wantThingsConnected),
  selectInternalWantThingsConnected: createSelector(
    selectSlice,
    (slice) => slice.internalWantThingsConnected
  ),
  selectInternalHaveThingsConnected: createSelector(
    selectSlice,
    (slice) => slice.internalHaveThingsConnected
  ),
  selectThingsConnectedRetryTime: createSelector(
    selectSlice,
    (slice) => slice.thingsConnectedRetryTime
  ),
};
