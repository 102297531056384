import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import {
  ApiBot,
  ApiClient,
  ApiClientType,
  ApiKeyClientDevDetails,
  OAuthClientDevDetails,
} from "../../../shared/Models/ApiClient.js";
import { createSelector } from "../../helpers/redux.js";
import { WindowKey } from "../../injection/windows/WindowKey.js";
import { RootState } from "../../store/reducers.js";

export interface SetApiClientsByAccountPayload {
  accountId: number;
  apiClients: ApiClient[];
}
export interface CreateApiClientPayload {
  clientType: ApiClientType;
  developerAccountId: number;
  roamId?: number; // clientType apiKey only
  name: string;
  description?: string;
  defaultImageUrl: string;
  windowKey: WindowKey;
  openModalOnSuccess: boolean;
}

export interface UpdateApiClientPayload {
  clientId: string;
  name: string;
  description?: string;
  defaultImageUrl: string;
  scopes?: string[];
  events?: string[];
  webhookUrl: string;
  bots?: ApiBot[];
  redirectUris?: string[]; // clientType oauth only
  roamId?: number; // clientType apiKey only
  closeModalOnSuccess: boolean;
}

export interface ApiClientSecret {
  clientId: string;
  clientSecret: string;
}

export interface StartAddingApiClientPayload {
  developerAccountId: number;
  roamId: number;
  windowKey: WindowKey;
}

export interface StartEditingApiClientPayload {
  developerAccountId: number;
  clientId: string;
  windowKey: WindowKey;
}

const slice = createSlice({
  name: "developer",
  initialState: {
    clientsByAccount: {} as Record<number, ApiClient[]>,
    clientSecret: undefined as ApiClientSecret | undefined,
    devDetails: undefined as OAuthClientDevDetails | ApiKeyClientDevDetails | undefined,
    selectedApiClientId: undefined as string | undefined,
  },
  reducers: {
    startAddingApiClient: (state, action: PayloadAction<StartAddingApiClientPayload>) => {},
    startEditingApiClient: (state, action: PayloadAction<StartEditingApiClientPayload>) => {},
    setSelectedApiClientId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedApiClientId = undefined;
    },
    clearSecretLocalStorage: (state) => {
      state.clientSecret = undefined;
    },

    requestApiClientsByAccount: (state, action: PayloadAction<number>) => {},

    createApiClient: (state, action: PayloadAction<CreateApiClientPayload>) => {},
    updateApiClient: (state, action: PayloadAction<UpdateApiClientPayload>) => {},
    requestClientSecret: (state, action: PayloadAction<string>) => {},
    requestDevDetails: (state, action: PayloadAction<string>) => {},
    archiveApiClient: (state, action: PayloadAction<string>) => {},

    setApiClientsByAccount: (state, action: PayloadAction<SetApiClientsByAccountPayload>) => {
      const { accountId, apiClients } = action.payload;
      state.clientsByAccount[accountId] = apiClients;
    },
    setApiClient: (state, action: PayloadAction<ApiClient>) => {
      const oauthClient = action.payload;
      const byAccount = state.clientsByAccount[oauthClient.developerAccountId];
      if (!byAccount) {
        state.clientsByAccount[oauthClient.developerAccountId] = [oauthClient];
      } else {
        const i = byAccount.findIndex((c) => c.clientId === oauthClient.clientId);
        if (i >= 0) {
          byAccount[i] = oauthClient;
        } else {
          byAccount.push(oauthClient);
        }
      }
    },
    setApiClientSecret: (state, action: PayloadAction<ApiClientSecret>) => {
      state.clientSecret = action.payload;
    },
    setOAuthDevDetails: (
      state,
      action: PayloadAction<OAuthClientDevDetails | ApiKeyClientDevDetails | undefined>
    ) => {
      state.devDetails = action.payload;
    },
  },
});

export const { actions: DeveloperActions, reducer: developerReducer } = slice;
const selectSlice = (state: RootState) => state.developer;

export const DeveloperSelectors = {
  selectApiClientsByAccount: defaultMemoize((accountId: number) =>
    createSelector(selectSlice, (slice) => slice.clientsByAccount[accountId])
  ),
  selectApiClient: defaultMemoize((accountId: number, clientId?: string) =>
    createSelector(selectSlice, (slice) => {
      if (!clientId) return undefined;
      return slice.clientsByAccount[accountId]?.find((c) => c.clientId === clientId);
    })
  ),
  selectClientDevDetails: defaultMemoize((clientId?: string) =>
    createSelector(
      (state: RootState) => state.developer.devDetails,
      (details) => {
        if (clientId && details && details.clientId === clientId) {
          return details;
        } else {
          return undefined;
        }
      }
    )
  ),
  selectApiClientSecret: defaultMemoize((clientId?: string) =>
    createSelector(
      (state: RootState) => state.developer.clientSecret,
      (secret) => {
        if (clientId && secret && secret.clientId === clientId) {
          return secret.clientSecret;
        } else {
          return undefined;
        }
      }
    )
  ),
  selectSelectedApiClientId: createSelector(selectSlice, (slice) => slice.selectedApiClientId),
};
