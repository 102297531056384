import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import {
  RoomInvite,
  RoomInviteResult,
  RoomInviteType,
} from "../../../../shared/Models/RoomInvite.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const adapter = createEntityAdapter<RoomInvite>();

export interface InviteGuest {
  guestEmail?: string;
  guestOccupantId?: string;
}

export interface IssueInvitePayload extends InviteGuest {
  roomId: number;
  inviteType: RoomInviteType;
}

export interface IssueInvitesPayload {
  guests: InviteGuest[];
  roomId: number;
  inviteType: RoomInviteType;
}

export interface InboundInvitePayload {
  senderAddressId: string;
  invite: RoomInvite;
  chatId: string;
  notificationAllowed: boolean;
  soundAllowed: boolean;
}

export interface GetInviteStatusPayload {
  sectionId: string;
  inviteId: string;
}

export interface ClickInviteNotificationPayload {
  invite: RoomInvite;
  decline?: boolean;
}

export interface RoomInviteResultPayload {
  inviteId: string;
  result: RoomInviteResult;
}

export interface SetRoomInvitesPayload {
  roomId: number;
  invites: RoomInvite[];
}

type SliceState = {
  currentRoomInvites: EntityState<RoomInvite>;
  currentRoomId: number | undefined;
  inboundInvites: EntityState<RoomInvite>;
};

const initialState: SliceState = {
  currentRoomInvites: adapter.getInitialState(),
  currentRoomId: undefined,
  inboundInvites: adapter.getInitialState(),
};

const slice = createSlice({
  name: "roomInvites",
  initialState,
  reducers: {
    issueRoomInvite: (state, action: PayloadAction<IssueInvitePayload>) => {},
    issueRoomInvites: (state, action: PayloadAction<IssueInvitesPayload>) => {},
    cancelRoomInvite: (state, action: PayloadAction<string>) => {},
    nudgeInvitee: (state, action: PayloadAction<string>) => {},
    acceptInvite: (state, action: PayloadAction<string>) => {},
    declineInvite: (state, action: PayloadAction<string>) => {},
    getInviteStatus: (state, action: PayloadAction<GetInviteStatusPayload>) => {},

    moveInviteToFront: (state, action: PayloadAction<string>) => {
      // This is just so it appears first when selected for the dock. Otherwise should have no impact.
      const inviteId = action.payload;
      const i = state.currentRoomInvites.ids.indexOf(inviteId);
      if (i > 0) {
        state.currentRoomInvites.ids.splice(i, 1);
        state.currentRoomInvites.ids.unshift(inviteId);
      }
    },

    serverSetRoomInvites: (state, action: PayloadAction<SetRoomInvitesPayload>) => {
      const { roomId, invites } = action.payload;
      state.currentRoomId = roomId;
      adapter.setAll(state.currentRoomInvites, invites);
    },
    serverUpsertInboundInvites: (state, action: PayloadAction<RoomInvite[]>) => {
      const invites = action.payload;
      adapter.upsertMany(state.inboundInvites, invites);
    },
    serverUpsertIfCurrentRoom: (state, action: PayloadAction<RoomInvite>) => {
      const invite = action.payload;
      if (invite.roomId === state.currentRoomId) {
        adapter.upsertOne(state.currentRoomInvites, invite);
      }
    },
    serverDeleteRoomInvite: (state, action: PayloadAction<string>) => {
      const inviteId = action.payload;
      adapter.removeOne(state.currentRoomInvites, inviteId);
      adapter.removeOne(state.inboundInvites, inviteId);
    },
    serverRoomInviteResult: (state, action: PayloadAction<RoomInviteResultPayload>) => {
      const { inviteId } = action.payload;
      adapter.removeOne(state.currentRoomInvites, inviteId);
      adapter.removeOne(state.inboundInvites, inviteId);
    },
    inboundInvite: (state, action: PayloadAction<InboundInvitePayload>) => {},
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.section.roomInvite;
const selectCurrentRoomInvites = (state: RootState) => state.section.roomInvite.currentRoomInvites;
const selectInboundInvites = (state: RootState) => state.section.roomInvite.inboundInvites;
const adapterSelectors = adapter.getSelectors();

export interface RoomAndTypeCriteria {
  roomId?: number;
  type?: RoomInviteType;
}

export const selectors = {
  selectRoomInviteIdsByRoom: defaultMemoize((roomId?: number) =>
    createSelector(selectSlice, (slice) => {
      if (slice.currentRoomId && roomId === slice.currentRoomId) {
        return adapterSelectors.selectIds(slice.currentRoomInvites);
      } else {
        return undefined;
      }
    })
  ),
  selectStageInviteIdsByRoom: defaultMemoize((roomId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      if (slice.currentRoomId && roomId === slice.currentRoomId) {
        return adapterSelectors
          .selectAll(slice.currentRoomInvites)
          .filter((x) => x.inviteType === "Stage")
          .map((x) => x.id);
      } else {
        return undefined;
      }
    })
  ),
  selectBackstageInviteIdsByRoom: defaultMemoize((roomId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      if (slice.currentRoomId && roomId === slice.currentRoomId) {
        return adapterSelectors
          .selectAll(slice.currentRoomInvites)
          .filter((x) => x.inviteType === "Backstage")
          .map((x) => x.id);
      } else {
        return undefined;
      }
    })
  ),
  selectRoomInvitesByRoom: defaultMemoize((roomId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      if (slice.currentRoomId && roomId === slice.currentRoomId) {
        return adapterSelectors.selectAll(slice.currentRoomInvites);
      } else {
        return undefined;
      }
    })
  ),
  selectStageInvitesByRoom: defaultMemoize((roomId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      if (slice.currentRoomId && roomId === slice.currentRoomId) {
        return adapterSelectors
          .selectAll(slice.currentRoomInvites)
          .filter((x) => x.inviteType === "Stage");
      } else {
        return undefined;
      }
    })
  ),
  selectBackstageInvitesByRoom: defaultMemoize((roomId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      if (slice.currentRoomId && roomId === slice.currentRoomId) {
        return adapterSelectors
          .selectAll(slice.currentRoomInvites)
          .filter((x) => x.inviteType === "Backstage");
      } else {
        return undefined;
      }
    })
  ),
  selectRoomInviteById: (inviteId: string) => (state: RootState) =>
    adapterSelectors.selectById(selectCurrentRoomInvites(state), inviteId),
  selectInboundInviteById: (inviteId?: string) => (state: RootState) =>
    inviteId ? adapterSelectors.selectById(selectInboundInvites(state), inviteId) : undefined,
  selectInboundInvite: defaultMemoize(
    ({ roomId, type }: RoomAndTypeCriteria) =>
      createSelector(selectInboundInvites, (inboundInvites) => {
        return roomId
          ? inboundInvites.ids
              .map((id) => inboundInvites.entities[id])
              .find((i) => i && i.roomId === roomId && (!type || i.inviteType === type))
          : undefined;
      }),
    { equalityCheck: equal }
  ),
  selectInboundInvites: createDeepEqualSelector(selectInboundInvites, (invites) =>
    adapterSelectors.selectAll(invites)
  ),
};
