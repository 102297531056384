import { useEffect, useState } from "react";
import { useContainer } from "../../hooks/useContainer.js";
import { StorageEvent, StorageKey } from "./IStorage.js";

/**
 * React hook to use our typed local storage service.
 */
export const useStorage = <T>(
  key: StorageKey<T>
): [T | undefined, (value: NonNullable<T>) => void] => {
  const container = useContainer();
  const [value, setValue] = useState(() => container.storage().getValue(key));
  const setValueInStorage = (value: NonNullable<T>) => {
    container.storage().setValue(key, value);
  };

  useEffect(() => {
    const listener = (event: StorageEvent<T>) => {
      setValue(event.newValue);
    };
    container.storage().addListener(key, listener);

    return () => {
      container.storage().removeListener(key, listener);
    };
  }, [container, key]);

  return [value, setValueInStorage];
};
