/* eslint-disable no-restricted-globals */

export interface ILocalStorage {
  readonly length: number;
  clear(): void;
  getItem(key: string): string | null;
  removeItem(key: string): void;
  setItem(key: string, value: string): void;
}

class ProxyLocalStorage implements ILocalStorage {
  get length(): number {
    return localStorage.length;
  }

  clear(): void {
    localStorage.clear();
  }
  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }
  removeItem(key: string): void {
    return localStorage.removeItem(key);
  }
  setItem(key: string, value: string): void {
    return localStorage.setItem(key, value);
  }
}

class InMemoryLocalStorage implements ILocalStorage {
  private data = new Map<string, string>();

  get length(): number {
    return this.data.size;
  }

  clear(): void {
    this.data.clear();
  }
  getItem(key: string): string | null {
    const val = this.data.get(key);
    if (val === undefined) {
      return null;
    }
    return val;
  }
  removeItem(key: string): void {
    this.data.delete(key);
  }
  setItem(key: string, value: string): void {
    this.data.set(key, value);
  }
}

const isLocalStorageSupported = (): boolean => {
  if (typeof localStorage === "undefined") {
    return false;
  }
  try {
    const key = "--verify-local-storage";
    localStorage.setItem(key, key);
    localStorage.removeItem(key);
  } catch {
    return false;
  }
  return true;
};

export const LocalStorage = isLocalStorageSupported() ? ProxyLocalStorage : InMemoryLocalStorage;
