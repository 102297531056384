import { ChatType } from "../../../shared/Models/Chat.js";
import { ChatAddress, ChatTarget } from "../../../shared/Models/ChatAddress.js";
import { GroupNewWithTargets } from "../../../shared/Models/Group.js";
import {
  BotTargetKey,
  ClientTargetKey,
  EmailTargetKey,
  OccupantTargetKey,
  SingleTargetKey,
} from "../../../shared/Models/TargetKey.js";
import { Anchor, AnchorType, OverrideAnchor } from "./Anchor.js";

// Represents requirements for a popup that we need to show. Anchors on the
// screen get a chance to indicate that they could show this popup, so this
// contains all of the information they need to determine that.

export type PopupType =
  | "newSingleChat"
  | "newSingleChannelChat"
  | "existingSingleChat"
  | "socialSuggestion"
  | "overworldHost"
  | "newChat"
  | "newOpenChat"
  | "groupChat"
  | "channel"
  | "channelThread"
  | "createOrEditGroup"
  | "teamRoamUser"
  | "teamRoamTeam"
  | "thisIsYou"
  | "error";

export const alwaysActivateInInbox: PopupType[] = ["groupChat", "channel"];

export const threadTimestampForPopup = (ctx: PopupContext): number | undefined => {
  if (ctx.popupType === "channel") {
    return ctx.openThread?.threadTimestamp;
  }
  if (ctx.popupType === "channelThread") {
    return ctx.threadTimestamp;
  }
  return undefined;
};

export interface PopupContextBase {
  popupType: PopupType;
  fromUserClick?: boolean;
  chatId?: string;
  pendingChatId?: string;
  chatType?: ChatType;
  displayName?: string;
  subtitleLabel?: string;
  imageUrl?: string;
  sender?: ChatAddress;
  fixedPopupId?: number;
  preferredAnchorKey?: string;

  // If this sender appears on the map, in video rooms, etc., and the popup is not maximized, a
  // preview bubble can be shown on this sender with the indicated message
  anchorOnSender?: ChatAddress;
  previewMessageThreadTimestamp?: number;
  previewMessageTimestamp?: number;

  // If the popup will show in the non-maximized state, this field will override the preview text
  // that is shown for the preview popup. Additionally, if this field is set, the preview popup will
  // show even if the anchor is on the dock.
  overridePreviewText?: string;

  // When the popup opens, it should scroll to and highlight the indicated message in the chat or
  // openThread
  focusMessageTimestamp?: number;
  focusMessageThreadTimestamp?: number;

  // The value for where the clientReadThrough line should be displayed.
  // Line persists in the popup even after the chat is marked as read.
  readThroughLine?: number;

  // whether this popup meets the criteria for initial activation at the time it is created
  startActivated?: boolean;
}

interface PopupContextEditingMessage {
  editingMessage?: {
    chatId: string;
    threadTimestamp: number | undefined;
    timestamp: number;
  };
}

type EmailUserContext = {
  targetKey: EmailTargetKey;
  email: string;
  occupantId?: string;
  roamId?: number;
  personId?: number;
};

type ClientUserContext = {
  targetKey: ClientTargetKey;
  email?: undefined;
  occupantId?: string;
  roamId?: number;
  personId?: number;
};

type OccupantUserContext = {
  targetKey: OccupantTargetKey;
  occupantId: string;
  roamId: number;
  // Because occupant targets are intended only for non-members, we don't include email/person
  // in this type.
  email?: undefined;
  personId?: undefined;
};

type BotUserContext = {
  targetKey: BotTargetKey;
  roamId: number;
  // Fields below are not valid for bots, specified for convenience.
  email?: undefined;
  occupantId?: undefined;
  personId?: undefined;
};

/**
 * Contextual information/identifiers relating to a single user target.
 *
 * NOTE:
 *  This type aims to separate out the "primary identifier" for a user from contextual info,
 *  such as personId.
 */
export type UserContext =
  | EmailUserContext
  | ClientUserContext
  | OccupantUserContext
  | BotUserContext;

/**
 * Popup context when the popup refers to a single user target.
 */
export interface PopupContextSingle extends PopupContextBase {
  displayName: string;
  targetKey: SingleTargetKey;
  targetContext: UserContext;
  inviteId?: string;
  requestId?: string;
}

export interface NewSingleChat extends PopupContextSingle {
  popupType: "newSingleChat";
  chatType: "address" | "confidential";
  pendingChatId: string;
}

// A special case of NewSingleChat when the user has clicked on a group rather than an individual
// person. This is treated differently because we know we're not dealing with a person who may
// appear on an anchor or who we might have to act on. "Channel" is sort of a legacy name but also
// refers to the style of the chat.
export interface NewSingleChannelChat extends PopupContextBase {
  popupType: "newSingleChannelChat";
  chatType: "channel";
  displayName: string;
  addressId: string; // === groupId
  roamId: number;
  pendingChatId: string;
}

export interface ExistingSingleChat extends PopupContextSingle, PopupContextEditingMessage {
  popupType: "existingSingleChat";
  chatType: "address" | "confidential";
  chatId: string;
  otherAddress: ChatAddress;
}

export interface NewChat extends PopupContextBase {
  popupType: "newChat";
  chatType: "address" | "confidential";
  // chatId (optional from base) is included if there is an existing chat that matches
  pendingChatId: string;
  selectedTargets: ChatTarget[];
  autoFocus: "chatTarget" | "chatComposer";
}

export interface NewOpenChat extends PopupContextBase {
  popupType: "newOpenChat";
  chatType: "address" | "confidential" | "channel";
  // chatId (optional from base) is included if there is an existing chat that matches
  pendingChatId: string;
  selectedTargets: ChatTarget[];
  autoFocus: "chatTarget" | "chatComposer";
}

export interface PopupContextMulti extends PopupContextBase {}

export interface ExistingGroupChat extends PopupContextMulti, PopupContextEditingMessage {
  popupType: "groupChat";
  chatType: "address" | "confidential";
  chatId: string;
  lastSenderAddressId?: string;
}

export interface Channel extends PopupContextMulti, PopupContextEditingMessage {
  popupType: "channel";
  chatType: "channel";
  chatId: string;
  newThread?: {
    pendingMessageId?: string;
  };
  openThread?: {
    threadTimestamp: number;
    readThroughLine?: number;
    disableInitialAnimation?: boolean;
  };
  lastSenderAddressId?: string;
}

export interface ChannelThread extends PopupContextMulti, PopupContextEditingMessage {
  popupType: "channelThread";
  chatType: "channel";
  chatId: string;
  threadTimestamp: number;
}

export interface SocialSuggestionPopup extends PopupContextBase {
  popupType: "socialSuggestion";
  chatType: "address";
  pendingChatId: string;
  displayName: string;
  targetKey: EmailTargetKey;
  targetContext: EmailUserContext;
}

export interface OverworldHostPopup extends PopupContextBase {
  popupType: "overworldHost";
  displayName: string;
  targetKey: EmailTargetKey;
  targetContext: EmailUserContext;
}

export interface TeamRoamUser extends PopupContextBase {
  popupType: "teamRoamUser";
  chatId: string;
}

export interface TeamRoamTeam extends PopupContextBase {
  popupType: "teamRoamTeam";
  chatId: string;
  userEmail: string;
}

export interface ThisIsYou extends PopupContextBase {
  popupType: "thisIsYou";
  displayName: string;
  targetKey: SingleTargetKey;
  targetContext: UserContext;
}

export interface PopupError extends PopupContextBase {
  popupType: "error";
  userMessage: string;
}

export interface CreateOrEditGroup extends PopupContextBase {
  popupType: "createOrEditGroup";
  payload: CreateOrEditGroupPayload;
}

export type CreateOrEditGroupPayload = CreateGroupPayload | ImportSlackGroupPayload;

export interface CreateGroupPayload {
  payloadType: "createGroup";
  roamId: number;
}

export interface ImportSlackGroupPayload {
  payloadType: "importSlackGroup";
  group: GroupNewWithTargets;
  slackChannelId: string;
}

export type PopupContext =
  | NewSingleChat
  | NewSingleChannelChat
  | ExistingSingleChat
  | NewChat
  | NewOpenChat
  | ExistingGroupChat
  | Channel
  | ChannelThread
  | CreateOrEditGroup
  | SocialSuggestionPopup
  | OverworldHostPopup
  | TeamRoamUser
  | TeamRoamTeam
  | ThisIsYou
  | PopupError;

export interface Popup {
  popupId: number;

  readyToShow: boolean;
  ctx: PopupContext;

  overrideAnchor?: OverrideAnchor;

  mainWindowAnchor: Anchor;
  mainWindowPopupIconKey?: string;
  meetingWindowAnchor: Anchor;
  meetingWindowPopupIconKey?: string;

  // Set if the user has actively engaged the popup (clicking, typing, scrolling) or opened it by
  // clicking themselves. Once they've done this, we won't move it.
  doNotReposition: boolean;
  // Incremented to indicate that the popup should visually signal itself
  signalNonce: number;
}

export interface PopupIds {
  email?: string;
  // Should be from client data.
  clientUuid?: string;
  occupantId?: string;
  chatId?: string;
  pendingChatId?: string;
  fixedPopupId?: number;
}

export type FinalPopupSpecialCase = "hostVisitEnded" | "guestVisitEnded";
export type PopupSpecialCase = FinalPopupSpecialCase | "newVisitor" | "newHost";

export interface OtherPersonDetails {
  displayName: string;
  imageUrl?: string;
  email?: string;
  occupantId?: string;
  roamId?: number;
  personId?: number;
  /**
   * `UserContext` object describing the other person.
   *
   * NOTE:
   *  Other fields in this object are currently wrong, as they cannot be derived purely from
   *  this context.
   */
  targetContext?: UserContext;
}

export const popupCanActivateOnAnchor = (popupType: PopupType, anchorType: AnchorType): boolean => {
  // Group chats and channels can preview on any anchor, but can only be activated on a dedicated
  // popup or pinned chat icon. This is to prevent confusion for multi-person chats.
  if (
    ["groupChat", "channel"].includes(popupType) &&
    !["popupIcon", "pinnedChatIcon", "recentDraftIcon"].includes(anchorType)
  ) {
    return false;
  }
  return true;
};

export type PopupFormat =
  | "floating"
  | "panel"
  | "compact"
  | "popupWindow"
  | "inbox"
  | "poppedInbox";
