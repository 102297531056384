import { AnyAction, PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { defaultMemoize } from "reselect";
import { ChatType } from "../../../../shared/Models/Chat.js";
import { ChatTarget } from "../../../../shared/Models/ChatAddress.js";
import { PreviewBlock, UnfurledLinkPreviewBlock } from "../../../../shared/Models/ChatMessage.js";
import { Item } from "../../../../shared/Models/Item.js";
import { getChatKey } from "../../../../shared/helpers/chat.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";
import { SerializableEditorState } from "../../helpers/messageContent.js";
import { targetListsAreEqual } from "../../helpers/targets.js";

export interface AddItemPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  item: Item;
}

export interface AddKnownItemsByIdPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  itemIds: string[];
}

export interface RemoveItemPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  itemId: string;
}

export interface RemoveItemsPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  itemIds: string[];
}

export interface SetPastedTextPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  text: string;
  isHtml: boolean;
  noSnippet?: boolean;
}

export interface SaveContentPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  content?: SerializableEditorState;
}

export interface TextSnippetDraft {
  id: string;
  text: string;
  language: string;
}

export interface UpsertTextSnippetPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  textSnippet: TextSnippetDraft;
}

export interface RemoveTextSnippetPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  textSnippetId: string;
}

export interface TransferDraftPayload {
  fromChatId: string;
  fromThreadTimestamp: number | undefined;
  toChatId: string;
  toThreadTimestamp: number | undefined;
}

export interface AddPendingChatPayload {
  chatId: string;
  chatType: ChatType;
  targets: ChatTarget[];
  open: boolean;
}

export interface AddPendingChatTargetPayload {
  chatId: string;
  target: ChatTarget;
}

export interface RemovePendingChatTargetPayload {
  chatId: string;
  targetIndex: number;
}

export interface ChatPopupClosedPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  inboxOpen: boolean;
}

export interface ChatOrThreadId {
  chatId: string;
  threadTimestamp: number | undefined;
}

export interface UpsertMessagePreviewPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  preview: PreviewBlock | undefined;
  originalLink: string | undefined;
}

export interface SetMessagePreviewLinkPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  link: string | undefined;
  originalLink: string | undefined;
}

export interface PendingChatInfo {
  targets: ChatTarget[];
  chatType: ChatType;
  open: boolean;
}

export interface ChatDraft {
  chatId: string;
  threadTimestamp: number | undefined;
  content?: SerializableEditorState;
  draftItems: Item[];
  textSnippets: TextSnippetDraft[];
  draftPreviews?: PreviewBlock[];

  lastEditedAt?: number;

  // This affects the sort order of chats in the list, so it should only be set once the inbox is
  // closed, to prevent visually distracting reorders.
  sortLastEditedAt?: number;

  // This flag tells the editor that it should trigger sending the chat draft.
  shouldSendImmediately?: boolean;

  // Populated for pending chats
  pendingChatInfo?: PendingChatInfo;
}

export interface DraftWarningMessage {
  warningId: string;
  type: "error" | "warning";
  message: string;
  clearAt?: number;
  actions?: Array<{
    description: string;
    action: AnyAction;
  }>;
}

export interface AddWarningMessagePayload {
  chatId: string;
  threadTimestamp: number | undefined;
  warning: DraftWarningMessage;
}

export interface ClearWarningMessagePayload {
  chatId: string;
  threadTimestamp: number | undefined;
  warningId: string;
}

export interface ReloadActiveDraftPayload {
  chatId: string;
  threadTimestamp: number | undefined;
}

export interface ApplyLinkPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  text: string;
  url: string; // if empty, remove link
}

export interface UnfurlLinkPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  nonce: number;
  url: string;
}

export interface AddUnfurlPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  unfurl: UnfurledLinkPreviewBlock;
}

export const editingMessageChatId = `EDITING-MESSAGE`;
export const pendingStoryChatId = `PENDING-STORY`;

const draftAdapter = createEntityAdapter<ChatDraft>({
  selectId: (draft) => getChatKey(draft.chatId, draft.threadTimestamp),
});

const draftSelectors = draftAdapter.getSelectors();

const initialState = {
  pastedTexts: {} as {
    [chatKey: string]: { text: string; isHtml: boolean; noSnippet?: boolean };
  },
  drafts: draftAdapter.getInitialState(),
  draftReload: {} as { [chatKey: string]: number },
  warnings: {} as { [chatKey: string]: DraftWarningMessage[] },
  appliedLinks: {} as { [chatKey: string]: { text: string; url: string } },
  messagePreviewLinks: {} as { [chatKey: string]: string[] | undefined },
};

export const draftIsEmpty = (draft: ChatDraft): boolean =>
  draftContentIsEmpty(draft) &&
  (!draft.pendingChatInfo ||
    draft.pendingChatInfo.targets.length < 2 ||
    !draft.pendingChatInfo.open);

const draftContentIsEmpty = (draft: ChatDraft): boolean =>
  draft.content === undefined && draft.draftItems.length === 0 && draft.textSnippets.length === 0;

const getDraftOrDefault = (
  state: typeof initialState,
  chatId: string,
  threadTimestamp: number | undefined
): ChatDraft => {
  const existing = state.drafts.entities[getChatKey(chatId, threadTimestamp)];
  if (existing) {
    return cloneDeep(existing);
  }
  return {
    chatId,
    threadTimestamp,
    draftItems: [],
    textSnippets: [],
  };
};

const createOrModifyDraft = (
  state: typeof initialState,
  chatId: string,
  threadTimestamp: number | undefined,
  modifier: (draft: ChatDraft) => void,
  opts?: { modifyOnly?: boolean; keepEmpty?: boolean; setSortLastEditedAt?: boolean }
): void => {
  if (
    opts?.modifyOnly &&
    state.drafts.entities[getChatKey(chatId, threadTimestamp)] === undefined
  ) {
    return;
  }

  const draft = getDraftOrDefault(state, chatId, threadTimestamp);
  modifier(draft);
  draft.lastEditedAt = Date.now();
  if (opts?.setSortLastEditedAt) {
    draft.sortLastEditedAt = draft.lastEditedAt;
  }
  draftAdapter.upsertOne(state.drafts, draft);
};

const slice = createSlice({
  name: "chatDraft",
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<AddItemPayload>) => {
      const { chatId, threadTimestamp, item: draftItem } = action.payload;
      createOrModifyDraft(state, chatId, threadTimestamp, (draft) => {
        if (!draft.draftItems.some((item) => item.id === draftItem.id)) {
          draft.draftItems.push(draftItem);
        }
      });
    },
    addKnownItemsById: (state, action: PayloadAction<AddKnownItemsByIdPayload>) => {},
    removeItem: (state, action: PayloadAction<RemoveItemPayload>) => {
      const { chatId, threadTimestamp, itemId } = action.payload;
      createOrModifyDraft(
        state,
        chatId,
        threadTimestamp,
        (draft) => {
          draft.draftItems = draft.draftItems.filter((i) => itemId !== i.id);
        },
        { modifyOnly: true }
      );
    },
    removeItems: (state, action: PayloadAction<RemoveItemsPayload>) => {
      const { chatId, threadTimestamp, itemIds } = action.payload;
      createOrModifyDraft(
        state,
        chatId,
        threadTimestamp,
        (draft) => {
          draft.draftItems = draft.draftItems.filter((i) => !itemIds.includes(i.id));
        },
        { modifyOnly: true }
      );
    },
    removeErroredItems: (state, action: PayloadAction<ChatOrThreadId>) => {},
    upsertTextSnippet: (state, action: PayloadAction<UpsertTextSnippetPayload>) => {
      const { chatId, threadTimestamp, textSnippet } = action.payload;
      createOrModifyDraft(state, chatId, threadTimestamp, (draft) => {
        const index = draft.textSnippets.findIndex((s) => s.id === textSnippet.id);
        if (index !== -1) {
          draft.textSnippets[index] = textSnippet;
        } else {
          draft.textSnippets.push(textSnippet);
        }
      });
    },
    removeTextSnippet: (state, action: PayloadAction<RemoveTextSnippetPayload>) => {
      const { chatId, threadTimestamp, textSnippetId } = action.payload;
      createOrModifyDraft(
        state,
        chatId,
        threadTimestamp,
        (draft) => {
          draft.textSnippets = draft.textSnippets.filter((s) => s.id !== textSnippetId);
        },
        { modifyOnly: true }
      );
    },
    saveContent: (state, action: PayloadAction<SaveContentPayload>) => {
      const { chatId, threadTimestamp, content } = action.payload;
      createOrModifyDraft(state, chatId, threadTimestamp, (draft) => {
        draft.content = content;
      });
    },
    transferDraft: (state, action: PayloadAction<TransferDraftPayload>) => {
      const { toChatId, toThreadTimestamp, fromChatId, fromThreadTimestamp } = action.payload;

      const fromChatKey = getChatKey(fromChatId, fromThreadTimestamp);
      const draft = draftSelectors.selectById(state.drafts, fromChatKey);
      if (draft) {
        draftAdapter.removeOne(state.drafts, fromChatKey);
        const newDraft: ChatDraft = {
          ...draft,
          chatId: toChatId,
          threadTimestamp: toThreadTimestamp,
          pendingChatInfo: undefined,
        };

        if (!draftContentIsEmpty(newDraft)) {
          draftAdapter.addOne(state.drafts, newDraft);
        }
      }
    },
    clearDraft: (state, action: PayloadAction<ChatOrThreadId>) => {
      const { chatId, threadTimestamp } = action.payload;
      draftAdapter.removeOne(state.drafts, getChatKey(chatId, threadTimestamp));
    },
    sendImmediately: (state, action: PayloadAction<ChatOrThreadId>) => {
      const { chatId, threadTimestamp } = action.payload;
      createOrModifyDraft(
        state,
        chatId,
        threadTimestamp,
        (draft) => {
          draft.shouldSendImmediately = true;
        },
        { modifyOnly: true }
      );
    },

    setPastedText: (state, action: PayloadAction<SetPastedTextPayload>) => {
      const { chatId, threadTimestamp, text, isHtml, noSnippet } = action.payload;
      const chatKey = getChatKey(chatId, threadTimestamp);
      state.pastedTexts[chatKey] = { text, isHtml, noSnippet };
    },
    pastedTextHandled: (state, action: PayloadAction<ChatOrThreadId>) => {
      const { chatId, threadTimestamp } = action.payload;
      delete state.pastedTexts[getChatKey(chatId, threadTimestamp)];
    },

    addWarningMessage: (state, action: PayloadAction<AddWarningMessagePayload>) => {
      const { chatId, threadTimestamp, warning } = action.payload;
      const chatKey = getChatKey(chatId, threadTimestamp);
      const updatedWarnings = state.warnings[chatKey] ?? [];
      const existingIdx = updatedWarnings.findIndex(
        (warning) => warning.warningId === warning.warningId
      );
      if (existingIdx >= 0) {
        updatedWarnings.splice(existingIdx, 1);
      }
      updatedWarnings.push(warning);
      state.warnings[chatKey] = updatedWarnings;
    },

    clearWarningMessage: (state, action: PayloadAction<ClearWarningMessagePayload>) => {
      const { chatId, threadTimestamp, warningId } = action.payload;
      const currentWarnings = state.warnings[getChatKey(chatId, threadTimestamp)];
      if (currentWarnings) {
        const existingIdx = currentWarnings.findIndex((warning) => warning.warningId === warningId);
        if (existingIdx >= 0) {
          currentWarnings.splice(existingIdx, 1);
        }
      }
    },

    addPendingChatTarget: (state, action: PayloadAction<AddPendingChatTargetPayload>) => {
      const { chatId, target } = action.payload;
      createOrModifyDraft(state, chatId, undefined, (draft) => {
        if (!draft.pendingChatInfo) {
          draft.pendingChatInfo = {
            targets: [],
            open: true,
            chatType: "address",
          };
        }
        draft.pendingChatInfo.targets.push(target);
        draft.pendingChatInfo.open = true;
      });
    },
    removePendingChatTarget: (state, action: PayloadAction<RemovePendingChatTargetPayload>) => {
      const { chatId, targetIndex } = action.payload;
      createOrModifyDraft(
        state,
        chatId,
        undefined,
        (draft) => {
          if (!draft.pendingChatInfo) {
            return;
          }
          if (draft.pendingChatInfo.targets.length > targetIndex) {
            draft.pendingChatInfo.targets.splice(targetIndex, 1);
          }
        },
        { modifyOnly: true }
      );
    },
    convertTextToPendingChatTargets: (state, action: PayloadAction<string /* baseId */>) => {},
    addPendingChat: (state, action: PayloadAction<AddPendingChatPayload>) => {
      const { chatId, chatType, targets, open } = action.payload;

      createOrModifyDraft(
        state,
        chatId,
        undefined,
        (draft) => {
          draft.pendingChatInfo = {
            targets,
            chatType,
            open,
          };
        },
        { setSortLastEditedAt: true }
      );
    },
    reloadActiveDraft: (state, action: PayloadAction<ReloadActiveDraftPayload>) => {
      const { chatId, threadTimestamp } = action.payload;
      const key = getChatKey(chatId, threadTimestamp);
      const v = state.draftReload[key] ?? 0;
      state.draftReload[key] = v + 1;
    },
    chatPopupClosed: (state, action: PayloadAction<ChatPopupClosedPayload>) => {
      const { chatId, threadTimestamp, inboxOpen } = action.payload;

      if (inboxOpen) {
        return;
      }
      const draft = state.drafts.entities[getChatKey(chatId, threadTimestamp)];
      if (!draft) {
        return;
      }

      if (draftIsEmpty(draft)) {
        draftAdapter.removeOne(state.drafts, getChatKey(chatId, threadTimestamp));
      }
    },
    inboxClosedOrSwitchedTabs: (state, action: PayloadAction<string | undefined>) => {
      const inboxOpenChatId = action.payload;
      for (const draft of Object.values(state.drafts.entities)) {
        if (!draft || draft.chatId === inboxOpenChatId) continue;

        if (draftIsEmpty(draft)) {
          draftAdapter.removeOne(state.drafts, getChatKey(draft.chatId, draft.threadTimestamp));
        } else if (draft.lastEditedAt) {
          draft.sortLastEditedAt = draft.lastEditedAt;
        }
      }
    },

    setAppliedLink: (state, action: PayloadAction<ApplyLinkPayload>) => {
      const { chatId, threadTimestamp, text, url } = action.payload;
      state.appliedLinks[getChatKey(chatId, threadTimestamp)] = {
        text,
        url,
      };
    },
    appliedLinkHandled: (state, action: PayloadAction<ChatOrThreadId>) => {
      const { chatId, threadTimestamp } = action.payload;
      delete state.appliedLinks[getChatKey(chatId, threadTimestamp)];
    },

    setMessagePreviewLink: (state, action: PayloadAction<SetMessagePreviewLinkPayload>) => {
      const { chatId, threadTimestamp, link, originalLink } = action.payload;
      const chatKey = getChatKey(chatId, threadTimestamp);
      let links = state.messagePreviewLinks[chatKey];

      if (link) {
        if (links === undefined) {
          links = [];
        }
        links.push(link);
        state.messagePreviewLinks[chatKey] = links;
      } else if (originalLink) {
        state.messagePreviewLinks[chatKey] = links?.filter((l) => l !== originalLink);
      }
    },

    upsertMessagePreview: (state, action: PayloadAction<UpsertMessagePreviewPayload>) => {
      const { chatId, threadTimestamp, preview, originalLink } = action.payload;
      createOrModifyDraft(state, chatId, threadTimestamp, (draft) => {
        if (preview === undefined) {
          draft.draftPreviews = [];
        } else {
          draft.draftPreviews = [preview];
        }
      });
    },
    unfurlLink: (state, action: PayloadAction<UnfurlLinkPayload>) => {},
    addUnfurl: (state, action: PayloadAction<AddUnfurlPayload>) => {
      const { chatId, threadTimestamp, unfurl } = action.payload;
      createOrModifyDraft(state, chatId, threadTimestamp, (draft) => {
        if (!draft.draftPreviews || draft.draftPreviews.length === 0) {
          draft.draftPreviews = [unfurl];
        }
      });
    },
    removeUnfurl: (state, action: PayloadAction<UnfurlLinkPayload>) => {
      const { chatId, threadTimestamp, url } = action.payload;
      createOrModifyDraft(state, chatId, threadTimestamp, (draft) => {
        draft.draftPreviews = [];
      });
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.chatDraft;
export const selectors = {
  selectPastedText: defaultMemoize(
    (chatId: string | undefined, threadTimestamp: number | undefined) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        chatId ? slice.pastedTexts[getChatKey(chatId, threadTimestamp)] : undefined
      )
  ),
  selectDraftByChatId: defaultMemoize(
    (chatId: string | undefined, threadTimestamp: number | undefined) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        chatId ? slice.drafts.entities[getChatKey(chatId, threadTimestamp)] : undefined
      )
  ),
  selectReloadableDraftByChatId: defaultMemoize(
    (chatId: string | undefined, threadTimestamp: number | undefined, draftReload: number) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        return chatId ? slice.drafts.entities[getChatKey(chatId, threadTimestamp)] : undefined;
      })
  ),
  selectWarningsByChatId: defaultMemoize(
    (chatId: string | undefined, threadTimestamp: number | undefined) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        chatId ? slice.warnings[getChatKey(chatId, threadTimestamp)] : undefined
      )
  ),
  selectChatDraftByPendingTargetList: defaultMemoize(
    (targets: ChatTarget[], confidential: boolean) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        const allDrafts = draftSelectors.selectAll(slice.drafts);
        return allDrafts.find(
          (d) =>
            d.pendingChatInfo &&
            (d.pendingChatInfo.chatType === "confidential") === confidential &&
            targetListsAreEqual(d.pendingChatInfo.targets, targets, [])
        );
      })
  ),
  selectAllDrafts: createDeepEqualSelector(selectSlice, (slice) =>
    draftSelectors
      .selectAll(slice.drafts)
      .filter((d) => d.chatId !== editingMessageChatId && d.chatId !== pendingStoryChatId)
  ),
  selectAppliedLink: (chatId: string, threadTimestamp: number | undefined) =>
    createSelector(selectSlice, (slice) =>
      chatId ? slice.appliedLinks[getChatKey(chatId, threadTimestamp)] : undefined
    ),
};

export const ChatDraftSelectors = selectors;
export const ChatDraftActions = actions;
