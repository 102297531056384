import { z } from "zod";

/**
 * Desired destination is a specific room.
 */
export const RoomDestination = z.object({
  type: z.literal("room"),
  roamId: z.number(),
  floorId: z.number(),
  roomId: z.number(),
});
export type RoomDestination = z.infer<typeof RoomDestination>;
