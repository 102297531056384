import { VideoPipelineName } from "../../../shared/Models/VideoPipeline.js";
import { logger } from "../../../shared/infra/logger.js";
import { ClientContainerInfo } from "../../injection/IClientContainer.js";
import { IClientService } from "../../injection/IClientService.js";
import { PostProcSyncObjectCache } from "../PostProcObjectCache.js";
import { MainThreadPipelineExecutor } from "./MainThreadPipelineExecutor.js";
import { VideoPipelineContainer } from "./VideoPipelineContainer.js";
import { IVideoPipelineContainer } from "./interfaces/IVideoPipelineContainer.js";
import { IVideoPipelineExecutor } from "./interfaces/IVideoPipelineExecutor.js";
import { VideoFeatureSupport } from "./interfaces/IVideoPipelineLauncher.js";
import { mediaPipePipelineVersion, setupMediaPipePipeline } from "./mediapipe/mediaPipePipeline.js";
import {
  preloadTFLiteModel,
  setupTFLitePipeline,
  tfLitePipelineVersion,
} from "./tflite/tfLitePipeline.js";
import { VideoWorkerPipelineExecutorProxy } from "./worker/VideoWorkerPipelineExecutorProxy.js";
import { VideoWorkerProxy } from "./worker/VideoWorkerProxy.js";

interface Injected {
  info(): ClientContainerInfo;
}

/*
 * VideoPipelineCache stores the pre-loaded and reusable container objects stored in the main
 * thread for running video pipelines. It supplies these to callers, paired with the correct
 * PipelineExecutor for executing them, via the createExecutorForPipeline() method.
 *
 * (This class has direct dependencies on the specific pipelines, but callers should not have to.
 * For that reason, it's also the home to some assorted functions that provide generic
 * functionality by pipelineName.)
 */
export class VideoPipelineCache implements IClientService {
  constructor(private container: Injected) {}

  public name() {
    return "VideoPipelineCache";
  }

  private videoPostProcCaches = {
    tfLitePipelineContainerCache: new PostProcSyncObjectCache<VideoPipelineContainer>(
      () => new VideoPipelineContainer(setupTFLitePipeline, false),
      0,
      2
    ),
    mediaPipePipelineContainerCache: new PostProcSyncObjectCache<VideoPipelineContainer>(
      () =>
        new VideoPipelineContainer(
          (gl, dims) => setupMediaPipePipeline(gl, dims, true, true),
          false
        ),
      0,
      2
    ),
    mediaPipeBasicPipelineContainerCache: new PostProcSyncObjectCache<VideoPipelineContainer>(
      () =>
        new VideoPipelineContainer(
          (gl, dims) => setupMediaPipePipeline(gl, dims, false, false),
          false
        ),
      0,
      2
    ),
    mediaPipeMediumPipelineContainerCache: new PostProcSyncObjectCache<VideoPipelineContainer>(
      () =>
        new VideoPipelineContainer(
          (gl, dims) => setupMediaPipePipeline(gl, dims, false, true),
          false
        ),
      0,
      2
    ),
    mediaPipeWorkerProxyCache: new PostProcSyncObjectCache<VideoWorkerProxy>(
      () => new VideoWorkerProxy("mediapipe"),
      0,
      2
    ),
    mediaPipeMediumWorkerProxyCache: new PostProcSyncObjectCache<VideoWorkerProxy>(
      () => new VideoWorkerProxy("mediapipeMedium"),
      0,
      2
    ),
    mediaPipeBasicWorkerProxyCache: new PostProcSyncObjectCache<VideoWorkerProxy>(
      () => new VideoWorkerProxy("mediapipeBasic"),
      0,
      2
    ),
  };

  public createExecutorForPipeline(
    pipelineName: VideoPipelineName,
    streamId: string,
    onPipelineError: (err: any) => void
  ): IVideoPipelineExecutor {
    switch (pipelineName) {
      case "tflite":
        return new MainThreadPipelineExecutor(
          this.container.info(),
          streamId,
          pipelineName,
          this.videoPostProcCaches.tfLitePipelineContainerCache,
          onPipelineError
        );
      case "mediapipe":
        return new VideoWorkerPipelineExecutorProxy(
          streamId,
          pipelineName,
          this.videoPostProcCaches.mediaPipeWorkerProxyCache,
          onPipelineError
        );
      case "mediapipeNoWorker":
        return new MainThreadPipelineExecutor(
          this.container.info(),
          streamId,
          pipelineName,
          this.videoPostProcCaches.mediaPipePipelineContainerCache,
          onPipelineError
        );
      case "mediapipeBasic":
        return new VideoWorkerPipelineExecutorProxy(
          streamId,
          pipelineName,
          this.videoPostProcCaches.mediaPipeBasicWorkerProxyCache,
          onPipelineError
        );
      case "mediapipeBasicNoWorker":
        return new MainThreadPipelineExecutor(
          this.container.info(),
          streamId,
          pipelineName,
          this.videoPostProcCaches.mediaPipeBasicPipelineContainerCache,
          onPipelineError
        );
      case "mediapipeMedium":
        return new VideoWorkerPipelineExecutorProxy(
          streamId,
          pipelineName,
          this.videoPostProcCaches.mediaPipeMediumWorkerProxyCache,
          onPipelineError
        );
      case "mediapipeMediumNoWorker":
        return new MainThreadPipelineExecutor(
          this.container.info(),
          streamId,
          pipelineName,
          this.videoPostProcCaches.mediaPipeMediumPipelineContainerCache,
          onPipelineError
        );
      default:
        throw new Error(`unrecognized pipeline ${pipelineName}`);
    }
  }

  public async preloadForPipeline(pipelineName: VideoPipelineName) {
    await this.preloadPipeline(pipelineName);

    // Only keep one pipeline preloaded
    for (const pipeline of VideoPipelineName.options) {
      if (pipeline !== pipelineName) {
        this.containerCacheForPipeline(pipeline)?.clean();
      }
    }
  }

  private async preloadPipeline(pipelineName: VideoPipelineName) {
    if (pipelineName === "tflite") {
      try {
        await preloadTFLiteModel();
        this.videoPostProcCaches.tfLitePipelineContainerCache.preloadMax();
      } catch (err) {
        logger.warn({ err }, `AvPipelines.preloadObjects: error preloading TFLite model`);
      }
    } else {
      const cache = this.containerCacheForPipeline(pipelineName);
      if (cache) {
        cache.preloadMax();
      } else {
        logger.error(`Error preloading pipeline ${pipelineName}: cache not found`);
      }
    }
  }

  private containerCacheForPipeline(
    pipelineName: VideoPipelineName
  ): PostProcSyncObjectCache<IVideoPipelineContainer | VideoWorkerProxy> | undefined {
    switch (pipelineName) {
      case "tflite":
        return this.videoPostProcCaches.tfLitePipelineContainerCache;
      case "mediapipe":
        return this.videoPostProcCaches.mediaPipeWorkerProxyCache;
      case "mediapipeBasic":
        return this.videoPostProcCaches.mediaPipeBasicWorkerProxyCache;
      case "mediapipeNoWorker":
        return this.videoPostProcCaches.mediaPipePipelineContainerCache;
      case "mediapipeBasicNoWorker":
        return this.videoPostProcCaches.mediaPipeBasicPipelineContainerCache;
      case "mediapipeMedium":
        return this.videoPostProcCaches.mediaPipeMediumWorkerProxyCache;
      case "mediapipeMediumNoWorker":
        return this.videoPostProcCaches.mediaPipeMediumPipelineContainerCache;
      default:
        return undefined;
    }
  }

  public currentVersionForPipeline(pipelineName: VideoPipelineName): number | undefined {
    switch (pipelineName) {
      case "tflite":
        return tfLitePipelineVersion;
      case "mediapipe":
      case "mediapipeBasic":
      case "mediapipeNoWorker":
      case "mediapipeBasicNoWorker":
      case "mediapipeMedium":
      case "mediapipeMediumNoWorker":
        return mediaPipePipelineVersion;
      default:
        return undefined;
    }
  }

  public featuresForPipeline(pipelineName?: VideoPipelineName): VideoFeatureSupport {
    switch (pipelineName) {
      case "tflite":
      case "mediapipeBasic":
      case "mediapipeBasicNoWorker":
        return { background: true, touchup: false };
      case "mediapipe":
      case "mediapipeNoWorker":
      case "mediapipeMedium":
      case "mediapipeMediumNoWorker":
        return { background: true, touchup: true };
      default:
        return { background: false, touchup: false };
    }
  }

  public pipelineRequiresWorker(pipelineName?: VideoPipelineName): boolean {
    switch (pipelineName) {
      case "mediapipe":
      case "mediapipeBasic":
      case "mediapipeMedium":
        return true;
      default:
        return false;
    }
  }

  public snapshot(): any {
    return Object.fromEntries(
      Object.entries(this.videoPostProcCaches).map(([k, v]) => [k, v.dump()])
    );
  }
}
