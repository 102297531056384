import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VideoBotAssignment, VideoBotInstruction } from "../../../../shared/Models/VideoBot.js";
import { RootState } from "../../../store/reducers.js";

export interface ActionInstructionPayload {
  roomId: number;
  occupantId: string;
  instruction: VideoBotInstruction;
}

const slice = createSlice({
  name: "videoBot",
  initialState: {
    reportedRoomId: undefined as number | undefined,
    reportedFloorId: undefined as number | undefined,
    assignment: undefined as VideoBotAssignment | undefined,
  },
  reducers: {
    resetAssignment: (state) => {
      state.assignment = undefined;
    },
    actionInstruction: (state, action: PayloadAction<ActionInstructionPayload>) => {},
    setReportedFloorId: (state, action: PayloadAction<number | undefined>) => {
      state.reportedFloorId = action.payload;
    },
    setReportedRoomId: (state, action: PayloadAction<number | undefined>) => {
      state.reportedRoomId = action.payload;
    },
    setAssignment: (state, action: PayloadAction<VideoBotAssignment | undefined>) => {
      state.assignment = action.payload;
    },
  },
});

export const { actions: VideoBotActions, reducer: videoBotReducer } = slice;

export const selectSlice = (state: RootState) => state.anyWorld.videoBot;
export const VideoBotSelectors = {
  selectReportedRoomId: (state: RootState) => selectSlice(state).reportedRoomId,
  selectReportedFloorId: (state: RootState) => selectSlice(state).reportedFloorId,
  selectAssignment: (state: RootState) => selectSlice(state).assignment,
};
