import { EventEmitter } from "events";
import { UserAgent } from "express-useragent";
import equal from "fast-deep-equal/es6/index.js";
import { logger } from "../../shared/infra/logger.js";
import { ClientContainerInfo } from "../injection/IClientContainer.js";
import { IRedux } from "../injection/redux/IRedux.js";
import { AppSelectors } from "../store/slices/appSlice.js";
import { MediaDeviceInfo } from "./avStreamShared.js";
import { IAvDevices } from "./interfaces/IAvDevices.js";

interface Injected {
  info(): ClientContainerInfo;
  redux(): IRedux;
}

/*
 * Provides callers with information about the AV devices on the system.
 *
 */
export class AvDevices extends EventEmitter implements IAvDevices {
  // The browser will initially return a list similar to this (i.e. one entry for each kind, with
  // empty labels)
  private mediaDeviceList: MediaDeviceInfo[] = [
    { kind: "videoinput", deviceId: "", groupId: "", label: "" },
    { kind: "audioinput", deviceId: "", groupId: "", label: "" },
    { kind: "audiooutput", deviceId: "", groupId: "", label: "" },
  ];

  constructor(private container: Injected) {
    super();

    // Refresh the device list:
    // - At the start
    // - Whenever Chrome tells us it's changed
    this.refreshDeviceList();
    // eslint-disable-next-line no-restricted-globals
    navigator.mediaDevices.addEventListener("devicechange", () => this.refreshDeviceList());
    // - On Linux, on a timer (sometimes devices don't appear unless we do something to force a refresh)
    //   (On macOS Sonoma this causes echo problems, so it should only be done on Linux)
    // eslint-disable-next-line no-restricted-globals
    const userAgent = new UserAgent().parse(navigator.userAgent);
    if (userAgent.isDesktop && userAgent.isLinux) {
      setInterval(() => this.refreshDeviceList(), 5_000);
    }
  }

  public refreshDeviceList() {
    // Deprioritized devices are those that we don't want to show toasts or auto-select.
    // If the user manually selects it, that is allowed.
    // The main use case for this is Continuity Camera on macOS with an iPhone, but more devices
    // may be given the same treatment in the future.
    // Right now devices are matched by label, but perhaps in the future if we patch chromium,
    // it can be a priority on MediaDeviceInfo directly.
    const deprioritizedDeviceLabels = AppSelectors.selectDeprioritizedDeviceLabels(
      this.container.redux().getState()
    );

    // eslint-disable-next-line no-restricted-globals
    navigator?.mediaDevices
      ?.enumerateDevices()
      .then((rawDevices) => {
        const devices = rawDevices.map((d) => ({
          deviceId: d.deviceId,
          groupId: d.groupId,
          kind: d.kind,
          label: d.label,
          deprioritized: deprioritizedDeviceLabels.some((label) => d.label.includes(label)),
        }));
        if (!equal(this.mediaDeviceList, devices)) {
          this.mediaDeviceList = devices;
          this.emit("devicechange");
        }
      })
      .catch((err) =>
        logger.warn({ err }, `AvPipelines.refreshDeviceList: enumerateDevices failed`)
      );
  }

  public mediaDevices() {
    return this.mediaDeviceList;
  }
}
