// Images helpers that only use features available in workers

//
const urlToImageBitmapCache = new Map<string, ImageBitmap>();
const maxCacheSize = 10;
export const urlToImageBitmap = async (url: string, allowCaching = true): Promise<ImageBitmap> => {
  if (allowCaching) {
    const cached = urlToImageBitmapCache.get(url);
    if (cached) {
      return cached;
    }
  }
  const resp = await fetch(url);
  const blob = await resp.blob();
  const imageBitmap = await createImageBitmap(blob);

  if (allowCaching) {
    if (urlToImageBitmapCache.size > maxCacheSize) {
      urlToImageBitmapCache.clear();
    }
    urlToImageBitmapCache.set(url, imageBitmap);
  }

  return imageBitmap;
};

export const blankImageBitmap = (width: number, height: number) => {
  const canvas = new OffscreenCanvas(width, height);
  const ctx = canvas.getContext("2d");
  return canvas.transferToImageBitmap();
};

export interface AnyCanvasContext
  extends CanvasCompositing,
    CanvasDrawImage,
    CanvasDrawPath,
    CanvasFillStrokeStyles,
    CanvasFilters,
    CanvasImageData,
    CanvasImageSmoothing,
    CanvasPath,
    CanvasPathDrawingStyles,
    CanvasRect,
    CanvasShadowStyles,
    CanvasState,
    CanvasText,
    CanvasTextDrawingStyles,
    CanvasTransform {}
