import _ from "lodash";
import { z } from "zod";
import { LinkType } from "./LinkType.js";

export const LinkCode = z.object({
  type: LinkType,
  code: z.string(),
});
export type LinkCode = z.infer<typeof LinkCode>;

export const LINK_CODES: LinkCode[] = [
  {
    type: "meeting",
    code: "d",
  },
  {
    type: "personal",
    code: "p",
  },
  {
    type: "oneTime",
    code: "o",
  },
  {
    type: "chat",
    code: "c",
  },
  {
    type: "screen",
    code: "s",
  },
  {
    type: "roamUProf",
    code: "u",
  },
];
export const LINK_CODES_BY_TYPE = _.keyBy(LINK_CODES, "type");
export const LINK_CODES_BY_CODE = _.keyBy(LINK_CODES, "code");
