/* eslint-disable no-restricted-globals */
import {
  applyMiddleware,
  legacy_createStore,
  Middleware,
  ReducersMapObject,
  Store,
  StoreEnhancer,
} from "redux";
import createSagaMiddleware, { Saga, SagaMonitor } from "redux-saga";
import { v4 as uuid } from "uuid";
import { logger } from "../../shared/infra/logger.js";
import { makeRootReducer, RootState } from "./reducers.js";
import { actions as AppActions } from "./slices/appSlice.js";

declare global {
  interface Window {
    store: Store<any>;
  }
}

const userTimingMiddleware: Middleware = () => (next) => (action) => {
  if (performance.mark === undefined || performance.measure === undefined) {
    return next(action);
  }

  performance.mark(`${action.type}_start`);
  const result = next(action);
  performance.mark(`${action.type}_end`);
  performance.measure(action.type as string, `${action.type}_start`, `${action.type}_end`);

  return result;
};

export interface RoamStoreInit {
  container: any;
  additionalReducers?: ReducersMapObject;
  saga?: Saga;
  sagaMonitor?: SagaMonitor;
  preloadedState?: RootState;
  enhancerComposer?: (...funcs: ReadonlyArray<StoreEnhancer<any>>) => StoreEnhancer<any>;
  addUserTimingMiddleware?: boolean;
  additionalMiddlewares?: Middleware[];
}

export const createClientStore = <S>({
  container,
  saga,
  sagaMonitor,
  additionalReducers,
  preloadedState,
  enhancerComposer,
  addUserTimingMiddleware,
  additionalMiddlewares,
}: RoamStoreInit): Store<S> => {
  const sagaMiddleware = createSagaMiddleware({
    context: { container },
    sagaMonitor,
  });
  const reducer = makeRootReducer(additionalReducers ?? {});
  const middlewares: Middleware[] = additionalMiddlewares ?? [];
  middlewares.push(sagaMiddleware);
  if (addUserTimingMiddleware) {
    middlewares.push(userTimingMiddleware);
  }
  let enhancer: StoreEnhancer;
  if (enhancerComposer) {
    enhancer = enhancerComposer(applyMiddleware(...middlewares));
  } else {
    enhancer = applyMiddleware(...middlewares);
  }
  const store = legacy_createStore(reducer, preloadedState, enhancer);
  if (saga) {
    sagaMiddleware
      .run(saga)
      .toPromise()
      .catch((err) => {
        const globalErrorId = uuid();
        logger.fatal(
          { err },
          `Global App Error: errorId ${globalErrorId}, message: ${err.message}`
        );
        store.dispatch(
          AppActions.setGlobalError({
            globalErrorId,
            message: err.message,
            sagaStack: err.stack,
          })
        );
      });
  }

  // Allow the store to be accessed from console devtools for debugging (not used by our code).
  if (typeof window !== "undefined") {
    window.store = store;
  }

  return store as Store<S>;
};
