import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { MagicMinutesAgreementStatus } from "../../../../shared/Models/SavedMeeting.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface SetMagicMinutesAgreementPayload {
  roamId: number;
  status: MagicMinutesAgreementStatus;
}

export interface UpdateMagicMinutesAgreementPayload {
  roamId: number;
  agree: boolean;
}

const slice = createSlice({
  name: "magicMinutes",
  initialState: {
    magicMinutesAgreementAccepted: {} as { [roamId: number]: MagicMinutesAgreementStatus },
  },
  reducers: {
    fetchMagicMinutesAgreement: (state, action: PayloadAction<{ roamId: number }>) => {},
    setMagicMinutesAgreement: (state, action: PayloadAction<SetMagicMinutesAgreementPayload>) => {
      const { roamId, status } = action.payload;
      state.magicMinutesAgreementAccepted[roamId] = status;
    },
    updateMagicMinutesAgreement: (
      state,
      action: PayloadAction<UpdateMagicMinutesAgreementPayload>
    ) => {},
  },
});

export const { actions, reducer } = slice;
export const MagicMinutesActions = actions;

const selectSlice = (state: RootState) => state.anyWorld.magicMinutes;
export const selectors = {
  selectMagicMinutesAgreementAccepted: defaultMemoize((roamId: number | undefined) =>
    createSelector(selectSlice, (slice) =>
      roamId === undefined ? undefined : slice.magicMinutesAgreementAccepted[roamId]
    )
  ),
};
export const MagicMinutesSelectors = selectors;
