import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatAddress } from "../../../../shared/Models/ChatAddress.js";
import { ChatMessage } from "../../../../shared/Models/ChatMessage.js";
import { Occupant } from "../../../../shared/Models/Occupant.js";
import { RootState } from "../../../store/reducers.js";

interface MessageReceivedPayload {
  senderAddress?: ChatAddress;
  occupant: Occupant;
  message: ChatMessage;
}

export interface ActivateChatPayload {
  chatId: string;
  threadTimestamp?: number;
}

const slice = createSlice({
  name: "mapMessage",
  initialState: {
    recentMessages: {} as Record<string, ChatMessage>,
  },
  reducers: {
    messageReceived: (state, action: PayloadAction<MessageReceivedPayload>) => {
      const { occupant, message } = action.payload;
      // Edited messages should only be set if they replace an older version.
      if (message.version && message.version > 1) {
        const existing = state.recentMessages[occupant.id];
        if (
          !existing ||
          existing.messageId !== message.messageId ||
          (existing.version && existing.version >= message.version)
        ) {
          return;
        }
      }
      state.recentMessages[occupant.id] = message;
    },
    activateChat: (state, action: PayloadAction<ActivateChatPayload>) => {},
    dismiss: (state, action: PayloadAction<string>) => {
      const addressId = action.payload;
      delete state.recentMessages[addressId];
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.mapMessage;

export const selectors = {
  selectRecentMessage: (occupantId?: string) => (state: RootState) =>
    occupantId ? selectSlice(state).recentMessages[occupantId] : undefined,
};

export const MapMessageSelectors = selectors;
export const MapMessageActions = actions;
