import { z } from "zod";

export const VideoPipelineName = z.enum([
  "tflite",
  "mediapipe",
  "mediapipeNoWorker",
  "mediapipeMedium",
  "mediapipeMediumNoWorker",
  "mediapipeBasic",
  "mediapipeBasicNoWorker",
]);
export type VideoPipelineName = z.infer<typeof VideoPipelineName>;

export type VideoPipelineAutoSelection = VideoPipelineName | "selecting";

export const VideoPipelineOverride = z.enum([...VideoPipelineName.options, "disabled"]);
export type VideoPipelineOverride = z.infer<typeof VideoPipelineOverride>;

export const VideoPipelineStats = z.object({
  streamId: z.string(),
  timestamp: z.number(),
  totalFrames: z.number(),
  totalTime: z.number(),
  segmentationFrames: z.number(),
  segmentationTime: z.number(),
  faceFeaturesFrames: z.number(),
  faceFeaturesTime: z.number(),
  webglContextLosses: z.number(),
  pipelineName: VideoPipelineName.optional(),
});
export type VideoPipelineStats = z.infer<typeof VideoPipelineStats>;

export const BenchmarkResultFailure = z.object({
  status: z.literal("failure"),
  error: z.string(),
  pipelineName: VideoPipelineName,
  pipelineVersion: z.number(),
});
export type BenchmarkResultFailure = z.infer<typeof BenchmarkResultFailure>;

export const BenchmarkResultSuccess = z.object({
  status: z.literal("success"),
  pipelineName: VideoPipelineName,
  rawStats: VideoPipelineStats.array(),
  loadTimeMs: z.number().optional(),
  timestamp: z.number(),
  supported: z.boolean(),
  timeTakenMs: z.number().optional(),
  pipelineVersion: z.number(),
});
export type BenchmarkResultSuccess = z.infer<typeof BenchmarkResultSuccess>;

export const BenchmarkResult = z.discriminatedUnion("status", [
  BenchmarkResultSuccess,
  BenchmarkResultFailure,
]);
export type BenchmarkResult = z.infer<typeof BenchmarkResult>;

export const PipelineSupport = z.object({
  pipelineVersion: z.number(),
  supported: z.boolean(),
  timestamp: z.number(),
  clientInfo: z.string().optional(),
});
export type PipelineSupport = z.infer<typeof PipelineSupport>;

export const PipelineClientState = z.object({
  supportByPipeline: z.record(VideoPipelineName, PipelineSupport),
  pipelineOverride: VideoPipelineOverride.optional(),
});
export type PipelineClientState = z.infer<typeof PipelineClientState>;
