import { logger } from "../infra/logger.js";

// Returns the value of paraName inside params if there is only one, otherwise undefined
export const getOnlyQueryParam = (
  params: URLSearchParams,
  paramName: string
): string | undefined => {
  const versionParam = params.get(paramName);
  logger.info(`versionParam: ${versionParam}`);
  if (!versionParam) {
    return;
  }
  if (typeof versionParam === "string") {
    return versionParam;
  }
  return;
};
