import { composeWithDevTools } from "@redux-devtools/extension";
import { AvDevices } from "../../client/avStream/AvDevices.js";
import { AvPipelines } from "../../client/avStream/AvPipelines.js";
import { FakeStreams } from "../../client/avStream/FakeStreams.js";
import { RawAudioStreamManager } from "../../client/avStream/audio/RawAudioStreamManager.js";
import { PipelineBenchmarkService } from "../../client/avStream/benchmark/PipelineBenchmarkService.js";
import { IPipelineBenchmarkService } from "../../client/avStream/benchmark/interfaces/IPipelineBenchmarkService.js";
import { IAvDevices } from "../../client/avStream/interfaces/IAvDevices.js";
import { IAvPipelines } from "../../client/avStream/interfaces/IAvPipelines.js";
import { IFakeStreams } from "../../client/avStream/interfaces/IFakeStreams.js";
import {
  IRawAudioStreamManager,
  IRawVideoStreamManager,
} from "../../client/avStream/interfaces/IRawStreamManager.js";
import { RawVideoStreamManager } from "../../client/avStream/video/RawVideoStreamManager.js";
import { VideoPipelineCache } from "../../client/avStream/video/VideoPipelineCache.js";
import { IVideoPipelineCache } from "../../client/avStream/video/interfaces/IVideoPipelineCache.js";
import { ClientContainerInfo } from "../../client/injection/IClientContainer.js";
import { IRedux } from "../../client/injection/redux/IRedux.js";
import { Redux } from "../../client/injection/redux/Redux.js";
import { IStorage } from "../../client/injection/storage/IStorage.js";
import { Storage } from "../../client/injection/storage/Storage.js";
import { BaseReduxStore } from "../../client/store/reducers.js";
import { IStats } from "../../shared/infra/stats/IStats.js";
import { BaseContainer } from "../../shared/injection/BaseContainer.js";
import { ClientStatsLocal } from "./ClientStatsLocal.js";
import { IPipelinesContainer } from "./IPipelinesContainer.js";

export class PipelinesContainer extends BaseContainer implements IPipelinesContainer {
  constructor(private clientInfo: ClientContainerInfo) {
    super();
    this.registerAsyncInstanceCreator<IRedux>("redux", async () => {
      return new Redux({
        container: this,
        enhancerComposer: composeWithDevTools,
      });
    });
  }
  avDevices(): IAvDevices {
    return this.getInstance<IAvDevices>(AvDevices);
  }
  avPipelines(): IAvPipelines {
    return this.getInstance<IAvPipelines>(AvPipelines);
  }
  fakeStreams(): IFakeStreams {
    return this.getInstance<IFakeStreams>(FakeStreams);
  }
  info(): ClientContainerInfo {
    return this.clientInfo;
  }
  pipelineBenchmarkService(): IPipelineBenchmarkService {
    return this.getInstance<IPipelineBenchmarkService>(PipelineBenchmarkService);
  }
  rawAudioStreamManager(): IRawAudioStreamManager {
    return this.getInstance<IRawAudioStreamManager>(RawAudioStreamManager);
  }
  rawVideoStreamManager(): IRawVideoStreamManager {
    return this.getInstance<IRawVideoStreamManager>(RawVideoStreamManager);
  }
  redux(): IRedux {
    return this.getAsyncInstance<IRedux>("redux");
  }
  stats(): IStats {
    return this.getInstance<IStats>(ClientStatsLocal);
  }
  storage(): IStorage {
    return this.getInstance<IStorage>(Storage);
  }
  store(): BaseReduxStore {
    return this.redux().getStore();
  }
  videoPipelineCache(): IVideoPipelineCache {
    return this.getInstance<IVideoPipelineCache>(VideoPipelineCache);
  }
}
