import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { defaultMemoize } from "reselect";
import { ClientEnterRoamRequest } from "../../../../../shared/Models/lobby/EnterRoamRequest.js";
import {
  crpc_lobby_ltc_ApproveRequest,
  crpc_lobby_ltc_DenyRequest,
} from "../../../../../shared/WorldMessages/lobby/external/ClientRpcLobbyTrafficControllerExternal.js";
import { createDeepEqualSelector, createSelector } from "../../../../helpers/redux.js";
import { RootState } from "../../../../store/reducers.js";
import { Notification } from "../../../../store/slices/appSlice.js";

/**
 * For tracking the state of guests enter your roam via Lobby ATC
 */

export interface EnterRoamRequestNotification {
  request: ClientEnterRoamRequest;
  notification: Notification;
}

const adapter = createEntityAdapter<ClientEnterRoamRequest>();
const slice = createSlice({
  name: "enterRoamRequest",
  initialState: {
    enterRoamRequests: adapter.getInitialState(),
    notificationTracker: {} as Record<string, number>,
  },
  reducers: {
    /**
     * Approves a request to allow a guest into the caller's roam.
     */
    approveRequests: (
      _state,
      _action: PayloadAction<Array<Omit<crpc_lobby_ltc_ApproveRequest, "kind">>>
    ) => {},
    /**
     * Denies a request to allow a guest into the caller's roam.
     */
    denyRequests: (
      _state,
      _action: PayloadAction<Array<Omit<crpc_lobby_ltc_DenyRequest, "kind">>>
    ) => {},

    removeEnterRoamRequests: (state, action: PayloadAction<string[]>) => {
      const requestIds = action.payload;
      adapter.removeMany(state.enterRoamRequests, requestIds);
    },
    requestEnterRoamRequest: (_state, _action: PayloadAction<string>) => {},
    setEnterRoamRequests: (state, action: PayloadAction<ClientEnterRoamRequest[]>) => {
      const requests = action.payload;
      adapter.setMany(state.enterRoamRequests, requests);
    },
    setAllEnterRoamRequests: (state, action: PayloadAction<ClientEnterRoamRequest[]>) => {
      const requests = action.payload;
      adapter.setAll(state.enterRoamRequests, requests);
    },
    deleteEnterRoamRequest: (state, action: PayloadAction<string>) => {
      const requestId = action.payload;
      adapter.removeOne(state.enterRoamRequests, requestId);
    },
    maybeNotify: (_state, _action: PayloadAction<EnterRoamRequestNotification>) => {},

    setLastNotificationShow: (state, action: PayloadAction<ClientEnterRoamRequest>) => {
      state.notificationTracker[action.payload.id] = Date.now();
    },
  },
});

export const { actions, reducer } = slice;
export const EnterRoamRequestActions = actions;
export const EnterRoamRequestReducer = reducer;

const selectSlice = (state: RootState) => state.world.lobby.enterRoamRequest;

const enterRoamRequestSelectors = adapter.getSelectors();

/** @internal */
export const selectors = {
  selectEnterRoamRequestById: defaultMemoize((id: string) =>
    createSelector(selectSlice, (slice): ClientEnterRoamRequest | undefined => {
      return enterRoamRequestSelectors.selectById(slice.enterRoamRequests, id);
    })
  ),
  selectEnterRoamRequestsForMyPersonalLink: createDeepEqualSelector(
    selectSlice,
    (slice): ClientEnterRoamRequest[] => {
      return enterRoamRequestSelectors
        .selectAll(slice.enterRoamRequests)
        .filter((g) => g.destination?.type === "person");
    }
  ),
  selectEnterRoamRequestsForMyMeetingLinks: createDeepEqualSelector(
    selectSlice,
    (slice): ClientEnterRoamRequest[] => {
      return enterRoamRequestSelectors
        .selectAll(slice.enterRoamRequests)
        .filter((g) => g.destination?.type === "meeting");
    }
  ),
  selectEnterRoamRequestsForMeetingLinkId: defaultMemoize((meetingLinkId: string) =>
    createDeepEqualSelector(selectSlice, (slice): ClientEnterRoamRequest[] => {
      return enterRoamRequestSelectors
        .selectAll(slice.enterRoamRequests)
        .filter(
          (g) => g.destination?.type === "meeting" && meetingLinkId === g.destination.meetingLinkId
        );
    })
  ),
  selectLastNotificationShow: (request: ClientEnterRoamRequest) => (state: RootState) => {
    return selectSlice(state).notificationTracker[request.id];
  },
};
/** @internal */
export const EnterRoamRequestSelectors = selectors;
