import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import {
  AudienceRequest,
  AudienceRequestAnswer,
} from "../../../../shared/Models/AudienceRequest.js";
import { Location } from "../../../../shared/Models/Location.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const audienceRequestAdapter = createEntityAdapter<AudienceRequest>({
  selectId: (audienceRequest) => audienceRequest.id,
});

export interface SetRequestsPayload {
  stageRoomId: number;
  requests: AudienceRequest[];
}

export interface MakeRequestPayload {
  roomId: number;
  goBackstage?: boolean;
}

export interface AnswerRequestPayload {
  stageRoomId: number;
  requestId: string;
  answer: AudienceRequestAnswer;
  newLocation?: Location;
}

export interface RequestAnsweredPayload {
  stageRoomId: number;
  requestId: string;
  answer: AudienceRequestAnswer;
  newLocation?: Location;
  occupantId: string;
}

const slice = createSlice({
  name: "audienceRequests",
  initialState: {
    requests: audienceRequestAdapter.getInitialState(),
    currentRoomId: undefined as number | undefined,
  },
  reducers: {
    makeRequest: (state, action: PayloadAction<MakeRequestPayload>) => {},
    cancelRequest: (state, action: PayloadAction<string>) => {},
    answerRequest: (state, action: PayloadAction<AnswerRequestPayload>) => {},

    setRequests: (state, action: PayloadAction<SetRequestsPayload>) => {
      const { stageRoomId, requests } = action.payload;
      state.currentRoomId = stageRoomId;
      audienceRequestAdapter.setAll(state.requests, requests);
    },
    upsertRequest: (state, action: PayloadAction<AudienceRequest>) => {
      const request = action.payload;
      if (state.currentRoomId === request.stageRoomId) {
        audienceRequestAdapter.upsertOne(state.requests, request);
      } else {
        state.currentRoomId = request.stageRoomId;
        audienceRequestAdapter.setAll(state.requests, [request]);
      }
    },
    requestAnswered: (state, action: PayloadAction<RequestAnsweredPayload>) => {},
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.section.audienceRequests;
const adapterSelectors = audienceRequestAdapter.getSelectors();
export const selectors = {
  selectRequestIdsByStageRoom: defaultMemoize((roomId: number) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      slice.currentRoomId === roomId ? adapterSelectors.selectIds(slice.requests) : undefined
    )
  ),
  selectRequestByOccupant: defaultMemoize((occupantId?: string) =>
    createSelector(selectSlice, (slice) =>
      adapterSelectors
        .selectAll(slice.requests)
        .find((request) => request.occupantId === occupantId)
    )
  ),
  selectRequestById: (id: string) => (state: RootState) =>
    adapterSelectors.selectById(selectSlice(state).requests, id),
};
