/* cspell:ignore Unlocker */
// A simple "async lock" that uses queueing.
//
// Calls to lock() will resolve strictly in the order that they were made.
export class AsyncLock {
  private locked = false;
  private resolvers: Array<() => void> = [];

  public async lock(): Promise<Unlocker> {
    if (!this.locked) {
      // No one has the lock, we can acquire it immediately.
      this.locked = true;
      return new Unlocker(() => this.unlock());
    }

    // Wait our turn to acquire the lock
    await new Promise((resolve) => this.resolvers.push(() => resolve(undefined)));

    // Return an object that allows the caller to safely unlock
    return new Unlocker(() => this.unlock());
  }

  public async guard<T>(fn: () => Promise<T>): Promise<T> {
    const unlocker = await this.lock();
    try {
      return await fn();
    } finally {
      unlocker.unlock();
    }
  }

  private unlock(): void {
    if (!this.locked) {
      throw new Error("lock isn't acquired, can't unlock");
    }
    const resolve = this.resolvers.shift();
    if (resolve) {
      // There was another caller waiting for the lock, unblock them
      // but don't set locked to false.
      resolve();
    } else {
      // No one was waiting for the lock, just unlock now.
      this.locked = false;
    }
  }
}

class Unlocker {
  private unlocked = false;

  constructor(private onUnlock: () => void) {}

  public unlock(): void {
    if (this.unlocked) {
      throw new Error("AsyncLock double unlock");
    }
    this.unlocked = true;
    this.onUnlock();
  }
}
