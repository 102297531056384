import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

interface SeatPrompt {
  category: "seat";
  roomId: number;
  position: number;
}

interface OccupantPrompt {
  category: "occupant";
  occupantId: string;
}

interface ExitButtonPrompt {
  category: "exit-button";
}

interface LeaveRoomButtonPrompt {
  category: "leave-room-button";
}

interface OpenVideoSettingsPrompt {
  category: "video-settings-dropdown";
}

interface RoamUMessage {
  headline: string;
  subHeadline: string;
  topLeft: string;
  middleRight: string;
  bottomLeft: string;
}

type Prompt =
  | SeatPrompt
  | ExitButtonPrompt
  | LeaveRoomButtonPrompt
  | OpenVideoSettingsPrompt
  | OccupantPrompt;

const getPromptKey = (prompt: Prompt) => JSON.stringify(prompt);
const getPrompt = (promptKey: string) => {
  try {
    return JSON.parse(promptKey);
  } catch {
    return undefined;
  }
};

const slice = createSlice({
  name: "glimmer",
  initialState: {
    glimmeringObjects: {} as {
      [promptKey: string]: boolean;
    },
    autoboardingMessage: {
      headline: "",
      subHeadline: "",
      topLeft: "",
      middleRight: "",
      bottomLeft: "",
    },
  },
  reducers: {
    startGlimmer: (state, action: PayloadAction<Prompt>) => {
      const prompt = action.payload;
      const promptKey = getPromptKey(prompt);

      // Make the prompt glimmer.
      state.glimmeringObjects[promptKey] = true;
    },
    completeGlimmer: (state, action: PayloadAction<Prompt | undefined>) => {
      if (!action.payload) {
        // If a specific prompt is not specified, clear all the glimmers.
        state.glimmeringObjects = {};
        return;
      }
      const promptKey = getPromptKey(action.payload);

      // Un-glimmer the object.
      state.glimmeringObjects[promptKey] = false;
    },

    setAutoboardingMessage: (state, action: PayloadAction<RoamUMessage>) => {
      state.autoboardingMessage = action.payload;
    },
    clearAutoboardingMessage: (state, action: PayloadAction) => {
      state.autoboardingMessage = {
        headline: "",
        subHeadline: "",
        topLeft: "",
        middleRight: "",
        bottomLeft: "",
      };
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.autoboarding.glimmer;
export const selectors = {
  selectIsGlimmering: defaultMemoize(
    (prompt: Prompt | undefined) =>
      createSelector(selectSlice, (slice) =>
        !prompt ? false : slice.glimmeringObjects[getPromptKey(prompt)]
      ),
    { equalityCheck: equal, maxSize: 100 }
  ),
  selectAutoboardingMessage: createSelector(selectSlice, (slice) => slice.autoboardingMessage),
};
