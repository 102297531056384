import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { IpBlock, OccupantIp, PhysicalOffice } from "../../../../shared/Models/PhysicalOffice.js";
import { createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

interface WindowKeyPayload {
  windowKey: WindowKey;
}

export interface RequestAccountOfficesPayload extends WindowKeyPayload {
  accountId: number;
}

export interface RequestOfficeDetailPayload extends WindowKeyPayload {
  officeId: string;
}

export interface DeleteOfficePayload extends WindowKeyPayload {
  accountId: number;
  officeId: string;
}

export interface AddUpdateOfficePayload extends WindowKeyPayload {
  office: PhysicalOffice;
}

export interface AddMyIPPayload extends WindowKeyPayload {
  officeId: string;
  accountId: number;
}

export interface AddOccupantsIpPayload extends WindowKeyPayload {
  accountId: number;
  occupantId: string;
  officeId: string;
}

export interface AddIPBlockPayload extends WindowKeyPayload {
  accountId: number;
  ipBlock: IpBlock;
}

export interface DeleteIPBlockPayload extends WindowKeyPayload {
  accountId: number;
  ipBlock: IpBlock;
}

export interface AccountOfficePayload {
  accountId: number;
  offices: PhysicalOffice[];
}

export interface OfficeLessOccupantsPayload {
  accountId: number;
  occupants: OccupantIp[];
}

export interface OfficeRemovedPayload {
  officeId: string;
  accountId: number;
}

export interface OfficeIpBlocksPayload {
  officeId: string;
  ipBlocks: IpBlock[];
}

// Physical offices
const slice = createSlice({
  name: "office",
  initialState: {
    officesByAccount: {} as { [accountId: number]: PhysicalOffice[] },
    ipBlocksByOffice: {} as { [officeId: string]: string[] },
    officeLessOccupants: {} as { [accountId: number]: OccupantIp[] },
  },
  reducers: {
    // Client-initiated RPC requests
    requestAccountOffices: (state, action: PayloadAction<RequestAccountOfficesPayload>) => {},
    requestCurrentAccountOffices: (state) => {},
    requestOfficeDetail: (state, action: PayloadAction<RequestOfficeDetailPayload>) => {},
    requestOfficeLessOccupants: (state, action: PayloadAction<RequestAccountOfficesPayload>) => {},
    addUpdateOffice: (state, action: PayloadAction<AddUpdateOfficePayload>) => {},
    deleteOffice: (state, action: PayloadAction<DeleteOfficePayload>) => {},

    addIpBlock: (state, action: PayloadAction<AddIPBlockPayload>) => {},
    deleteIpBlock: (state, action: PayloadAction<DeleteIPBlockPayload>) => {},
    addMyIPBlock: (state, action: PayloadAction<AddMyIPPayload>) => {},
    addOccupantsIp: (state, action: PayloadAction<AddOccupantsIpPayload>) => {},

    // Insert data from server
    setAccountOffices: (state, action: PayloadAction<AccountOfficePayload>) => {
      const { accountId, offices } = action.payload;
      state.officesByAccount[accountId] = offices;
    },
    setOffice: (state, action: PayloadAction<PhysicalOffice>) => {
      const office = action.payload;
      const { accountId, id } = office;
      const accountOffices = state.officesByAccount[accountId];
      if (accountOffices) {
        const i = accountOffices.findIndex((o) => o.id === id);
        if (i >= 0) {
          accountOffices[i] = office;
        } else {
          accountOffices.push(office);
        }
      } else {
        state.officesByAccount[accountId] = [office];
      }
    },
    setOfficeRemoved: (state, action: PayloadAction<OfficeRemovedPayload>) => {
      const { accountId, officeId } = action.payload;
      const offices = state.officesByAccount[accountId];
      if (offices) {
        const i = offices.findIndex((o) => o.id === officeId);
        if (i >= 0) {
          offices.splice(i, 1);
        }
      }
    },
    setOfficeIpBlocks: (state, action: PayloadAction<OfficeIpBlocksPayload>) => {
      const { officeId, ipBlocks } = action.payload;
      state.ipBlocksByOffice[officeId] = ipBlocks.map((b) => b.cidr);
    },
    setOfficeLessOccupants: (state, action: PayloadAction<OfficeLessOccupantsPayload>) => {
      const { accountId, occupants } = action.payload;
      state.officeLessOccupants[accountId] = occupants;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.anyWorld.office;
export const selectors = {
  selectAccountOffices: (accountId: number) => (state: RootState) =>
    selectSlice(state).officesByAccount[accountId],
  selectOffice: defaultMemoize(
    (accountId?: number, officeId?: string) =>
      createSelector(selectSlice, (slice) => {
        if (!accountId || !officeId) return undefined;
        const offices = slice.officesByAccount[accountId];
        if (offices) {
          return offices.find((o: PhysicalOffice) => o.id === officeId);
        } else {
          return undefined;
        }
      }),
    { maxSize: 100 }
  ),
  selectIpBlocksByOffice: (officeId: string) => (state: RootState) =>
    selectSlice(state).ipBlocksByOffice[officeId],
  selectOfficeLessOccupants: (accountId: number) => (state: RootState) =>
    selectSlice(state).officeLessOccupants[accountId],
};
