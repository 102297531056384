/* cspell:ignore Unlocker */

export class AsyncRWLock {
  private writeLocked = false;
  private writeResolvers: Array<() => void> = [];
  private readLockCount = 0;
  private readResolvers: Array<() => void> = [];

  public async writeLock(): Promise<Unlocker> {
    if (!this.writeLocked && this.readLockCount === 0) {
      // No one has the lock, we can acquire it immediately.
      this.writeLocked = true;
      return new Unlocker(() => this.writeUnlock());
    }
    // Wait our turn to acquire the lock
    await new Promise((resolve) => this.writeResolvers.push(() => resolve(undefined)));
    if (!this.writeLocked) {
      throw new Error("lock wasn't write-locked when writer was woken up");
    }
    // Return an object that allows the caller to safely unlock
    return new Unlocker(() => this.writeUnlock());
  }

  private writeUnlock(): void {
    if (!this.writeLocked) {
      throw new Error("lock isn't write-locked, can't unlock");
    } else if (this.readLockCount !== 0) {
      throw new Error("lock is both read- and write-locked");
    }

    const resolve = this.writeResolvers.shift();
    if (resolve) {
      // Another writer was waiting for the lock; unblock them and keep as write-locked
      resolve();
    } else {
      // Completely unlock
      this.writeLocked = false;

      // Wake up all readers, and increment the read count for each of them
      while (true) {
        const resolve = this.readResolvers.shift();
        if (!resolve) break;
        this.readLockCount++;
        resolve();
      }
    }
  }

  public async writeGuard<T>(fn: () => Promise<T>): Promise<T> {
    const unlocker = await this.writeLock();
    try {
      return await fn();
    } finally {
      unlocker.unlock();
    }
  }

  public async readLock(): Promise<Unlocker> {
    if (!this.writeLocked && this.writeResolvers.length === 0) {
      // The lock is not write-locked and nobody is waiting to write-lock it
      // We can acquire it immediately
      this.readLockCount++;
      return new Unlocker(() => this.readUnlock());
    }
    // Wait our turn to acquire the lock
    await new Promise((resolve) => this.readResolvers.push(() => resolve(undefined)));
    if (this.readLockCount === 0) {
      throw new Error("lock wasn't read-locked when reader was woken up");
    }
    // Return an object that allows the caller to safely unlock
    return new Unlocker(() => this.readUnlock());
  }

  private readUnlock(): void {
    if (this.readLockCount === 0) {
      throw new Error("lock isn't read-locked, can't unlock");
    }

    this.readLockCount--;
    if (this.writeResolvers.length) {
      // Writers are waiting for the lock; release the read-lock
      if (this.readLockCount === 0) {
        // All readers have now released the lock
        // Wake up one of the writers
        const resolve = this.writeResolvers.shift();
        if (!resolve) {
          throw new Error("writeResolvers.length > 0 but writeResolvers.shift returned undefined");
        }
        this.writeLocked = true;
        resolve();
      }
    } else {
      if (this.readResolvers.length) {
        throw new Error(
          "this.writeResolvers.length = 0 and readResolvers.length > 0 when read-unlocking"
        );
      }
    }
  }

  public async readGuard<T>(fn: () => Promise<T>): Promise<T> {
    const unlocker = await this.readLock();
    try {
      return await fn();
    } finally {
      unlocker.unlock();
    }
  }
}

class Unlocker {
  private unlocked = false;

  constructor(private onUnlock: () => void) {}

  public unlock(): void {
    if (this.unlocked) {
      throw new Error("AsyncRWLock double unlock");
    }
    this.unlocked = true;
    this.onUnlock();
  }
}
