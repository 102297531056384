import { createContext, useContext } from "react";
import { IClientContainer } from "../injection/IClientContainer.js";
import { IAvInjectionModule } from "../injection/av/IAvInjectionModule.js";
import { IMessagingInjectionModule } from "../injection/messaging/IMessagingInjectionModule.js";

export const ContainerContext = createContext<IClientContainer | undefined>(undefined);

export const useContainer = <
  T = IClientContainer & IAvInjectionModule & IMessagingInjectionModule,
>(): T => {
  const container = useContext(ContainerContext);
  if (!container) {
    throw new Error("ContainerContext is not set in this component hierarchy.");
  }

  return container as T;
};
