/* cspell:ignore superroam */
import { Md5 } from "ts-md5";
import { baseDingDongURL } from "../../shared/api/http.js";
import { OccupantType } from "../Models/Occupant.js";
import { logger } from "../infra/logger.js";
import { requireValue } from "./types/require.js";

const GUEST_IMAGE_FILENAMES: string[] = [
  "20231030_guest_avatars/guest-01.png",
  "20231030_guest_avatars/guest-02.png",
  "20231030_guest_avatars/guest-03.png",
  "20231030_guest_avatars/guest-04.png",
  "20231030_guest_avatars/guest-05.png",
  "20231030_guest_avatars/guest-06.png",
  "20231030_guest_avatars/guest-07.png",
  "20231030_guest_avatars/guest-08.png",
  "20231030_guest_avatars/guest-09.png",
  "20231030_guest_avatars/guest-10.png",
];

export const defaultPersonPhotoUrl = (baseRoamUrl: string): string => {
  const fileName = "member.png";
  return resolvePresetPersonPhotoUrl(baseRoamUrl, fileName);
};

export const defaultOccupantPhotoUrl = (
  baseRoamUrl: string,
  occupantType: OccupantType | undefined
): string | undefined => {
  if (!occupantType) return;
  const fileName =
    occupantType === "Member"
      ? "member.png"
      : occupantType === "TeleRoom"
        ? "teleroom.png"
        : "visitor.png";
  return resolvePresetPersonPhotoUrl(baseRoamUrl, fileName);
};

export const defaultLobbyGuestPhotoUrl = (baseRoamUrl: string): string | undefined => {
  return resolvePresetPersonPhotoUrl(baseRoamUrl, "lobbyGuest.png");
};

/**
 * Returns a random filename for a default guest profile photo.
 *
 * This URL is deterministic relative to [seed] (only if defined) and the number of available photos.
 * If the [seed] stays the same and the number of available photos doesn't change this function
 * will always resolve to the same URL.
 */
export const randomGuestPhotoFilename = (seed?: string): string => {
  // Any hash function will do, and it doesn't have to be particularly clever.
  // This just determines an index into which guest pic to use.
  const hash = seed ? stringToHash(seed) : Date.now();

  const total = GUEST_IMAGE_FILENAMES.length;
  // JS returns negative mods, we need a non-negative index.
  // For example, -4 % 3 evaluates as -1, but we really want 2.
  const moddedHash = ((hash % total) + total) % total;

  return requireValue(GUEST_IMAGE_FILENAMES[moddedHash]);
};

export const resolvePresetPersonPhotoUrl = (baseRoamUrl: string, fileName: string): string => {
  return `${baseRoamUrl}/photos/people/${fileName}`;
};

export const defaultAudioEntranceUrl = (
  baseRoamUrl: string,
  occupantType: OccupantType | undefined
): string | undefined => {
  if (!occupantType) return;
  const fileName = occupantType === "Member" ? "jurassic-park-theme-song.mp3" : "superroam.mp3";
  return resolvePresetAudioUrl(baseRoamUrl, fileName);
};

export const resolvePresetAudioUrl = (baseUrl: string, fileName: string): string => {
  return `${baseUrl}/audio/${fileName}`;
};

export const resolveAbsoluteRoamPhotoUrl = (baseRoamUrl: string, fileName: string): string => {
  return `${baseRoamUrl}/card-images/${fileName}`;
};

export const fileNameFromUrl = (url: string): string => {
  const i = url.lastIndexOf("/");
  if (i === -1) {
    logger.error(`Could not find filename in url: ${url}`);
    return url;
  }
  return url.substring(i + 1);
};

export const resolveAbsolutePersonPhotoUrl = (
  fileName: string | undefined,
  baseRoamURL: string
): string | undefined => {
  if (fileName && fileName !== DEFAULT_ASSET_NAME) {
    if (isUUID(fileName)) {
      return resolveAbsoluteRoamPhotoUrl(baseRoamURL, fileName);
    } else {
      // TODO(jane): Keep this for now to not break people's old profile photos.
      return resolvePresetPersonPhotoUrl(baseRoamURL, fileName);
    }
  }
};

/**
 * Returns the hash used to construct gravatar-style URLs for DingDongs.
 */
export const dingDongHash = (principal: string): string => {
  return Md5.hashStr(principal);
};

export const dingDongAudioUrl = (hash: string): string => {
  return `${baseDingDongURL}/dd/${hash}`;
};

export const dingDongHashToName = (hash: string): string => {
  return `dingdong-${hash}`;
};

export const dingDongNameToHash = (id: string): string => {
  return id.substring("dingdong-".length);
};

export const isDingDongName = (value: string): boolean => {
  return value.startsWith("dingdong-");
};

export const DEFAULT_ASSET_NAME = "DEFAULT";
export const NONE_ASSET_NAME = "NONE";

export const isUUID = (value: string): boolean => {
  const uuidRegex = /^[\da-f]{8}-[\da-f]{4}-[1-5][\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}$/i;
  return uuidRegex.test(value);
};

/**
 * Hashes [input] to a number.
 *
 * Taken from here:
 * https://www.geeksforgeeks.org/how-to-create-hash-from-string-in-javascript/
 */
const stringToHash = (input: string): number => {
  let hash = 0;

  if (input.length === 0) {
    return hash;
  }

  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // eslint-disable-next-line no-bitwise
    hash = hash & hash;
  }

  return hash;
};
