import { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { IVideoPipelineLauncher } from "../../client/avStream/video/interfaces/IVideoPipelineLauncher.js";
import { PipelineError } from "../../client/avStream/video/interfaces/VideoPipelineError.js";
import {
  VideoPipelineDeltaStats,
  formatDuration,
  formatFps,
  pipelineDeltaStats,
} from "../../client/avStream/video/pipelineStats.js";
import { useContainer } from "../../client/hooks/useContainer.js";
import { styled } from "../../client/styles/theme.js";
import { VideoPipelineStats } from "../../shared/Models/VideoPipeline.js";

interface Props {
  streamId: string;
  pipelineLauncher: IVideoPipelineLauncher;
}

export const PipelineMetrics: React.FC<Props> = ({ streamId, pipelineLauncher }: Props) => {
  const container = useContainer();
  const prevStats = useRef<VideoPipelineStats | undefined>();
  const [deltaStats, setDeltaStats] = useState<VideoPipelineDeltaStats | undefined>();
  const [lastError, setLastError] = useState<PipelineError | undefined>(undefined);

  useEffect(() => {
    const timer = setInterval(() => {
      const stats = cloneDeep(container.avPipelines().getStatsByStreamId(streamId));
      if (stats) {
        if (stats.timestamp !== prevStats.current?.timestamp) {
          if (prevStats.current) {
            setDeltaStats(pipelineDeltaStats(prevStats.current, stats));
          }
          prevStats.current = stats;
        }
      }
      setLastError(pipelineLauncher.getPipelineError());
    }, 1000);
    return () => clearInterval(timer);
  }, [streamId, container, pipelineLauncher]);

  return (
    <Container>
      <Title>Metrics</Title>
      {deltaStats ? (
        <>
          <Metric>FPS: {formatFps(deltaStats.fps)}</Metric>
          <Metric>Avg (ms): {formatDuration(deltaStats.avgMs, deltaStats.avgMs)}</Metric>
          <Metric>Segment FPS: {formatFps(deltaStats.segmentFps)}</Metric>
          <Metric>Segment (ms): {formatDuration(deltaStats.segmentAvgMs, deltaStats.avgMs)}</Metric>
          <Metric>Face (ms): {formatDuration(deltaStats.faceAvgMs, deltaStats.avgMs)}</Metric>
          <Metric>Face FPS: {formatFps(deltaStats.faceFps)}</Metric>
          <Metric>Frames: {prevStats.current?.totalFrames}</Metric>
        </>
      ) : (
        <div>None</div>
      )}
      <Metric>Last Error: {lastError?.name ?? "(none)"}</Metric>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.overlayPrimary};
  color: ${({ theme }) => theme.color.text.primary};
  padding: 1em;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
`;
Container.displayName = "Container";

const Title = styled.div`
  font-size: 1em;
  align-self: center;
`;
Title.displayName = "Title";

const Metric = styled.div``;
Metric.displayName = "Metric";
