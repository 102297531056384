import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { z } from "zod";
import { createSelector } from "../../../helpers/redux.js";
import { createStorageKey } from "../../../injection/storage/IStorage.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { updateInternal, WantHave } from "../../../store/clientConnectionHelpers.js";
import { RootState } from "../../../store/reducers.js";

export interface ScreenKey {
  screen:
    | {
        screenKey: string;
        readOnly: boolean;
      }
    | undefined;
}

export interface SetBoardPayload {
  screenKey: string;
  activeBoardId: string | undefined;
  openBoardIds?: string[];
  sessionBookmark?: number;
}

export interface CloseBoardPayload {
  boardId: string;
  windowKey: WindowKey;
  force?: boolean;
}

export interface StartScreenShareBoardPayload {
  screenKey: string;
  boardId: string;
  sourceName?: string;
  sourceType?: "screen" | "window";
}

export const autoFullScreenKey = createStorageKey("autoFullScreen", z.boolean());
export const edgeScrollingKey = createStorageKey("edgeScrolling", z.boolean());

export const videoParticipantsPoppedOutFullScreenKey = createStorageKey(
  "videoParticipantsPoppedOutFullScreen",
  z.boolean()
);

export const videoParticipantsPoppedOutFullScreenDefault = true;

export const videoParticipantsPoppedOutNotFullScreenKey = createStorageKey(
  "videoParticipantsPoppedOutNotFullScreen",
  z.boolean()
);

export const videoParticipantsPoppedOutNotFullScreenDefault = false;

const slice = createSlice({
  name: "screen",
  initialState: {
    wantScreenKey: { screen: undefined } as ScreenKey,
    internalWantScreenKey: { screen: undefined } as ScreenKey,
    internalHaveScreenKey: { screen: undefined } as ScreenKey,
    internalRenderScreenKey: { screen: undefined } as ScreenKey,

    holoConnectionStatus: undefined as string | undefined,

    activeBoards: {} as { [screenKey: string]: string | undefined },
    openBoards: {} as { [screenKey: string]: string[] },
    sessionBookmarks: {} as { [screenKey: string]: number | undefined },
    mobileAccess: false,
  },
  reducers: {
    setWantKey: (state, action: PayloadAction<ScreenKey>) => {
      state.wantScreenKey = action.payload;
    },
    setInternalKey: (state, action: PayloadAction<WantHave<ScreenKey>>) => {
      updateInternal(state, "ScreenKey", action.payload);
    },
    setInternalRenderKey: (state, action: PayloadAction<ScreenKey>) => {
      state.internalRenderScreenKey = action.payload;
    },

    // client calls
    setCurrentBoard: (state, action: PayloadAction<string>) => {},
    closeBoard: (state, action: PayloadAction<CloseBoardPayload>) => {},
    showNoBoards: (state) => {},

    startScreenShareBoard: (state, action: PayloadAction<StartScreenShareBoardPayload>) => {},
    stopScreenShareBoard: (state, action: PayloadAction<string>) => {},

    // from server
    setHoloConnectionStatus: (state, action: PayloadAction<string | undefined>) => {
      state.holoConnectionStatus = action.payload;
    },
    updateActiveBoard: (state, action: PayloadAction<SetBoardPayload>) => {
      const { screenKey, activeBoardId, openBoardIds, sessionBookmark } = action.payload;
      state.activeBoards[screenKey] = activeBoardId;
      state.sessionBookmarks[screenKey] = sessionBookmark;
      if (openBoardIds) {
        state.openBoards[screenKey] = openBoardIds;
      } else if (activeBoardId) {
        state.openBoards[screenKey] = [activeBoardId];
      } else {
        state.openBoards[screenKey] = [];
      }
    },
    setMobileAccess: (state, action: PayloadAction<boolean>) => {
      state.mobileAccess = action.payload;
    },
  },
});

export const { actions: ScreenActions, reducer: screenReducer } = slice;
const selectSlice = (state: RootState) => state.holo.screen;
export const ScreenSelectors = {
  selectWantScreenKey: createSelector(selectSlice, (slice) => slice.wantScreenKey),
  selectInternalWantScreenKey: createSelector(selectSlice, (slice) => slice.internalWantScreenKey),
  selectInternalHaveScreenKey: createSelector(selectSlice, (slice) => slice.internalHaveScreenKey),
  selectInternalRenderScreenKey: createSelector(
    selectSlice,
    (slice) => slice.internalRenderScreenKey
  ),

  selectHoloConnectionStatus: (state: RootState) => selectSlice(state).holoConnectionStatus,

  selectScreenKey: createSelector(
    selectSlice,
    (slice) => slice.internalRenderScreenKey.screen?.screenKey
  ),
  selectScreenReadOnly: createSelector(
    selectSlice,
    (slice) => slice.internalRenderScreenKey.screen?.readOnly
  ),

  selectActiveBoard: createSelector(selectSlice, (slice) => {
    const screenKey = slice.internalRenderScreenKey.screen?.screenKey;
    return screenKey ? slice.activeBoards[screenKey] : undefined;
  }),

  selectActiveBoardByScreen: defaultMemoize((screenKey?: string) =>
    createSelector(selectSlice, (slice) => (screenKey ? slice.activeBoards[screenKey] : undefined))
  ),
  selectOpenBoardsIdsOnActiveScreen: createSelector(selectSlice, (slice) => {
    const screenKey = slice.internalRenderScreenKey.screen?.screenKey;
    if (screenKey) {
      return slice.openBoards[screenKey];
    } else {
      return undefined;
    }
  }),
  selectActiveBoardId: createSelector(
    selectSlice,
    (state: RootState) => state.holo.screen.internalRenderScreenKey.screen?.screenKey,
    (slice, screenKey) => (screenKey ? slice.activeBoards[screenKey] : undefined)
  ),

  selectIsShowingBoard: createSelector(
    selectSlice,
    (state: RootState) => state.holo.screen.internalRenderScreenKey.screen?.screenKey,
    (slice, screenKey) => screenKey !== undefined && slice.activeBoards[screenKey] !== undefined
  ),

  selectSessionBookmarkOnActiveScreen: createSelector(selectSlice, (slice) => {
    const screenKey = slice.internalRenderScreenKey.screen?.screenKey;
    if (screenKey) {
      return slice.sessionBookmarks[screenKey];
    } else {
      return undefined;
    }
  }),

  selectMobileAccess: createSelector(selectSlice, (slice) => slice.mobileAccess),
};
