/* cspell:ignore clientdata */

import type { Placement } from "@floating-ui/react";
import type { AnyAction, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { CSSProperties } from "react";
import { defaultMemoize } from "reselect";
import { z } from "zod";
import { AutoUpdateStatus } from "../../../shared/Models/AutoUpdateStatus.js";
import {
  NotificationConfiguration,
  NotificationOption,
} from "../../../shared/Models/NotificationSounds.js";
import { SystemMessage } from "../../../shared/Models/SystemMessage.js";
import {
  BenchmarkResult,
  PipelineClientState,
  VideoPipelineAutoSelection,
  VideoPipelineName,
  VideoPipelineOverride,
} from "../../../shared/Models/VideoPipeline.js";
import { Record as CxRecord } from "../../../shared/Models/crm/records/Record.js";
import { isDevMode } from "../../../shared/api/environment.js";
import { ClientData } from "../../../shared/clientData/models/ClientData.js";
import { CSSDimensions } from "../../../shared/geometry/Dimensions.js";
import { Rectangle } from "../../../shared/geometry/Rectangle.js";
import { logger } from "../../../shared/infra/logger.js";
import {
  computeNotificationSounds,
  defaultNotificationSounds,
} from "../../helpers/notificationSounds.js";
import { createDeepEqualSelector, createSelector } from "../../helpers/redux.js";
import { createStorageKey } from "../../injection/storage/IStorage.js";
import { WindowKey } from "../../injection/windows/WindowKey.js";
import { StoredAppearance } from "../../styles/appearance.js";
import { RootState } from "../reducers.js";

export type ClientPlatform = "electron" | "web";

export type ModalType =
  | "AccessBadgeEditAdmin"
  | "AccessBadgeGrantConfirmation"
  | "AddEditBotSequence"
  | "AddEditMember"
  | "AddEditApiClient"
  | "AddEmoji"
  | "AddFloorWizard"
  | "AddNonMembersToGroup"
  | "AddPaymentMethod"
  | "AddShelfItem"
  | "AddTemplateFloor"
  | "AssignPerson"
  | "AudienceRequestConfirm"
  | "BulkAddMember"
  | "ChatMessageDeletionConfirm"
  | "ComposeChatLink"
  | "Confirmation"
  | "CreateCalendarEvent"
  | "DoubleConfirmation"
  | "EditBillingInformation"
  | "SwitchOAuthAccount"
  | "ExternalRoomList"
  | "FoundersTour"
  | "GetStarted"
  | "GroupSettings"
  | "Groups"
  | "GuestSettings"
  | "Info"
  | "ItemView"
  | "KeyboardShortcuts"
  | "MakeConnection"
  | "ManageDmMembers"
  | "ManageGroupMembers"
  | "ManageTeamRoamMembers"
  | "ManageMagicMinutes"
  | "MarkAllTeamRoamChatsAnswered"
  | "NewGuest"
  | "NotificationPermission"
  | "OccupantIp"
  | "OpenChatLink"
  | "PopupInstructions"
  | "ProfileBadgeOther"
  | "ProfileBadgeSelf"
  | "ReferACompany"
  | "RemoveBadge"
  | "RenameBoard"
  | "ReportBug"
  | "RoomCalendar"
  | "SendScreenshot"
  | "Stories"
  | "SetAddressInfo"
  | "ShareSelector"
  | "SsoAddConfiguration"
  | "SsoEditConfiguration"
  | "TagsOverlay"
  | "TeleRoomCalendar"
  | "TextSnippet"
  | "TextSnippetComposer"
  | "YoureMuted"
  | "YouveBeenMuted"
  | "SFUConnectionError";

export type ModalSize = "auto" | "sm" | "md" | "lg" | CSSDimensions;

export type HeaderStyle = "compact" | "compact-no-border";

export interface Modal {
  id?: string;
  type: ModalType;
  size?: ModalSize;
  maxWidth?: "sm" | "md" | "lg" | number;
  maxHeight?: "sm" | "md" | "lg" | number;
  title?: string;
  additionalStyles?: CSSProperties;
  windowKey: WindowKey;
  error?: boolean;
  warning?: boolean;
  headerStyle?: HeaderStyle;
  noCloseButton?: boolean;
  noPadding?: boolean;
  noScroll?: boolean;
  overlaySecondary?: boolean;
  backgroundColor?: string;
  popper?: {
    sourceBoundingClientRect: Rectangle;
    placement?: Placement | Placement[];
    arrowColor?: string;
  };
  innerModal?: Modal;

  // Note that this is set internally in Modal.tsx when `innerModal` is provided.
  parentCloseInnerModal?: () => void;

  props: any;
}

export interface CloseModalPayload {
  // If `id` is provided, will only close the modal only if it has a matching `id`.
  // If no `id` is provided, will close any modal that is open.
  id?: string;
}

export interface Notification {
  id?: string;
  title: string;
  body: string;
  audioName?: string;
  audioUrl?: string;
  silent?: boolean;
  requireInteraction?: boolean;
  renotify?: boolean;
  onClick?: AnyAction;
  onClose?: AnyAction;
  reason?: string; // for logging
}

export interface Screenshot {
  roomId: number;
  boundaries?: Rectangle;
  url?: string;
  timestamp?: number;
  windowKey: WindowKey;
}

export const MediaDevices = z.object({
  audioInput: z.string().optional(),
  audioOutput: z.string().optional(),
  videoInput: z.string().optional(),
});
export type MediaDevices = z.infer<typeof MediaDevices>;

export interface ShowSettingsPayload {
  defaultRoamId?: number;
  defaultAccountId?: number;
  defaultSection?: string;
}

export interface GlobalError {
  globalErrorId: string;
  message?: string;
  sagaStack?: string;
  displayDetails?: boolean;
}

export interface SendDiagnosticsPayload {
  forSectionId?: string;
  interactive?: boolean;
  uuid?: string;
  pendingDiagnosticNotes?: string;
}

export interface EnableDebugToolsPayload {
  timeoutMillis: number;
}

export interface SendClientBugReportPayload {
  uuid: string;
  selectedIssues?: string[];
  details: string;
}

export interface RequestDiagnosticsForUserPayload {
  occupantId: string;
  sectionId: string;
}

export interface EnableDebugToolsForUserPayload {
  occupantId: string;
  sectionId: string;
}

export interface PlayAudioPayload {
  audioName?: string;
  audioUrl?: string;
  volume?: number;
  reason?: string; // for logging
}

export interface SetDeviceIdPayload {
  deviceId: string | undefined;
  manual?: boolean;
}

export interface SaveNotificationSoundsPayload {
  theme: NotificationOption;
  override: NotificationConfiguration;
  playWalkOnMusic: boolean;
}
export type NotificationPermissionStatus = "unknown" | "needToAsk" | "granted" | "denied";

export interface ReportVideoBenchmarkResultsPayload {
  results: BenchmarkResult[];
  clientState?: PipelineClientState;
}

// To be turned into enum with second hidden notice!
export const HiddenNotice = z.literal("audienceRequest");
export type HiddenNotice = z.infer<typeof HiddenNotice>;

export interface LinuxEnvironmentInfo {
  /** Are we running under Wayland? */
  isWayland: boolean;
  /** Does BrowserWindow.setIgnoreMouseEvents() work? */
  supportsIgnoreMouseEvents: boolean;
  /**
   * Are we running under a wlroots-based Wayland compositor?
   * (Screensharing on wlroots has certain oddities)
   */
  isWlroots: boolean;
}

export const showToolbarLabelsKey = createStorageKey("showToolbarLabels", z.boolean());
export const appearanceKey = createStorageKey("appearance", StoredAppearance);

const slice = createSlice({
  name: "app",
  initialState: {
    baseUrl: undefined as string | undefined,
    baseMediaUrl: undefined as string | undefined,
    modalDialog: undefined as Modal | undefined,
    clientPlatform: undefined as ClientPlatform | undefined,
    cpuBrand: undefined as string | undefined,
    electronOS: undefined as NodeJS.Platform | undefined,
    electronOSVersion: undefined as string | undefined,
    // This is undefined in all cases except Linux on Electron
    linuxEnvironmentInfo: undefined as LinuxEnvironmentInfo | undefined,
    // NOTE: currentlyUsingNativeWaylandScreenshare is the value loaded from prefs.json at the start. To avoid
    // crashes, this value *must not* be changed at runtime under any circumstances.
    // useNativeWaylandScreensharePref is kept in sync with the current value in prefs.json.
    currentlyUsingNativeWaylandScreenshare: false as boolean,
    useNativeWaylandScreensharePref: false as boolean,
    notificationPermission: "unknown" as NotificationPermissionStatus,
    mediaDevices: {} as MediaDevices,
    hideDownloadAppBanner: false as boolean,
    globalError: undefined as GlobalError | undefined,
    forceRerender: false as boolean,
    clientGuid: undefined as string | undefined,
    reportedClientGuid: undefined as string | undefined,
    clientVersion: undefined as string | undefined,
    connectionId: undefined as string | undefined,
    recentConnectionIds: new Array<{ id: string; connectTime: string }>(),
    notificationSoundsTheme: "INHERIT" as NotificationOption,
    notificationSoundsOverride: {} as NotificationConfiguration,
    // Cached sound paths - computed from theme and override.
    notificationSounds: defaultNotificationSounds(),
    notificationVolume: 1.0 as number,
    playWalkOnMusic: true as boolean,
    homeRegion: undefined as string | undefined,
    hiddenNotices: new Array<HiddenNotice>(),
    launchAppOnStartup: false as boolean,
    autoUpdateReleaseName: undefined as string | undefined,
    autoUpdateStatus: AutoUpdateStatus.Idle,
    hideAutoUpdateAvailableTime: undefined as number | undefined,
    linuxAppUpdated: false as boolean,
    systemMessages: new Array<SystemMessage>(),
    alwaysStartAudioMuted: false as boolean,
    alwaysStartVideoMuted: false as boolean,
    showYoureMutedNotification: false as boolean,
    explicitlyChosenVideoDeviceIds: new Array<string>(),
    explicitlyChosenAudioInputDeviceIds: new Array<string>(),
    explicitlyChosenAudioOutputDeviceIds: new Array<string>(),
    deprioritizedDeviceLabels: new Array<string>(),
    lameDuckConnectionId: undefined as string | undefined,
    restartingForUpdate: false as boolean,
    // Electron only. Similar to auth tokens, web tracks client data via cookies.
    clientDataJwt: undefined as string | undefined,
    /** `ClientData` payload from `clientDataJwt`. */
    clientData: undefined as ClientData | undefined,
    debugToolsEnabled: false as boolean,

    disableHardwareAcceleration: false as boolean,
    // Default is undefined so that in the future, we can have code determine the default based on
    // hardware configurations, but only if the user hasn't specified a value.
    useUITransparency: undefined as boolean | undefined,

    automaticVideoPipeline: undefined as VideoPipelineAutoSelection | undefined,
    overrideVideoPipeline: undefined as VideoPipelineOverride | undefined,
    effectiveVideoPipeline: undefined as VideoPipelineName | undefined,

    // If true, open all links in chat without a confirmation dialog
    alwaysOpenChatLinks: false,
    enterInsertsNewline: false,

    // unix timestamp [milliseconds] of starting time
    clientStartTime: undefined as number | undefined,

    takingScreenshot: false,
  },
  reducers: {
    appLaunch: (_, action: PayloadAction<void>) => {},
    setBaseUrl: (state, action: PayloadAction<string>) => {
      state.baseUrl = action.payload;
    },
    setBaseMediaUrl: (state, action: PayloadAction<string>) => {
      state.baseMediaUrl = action.payload;
    },

    setClientPlatform: (state, action: PayloadAction<ClientPlatform>) => {
      state.clientPlatform = action.payload;
    },

    openModalDialog: (state, action: PayloadAction<Modal>) => {
      state.modalDialog = action.payload;
    },

    closeModalDialog: (state, _: PayloadAction<CloseModalPayload | undefined>) => {},

    modalDialogClosed: (state, _: PayloadAction<void>) => {
      state.modalDialog = undefined;
    },

    checkNotificationPermission: (state, _: PayloadAction<void>) => {},

    ensureNotificationPermission: (_, action: PayloadAction<void>) => {},

    sendClientDiagnostics: (_, action: PayloadAction<SendDiagnosticsPayload>) => {},

    sendClientBugReport: (_, action: PayloadAction<SendClientBugReportPayload>) => {},

    enableDebugTools: (state, action: PayloadAction<EnableDebugToolsPayload>) => {
      state.debugToolsEnabled = true;
    },
    disableDebugTools: (state, action: PayloadAction<void>) => {
      state.debugToolsEnabled = false;
    },

    requestDiagnosticsForUser: (_, actin: PayloadAction<RequestDiagnosticsForUserPayload>) => {},

    enableDebugToolsForUser: (_, action: PayloadAction<EnableDebugToolsForUserPayload>) => {},

    setLaunchAppOnStartup: (state, action: PayloadAction<boolean>) => {
      state.launchAppOnStartup = action.payload;
    },

    setSavedLaunchAppOnStartup: (state, action: PayloadAction<boolean>) => {
      state.launchAppOnStartup = action.payload;
    },

    loadLaunchAppOnStartup: () => {},

    setNotificationPermission: (state, action: PayloadAction<NotificationPermissionStatus>) => {
      state.notificationPermission = action.payload;
    },

    showNotification: (_, action: PayloadAction<Notification>) => {},

    playAudio: (_, action: PayloadAction<PlayAudioPayload>) => {},

    takeScreenshot: (state, action: PayloadAction<Screenshot>) => {
      state.takingScreenshot = true;
    },
    screenshotTaken: (state, action: PayloadAction<Screenshot>) => {
      state.takingScreenshot = false;
    },

    loadMediaDevices: (_, action: PayloadAction<void>) => {},
    setAudioInputDeviceId: (state, action: PayloadAction<SetDeviceIdPayload>) => {
      state.mediaDevices = { ...state.mediaDevices, audioInput: action.payload.deviceId };
      if (action.payload.manual && action.payload.deviceId) {
        state.explicitlyChosenAudioInputDeviceIds =
          state.explicitlyChosenAudioInputDeviceIds.filter((id) => id !== action.payload.deviceId);
        state.explicitlyChosenAudioInputDeviceIds.unshift(action.payload.deviceId);
        if (state.explicitlyChosenAudioInputDeviceIds.length > 5) {
          state.explicitlyChosenAudioInputDeviceIds.splice(
            5,
            state.explicitlyChosenAudioInputDeviceIds.length - 5
          );
        }
      }
    },
    setAudioOutputDeviceId: (state, action: PayloadAction<SetDeviceIdPayload>) => {
      state.mediaDevices = { ...state.mediaDevices, audioOutput: action.payload.deviceId };
      if (action.payload.manual && action.payload.deviceId) {
        state.explicitlyChosenAudioOutputDeviceIds =
          state.explicitlyChosenAudioOutputDeviceIds.filter((id) => id !== action.payload.deviceId);
        state.explicitlyChosenAudioOutputDeviceIds.unshift(action.payload.deviceId);
        if (state.explicitlyChosenAudioOutputDeviceIds.length > 5) {
          state.explicitlyChosenAudioOutputDeviceIds.splice(
            5,
            state.explicitlyChosenAudioOutputDeviceIds.length - 5
          );
        }
      }
    },
    setVideoInputDeviceId: (state, action: PayloadAction<SetDeviceIdPayload>) => {
      state.mediaDevices = { ...state.mediaDevices, videoInput: action.payload.deviceId };
      if (action.payload.manual && action.payload.deviceId) {
        state.explicitlyChosenVideoDeviceIds = state.explicitlyChosenVideoDeviceIds.filter(
          (id) => id !== action.payload.deviceId
        );
        state.explicitlyChosenVideoDeviceIds.unshift(action.payload.deviceId);
        if (state.explicitlyChosenVideoDeviceIds.length > 5) {
          state.explicitlyChosenVideoDeviceIds.splice(
            5,
            state.explicitlyChosenVideoDeviceIds.length - 5
          );
        }
      }
    },
    setMediaDevices: (state, action: PayloadAction<MediaDevices>) => {
      state.mediaDevices = action.payload;
    },

    showSettings: (state, action: PayloadAction<ShowSettingsPayload>) => {},
    showClientPreferences: () => {},

    hideDownloadAppBanner: (state, action: PayloadAction<void>) => {
      state.hideDownloadAppBanner = true;
    },

    setGlobalError: (state, action: PayloadAction<GlobalError>) => {
      state.globalError = action.payload;
    },
    setForceRerender: (state, action: PayloadAction<boolean>) => {
      state.forceRerender = action.payload;
    },
    simulateError: (state) => {},

    setElectronOS: (state, action: PayloadAction<{ name: NodeJS.Platform; version: string }>) => {
      state.electronOS = action.payload.name;
      state.electronOSVersion = action.payload.version;
    },

    setCPUBrand: (state, action: PayloadAction<string>) => {
      state.cpuBrand = action.payload;
    },

    setLinuxEnvironmentInfo: (state, action: PayloadAction<LinuxEnvironmentInfo | undefined>) => {
      state.linuxEnvironmentInfo = action.payload;
    },
    // NOTE: This should ONLY be called at startup to set the initial value. Changing it at runtime
    // will cause crashes/breakage on Linux.
    setCurrentlyUsingNativeWaylandScreenshare: (state, action: PayloadAction<boolean>) => {
      state.currentlyUsingNativeWaylandScreenshare = action.payload;
    },
    loadUseNativeWaylandScreensharePref: () => {},
    setUseNativeWaylandScreensharePref: (state, action: PayloadAction<boolean>) => {
      state.useNativeWaylandScreensharePref = action.payload;
    },

    loadClientGuid: () => {},
    setClientGuid: (state, action: PayloadAction<string>) => {
      state.clientGuid = action.payload;
    },
    setReportedClientGuid: (state, action: PayloadAction<string>) => {
      state.reportedClientGuid = action.payload;
    },
    reportOpenApplication: (state) => {},

    setClientVersion: (state, action: PayloadAction<string>) => {
      state.clientVersion = action.payload;
    },

    setConnectionId: (state, action: PayloadAction<string | undefined>) => {
      state.connectionId = action.payload;
      if (action.payload) {
        if (
          state.recentConnectionIds.push({
            id: action.payload,
            connectTime: new Date().toISOString(),
          }) > 10
        ) {
          state.recentConnectionIds.shift();
        }
      }
    },

    loadNotificationSoundsSettings: () => {},
    setNotificationSoundsSettings: (
      state,
      action: PayloadAction<SaveNotificationSoundsPayload>
    ) => {
      const { theme, override, playWalkOnMusic } = action.payload;

      state.notificationSoundsTheme = theme;
      state.notificationSoundsOverride = override;

      const sounds = computeNotificationSounds(theme, override);
      state.notificationSounds = sounds;

      state.playWalkOnMusic = playWalkOnMusic;
    },
    loadNotificationVolume: () => {},
    setNotificationVolume: (state, action: PayloadAction<number>) => {
      state.notificationVolume = action.payload;
    },

    loadHomeRegion: () => undefined,
    setHomeRegion: (state, action: PayloadAction<string | undefined>) => {
      state.homeRegion = action.payload;
    },

    setAutoUpdateReleaseName: (state, action: PayloadAction<string>) => {
      state.autoUpdateReleaseName = action.payload;
    },
    setAutoUpdateStatus: (state, action: PayloadAction<AutoUpdateStatus>) => {
      state.autoUpdateStatus = action.payload;
    },

    hideAutoUpdateAvailable: (state, _: PayloadAction<void>) => {
      state.hideAutoUpdateAvailableTime = Date.now();
    },

    setLinuxAppUpdated: (state, action: PayloadAction<boolean>) => {
      state.linuxAppUpdated = action.payload;
    },

    openReleaseNotes: () => undefined,
    checkForUpdate: () => undefined,
    restartForUpdate: (state) => {
      state.restartingForUpdate = true;
    },
    restartApp: () => undefined,
    maybeLoadAwayCacheAfterUpdate: () => undefined,

    addHiddenNotice: (state, action: PayloadAction<HiddenNotice>) => {
      const notice = action.payload;
      if (!state.hiddenNotices.includes(notice)) {
        state.hiddenNotices.push(notice);
      }
    },
    setHiddenNotices: (state, action: PayloadAction<HiddenNotice[]>) => {
      state.hiddenNotices = action.payload;
    },
    loadHiddenNotices: (state) => {},

    openExternalUrl: (state, action: PayloadAction<string>) => {},

    /**
     * Sends a client CX event to be recorded.
     *
     * It is preferred to either directly send the RPC message in an existing saga,
     * or to dispatch this action in an existing saga.
     * Either of those two approaches are preferred over dispatching this action in a React component,
     * which should generally not be done unless there is no corresponding saga.
     *
     * This is preferred since the saga approaches will allow future use cases, refactors, etc. to continue sending events.
     * It's more likely that we will forget ot send an event if every component must send the appropriate events.
     */
    sendClientCxRecord: (state, action: PayloadAction<CxRecord>) => {},

    loadAutoFullScreenSetting: (state) => {},
    setSystemMessage: (state, action: PayloadAction<SystemMessage>) => {
      const index = state.systemMessages.findIndex((m) => m.id === action.payload.id);
      if (index === -1) {
        state.systemMessages.push(action.payload);
      } else {
        state.systemMessages[index] = action.payload;
      }
    },
    clearSystemMessage: (state, action: PayloadAction<string>) => {
      state.systemMessages = state.systemMessages.filter((m) => m.id !== action.payload);
    },
    setAlwaysStartAudioMuted: (state, action: PayloadAction<boolean>) => {
      state.alwaysStartAudioMuted = action.payload;
    },
    setAlwaysStartVideoMuted: (state, action: PayloadAction<boolean>) => {
      state.alwaysStartVideoMuted = action.payload;
    },
    loadAlwaysStartMutedSettings: (state) => {},
    setShowYoureMutedNotification: (state, action: PayloadAction<boolean>) => {
      state.showYoureMutedNotification = action.payload;
    },
    loadShowYoureMutedNotificationSetting: (state) => {},
    setExplicitlyChosenVideoDeviceIds: (state, action: PayloadAction<string[]>) => {
      state.explicitlyChosenVideoDeviceIds = action.payload;
    },
    setExplicitlyChosenAudioInputDeviceIds: (state, action: PayloadAction<string[]>) => {
      state.explicitlyChosenAudioInputDeviceIds = action.payload;
    },
    setExplicitlyChosenAudioOutputDeviceIds: (state, action: PayloadAction<string[]>) => {
      state.explicitlyChosenAudioOutputDeviceIds = action.payload;
    },
    addDeprioritizedDeviceLabels: (state, action: PayloadAction<string[]>) => {
      for (const deviceName of action.payload) {
        if (!state.deprioritizedDeviceLabels.includes(deviceName)) {
          state.deprioritizedDeviceLabels.push(deviceName);
        }
      }
    },
    loadPreferredSFURegions: () => {},

    loadDisableHardwareAcceleration: () => {},
    setDisableHardwareAcceleration: (state, action: PayloadAction<boolean>) => {
      state.disableHardwareAcceleration = action.payload;
    },

    loadUseUITransparency: () => {},
    setUseUITransparency: (state, action: PayloadAction<boolean>) => {
      state.useUITransparency = action.payload;
    },

    setAutomaticVideoPipeline: (
      state,
      action: PayloadAction<VideoPipelineAutoSelection | undefined>
    ) => {
      // Should be called only by PipelineBenchmarkService. Set here for display.
      state.automaticVideoPipeline = action.payload;
    },
    setOverrideVideoPipeline: (state, action: PayloadAction<VideoPipelineOverride | undefined>) => {
      // Should be called only by PipelineBenchmarkService. Set here for display.
      state.overrideVideoPipeline = action.payload;
    },
    setEffectiveVideoPipeline: (state, action: PayloadAction<VideoPipelineName | undefined>) => {
      // Should be called only by PipelineBenchmarkService. Set here for display.
      state.effectiveVideoPipeline = action.payload;
    },

    loadAlwaysOpenChatLinks: (state) => {},
    setAlwaysOpenChatLinks: (state, action: PayloadAction<boolean>) => {
      state.alwaysOpenChatLinks = action.payload;
    },

    loadEnterInsertsNewline: (state) => {},
    setEnterInsertsNewline: (state, action: PayloadAction<boolean>) => {
      state.enterInsertsNewline = action.payload;
    },

    reportVideoBenchmarkResults: (
      state,
      action: PayloadAction<ReportVideoBenchmarkResultsPayload>
    ) => {},

    setConnectionIsLameDuck: (state) => {
      state.lameDuckConnectionId = state.connectionId;
    },

    setClientStartTime: (state, action: PayloadAction<number>) => {
      logger.info("Client starting");
      state.clientStartTime = action.payload;
    },
    /**
     * Loads the `ClientData` JWT.
     *
     * On web, this is obtained from the current client data cookie value.
     * On electron, this comes from the electron store.
     */
    loadClientDataJwt: (state) => {},
    /**
     * Attempts to decode a `ClientData` JWT.
     *
     * If successful, stores the JWT and decoded `ClientData` in redux state.
     * If appropriate, also persists to the client store.
     * If unsuccessful, does nothing.
     *
     * On web, there is no storage action; the client data JWT is automatically stored via cookie.
     * On electron, this stores the JWT to the electron store.
     *
     * Does not verify the JWT, only decodes.
     */
    saveClientDataJwt: (state, action: PayloadAction<string>) => {},
    /**
     * Handles the logic & redux state changes described in `saveClientDataJwt`.
     *
     * Please do not call directly, use via saveClientDataJwt.
     */
    internalSaveClientData: (
      state,
      action: PayloadAction<{ clientDataJwt: string; clientData: ClientData }>
    ) => {
      const { clientDataJwt, clientData } = action.payload;
      state.clientDataJwt = clientDataJwt;
      state.clientData = clientData;
    },
  },
});
export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.app;
export const selectors = {
  selectClientPlatform: (state: RootState) => selectSlice(state).clientPlatform,
  selectBaseUrl: (state: RootState) => selectSlice(state).baseUrl,
  selectBaseMediaUrl: (state: RootState) => selectSlice(state).baseMediaUrl,
  selectModalDialog: (state: RootState) => selectSlice(state).modalDialog,
  selectLaunchAppOnStartup: (state: RootState) => selectSlice(state).launchAppOnStartup,
  selectAutoUpdateReleaseName: (state: RootState) => selectSlice(state).autoUpdateReleaseName,
  selectAutoUpdateStatus: (state: RootState) => selectSlice(state).autoUpdateStatus,
  selectHideAutoUpdateAvailableTime: (state: RootState) =>
    selectSlice(state).hideAutoUpdateAvailableTime,
  selectLinuxAppUpdated: (state: RootState) => selectSlice(state).linuxAppUpdated,
  selectNotificationPermission: (state: RootState) => selectSlice(state).notificationPermission,
  selectAudioInputDeviceId: (state: RootState) => selectSlice(state).mediaDevices.audioInput,
  selectAudioOutputDeviceId: (state: RootState) => selectSlice(state).mediaDevices.audioOutput,
  selectVideoInputDeviceId: (state: RootState) => selectSlice(state).mediaDevices.videoInput,
  selectMediaDevices: (state: RootState) => selectSlice(state).mediaDevices,
  selectHideDownloadAppBanner: (state: RootState) => selectSlice(state).hideDownloadAppBanner,
  selectGlobalError: (state: RootState) => selectSlice(state).globalError,
  selectForceRerender: (state: RootState) => selectSlice(state).forceRerender,
  selectElectronOS: (state: RootState) => selectSlice(state).electronOS,
  selectElectronOSVersion: (state: RootState) => selectSlice(state).electronOSVersion,
  selectCPUBrand: (state: RootState) => selectSlice(state).cpuBrand,
  selectIsLinuxWayland: (state: RootState) => !!selectSlice(state).linuxEnvironmentInfo?.isWayland,
  selectLinuxEnvironmentInfo: (state: RootState) => selectSlice(state).linuxEnvironmentInfo,
  selectCurrentlyUsingNativeWaylandScreenshare: (state: RootState) =>
    selectSlice(state).currentlyUsingNativeWaylandScreenshare,
  selectDebugToolsEnabled: (state: RootState) =>
    selectSlice(state).debugToolsEnabled || selectors.selectIsDevOrAlphaMode(state),
  selectDisableHardwareAcceleration: (state: RootState) =>
    selectSlice(state).disableHardwareAcceleration,
  selectUseUITransparency: (state: RootState) =>
    // NOTE: We consider "undefined" (user has not explicitly selected an option) to be true in this context.
    selectSlice(state).useUITransparency !== false &&
    !selectors.selectDisableHardwareAcceleration(state),
  selectAutomaticVideoPipeline: (state: RootState) => selectSlice(state).automaticVideoPipeline,
  selectOverrideVideoPipeline: (state: RootState) => selectSlice(state).overrideVideoPipeline,
  selectEffectiveVideoPipeline: (state: RootState) => selectSlice(state).effectiveVideoPipeline,
  selectUseNativeWaylandScreensharePref: (state: RootState) =>
    selectSlice(state).useNativeWaylandScreensharePref,
  selectEntireState: (state: RootState): RootState => state,
  selectClientGuid: (state: RootState) => selectSlice(state).clientGuid,
  selectReportedClientGuid: (state: RootState) => selectSlice(state).reportedClientGuid,
  selectClientVersion: (state: RootState) => selectSlice(state).clientVersion,
  selectIsDevOrAlphaMode: (state: RootState) => {
    // NOTE: This should match the logic of isDevOrAlphaMode in environment.ts
    const version = selectSlice(state).clientVersion;
    return (
      isDevMode ||
      version?.includes("alpha") ||
      version?.includes("exp") ||
      version?.includes("local")
    );
  },
  selectConnectionId: (state: RootState) => selectSlice(state).connectionId,
  selectNotificationSoundsTheme: (state: RootState) => selectSlice(state).notificationSoundsTheme,
  selectNotificationSoundsOverride: (state: RootState) =>
    selectSlice(state).notificationSoundsOverride,
  selectNotificationSounds: (state: RootState) => selectSlice(state).notificationSounds,
  selectPlayWalkOnMusic: (state: RootState) => selectSlice(state).playWalkOnMusic,
  selectNotificationVolume: (state: RootState) => selectSlice(state).notificationVolume,
  selectHomeRegion: (state: RootState) => selectSlice(state).homeRegion,
  selectHiddenNotices: (state: RootState) => selectSlice(state).hiddenNotices,
  selectNoticeIsHidden: defaultMemoize((notice: HiddenNotice) =>
    createSelector(selectSlice, (slice) => slice.hiddenNotices.includes(notice))
  ),
  selectSystemMessages: (state: RootState) => selectSlice(state).systemMessages,
  selectAccountSystemMessages: defaultMemoize((accountId: number) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      slice.systemMessages.filter((message) => message.scope?.accountId === accountId)
    )
  ),
  selectAlwaysStartAudioMuted: (state: RootState) => selectSlice(state).alwaysStartAudioMuted,
  selectAlwaysStartVideoMuted: (state: RootState) => selectSlice(state).alwaysStartVideoMuted,
  selectShowYoureMutedNotification: (state: RootState) =>
    selectSlice(state).showYoureMutedNotification,
  selectAlwaysOpenChatLinks: (state: RootState) => selectSlice(state).alwaysOpenChatLinks,
  selectEnterInsertsNewline: (state: RootState) => selectSlice(state).enterInsertsNewline,
  selectExplicitlyChosenVideoDeviceIds: (state: RootState) =>
    selectSlice(state).explicitlyChosenVideoDeviceIds,
  selectExplicitlyChosenAudioInputDeviceIds: (state: RootState) =>
    selectSlice(state).explicitlyChosenAudioInputDeviceIds,
  selectExplicitlyChosenAudioOutputDeviceIds: (state: RootState) =>
    selectSlice(state).explicitlyChosenAudioOutputDeviceIds,
  selectDeprioritizedDeviceLabels: (state: RootState) =>
    selectSlice(state).deprioritizedDeviceLabels,
  selectLameDuckConnectionId: (state: RootState) => selectSlice(state).lameDuckConnectionId,
  selectRestartingForUpdate: (state: RootState) => selectSlice(state).restartingForUpdate,
  selectClientStartTime: (state: RootState) => selectSlice(state).clientStartTime,
  selectTakingScreenshot: (state: RootState) => selectSlice(state).takingScreenshot,
  selectClientDataJwt: (state: RootState) => selectSlice(state).clientDataJwt,
  selectClientData: (state: RootState): ClientData | undefined => selectSlice(state).clientData,
};

export const AppActions = actions;
export const AppSelectors = selectors;

export const disableHardwareAccelerationPref = "disableHardwareAcceleration";
