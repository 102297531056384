import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccessLinkId } from "../../../../../shared/Models/AccessLinks/AccessLinkId.js";
import { Directive } from "../../../../../shared/Models/lobby/directives/Directive.js";
import { DirectiveType } from "../../../../../shared/Models/lobby/directives/DirectiveType.js";
import { LobbyClientState } from "../../../../../shared/Models/lobby/LobbyClientState.js";
import { LobbyUserAgent } from "../../../../../shared/Models/lobby/LobbyUserAgent.js";
import { crpc_lobby_ltc_RequestDirective } from "../../../../../shared/WorldMessages/lobby/external/ClientRpcLobbyTrafficControllerExternal.js";
import { RootState } from "../../../../store/reducers.js";
import { getUserAgent } from "./userAgent.js";

/**
 * Lobby traffic controller slice.
 */

export interface MapPreviewTarget {
  roamId: number;
  floorId: number;
  roomId?: number;
}

const slice = createSlice({
  name: "ltc",
  initialState: {
    // May need a way to reset this in the future?
    /** Current lobby session ID. */
    sessionId: undefined as string | undefined,
    enterRoamRequestId: undefined as string | undefined,
    haveDirective: undefined as Directive | undefined,
    haveDirectiveType: undefined as DirectiveType | undefined,
    wantDirective: undefined as Directive | undefined,
    mapPreviewTarget: undefined as MapPreviewTarget | undefined,
  },
  reducers: {
    setMapPreviewTarget: (state, action: PayloadAction<MapPreviewTarget | undefined>) => {
      state.mapPreviewTarget = action.payload;
    },

    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },

    setEnterRoamRequestId: (state, action: PayloadAction<string | undefined>) => {
      state.enterRoamRequestId = action.payload;
    },

    requestDirective: (
      _state,
      _action: PayloadAction<Pick<crpc_lobby_ltc_RequestDirective, "intent" | "actionType">>
    ) => {},

    setHaveDirective: (state, action: PayloadAction<Directive>) => {
      state.haveDirective = action.payload;
    },
    setHaveDirectiveType: (state, action: PayloadAction<DirectiveType>) => {
      state.haveDirectiveType = action.payload;
    },
    setWantDirective: (state, action: PayloadAction<Directive>) => {
      state.wantDirective = action.payload;
    },
    ensureHaveWantDirective: (_state, _action: PayloadAction<Directive>) => {},

    subscribeToMapPreview: (_state, _action: PayloadAction<AccessLinkId>) => {},

    // For development testing.
    printClientState: () => {},
  },
});

export const { actions, reducer } = slice;
export const LtcActions = actions;
export const ltcReducer = reducer;

/**
 * Derives the `LobbyClientState` from [state].
 */
const getClientState = (state: RootState): LobbyClientState => {
  return {
    media: {
      screen: state.holo.avPermission.screen,
      microphone: state.holo.avPermission.microphone,
      camera: state.holo.avPermission.camera,
    },
    adHocProfile: state.world.visiting.adHocProfile,
  };
};

/** @internal */
export const selectors = {
  selectSessionId: (state: RootState): string | undefined => {
    return state.world.lobby.ltc.sessionId;
  },
  selectEnterRoamRequestId: (state: RootState): string | undefined => {
    return state.world.lobby.ltc.enterRoamRequestId;
  },
  /**
   * WARNING:
   *  Using this via useSelector will currently cause lots of rerenders.
   *  Currently written for saga usage only.
   */
  selectUserAgent: (state: RootState): LobbyUserAgent => {
    return getUserAgent(state);
  },
  /**
   * WARNING:
   *  Using this via useSelector will currently cause lots of rerenders.
   *  Currently written for saga usage only.
   */
  selectClientState: (state: RootState): LobbyClientState => {
    return getClientState(state);
  },
  selectHaveDirective: (state: RootState): Directive | undefined => {
    return state.world.lobby.ltc.haveDirective;
  },
  selectHaveDirectiveType: (state: RootState): DirectiveType | undefined => {
    return state.world.lobby.ltc.haveDirectiveType;
  },
  selectWantDirective: (state: RootState): Directive | undefined => {
    return state.world.lobby.ltc.wantDirective;
  },
  selectMapPreviewTarget: (state: RootState): MapPreviewTarget | undefined => {
    return state.world.lobby.ltc.mapPreviewTarget;
  },
};
/** @internal */
export const LtcSelectors = selectors;
