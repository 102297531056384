import { logger } from "../../../shared/infra/logger.js";

export class NoiseNode extends window.AudioWorkletNode {
  /**
   * Initialize the Audio processor by sending the fetched WebAssembly module to
   * the processor worklet.
   *
   */
  init(module) {
    this.port.onmessage = (event) => this.onmessage(event.data);
    this.onVoiceCallback = undefined;
    this.onVoiceStartCallback = undefined;
    this.onVoiceStopCallback = undefined;
    this.onMaybeVoiceCallback = undefined;
    this.voiceLevel = 0;
    this.voiceStarted = false;

    this.port.postMessage({
      type: "init",
      module: module,
    });
  }

  shutdown() {
    this.port.postMessage({ type: "shutdown-process" });
  }

  getWasmBytes(bytes) {
    this.bytes = bytes;

    this.port.postMessage({
      type: "send-rnnoise-module",
      wasmBytes: this.bytes,
    });
  }

  onprocessorerror(err) {
    logger.error(`An error from AudioWorkletProcessor.process() occurred: ${err}`);
  }

  onmessage(event) {
    if (event.type === "noise-module-loaded") {
      this.port.postMessage({
        type: "init-detector",
        sampleRate: 48000,
        numAudioSamplesPerAnalysis: 480,
      });
    } else if (event.type === "VAD") {
      if (this.onVoiceCallback) {
        this.onVoiceCallback(event.vad);
      }
      this.voiceLevel = event.vad;
    } else if (event.type === "voiceOn") {
      if (this.onVoiceStartCallback) {
        this.onVoiceStartCallback();
      }
      this.voiceStarted = true;
    } else if (event.type === "voiceOff") {
      if (this.onVoiceStopCallback) {
        this.onVoiceStopCallback();
      }
      this.voiceStarted = false;
    } else if (event.type === "maybeVoice") {
      if (this.onMaybeVoiceCallback) {
        this.onMaybeVoiceCallback();
      }
    }
  }
}
