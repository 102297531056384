import { z } from "zod";

export const CustomEmoji = z.object({
  code: z.string(),
  fileId: z.string(),
});
export type CustomEmoji = z.infer<typeof CustomEmoji>;

export const CustomEmojiRecord = z.object({
  roamId: z.number(),
  code: z.string(),
  fileId: z.string(),
  itemId: z.string().optional(),
  dateCreated: z.number(),
  personId: z.number(),
  personName: z.string(),
});
export type CustomEmojiRecord = z.infer<typeof CustomEmojiRecord>;
