/* cspell:ignore incref */
import { logger } from "../../shared/infra/logger.js";
import { IRawStream } from "./interfaces/IRawStreamManager.js";

export class RawStream {
  private closed = false;
  public clones = new Map<string, RawStream>();

  constructor(
    public mediaStream: MediaStream,
    private onClosed: () => void
  ) {
    this.mediaStream.getTracks().forEach((t) => {
      if (t.readyState === "ended") {
        // Track is already ended
        // Manually close (after a short delay to avoid busy looping)
        setTimeout(() => this.close(), 100);
      }
      t.addEventListener("ended", () => this.close());
    });
  }

  public clone(): IRawStream {
    const mediaStreamClone = this.mediaStream.clone();
    const id = mediaStreamClone.id;
    const clone = new RawStream(mediaStreamClone, () => this.clones.delete(id));
    this.clones.set(id, clone);
    return clone;
  }

  public close(): void {
    if (this.closed) return;
    this.closed = true;
    this.mediaStream.getTracks().forEach((t) => {
      t.stop();
      // Manually emit the "ended" event (asynchronously)
      setTimeout(() => t.dispatchEvent(new Event("ended")));
    });
    // Close any clones
    for (const clone of this.clones.values()) {
      clone.close();
    }
    // Fire the original callback we were given
    this.onClosed();
  }

  public isClosed(): boolean {
    return this.closed;
  }
}

export class RefCountRawStream extends RawStream {
  private refCount = 1;

  public incref(): void {
    if (this.refCount === 0) {
      logger.warn(`RefCountRawStream.incref: already freed`);
      return;
    }
    this.refCount++;
  }

  public decref(): void {
    if (this.refCount === 0) {
      logger.warn(`RefCountRawStream.decref: already freed`);
      return;
    }

    this.refCount--;
    if (this.refCount === 0) {
      this.close();
    }
  }

  public clone(): IRawStream {
    this.incref();
    const mediaStreamClone = this.mediaStream.clone();
    const id = mediaStreamClone.id;
    const clone = new RawStream(mediaStreamClone, () => {
      this.clones.delete(id);
      this.decref();
    });
    this.clones.set(id, clone);
    return clone;
  }
}
