import { z } from "zod";
import { uuid } from "./zodTypes.js";

export const SocialNetwork = z.enum(["LinkedIn", "Twitter", "Instagram", "TikTok"]);
export type SocialNetwork = z.infer<typeof SocialNetwork>;

export const ExternalSocialLink = z.object({
  // (deprecate-external-social-link-id): this field is just a combination of
  // addressId and socialNetwork, kept around for backwards compatibility
  id: z.string(),
  addressId: uuid(),
  socialNetwork: SocialNetwork,
  username: z.string(),
  // Derived from socialNetwork and username, included on object for convenience
  link: z.string(),
});
export type ExternalSocialLink = z.infer<typeof ExternalSocialLink>;
