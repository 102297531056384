import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { Item } from "../../../../shared/Models/Item.js";
import { Upload } from "../../../../shared/Models/Upload.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";
import { UploadDetails, UploadOrigin } from "../../upload/UploadDetails.js";
import { getUploadingMessage, getUploadPercentComplete } from "../../upload/uploadDisplay.js";

const adapter = createEntityAdapter<UploadDetails>({
  selectId: (upload) => upload.itemId,
});

export interface BeginUploadPayload {
  item: Item;
  windowKey: WindowKey | undefined;
  origin: UploadOrigin;
}

export interface UploadProgress {
  itemId: string;
  percentComplete: number;
}
export interface SetUploadErrorPayload {
  itemId: string;
  error: string;
}

const slice = createSlice({
  name: "upload",
  initialState: {
    uploads: adapter.getInitialState(),
  },
  reducers: {
    // Client calls - used by client doing the upload
    beginUpload: (state, action: PayloadAction<BeginUploadPayload>) => {
      const { item, origin } = action.payload;
      const upload: UploadDetails = {
        isMine: true,
        itemId: item.id,
        itemType: item.itemType,
        itemName: item.name,
        origin,
        status: "Uploading",
        upload: {
          itemId: item.id,
          percentComplete: 0,
        },
      };
      adapter.upsertOne(state.uploads, upload);
    },
    bulkUpdateProgress: (state, action: PayloadAction<UploadProgress[]>) => {
      for (const { itemId, percentComplete } of action.payload) {
        const details = state.uploads.entities[itemId];
        if (details && details.isMine && details.status === "Uploading" && details.upload) {
          const newDetails: UploadDetails = {
            ...details,
            upload: {
              ...details.upload,
              percentComplete,
            },
          };
          adapter.upsertOne(state.uploads, newDetails);
        }
      }
    },
    completedUpload: (state, action: PayloadAction<string>) => {
      const itemId = action.payload;

      const details = state.uploads.entities[itemId];
      if (details?.isMine) {
        const newDetails: UploadDetails = {
          ...details,
          status: "CompleteOrErrored",
          upload: undefined,
          error: undefined,
        };
        adapter.upsertOne(state.uploads, newDetails);
      }
    },
    setUploadError: (state, action: PayloadAction<SetUploadErrorPayload>) => {
      const { itemId, error } = action.payload;

      const details = state.uploads.entities[itemId];
      if (details?.isMine) {
        const newDetails: UploadDetails = {
          ...details,
          status: "CompleteOrErrored",
          upload: undefined,
          error,
        };
        adapter.upsertOne(state.uploads, newDetails);
      }
    },

    // Server
    setUploadStatuses: (state, action: PayloadAction<Upload[]>) => {
      for (const upload of action.payload) {
        const details = state.uploads.entities[upload.itemId];
        if (details?.status === "CompleteOrErrored") {
          return;
        }

        let newDetails: UploadDetails | undefined;
        if (details?.upload) {
          const currentLastUpdated = details.upload.lastUpdated;
          const newLastUpdated = upload.lastUpdated;
          const lastUpdatedIsNewer =
            newLastUpdated && (!currentLastUpdated || currentLastUpdated < newLastUpdated);
          if (details.isMine) {
            const updateLastUpdated =
              upload.percentComplete === details.upload.percentComplete && lastUpdatedIsNewer;
            // For your own uploads, the local percentComplete is always the most up-to-date.
            newDetails = {
              ...details,
              upload: {
                ...upload,
                percentComplete: details.upload.percentComplete,
                lastUpdated: updateLastUpdated ? newLastUpdated : currentLastUpdated,
              },
            };
          } else if (lastUpdatedIsNewer) {
            newDetails = {
              ...details,
              upload,
            };
          }
        } else {
          newDetails = {
            isMine: false,
            itemId: upload.itemId,
            status: "Uploading",
            upload,
          };
        }

        if (newDetails) {
          adapter.upsertOne(state.uploads, newDetails);
        }
      }
    },
    markUploadCompletedOrErrored: (state, action: PayloadAction<string>) => {
      const itemId = action.payload;
      const details = state.uploads.entities[itemId];
      if (details) {
        const newDetails: UploadDetails = {
          ...details,
          status: "CompleteOrErrored",
          upload: undefined,
        };
        adapter.upsertOne(state.uploads, newDetails);
      }
    },
  },
});

export const { actions, reducer } = slice;
const adapterSelectors = adapter.getSelectors();
const selectSlice = (state: RootState) => state.things.upload;
export const selectors = {
  selectUploadingMessageForBoard: defaultMemoize((boardId?: string) =>
    createSelector(selectSlice, (slice) => {
      if (!boardId) return undefined;
      const uploads = adapterSelectors
        .selectAll(slice.uploads)
        .filter(
          (u) =>
            u.isMine &&
            (u.origin.type === "holoBoard" || u.origin.type === "whiteboard") &&
            u.origin.id === boardId &&
            u.status === "Uploading"
        );
      return getUploadingMessage(uploads);
    })
  ),
  selectPercentCompleteById: defaultMemoize(
    (itemId?: string) =>
      createSelector(selectSlice, (slice) => {
        if (!itemId) return;
        const upload = slice.uploads.entities[itemId];
        if (upload?.status === "Uploading") {
          return getUploadPercentComplete([upload]);
        }
      }),
    {
      maxSize: 10,
    }
  ),
  selectIsUploadingById: defaultMemoize(
    (itemId?: string) =>
      createSelector(selectSlice, (slice) => {
        if (!itemId) return false;
        const upload = slice.uploads.entities[itemId];
        return upload?.status === "Uploading";
      }),
    {
      maxSize: 10,
    }
  ),
  selectUploadById: (itemId?: string) => (state: RootState) =>
    itemId ? state.things.upload.uploads.entities[itemId] : undefined,
  selectUploadsByIds: defaultMemoize(
    (itemIds: string[]) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        const uploads: { [itemId: string]: UploadDetails } = {};
        for (const itemId of itemIds) {
          const upload = slice.uploads.entities[itemId];
          if (upload) {
            uploads[itemId] = upload;
          }
        }
        return uploads;
      }),
    {
      equalityCheck: equal,
      maxSize: 5,
    }
  ),
  selectErrorMessagesByIds: defaultMemoize(
    (itemIds: string[]) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        const errors: { [itemId: string]: string } = {};
        for (const itemId of itemIds) {
          const upload = slice.uploads.entities[itemId];
          if (upload && upload.isMine && upload.error) {
            errors[itemId] = upload.error ?? "";
          }
        }
        return errors;
      }),
    {
      equalityCheck: equal,
      maxSize: 5,
    }
  ),
  selectAnyErrorMessagesByIds: defaultMemoize(
    (itemIds: string[]) =>
      createSelector(selectSlice, (slice) => {
        for (const itemId of itemIds) {
          const upload = slice.uploads.entities[itemId];
          if (upload && upload.isMine && upload.error) {
            return true;
          }
        }
        return false;
      }),
    {
      equalityCheck: equal,
      maxSize: 5,
    }
  ),
  selectErrorMessageById: defaultMemoize(
    (itemId: string | undefined) =>
      createSelector(selectSlice, (slice) => {
        if (itemId) {
          const upload = slice.uploads.entities[itemId];
          if (upload && upload.isMine) {
            return upload.error;
          }
        }
      }),
    {
      equalityCheck: equal,
      maxSize: 10,
    }
  ),
};

export const UploadActions = actions;
export const UploadSelectors = selectors;
