import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { InboxSelectors } from "../../chat/store/slices/inboxSlice.js";
import { createDeepEqualSelector, createSelector } from "../../helpers/redux.js";
import { fromWindowKey, WindowKey } from "../../injection/windows/WindowKey.js";
import { RootState } from "../../store/reducers.js";
import { WindowSelectors } from "../../store/slices/windowSlice.js";
import {
  anchorForParent,
  anchorParentForWindow,
  emptyPopupIconAnchor,
  popupIconKeyForParent,
} from "../helpers/anchors.js";
import { popupMatchesIds } from "../helpers/popups.js";
import {
  anchorTypeHigherPriority,
  anchorTypeIsOnDock,
  anchorTypeServesAsPopupIcon,
} from "../types/Anchor.js";
import { initialState } from "../types/InteractionState.js";
import {
  AnchorChangedPayload,
  AnchorMaybeShouldPopPayload,
  ChatWithOccupantIdsPayload,
  CloseActiveAnchoredPopupPayload,
  ClosePopupPayload,
  InternalAddPopupPayload,
  NewChannelThreadPayload,
  OpenChannelThreadPayload,
  OpenEditMessagePayload,
  PopupContextPayload,
  PopupForAnchorCriteria,
  SetChatForPopupPayload,
  SetOverridePopupAnchor,
  SetPopupAnchorPayload,
  SetPopupIconKeyPayload,
  UpdatePopupAnchorPayload,
} from "../types/payloads.js";
import { Popup, threadTimestampForPopup } from "../types/Popup.js";
import { PopupTrigger } from "../types/PopupTrigger.js";

const slice = createSlice({
  name: "interaction",
  initialState,
  reducers: {
    // These actions can be dispatched by anyone
    trigger: (state, action: PayloadAction<PopupTrigger>) => {},
    activatePopup: (state, action: PayloadAction<number>) => {
      // logic is in saga
    },
    closePopup: (state, action: PayloadAction<ClosePopupPayload>) => {},
    setDoNotPop: (state, action: PayloadAction<boolean>) => {
      state.doNotPop = action.payload;
    },
    closeActiveAnchoredPopup: (state, action: PayloadAction<CloseActiveAnchoredPopupPayload>) => {},
    openDockIndex: (state, action: PayloadAction<number>) => {
      state.popDockIndex = action.payload;
      state.popDockCounter = state.popDockCounter + 1;
    },
    clearOpenDockIndex: (state) => {
      state.popDockIndex = undefined;
      state.popDockCounter = 0;
    },

    // These actions are dispatched by usePopAnchor and InteractionPopup
    anchorChanged: (state, action: PayloadAction<AnchorChangedPayload>) => {
      // logic in saga
    },
    anchorMaybeShouldPop: (state, action: PayloadAction<AnchorMaybeShouldPopPayload>) => {
      // logic in saga
    },
    setDoNotRepositionPopup: (state, action: PayloadAction<number>) => {
      const popupId = action.payload;
      const popup = state.popups.find((p) => p.popupId === popupId);
      if (!popup) {
        return;
      }
      popup.doNotReposition = true;
    },
    openChatWithOccupantIds: (state, action: PayloadAction<ChatWithOccupantIdsPayload>) => {
      // logic in saga
    },
    extendChatInPopup: (state, action: PayloadAction<number>) => {
      // logic in saga
    },
    setChatForPopup: (state, action: PayloadAction<SetChatForPopupPayload>) => {
      // logic in saga
    },
    toggleConfidentialForPopup: (state, action: PayloadAction<number>) => {
      // logic in saga
    },
    recomputeMatchingOpenChat: (state) => {
      // logic in saga
    },
    newChannelThread: (state, action: PayloadAction<NewChannelThreadPayload>) => {
      // logic in saga
    },
    openChannelThread: (state, action: PayloadAction<OpenChannelThreadPayload>) => {
      // logic in saga
    },
    openEditMessage: (state, action: PayloadAction<OpenEditMessagePayload>) => {
      // logic in saga
    },
    moveInboxPopupToPopupWindow: (state) => {
      // logic in saga
    },
    movePopupToPopupWindow: (state, action: PayloadAction<number>) => {
      // logic in saga
    },
    movePopupThreadToPopupWindow: (state, action: PayloadAction<number>) => {
      // logic in saga
    },
    movePopupFromPopupWindow: (state, action: PayloadAction<number>) => {
      // logic in saga
    },
    closeAllPreviewPopups: (state) => {
      // logic in saga
    },
    // These internalXX actions are dispatched only by interactionSaga
    internalAddPopup: (state, action: PayloadAction<InternalAddPopupPayload>) => {
      const { popupId, ctx, trigger } = action.payload;
      const popup: Popup = {
        popupId,
        ctx,
        overrideAnchor: trigger.overrideAnchor,
        mainWindowAnchor: emptyPopupIconAnchor("mainWindow", popupId),
        meetingWindowAnchor: emptyPopupIconAnchor("meetingWindow", popupId),
        readyToShow: false,
        doNotReposition: (ctx.fromUserClick || !!trigger.overrideAnchor) ?? false,
        signalNonce: 1,
      };
      state.popups.unshift(popup);
    },
    internalSetActiveAnchoredPopup: (state, action: PayloadAction<number | undefined>) => {
      state.activeAnchoredPopupId = action.payload;
    },
    internalRemovePopup: (state, action: PayloadAction<Popup>) => {
      const { popupId } = action.payload;
      if (state.activeAnchoredPopupId === popupId) {
        state.activeAnchoredPopupId = undefined;
      }
      const i = state.popups.findIndex((p) => p.popupId === popupId);
      if (i >= 0) {
        state.popups.splice(i, 1);
      }
    },
    internalReadyToShow: (state, action: PayloadAction<number>) => {
      const popupId = action.payload;
      const popup = state.popups.find((p) => p.popupId === popupId);
      if (!popup) {
        return;
      }
      popup.readyToShow = true;
    },
    internalSetPopupAnchor: (state, action: PayloadAction<SetPopupAnchorPayload>) => {
      const { popupId, anchorParent, anchor } = action.payload;

      const popup = state.popups.find((p) => p.popupId === popupId);
      if (!popup) {
        return;
      }

      if (anchorParent === "mainWindow") {
        popup.mainWindowAnchor = anchor;
      } else if (anchorParent === "meetingWindow") {
        popup.meetingWindowAnchor = anchor;
      }
    },
    internalSetOverridePopupAnchor: (state, action: PayloadAction<SetOverridePopupAnchor>) => {
      const { popupId, overrideAnchor } = action.payload;
      const popup = state.popups.find((p) => p.popupId === popupId);
      if (!popup) {
        return;
      }

      popup.overrideAnchor = overrideAnchor;
      popup.signalNonce = 1;
      if (overrideAnchor) {
        popup.doNotReposition = true;
      }
      if (overrideAnchor && state.activeAnchoredPopupId === popupId) {
        state.activeAnchoredPopupId = undefined;
      }
    },
    internalSetPopupIconKey: (state, action: PayloadAction<SetPopupIconKeyPayload>) => {
      const { popupId, anchorParent, anchorKey } = action.payload;

      const popup = state.popups.find((p) => p.popupId === popupId);
      if (!popup) {
        return;
      }

      if (anchorParent === "mainWindow") {
        popup.mainWindowPopupIconKey = anchorKey;
      } else if (anchorParent === "meetingWindow") {
        popup.meetingWindowPopupIconKey = anchorKey;
      }
    },
    internalUpdatePopupAnchor: (state, action: PayloadAction<UpdatePopupAnchorPayload>) => {
      const { popupId, anchorParent, clientRect, inMyRoom, anchorStatus } = action.payload;
      const popup = state.popups.find((popup) => popup.popupId === popupId);
      if (!popup) return;

      const anchor = anchorForParent(popup, anchorParent);
      if (clientRect !== undefined) anchor.clientRect = clientRect;
      if (inMyRoom !== undefined) anchor.inMyRoom = inMyRoom;
      if (anchorStatus !== undefined) anchor.anchorStatus = anchorStatus;
    },
    internalReplacePopupContext: (state, action: PayloadAction<PopupContextPayload>) => {
      const { popupId, ctx } = action.payload;
      const popup = state.popups.find((p) => p.popupId === popupId);
      if (popup) {
        popup.ctx = ctx;
      }
    },
    internalSignalPopup: (state, action: PayloadAction<number>) => {
      const popupId = action.payload;
      const popup = state.popups.find((p) => p.popupId === popupId);
      if (popup) {
        popup.signalNonce++;
        popup.doNotReposition = true;
      }
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.interaction;

const selectFocusedPopup = createSelector(
  selectSlice,
  InboxSelectors.selectInboxOpenWindow,
  WindowSelectors.selectFocusedWindow,
  (slice, inboxOpenWindow, focusedWindow) => {
    if (!focusedWindow) return undefined;

    const { windowType, windowId } = fromWindowKey(focusedWindow);
    if (windowType === "popup") {
      return slice.popups.find(
        (p) =>
          p.overrideAnchor?.anchorType === "popupWindow" &&
          p.overrideAnchor.popupWindowId === windowId
      );
    }

    if (inboxOpenWindow && inboxOpenWindow === windowType) {
      const inboxPopup = slice.popups.find((p) => p.overrideAnchor?.anchorType === "inbox");
      if (inboxPopup) return inboxPopup;
    }

    if (slice.activeAnchoredPopupId) {
      return slice.popups.find((p) => p.popupId === slice.activeAnchoredPopupId);
    }

    return undefined;
  }
);

export const selectors = {
  selectPossiblePopupToAnchor: defaultMemoize(
    ({
      anchorType,
      anchorParent,
      email,
      occupantId,
      chatId,
      pendingChatId,
      fixedPopupId,
      anyPopup,
      clientUuid,
    }: PopupForAnchorCriteria) =>
      createSelector(selectSlice, (slice) => {
        return slice.popups.find(
          (p) =>
            !p.overrideAnchor &&
            ((anchorParent === "mainWindow" &&
              (anchorTypeHigherPriority(anchorType, p.mainWindowAnchor.anchorType) ||
                (anchorTypeServesAsPopupIcon(anchorType) && !p.mainWindowPopupIconKey))) ||
              (anchorParent === "meetingWindow" &&
                (anchorTypeHigherPriority(anchorType, p.meetingWindowAnchor.anchorType) ||
                  (anchorTypeServesAsPopupIcon(anchorType) && !p.meetingWindowPopupIconKey)))) &&
            popupMatchesIds(p, {
              email,
              occupantId,
              chatId,
              pendingChatId,
              fixedPopupId,
              anyPopup,
              clientUuid,
            })
        );
      }),
    { equalityCheck: equal, maxSize: 250 }
  ),
  selectPopupsNeedingDockIcon: defaultMemoize((windowKey?: WindowKey) =>
    createSelector(
      selectSlice,
      (slice) => {
        const parent = anchorParentForWindow(windowKey);
        if (!parent) return [];

        return slice.popups.filter((p) => {
          if (
            !p.readyToShow ||
            p.ctx.popupType === "thisIsYou" ||
            p.overrideAnchor?.anchorType === "inbox"
          )
            return false;
          const anchor = anchorForParent(p, parent);
          if (anchorTypeServesAsPopupIcon(anchor.anchorType) && anchor.anchorStatus !== "stale")
            return false;
          else return !popupIconKeyForParent(p, parent);
        });
      },
      {
        memoizeOptions: {
          resultEqualityCheck: (a: Popup[], b: Popup[]) => {
            return equal(
              a.map((p) => p.popupId),
              b.map((p) => p.popupId)
            );
          },
        },
      }
    )
  ),
  selectOpenPopups: defaultMemoize((windowKey?: WindowKey) =>
    createSelector(
      selectSlice,
      (slice) => {
        const parent = anchorParentForWindow(windowKey);
        if (!parent) return [];
        return slice.popups.filter(
          (p) =>
            !p.overrideAnchor &&
            (!anchorTypeIsOnDock(anchorForParent(p, parent).anchorType) ||
              p.popupId === slice.activeAnchoredPopupId ||
              // If we have set `overridePreviewText`, we want to show the preview popup even if it
              // is inactive and anchored to the dock.
              p.ctx.overridePreviewText) &&
            p.readyToShow
        );
      },
      {
        memoizeOptions: {
          resultEqualityCheck: (a: Popup[], b: Popup[]) => {
            return equal(
              a.map((p) => p.popupId),
              b.map((p) => p.popupId)
            );
          },
        },
      }
    )
  ),
  selectPopupById: defaultMemoize(
    (popupId?: number) =>
      createSelector(selectSlice, (slice) =>
        popupId ? slice.popups.find((p) => p.popupId === popupId) : undefined
      ),
    { maxSize: 20 }
  ),
  selectPopupByChatId: defaultMemoize((chatId?: string) =>
    createSelector(selectSlice, (slice) =>
      chatId
        ? slice.popups.find((p) => p.ctx.popupType !== "channelThread" && p.ctx.chatId === chatId)
        : undefined
    )
  ),
  selectPopupByChatIdThreadTimestamp: defaultMemoize((chatId?: string, threadTimestamp?: number) =>
    createSelector(selectSlice, (slice) =>
      chatId
        ? slice.popups.find(
            (p) => p.ctx.chatId === chatId && threadTimestampForPopup(p.ctx) === threadTimestamp
          )
        : undefined
    )
  ),
  selectActiveAnchoredPopupId: (state: RootState) => selectSlice(state).activeAnchoredPopupId,
  selectPopupByPendingChatId: defaultMemoize((pendingChatId?: string) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      pendingChatId ? slice.popups.find((p) => p.ctx.pendingChatId === pendingChatId) : undefined
    )
  ),
  selectActiveAnchoredPopup: createSelector(selectSlice, (slice) => {
    if (!slice.activeAnchoredPopupId) return undefined;
    return slice.popups.find((p) => p.popupId === slice.activeAnchoredPopupId);
  }),
  selectFocusedPopup,
  selectFocusedPopupId: createSelector(selectFocusedPopup, (popup) => popup?.popupId),
  selectPopupsByAnchorKey: defaultMemoize(
    (anchorKey: string) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        slice.popups.filter(
          (p) =>
            p.mainWindowAnchor.anchorKey === anchorKey ||
            p.meetingWindowAnchor.anchorKey === anchorKey
        )
      ),
    { maxSize: 250 }
  ),
  selectPopupByDockAnchorKey: defaultMemoize(
    (anchorKey: string) =>
      createSelector(selectSlice, (slice) =>
        slice.popups.find(
          (p) => p.mainWindowPopupIconKey === anchorKey || p.meetingWindowPopupIconKey === anchorKey
        )
      ),
    { maxSize: 250 }
  ),
  selectAllPopups: (state: RootState) => selectSlice(state).popups,
  selectAllPopupIds: createDeepEqualSelector(selectSlice, (slice) =>
    slice.popups.map((popup) => popup.popupId)
  ),
  selectDoNotPop: (state: RootState) => selectSlice(state).doNotPop,
  selectDoNotReposition: (popupId: number | undefined) => (state: RootState) =>
    popupId
      ? selectSlice(state).popups.find((p) => p.popupId === popupId)?.doNotReposition
      : undefined,
  selectPopDockIndex: createDeepEqualSelector(selectSlice, (slice) => ({
    popDockIndex: slice.popDockIndex,
    popDockCounter: slice.popDockCounter,
  })),
  selectPopupByPopupWindowId: defaultMemoize((popupWindowId?: string) =>
    createSelector(selectSlice, (slice) =>
      popupWindowId
        ? slice.popups.find(
            (p) =>
              p.overrideAnchor?.anchorType === "popupWindow" &&
              p.overrideAnchor.popupWindowId === popupWindowId
          )
        : undefined
    )
  ),
  selectInboxPopup: createDeepEqualSelector(selectSlice, (slice) =>
    slice.popups.find((p) => p.overrideAnchor?.anchorType === "inbox")
  ),
  selectPopupWindowPopups: createDeepEqualSelector(selectSlice, (slice) =>
    slice.popups.filter((p) => p.overrideAnchor?.anchorType === "popupWindow")
  ),
};

export const InteractionSelectors = selectors;
export const InteractionActions = actions;
