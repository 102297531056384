import type { Element } from "hast"; // cspell:disable-line
import { CustomEmoji } from "../../../shared/Models/chat/CustomEmoji.js";
import { assetBaseUrl } from "../../../shared/api/http.js";
import { resolveAbsoluteRoamPhotoUrl } from "../../../shared/helpers/assets.js";
import {
  CUSTOM_EMOJI_REGEX_GLOBAL,
  parseCustomEmoji,
} from "../../../shared/helpers/customEmoji.js";
import { buildVisitReplacingHtml } from "./markup.js";

export const customEmojiToHref = (emoji: CustomEmoji) => {
  return `#${emoji.code}:${emoji.fileId}`;
};

export const hrefToCustomEmoji = (href?: string): CustomEmoji | undefined => {
  if (!href || !href.startsWith("#")) return undefined;
  const parts = href.substring(1).split(":");
  const parseResult = CustomEmoji.safeParse({
    code: parts[0],
    fileId: parts[1],
  });
  return parseResult.success ? parseResult.data : undefined;
};

export const visitReplacingCustomEmojiHtml = buildVisitReplacingHtml<CustomEmoji>(
  CUSTOM_EMOJI_REGEX_GLOBAL,
  parseCustomEmoji,
  customEmojiToHref,
  "customEmoji"
);

export const customEmojiMarkup = () => {
  return (node: Element) => visitReplacingCustomEmojiHtml(node);
};

type EmojiMartEmoji = {
  id: string;
  skins: Array<{ src: string }>;
  keywords?: string[];
};

export const customEmojiCategories = (customEmojis: CustomEmoji[]) => {
  const emojis: EmojiMartEmoji[] = [];
  for (const customEmoji of customEmojis) {
    const emoji: EmojiMartEmoji = {
      id: customEmoji.code,
      skins: [{ src: resolveAbsoluteRoamPhotoUrl(assetBaseUrl, customEmoji.fileId) }],
    };
    if (/[+_-]/.test(customEmoji.code)) {
      emoji.keywords = customEmoji.code.split(/[+_-]/).filter(Boolean);
    }
    emojis.push(emoji);
  }
  return [{ id: "custom", emojis }];
};
