/* eslint-disable no-underscore-dangle */
import { ZodType } from "zod";

export interface StorageKey<T> {
  id: string;
  type: ZodType<T>;
}

export interface StorageEvent<T> {
  key: StorageKey<T>;
  oldValue: T | undefined;
  newValue: T | undefined;
}

export type StorageListener<T> = (event: StorageEvent<T>) => void;

export interface IStorage {
  init(): void;

  getValue<T>(key: StorageKey<T>): T | undefined;
  removeValue(key: StorageKey<any>): void;
  setValue<T, V extends T>(key: StorageKey<T>, value: NonNullable<V>): void;

  addListener<T>(key: StorageKey<T>, listener: StorageListener<T>): void;
  removeListener<T>(key: StorageKey<T>, listener: StorageListener<T>): void;
}

export const createStorageKey = <T>(id: string, type: ZodType<T>): Readonly<StorageKey<T>> => {
  return Object.freeze({ id, type });
};

export const StorageListener = (key: StorageKey<any>) => {
  return (target: any, propertyKey: string) => {
    if (!Object.prototype.hasOwnProperty.call(target as object, "_storageListeners")) {
      target._storageListeners = [];
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    target._storageListeners.push({ key, propertyKey });
  };
};

export const addStorageListeners = (storage: IStorage, controller: any) => {
  for (let obj = controller; obj; obj = Object.getPrototypeOf(obj)) {
    if (Object.prototype.hasOwnProperty.call(obj as object, "_storageListeners")) {
      for (const { key, propertyKey } of obj._storageListeners ?? []) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
        storage.addListener(key, controller[propertyKey].bind(controller));
      }
    }
  }
};
