import { combineReducers } from "redux";
import { reducer as itemCreatorReducer } from "./slices/itemCreatorSlice.js";
import { reducer as itemReducer } from "./slices/itemSlice.js";
import { reducer as sackItemReducer } from "./slices/sackItemSlice.js";
import { reducer as shelfFocusReducer } from "./slices/shelfFocusSlice.js";
import { reducer as shelfItemCreatorReducer } from "./slices/shelfItemCreatorSlice.js";
import { reducer as shelfItemReducer } from "./slices/shelfItemSlice.js";
import { reducer as shelfReducer } from "./slices/shelfSlice.js";
import { reducer as shelfViewReducer } from "./slices/shelfViewSlice.js";
import { reducer as thingsReducer } from "./slices/thingsSlice.js";
import { reducer as uploadReducer } from "./slices/uploadSlice.js";

export const reducer = combineReducers({
  things: thingsReducer,
  shelf: shelfReducer,
  shelfView: shelfViewReducer,
  item: itemReducer,
  sackItem: sackItemReducer,
  shelfItem: shelfItemReducer,
  itemCreator: itemCreatorReducer,
  shelfItemCreator: shelfItemCreatorReducer,
  shelfFocus: shelfFocusReducer,
  upload: uploadReducer,
});
