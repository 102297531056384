import { ActionCreatorWithPayload, Selector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import equal from "fast-deep-equal/es6/index.js";
import { SagaIterator } from "redux-saga";
import { delay, fork, put, race, select, take } from "redux-saga/effects";
import { call, spawn } from "typed-redux-saga";
import { Location, RoomLocation } from "../../shared/Models/Location.js";
import { Room } from "../../shared/Models/Room.js";
import { sleep } from "../../shared/helpers/util.js";
import { logger } from "../../shared/infra/logger.js";
import { RpcResult } from "../../shared/messaging/types.js";
import { selectors as AccountSelectors } from "../anyworld/store/slices/accountSlice.js";
import { IRedux } from "../injection/redux/IRedux.js";
import { ConnectionStatus } from "../messaging/interfaces/IMessaging.js";
import {
  actions as RoomActions,
  selectors as RoomSelectors,
} from "../section/store/slices/roomSlice.js";
import { RootState } from "./reducers.js";

export const TIMEOUT_FOR_RENDER_STATES_MS = 30_000;

export type WantHave<T> = {
  want?: T;
  have?: T;
};

/**
 * Updates internal state 'want' and/or 'have' value according to wantHave
 *
 * @param state current system state
 * @param name the name of the parameter to update
 * @param wantHave contains a want and/or a have value
 */
export const updateInternal = <T>(state: any, name: string, wantHave: WantHave<T>) => {
  if (wantHave.want !== undefined) {
    state["internalWant" + name] = wantHave.want;
  }
  if (wantHave.have !== undefined) {
    state["internalHave" + name] = wantHave.have;
  }
};

export const calculateBackoffMillis: (attemptCount: number) => number = (attemptCount) => {
  const baseRetryMillis = 200;
  const maxRetryMillis = 60_000;

  // Basic exponential backoff algorithm
  const exponentialBackoff = Math.min(maxRetryMillis, Math.pow(2, attemptCount) * baseRetryMillis);

  // Jitter to avoid correlated retries overloading the server with simultaneous calls.
  // Averages out to the same as exponential backoff, but with jitter mixed in.
  const fullJitterBackoff = Math.random() * 2 * exponentialBackoff;

  return Math.round(fullJitterBackoff);
};

// Declaring explicitly because seem to be unable to take typeof a generic function to get
// a generic type: https://github.com/microsoft/TypeScript/issues/37181
export type SetInternalWant<T> = (
  internalWantSelector: Selector<RootState, T>,
  wantValue: T,
  setInternalAction: ActionCreatorWithPayload<WantHave<T>, string>
) => SagaIterator<any>;

/**
 * Call setInternalAction if internal want value does not equal wantValue
 *
 * @param internalWantSelector select internal want from RootState
 * @param wantValue desired value for 'want'
 * @param setInternalAction action to set value of 'want'
 */
export function* setInternalWant<T>(
  internalWantSelector: Selector<RootState, T>,
  wantValue: T,
  setInternalAction: ActionCreatorWithPayload<WantHave<T>, string>
): SagaIterator {
  const currentInternalWant = yield select(internalWantSelector);
  if (!equal(currentInternalWant, wantValue)) {
    yield put(setInternalAction({ want: wantValue }));
  }
}

export type SetInternal<T> = (
  payload: WantHave<T>,
  internalWantSelector: Selector<RootState, T>,
  internalHaveSelector: Selector<RootState, T>,
  setTimer: ActionCreatorWithPayload<string | undefined, string>,
  ensureHaveWantedMatch: (want: T, have: T) => any,
  setRenderMaybeWithDelay?: () => any
) => SagaIterator<any>;

/**
 * Call (fork) ensureHaveWantedMatch if internal want and internal have
 * are not equal under equal(*,*). Optionally call setTimer
 * if passed, after success.
 *
 * @param internalWantSelector selects internal want from RootState
 * @param internalHaveSelector selects internal have from RootState
 * @param setTimer called after want == have, if passed
 * @param ensureHaveWantedMatch called if want != have
 */
export function* setInternal<T>(
  payload: WantHave<T>,
  internalWantSelector: Selector<RootState, T>,
  internalHaveSelector: Selector<RootState, T>,
  setTimer: ActionCreatorWithPayload<string | undefined, string> | undefined,
  ensureHaveWantedMatch: (want: T, have: T) => any,
  setRenderMaybeWithDelay?: () => any
): SagaIterator {
  const want: T = yield select(internalWantSelector);
  const have: T = yield select(internalHaveSelector);

  if (setTimer && equal(want, have)) {
    yield* call(setTimer, undefined);
  }
  if (!equal(want, have)) {
    yield fork(ensureHaveWantedMatch, want, have);
  }
  if (payload.have && setRenderMaybeWithDelay) {
    yield* spawn(setRenderMaybeWithDelay);
  }
}

/**
 *
 * repeatedly send getToFalseMsg or getToTrueMsg until have == want == true
 *
 */
export function* ensureWantHaveBooleanMiddleRpc(
  errorMessage: string,
  want: boolean,
  have: boolean,
  getToFalse: () => Promise<RpcResult>,
  getToTrue: () => Promise<RpcResult>,
  setInternalAction: ActionCreatorWithPayload<WantHave<boolean>, string>,
  setTimer: ActionCreatorWithPayload<string | undefined, string>
): SagaIterator {
  if (!want && have) {
    let attempt = 1;
    while (true) {
      const r = yield* call(getToFalse);
      if (r.status === "success") {
        yield put(setInternalAction({ have: false }));
        return;
      }
      if (r.status === "error" && r.errorDetail?.includes("not connected")) {
        yield delay(300);
        continue;
      }
      yield delay(calculateBackoffMillis(attempt));
      attempt++;
    }
  } else if (want && !have) {
    let attempt = 1;
    // will end when this saga is canceled due to updating want and have state
    // either after connection or canceling the desire to connect
    while (true) {
      try {
        const messagingStatus: ConnectionStatus = yield select(
          AccountSelectors.selectInternalHaveMessagingStatus
        );
        if (messagingStatus !== "connected") {
          yield delay(1000);
          continue;
        }
        const r = yield* call(getToTrue);
        if (r?.status === "success") {
          yield put(setInternalAction({ have: true }));
          return;
        } else {
          logger.warn({ response: r }, errorMessage);
        }
      } catch (err) {
        logger.warn({ err }, errorMessage);
      }
      const delayVal = calculateBackoffMillis(attempt);
      const nextRetryTime = dayjs().add(delayVal, "milliseconds").format();
      yield put(setTimer(nextRetryTime));
      yield delay(delayVal);
      attempt++;
    }
  }
}

export function* waitForRoomInfo(roomId: number, floorId: number): SagaIterator<Room> {
  let room = yield select(RoomSelectors.selectById(roomId));
  const startTime = Date.now();
  let timeSinceWarning = Date.now();
  while (!room) {
    const currentTime = Date.now();
    if (currentTime - 2000 > timeSinceWarning) {
      logger.warn(
        `waitForRoomInfo: don't know what we need to about roomId ${roomId}. waiting until set, started at ${startTime}, current time ${currentTime}.`
      );
      yield put(RoomActions.requestFloor(floorId));
      timeSinceWarning = Date.now();
    }
    yield race([take([RoomActions.setAllFloorRooms, RoomActions.setRoom]), delay(200)]);
    room = yield select(RoomSelectors.selectById(roomId));
  }
  return room;
}

export const waitForRoomInfoAsync = async (roomId: number, floorId: number, redux: IRedux) => {
  let room = RoomSelectors.selectById(roomId)(redux.getState());
  const startTime = Date.now();
  let timeSinceWarning = Date.now();
  while (!room) {
    const currentTime = Date.now();
    if (currentTime - 2000 > timeSinceWarning) {
      logger.warn(
        `waitForRoomInfoAsync: don't know what we need to about roomId ${roomId}. waiting until set, started at ${startTime}, current time ${currentTime}.`
      );
      redux.dispatch(RoomActions.requestFloor(floorId));
      timeSinceWarning = Date.now();
    }
    await sleep(100);
    room = RoomSelectors.selectById(roomId)(redux.getState());
  }
  return room;
};

export function* maybeConvertRoomToAudience(location: RoomLocation): SagaIterator<Location> {
  // Get the room info so we can check if it's an auditorium
  const room = yield* call(waitForRoomInfo, location.roomId, location.section.floorId);

  if (room.roomType === "Auditorium") {
    return {
      kind: "AudienceLocation",
      occupantId: location.occupantId,
      section: {
        kind: "audience",
        floorId: location.section.floorId,
        roomId: location.roomId,
        sectionNumber: 0,
      },
      positionNumber: 0,
    };
  }
  return location;
}
