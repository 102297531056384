import { compileShader, createProgram, glsl } from "../webglHelper.js";

const vertexShaderSource = glsl`#version 300 es
  in vec2 a_position;
  in vec2 a_texCoord;

  out vec2 v_texCoord;

  void main() {
    gl_Position = vec4(a_position, 0, 1);
    v_texCoord = a_texCoord;
  }
`;

const fragmentShaderSrc = glsl`#version 300 es
  precision mediump float;

  uniform sampler2D uSourceSampler;
  uniform vec2 uInverseTextureSize;

  in vec2 v_texCoord;

  out vec4 fragColor;

  vec4 offsetLookup(float xOff, float yOff) {
    return texture(
      uSourceSampler, 
      vec2(
        v_texCoord.x + xOff * uInverseTextureSize.x, 
        v_texCoord.y + yOff * uInverseTextureSize.y
      )
    );
  }

  void main(void) {
    vec4 frameColor = offsetLookup(-4.0, 0.0) * 0.05;
    frameColor += offsetLookup(-3.0, 0.0) * 0.09;
    frameColor += offsetLookup(-2.0, 0.0) * 0.12;
    frameColor += offsetLookup(-1.0, 0.0) * 0.15;
    frameColor += offsetLookup(0.0, 0.0) * 0.16;
    frameColor += offsetLookup(1.0, 0.0) * 0.15;
    frameColor += offsetLookup(2.0, 0.0) * 0.12;
    frameColor += offsetLookup(3.0, 0.0) * 0.09;
    frameColor += offsetLookup(4.0, 0.0) * 0.05;
    fragColor = frameColor;
  }
  `;

export interface RenderSourceStage {
  render(): void;
}

const kernel = [0.045, 0.122, 0.045, 0.122, 0.332, 0.122, 0.045, 0.122, 0.045];

/* Blurs the texture in unit texUnit
 *
 *
 */
export const buildBlurTextureStage = (
  gl: WebGL2RenderingContext,
  positionBuffer: WebGLBuffer,
  texCoordBuffer: WebGLBuffer,
  texUnit: number,
  outputTexture: WebGLTexture
): RenderSourceStage => {
  const { width: outputWidth, height: outputHeight } = gl.canvas;

  const vertexShader = compileShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = compileShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSrc);
  const program = createProgram(gl, vertexShader, fragmentShader);
  gl.useProgram(program);

  const uSourceLocation = gl.getUniformLocation(program, "uSourceSampler");
  const uSizeLocation = gl.getUniformLocation(program, "uInverseTextureSize");
  const texCoordAttributeLocation = gl.getAttribLocation(program, "a_texCoord");
  const positionAttributeLocation = gl.getAttribLocation(program, "a_position");

  const vao = gl.createVertexArray();
  gl.bindVertexArray(vao);

  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.vertexAttribPointer(positionAttributeLocation, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(positionAttributeLocation);

  gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
  gl.vertexAttribPointer(texCoordAttributeLocation, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(texCoordAttributeLocation);

  gl.uniform1i(uSourceLocation, texUnit);
  gl.uniform2fv(uSizeLocation, new Float32Array([1 / gl.canvas.width, 1 / gl.canvas.height]));
  gl.bindVertexArray(null);

  const frameBuffer = gl.createFramebuffer();
  gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer);
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, outputTexture, 0);

  const render = () => {
    gl.useProgram(program);

    gl.viewport(0, 0, outputWidth, outputHeight);

    gl.bindVertexArray(vao);
    gl.uniform1i(uSourceLocation, texUnit);
    gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer);
    gl.clearColor(1.0, 1.0, 1.0, 1.0);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  };

  const cleanUp = () => {
    gl.deleteShader(vertexShader);
    gl.deleteShader(fragmentShader);
  };

  return { render };
};
