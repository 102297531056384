import { z } from "zod";
import { numberId, stringDate, stringId } from "./zodTypes.js";

export const KnockResult = z.object({
  status: z.enum([
    // Knock recipient accepts the knock.
    "comeIn",
    // Knock recipient declines the knock.
    "notAvailable",
    // Knocker cancels knock request. May be indirect (e.g. by going to a different room).
    "canceled",
    // Knock recipient did not respond in time.
    "timeout",
    // Knocker left the section.
    "left",
    // Knocker enters the room that they knocked on, before anyone responds.
    "entered",
    // Everyone in the room left.
    "roomEmpty",
  ]),
  answerFromOccupantId: stringId().optional(),
  answerFromPersonId: numberId().optional(),
  answerFromName: z.string().optional(),
  answerFromEmail: z.string().optional(),
  answerMessage: z.string().optional(),
});
export type KnockResult = z.infer<typeof KnockResult>;

export const Knock = z.object({
  floorId: numberId(),
  roomId: numberId(),
  id: stringId(),
  byOccupantId: stringId(),
  knockerName: z.string(),
  knockerImageUrl: z.string().url().optional(),
  knockTime: stringDate().optional(),
  result: KnockResult.optional(),
});
export type Knock = z.infer<typeof Knock>;
