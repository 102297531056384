body {
  color: rgb(255, 255, 255);
  cursor: inherit;
  font-family:
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    "Noto Sans",
    "Liberation Sans",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  margin: 0;
  user-select: none !important;
}

/* Allow user to select text that they type */
input,
textarea {
  user-select: text !important;
}

* {
  box-sizing: border-box;
}

/* Ensure that root creates its own stacking context, so portal elements appear above */
body > #root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

/* Override built-in focus styling */
:focus-visible {
  outline-color: #ffffff;
}
