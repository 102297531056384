/* eslint-disable no-restricted-imports */
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { memoize } from "lodash";
import { MessageReactions, ReactionData } from "../../../../shared/Models/ChatMessageReactions.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";
import { ChatSelectors } from "./chatSlice.js";

const previewedNames = 10;

const messageKey = (
  chatId: string,
  threadTimestamp: number | undefined,
  messageTimestamp: number
) => `${chatId}_${threadTimestamp || "u"}_${messageTimestamp}`;

const reactionAdapter = createEntityAdapter<MessageReactions>({
  selectId: (r) => messageKey(r.chatId, r.threadTimestamp, r.messageTimestamp),
});

export interface LocalReactions {
  [messageKey: string]: {
    [reactionCode: string]: {
      action: "add" | "remove";
      emojiText: string;
    };
  };
}

export interface ReactionPayload {
  windowKey: WindowKey;
  chatId: string;
  threadTimestamp: number | undefined;
  messageTimestamp: number;
  reactionData: ReactionData;
}

export type ReactionNamePreview = string | { isMe: true };

export interface DisplayableReactions {
  reactionData: ReactionData;
  iReacted: boolean;
  reactorCount: number;
  namePreview: ReactionNamePreview[];
}

const slice = createSlice({
  name: "messageReaction",
  initialState: {
    reactions: reactionAdapter.getInitialState(),
    localReactions: {} as LocalReactions,
  },
  reducers: {
    addReaction: (state, action: PayloadAction<ReactionPayload>) => {
      const { chatId, threadTimestamp, messageTimestamp, reactionData } = action.payload;
      const key = messageKey(chatId, threadTimestamp, messageTimestamp);
      let reaction = state.localReactions[key];
      if (!reaction) {
        state.localReactions[key] = reaction = {};
      }
      reaction[reactionData.code] = {
        action: "add",
        emojiText: reactionData.emojiText,
      };
    },
    removeReaction: (state, action: PayloadAction<ReactionPayload>) => {
      const { chatId, threadTimestamp, messageTimestamp, reactionData } = action.payload;
      const key = messageKey(chatId, threadTimestamp, messageTimestamp);
      let reaction = state.localReactions[key];
      if (!reaction) {
        state.localReactions[key] = reaction = {};
      }
      reaction[reactionData.code] = {
        action: "remove",
        emojiText: reactionData.emojiText,
      };
    },
    reactionFailed: (state, action: PayloadAction<ReactionPayload>) => {
      const { chatId, threadTimestamp, messageTimestamp, reactionData } = action.payload;
      const key = messageKey(chatId, threadTimestamp, messageTimestamp);
      const keyReactions = state.localReactions[key];
      if (keyReactions) {
        delete keyReactions[reactionData.code];
      }
    },

    // From server
    upsertReactions: (state, action: PayloadAction<MessageReactions[]>) => {
      reactionAdapter.upsertMany(state.reactions, action.payload);
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.messageReaction;
const reactionSelectors = reactionAdapter.getSelectors();
export const selectors = {
  selectReactionPreview: memoize(
    (
      chatId: string | undefined,
      threadTimestamp: number | undefined,
      messageTimestamp: number | undefined
    ) =>
      createDeepEqualSelector(
        selectSlice,
        ChatSelectors.selectSendFromAddresses,
        (slice, sendFromAddresses) => {
          if (!chatId || !messageTimestamp) return [];
          const key = messageKey(chatId, threadTimestamp, messageTimestamp);
          const result = new Array<DisplayableReactions>();
          const messageReactions = reactionSelectors.selectById(slice.reactions, key);
          for (const reaction of messageReactions?.reactions ?? []) {
            let iReacted = false;
            const namePreview = new Array<ReactionNamePreview>();
            for (const reactor of reaction.reactors) {
              if ((sendFromAddresses ?? []).some((a) => a.addressId === reactor.addressId)) {
                namePreview.push({ isMe: true });
                iReacted = true;
              } else if (namePreview.length < previewedNames && reactor.displayName) {
                namePreview.push(reactor.displayName);
              }
            }

            result.push({
              reactionData: reaction.reactionData,
              iReacted,
              reactorCount: reaction.reactors.length,
              namePreview,
            });
          }

          // Apply pending updates
          for (const [reactionCode, localReaction] of Object.entries(
            slice.localReactions[key] ?? {}
          )) {
            const existing = result.find((r) => r.reactionData.code === reactionCode);
            if (existing) {
              if (existing.iReacted && localReaction.action === "remove") {
                existing.iReacted = false;
                existing.namePreview = existing.namePreview.filter((p) => typeof p === "string");
                existing.reactorCount -= 1;
              } else if (!existing.iReacted && localReaction.action === "add") {
                existing.iReacted = true;
                existing.namePreview.push({ isMe: true });
                existing.reactorCount += 1;
              }
            } else if (localReaction.action === "add") {
              result.push({
                reactionData: {
                  type: "emoji",
                  code: reactionCode,
                  emojiText: localReaction.emojiText,
                },
                iReacted: true,
                reactorCount: 1,
                namePreview: [{ isMe: true }],
              });
            }
          }

          return result.filter((r) => r.reactorCount > 0);
        }
      ),
    (chatId?: string, threadTimestamp?: number, messageTimestamp?: number) =>
      messageKey(chatId ?? "undefined", threadTimestamp, messageTimestamp ?? -1)
  ),
};

export const MessageReactionActions = actions;
export const MessageReactionSelectors = selectors;
