import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { defaultMemoize } from "reselect";
import {
  crpc_calSet_GetMicrosoftAdminConsentConfig,
  crpc_calSet_ResetCalendarSettings,
} from "../../../../shared/CalendarMessages/ClientRpcCalendarSettings.js";
import {
  CalendarProviderId,
  CalendarSettings,
  CalendarSettingsHistory,
} from "../../../../shared/Models/calendar/settings/CalendarSettings.js";
import { CalendarRoomInfo } from "../../../../shared/Models/CalendarRoomInfo.js";
import { ExternalBuilding, ExternalRoom } from "../../../../shared/Models/ExternalRoom.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const settingsAdapter = createEntityAdapter<CalendarSettings>({
  selectId: (settings) => settings.accountId,
});

export interface SettingsHistoryPayload {
  roamId: number;
  history: CalendarSettingsHistory[];
}

export interface ActivateCalendarPayload {
  roamId: number;
  providerId: CalendarProviderId;
}

export interface DeactivateCalendarPayload {
  roamId: number;
  deleteRemoteResources?: boolean;
}

export interface RequestRoomsPayload {
  roamId: number;
  providerId: CalendarProviderId;
}

export interface RoomIdPayload {
  roomId: number;
  providerId: CalendarProviderId;
}

export interface SetRoomsPayload {
  roamId: number;
  providerId: CalendarProviderId;
  building?: ExternalBuilding;
  rooms: ExternalRoom[];
  roomInfo: CalendarRoomInfo[];
}

export interface RequestByFloorPayload {
  roamId: number;
  floorId: number;
}

export interface RoomsByFloorPayload {
  floorId: number;
  rooms: ExternalRoom[];
}

export interface ToggleRoomPayload {
  roamId: number;
  providerId: CalendarProviderId;
  room: CalendarRoomInfo;
}

export interface SetTeleRoomKnownEmailPayload {
  teleRoomId: string;
  externalEmail: string | undefined;
}

export interface SetTeleRoomEmailPayload {
  teleRoomId: string;
  accountId: number;
  providerId: CalendarProviderId;
  externalEmail: string;
  name: string;
}

export interface RemoveTeleRoomEmailPayload {
  teleRoomId: string;
}

export interface GetTeleRoomEmailPayload {
  teleRoomId: string;
}

export interface SetTeleRoomsPayload {
  teleRooms: Array<{
    providerId: CalendarProviderId;
    roamId: number;
    teleRoomIds: string[];
  }>;
  teleRoomIdsFlat: string[];
}

const buildingKey = (providerId: CalendarProviderId, roamId: number) => `${providerId}-${roamId}`;

const slice = createSlice({
  name: "settings",
  initialState: {
    settings: settingsAdapter.getInitialState(),
    settingsHistory: undefined as CalendarSettingsHistory[] | undefined,
    settingsHistoryRoamId: undefined as number | undefined,
    buildings: {} as { [buildingKey: string]: ExternalBuilding },
    rooms: {} as { [buildingKey: string]: ExternalRoom[] },
    roomInfo: {} as { [buildingKey: string]: CalendarRoomInfo[] },

    // used when actually in Roam, requested by floor. everything above is used for settings.
    roomsByFloor: {} as { [floorId: number]: ExternalRoom[] },

    teleRoomEmails: {} as { [teleRoomId: string]: string | undefined },
    teleRooms: {} as { [buildingKey: string]: string[] },
    teleRoomsFlat: undefined as string[] | undefined,

    microsoftAdminConsentUrls: {} as { [accountId: number]: string },
  },
  reducers: {
    requestSettingsByRoamId: (state, action: PayloadAction<number>) => {
      state.settingsHistoryRoamId = undefined;
      state.settingsHistory = undefined;
    },
    unsubscribeFromSettingsByRoamId: (state, action: PayloadAction<number>) => {},

    activateCalendar: (state, action: PayloadAction<ActivateCalendarPayload>) => {},
    deactivateCalendar: (state, action: PayloadAction<DeactivateCalendarPayload>) => {},
    updateSettings: (state, action: PayloadAction<CalendarSettings>) => {},
    resetSettings: (
      state,
      action: PayloadAction<Omit<crpc_calSet_ResetCalendarSettings, "kind">>
    ) => {},

    setSettings: (state, action: PayloadAction<CalendarSettings>) => {
      settingsAdapter.setOne(state.settings, action.payload);
    },
    setSettingsHistory: (state, action: PayloadAction<SettingsHistoryPayload>) => {
      const { roamId, history } = action.payload;
      state.settingsHistoryRoamId = roamId;
      state.settingsHistory = history;
    },

    requestExternalRooms: (state, action: PayloadAction<RequestRoomsPayload>) => {},
    setRoomsByBuilding: (state, action: PayloadAction<SetRoomsPayload>) => {
      const { roamId, providerId, building, rooms, roomInfo } = action.payload;
      const key = buildingKey(providerId, roamId);
      if (building) {
        state.buildings[key] = building;
      } else {
        delete state.buildings[key];
      }
      state.rooms[key] = rooms;
      state.roomInfo[key] = roomInfo;
    },

    requestExternalRoomsByFloor: (state, action: PayloadAction<RequestByFloorPayload>) => {},
    setRoomsByFloor: (state, action: PayloadAction<RoomsByFloorPayload>) => {
      const { floorId, rooms } = action.payload;
      state.roomsByFloor[floorId] = rooms;
    },
    toggleRoomSyncStatus: (state, action: PayloadAction<ToggleRoomPayload>) => {
      const { providerId, roamId, room } = action.payload;

      if (room.syncStatus === "activating" || room.syncStatus === "deactivating") {
        // ignore rooms undergoing change
        return;
      }

      const pendingStatus = room.syncStatus === "active" ? "deactivating" : "activating";

      const key = buildingKey(providerId, roamId);
      const roomInfo = state.roomInfo[key];
      if (!roomInfo) return;
      state.roomInfo[key] = roomInfo.map((r) => {
        if (r.id === room.id) {
          return {
            ...room,
            syncStatus: pendingStatus,
          };
        }
        return r;
      });

      // TODO Trigger saga
    },
    requestTeleRooms: (state, action: PayloadAction) => {},
    setTeleRooms: (state, action: PayloadAction<SetTeleRoomsPayload>) => {
      const { teleRoomIdsFlat } = action.payload;
      state.teleRoomsFlat = teleRoomIdsFlat;
      for (const roamTeleRooms of action.payload.teleRooms) {
        const { providerId, roamId, teleRoomIds } = roamTeleRooms;
        const key = buildingKey(providerId, roamId);
        state.teleRooms[key] = teleRoomIds;
      }
    },
    setTeleRoomKnownEmail: (state, action: PayloadAction<SetTeleRoomKnownEmailPayload>) => {
      const { teleRoomId, externalEmail } = action.payload;
      state.teleRoomEmails[teleRoomId] = externalEmail;
    },
    setTeleRoomEmail: (state, action: PayloadAction<SetTeleRoomEmailPayload>) => {},
    removeTeleRoomEmail: (state, action: PayloadAction<RemoveTeleRoomEmailPayload>) => {
      const { teleRoomId } = action.payload;
      state.teleRoomEmails[teleRoomId] = undefined;
    },
    getTeleRoomEmail: (state, action: PayloadAction<GetTeleRoomEmailPayload>) => {},

    requestMicrosoftConsentUrl: (
      state,
      action: PayloadAction<Omit<crpc_calSet_GetMicrosoftAdminConsentConfig, "kind">>
    ) => {},
    setAccountMicrosoftAdminConsentUrl: (
      state,
      action: PayloadAction<{ accountId: number; url: string }>
    ) => {
      const { accountId, url } = action.payload;
      state.microsoftAdminConsentUrls[accountId] = url;
    },
  },
});

export const { actions, reducer } = slice;

const adapterSelectors = settingsAdapter.getSelectors();
const selectSlice = (state: RootState) => state.calendar.settings;
export const selectors = {
  selectSettingsByAccountId: (accountId: number) => (state: RootState) =>
    adapterSelectors.selectById(selectSlice(state).settings, accountId),
  selectSettingsByRoamId: (roamId: number) => (state: RootState) =>
    adapterSelectors
      .selectAll(selectSlice(state).settings)
      .find((setting) => roamId in setting.roamConfigs),
  selectSettingsHistory: (roamId: number) => (state: RootState) =>
    selectSlice(state).settingsHistoryRoamId === roamId
      ? selectSlice(state).settingsHistory
      : undefined,
  selectLatestSettingsHistory:
    (roamId: number) =>
    (state: RootState): CalendarSettingsHistory | undefined => {
      const slice = state.calendar.settings;
      const history = slice.settingsHistoryRoamId === roamId ? slice.settingsHistory : undefined;
      if (!history) {
        return undefined;
      }
      return _.maxBy(history, (item) => item.timestamp);
    },
  selectExternalRooms: defaultMemoize((providerId: CalendarProviderId, roamId: number) =>
    createSelector(selectSlice, (slice) => slice.rooms[buildingKey(providerId, roamId)])
  ),
  selectRoomInfo: defaultMemoize((providerId: CalendarProviderId, roamId: number) =>
    createSelector(selectSlice, (slice) => slice.roomInfo[buildingKey(providerId, roamId)])
  ),
  selectTeleRoomEmail: defaultMemoize((teleRoomId: string) =>
    createSelector(selectSlice, (slice) => slice.teleRoomEmails[teleRoomId])
  ),
  selectTeleRooms: defaultMemoize((providerId?: CalendarProviderId, roamId?: number) =>
    createSelector(selectSlice, (slice) => {
      if (!providerId || !roamId) {
        return undefined;
      }
      return slice.teleRooms[buildingKey(providerId, roamId)];
    })
  ),
  selectHaveTeleRoom: defaultMemoize((teleRoomId: string) =>
    createSelector(selectSlice, (slice) => {
      const { teleRoomsFlat } = slice;
      return teleRoomsFlat?.find((id) => id === teleRoomId);
    })
  ),

  selectExternalRoomCount: defaultMemoize((providerId: CalendarProviderId, roamId: number) =>
    createSelector(selectSlice, (slice) => slice.rooms[buildingKey(providerId, roamId)]?.length)
  ),
  selectExternalBuilding: defaultMemoize((providerId: CalendarProviderId, roamId: number) =>
    createSelector(selectSlice, (slice) => slice.buildings[buildingKey(providerId, roamId)])
  ),

  selectByFloorAndRoom: defaultMemoize(
    (floorId?: number, roomId?: number) =>
      createSelector(selectSlice, (slice) =>
        floorId && roomId
          ? slice.roomsByFloor[floorId]?.find((r) => r.roomId === roomId)
          : undefined
      ),
    {
      maxSize: 150,
    }
  ),

  selectMicrosoftAdminConsentUrl:
    (accountId: number) =>
    (state: RootState): string | undefined =>
      selectSlice(state).microsoftAdminConsentUrls[accountId],
};
