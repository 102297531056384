import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StepCompletionCriterion } from "../../../../shared/Models/AutoboardingInstructions.js";
import { createSelector } from "../../../helpers/redux.js";
import { updateInternal, WantHave } from "../../../store/clientConnectionHelpers.js";
import { RootState } from "../../../store/reducers.js";

const slice = createSlice({
  name: "autoboarding",
  initialState: {
    // For keeping track of events that wouldn't otherwise affect the root state, e.g., opening a
    // dropdown menu.
    videoSettingsDropdownOpen: false,

    // For connecting to autoboarding server (see docs/clientConnections.md).
    wantAutoboardingConnected: false,
    internalWantAutoboardingConnected: false,
    internalHaveAutoboardingConnected: false,

    autoboardingConnectionRetryTime: undefined as string | undefined,
  },
  reducers: {
    initAutoboarding: (state, action: PayloadAction) => {},

    completeStep: (state, action: PayloadAction<StepCompletionCriterion[]>) => {},

    potentiallyGoToRoamU: (state, action: PayloadAction<boolean | undefined>) => {},

    // For keeping track of events that wouldn't otherwise affect the root state, e.g., opening a
    // dropdown menu.
    setVideoSettingsDropdownOpen: (state, action: PayloadAction<boolean>) => {
      state.videoSettingsDropdownOpen = action.payload;
    },

    // For connecting to autoboarding server (see docs/clientConnections.md).
    setWantAutoboardingConnected: (state, action: PayloadAction<boolean>) => {
      state.wantAutoboardingConnected = action.payload;
    },
    setInternalAutoboardingConnected: (state, action: PayloadAction<WantHave<boolean>>) => {
      updateInternal(state, "AutoboardingConnected", action.payload);
    },
    setAutoboardingConnectionRetryTime: (state, action: PayloadAction<string | undefined>) => {
      state.autoboardingConnectionRetryTime = action.payload;
    },
  },
});
export const { actions, reducer } = slice;
export const AutoboardingActions = actions;

const selectSlice = (state: RootState) => state.autoboarding.autoboarding;
// TODO: visit memoization to make sure it's being done right.
export const selectors = {
  // For keeping track of events that wouldn't otherwise affect the root state, e.g., opening a
  // dropdown menu.
  selectVideoSettingsDropdownOpen: createSelector(
    selectSlice,
    (slice) => slice.videoSettingsDropdownOpen
  ),

  // For connecting to autoboarding server (see docs/clientConnections.md).
  selectWantAutoboardingConnected: createSelector(
    selectSlice,
    (slice) => slice.wantAutoboardingConnected
  ),
  selectInternalWantAutoboardingConnected: createSelector(
    selectSlice,
    (slice) => slice.internalWantAutoboardingConnected
  ),
  selectInternalHaveAutoboardingConnected: createSelector(
    selectSlice,
    (slice) => slice.internalHaveAutoboardingConnected
  ),
  selectAutoboardingConnectionRetryTime: createSelector(
    selectSlice,
    (slice) => slice.autoboardingConnectionRetryTime
  ),
};
