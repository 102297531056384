// https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
type ErrorWithMessage = {
  message: string;
};

type ErrorWithStack = {
  stack: string;
  cause?: ErrorWithStack;
};

export const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
};

export const isErrorWithStack = (error: unknown): error is ErrorWithStack => {
  return (
    typeof error === "object" &&
    error !== null &&
    "stack" in error &&
    typeof (error as Record<string, unknown>).stack === "string"
  );
};

export const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error converting the maybeError
    // into a string like with circular references for example.
    return new Error(String(maybeError));
  }
};

export const getErrorMessage = (error: unknown): string => {
  const errorObj = toErrorWithMessage(error);
  if (isErrorWithStack(errorObj)) {
    return errorObj.stack;
  } else {
    return errorObj.message;
  }
};

export const getErrorStack = (maybeError: unknown): string | undefined => {
  if (isErrorWithStack(maybeError)) {
    let stack = maybeError.stack;

    if (isErrorWithStack(maybeError.cause)) {
      stack += ` \n via Cause => ${getErrorStack(maybeError.cause)}`;
    }

    return stack;
  } else {
    return undefined;
  }
};
