import { z } from "zod";
import { BotChatTarget, EmailChatTarget, StandardGroupChatTarget } from "../Models/ChatAddress.js";
import { uuid } from "../Models/zodTypes.js";
import { UnreachableError } from "./UnreachableError.js";
import { isUUID } from "./assets.js";

// User example:
// Match: <!<@nico@@wonder.inc|||Nico Schlumprecht>!>
// Group 1: @
// Group 2: nico@@wonder.inc
// Group 3: Nico Schlumprecht

// Group example:
// Match: <!<#dbefb329-b09c-4133-a8dc-8ffa05cd60fa|||Computer People>!>
// Group 1: #
// Group 2: dbefb329-b09c-4133-a8dc-8ffa05cd60fa
// Group 3: Computer People

// All (everyone in chat) example:
// Match: <!<%*|||all>!>
// Group 1: %
// Group 2: * (unused)
// Group 3: all

// Keep in sync with mentionRegex in ios/Sources/RoamModelsExtras/Chat/Mention.swift
// Keep in sync with mentionRegex in roam/chatsearch/scripts/bulkindex/bulkindex.go
// Keep in sync with mentionRegex in roam/client/chat/helpers/lexical.ts
export const mentionRegex = /<!<([%&@])(.+?)\|{3}(.+?)>!>/g;

export const UserMention = z.object({
  mentionType: z.literal("user"),
  email: z.string().min(1),
  displayName: z.string().min(1),
});
export type UserMention = z.infer<typeof UserMention>;

export const GroupMention = z.object({
  mentionType: z.literal("group"),
  addressId: uuid(),
  displayName: z.string().min(1),
});
export type GroupMention = z.infer<typeof GroupMention>;

export const BotMention = z.object({
  mentionType: z.literal("bot"),
  roamId: z.number(),
  integrationId: z.string(),
  botCode: z.string(),
  displayName: z.string(),
});
export type BotMention = z.infer<typeof BotMention>;

export const AllMention = z.object({
  mentionType: z.literal("all"),
});
export type AllMention = z.infer<typeof AllMention>;

export const SummaryMention = z.object({
  mentionType: z.literal("summary"),
});
export type SummaryMention = z.infer<typeof SummaryMention>;

export const Mention = z.discriminatedUnion("mentionType", [
  UserMention,
  BotMention,
  GroupMention,
  AllMention,
  SummaryMention,
]);
export type Mention = z.infer<typeof Mention>;

export type AllTarget = {
  targetType: "all";
  icon: "IconPerson3";
  displayName: "all";
  displayDetail: "Notify everyone in this chat";
};

export type SummaryTarget = {
  targetType: "summary";
  icon: "IconMagicQuillAccent";
  displayName: "MagicMinutes";
  displayDetail: string;
};

export type MentionTarget =
  | BotChatTarget
  | EmailChatTarget
  | StandardGroupChatTarget
  | AllTarget
  | SummaryTarget;

export const targetToMention = (target: MentionTarget): Mention => {
  switch (target.targetType) {
    case "bot":
      return {
        mentionType: "bot",
        roamId: target.roamId,
        integrationId: target.integrationId,
        botCode: target.botCode,
        displayName: target.displayName ?? target.botCode,
      };
    case "email":
      return {
        mentionType: "user",
        email: target.email,
        displayName: target.displayName ?? "Unknown",
      };
    case "standardGroup":
      return {
        mentionType: "group",
        addressId: target.addressId,
        displayName: target.displayName ?? "Unknown",
      };
    case "all":
      return {
        mentionType: "all",
      };
    case "summary":
      return {
        mentionType: "summary",
      };
    default:
      throw new UnreachableError(target);
  }
};

export const mentionDisplayName = (mention: Mention): string => {
  switch (mention.mentionType) {
    case "user":
    case "group":
    case "bot":
      return mention.displayName;
    case "all":
      return "all";
    case "summary":
      return "MagicMinutes";
    default:
      throw new UnreachableError(mention);
  }
};

export const formatMention = (mention: Mention): string => {
  switch (mention.mentionType) {
    case "user":
      return `<!<@${mention.email.replace("@", "@@")}|||${mention.displayName}>!>`;
    case "group":
      return `<!<&${mention.addressId}|||${mention.displayName}>!>`;
    case "all":
      return "<!<%*|||all>!>";
    case "summary":
      return "<!<%summary|||MagicMinutes>!>";
    case "bot":
      return `<!<%${mention.roamId}.${mention.integrationId}.${mention.botCode}|||${mention.displayName}>!>`;
    default:
      throw new UnreachableError(mention);
  }
};

export const mentionsToPlaintext = (text: string): string => {
  return text.replace(mentionRegex, "@$3");
};

export const parseMentions = (text: string): Mention[] => {
  const result = new Array<Mention>();
  const matches = text.matchAll(mentionRegex);
  if (!matches) {
    return result;
  }

  for (const match of matches) {
    const mention = parseMention(match);
    if (mention) {
      result.push(mention);
    }
  }

  return result;
};

export const parseMention = (match: string[]): Mention | undefined => {
  if (match.length !== 4 || !match[1] || !match[2] || !match[3]) {
    return undefined;
  }

  if (match[1] === "@") {
    const parseResult = UserMention.safeParse({
      mentionType: "user",
      email: match[2].replace("@@", "@"),
      displayName: match[3],
    });

    return parseResult.success ? parseResult.data : undefined;
  } else if (match[1] === "&") {
    const addressId = match[2];
    const displayName = match[3];
    if (!isUUID(addressId) || displayName.length === 0) {
      return undefined;
    }

    const parseResult = GroupMention.safeParse({
      mentionType: "group",
      addressId: match[2],
      displayName: match[3],
    });

    return parseResult.success ? parseResult.data : undefined;
  } else if (match[1] === "%") {
    if (match[3] === "all") {
      return {
        mentionType: "all",
      };
    } else if (
      match[2] === "summary" || // This case is required.
      match[3] === "summary" // This second case is for backwards-compatibility.
    ) {
      return {
        mentionType: "summary",
      };
    } else {
      const botStr = match[2];
      const dot = botStr.indexOf(".");
      if (dot !== -1) {
        const roamId = Number(botStr.slice(0, dot));
        const dot2 = botStr.indexOf(".", dot + 1);
        const integrationId = botStr.slice(dot + 1, dot2);
        const botCode = botStr.slice(dot2 + 1);
        if (isNaN(roamId) || !integrationId) {
          return undefined;
        }
        return {
          mentionType: "bot",
          roamId,
          integrationId,
          botCode: botCode || "_",
          displayName: match[3],
        };
      }
    }
  }

  return undefined;
};
