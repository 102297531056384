import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "demobots",
  initialState: {},
  reducers: {
    bringBotsToMyTheater: () => {},
    sendBotsHome: () => {},
    makeBotsEmote: () => {},
  },
});

export const { actions, reducer } = slice;

export const DemoBotActions = actions;
