import { compileShader, createProgram, glsl } from "../webglHelper.js";

const vertexShaderSource = glsl`#version 300 es
    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;

    void main() {
      // Flipping Y is required when rendering to canvas
      gl_Position = vec4(a_position * vec2(1.0, -1.0), 0.0, 1.0);
      v_texCoord = a_texCoord;
    }
  `;

const fragmentShaderSrc = (maskComponent: string, invert: boolean) => glsl`#version 300 es
    precision highp float;

    in vec2 v_texCoord;
    uniform sampler2D u_textureSampler;
    out vec4 outColor;

    void main() {
      float a = ${invert ? "1.0 - " : ""}texture(u_textureSampler, v_texCoord).${maskComponent};
      outColor = vec4(a, 0.0, 0.0, 1.0);
    }
  `;

/* Renders a mask with values stored in the maskComponent channel of the supplied texture unit.
 * Writes to null framebuffer. (Used for debugging.)
 *
 */
export const buildRenderMaskStage = (
  gl: WebGL2RenderingContext,
  positionBuffer: WebGLBuffer,
  texCoordBuffer: WebGLBuffer,
  maskComponent: string,
  invert = false
) => {
  const { width: outputWidth, height: outputHeight } = gl.canvas;

  const vertexShader = compileShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = compileShader(
    gl,
    gl.FRAGMENT_SHADER,
    fragmentShaderSrc(maskComponent, invert)
  );
  const program = createProgram(gl, vertexShader, fragmentShader);
  gl.useProgram(program);

  const maskLocation = gl.getUniformLocation(program, "u_textureSampler");
  const texCoordAttributeLocation = gl.getAttribLocation(program, "a_texCoord");
  const positionAttributeLocation = gl.getAttribLocation(program, "a_position");

  const vao = gl.createVertexArray();
  gl.bindVertexArray(vao);

  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.vertexAttribPointer(positionAttributeLocation, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(positionAttributeLocation);

  gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
  gl.vertexAttribPointer(texCoordAttributeLocation, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(texCoordAttributeLocation);

  gl.bindVertexArray(null);

  const render = (texUnit: number) => {
    gl.useProgram(program);

    gl.uniform1i(maskLocation, texUnit);
    gl.bindVertexArray(vao);

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.clearColor(0.0, 0.0, 0.0, 0.0);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  };

  return { render };
};
