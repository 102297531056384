import React, { useEffect, useLayoutEffect, useRef } from "react";
import { v4 } from "uuid";
import { AsyncLock } from "../../shared/helpers/lock.js";
import { logger } from "../../shared/infra/logger.js";
import { HTMLVideoElementWithExperimentalFeatures } from "../helpers/media.js";
import { useContainer } from "../hooks/useContainer.js";
import { useDocument } from "../hooks/useGlobals.js";
import { useWindowInstance } from "../hooks/useWindowInstance.js";
import { WindowInstance } from "../injection/windows/WindowInstance.js";
import { styled } from "../styles/theme.js";

/**
 * See: https://www.madebymike.com.au/writing/getting-the-heck-out-of-react/
 *
 * Summary:
 *
 * React does not play nicely with "stateful" dom elements like video and audio. When a video
 * is moved from 1 part of the dom to another, react will destroy and recreate it via it's magic reconciliation process.  This leads to
 * reinitialization of stream playback, unnecessary PLI requests, and "blips".
 *
 * This component getOrCreates a detached video element and manually mounts it into the videoContainer ref.
 *
 * Because the video element is managed "imperatively", all the actions to create, update, and destroy it are done
 * manually in a non-react style (and special care must be taken to prevent memory leaks).
 *
 * Additional notes below:
 */

// global state for our video elements
const videoMap = new Map<string, HTMLVideoElementWithExperimentalFeatures>();

// global state for our AsyncLocks
const videoLocks = new Map<string, AsyncLock>();

// global state for our teardown methods
const videoDestroyers = new Map<string, ReturnType<typeof setTimeout>>();

/**
 * By default we'll try to reuse a video element based on the mediaStream.id; there
 * are situations where we want to render the same stream multiple times on a page;
 * eg, on stage for theater and viewing your video settings.
 *
 * If so you can provide an optional "playerContext" string that will alter the cache
 * key, giving you a context specific video element.
 */
const getContextKey = (
  mediaStream: MediaStream,
  windowInstance: WindowInstance,
  playerContext?: string
): string => `${windowInstance}__${playerContext}__${mediaStream.id}`;

/**
 * This is the getOrCreate method for retrieving a video element based on the contextKey
 */
const getVideoElement = (
  document: Document,
  contextKey: string
): HTMLVideoElementWithExperimentalFeatures => {
  const existing = videoMap.get(contextKey);
  if (existing) return existing;

  // create a new video element
  const newVideoElement = document.createElement(
    "video"
  ) as HTMLVideoElementWithExperimentalFeatures;

  // set static default props
  newVideoElement.autoplay = true;
  newVideoElement.playsInline = true;

  newVideoElement.onstalled = () => logger.warn("PW:Video loading Stalled");

  newVideoElement.style.pointerEvents = "none";
  newVideoElement.style.width = "100%";
  newVideoElement.style.height = "100%";

  videoMap.set(contextKey, newVideoElement);

  return newVideoElement;
};

const clearDestroyer = (contextKey: string) => {
  clearTimeout(videoDestroyers.get(contextKey));
  videoDestroyers.delete(contextKey);
};

interface Props extends Partial<HTMLVideoElementWithExperimentalFeatures> {
  mediaStream: MediaStream;
  mirror?: boolean;
  source?: string;
  playerContext?: string;
}

const VideoPlayer: React.FC<Props> = ({
  mediaStream,
  mirror,
  hidden,
  onloadstart,
  onloadeddata,
  ontimeupdate,
  source,
  playerContext,
  ...rest
}) => {
  const document = useDocument();
  const windowInstance = useWindowInstance();
  const contextKey = getContextKey(mediaStream, windowInstance, playerContext);
  const playerId = useRef(v4()).current;

  const container = useContainer();

  // this is the div where we'll manually mount our video element
  const videoContainer = useRef<HTMLDivElement>(null);

  const videoNode = getVideoElement(document, contextKey);

  // internal timing
  const videoLoadStartTimeRef = useRef<number | null>(null);

  // see: videoNode.ontimeupdate below
  const videoOnLoadDataCalled = useRef<boolean>(false);

  // If anything changes, update the node
  useEffect(() => {
    const setVideoNodeProperties = async () => {
      let lock = videoLocks.get(contextKey);
      if (!lock) {
        lock = new AsyncLock();
        videoLocks.set(contextKey, lock);
      }

      /**
       * We use a lock to serialize updates to the video element. Stateful elements
       * are more susceptible to issues if things are called in unexpected order.
       */
      await lock.guard(async () => {
        if (videoMap.get(contextKey) !== videoNode) {
          // Already destroyed -> don't do anything else
          return;
        }

        videoNode.muted = true;
        if (videoNode.srcObject !== mediaStream) {
          videoNode.srcObject = mediaStream;
        }

        Object.assign(videoNode, rest);

        videoNode.onloadstart = (e) => {
          videoLoadStartTimeRef.current = Date.now();
          if (onloadstart) {
            onloadstart.bind(videoNode)(e);
          }
        };

        videoNode.onloadeddata = (e) => {
          const endTime = Date.now();
          if (videoLoadStartTimeRef.current && source) {
            const loadTime = endTime - videoLoadStartTimeRef.current;
            container
              .stats()
              .timing({ category: "video", metric: "streamLoadTime", tags: { source } }, loadTime);
          }
          if (onloadeddata) {
            videoOnLoadDataCalled.current = true;
            onloadeddata.bind(videoNode)(e);
          }
        };

        videoNode.ontimeupdate = (e) => {
          // If this component remounts with an active videoNode, the onloadeddata
          // won't refire (since the video is already loaded).  If ontimeupdate fires
          // we know that the videoNode onloadeddata has already fired and we manually
          // retrigger it once so that the parent components that depend on it work correctly
          if (ontimeupdate) {
            ontimeupdate.bind(videoNode)(e);
          }
          if (onloadeddata && !videoOnLoadDataCalled.current) {
            videoOnLoadDataCalled.current = true;
            onloadeddata.bind(videoNode)(e);
          }
        };

        videoNode.style.transform = mirror ? "scale(-1, 1)" : "none"; // mirror for self view
        videoNode.style.display = hidden ? "none" : "inherit";
      });
    };

    setVideoNodeProperties().catch((err) =>
      logger.error({ err }, `error in setVideoNodeProperties`)
    );

    return () => {
      videoNode.onloadstart = null;
      videoNode.onloadeddata = null;
      videoNode.ontimeupdate = null;
    };
  }, [
    videoNode,
    mediaStream,
    container,
    onloadeddata,
    onloadstart,
    source,
    hidden,
    mirror,
    ontimeupdate,
    rest,
    contextKey,
  ]);

  /**
   * Here is where we manually mount the detached video element into
   * our container element.
   */
  useLayoutEffect(() => {
    videoContainer.current?.appendChild(videoNode);

    // detaching a video dom element pauses it; this ensures the video is kept playing
    if (videoNode.paused) {
      videoNode.play()?.catch((err) => logger.warn({ err }, `error while resuming VideoPlayer`));
    }
  }, [playerId, videoNode]);

  /**
   * Here is where we handle teardown.
   *
   * When the VideoPlayer unmounts we start a 5s timer which will remove any reference
   * to the video element allowing it to be scooped up by garbage collection.
   *
   * If the VideoPlayer is remounted before the 5s timeout (ie the player was moved in the dom),
   * we cancel the timer, preventing any cleanup until the player is unmounted again.
   */
  useLayoutEffect(() => {
    // clear any pending destroyers
    clearDestroyer(contextKey);

    return () => {
      // when unmounted, start the destroyer countdown
      clearDestroyer(contextKey);

      videoDestroyers.set(
        contextKey,
        setTimeout(async () => {
          const lock = videoLocks.get(contextKey);
          if (!lock) {
            logger.warn("Missing lock during video teardown");
            return;
          }

          await lock.guard(async () => {
            const videoNode = videoMap.get(contextKey);
            if (videoNode) {
              videoNode.autoplay = false;
              videoNode.pause();
              videoNode.srcObject = null;
            }
            videoMap.delete(contextKey);
            videoLocks.delete(contextKey);
            videoDestroyers.delete(contextKey);
          });
        }, 5000)
      );
    };
  }, [contextKey, playerId]);

  return (
    <VideoPlayerDiv
      hidden={hidden}
      className="video-player-container"
      ref={videoContainer}
      key={contextKey}
    />
  );
};

const VideoPlayerDiv = styled.div<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? "none" : "block")};
`;

VideoPlayerDiv.displayName = "VideoPlayerDiv​";

export default VideoPlayer;
