import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import {
  BotSearchResult,
  ChatMessageSearchResult,
  ChatSearchResult,
  ConnectionSearchResult,
  GroupSearchResult,
  MemberSearchResult,
  PersonSearchResult,
  PublicProfileSearchResult,
  SearchType,
  SocialSearchResult,
} from "../../../../shared/Models/SearchResult.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface RunSearchPayload {
  queryId: string;
  query: string;
  filters?: string[];
  searchTypes: "all" | SearchType[];
  handle: string;
}

export interface SetBotResultsPayload {
  searchType: "bot";
  results: BotSearchResult[];
  queryId: string;
}

export interface SetMemberResultsPayload {
  searchType: "member";
  results: MemberSearchResult[];
  queryId: string;
}

export interface SetConnectionResultsPayload {
  searchType: "connection";
  results: ConnectionSearchResult[];
  queryId: string;
}

export interface SetPublicResultsPayload {
  searchType: "public";
  results: PublicProfileSearchResult[];
  queryId: string;
}

export interface SetSocialResultsPayload {
  searchType: "social";
  results: SocialSearchResult[];
  queryId: string;
}

export interface SetChatResultsPayload {
  searchType: "chat";
  results: ChatSearchResult[];
  queryId: string;
}

export interface SetChatMessageResultsPayload {
  results: ChatMessageSearchResult[];
  queryId: string;
}

export interface SetGroupResultsPayload {
  searchType: "group";
  results: GroupSearchResult[];
  queryId: string;
}

export interface SetStatusPayload {
  searchStatus: SearchStatus;
  queryId: string;
}

export interface SetExplicitFocusPayload {
  explicitFocus: boolean;
  queryHandle: string;
}

export type SearchStatus = "searching" | "results" | "error";

export interface Search {
  query: string;
  queryId: string;
  filters?: string[];
  searchStatus: SearchStatus;
  botResults?: BotSearchResult[];
  memberResults?: MemberSearchResult[];
  connectionResults?: ConnectionSearchResult[];
  publicResults?: PublicProfileSearchResult[];
  socialResults?: SocialSearchResult[];
  chatResults?: ChatSearchResult[];
  groupResults?: GroupSearchResult[];
  messageResults?: ChatMessageSearchResult[];
}

const slice = createSlice({
  name: "search",
  initialState: {
    searches: {} as {
      [queryId: string]: Search;
    },
    activeQueryIds: {} as {
      [handle: string]: string;
    },
    explicitFocus: {} as {
      [queryHandle: string]: boolean;
    },
  },
  reducers: {
    runSearch: (state, action: PayloadAction<RunSearchPayload>) => {
      const { queryId, query, filters, searchTypes, handle } = action.payload;

      const search = state.searches[queryId];
      if (!search) {
        state.searches[queryId] = {
          query,
          queryId,
          filters,
          searchStatus: "searching",
        };
      } else {
        search.query = query;
      }
      const oldQueryId = state.activeQueryIds[handle];
      if (oldQueryId && oldQueryId !== queryId) {
        delete state.searches[oldQueryId];
      }
      state.activeQueryIds[handle] = queryId;
    },

    updateSearch: (state, action: PayloadAction<RunSearchPayload>) => {},

    clearSearch: (state, action: PayloadAction<string>) => {
      const handle = action.payload;
      const oldQueryId = state.activeQueryIds[handle];
      if (oldQueryId) {
        delete state.searches[oldQueryId];
      }
      delete state.activeQueryIds[handle];
    },

    // From server messages
    setBotResults: (state, action: PayloadAction<SetBotResultsPayload>) => {
      const { results, queryId } = action.payload;
      const search = state.searches[queryId];
      if (search) {
        search.botResults = results;
      }
    },
    setMemberResults: (state, action: PayloadAction<SetMemberResultsPayload>) => {
      const { results, queryId } = action.payload;
      const search = state.searches[queryId];
      if (search) {
        search.memberResults = results;
        search.searchStatus = "results";
      }
    },
    setConnectionResults: (state, action: PayloadAction<SetConnectionResultsPayload>) => {
      const { results, queryId } = action.payload;
      const search = state.searches[queryId];
      if (search) {
        search.connectionResults = results;
      }
    },
    setPublicResults: (state, action: PayloadAction<SetPublicResultsPayload>) => {
      const { results, queryId } = action.payload;
      const search = state.searches[queryId];
      if (search) {
        search.publicResults = results;
      }
    },
    setSocialResults: (state, action: PayloadAction<SetSocialResultsPayload>) => {
      const { results, queryId } = action.payload;
      const search = state.searches[queryId];
      if (search) {
        search.socialResults = results;
      }
    },
    setChatResults: (state, action: PayloadAction<SetChatResultsPayload>) => {
      const { results, queryId } = action.payload;
      const search = state.searches[queryId];
      if (search) {
        search.chatResults = results;
      }
    },
    setChatMessageResults: (state, action: PayloadAction<SetChatMessageResultsPayload>) => {
      const { results, queryId } = action.payload;
      const search = state.searches[queryId];
      if (search) {
        search.messageResults = results;
      }
    },
    setGroupResults: (state, action: PayloadAction<SetGroupResultsPayload>) => {
      const { results, queryId } = action.payload;
      const search = state.searches[queryId];
      if (search) {
        search.groupResults = results;
      }
    },
    setStatus: (state, action: PayloadAction<SetStatusPayload>) => {
      const { queryId, searchStatus } = action.payload;
      const search = state.searches[queryId];
      if (search) {
        search.searchStatus = searchStatus;
      }
    },
    setExplicitFocus: (state, action: PayloadAction<SetExplicitFocusPayload>) => {
      const { queryHandle, explicitFocus } = action.payload;
      state.explicitFocus[queryHandle] = explicitFocus;
    },
    clickSearchResult: (state, action: PayloadAction<ClickSearchResultPayload>) => {},
  },
});

export type ClickSearchResultPayload = {
  rank: number;
  resultType: string;
};

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.anyWorld.search;
export const selectors = {
  selectQueryIdByHandle: (handle: string) => (state: RootState) =>
    selectSlice(state).activeQueryIds[handle],
  selectQuery: (queryId?: string) => (state: RootState) =>
    queryId ? selectSlice(state).searches[queryId]?.query : undefined,
  selectBotResults: (queryId?: string) => (state: RootState) =>
    queryId ? selectSlice(state).searches[queryId]?.botResults : undefined,
  selectMemberResults: (queryId?: string) => (state: RootState) =>
    queryId ? selectSlice(state).searches[queryId]?.memberResults : undefined,
  selectConnectionResults: (queryId?: string) => (state: RootState) =>
    queryId ? selectSlice(state).searches[queryId]?.connectionResults : undefined,
  selectPublicResults: (queryId?: string) => (state: RootState) =>
    queryId ? selectSlice(state).searches[queryId]?.publicResults : undefined,
  selectPersonResults: defaultMemoize((queryId?: string) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      if (!queryId) return undefined;
      const result: Array<
        | BotSearchResult
        | PersonSearchResult
        | ChatSearchResult
        | ChatMessageSearchResult
        | GroupSearchResult
      > = [
        ...(slice.searches[queryId]?.chatResults ?? []),
        ...(slice.searches[queryId]?.groupResults ?? []),
        ...(slice.searches[queryId]?.memberResults ?? []),
        ...(slice.searches[queryId]?.botResults ?? []),
        ...(slice.searches[queryId]?.connectionResults ?? []),
        ...(slice.searches[queryId]?.socialResults ?? []),
        ...(slice.searches[queryId]?.messageResults ?? []),
      ];
      return result;
    })
  ),
  selectChatResults: (queryId?: string) => (state: RootState) =>
    queryId ? selectSlice(state).searches[queryId]?.chatResults : undefined,
  selectChatMessageResults: (queryId?: string) => (state: RootState) =>
    queryId ? selectSlice(state).searches[queryId]?.messageResults : undefined,
  selectGroupResults: (queryId?: string) => (state: RootState) =>
    queryId ? selectSlice(state).searches[queryId]?.groupResults : undefined,

  selectStatus: (queryId?: string) => (state: RootState) =>
    queryId ? selectSlice(state).searches[queryId]?.searchStatus : undefined,
  selectExplicitFocus: (queryHandle?: string) => (state: RootState) =>
    queryHandle ? selectSlice(state).explicitFocus[queryHandle] ?? false : false,
};

export const SearchSelectors = selectors;
export const SearchActions = actions;
