import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { Card } from "../../../../shared/Models/Card.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";
import {
  cardToFront,
  cardWithLocalUpdate,
  removeCard,
  updateLockedCard,
  upsertCard,
} from "./cardSliceHelpers.js";
import {
  AddCardPayload,
  BringCardToFrontPayload,
  CardIdPayload,
  CompleteLocalUpdatePayload,
  DragMouseToPayload,
  LocalUpdate,
  NewLocalUpdate,
  SetAllBoardCardsPayload,
  SetSharedCardInfoPayload,
  SharedCardInfo,
  TextContentUpdatePayload,
  ToggleMaximizePayload,
  UpdateCardPayload,
  UpdateIdPayload,
} from "./cardSlicePayloads.js";

const adapter = createEntityAdapter<Card>();

interface CardLock {
  cardId: string;
  timestamp: number;
}

const initialState = {
  cardsByBoard: {} as { [boardId: string]: Card[] },

  pendingUpdate: undefined as LocalUpdate | undefined,
  activeUpdate: undefined as LocalUpdate | undefined,

  sharedCardInfo: undefined as SharedCardInfo | undefined,
};
export type CardSliceState = typeof initialState;

const slice = createSlice({
  name: "card",
  initialState,
  reducers: {
    // UI-initiated actions
    addCard: (state, action: PayloadAction<AddCardPayload>) => {},
    updateCard: (state, action: PayloadAction<UpdateCardPayload>) => {},
    removeCard: (state, action: PayloadAction<CardIdPayload>) => {}, // remove card triggers an "undo" toast
    deleteCard: (state, action: PayloadAction<CardIdPayload>) => {}, // delete card directly deletes the card
    bringCardToFront: (state, action: PayloadAction<BringCardToFrontPayload>) => {},
    toggleMaximize: (state, action: PayloadAction<ToggleMaximizePayload>) => {
      const { boardId, cardId, maximize } = action.payload;
      cardToFront(state, boardId, cardId);
      const card = state.cardsByBoard[boardId]?.find((p) => p.id === cardId);
      if (card) card.maximized = maximize;
    },

    // Streaming updates: start
    startLocalUpdate: (state, action: PayloadAction<NewLocalUpdate>) => {},
    startLocalUpdateInternal: (state, action: PayloadAction<LocalUpdate>) => {
      const update = action.payload;
      state.pendingUpdate = update;
    },
    pendingUpdateStarted: (state, action: PayloadAction<UpdateIdPayload>) => {
      const { cardId, lockTimestamp } = action.payload;
      if (
        state.pendingUpdate?.cardId === cardId &&
        state.pendingUpdate?.lockTimestamp === lockTimestamp
      ) {
        state.activeUpdate = state.pendingUpdate;
        state.pendingUpdate = undefined;
      }
    },
    pendingUpdateAborted: (state, action: PayloadAction<UpdateIdPayload>) => {
      const { cardId, lockTimestamp } = action.payload;
      if (
        state.pendingUpdate?.cardId === cardId &&
        state.pendingUpdate?.lockTimestamp === lockTimestamp
      ) {
        state.pendingUpdate = undefined;
      }
    },

    // Streaming updates: in progress
    textContentUpdate: (state, action: PayloadAction<TextContentUpdatePayload>) => {
      const { cardId, textContent } = action.payload;
      if (state.activeUpdate?.updateType === "text" && state.activeUpdate.cardId === cardId) {
        state.activeUpdate.content = textContent;
      } else if (
        state.pendingUpdate?.updateType === "text" &&
        state.pendingUpdate.cardId === cardId
      ) {
        state.pendingUpdate.content = textContent;
      }
    },
    dragMouseTo: (state, action: PayloadAction<DragMouseToPayload>) => {
      const { boardId, x, y } = action.payload;
      if (
        state.activeUpdate?.boardId === boardId &&
        (state.activeUpdate.updateType === "move" || state.activeUpdate.updateType === "resize")
      ) {
        state.activeUpdate.currentX = x;
        state.activeUpdate.currentY = y;
      } else if (
        state.pendingUpdate?.boardId === boardId &&
        (state.pendingUpdate.updateType === "move" || state.pendingUpdate.updateType === "resize")
      ) {
        state.pendingUpdate.currentX = x;
        state.pendingUpdate.currentY = y;
      }
    },

    // Streaming updates: end
    completeLocalUpdate: (state, action: PayloadAction<CompleteLocalUpdatePayload>) => {},
    completeMouseOperations: (state, action: PayloadAction<string>) => {},
    activeUpdateEnded: (state, action: PayloadAction<UpdateIdPayload>) => {
      const { cardId, lockTimestamp } = action.payload;
      if (
        state.activeUpdate?.card.id === cardId &&
        state.activeUpdate?.lockTimestamp === lockTimestamp
      ) {
        const card = state.cardsByBoard[state.activeUpdate.boardId]?.find((p) => p.id === cardId);
        if (card) {
          const updatedCard = cardWithLocalUpdate(card, state.activeUpdate);
          upsertCard(state, updatedCard);
        }
        state.activeUpdate = undefined;
      }
    },
    releaseLock: (state, action: PayloadAction<CardIdPayload>) => {
      // This is used only if the client realizes it has a lock but doesn't think it's editing
      // that card, which is an exceptional situation, not part of the standard flow.
    },

    // Server updates
    serverAddUpdateCards: (state, action: PayloadAction<Card[]>) => {
      for (const card of action.payload) {
        if (state.activeUpdate?.card.id === card.id) {
          updateLockedCard(state, card);
        } else {
          upsertCard(state, card);
        }
      }
    },
    serverRemoveCard: (state, action: PayloadAction<CardIdPayload>) => {
      const { boardId, cardId } = action.payload;
      removeCard(state, boardId, cardId);
    },
    serverAllCardsForBoard: (state, action: PayloadAction<SetAllBoardCardsPayload>) => {
      const { boardId, cards } = action.payload;
      state.cardsByBoard[boardId] = cards;
    },
    setSharedCardInfo: (state, action: PayloadAction<SetSharedCardInfoPayload>) => {
      state.sharedCardInfo = action.payload;
    },
  },
});

export const { actions: CardActions, reducer: cardReducer } = slice;

const selectSlice = (state: RootState) => state.holo.card;
const boardCardsSelector = (state: RootState, boardId?: string) =>
  boardId ? state.holo.card.cardsByBoard[boardId] : undefined;

export const CardSelectors = {
  selectById: defaultMemoize(
    (boardId: string | undefined, cardId: string | undefined) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        if (!boardId || !cardId) {
          return undefined;
        }
        const cards = slice.cardsByBoard[boardId];
        if (!cards) {
          return undefined;
        }
        return cards.find((c) => c.id === cardId);
      }),
    { maxSize: 100 }
  ),
  selectCardIdsByBoard: defaultMemoize((boardId?: string) =>
    createDeepEqualSelector(
      (state: RootState) => boardCardsSelector(state, boardId) || [],
      (cards) => {
        return cards.map((c) => c.id);
      }
    )
  ),
  selectCardsByBoard: defaultMemoize((boardId?: string) =>
    createDeepEqualSelector(
      (state: RootState) => state,
      (state) => boardCardsSelector(state, boardId) ?? []
    )
  ),
  selectCardIncludingCurrentUpdate: defaultMemoize(
    (boardId: string, cardId: string) =>
      createDeepEqualSelector(
        (state: RootState) => boardCardsSelector(state, boardId) || [],
        (state: RootState) => state.holo.card.pendingUpdate,
        (state: RootState) => state.holo.card.activeUpdate,
        (cards, pendingUpdate, activeUpdate) => {
          const card = cards.find((c) => c.id === cardId);
          if (!card) return undefined;
          if (activeUpdate && activeUpdate.card.id === cardId) {
            return cardWithLocalUpdate(card, activeUpdate);
          } else if (pendingUpdate && pendingUpdate.card.id === cardId) {
            return cardWithLocalUpdate(card, pendingUpdate);
          }
          return card;
        }
      ),
    { maxSize: 100 }
  ),
  selectHighestCardZForBoard: defaultMemoize((boardId: string) =>
    createSelector(
      (state: RootState) => boardCardsSelector(state, boardId) || [],
      (cards) => {
        return cards.reduce((z, card) => Math.max(z, card.z), 10);
      }
    )
  ),
  selectTopCardIdForBoard: defaultMemoize((boardId: string) =>
    createSelector(
      (state: RootState) => boardCardsSelector(state, boardId) || [],
      (cards) => {
        if (!cards[0]) return undefined;
        return cards.reduce((c1, c2) => (c1.z > c2.z ? c1 : c2), cards[0]).id;
      }
    )
  ),
  selectBoardHasActiveMouseOperation: defaultMemoize((boardId: string) =>
    createSelector(
      (state: RootState) => state.holo.card.activeUpdate,
      (update: LocalUpdate | undefined) =>
        update !== undefined &&
        update.boardId === boardId &&
        (update.updateType === "move" || update.updateType === "resize")
    )
  ),
  selectIsCardUpdating: defaultMemoize(
    (cardId: string) =>
      createSelector(
        selectSlice,
        (slice) => slice.activeUpdate?.card.id === cardId || slice.pendingUpdate?.card.id === cardId
      ),
    { maxSize: 100 }
  ),
  selectActiveUpdate: createDeepEqualSelector(selectSlice, (slice) => slice.activeUpdate),
  selectPendingUpdate: createDeepEqualSelector(selectSlice, (slice) => slice.pendingUpdate),
  selectSharedCardInfo: createDeepEqualSelector(selectSlice, (slice) => slice.sharedCardInfo),
  selectPendingOrActiveUpdate: createDeepEqualSelector(
    selectSlice,
    (slice) => slice.pendingUpdate ?? slice.activeUpdate
  ),
  selectScreenShareCard: defaultMemoize((boardId: string) =>
    createSelector(
      (state: RootState) => boardCardsSelector(state, boardId) || [],
      (cards) => {
        const card = cards[0];
        if (card && card.cardType !== "DisplayStream") {
          throw new Error(
            `Error card ${card.id} is of type ${card.cardType} (expected 'DisplayStream').`
          );
        }
        return card;
      }
    )
  ),
};
