import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import {
  EmailKeyNotificationPreference,
  PersonRoamKeyNotificationPreference,
  UserNotificationPreference,
} from "../../../../shared/notification/settings/UserNotificationPreference.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface FetchPersonRoamNotificationPreferencesPayload {
  personId: number;
  roamId: number;
}

const notificationPreferencesAdapter = createEntityAdapter<UserNotificationPreference>({
  selectId: (preference) => {
    switch (preference.type) {
      case "PERSON_ROAM": {
        return `${preference.roamId}-${preference.preference.notification.type}-${preference.preference.notification.channel}`;
      }
      case "EMAIL": {
        return `${preference.email}-${preference.preference.notification.type}-${preference.preference.notification.channel}`;
      }
      default: {
        throw new Error("Unsupported type!");
      }
    }
  },
});

const slice = createSlice({
  name: "notification",
  initialState: {
    personRoamNotificationsLoaded: {} as Record<number, boolean>,
    emailNotificationsLoaded: {} as Record<string, boolean>,
    notificationPreferences: notificationPreferencesAdapter.getInitialState(),
  },
  reducers: {
    fetchPersonRoamKeyNotificationPreferences: (
      _,
      action: PayloadAction<FetchPersonRoamNotificationPreferencesPayload>
    ) => {},
    fetchEmailKeyNotificationPreferences: (_, action: PayloadAction<string>) => {},
    /**
     * Saves/persists a user notification preference.
     */
    updateUserNotificationPreference: (_, action: PayloadAction<UserNotificationPreference>) => {},
    /**
     * Updates redux state with the given notification preferences.
     *
     * Intended to be called with all preferences for a single roam, and with preferences of the same user key type.
     */
    upsertNotificationPreferences: (state, action: PayloadAction<UserNotificationPreference[]>) => {
      const preferences = action.payload;
      if (preferences.length === 0) {
        return;
      }

      notificationPreferencesAdapter.upsertMany(state.notificationPreferences, preferences);

      // These are all assumed to be the same.
      const first = preferences[0];
      switch (first?.type) {
        case "PERSON_ROAM": {
          const roamId = first.roamId;
          state.personRoamNotificationsLoaded[roamId] = true;
          break;
        }
        case "EMAIL": {
          const email = first.email;
          state.emailNotificationsLoaded[email] = true;
          break;
        }
        default: {
          throw new Error("Unsupported!");
        }
      }
    },
  },
});
export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.notification.notification;
const notificationPreferencesSelectors = notificationPreferencesAdapter.getSelectors();

const personRoamNotificationPreferencesSelector = createDeepEqualSelector(selectSlice, (slice) => {
  const preferences = notificationPreferencesSelectors
    .selectAll(slice.notificationPreferences)
    .filter((preference) => preference.type === "PERSON_ROAM");
  return preferences as PersonRoamKeyNotificationPreference[];
});

const emailNotificationPreferencesSelector = createDeepEqualSelector(selectSlice, (slice) => {
  const preferences = notificationPreferencesSelectors
    .selectAll(slice.notificationPreferences)
    .filter((preference) => preference.type === "EMAIL");
  return preferences as EmailKeyNotificationPreference[];
});

export const selectors = {
  selectPersonRoamNotificationPreferencesLoadedByRoam: (roamId: number) => (state: RootState) =>
    selectSlice(state).personRoamNotificationsLoaded[roamId],
  selectEmailNotificationPreferencesLoadedByEmail: (email: string) => (state: RootState) =>
    selectSlice(state).emailNotificationsLoaded[email],

  selectPersonRoamKeyNotificationPreferencesByRoam: defaultMemoize((roamId: number) =>
    createDeepEqualSelector(personRoamNotificationPreferencesSelector, (preferences) => {
      return preferences.filter((preference) => preference.roamId === roamId);
    })
  ),

  selectEmailKeyNotificationPreferencesByEmail: defaultMemoize((email?: string) =>
    createDeepEqualSelector(emailNotificationPreferencesSelector, (preferences) => {
      if (!email) {
        return [];
      }
      return preferences.filter((preference) => preference.email === email);
    })
  ),
};
