import equal from "fast-deep-equal/es6/index.js";
import { logger } from "../../../shared/infra/logger.js";
import { ClientContainerInfo } from "../../injection/IClientContainer.js";
import { IClientService } from "../../injection/IClientService.js";
import { IRedux } from "../../injection/redux/IRedux.js";
import { RawStreamManager } from "../RawStreamManager.js";
import { IAvDevices } from "../interfaces/IAvDevices.js";
import { IFakeStreams } from "../interfaces/IFakeStreams.js";
import { GlobalVideoSettings, IRawVideoStreamManager } from "../interfaces/IRawStreamManager.js";

interface Injected {
  avDevices(): IAvDevices;
  fakeStreams(): IFakeStreams;
  info(): ClientContainerInfo;
  redux(): IRedux;
}

export class RawVideoStreamManager
  extends RawStreamManager
  implements IRawVideoStreamManager, IClientService
{
  protected globalVideoSettings: GlobalVideoSettings = {
    aspectRatio: 4 / 3,
  };
  constructor(container: Injected) {
    super(container, "video");
  }

  public name() {
    return "RawVideoStreamManager";
  }

  public setGlobalVideoSettings(settings: GlobalVideoSettings): void {
    // See setGlobalAudioSettings for an explanation of why this is needed

    // Acquire a write-lock (which will halt any calls to getStream in the meantime)
    this.lock
      .writeGuard(async () => {
        if (!equal(this.globalVideoSettings, settings)) {
          // Close every stream
          const promises = [...this.streamPromises.values()];
          this.streamPromises.clear();
          await Promise.race([
            // Close all the streams
            Promise.all(
              promises.map(async (streamPromise) => {
                try {
                  const stream = await streamPromise;
                  // This will also close all clones
                  stream.close();
                } catch {
                  // Getting the stream failed
                  // Shouldn't happen (should be caught elsewhere), but means we have nothing to do
                }
              })
            ),
            // Time out after 5 seconds (sanity check)
            new Promise((resolve) => setTimeout(resolve, 5_000)),
          ]);
          // Start using the new settings for future calls to getStream
          this.globalVideoSettings = settings;
        }
      })
      .catch((err) => logger.warn({ err }, `Error setting global video settings`));
  }

  protected async getStreamInner(deviceId: string | undefined): Promise<MediaStream> {
    // If an override stream is configured (for testing/robots - this is uncommon), just use it
    const overrideStream = await this.container.fakeStreams().getOverrideStream("video");
    if (overrideStream) {
      return overrideStream.clone();
    }

    const device = deviceId ? { deviceId: { exact: deviceId } } : {};
    let stream: MediaStream;
    try {
      // eslint-disable-next-line no-restricted-globals
      stream = await navigator.mediaDevices.getUserMedia({
        video: {
          ...this.globalVideoSettings,
          ...device,
        },
      });
    } catch (err) {
      logger.info({ err }, `Error thrown from video getUserMedia`);
      throw err;
    }
    return stream;
  }

  public snapshot() {
    return {
      ...super.snapshot(),
      globalVideoSettings: this.globalVideoSettings,
    };
  }
}
