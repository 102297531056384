import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import {
  Group,
  GroupAccessMode,
  GroupManagement,
  GroupMemberAddress,
  GroupMemberTarget,
  GroupPermissionsForClient,
  GroupUpdate,
} from "../../../../shared/Models/Group.js";
import { targetLongName } from "../../../chat/helpers/targets.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

const groupAdapter = createEntityAdapter<Group>({ selectId: (group) => group.addressId });
const groupSelectors = groupAdapter.getSelectors();

const groupMembersAdapter = createEntityAdapter<GroupMemberAddress>({
  selectId: (chatAddress) => chatAddress.addressId,
  sortComparer: (a, b) => targetLongName(a).localeCompare(targetLongName(b)),
});
const groupMembersInitialState = groupMembersAdapter.getInitialState();
const groupMembersSelector = groupMembersAdapter.getSelectors();

export interface CreateGroupPayload {
  roamId: number;
  name: string;
  description?: string;
  imageName?: string;
  accessMode: GroupAccessMode;
  groupManagement: GroupManagement;
  initialTargets: GroupMemberTarget[];
  windowKey?: WindowKey;
}

export interface UpdateGroupPayload {
  update: GroupUpdate;
  windowKey?: WindowKey;
}

export interface RequestGroupsPayload {
  roamId: number;
  windowKey?: WindowKey;
}

export interface SetAllRoamGroupsPayload {
  roamId: number;
  groups: Group[];
}

export interface GroupIdAndWindowKeyPayload {
  groupId: string;
  windowKey?: WindowKey;
}

export interface RemoveGroupMembersPayload {
  groupId: string;
  addressIds: string[];
  windowKey?: WindowKey;
}

export interface GroupMembersRemovedPayload {
  groupId: string;
  removedAddressIds: string[];
}

export interface AddUpdateGroupMembersPayload {
  groupId: string;
  memberTargets: GroupMemberTarget[];
  windowKey?: WindowKey;
}

export interface AddGuestsToGroupPayload {
  groupId: string;
  guests: Array<{ email: string; name?: string }>;
  windowKey?: WindowKey;
}

export interface GroupMembersAddedPayload {
  groupId: string;
  addedMembers: GroupMemberAddress[];
}

export interface RequestGroupDetailPayload {
  groupId: string;
  includeMemberAddresses: boolean;
  windowKey?: WindowKey;
}

export interface SetGroupDetailPayload {
  groupId: string;
  group?: Group;
  permissions?: GroupPermissionsForClient;
  memberAddresses?: GroupMemberAddress[];
  lastUpdated?: number;
}

export interface SetGroupMembersPayload {
  groupId: string;
  memberAddresses: GroupMemberAddress[];
}

const slice = createSlice({
  name: "group",
  initialState: {
    groups: groupAdapter.getInitialState(),
    groupsAllLoadedForRoams: {} as { [roamId: number]: boolean },
    memberOfGroupIds: undefined as string[] | undefined,
    groupMembers: {} as { [addressId: string]: EntityState<GroupMemberAddress> },
    permissions: {} as { [addressId: string]: GroupPermissionsForClient },
    groupMembershipLastUpdate: {} as { [addressId: string]: number },
  },
  reducers: {
    createGroup: (state, action: PayloadAction<CreateGroupPayload>) => {},
    updateGroup: (state, action: PayloadAction<UpdateGroupPayload>) => {},
    makeGroupPrivate: (state, action: PayloadAction<GroupIdAndWindowKeyPayload>) => {},
    archiveGroup: (state, action: PayloadAction<GroupIdAndWindowKeyPayload>) => {},
    unarchiveGroup: (state, action: PayloadAction<GroupIdAndWindowKeyPayload>) => {},
    requestGroupsForRoam: (state, action: PayloadAction<RequestGroupsPayload>) => {},
    requestGroupDetail: (state, action: PayloadAction<RequestGroupDetailPayload>) => {
      if (!state.groupMembers[action.payload.groupId]) {
        state.groupMembers[action.payload.groupId] = groupMembersInitialState;
      }
    },
    joinGroup: (state, action: PayloadAction<GroupIdAndWindowKeyPayload>) => {},
    leaveGroup: (state, action: PayloadAction<GroupIdAndWindowKeyPayload>) => {},
    removeGroupMembers: (state, action: PayloadAction<RemoveGroupMembersPayload>) => {},
    addUpdateGroupMembers: (state, action: PayloadAction<AddUpdateGroupMembersPayload>) => {},
    addGuestsToGroup: (state, action: PayloadAction<AddGuestsToGroupPayload>) => {},
    requestMyGroupMembership: (state) => {},
    invalidateGroupDetail: (state, action: PayloadAction<string>) => {
      delete state.groupMembershipLastUpdate[action.payload];
    },
    setGroup: (state, action: PayloadAction<Group>) => {
      groupAdapter.setOne(state.groups, action.payload);
    },
    setAllRoamGroups: (state, action: PayloadAction<SetAllRoamGroupsPayload>) => {
      const { roamId, groups } = action.payload;
      const currentIds = groupSelectors
        .selectAll(state.groups)
        .filter((g) => g.roamId === roamId)
        .map((g) => g.addressId);
      const idsToRemove = currentIds.filter(
        (addressId) => !groups.some((c) => c.addressId === addressId)
      );
      groupAdapter.upsertMany(state.groups, groups);
      groupAdapter.removeMany(state.groups, idsToRemove);
      state.groupsAllLoadedForRoams[roamId] = true;
    },
    setMemberOfGroupIds: (state, action: PayloadAction<string[]>) => {
      state.memberOfGroupIds = action.payload;
    },
    setGroupDetail: (state, action: PayloadAction<SetGroupDetailPayload>) => {
      const { groupId, group, permissions, memberAddresses, lastUpdated } = action.payload;
      if (group) {
        groupAdapter.setOne(state.groups, group);
      }
      if (permissions) {
        state.permissions[groupId] = permissions;
      }
      if (memberAddresses) {
        let members = state.groupMembers[groupId];
        if (!members) {
          members = groupMembersInitialState;
        }
        state.groupMembers[groupId] = groupMembersAdapter.setAll(members, memberAddresses);
      }
      if (lastUpdated) {
        state.groupMembershipLastUpdate[groupId] = lastUpdated;
      }
    },
    setGroupMembers: (state, action: PayloadAction<SetGroupMembersPayload>) => {
      const { memberAddresses, groupId } = action.payload;

      let members = state.groupMembers[groupId];
      if (!members) {
        state.groupMembers[groupId] = members = groupMembersInitialState;
      }
      groupMembersAdapter.setAll(members, memberAddresses);
    },
    groupMembersRemoved: (state, action: PayloadAction<GroupMembersRemovedPayload>) => {
      const { groupId, removedAddressIds } = action.payload;

      let members = state.groupMembers[groupId];
      if (!members) {
        state.groupMembers[groupId] = members = groupMembersInitialState;
      }
      groupMembersAdapter.removeMany(members, removedAddressIds);
    },
    groupMembersAdded: (state, action: PayloadAction<GroupMembersAddedPayload>) => {
      const { groupId, addedMembers } = action.payload;
      let members = state.groupMembers[groupId];
      if (!members) {
        state.groupMembers[groupId] = members = groupMembersInitialState;
      }
      groupMembersAdapter.upsertMany(members, addedMembers);
    },
  },
});

export const { actions, reducer } = slice;
const selectSlice = (state: RootState) => state.anyWorld.group;
export const selectors = {
  selectByRoamId: defaultMemoize((roamId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      roamId ? groupSelectors.selectAll(slice.groups).filter((c) => c.roamId === roamId) : []
    )
  ),
  selectGroupsAllLoadedForRoam: (roamId?: number) => (state: RootState) =>
    roamId ? selectSlice(state).groupsAllLoadedForRoams[roamId] ?? false : false,
  selectAllHandsForRoamId: defaultMemoize((roamId?: number) =>
    createSelector(selectSlice, (slice) =>
      roamId
        ? groupSelectors
            .selectAll(slice.groups)
            .find((g) => g.roamId === roamId && g.groupType === "roam")
        : undefined
    )
  ),
  selectById: (addressId?: string) => (state: RootState) =>
    addressId ? selectSlice(state).groups.entities[addressId] : undefined,
  selectByIds: defaultMemoize(
    (addressIds: string[]) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        const addressIdsSet = new Set(addressIds);
        return groupSelectors
          .selectAll(slice.groups)
          .filter((group) => addressIdsSet.has(group.addressId));
      }),
    { equalityCheck: equal }
  ),
  selectGroupMembers: defaultMemoize((addressId?: string) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      if (!addressId) return [];
      const members = slice.groupMembers[addressId];
      if (!members) return [];
      return groupMembersSelector.selectAll(members);
    })
  ),
  selectPermissions: (addressId?: string) => (state: RootState) =>
    addressId ? selectSlice(state).permissions[addressId] : undefined,
  selectGroupMembershipLastUpdate: (addressId?: string) => (state: RootState) =>
    addressId ? selectSlice(state).groupMembershipLastUpdate[addressId] : undefined,
  selectMemberOfGroupIds: (state: RootState) => selectSlice(state).memberOfGroupIds,
  selectIsMemberOfGroupId: defaultMemoize((addressId?: string) =>
    createSelector(
      selectSlice,
      (slice) =>
        addressId !== undefined &&
        slice.memberOfGroupIds !== undefined &&
        slice.memberOfGroupIds.includes(addressId)
    )
  ),
};

export const GroupActions = actions;
export const GroupSelectors = selectors;
