import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RoamologyData,
  RoamologySection,
  RoamologyTable,
  RoamologyTableOrPlaceholder,
} from "../../../../shared/Models/MeetingStats.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface RoamologySectionsByRoam {
  [roamId: number]: RoamologySection[];
}

export interface RoamologyTablesByRoam {
  [roamId: number]: {
    [tableId: string]: { queryId: string; table: RoamologyTableOrPlaceholder };
  };
}

export interface RequestRoamologyPayload {
  roamId: number;
  personId: number;
  startDateString?: string;
  localTimezone?: string;
}

export interface SetRoamologyDataPayload {
  roamId: number;
  queryId: string;
  data: RoamologyData;
}

export interface FillRoamologyDataPayload {
  roamId: number;
  queryId: string;
  table: RoamologyTable;
}

const slice = createSlice({
  name: "meetingStats",
  initialState: {
    activeQueryId: "",
    roamologySectionsByRoam: {} as RoamologySectionsByRoam,
    roamologyTablesByRoam: {} as RoamologyTablesByRoam,
  },
  reducers: {
    requestRoamologyData: (_, action: PayloadAction<RequestRoamologyPayload>) => {},
    setActiveQueryId: (state, action: PayloadAction<string>) => {
      state.activeQueryId = action.payload;
    },
    setRoamologyData: (state, action: PayloadAction<SetRoamologyDataPayload>) => {
      const { roamId, queryId, data } = action.payload;

      if (state.activeQueryId === queryId) {
        state.roamologySectionsByRoam[roamId] = data.sections;
        // This handles the race condition where the table gets filled before the placeholder is
        // returned, which may happen if the result was cached.
        const existingTables = state.roamologyTablesByRoam[roamId] ?? {};
        for (const table of data.tables) {
          const existingTable = existingTables[`${table.sectionId}--${table.id}`];
          if (!existingTable || existingTable.queryId !== queryId) {
            existingTables[`${table.sectionId}--${table.id}`] = { queryId, table };
          }
        }
        state.roamologyTablesByRoam[roamId] = existingTables;
      }
    },
    fillRoamologyTable: (state, action: PayloadAction<FillRoamologyDataPayload>) => {
      const { roamId, queryId, table } = action.payload;

      if (state.activeQueryId === queryId) {
        // We expect the table to already exist with the corresponding roam ID,
        // but do this just in case.
        const existingTables = state.roamologyTablesByRoam[roamId] ?? {};
        existingTables[`${table.sectionId}--${table.id}`] = { queryId, table };
        state.roamologyTablesByRoam[roamId] = existingTables;
      }
    },
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
};

export const selectors = {
  selectRoamologyData: (roamId: number) =>
    createSelector(
      (state: RootState) => state,
      (state) => {
        const roamologySection = state.anyWorld.meetingStats.roamologySectionsByRoam[roamId];
        const roamologyTables = state.anyWorld.meetingStats.roamologyTablesByRoam[roamId];

        if (!roamId || !roamologySection || !roamologyTables) return undefined;

        return {
          sections: roamologySection,
          tables: Object.values(roamologyTables).map((v) => v.table),
        } as RoamologyData;
      }
    ),
};
