import { z } from "zod";
import { numberId, stringId } from "../Models/zodTypes.js";
import { UnreachableError } from "../helpers/UnreachableError.js";

export const OverworldLocation = z.object({
  locationType: z.literal("overworld"),
});
export type OverworldLocation = z.infer<typeof OverworldLocation>;

export const SectionLocation = z.object({
  locationType: z.literal("section"),
  sectionId: stringId(),
  roamId: numberId(),
  hostPersonId: numberId().optional(),
});
export type SectionLocation = z.infer<typeof SectionLocation>;

export const UserLocation = z.discriminatedUnion("locationType", [
  OverworldLocation,
  SectionLocation,
]);
export type UserLocation = z.infer<typeof UserLocation>;

export const EmailRequest = z.object({
  requestType: z.literal("email"),
  email: z.string().email(),
});
export type EmailRequest = z.infer<typeof EmailRequest>;

export const PersonRequest = z.object({
  requestType: z.literal("person"),
  personId: numberId(),
});
export type PersonRequest = z.infer<typeof PersonRequest>;

export const OccupantRequest = z.object({
  requestType: z.literal("occupant"),
  occupantId: stringId(),
});
export type OccupantRequest = z.infer<typeof OccupantRequest>;

export const UserLocationRequest = z.discriminatedUnion("requestType", [
  PersonRequest,
  OccupantRequest,
  EmailRequest,
]);
export type UserLocationRequest = z.infer<typeof UserLocationRequest>;

const UserLocationResultBase = z.object({
  status: z.enum(["found", "notpresent"]),
  timestamp: z.number(),
  locations: UserLocation.array().optional(),
});

export const EmailResult = UserLocationResultBase.extend({
  resultType: z.literal("email"),
  email: z.string().email(),
});
export type EmailResult = z.infer<typeof EmailResult>;

export const PersonResult = UserLocationResultBase.extend({
  resultType: z.literal("person"),
  personId: numberId(),
});
export type PersonResult = z.infer<typeof PersonResult>;

export const OccupantResult = UserLocationResultBase.extend({
  resultType: z.literal("occupant"),
  occupantId: stringId(),
});
export type OccupantResult = z.infer<typeof OccupantResult>;

export const UserLocationResult = z.discriminatedUnion("resultType", [
  PersonResult,
  OccupantResult,
  EmailResult,
]);
export type UserLocationResult = z.infer<typeof UserLocationResult>;

export const userLocationRequestToKey = (request: UserLocationRequest) => {
  switch (request.requestType) {
    case "email":
      return `email:${request.email}`;
    case "person":
      return `person:${request.personId}`;
    case "occupant":
      return `occupant:${request.occupantId}`;
    default: {
      throw new UnreachableError(request);
    }
  }
};
