import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { Roam } from "../../../../shared/Models/Roam.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const adapter = createEntityAdapter<Roam>();

const slice = createSlice({
  name: "adminAllRoams",
  initialState: adapter.getInitialState(),
  reducers: {
    upsertRoams: adapter.upsertMany.bind(adapter),
    removeRoam: adapter.removeOne.bind(adapter),
    requestAll: () => {},
    enterRoamAsRoamgineer: (_, action: PayloadAction<number>) => {},
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
};

const adapterSelectors = adapter.getSelectors((state: RootState) => state.anyWorld.adminAllRoams);
export const selectors = {
  selectAllArray: adapterSelectors.selectAll,
  selectAllEntities: adapterSelectors.selectEntities,
  selectById: (roamId?: number) => (state: RootState) =>
    roamId ? adapterSelectors.selectById(state, roamId) : undefined,
  selectByIds: defaultMemoize(
    (ids?: number[]) => {
      const idSet = new Set(ids || []);
      return createDeepEqualSelector(adapterSelectors.selectAll, (roams) => {
        const roamEntities: { [id: number]: Roam } = {};
        for (const roam of roams) {
          if (idSet.has(roam.id)) {
            roamEntities[roam.id] = roam;
          } else {
            throw new Error("You're trying to get a Roam that doesn't exist or hasn't been loaded");
          }
        }
        return roamEntities;
      });
    },
    { equalityCheck: equal }
  ),
  selectByAccountId: defaultMemoize((accountId: number) =>
    createDeepEqualSelector(adapterSelectors.selectAll, (roams) =>
      roams.filter((roam) => roam.accountId === accountId)
    )
  ),
};
