/* cspell:ignore resock screencapturekit */
import { isNumber } from "lodash";
import semver from "semver";
import * as rpsock from "../shared/backend/server.js";
import { MiddleEndClientInfo } from "./InternalModels/MiddleEnd.js";
import { ClientIdentifiers } from "./Models/ClientIdentifiers.js";
import { IRoamFlags } from "./backend/featureflags/IRoamFlags.js";
import { getOnlyQueryParam } from "./helpers/resock.js";
import { logger } from "./infra/logger.js";
import versionJson from "./versionData.json";

const currentVersion = versionJson["currentVersion"];
const minWeb = versionJson["minVersion"]["web"];
const minElectron = versionJson["minVersion"]["electron"];
const minOther = versionJson["minVersion"]["other"];

if (
  !isNumber(currentVersion) ||
  !isNumber(minWeb) ||
  !isNumber(minElectron) ||
  !isNumber(minOther)
) {
  throw new Error("bad versions.json");
}

export const CURRENT_VERSION = currentVersion;
export const MIN_VERSION = { web: minWeb, electron: minElectron, other: minOther };

export const getProtocolVersion = (socket: rpsock.Socket): number | undefined => {
  const versionParam = getOnlyQueryParam(socket.params, "version");
  let clientVersion: number | undefined;
  if (versionParam) {
    clientVersion = Number(versionParam);
  }
  if (!Number.isInteger(clientVersion)) {
    return undefined;
  }
  return clientVersion;
};

const isValidVersion = (
  socket: rpsock.Socket,
  clientPlatform: "electron" | "web" | undefined
): { success: boolean; protocolVersion: number } => {
  const clientVersion = getProtocolVersion(socket);
  const minVersionKey = clientPlatform ?? "other";

  if (!clientVersion || clientVersion < MIN_VERSION[minVersionKey]) {
    return { success: false, protocolVersion: clientVersion ?? 0 };
  }
  return { success: true, protocolVersion: clientVersion };
};

export const disconnectIfNotValidVersion = (
  socket: rpsock.Socket,
  source: string,
  clientPlatform: "electron" | "web" | undefined,
  roamVersion: string | undefined
): { success: boolean; protocolVersion: number } => {
  const validVersion = isValidVersion(socket, clientPlatform);
  if (!validVersion.success) {
    logger.debug(
      `${source}: version ${socket.params.get("version")} out of date - disconnecting client`
    );
    socket.send("oldVersion");
    socket.close();
  }

  if (
    roamVersion &&
    semver.valid(roamVersion) &&
    semver.lt(roamVersion, "91.2.0") &&
    semver.gte(roamVersion, "91.0.0")
  ) {
    logger.debug(
      `${source}: roamVersion ${roamVersion} has bad audio problem - disconnecting client`
    );
    socket.send("oldVersion");
    socket.close();
    validVersion.success = false;
  }
  return validVersion;
};

export const supportsBigMeetingRoomType = (version: number): boolean => {
  // After version 177 is no longer supported, code tagged with (big-meeting-room) can be removed.
  return version >= 178;
};

// When version 179 is no longer supported, code tagged with (chat-streaming-sub) can be deleted.

// When version 187 is no longer supported, code tagged with (bulk-upload-progress) can be deleted.

// When version 191 is no longer supported, code tagged with (replay-info-no-server-tick) can be removed.

// When version 192 is no longer supported and the create-meeting-groups flag is on for all, code
// tagged with (meeting-groups) can be deleted.
export const meetingGroupsMinProtocolVersion = 193;

export const supportsMeetingGroups = async (
  flags: IRoamFlags,
  clientInfo?: MiddleEndClientInfo
): Promise<boolean> => {
  return (clientInfo?.protocolVersion ?? 0) >= meetingGroupsMinProtocolVersion;
};

export const supportsSecureAccessBadges = (version: number): boolean => {
  // After version 195 is no longer supported, code tagged with (secure-access-badges) can be removed.
  return version >= 195;
};

export const supportsMeetingCenter = (version: number): boolean => {
  return version >= 196;
};

export const requireAccessLinkQueryOnIntent = (version: number): boolean => {
  return version >= 200;
};

export const supportsPersonalLinkWebRouter = (version: number): boolean => {
  return version >= 202;
};

export const supportsMeetingLinkWebRouter = (version: number): boolean => {
  return version >= 202;
};

export const requireLobbyUserAgentClientIdentifiers = (version: number): boolean => {
  return version >= 202;
};

// After version 204 is no longer supported, code tagged with (sfu-probing-token) can be deleted.
export const supportsSFUProbingToken = (version: number) => version > 204;

// After version 205 is no longer supported, code tagged with (supports-pending-diagnostics) can be deleted.
export const supportsPendingDiagnosticNotes = (version: number) => version > 205;

// When version 205 is no longer supported, code tagged with (meeting-feedback-extra-occupant-id-params) can be removed.

export const requireClientData = (version: number): boolean => {
  return version >= 207;
};

// If true, supports receiving an `OnConnectData` payload when sending a "connectionComplete"
// message after connecting to middleend.
export const supportsConnectionCompleteData = (version: number): boolean => {
  return version >= 208;
};

// If true, client is expected to understand the "client" chat target type.
export const supportsClientChatAddress = (version: number): boolean => {
  return version >= 212;
};

// After version 212 is no longer supported, code tagged with (chat-blob-item-type) can be deleted.
export const supportsChatBlobItemType = (version: number): boolean => {
  return version >= 212;
};

// After version 212 is no longer supported, code tagged with (local-storage-refactor) can be deleted.

// After version 214 is no longer supported, code tagged with (confidential-messages) can be
// removed

// After version 218 is no longer supported, code tagged with (non-member-public-groups) can be
// deleted, and the (non-member-public-groups-client) tag can be deleted.
//
// For implementing these changes on mobile, pay special attention to
// (non-member-public-groups-client) for changes in the client <-> server API
export const nonMemberPublicGroupsVersion = 219;
export const supportsNonMemberPublicGroups = (version: number): boolean => {
  return version >= nonMemberPublicGroupsVersion;
};

// After version 219 is no longer supported on mobile, code tagged with (conversation-office-visits) can be deleted.
export const supportsRefreshConversation = (
  version: number,
  platform: ClientIdentifiers["platform"]
) => !["ios", "android"].includes(platform) || version > 219;

// After version 219 is no longer supported on mobile, code tagged with (office-meeting-feedback) can be deleted.
export const supportsOfficeMeetingFeedback = (
  version: number,
  platform: ClientIdentifiers["platform"]
) => !["ios", "android"].includes(platform) || version > 219;

// After version 219 is no longer supported, code tagged with (groups-threads-feature-flags) can be
// deleted

// After version 219 is no longer supported, code tagged with no-more-conversation-status can be deleted

export const iosStaysConnectedWithoutAuth = (version: number): boolean => version >= 221;

// After version 219 is no longer supported, code tagged with (remove-all-loaded) can be removed.

// After version 219 is no longer supported, code tagged with (myhosts-removal) can be removed.

// After version 223 is no longer supported, code tagged with (allow-zero-rollout-bucket) can be removed.

// After version 224 is no longer supported, code tagged with (switch-to-test-ids) can be updated

// After version 224 is no longer supported, code tagged with (remove-occupant-address) can be
// removed.
//
// Before removing code, ensure that nothing is still logging "Occupant target used"

// After version 225 is no longer supported, code tagged with (window-state-keeper) can be removed.
// After version 225 is no longer supported, code tagged with (addressserver-update-occupant) can be removed.

// After version 226 is no longer supported, code tagged with (deprecate-external-social-link-id) can be removed.

// After May 2024 and version 226 is no longer supported, code tagged with (figma-workaround) and the electron
// fork's roam_allow_disabling_screencapturekit_on_a_per-share_basis.patch file (and the .patches file reference
// to it) can be removed.

// After version 229 is no longer supported, code tagged with (check-recording-assignment-no-room) can be removed.
export const supportsCheckRecordingAssignmentNoRoom = (version: number): boolean => {
  return version >= 230;
};

// After version 229 is no longer supported, code tagged with (storage-prefs-migration) can be removed.
