import { z } from "zod";
import { numberId } from "../../zodTypes.js";
import { CalendarStatus } from "./CalendarStatus.js";
import { RoamCalendarConfig } from "./RoamCalendarConfig.js";

export const CalendarProviderId = z.enum(["off", "google", "microsoft"]);
export type CalendarProviderId = z.infer<typeof CalendarProviderId>;

/**
 * Whether or not the provider has authorized us (Roam) to act on behalf of the customer's
 * organization.
 *
 * NOTE:
 *  This was added post-google and google providers have not been migrated.
 *  Expect to see "unknown" for google calendars.
 *  A key point to figure out for google is user vs "superadmin" auth.
 *  It seems like we eventually want to migrate to the latter, at which point we may want to
 *  require a non-unknown status.
 */
export const AuthStatus = z.enum([
  /** We (Roam) have been successfully authenticated to act on the customer's behalf. */
  "authorized",
  /**
   * Roam does not have authorization to act on the customer's behalf.
   *
   * It's fine to use the "unauthorized" status if we're unsure about the auth status and would
   * like to resolve this by having the user (re)authenticate.
   */
  "unauthorized",
  /**
   * Account might be authorized, but we don't know.
   *
   * This is primarily intended as a legacy status for google calendar accounts.
   */
  "unknown",
]);
export type AuthStatus = z.infer<typeof AuthStatus>;

/**
 * Indicates that something is wrong with the account's auth status.
 *
 * This is separate from `AuthStatus` to allow for more granularity.
 */
export const AuthError = z.enum([
  /**
   * The auth mechanism we have has expired (and refreshing failed/was not possible).
   *
   * Requires the customer to re-auth/re-link their account.
   */
  "expired",
  /**
   * There is an error, but what it is is unknown.
   */
  "unknown",
]);
export type AuthError = z.infer<typeof AuthError>;

export const PersonalCalendarsSyncStatus = z.enum(["activating", "active", "error"]);
export type PersonalCalendarsSyncStatus = z.infer<typeof PersonalCalendarsSyncStatus>;

export const CreateCalendarEventsStatus = z.enum(["activating", "active", "error"]);
export type CreateCalendarEventsStatus = z.infer<typeof CreateCalendarEventsStatus>;

/**
 * Account/provider level calendar configuration.
 *
 * See also: `RoamCalendarConfig`.
 */
export const AccountCalendarConfig = z.object({
  personalCalendarSync: PersonalCalendarsSyncStatus.optional(),
  createCalendarEvents: CreateCalendarEventsStatus.optional(),
  /**
   * ID of the customer's Microsoft tenant.
   * (Roughly speaking, their Microsoft account/organization.)
   *
   * If unspecified, the tenant is unknown and/or not configured.
   *
   * NOTE:
   *  Shoving this in as a (provider) setting for now.
   *  Could potentially want this stored DB-side as its own column, but currently this should
   *  be fine.
   *  Putting this in its own column could be as part of this model (e.g. `authKey` or
   *  something), a provider-agnostic model (e.g. an `externalAccounts` table), or a Microsoft
   *  specific thing (e.g. a `microsoftTenants` table).
   */
  microsoftTenantId: z.string().optional(),
});
export type AccountCalendarConfig = z.infer<typeof AccountCalendarConfig>;

/**
 * Calendar configuration for an entire account.
 *
 * NOTE:
 *  This currently captures two main types of settings:
 *  1) Calendar provider integration settings (e.g. the state between our integration with
 *     Google for this account).
 *  2) "Product feature" settings (e.g. what we allow the account/users to do).
 */
export const CalendarSettings = z.object({
  accountId: numberId(),
  roamId: numberId(),
  providerId: CalendarProviderId,
  /**
   * Whether or not Roam has authorization to act as the customer.
   *
   * NOTE: Poorly defined for Google (see `AuthStatus` documentation).
   *
   * NOTE:
   *  This currently has significant overlap with `status`, and is aimed to start pulling out
   *  authorization-specific state from `status`.
   *  `status` can then be purely an on/off switch for the calendar feature.
   *  This might seem a bit redundant, but it lets us better encode the state of "your calendar is
   *  supposed to be on but an auth-related error is causing things to not work."
   *  It also allows us to start using `authStatus` as an explicit prerequisite to turning on a calendar.
   */
  authStatus: AuthStatus,
  /**
   * The current authentication error type, if any.
   */
  authError: AuthError.optional(),
  /**
   * Whether or not calendar features are enabled for this account (and provider).
   *
   * Under the hood, this acts as (a) the status of configuring the provider for this account
   * and (b) a global on/off switch for the account.
   * This currently boils down to whether or not the provider has successfully been linked via
   * OAuth.
   *  - This is an implicit check, as we assume that (a) the settings UI only allows the user
   *    to activate their calendar if they have successfully linked and (b) API calls will fail
   *    otherwise.
   *
   * NOTE:
   *  Legacy behavior is that this both controlled provider setup and room sync.
   *  Room sync status has been moved to be a part of `roamConfigs`.
   */
  status: CalendarStatus,
  /**
   * Provider/integration specific configuration.
   *
   * NOTE:
   *  The `CalendarSettings` model is currently 1-1 between account IDs and a provider integration,
   *  and so this configuration refers to that specific provider.
   *  If we ever wanted to support multiple providers, we'd probably want to change this to be
   *  a config per provider account.
   */
  config: AccountCalendarConfig,
  /**
   * Roam-specific calendar configurations, keyed by roam ID.
   */
  roamConfigs: z.record(RoamCalendarConfig),
});
export type CalendarSettings = z.infer<typeof CalendarSettings>;

export const CalendarSettingsHistory = z.object({
  roamId: numberId(),
  accountId: numberId(),
  timestamp: z.number().positive(),
  providerId: CalendarProviderId,
  authStatus: AuthStatus,
  authError: AuthError.optional(),
  status: CalendarStatus,
  config: z.object({}),
  roamConfigs: z.object({}),
  details: z.string().optional(),
});
export type CalendarSettingsHistory = z.infer<typeof CalendarSettingsHistory>;

export const calendarProviderDisplayName = (providerId: CalendarProviderId): string => {
  switch (providerId) {
    case "off":
      return "";
    case "google":
      return "Google";
    case "microsoft":
      return "Microsoft";
    default:
      throw new Error("Unsupported!");
  }
};
