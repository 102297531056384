import { VideoPipelineName, VideoPipelineStats } from "../../../shared/Models/VideoPipeline.js";
import { RootState } from "../../store/reducers.js";
import { getClientIdentifiers } from "../../store/slices/getClientIdentifiers.js";
import { pipelineDeltaStats } from "../video/pipelineStats.js";

export const evaluateBenchmark = (
  pipelineName: VideoPipelineName,
  stats: VideoPipelineStats[]
): "supported" | "notSupported" | "continue" => {
  let firstAfterWarmup: VideoPipelineStats | undefined;
  let prev: VideoPipelineStats | undefined;

  let intervals = 0;
  let fpsGood = 0;
  let fpsBad = 0;
  let segmentGood = 0;
  let segmentBad = 0;
  let faceGood = 0;
  let faceBad = 0;

  for (const curr of stats) {
    intervals++;
    if (!firstAfterWarmup) {
      if (curr.totalFrames > 20) {
        firstAfterWarmup = curr;
      } else if (intervals > 3) {
        // If we haven't gotten to 20 frames after four intervals, it won't end well
        return "notSupported";
      }
    } else if (prev) {
      const last = pipelineDeltaStats(prev, curr);
      if (!last) {
        continue;
      }

      if (last.fps) {
        if (last.fps >= 29.5) fpsGood++;
        if (last.fps < 25) fpsBad++;
      }
      if (last.segmentAvgMs) {
        if (last.segmentAvgMs < 10) segmentGood++;
        if (last.segmentAvgMs > 25) segmentBad++;
      }
      if (last.faceAvgMs) {
        if (last.faceAvgMs < 80) faceGood++;
        if (last.faceAvgMs > 150) faceBad++;
      }

      if (
        fpsGood > 1 &&
        segmentGood > 1 &&
        (faceGood > 1 || !["mediapipe", "mediapipeNoWorker"].includes(pipelineName))
      ) {
        return "supported";
      }

      if (
        fpsBad > 1 ||
        (fpsBad > 0 &&
          (segmentBad > 1 ||
            (faceBad > 1 && ["mediapipe", "mediapipeNoWorker"].includes(pipelineName))))
      ) {
        return "notSupported";
      }
    }
    prev = curr;
  }

  return "continue";
};

export const pipelineClientInfoString = (state: RootState): string => {
  const client = getClientIdentifiers(state);
  if (!client || client.platform !== "electron") {
    // For now, don't try to notice changes to browser that might affect pipeline support because if
    // it's a totally different browser this data won't be shared and if it's the same one the
    // versions are complex to understand
    return "web";
  }
  return client.cpuBrand ?? "unknown";
};
