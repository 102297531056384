import EventEmitter from "events";
import { inspect } from "util";
import { logger } from "../../../shared/infra/logger.js";
import { IStorage, StorageKey, StorageListener } from "./IStorage.js";
import { LocalStorage } from "./localStorage.js";

export class Storage implements IStorage {
  private eventEmitter = new EventEmitter();
  private localStorage = new LocalStorage();

  public init() {}

  public getValue<T>(key: StorageKey<T>) {
    const item = this.getTransformedItem(key);
    if (item !== null) {
      try {
        const json = JSON.parse(item);
        return key.type.parse(json);
      } catch (err) {
        logger.error({ err }, `Error parsing stored value, id: ${key.id}, item: ${item}`);
      }
    }
    return undefined;
  }

  public removeValue(key: StorageKey<any>) {
    const oldValue = this.getValue(key);

    this.localStorage.removeItem(key.id);

    this.eventEmitter.emit(key.id, {
      key,
      oldValue,
      newValue: undefined,
    });
  }

  public setValue<T, V extends T>(key: StorageKey<T>, value: NonNullable<V>) {
    const oldValue = this.getValue(key);

    this.saveValue(key, value);

    this.eventEmitter.emit(key.id, {
      key,
      oldValue,
      newValue: value,
    });
  }

  public addListener<T>(key: StorageKey<T>, listener: StorageListener<T>) {
    this.eventEmitter.addListener(key.id, listener);
  }

  public removeListener<T>(key: StorageKey<T>, listener: StorageListener<T>) {
    this.eventEmitter.removeListener(key.id, listener);
  }

  protected saveValue<T>(key: StorageKey<T>, value: T) {
    // Validate the value.
    try {
      key.type.parse(value);
    } catch (err: any) {
      throw new Error(
        `Error setting storage value, id: ${key.id}, value: ${inspect(value)}, error: ${
          err.message
        }`
      );
    }

    const item = JSON.stringify(value, undefined, 2);
    this.localStorage.setItem(key.id, item);
  }

  // TODO(local-storage-refactor) Remove this method sometime in 2024.
  private getTransformedItem<T>(key: StorageKey<T>): string | null {
    const item = this.localStorage.getItem(key.id);
    if (item) {
      let transformedItem;
      if (key.id === "clientGuid" && !item.startsWith('"')) {
        transformedItem = JSON.stringify(item);
      } else if (key.id === "homeRegion" && !item.startsWith('"')) {
        transformedItem = JSON.stringify(item);
      }

      // Save the transformed value back to local storage.
      if (transformedItem) {
        this.localStorage.setItem(key.id, transformedItem);
        return transformedItem;
      }
    }
    return item;
  }
}
