import { z } from "zod";

/**
 * Represents a specific floor.
 */
export const AccessLinkFloorLocation = z.object({
  type: z.literal("floor"),
  roamId: z.number(),
  sectionId: z.string(),
  floorId: z.number(),
});
export type AccessLinkFloorLocation = z.infer<typeof AccessLinkFloorLocation>;
