import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { Floor } from "../../../../shared/Models/Floor.js";
import { Room } from "../../../../shared/Models/Room.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

export interface RoamFloorsPayload {
  roamId: number;
  floors: Floor[];
}

export interface FloorOrderPayload {
  roamId: number;
  orderedFloorIds: number[];
  movedFloorId: number;
}

export interface AddFullFloorPayload {
  // The floor to add.
  floor: Floor;
  // The rooms to add.
  // Floor IDs in these rooms should be -1, matching the floor's ID.
  rooms: Room[];
  actorId?: string;
  windowKey: WindowKey;
}

export interface DeleteFloorPayload {
  floorId: number;
  confirmKickOut?: boolean;
  windowKey: WindowKey;
}

type CreatedFloor = {
  actorId: string;
  floor: Floor;
};

const slice = createSlice({
  name: "floor",
  initialState: {
    floors: {} as {
      [roamId: number]: Floor[];
    },
    editingFloor: false,
    addedFloors: [] as CreatedFloor[],
  },
  reducers: {
    requestAll: (_, _action: PayloadAction<number>) => {},
    requestAllForAccount: (_, _action: PayloadAction<number>) => {},
    setRoamFloors: (state, action: PayloadAction<RoamFloorsPayload>) => {
      const { roamId, floors } = action.payload;
      state.floors[roamId] = floors;
    },

    addFloor: (_state, _action: PayloadAction<Floor>) => {},
    addFloorSuccess: (state, action: PayloadAction<CreatedFloor>) => {
      state.addedFloors.push(action.payload);
    },
    // NOTE(vple):
    // Ideally this gets merged with add, but I'm not refactoring right now for simplicity.
    /**
     * A composite action that will first create a floor, then configure the rooms on the floor.
     */
    addFullFloor: (_state, _action: PayloadAction<AddFullFloorPayload>) => {},
    updateFloor: (_state, _action: PayloadAction<Floor>) => {},
    deleteFloor: (_state, _action: PayloadAction<DeleteFloorPayload>) => {},
    reorderFloors: (_state, _action: PayloadAction<FloorOrderPayload>) => {},
    clearEditingError: () => {},

    startEditingFloor: (state) => {
      state.editingFloor = true;
    },
    stopEditingFloor: (state) => {
      state.editingFloor = false;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.world.floor;
export const selectors = {
  selectAll: createDeepEqualSelector(selectSlice, (slice): Floor[] => {
    return Object.values(slice.floors).flat();
  }),
  selectEditingFloor: (state: RootState) => selectSlice(state).editingFloor,
  selectByRoam: (roamId?: number) => (state: RootState) =>
    roamId ? selectSlice(state).floors[roamId] : undefined,
  selectCountByRoam: (roamId?: number) => (state: RootState) =>
    roamId ? selectSlice(state).floors[roamId]?.length ?? 0 : 0,
  selectById: defaultMemoize((floorId?: number) =>
    createSelector(selectSlice, (slice) => {
      for (const [_, floors] of Object.entries(slice.floors)) {
        const floor = floors.find((f) => f.id === floorId);
        if (floor) {
          return floor;
        }
      }
      return undefined;
    })
  ),
  selectByIds: defaultMemoize(
    (floorIds: number[]) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        const floors: { [floorId: number]: Floor } = {};
        for (const floorList of Object.values(slice.floors)) {
          for (const floor of floorList) {
            if (floorIds.includes(floor.id)) {
              floors[floor.id] = floor;
            }
          }
        }
        return floors;
      }),
    { equalityCheck: equal }
  ),
  selectAllById: createDeepEqualSelector(selectSlice, (slice) => {
    const allFloors = new Map<number, Floor>();
    for (const floors of Object.values(slice.floors)) {
      for (const floor of floors) {
        allFloors.set(floor.id, floor);
      }
    }
    return allFloors;
  }),
  selectedAddedByActorId: defaultMemoize((actorId: string) =>
    createSelector(selectSlice, (slice) => {
      for (const addedFloor of slice.addedFloors) {
        if (addedFloor.actorId === actorId) {
          return addedFloor.floor;
        }
      }
      return undefined;
    })
  ),
};

export const FloorSelectors = selectors;
export const FloorActions = actions;
