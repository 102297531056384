import { ClientRect } from "../../helpers/dom.js";
import { PopupIds } from "./Popup.js";
import { PopupTriggerBase } from "./PopupTrigger.js";

export type AnchorParent = "mainWindow" | "meetingWindow";
export type AnchorType =
  | "audienceRequest"
  | "commandCenter"
  | "invitedDock"
  | "mapIcon"
  | "miniMapIcon"
  | "miniMapStagePresenter"
  | "mapStagePresenter"
  | "stageInvited"
  | "socialCard"
  | "pinnedChatIcon"
  | "popupIcon"
  | "recentDraftIcon"
  | "roomOccupantIcon"
  | "video"
  | "visitorDock"
  | "yourHost"
  | "overworldHost";
export type AnchorStatus = "missingClientRect" | "present" | "stale";

// Anchor clicks on these types should always open in two panel, when available
export const anchorsOpenedInTwoPanel = [
  "pinnedChatIcon",
  "popupIcon",
  "recentDraftIcon",
  "visitorDock",
];

interface AnchorTypeInfo {
  // Anchors with a higher priority level always get popups instead of those with a lower level. If
  // they are the same, the user clicking on a specific one takes precedence, or otherwise it's
  // arbitrary.
  priority: number;

  // We have a rule that every popup must have a "docking"-type of icon corresponding to it, similar
  // to the dock on macOS or the taskbar on Windows. If true, this anchor type counts for this, so
  // we know not to create yet another icon of type `popupIcon` to represent it. (For example, the
  // "Your Host" icon on the dock counts as this, but the occupant icon on the map does not).
  servesAsPopupIcon: boolean;

  // To reduce possible confusion, popup types representing groups of people must only pop up on the
  // actual dock. If false, this anchor can show a preview bubble for the last sender of a group
  // message, but when the group message is activated it must show up elsewhere.
  isOnDock: boolean;
}

const anchorTypeInfo: { [anchorType in AnchorType]: AnchorTypeInfo } = {
  audienceRequest: { priority: 5, servesAsPopupIcon: false, isOnDock: false },
  commandCenter: { priority: 6, servesAsPopupIcon: true, isOnDock: false },
  invitedDock: { priority: 4, servesAsPopupIcon: true, isOnDock: true },
  mapIcon: { priority: 5, servesAsPopupIcon: false, isOnDock: false },
  mapStagePresenter: { priority: 5, servesAsPopupIcon: false, isOnDock: false },
  miniMapIcon: { priority: 4, servesAsPopupIcon: false, isOnDock: false },
  miniMapStagePresenter: { priority: 4, servesAsPopupIcon: false, isOnDock: false },
  overworldHost: { priority: 5, servesAsPopupIcon: false, isOnDock: false },
  pinnedChatIcon: { priority: 2, servesAsPopupIcon: true, isOnDock: true },
  recentDraftIcon: { priority: 2, servesAsPopupIcon: true, isOnDock: true },
  // popupIcon doesn't serveAsPopupIcon because it IS popupIcon
  popupIcon: { priority: 1, servesAsPopupIcon: false, isOnDock: true },
  roomOccupantIcon: { priority: 5, servesAsPopupIcon: true, isOnDock: false },
  socialCard: { priority: 3, servesAsPopupIcon: true, isOnDock: false },
  stageInvited: { priority: 5, servesAsPopupIcon: false, isOnDock: false },
  video: { priority: 6, servesAsPopupIcon: false, isOnDock: false },
  visitorDock: { priority: 4, servesAsPopupIcon: true, isOnDock: true },
  yourHost: { priority: 4, servesAsPopupIcon: true, isOnDock: true },
};

export interface Anchor {
  anchorKey: string;
  anchorType: AnchorType;
  anchorStatus: AnchorStatus;
  anchorParent: AnchorParent;
  clientRect?: ClientRect;
  inMyRoom?: boolean;
}

export interface AnchorIds extends PopupIds {
  anchorParent: AnchorParent;
  anchorType: AnchorType;
  fixedPopupId?: number;
  panelId?: number;
}

export const anchorTypeServesAsPopupIcon = (anchorType: AnchorType): boolean => {
  return anchorTypeInfo[anchorType].servesAsPopupIcon;
};

export const anchorTypeIsOnDock = (anchorType: AnchorType): boolean => {
  return anchorTypeInfo[anchorType].isOnDock;
};

export const anchorTypeHigherPriority = (a1: AnchorType, a2: AnchorType) => {
  const p1 = anchorTypeInfo[a1].priority;
  const p2 = anchorTypeInfo[a2].priority;
  return p1 > p2;
};

export const stringIsAnchorType = (str: string): str is AnchorType => {
  return anchorTypeInfo[str as AnchorType] !== undefined;
};

export interface AnchorContext extends PopupTriggerBase {
  anchorType: AnchorType;
  inMyRoom?: boolean;
  panelId?: number;
  fixedPopupId?: number;
  anyPopup?: boolean;
  clientUuid?: string;
}

// If an override anchor is set on a popup, that popup will not be rendered on a normal anchor.
// The override anchor is responsible for rendering the window itself.
export type OverrideAnchor = PopupWindowAnchor | InboxAnchor;

export interface PopupWindowAnchor {
  anchorType: "popupWindow";

  /**
   * An undefined popupWindowId indicates that a new popup window should be opened, and is valid only for
   * popup triggers.
   */
  popupWindowId?: string;
}

export interface InboxAnchor {
  anchorType: "inbox";
}
