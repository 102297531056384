import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { ToneType } from "../../../../shared/Models/Tone.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface PlayTone {
  uniqueId: string;
  roomId: number;
  occupantId: string;
  toneType: ToneType;
}

const slice = createSlice({
  name: "tone",
  initialState: {
    requestedTones: [] as PlayTone[],
  },
  reducers: {
    serverEnqueuePlayTone: (state, action: PayloadAction<PlayTone>) => {
      // Dedupe
      const index = state.requestedTones.findIndex(
        (request) => request.uniqueId === action.payload.uniqueId
      );
      if (index < 0) state.requestedTones.push(action.payload);
    },
    playTones: (state, action: PayloadAction<PlayTone[]>) => {
      for (const tone of action.payload) {
        const index = state.requestedTones.findIndex(
          (request) => request.uniqueId === tone.uniqueId
        );
        if (index >= 0) state.requestedTones.splice(index, 1);
      }
    },
    clearTone: (state, action: PayloadAction<string>) => {
      const index = state.requestedTones.findIndex(
        (request) => request.uniqueId === action.payload
      );
      if (index >= 0) state.requestedTones.splice(index, 1);
    },
  },
});

export const { actions, reducer } = slice;

export const selectSlice = (state: RootState) => state.section.tone;
export const selectors = {
  selectRequestedTones: (state: RootState) => selectSlice(state).requestedTones,
  selectRequestedTonesByRoomId: defaultMemoize((roomId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      slice.requestedTones.filter((tone) => roomId && tone.roomId === roomId)
    )
  ),
  selectRequestedTonesByRoomIdAndOccupantId: defaultMemoize(
    ({ roomId, occupantId }: { roomId?: number; occupantId?: string }) =>
      createSelector(selectSlice, (slice) =>
        slice.requestedTones.find(
          (tone) => roomId && occupantId && tone.roomId === roomId && tone.occupantId === occupantId
        )
      ),
    { equalityCheck: equal }
  ),
  selectRequestedToneByUniqueId: defaultMemoize((uniqueId: string) =>
    createSelector(selectSlice, (slice) =>
      slice.requestedTones.find((tone) => tone.uniqueId === uniqueId)
    )
  ),
};
