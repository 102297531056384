export interface AmpersandJoinOptions {
  separator?: string;
  lastSeparator?: string;

  // Limit will truncate the items array if necessary and show "# more" as appropriate
  // Will never show "1 more", as it's better to include the last item instead, so will start at "2 more"
  // Limit is ignored unless >= 2
  limit?: number;
}

export const ampersandJoin = (items: string[], options?: AmpersandJoinOptions): string => {
  const separator = options?.separator ?? ", ";
  const lastSeparator = options?.lastSeparator ?? " & ";
  const limit = options?.limit;

  if (items.length === 0) return "";

  if (items.length === 1 && items[0]) return items[0];

  const limitedItems =
    limit !== undefined && limit >= 2
      ? items.slice(0, items.length === limit ? limit : limit - 1)
      : items;
  if (limitedItems.length < items.length) {
    limitedItems.push(`${items.length - limitedItems.length} more`);
  }

  const firstPart = limitedItems.slice(0, -1).join(separator);
  return `${firstPart}${lastSeparator}${limitedItems[limitedItems.length - 1]}`;
};

export const toIntOrUndefined = (input?: string): number | undefined => {
  if (!input) return undefined;
  const result = parseInt(input);
  return isNaN(result) ? undefined : result;
};
