import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

export interface OpenGroupsPayload {
  windowKey: WindowKey;
}

const slice = createSlice({
  name: "groupView",
  initialState: {
    selectedGroupId: undefined as string | undefined,
    creatingGroup: false,
  },
  reducers: {
    setSelectedGroupId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedGroupId = action.payload;
      if (state.selectedGroupId) {
        state.creatingGroup = false;
      }
    },
    setCreatingGroup: (state, action: PayloadAction<boolean>) => {
      state.creatingGroup = action.payload;
      if (action.payload) {
        state.selectedGroupId = undefined;
      }
    },
    openGroups: (state, action: PayloadAction<OpenGroupsPayload>) => {
      // implemented in saga
    },
    openChatWithGroup: (state, action: PayloadAction<string>) => {
      // implemented in saga
    },
  },
});

export const { actions, reducer } = slice;
const selectSlice = (state: RootState) => state.anyWorld.groupView;
export const selectors = {
  selectSelectedGroupId: createSelector(selectSlice, (slice) => slice.selectedGroupId),
  selectCreatingGroup: createSelector(selectSlice, (slice) => slice.creatingGroup),
};
