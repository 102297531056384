import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { ChatMessageSearchResult } from "../../../../shared/Models/SearchResult.js";
import { getMessageKey } from "../../../../shared/helpers/chat.js";
import { createDeepEqualSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

export interface ToggleBookmarkPayload {
  chatId: string;
  threadTimestamp?: number;
  timestamp: number;
  windowKey: WindowKey;
}

const adapter = createEntityAdapter<ChatMessageSearchResult>({
  selectId: (res: ChatMessageSearchResult) =>
    getMessageKey(res.chatId, res.threadTimestamp, res.timestamp),
});
const bookmarkSelectors = adapter.getSelectors();

const slice = createSlice({
  name: "chatBookmark",
  initialState: {
    bookmarkResults: adapter.getInitialState(),

    // Incremented to indicate that the inbox tab should visually signal itself
    signalNonce: 0,
  },
  reducers: {
    requestBookmarks: (state, action: PayloadAction<void>) => {},
    addBookmark: (state, action: PayloadAction<ToggleBookmarkPayload>) => {},
    deleteBookmark: (state, action: PayloadAction<ToggleBookmarkPayload>) => {},

    setBookmarkResults: (state, action: PayloadAction<ChatMessageSearchResult[]>) => {
      adapter.setAll(state.bookmarkResults, action.payload);
    },
    clearBookmarkResults: (state) => {
      adapter.removeAll(state.bookmarkResults);
    },

    signalTab: (state) => {
      state.signalNonce++;
    },

    resetSignal: (state) => {
      state.signalNonce = 0;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.chat.chatBookmark;
export const selectors = {
  selectAllBookmarks: createDeepEqualSelector(selectSlice, (slice) =>
    bookmarkSelectors.selectAll(slice.bookmarkResults)
  ),

  selectIsBookmarked: (id?: string) => (state: RootState) =>
    id ? selectSlice(state).bookmarkResults.entities[id] !== undefined : false,

  selectSignalNonce: (state: RootState) => selectSlice(state).signalNonce,
};

export const ChatBookmarkSelectors = selectors;
export const ChatBookmarkActions = actions;
