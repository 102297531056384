import { z } from "zod";
import { RoomType, isFocusRoomType } from "../../../shared/Models/Room.js";
import { isElectron } from "../../../shared/api/environment.js";

export const WindowType = z.enum([
  "audioParticipants",
  "calendar",
  "closedCaption",
  "elevator",
  "inbox",
  "item",
  "main",
  "meeting",
  "meetingChat",
  "modalOverlay",
  "overlay",
  "popup",
  "preferences",
  "share",
  "shareControls",
  "videoParticipants",
]);
export type WindowType = z.infer<typeof WindowType>;

export const WindowKey = z.string().brand("WindowKey");
export type WindowKey = z.infer<typeof WindowKey>;

export const fromWindowKey = (windowKey: WindowKey) => {
  const [windowTypeStr, windowId] = windowKey.split("|", 2);
  const windowType = WindowType.parse(windowTypeStr);
  if (!windowType || windowId === "") {
    throw new Error(`The windowKey ${windowKey} is not valid.`);
  }
  return { windowType, windowId };
};

export const toWindowKey = (windowType: WindowType, windowId?: string) => {
  if (windowId === "") {
    throw new Error(`The windowId cannot be an empty string.`);
  }
  return WindowKey.parse(windowId ? `${windowType}|${windowId}` : windowType);
};

export const audioParticipantsWindowKey = toWindowKey("audioParticipants");
export const calendarWindowKey = toWindowKey("calendar");
export const closedCaptionWindowKey = toWindowKey("closedCaption");
export const elevatorWindowKey = toWindowKey("elevator");
export const inboxWindowKey = toWindowKey("inbox");
export const mainWindowKey = toWindowKey("main");
export const meetingWindowKey = toWindowKey("meeting");
export const meetingChatWindowKey = toWindowKey("meetingChat");
export const modalOverlayWindowKey = toWindowKey("modalOverlay");
export const overlayWindowKey = toWindowKey("overlay");
export const preferencesWindowKey = toWindowKey("preferences");
export const shareWindowKey = toWindowKey("share");
export const shareControlsWindowKey = toWindowKey("shareControls");
export const videoParticipantsWindowKey = toWindowKey("videoParticipants");

export const getRoomWindowKey = (roomType: RoomType | undefined): WindowKey => {
  if (isElectron) {
    return isFocusRoomType(roomType) ? meetingWindowKey : mainWindowKey;
  } else {
    return mainWindowKey;
  }
};
