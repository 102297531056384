/* eslint-disable prefer-arrow/prefer-arrow-functions */
import equal from "fast-deep-equal/es6/index.js";
import * as Reselect from "reselect";
import { RootState } from "../store/reducers.js";

const myCreateDeepEqualSelector = Reselect.createSelectorCreator(Reselect.defaultMemoize, {
  resultEqualityCheck: equal,
});

export function createDeepEqualSelector<Input, Result>(
  inputSelector: (state: RootState) => Input,
  resultFunc: (input: Input) => Result
): (state: RootState) => Result;

export function createDeepEqualSelector<Input1, Input2, Result>(
  inputSelector1: (state: RootState) => Input1,
  inputSelector2: (state: RootState) => Input2,
  resultFunc: (input1: Input1, input2: Input2) => Result
): (state: RootState) => Result;

export function createDeepEqualSelector<Input1, Input2, Input3, Result>(
  inputSelector1: (state: RootState) => Input1,
  inputSelector2: (state: RootState) => Input2,
  inputSelector3: (state: RootState) => Input3,
  resultFunc: (input1: Input1, input2: Input2, input3: Input3) => Result
): (state: RootState) => Result;

export function createDeepEqualSelector<Input1, Input2, Input3, Input4, Result>(
  inputSelector1: (state: RootState) => Input1,
  inputSelector2: (state: RootState) => Input2,
  inputSelector3: (state: RootState) => Input3,
  inputSelector4: (state: RootState) => Input4,
  resultFunc: (input1: Input1, input2: Input2, input3: Input3, input4: Input4) => Result
): (state: RootState) => Result;

export function createDeepEqualSelector<Result>(...params: any): (state: RootState) => Result {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return myCreateDeepEqualSelector(...params);
}

export function createSelector<Input, Result>(
  inputSelector: (state: RootState) => Input,
  resultFunc: (input: Input) => Result,
  options?: { memoizeOptions: Reselect.DefaultMemoizeOptions }
): (state: RootState) => Result;

export function createSelector<Input1, Input2, Result>(
  inputSelector1: (state: RootState) => Input1,
  inputSelector2: (state: RootState) => Input2,
  resultFunc: (input1: Input1, input2: Input2) => Result,
  options?: { memoizeOptions: Reselect.DefaultMemoizeOptions }
): (state: RootState) => Result;

export function createSelector<Input1, Input2, Input3, Result>(
  inputSelector1: (state: RootState) => Input1,
  inputSelector2: (state: RootState) => Input2,
  inputSelector3: (state: RootState) => Input3,
  resultFunc: (input1: Input1, input2: Input2, input3: Input3) => Result,
  options?: { memoizeOptions: Reselect.DefaultMemoizeOptions }
): (state: RootState) => Result;

export function createSelector<Input1, Input2, Input3, Input4, Result>(
  inputSelector1: (state: RootState) => Input1,
  inputSelector2: (state: RootState) => Input2,
  inputSelector3: (state: RootState) => Input3,
  inputSelector4: (state: RootState) => Input4,
  resultFunc: (input1: Input1, input2: Input2, input3: Input3, input4: Input4) => Result,
  options?: { memoizeOptions: Reselect.DefaultMemoizeOptions }
): (state: RootState) => Result;

export function createSelector(...params: any) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return Reselect.createSelector(...params);
}
