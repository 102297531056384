import { combineReducers } from "redux";
import { lobbyReducer } from "../lobby/store/lobbyReducer.js";
import { reducer as accessLinkReducer } from "./slices/accessLinkSlice.js";
import { reducer as demoBotsReducer } from "./slices/demoBotsSlice.js";
import { reducer as floorReducer } from "./slices/floorSlice.js";
import { reducer as hostingReducer } from "./slices/hostingSlice.js";
import { reducer as occupantReducer } from "./slices/occupantSlice.js";
import { reducer as recordingReducer } from "./slices/recordingSlice.js";
import { reducer as toastReducer } from "./slices/toastSlice.js";
import { reducer as visitingReducer } from "./slices/visitingSlice.js";
import { reducer as worldReducer } from "./slices/worldSlice.js";

export const reducer = combineReducers({
  world: worldReducer,
  occupant: occupantReducer,
  floor: floorReducer,
  accessLink: accessLinkReducer,
  visiting: visitingReducer,
  toast: toastReducer,
  hosting: hostingReducer,
  recording: recordingReducer,
  lobby: lobbyReducer,
  demoBots: demoBotsReducer,
});
