import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VirtualBackground } from "../../../../shared/Models/VirtualBackground.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface VirtualBackgroundEmailImageNamePayload {
  email: string;
  imageName: string;
}

const slice = createSlice({
  name: "virtualBackground",
  initialState: {
    virtualBackgrounds: [] as VirtualBackground[],
  },
  reducers: {
    requestAll: () => {},
    setAllVirtualBackgrounds: (state, action: PayloadAction<VirtualBackground[]>) => {
      state.virtualBackgrounds = action.payload;
    },
    appendVirtualBackground: (state, action: PayloadAction<VirtualBackground>) => {
      state.virtualBackgrounds.push(action.payload);
    },

    uploadVirtualBackground: (
      state,
      action: PayloadAction<VirtualBackgroundEmailImageNamePayload>
    ) => {},
    deleteVirtualBackground: (
      state,
      action: PayloadAction<VirtualBackgroundEmailImageNamePayload>
    ) => {},
    deleteVirtualBackgroundSuccess: (state, action: PayloadAction<string>) => {
      const imageName = action.payload;
      state.virtualBackgrounds = state.virtualBackgrounds.filter((v) => v.imageName !== imageName);
    },
  },
});

export const { actions, reducer } = slice;
const selectSlice = (state: RootState) => state.anyWorld.virtualBackground;
export const selectors = {
  selectVirtualBackgrounds: createSelector(selectSlice, (slice) => slice.virtualBackgrounds),
};

export const VirtualBackgroundActions = actions;
export const VirtualBackgroundSelectors = selectors;
