{
  "ACCOUNTS_BACKEND_PORT": 5554,

  "CRM_BACKEND_PORT": 5567,

  "DINGDONG_BACKEND_PORT": 5570,
  "DINGDONG_EXPO_WEB_PORT": 19006,

  "DYNASCORE_DEV_PORT": 3333,
  "DYNASCORE_ELECTRON_PORT": 5551,
  "DYNASCORE_BACKEND_PORT": 5553,
  "DYNASCORE_EXTERNALAPI_PORT": 5556,

  "ROAM_MAGICAST_PORT": 5553,
  "ROAM_BACKEND_PORT": 5555,
  "ROAM_SECTION_PORT_1": 5558,
  "ROAM_SECTION_PORT_2": 5559,
  "ROAM_MIDDLEEND_PORT": 5560,
  "ROAM_MIDDLEEND_PORT_2": 5561,
  "ROAM_HOLO_PORT": 5562,
  "ROAM_OVERWORLD_PORT": 5563,
  "ROAM_USER_LOCATION_PORT": 5564,
  "ROAM_CHAT_PORT": 5565,
  "ROAM_CHAT_PORT_2": 5566,
  "ROAM_MAGICAST_ELECTRON_WEBPACK_LOG_PORT": 9001,
  "ROAM_NOTIFICATION_PORT": 5568,
  "ROAM_SOCIAL_PORT": 5569,
  "ROAM_ZONE_PORT": 5572,
  "ROAM_STATIC_SERVER_PORT": 5574,
  "ROAM_CHAT_MESSAGES_PORT": 5575,
  "ROAM_CHAT_MESSAGES_PORT_2": 5576,
  "ROAM_ADDRESS_SERVER_PORT": 5577,
  "ROAM_ADDRESS_INFO_PORT": 5578,
  "ROAM_ADDRESS_INFO_PORT_2": 5579,
  "ROAM_CHAT_SERVER_PORT": 5580,
  "ROAM_TEAM_ROAM_PORT": 5581,
  "ROAM_CHAT_PIPELINE_PORT": 5582,
  "ROAM_CHAT_PIPELINE_PORT_2": 5583,
  "ROAM_AUTOBOARDING_PORT": 5584,
  "ROAM_CHATSEARCH_PORT": 5585,
  "ROAM_CALENDAR_PORT": 5586,
  "ROAM_APPS_PORT": 5587,
  "ROAM_TAGS_PORT": 5588,
  "CROAM_SERVER_PORT": 5589,
  "ROAM_CHAT_COMPLIANCE_PORT": 5590,
  "ROAM_DEMOBOTS_PORT": 5591,
  "ROAM_EMAILS_PORT": 5592,
  "ROAM_PUSH_PORT": 5593,
  "ROAM_SIDEKICK_PORT": 5594,

  "ROAM_BILLING_PORT": 5573,

  "_Zone_App_Ports": "Reserved for first-party Zone Apps, 5900-5999. Only used locally, but it's probably better not to collide anyway.",
  "ZONE_INVITE_APP": 5900,

  "GORUSH_PORT": 8088,

  "LISTENING_BACKEND_PORT": 8003,
  "FILES_PORT": 8004,

  "NUCLEUS_PORT": 8080,
  "SFU_STATS_PORT": 9085,
  "SFU_CLIENTSTATS_PORT": 9089,

  "_warning": "These port numbers are still hardcoded in some places! Make sure to update them elsewhere."
}
