import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import {
  crpc_addExl_CreateSocialLink,
  crpc_addExl_GetSocialLinksForAddressId,
  crpc_addExl_RemoveSocialLink,
} from "../../../../shared/AddressMessages/ClientRpcExternalSocialLink.js";
import { converters } from "../../../../shared/addressserver/externalSocialLinkHelpers.js";
import { ExternalSocialLink, SocialNetwork } from "../../../../shared/Models/ExternalSocialLink.js";
import { ProfileBadgeField } from "../../../../shared/Models/ProfileBadgeField.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

type SocialLinks = {
  [sn in SocialNetwork]?: Omit<ExternalSocialLink, "socialNetwork" | "addressId" | "id">;
};
type AddressIdToSocialLinks = { [sn in string]?: SocialLinks };

const slice = createSlice({
  name: "externalSocialLinks",
  initialState: {
    addressIdToLinks: {} as AddressIdToSocialLinks,
  },

  reducers: {
    createSocialLink: (
      state,
      action: PayloadAction<Omit<crpc_addExl_CreateSocialLink, "kind">>
    ) => {},
    deleteSocialLink: (
      state,
      action: PayloadAction<Omit<crpc_addExl_RemoveSocialLink, "kind">>
    ) => {
      const { addressId, socialNetwork } = action.payload;
      // In addition to sending a remote request, also delete the link from
      // the local store immediately
      const socialNetworkToLink = state.addressIdToLinks[addressId];
      if (socialNetworkToLink) {
        socialNetworkToLink[socialNetwork] = undefined;
      }
    },

    /**
     * @deprecated use ProfileBadgeActions.getProfileBadgeFieldsForAddressId instead
     */
    getSocialLinksForAddressId: (
      state,
      action: PayloadAction<Omit<crpc_addExl_GetSocialLinksForAddressId, "kind">>
    ) => {},

    setSocialLink: (state, action: PayloadAction<ExternalSocialLink>) => {
      const { addressId, socialNetwork, link, username } = action.payload;
      const links: SocialLinks = state.addressIdToLinks[addressId] ?? {};
      links[socialNetwork] = { link, username };
      state.addressIdToLinks[addressId] = links;
    },
    setSocialLinks: (state, action: PayloadAction<ExternalSocialLink[]>) => {
      for (const { addressId, socialNetwork, link, username } of action.payload) {
        const links: SocialLinks = state.addressIdToLinks[addressId] ?? {};
        links[socialNetwork] = { link, username };
        state.addressIdToLinks[addressId] = links;
      }
    },
    setSocialLinksFromFields: (state, action: PayloadAction<ProfileBadgeField[]>) => {
      for (const { addressId, key, value: username } of action.payload) {
        if (key.keyType !== "social") {
          continue;
        }

        const links: SocialLinks = state.addressIdToLinks[addressId] ?? {};
        const link = converters[key.key].urlFromUsername(username);
        links[key.key] = { link, username };
        state.addressIdToLinks[addressId] = links;
      }
    },
  },
});

export const { actions, reducer } = slice;
export const ExternalSocialLinkActions = actions;
export const ExternalSocialLinkReducer = reducer;

const selectSlice = (state: RootState) => state.anyWorld.externalSocialLinks;
export const selectors = {
  selectSocialLink: defaultMemoize((addressId: string, socialNetwork: SocialNetwork) =>
    createSelector(selectSlice, (slice) => {
      return slice.addressIdToLinks[addressId]?.[socialNetwork];
    })
  ),
};

export const ExternalSocialLinkSelectors = selectors;
