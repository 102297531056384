import { z } from "zod";
import { uuid } from "./zodTypes.js";

export const MuteChatUntil = z.union([z.number().positive(), z.literal("forever")]);
export type MuteChatUntil = z.infer<typeof MuteChatUntil>;

// Used when updating the MuteChatUntil by supplying a number of seconds from now to mute the chat
export const MuteChatForSec = z.union([
  z.number().positive(),
  z.literal("forever"),
  z.literal("off"),
]);
export type MuteChatForSec = z.infer<typeof MuteChatForSec>;

export const ChatPersonalSettings = z.object({
  muteUntil: MuteChatUntil.optional(),
  pinOrder: z.number().optional(),
});
export type ChatPersonalSettings = z.infer<typeof ChatPersonalSettings>;

export const PersonalSettingsUpdate = ChatPersonalSettings.extend({
  chatId: uuid(),
  addressIds: uuid().array(),
});
export type PersonalSettingsUpdate = z.infer<typeof PersonalSettingsUpdate>;
