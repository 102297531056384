import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { Chat } from "../../../../shared/Models/Chat";
import { SentChatMessage } from "../../../../shared/Models/ChatMessage";
import { RootState } from "../../../store/reducers";

export const storiesEntityAdapter = createEntityAdapter<SentChatMessage>({
  selectId: (m) => m.timestamp,
  sortComparer: (l, r) => r.timestamp - l.timestamp,
});

export interface SetAllStoriesPayload {
  stories: Array<{ chat: Chat; stories: SentChatMessage[] }>;
  latestUnreadBySender: { [emailAddress: string]: { chatId: string; timestamp: number } };
}

export interface UpsertNewStoryPayload {
  email: string;
  story: SentChatMessage;
}

export interface MarkReadPayload {
  chatId: string;
  timestamp: number;
}

export interface StoryKey {
  chatId: string;
  timestamp: number;
}

export interface StoriesForChat {
  stories: EntityState<SentChatMessage>;
  read: { [timestamp: number]: boolean };
}

export type StoriesByChat = {
  [chatId: string]: StoriesForChat;
};

export type LatestUnreadBySender = { [emailAddress: string]: StoryKey };

export type StoryState = ReturnType<typeof slice.getInitialState>;
const slice = createSlice({
  name: "story",
  initialState: {
    chats: {} as Record<string, Chat>,
    stories: {} as StoriesByChat,
    latestUnreadBySender: {} as LatestUnreadBySender,
  },
  reducers: {
    setAllStories: (state, action: PayloadAction<SetAllStoriesPayload>) => {
      const { stories, latestUnreadBySender } = action.payload;
      state.stories = {};
      state.chats = {};
      state.latestUnreadBySender = latestUnreadBySender;

      for (const { chat, stories: chatStories } of stories) {
        state.chats[chat.id] = chat;
        state.stories[chat.id] = {
          stories: storiesEntityAdapter.setMany(
            storiesEntityAdapter.getInitialState(),
            chatStories
          ),
          read: {},
        };
      }
    },
    upsertNewStory: (state, action: PayloadAction<UpsertNewStoryPayload>) => {
      const { story, email } = action.payload;
      const stories = state.stories[story.chatId];
      if (stories) {
        stories.stories = storiesEntityAdapter.setOne(stories.stories, story);
        state.stories[story.chatId] = stories;
        state.latestUnreadBySender[email] = {
          chatId: story.chatId,
          timestamp: story.timestamp,
        };
      }
    },
    markRead: (state, action: PayloadAction<MarkReadPayload>) => {
      const { chatId, timestamp } = action.payload;
      const stories = state.stories[chatId];
      if (stories) {
        stories.read[timestamp] = true;
      }
    },
  },
});

const selectSlice = (state: RootState) => state.chat.story;

export const { actions, reducer } = slice;

export const selectors = {
  selectStoriesByChat: (state: RootState) => selectSlice(state).stories,
  selectAllStoryChats: (state: RootState) => selectSlice(state).chats,
  selectStoryChatById: (chatId: string | undefined) => (state: RootState) =>
    chatId ? selectSlice(state).chats[chatId] : undefined,
  selectStoryById:
    (chatId: string | undefined, timestamp: number | undefined) => (state: RootState) =>
      chatId && timestamp
        ? selectSlice(state).stories[chatId]?.stories?.entities[timestamp]
        : undefined,
  selectAllLatestBySender: (state: RootState) => selectSlice(state).latestUnreadBySender,
  selectLatestBySender: (addressId: string | undefined) => (state: RootState) =>
    addressId ? selectSlice(state).latestUnreadBySender[addressId] : undefined,
};

export const StoryActions = actions;
export const StorySelectors = selectors;
