/**
 * We've enabled typescripts `noUncheckedIndexedAccess` which adds much safety but is
 * dumber about type narrowing. This issue is a known limitation and will hopefully be fixed
 * in the future: https://github.com/microsoft/TypeScript/search?q=noUncheckedIndexedAccess+type+narrow&type=issues
 *
 * In the meantime, you can use this Error Type to please typescript in impossible scenarios; eg:
 *
 *   // before
 * const arr = ['a', 'b'];
 * return arr[0]  // TS ERROR!!
 *
 *
 *   // after
 * const arr = ['a', 'b'];
 * const arr0 = arr[0];
 * if (!arr0) {
 *    throw new TypeNarrow()
 * }
 * return arr0;
 *
 *
 * When this TS issue is resolved in the future, we can refactor this thing out.
 *
 */
export class TypeNarrowError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.name = "TypeNarrowError";
  }
}
