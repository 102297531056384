import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HostAvailability } from "../../../../shared/Models/HostAvailability.js";
import { PersonPreview, RoamPreview } from "../../../../shared/Models/RoamPreview.js";
import { RootState } from "../../../store/reducers.js";

export interface PeopleByFloorPayload {
  roamId: number;
  peopleByFloor: {
    [floorId: number]: PersonPreview[];
  };
}

const slice = createSlice({
  name: "roamPreview",
  initialState: {
    roamPreviews: {} as { [roamId: number]: RoamPreview },
    hostAvailability: [] as HostAvailability[],
  },
  reducers: {
    refreshAll: (_) => {},

    setRoamPreviews: (state, action: PayloadAction<RoamPreview[]>) => {
      const roamPreviews = action.payload;
      for (const preview of roamPreviews) {
        state.roamPreviews[preview.roamId] = preview;
      }
    },
    setPeopleByFloor: (state, action: PayloadAction<PeopleByFloorPayload>) => {
      const { roamId, peopleByFloor } = action.payload;
      const roamPreview = state.roamPreviews[roamId];
      if (roamPreview) {
        for (const [floorId, people] of Object.entries(peopleByFloor)) {
          roamPreview.personPreviews[floorId] = people;
        }
      }
    },

    serverAllHostAvailability: (state, action: PayloadAction<HostAvailability[]>) => {
      state.hostAvailability = action.payload;
    },
    serverUpdateHostAvailability: (state, action: PayloadAction<HostAvailability[]>) => {
      for (const hostAvailability of action.payload) {
        const { hostPersonId, currentRoamId, available } = hostAvailability;
        const i = state.hostAvailability.findIndex((ha) => ha.hostPersonId === hostPersonId);

        if (i < 0) {
          // If a host is not in a Roam, this is indicated by not storing them at all
          if (currentRoamId) {
            state.hostAvailability.push(hostAvailability);
          }
        } else {
          if (!currentRoamId) {
            state.hostAvailability.splice(i, 1);
          } else {
            state.hostAvailability[i] = hostAvailability;
          }
        }
      }
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.overworld.roamPreview;
export const selectors = {
  selectPreviewByRoamId: (roamId: number | undefined) => (state: RootState) =>
    roamId ? selectSlice(state).roamPreviews[roamId] : undefined,
  selectAllHostAvailability: (state: RootState) => selectSlice(state).hostAvailability,
};
