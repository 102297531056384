import { Card } from "../Models/Card.js";
import { CardUpdate } from "../Models/CardUpdate.js";

export const cardWithUpdate = (card: Card, update: CardUpdate): Card => {
  switch (update.updateKind) {
    case "maximized":
      return {
        ...card,
        maximized: update.maximized,
      };
    case "position":
      return {
        ...card,
        x: update.x,
        y: update.y,
        width: update.width,
        height: update.height,
      };
    case "toFront":
      return {
        ...card,
        z: update.z,
      };
    case "text":
      return card.cardType === "Text" ? { ...card, data: { content: update.textContent } } : card;
    case "loop":
      if (card.cardType === "Item") {
        return {
          ...card,
          data: {
            ...card.data,
            loop: update.loop,
          },
        };
      }
      return card;
    default:
      return card;
  }
};
