import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store/reducers.js";

export interface MeetingFeedbackPromptPayload {
  meetingGuid: string;
  allParticipants: { [occupantId: string]: { displayName: string; displayImageUrl: string } };
  sectionId: string;
  roomId?: number;
  roomName?: string;
  roomType?: string;
  officeOwnerParticipantKey?: string;
  place: string;
  additionalPredefinedIssues?: string[];
  startTime?: number;
  endTime?: number;
  occupantId?: string;
  participationTimeSeconds?: number;
}

export type StarRatingValue = 1 | 2 | 3 | 4 | 5;

export interface MeetingFeedbackSubmissionPayload {
  starRating: StarRatingValue | undefined;
  textResponse: string;
  selectedIssues: string[];
}

export interface AuditoriumParticipation {
  totalTimeSeconds: number;
  lastEnterTimeSeconds: number | undefined;
}

export interface SubmitFeedbackPayload {
  surface: "OnMap";
  isExplicitSubmission: boolean;
}

const slice = createSlice({
  name: "meetingFeedback",
  initialState: {
    meetingFeedbackPrompt: undefined as MeetingFeedbackPromptPayload | undefined,
    showMeetingFeedback: false as boolean,
    cancelSubmitMeetingFeedback: false as boolean,
    meetingFeedbackSubmission: {
      starRating: undefined,
      textResponse: "",
      selectedIssues: [],
    } as MeetingFeedbackSubmissionPayload,
    auditoriumParticipation: {
      totalTimeSeconds: 0,
      lastEnterTimeSeconds: undefined,
    } as AuditoriumParticipation,
  },
  reducers: {
    setStarRating: (state, action: PayloadAction<StarRatingValue>) => {
      state.meetingFeedbackSubmission.starRating = action.payload;
    },
    setTextResponse: (state, action: PayloadAction<string>) => {
      state.meetingFeedbackSubmission.textResponse = action.payload;
    },
    setSelectedIssues: (state, action: PayloadAction<string[]>) => {
      state.meetingFeedbackSubmission.selectedIssues = action.payload;
    },
    cancelSubmitMeetingFeedback: (state) => {
      state.cancelSubmitMeetingFeedback = true;
    },
    submitMeetingFeedback: (state, _: PayloadAction<SubmitFeedbackPayload>) => {
      state.cancelSubmitMeetingFeedback = false;
    },
    addMeetingFeedbackSuggestion: (state, action: PayloadAction<MeetingFeedbackPromptPayload>) => {
      const feedbackPrompt = action.payload;
      state.meetingFeedbackPrompt = feedbackPrompt;
    },
    showMeetingFeedbackPrompt: (state) => {
      state.showMeetingFeedback = true;
    },
    hideMeetingFeedbackPrompt: (state) => {
      state.showMeetingFeedback = false;
    },
    clearMeetingFeedbackPrompt: (state) => {
      state.showMeetingFeedback = false;
      state.meetingFeedbackPrompt = undefined;
      state.meetingFeedbackSubmission.starRating = undefined;
      state.meetingFeedbackSubmission.textResponse = "";
      state.meetingFeedbackSubmission.selectedIssues = [];
    },
    enteredAuditorium: (state) => {
      state.auditoriumParticipation.lastEnterTimeSeconds = performance.now() / 1000;
      state.auditoriumParticipation.totalTimeSeconds = 0;
    },
    leftAuditorium: (state) => {
      if (!state.auditoriumParticipation.lastEnterTimeSeconds) {
        // Something got messed up, just bail out.
        return;
      }
      state.auditoriumParticipation.totalTimeSeconds =
        performance.now() / 1000 - state.auditoriumParticipation.lastEnterTimeSeconds;
      state.auditoriumParticipation.lastEnterTimeSeconds = undefined;
    },
  },
});

export const { actions, reducer } = slice;
export const MeetingFeedbackActions = actions;
export const MeetingFeedbackReducer = reducer;

export const selectSlice = (state: RootState) => state.section.meetingFeedback;

export const selectors = {
  selectMeetingFeedbackPrompt: (state: RootState) => selectSlice(state).meetingFeedbackPrompt,
  selectShowMeetingFeedback: (state: RootState) => selectSlice(state).showMeetingFeedback,
  selectCancelSubmitMeetingFeedback: (state: RootState) =>
    selectSlice(state).cancelSubmitMeetingFeedback,
  selectMeetingFeedbackSubmission: (state: RootState) =>
    selectSlice(state).meetingFeedbackSubmission,
  selectTotalAuditoriumParticipationTimeSeconds: (state: RootState) =>
    selectSlice(state).auditoriumParticipation.totalTimeSeconds,
};
export const MeetingFeedbackSelectors = selectors;
