import { combineReducers } from "redux";
import { reducer as floorOccupantCountsReducer } from "./slices/floorOccupantCountsSlice.js";
import { reducer as overworldReducer } from "./slices/overworldSlice.js";
import { reducer as personalRoamPreviewReducer } from "./slices/personalRoamPreviewSlice.js";
import { reducer as planetReducer } from "./slices/planetSlice.js";
import { reducer as recommendationsReducer } from "./slices/recommendationsSlice.js";
import { reducer as roamPreviewReducer } from "./slices/roamPreviewSlice.js";

export const reducer = combineReducers({
  overworld: overworldReducer,
  planet: planetReducer,
  roamPreview: roamPreviewReducer,
  personalRoamPreview: personalRoamPreviewReducer,
  floorOccupantCounts: floorOccupantCountsReducer,
  recommendations: recommendationsReducer,
});
