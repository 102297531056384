import React from "react";
import IconSpinner from "../../icons/IconSpinner.svg";
import spinnerAnimation from "../assets/spinnerAnimation.svg";
import { keyframes, styled } from "../styles/theme.js";

type Type =
  // Logo style uses a fancy animated version of the Roam logo. Default style.
  | "logo"

  // Plain style is a more traditional spinner, and should be only used if the
  // logo style is not appropriate, such as when overlaying the spinner on top
  // of the IconTimer.
  | "plain";

interface Props {
  type?: Type;
  size?: string | number;
}

const Spinner: React.FC<Props> = ({ type, ...props }) => {
  return type === "plain" ? (
    <PlainSpinnerContainer {...props}>
      <IconSpinner />
    </PlainSpinnerContainer>
  ) : (
    <BaseSpinnerContainer {...props}>
      <img src={spinnerAnimation} />
    </BaseSpinnerContainer>
  );
};

type BaseSpinnerContainerProps = Omit<Props, "style">;

const BaseSpinnerContainer = styled.div<BaseSpinnerContainerProps>`
  display: flex;
  font-size: ${({ size }) => size ?? "1em"};
  width: ${({ size }) => size ?? "1em"};
  height: ${({ size }) => size ?? "1em"};
`;
BaseSpinnerContainer.displayName = "BaseSpinnerContainer";

const RotateAnimation = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

const PlainSpinnerContainer = styled(BaseSpinnerContainer)`
  animation: 1.5s ${RotateAnimation} linear infinite;
`;
PlainSpinnerContainer.displayName = "PlainSpinnerContainer";

export default Spinner;
