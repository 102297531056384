import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  crpc_pcalEv_CreatePersonalEvent,
  crpc_pcalEv_ReserveRoomForEvent,
} from "../../../../shared/CalendarMessages/ClientRpcPersonalCalendarEvents.js";
import { ClientPersonalCalendarEvent } from "../../../../shared/Models/calendar/client/ClientPersonalCalendarEvent.js";
import { PersonalCalendarPartial } from "../../../../shared/Models/PersonalCalendar.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

export type PersonalCalendarReserveRoomPayload = Omit<crpc_pcalEv_ReserveRoomForEvent, "kind">;
export type PersonalCalendarCreateEventPayload = Omit<crpc_pcalEv_CreatePersonalEvent, "kind"> & {
  windowKey: WindowKey;
};

const eventsAdapter = createEntityAdapter<ClientPersonalCalendarEvent>({
  sortComparer: (e1, e2) => {
    // Sort primarily by start time, breaking ties with end time and then title
    // Just to try to give a consistent sort across multiple runs
    const startDiff = e1.start - e2.start;
    if (startDiff !== 0) {
      return startDiff;
    }

    const endDiff = e1.end - e2.end;
    if (endDiff !== 0) {
      return endDiff;
    }

    return (e1.title ?? "").localeCompare(e2.title ?? "");
  },
});

const calendarsAdapter = createEntityAdapter<PersonalCalendarPartial>({
  selectId: (cal: PersonalCalendarPartial) => `${cal.calendarId}|${cal.providerId}`,
});

const slice = createSlice({
  name: "personalCalendars",
  initialState: {
    events: eventsAdapter.getInitialState(),
    calendars: calendarsAdapter.getInitialState(),
    eventsUpdatedTime: undefined as number | undefined,

    nextEventNotification: undefined as ClientPersonalCalendarEvent | undefined,
    dismissedNextEventNotifications: [] as string[],

    selectedEventId: undefined as string | undefined,

    eventsUpdating: [] as string[],

    showFullCalendar: false,
  },
  reducers: {
    requestAll: (state) => {},
    setAllEvents: (state, action: PayloadAction<ClientPersonalCalendarEvent[]>) => {
      eventsAdapter.setAll(state.events, action.payload);
      state.eventsUpdatedTime = Date.now();
    },
    setAllCalendars: (state, action: PayloadAction<PersonalCalendarPartial[]>) => {
      calendarsAdapter.setAll(state.calendars, action.payload);
    },

    setNextEventNotification: (
      state,
      action: PayloadAction<ClientPersonalCalendarEvent | undefined>
    ) => {
      state.nextEventNotification = action.payload;
    },
    dismissNextEventNotification: (state, action: PayloadAction<string>) => {
      const eventIds = new Set(eventsSelectors.selectIds(state.events));

      state.dismissedNextEventNotifications = [
        // Clean up any stale notifications that are no longer events
        ...state.dismissedNextEventNotifications.filter((e) => eventIds.has(e)),
        action.payload,
      ];
    },

    selectEvent: (state, action: PayloadAction<string>) => {
      state.selectedEventId = action.payload;
    },
    clearSelectedEvent: (state) => {
      state.selectedEventId = undefined;
    },

    reserveEventRoom: (state, action: PayloadAction<PersonalCalendarReserveRoomPayload>) => {},
    createEvent: (state, action: PayloadAction<PersonalCalendarCreateEventPayload>) => {},

    eventUpdateStarted: (state, action: PayloadAction<string>) => {
      // Allow duplicates.
      state.eventsUpdating.push(action.payload);
    },
    eventUpdateDone: (state, action: PayloadAction<string>) => {
      // Clear only one.
      const idx = state.eventsUpdating.indexOf(action.payload);
      if (idx >= 0) state.eventsUpdating.splice(idx, 1);
    },

    setShowFullCalendar: (state, action: PayloadAction<boolean>) => {
      state.showFullCalendar = action.payload;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.calendar.personalCalendars;
const eventsSelectors = eventsAdapter.getSelectors();
const calendarsSelectors = calendarsAdapter.getSelectors();

export const selectors = {
  selectEvents: (state: RootState) => eventsSelectors.selectAll(selectSlice(state).events),

  selectEventById: (id?: string) => (state: RootState) =>
    id ? eventsSelectors.selectById(selectSlice(state).events, id) : undefined,
  selectEventsUpdatedTime: (state: RootState) => selectSlice(state).eventsUpdatedTime,

  selectCurrentEvents: createDeepEqualSelector(
    (state) => selectSlice(state).events,
    () => Date.now(),
    (events, now) =>
      eventsSelectors.selectAll(events).filter((event) => now >= event.start && now <= event.end)
  ),

  selectNextEvent: createDeepEqualSelector(
    (state) => selectSlice(state).events,
    () => Date.now(),
    (events, now) => {
      const filteredEvents = eventsSelectors
        .selectAll(events)
        .filter((event) => event.start >= now);

      if (filteredEvents.length === 0) {
        return undefined;
      }

      return filteredEvents.reduce((accumulatedEvent, currentEvent) =>
        accumulatedEvent === undefined || currentEvent.start < accumulatedEvent.start
          ? currentEvent
          : accumulatedEvent
      );
    }
  ),

  selectNextEventNotification: (state: RootState) => selectSlice(state).nextEventNotification,
  selectDismissedNextEventNotifications: (state: RootState) =>
    selectSlice(state).dismissedNextEventNotifications,
  selectSelectedEventId: (state: RootState) => selectSlice(state).selectedEventId,

  selectCalendars: (state: RootState) => calendarsSelectors.selectAll(selectSlice(state).calendars),
  selectHasCalendars: (state: RootState) =>
    calendarsSelectors.selectTotal(selectSlice(state).calendars) > 0,

  selectEventIsUpdating: (id?: string) => (state: RootState) =>
    id ? selectSlice(state).eventsUpdating.includes(id) : false,
  selectCanCreateEvents: createSelector(
    (state) => selectSlice(state).calendars,
    (calendars) => calendarsSelectors.selectAll(calendars).some((cal) => cal.canCreateEvents)
  ),

  selectShowFullCalendar: (state: RootState) => selectSlice(state).showFullCalendar,
};

export const PersonalCalendarSelectors = selectors;
export const PersonalCalendarActions = actions;
