import { z } from "zod";

/**
 * LobbyGuest represents a visitor who is actively attempting to enter
 * a roam.  They must be admitted or denied entry
 */

export const LobbyGuest = z.object({
  name: z.string(),
  email: z.string().optional(),
  imageName: z.string().optional(),
});
export type LobbyGuest = z.infer<typeof LobbyGuest>;
