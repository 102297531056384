import { useSelector } from "react-redux";
import {
  ExtractFlagReturnType,
  Flag,
  FlagValue,
} from "../../shared/featureflags/RoamFlagConfigs.js";
import { FeatureFlagSelectors } from "../store/slices/featureFlagSlice.js";
import { SettingsFeatureFlagSelectors } from "../store/slices/settingsFeatureFlagSlice.js";
import { useContainer } from "./useContainer.js";

export const useFeatureFlag = <T extends FlagValue, U extends FlagValue, F extends Flag<T, U>>(
  flag: F
): ExtractFlagReturnType<F> => {
  const container = useContainer();
  const containerType = container.info().containerType;
  const selector =
    containerType === "settings" ? SettingsFeatureFlagSelectors : FeatureFlagSelectors;
  return useSelector(selector.selectFlag(flag));
};
