import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { notUndefined } from "../../../shared/helpers/typing.js";
import { TagView } from "../../../shared/Models/Tag.js";
import { ClientRect } from "../../helpers/dom.js";
import { createDeepEqualSelector } from "../../helpers/redux.js";
import { WindowKey } from "../../injection/windows/WindowKey.js";
import { selectors as MyLocationSelectors } from "../../section/store/slices/myLocationSlice.js";
import { RootState } from "../../store/reducers.js";

export interface AddUpdateTagsPayload {
  sectionId: string;
  tagViews: TagView[];
}

export interface ClearTagsPayload {
  sectionId: string;
  tagIds: string[];
}

export interface OpenTagOverlayPayload {
  windowKey: WindowKey;
  anchorRect: ClientRect;
  email: string;
  clickedTagId?: string;
}

const slice = createSlice({
  name: "tags",
  initialState: {
    // section -> id -> TagView
    tagViews: {} as Record<string, Record<string, TagView>>,
    // section -> subject -> id[]
    subjectToIds: {} as Record<string, Record<string, string[]>>,
  },
  reducers: {
    addUpdateTags: (state, action: PayloadAction<AddUpdateTagsPayload>) => {
      const { sectionId, tagViews } = action.payload;
      let views = state.tagViews[sectionId];
      if (!views) {
        views = {};
        state.tagViews[sectionId] = views;
      }
      let ids = state.subjectToIds[sectionId];
      if (!ids) {
        ids = {};
        state.subjectToIds[sectionId] = ids;
      }
      for (const tagView of tagViews) {
        views[tagView.id] = tagView;
        let subjectIds = ids[tagView.subject];
        if (!subjectIds) {
          subjectIds = [tagView.id];
          ids[tagView.subject] = subjectIds;
        } else {
          if (!subjectIds.includes(tagView.id)) {
            subjectIds.push(tagView.id);
          }
        }
      }
    },
    clearTags: (state, action: PayloadAction<ClearTagsPayload>) => {
      const { sectionId, tagIds } = action.payload;
      const views = state.tagViews[sectionId];
      const ids = state.subjectToIds[sectionId];
      if (!views || !ids) return;

      for (const tagId of tagIds) {
        const tagView = views?.[tagId];
        if (!tagView) continue;
        const subjectIds = ids[tagView.subject];
        if (subjectIds) {
          const i = subjectIds.indexOf(tagId);
          if (i >= 0) {
            subjectIds.splice(i, 1);
          }
        }
        delete views[sectionId];
      }
    },
    clearSection: (state, action: PayloadAction<string>) => {
      const sectionId = action.payload;
      delete state.tagViews[sectionId];
      delete state.subjectToIds[sectionId];
    },

    openTagOverlay: (state, action: PayloadAction<OpenTagOverlayPayload>) => {},
  },
});

export const { actions: TagsActions, reducer: tagsReducer } = slice;

export const TagsSelectors = {
  selectFirstForSubject: defaultMemoize((subject?: string) =>
    createDeepEqualSelector(
      MyLocationSelectors.selectMySectionId,
      (state: RootState) => state.tags,
      (sectionId, slice) => {
        if (!sectionId || !subject) return undefined;
        const id = slice.subjectToIds[sectionId]?.[subject]?.[0];
        if (!id) return undefined;
        return slice.tagViews[sectionId]?.[id];
      }
    )
  ),
  selectIdsForSubject: defaultMemoize((subject?: string) =>
    createDeepEqualSelector(
      MyLocationSelectors.selectMySectionId,
      (state: RootState) => state.tags,
      (sectionId, slice) => {
        if (!sectionId || !subject) return undefined;
        const ids = slice.subjectToIds[sectionId]?.[subject];
        if (!ids) return undefined;
        return ids.filter(notUndefined);
      }
    )
  ),
  selectForSubject: defaultMemoize((subject?: string) =>
    createDeepEqualSelector(
      MyLocationSelectors.selectMySectionId,
      (state: RootState) => state.tags,
      (sectionId, slice) => {
        if (!sectionId || !subject) return undefined;
        const ids = slice.subjectToIds[sectionId]?.[subject];
        if (!ids) return undefined;
        return ids
          .filter(notUndefined)
          .map((tagId) => slice.tagViews[sectionId]?.[tagId])
          .filter(notUndefined);
      }
    )
  ),
  selectById: defaultMemoize((tagId: string) =>
    createDeepEqualSelector(
      MyLocationSelectors.selectMySectionId,
      (state: RootState) => state.tags,
      (sectionId, slice) => {
        if (!sectionId) return undefined;
        return slice.tagViews[sectionId]?.[tagId];
      }
    )
  ),
};
