type FontStyle =
  | "title1"
  | "title2"
  | "title3"
  | "title4"
  | "headline"
  | "body"
  | "bodyEmphasis"
  | "subheadline"
  | "subheadlineEmphasis"
  | "caption"
  | "captionEmphasis"
  | "caption2"
  | "caption2Emphasis"
  | "caption3Emphasis"
  | "possibilityLargeTitle"
  | "possibilityTitle"
  | "possibilityBody"
  | "possibilityCaption"
  | "possibilityCaption2";

type FontFamily = "poppins" | "possibility";
type FontWeight = "regular" | "medium" | "heavy";

type FontMetrics = {
  family?: FontFamily;
  weight: FontWeight;
  size: number;
  lineHeight: number;
};

const fontFamilies: { [fontFamily in FontFamily]: string } = {
  poppins: "Poppins",
  possibility: "Possibility",
};

const baseFonts = [
  `system-ui`,
  `-apple-system`,
  // cspell:disable-next-line
  `"Segoe UI"`,
  // cspell:disable-next-line
  `Roboto`,
  // cspell:disable-next-line
  `"Helvetica Neue"`,
  // cspell:disable-next-line
  `"Noto Sans"`,
  `"Liberation Sans"`,
  `Arial`,
  `sans-serif`,
  `"Apple Color Emoji"`,
  // cspell:disable-next-line
  `"Segoe UI Emoji"`,
  // cspell:disable-next-line
  `"Segoe UI Symbol"`,
  // cspell:disable-next-line
  `"Noto Color Emoji"`,
].join(", ");

const fontFamily = (fontFamily: FontFamily | undefined): string => {
  if (fontFamily === undefined) {
    return baseFonts;
  }

  return `"${fontFamilies[fontFamily]}", ${baseFonts}`;
};

const fontWeights: { [fontWeight in FontWeight]: number } = {
  regular: 400,
  medium: 500,
  heavy: 700,
};

const fonts: { [fontStyle in FontStyle]: FontMetrics } = {
  title1: { family: "poppins", weight: "medium", size: 34, lineHeight: 40 },
  title2: { family: "poppins", weight: "medium", size: 28, lineHeight: 32 },
  title3: { family: "poppins", weight: "medium", size: 22, lineHeight: 28 },
  title4: { family: "poppins", weight: "medium", size: 20, lineHeight: 24 },
  headline: { family: "poppins", weight: "medium", size: 18, lineHeight: 24 },
  body: { weight: "regular", size: 16, lineHeight: 24 },
  bodyEmphasis: { weight: "medium", size: 16, lineHeight: 24 },
  subheadline: { weight: "regular", size: 14, lineHeight: 20 },
  subheadlineEmphasis: { weight: "medium", size: 14, lineHeight: 20 },
  caption: { weight: "regular", size: 12, lineHeight: 16 },
  captionEmphasis: { weight: "medium", size: 12, lineHeight: 16 },
  caption2: { weight: "regular", size: 10, lineHeight: 12 },
  caption2Emphasis: { weight: "medium", size: 10, lineHeight: 12 },
  caption3Emphasis: { weight: "medium", size: 8, lineHeight: 10 },
  possibilityLargeTitle: { family: "possibility", weight: "heavy", size: 32, lineHeight: 38 },
  possibilityTitle: { family: "possibility", weight: "heavy", size: 20, lineHeight: 24 },
  possibilityBody: { family: "possibility", weight: "heavy", size: 16, lineHeight: 20 },
  possibilityCaption: { family: "possibility", weight: "heavy", size: 12, lineHeight: 14 },
  possibilityCaption2: { family: "possibility", weight: "heavy", size: 10, lineHeight: 12 },
};

const allFontStyles = Object.keys(fonts) as FontStyle[];

const fontStyles = allFontStyles.reduce<Partial<Record<FontStyle, string>>>((result, fontStyle) => {
  const metrics = fonts[fontStyle];
  const pxToRem = (px: number) => `${px / 16}rem`;
  result[fontStyle] = `
    font-family: ${fontFamily(metrics.family)};
    font-size: ${pxToRem(metrics.size)};
    font-weight: ${fontWeights[metrics.weight]};
    line-height: ${pxToRem(metrics.lineHeight)};
    letter-spacing: 0rem;
  `;
  return result;
}, {});

export default fontStyles;
