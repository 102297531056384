import equal from "fast-deep-equal/es6/index.js";
import { logger } from "../../../shared/infra/logger.js";
import { ClientContainerInfo } from "../../injection/IClientContainer.js";
import { IClientService } from "../../injection/IClientService.js";
import { IRedux } from "../../injection/redux/IRedux.js";
import { RawStreamManager } from "../RawStreamManager.js";
import { IAvDevices } from "../interfaces/IAvDevices.js";
import { IFakeStreams } from "../interfaces/IFakeStreams.js";
import { GlobalAudioSettings, IRawAudioStreamManager } from "../interfaces/IRawStreamManager.js";

interface Injected {
  avDevices(): IAvDevices;
  fakeStreams(): IFakeStreams;
  info(): ClientContainerInfo;
  redux(): IRedux;
}

export class RawAudioStreamManager
  extends RawStreamManager
  implements IRawAudioStreamManager, IClientService
{
  protected globalAudioSettings: GlobalAudioSettings = {};
  constructor(container: Injected) {
    super(container, "audio");
  }

  public name() {
    return "RawAudioStreamManager";
  }

  public setGlobalAudioSettings(settings: GlobalAudioSettings): void {
    // NOTE: Once a call to getUserMedia is made with certain echoCancellation and noiseSuppression
    // settings, it is impossible to get a new stream (at least, for the same device) with different
    // settings.
    // As a result, to properly update these settings, we need to 1) block any new getUserMedia calls
    // from being made, 2) close all currently open streams, and 3) start using the new settings for
    // all future getUserMedia calls. There is no way around this.

    // Acquire a write-lock (which will halt any calls to getStream in the meantime)
    this.lock
      .writeGuard(async () => {
        if (!equal(this.globalAudioSettings, settings)) {
          // Close every stream
          const promises = [...this.streamPromises.values()];
          this.streamPromises.clear();
          await Promise.race([
            // Close all the streams
            Promise.all(
              promises.map(async (streamPromise) => {
                try {
                  const stream = await streamPromise;
                  // This will also close all clones
                  stream.close();
                } catch {
                  // Getting the stream failed
                  // Shouldn't happen (should be caught elsewhere), but means we have nothing to do
                }
              })
            ),
            // Time out after 5 seconds (sanity check)
            new Promise((resolve) => setTimeout(resolve, 5_000)),
          ]);
          // Start using the new settings for future calls to getStream
          this.globalAudioSettings = settings;
        }
      })
      .catch((err) => logger.warn({ err }, `Error setting global audio settings`));
  }

  protected async getStreamInner(deviceId: string | undefined): Promise<MediaStream> {
    // If an override stream is configured (for testing/robots - this is uncommon), just use it
    const overrideStream = await this.container.fakeStreams().getOverrideStream("audio");
    if (overrideStream) {
      return overrideStream.clone();
    }

    const device = deviceId ? { deviceId: { exact: deviceId } } : {};
    let stream: MediaStream;
    try {
      // eslint-disable-next-line no-restricted-globals
      stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          ...(this.globalAudioSettings.echoCancellation !== undefined
            ? { echoCancellation: { exact: this.globalAudioSettings.echoCancellation } }
            : {}),
          ...(this.globalAudioSettings.noiseSuppression !== undefined
            ? { noiseSuppression: { exact: this.globalAudioSettings.noiseSuppression } }
            : {}),
          ...(this.globalAudioSettings.autoGainControl !== undefined
            ? { autoGainControl: { exact: this.globalAudioSettings.autoGainControl } }
            : {}),
          sampleRate: { exact: 48000 },
          ...device,
        },
      });
    } catch (err) {
      logger.info({ err }, `Error thrown from audio getUserMedia, falling back to ideal`);
      try {
        // eslint-disable-next-line no-restricted-globals
        stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            ...(this.globalAudioSettings.echoCancellation !== undefined
              ? { echoCancellation: { ideal: this.globalAudioSettings.echoCancellation } }
              : {}),
            ...(this.globalAudioSettings.noiseSuppression !== undefined
              ? { noiseSuppression: { ideal: this.globalAudioSettings.noiseSuppression } }
              : {}),
            ...(this.globalAudioSettings.autoGainControl !== undefined
              ? { autoGainControl: { ideal: this.globalAudioSettings.autoGainControl } }
              : {}),
            sampleRate: { ideal: 48000 },
            ...device,
          },
        });
      } catch (err) {
        logger.info({ err }, `Error thrown from audio getUserMedia`);
        throw err;
      }
    }
    return stream;
  }

  public snapshot() {
    return {
      ...super.snapshot(),
      globalAudioSettings: this.globalAudioSettings,
    };
  }
}
