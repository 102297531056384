import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

export type ZoomLevel = "fit" | "50" | "100" | "150" | "200" | "300";

export interface ZoomTransform {
  scale: number;
  x: number;
  y: number;
}

export interface ScreenShareZoom {
  level?: ZoomLevel;
  transform: ZoomTransform;
}

export interface SetZoomPayload {
  cardId: string;
  level?: ZoomLevel;
  transform: ZoomTransform;
}

export interface SetZoomLevelPayload {
  cardId: string;
  level?: ZoomLevel;
}

export interface SetZoomTransformPayload {
  cardId: string;
  transform: ZoomTransform;
}

const slice = createSlice({
  name: "screenShare",
  initialState: {
    zooms: {} as Record<string, ScreenShareZoom>,
  },
  reducers: {
    setZoom: (slice, action: PayloadAction<SetZoomPayload>) => {
      const { cardId, level, transform } = action.payload;
      slice.zooms[cardId] = { level, transform };
    },
    setZoomLevel: (slice, action: PayloadAction<SetZoomLevelPayload>) => {
      const { cardId, level } = action.payload;
      const zoom = slice.zooms[cardId];
      if (zoom) {
        zoom.level = level;
      }
    },
    setZoomTransform: (slice, action: PayloadAction<SetZoomTransformPayload>) => {
      const { cardId, transform } = action.payload;
      const zoom = slice.zooms[cardId];
      if (zoom) {
        zoom.transform = transform;
      }
    },
    toggleFullScreen: (state, action: PayloadAction<WindowKey>) => {},
  },
});
export const { actions: ScreenShareActions, reducer: screenShareReducer } = slice;

export const ScreenShareSelectors = {
  selectZoomLevel: (cardId: string) => (state: RootState) =>
    state.holo.screenShare.zooms[cardId]?.level,
  selectZoomTransform: (cardId: string) => (state: RootState) =>
    state.holo.screenShare.zooms[cardId]?.transform,
};
