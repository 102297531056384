// From https://stackoverflow.com/a/3143231
const dateFormat =
  /^(\d{4}-[01]\d-[0-3]\dT[0-2](?:\d:[0-5]){2}\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2](?:\d:[0-5]){2}\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$/;

export const dateReviver = (value: Date | string | undefined): Date | undefined => {
  if (value instanceof Date) {
    return value;
  } else if (typeof value === "string" && dateFormat.test(value)) {
    return new Date(value);
  } else return undefined;
};

export const daysAgo = (daysAgo: number): Date => {
  const d = new Date();
  d.setDate(d.getDate() - daysAgo);
  return d;
};
