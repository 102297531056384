import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { ChatKeyString, getChatKey } from "../../../../shared/helpers/chat.js";
import { FeatureControlBitFlags } from "../../../../shared/helpers/featureControlBits.js";
import { logger } from "../../../../shared/infra/logger.js";
import { Chat } from "../../../../shared/Models/Chat.js";
import { ChatStatus } from "../../../../shared/Models/ChatStatus.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";
import { ChatDraftSelectors } from "./chatDraftSlice.js";

export interface ItemId {
  chatId: string;
  threadTimestamp?: number;
}

export interface ReadThrough {
  chatId: string;
  threadTimestamp?: number;
  clientReadThrough: number;
}

export interface MarkAllReadPayload {
  windowKey?: WindowKey;
}

export interface LoadActionPayload {
  listMode: ListMode;

  // Use the same action / saga for all three of these actions so a subsequent request for a list
  // mode will cancel the previous one. For example, a "reset" saga will cancel the running
  // "requestMore" dispatch for the same listMode
  loadAction: "requestMore" | "resetAndRequestMore" | "reset";
}

export interface ReadThroughsUpdatedPayload {
  allowEarlierTime?: boolean;
  readThroughs: ReadThrough[];
}

export interface RecentChatsLoadedPayload {
  listMode: ListMode;
  statuses: ChatStatus[];
  allLoaded: boolean;
  nextBeforeTimestamp?: number;
}

export interface UpdateLastMessageTimePayload {
  chatId: string;
  threadTimestamp: number | undefined;
  lastMessageTime: number;
  alsoUpdateLastRead?: boolean;
  overwriteNewerTimestamp?: boolean;
  fromRealTimeMessage?: boolean;
  chatMutedByRecipient?: boolean;
}

export interface UpdateReadThroughPayload {
  chatId: string;
  threadTimestamp: number | undefined;
  clientReadThrough: number;
}

export interface UnreadSubscribedPayload {
  unreadThreadsByChat: { [chatId: string]: number[] };
}

export type ListMode = "all" | "chats" | "groups" | "meetings";
export const listModes: ListMode[] = ["all", "chats", "groups", "meetings"];

export interface SetGroupListEntriesPayload {
  entries: GroupListEntry[];
  comprehensive: boolean;
}

const chatStatusAdapter = createEntityAdapter<ChatStatus>({
  selectId: (i) => getChatKey(i.chatId, i.threadTimestamp),
  sortComparer: (a, b) => b.lastMessageTime - a.lastMessageTime,
});

export interface GroupListEntry {
  chatId: string;
  threadTimestamp: undefined;
  name: string;
}

const groupListEntryAdapter = createEntityAdapter<GroupListEntry>({
  selectId: (e) => e.chatId,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export interface MeetingListEntry {
  chatId: string;
  threadTimestamp: undefined;
  startedTimestamp: number;
}

const meetingListEntryAdapter = createEntityAdapter<MeetingListEntry>({
  selectId: (e) => e.chatId,
  sortComparer: (a, b) => b.startedTimestamp - a.startedTimestamp,
});

const setUnread = (
  state: ChatStatusState,
  chatId: string,
  threadTimestamp: number | undefined,
  unread: boolean
) => {
  if (threadTimestamp) {
    const current = state.unreadSubscribedThreadsByChat[chatId];
    if (current) {
      current[threadTimestamp] = unread;
    } else if (unread) {
      state.unreadSubscribedThreadsByChat[chatId] = { [threadTimestamp]: true };
    }
  } else {
    state.unreadChatIds[chatId] = unread;
  }
};

/*
 * Update the `unreadChatIds` and `unreadSubscribedThreadsByChat` state members based on the data
 * in the `statusList` state member for the item IDs listed. We know about unread chats and threads
 * that we don't have summaries for, so this information is not completely derived from the
 * summaries, but once we know the summaries for specific items have changed this method will
 * update the unreads based on those summaries for those items only.
 */
const updateUnreadsFromStatus = (state: ChatStatusState, itemsToCheck: ItemId[]) => {
  for (const { chatId, threadTimestamp } of itemsToCheck) {
    const status = state.statusList.entities[getChatKey(chatId, threadTimestamp)];
    const unread =
      (status?.lastMessageTime !== undefined &&
        (!status.clientReadThrough || status.clientReadThrough < status.lastMessageTime)) ??
      false;
    setUnread(state, chatId, threadTimestamp, unread);
  }
};

export interface ProgressiveLoadState {
  allLoaded: boolean;
  nextBeforeTimestamp: number | undefined;
  loading: boolean;
}

const initialState = {
  statusList: chatStatusAdapter.getInitialState(),
  groupList: groupListEntryAdapter.getInitialState(),
  meetingList: meetingListEntryAdapter.getInitialState(),
  directChats: {} as { [chatId: string]: boolean },

  // Progressive loading
  loadState: {} as { [mode: string /* ListMode */]: ProgressiveLoadState },

  // All thread timestamps for a chat that we're subscribed to and are unread. These are loaded on
  // startup and the client is expected to maintain them as it receives real-time messages.
  // chatId -> threadTimestamp -> boolean
  unreadSubscribedThreadsByChat: {} as Record<string, Record<number, boolean>>,
  unreadChatIds: {} as Record<string, boolean>,

  focusedEditorChatId: undefined as string | undefined,
  clientRestrictionFlags: 0 as number,
};

const slice = createSlice({
  name: "chatStatus",
  initialState,
  reducers: {
    resetState: () => initialState,
    loadAction: (state, action: PayloadAction<LoadActionPayload>) => {
      const { listMode, loadAction } = action.payload;
      let loadState = state.loadState[listMode];
      if (!loadState) {
        loadState = {
          allLoaded: false,
          nextBeforeTimestamp: undefined,
          loading: false,
        };
        state.loadState[listMode] = loadState;
      }

      switch (loadAction) {
        case "reset":
          loadState.allLoaded = false;
          loadState.nextBeforeTimestamp = undefined;
          loadState.loading = false;
          break;
        case "resetAndRequestMore":
          loadState.allLoaded = false;
          loadState.nextBeforeTimestamp = undefined;
          loadState.loading = true;
          break;
        case "requestMore":
          if (!loadState.allLoaded) {
            loadState.loading = true;
          }
          break;
      }
    },
    recentChatStatusesLoaded: (state, action: PayloadAction<RecentChatsLoadedPayload>) => {
      const { listMode, statuses, allLoaded, nextBeforeTimestamp } = action.payload;
      chatStatusAdapter.setMany(state.statusList, statuses);
      updateUnreadsFromStatus(state, statuses);

      const loadState = state.loadState[listMode];
      if (!loadState) {
        logger.error(`recentChatStatusesLoaded: no load state for list mode ${listMode}`);
        return;
      }
      loadState.loading = false;
      loadState.allLoaded = allLoaded;
      loadState.nextBeforeTimestamp = nextBeforeTimestamp;
    },
    recentChatStatusesLoadFailed: (state, action: PayloadAction<ListMode>) => {
      const listMode = action.payload;
      const loadState = state.loadState[listMode];
      if (loadState) {
        loadState.loading = false;
      }
    },
    updateLastMessageTime: (state, action: PayloadAction<UpdateLastMessageTimePayload>) => {
      const {
        chatId,
        threadTimestamp,
        lastMessageTime,
        alsoUpdateLastRead,
        overwriteNewerTimestamp,
      } = action.payload;
      const chatKey = getChatKey(chatId, threadTimestamp);

      const status = state.statusList.entities[chatKey];
      if (
        status &&
        (!status.lastMessageTime ||
          status.lastMessageTime < lastMessageTime ||
          overwriteNewerTimestamp)
      ) {
        const changes: { lastMessageTime: number; clientReadThrough?: number } = {
          lastMessageTime,
        };
        if (alsoUpdateLastRead) {
          changes.clientReadThrough = lastMessageTime;
        }
        chatStatusAdapter.updateOne(state.statusList, {
          id: chatKey,
          changes,
        });
      }
      updateUnreadsFromStatus(state, [{ chatId, threadTimestamp }]);
    },
    readThroughsUpdated: (state, action: PayloadAction<ReadThroughsUpdatedPayload>) => {
      const { readThroughs, allowEarlierTime } = action.payload;
      for (const readThrough of readThroughs) {
        const { chatId, threadTimestamp, clientReadThrough } = readThrough;
        const status = state.statusList.entities[getChatKey(chatId, threadTimestamp)];
        if (!status) continue;

        if (allowEarlierTime || (status.clientReadThrough ?? 0) < clientReadThrough) {
          status.clientReadThrough = clientReadThrough;
        }
      }
      updateUnreadsFromStatus(state, readThroughs);
    },
    markRead: (state, action: PayloadAction<ItemId>) => {
      const { chatId, threadTimestamp } = action.payload;
      const status = state.statusList.entities[getChatKey(chatId, threadTimestamp)];
      if (status?.lastMessageTime) {
        status.clientReadThrough = status.lastMessageTime;
      }
      updateUnreadsFromStatus(state, [{ chatId, threadTimestamp }]);
    },
    markUnread: (state, action: PayloadAction<ItemId>) => {
      const { chatId, threadTimestamp } = action.payload;
      const status = state.statusList.entities[getChatKey(chatId, threadTimestamp)];
      if (status?.lastMessageTime) {
        status.clientReadThrough = status.lastMessageTime - 1;
      }
      updateUnreadsFromStatus(state, [{ chatId, threadTimestamp }]);
    },
    markAllRead: (state, action: PayloadAction<MarkAllReadPayload>) => {
      state.unreadChatIds = {};
      state.unreadSubscribedThreadsByChat = {};
    },
    updateReadThrough: (state, action: PayloadAction<UpdateReadThroughPayload>) => {
      const { chatId, threadTimestamp, clientReadThrough } = action.payload;
      const status = state.statusList.entities[getChatKey(chatId, threadTimestamp)];
      if (status && (status.clientReadThrough ?? 0) < clientReadThrough) {
        status.clientReadThrough = clientReadThrough;
      }
      updateUnreadsFromStatus(state, [{ chatId, threadTimestamp }]);
    },
    setStatuses: (state, action: PayloadAction<ChatStatus[]>) => {
      const statuses = action.payload;
      chatStatusAdapter.setMany(state.statusList, statuses);
      updateUnreadsFromStatus(state, statuses);
    },
    removeStatuses: (state, action: PayloadAction<ItemId[]>) => {
      chatStatusAdapter.removeMany(
        state.statusList,
        action.payload.map((i) => getChatKey(i.chatId, i.threadTimestamp))
      );
      for (const { chatId, threadTimestamp } of action.payload) {
        if (threadTimestamp) {
          const unreadThreads = state.unreadSubscribedThreadsByChat[chatId];
          if (unreadThreads) {
            unreadThreads[threadTimestamp] = false;
          }
        } else {
          state.unreadChatIds[chatId] = false;
        }
      }
    },
    removeAllStatusesForChats: (state, action: PayloadAction<string[]>) => {
      const chatIds = action.payload;
      const toRemove = chatStatusSelectors
        .selectAll(state.statusList)
        .filter((status) => chatIds.includes(status.chatId))
        .map((status) => getChatKey(status.chatId, status.threadTimestamp));

      chatStatusAdapter.removeMany(state.statusList, toRemove);
      for (const chatId of chatIds) {
        state.unreadChatIds[chatId] = false;
        state.unreadSubscribedThreadsByChat[chatId] = {};
      }

      groupListEntryAdapter.removeMany(state.groupList, toRemove);
      meetingListEntryAdapter.removeMany(state.meetingList, toRemove);
    },
    setUnreadChatIds: (state, action: PayloadAction<string[]>) => {
      const unreadChatIds = Object.fromEntries(action.payload.map((chatId) => [chatId, true]));
      state.unreadChatIds = unreadChatIds;
    },
    setUnreadSubscribedThreadsByChat: (state, action: PayloadAction<UnreadSubscribedPayload>) => {
      const { unreadThreadsByChat } = action.payload;
      for (const [chatId, threadTimestamps] of Object.entries(unreadThreadsByChat)) {
        state.unreadSubscribedThreadsByChat[chatId] = Object.fromEntries(
          threadTimestamps.map((threadTimestamp) => [threadTimestamp, true])
        );
      }
    },
    setFocusedEditorChatId: (state, action: PayloadAction<string | undefined>) => {
      state.focusedEditorChatId = action.payload;
    },
    setGroupListEntries: (state, action: PayloadAction<SetGroupListEntriesPayload>) => {
      const { entries, comprehensive } = action.payload;
      if (comprehensive) {
        groupListEntryAdapter.setAll(state.groupList, entries);
      } else {
        const filtered = entries.filter(
          (e) => e.name !== groupListEntrySelectors.selectById(state.groupList, e.chatId)?.name
        );
        if (filtered.length > 0) {
          groupListEntryAdapter.setMany(state.groupList, filtered);
        }
      }
    },
    setMeetingListEntries: (state, action: PayloadAction<MeetingListEntry[]>) => {
      const entries = action.payload;
      const filtered = entries.filter((e) => state.meetingList.entities[e.chatId] === undefined);
      if (filtered.length === 0) {
        return;
      }
      meetingListEntryAdapter.setMany(state.meetingList, filtered);
    },
    setDirectChatIds: (state, action: PayloadAction<string[]>) => {
      const chatIds = action.payload;
      const filtered = chatIds.filter((chatId) => !state.directChats[chatId]);
      if (filtered.length === 0) {
        return;
      }
      filtered.forEach((chatId) => {
        state.directChats[chatId] = true;
      });
    },
    setClientRestrictionFlags: (state, action: PayloadAction<number>) => {
      state.clientRestrictionFlags = action.payload;
    },
  },
});

export const { actions, reducer } = slice;
export type ChatStatusState = ReturnType<typeof slice.getInitialState>;

export const selectSlice = (state: RootState) => state.chat.chatStatus;
const chatStatusSelectors = chatStatusAdapter.getSelectors();
const groupListEntrySelectors = groupListEntryAdapter.getSelectors();
const meetingListEntrySelectors = meetingListEntryAdapter.getSelectors();

export const isUnread = (
  slice: ChatStatusState,
  chatId: string | undefined,
  threadTimestamp: number | undefined
): boolean => {
  if (!chatId) return false;
  if (threadTimestamp) {
    return slice.unreadSubscribedThreadsByChat[chatId]?.[threadTimestamp] === true;
  } else {
    return slice.unreadChatIds[chatId] ?? false;
  }
};
const selectIsUnread = defaultMemoize(
  (chatId: string | undefined, threadTimestamp: number | undefined) =>
    createSelector(selectSlice, (slice) => isUnread(slice, chatId, threadTimestamp))
);

const messageIsUnread = (
  slice: ChatStatusState,
  chatId: string | undefined,
  threadTimestamp: number | undefined,
  timestamp: number | undefined
): boolean => {
  if (!chatId) return false;
  const status = slice.statusList.entities[getChatKey(chatId, threadTimestamp)];
  if (status && timestamp && status.clientReadThrough) {
    return status.clientReadThrough < timestamp;
  }
  return true; // if there's no status or readThrough, assume it's unread.
};
const selectMessageIsUnread = defaultMemoize(
  (chatId: string, threadTimestamp: number | undefined, timestamp: number | undefined) =>
    createSelector(selectSlice, (slice) =>
      messageIsUnread(slice, chatId, threadTimestamp, timestamp)
    )
);

const selectChatsWithSingleGroups = createDeepEqualSelector(
  (state: RootState) => state.chat.chat.chats,
  (state: RootState) => state.chat.chatStatus.statusList,
  (chats, statusList): Chat[] => {
    const allStatuses = chatStatusSelectors.selectAll(statusList);
    const groupChats = new Array<Chat>();
    for (const s of allStatuses) {
      const { chatId, threadTimestamp } = s;
      if (threadTimestamp) continue;
      const chat = chats.entities[chatId];
      if (!chat) continue;
      if (chat.chatType === "channel" && chat.sortedAddressIds.length === 1) {
        groupChats.push(chat);
      }
    }
    return groupChats;
  }
);

export interface RecentChatItem {
  chatId: string;
  threadTimestamp: number | undefined;
  sortTimestamp: number;
}

export interface ChatStatusSelectorResult {
  chatId: string;
  threadTimestamp?: number;
  sortTimestamp?: number;
}

export const selectAll = defaultMemoize((listMode: ListMode, unreadFilter: boolean) =>
  createDeepEqualSelector(
    selectSlice,
    ChatDraftSelectors.selectAllDrafts,
    (slice, drafts): ChatStatusSelectorResult[] => {
      const loadState = slice.loadState[listMode];
      if (!loadState || (!loadState.allLoaded && !loadState.nextBeforeTimestamp)) {
        return [];
      }

      let result: ChatStatusSelectorResult[];
      if (listMode === "all" || listMode === "chats") {
        const chatKeyToItem = new Map<string, RecentChatItem>();
        for (const { chatId, threadTimestamp, lastMessageTime } of chatStatusSelectors.selectAll(
          slice.statusList
        )) {
          chatKeyToItem.set(getChatKey(chatId, threadTimestamp), {
            chatId,
            threadTimestamp,
            sortTimestamp: lastMessageTime,
          });
        }

        if (listMode === "all" && !unreadFilter) {
          for (const { chatId, threadTimestamp, sortLastEditedAt } of drafts) {
            if (!sortLastEditedAt || chatId.startsWith("MEETING-")) continue;

            const chatKey = getChatKey(chatId, threadTimestamp);
            const currentItem = chatKeyToItem.get(chatKey);
            chatKeyToItem.set(chatKey, {
              chatId,
              threadTimestamp,
              sortTimestamp: Math.max(currentItem?.sortTimestamp ?? 0, sortLastEditedAt * 1000),
            });
          }
        }

        const cutoffTimestamp = loadState.nextBeforeTimestamp ?? 0;
        result = Array.from(chatKeyToItem.values())
          .filter(
            (i) =>
              (listMode !== "chats" || (!i.threadTimestamp && slice.directChats[i.chatId])) &&
              (loadState.allLoaded || i.sortTimestamp >= cutoffTimestamp)
          )
          .sort(
            (l, r) =>
              r.sortTimestamp - l.sortTimestamp ||
              (r.threadTimestamp ?? 0) - (l.threadTimestamp ?? 0)
          );
      } else if (listMode === "groups") {
        result = groupListEntrySelectors.selectAll(slice.groupList);
      } else if (listMode === "meetings") {
        result = meetingListEntrySelectors
          .selectAll(slice.meetingList)
          .filter(
            (e) =>
              loadState.allLoaded ||
              (loadState.nextBeforeTimestamp && loadState.nextBeforeTimestamp <= e.startedTimestamp)
          )
          .map((e) => ({ ...e, sortTimestamp: e.startedTimestamp }));
      } else {
        result = [];
      }

      if (unreadFilter) {
        result = result.filter(({ chatId, threadTimestamp }) =>
          isUnread(slice, chatId, threadTimestamp)
        );
      }

      // If both a top level chat if
      // - There is a thread status for the most recent message in that chat
      // - The thread has no replies
      result = result.filter(
        (status, i, arr) =>
          i === 0 ||
          status.threadTimestamp ||
          arr[i - 1]?.chatId !== status.chatId ||
          arr[i - 1]?.sortTimestamp !== status.sortTimestamp
      );

      return result;
    }
  )
);

export const selectTotalUnreadCount = createSelector(
  (state: RootState) => state.chat.chatStatus.unreadChatIds,
  (state: RootState) => state.chat.chatStatus.unreadSubscribedThreadsByChat,
  (state: RootState) => state.chat.chatStatus.statusList,
  (unreadChatIds, unreadSubscribedThreadsByChat, statusList) => {
    const chatCount = Object.entries(unreadChatIds).filter(([chatId, unread]) => {
      if (!unread) {
        return false;
      }

      // If both a chat and the latest thread in that chat are unread, and the thread has no
      // replies, only count as a single unread.
      //
      // TODO: This would be easier and cleaner if the server returned the lastMessageTime for
      // each unread.
      const chatStatus = chatStatusSelectors.selectById(statusList, chatId);
      if (!chatStatus) {
        return true;
      }
      const maybeThreadTimestamp = chatStatus.lastMessageTime;

      const threadUnread = unreadSubscribedThreadsByChat[chatId]?.[maybeThreadTimestamp];
      if (!threadUnread) {
        return true;
      }

      const threadStatus = chatStatusSelectors.selectById(
        statusList,
        getChatKey(chatId, maybeThreadTimestamp)
      );

      return !threadStatus || threadStatus.lastMessageTime !== chatStatus.lastMessageTime;
    }).length;
    const threadCount = Object.values(unreadSubscribedThreadsByChat)
      .map((byTimestamp) =>
        Object.values(byTimestamp).reduce((total, unread) => total + (unread ? 1 : 0), 0)
      )
      .reduce((total, curr) => total + curr, 0);
    return chatCount + threadCount;
  }
);
export const selectors = {
  selectAll,
  selectIsUnread,
  selectMessageIsUnread,
  selectChatsWithSingleGroups,
  selectTotalUnreadCount,
  selectAllLoaded: defaultMemoize((listMode: ListMode, unreadFilter: boolean) =>
    createSelector(
      (state: RootState) => state.chat.chatStatus.loadState[listMode]?.allLoaded ?? false,
      selectAll(listMode, unreadFilter),
      selectTotalUnreadCount,
      (allLoaded, unread, totalUnreadCount) =>
        allLoaded || (unreadFilter && unread.length === totalUnreadCount)
    )
  ),
  selectListLength: defaultMemoize((listMode: ListMode, unreadFilter: boolean) =>
    createSelector(selectAll(listMode, unreadFilter), (all) => all.length)
  ),
  selectNextBeforeTimestamp: (listMode: ListMode) => (state: RootState) =>
    selectSlice(state).loadState[listMode]?.nextBeforeTimestamp,
  selectLoading: (listMode: ListMode) => (state: RootState) =>
    selectSlice(state).loadState[listMode]?.loading ?? false,
  selectStatusByIndex: defaultMemoize((listMode: ListMode, unreadFilter: boolean, index: number) =>
    createDeepEqualSelector(selectAll(listMode, unreadFilter), (all) => all[index])
  ),
  selectStatus: (chatKey: ChatKeyString | undefined) => (state: RootState) => {
    if (!chatKey) return undefined;
    return selectSlice(state).statusList.entities[chatKey];
  },
  selectUnreadCountByChat: defaultMemoize(
    (chatId?: string) =>
      createSelector(selectSlice, (slice) => {
        if (!chatId) return 0;
        const threads = slice.unreadSubscribedThreadsByChat[chatId];

        let count = threads ? Object.values(threads).filter(Boolean).length : 0;
        if (slice.unreadChatIds[chatId]) {
          // Don't double count an unread thread and unread chat, if the unread thread's parent
          // is the newest message in the chat
          const chatStatus = chatStatusSelectors.selectById(slice.statusList, chatId);
          if (!chatStatus || !threads?.[chatStatus.lastMessageTime]) {
            count++;
          }
        }

        return count;
      }),
    { maxSize: 15 }
  ),
  selectUnreadThreadTimestampsByChat: defaultMemoize((chatId?: string) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      if (!chatId) return undefined;
      const byTimestamp = slice.unreadSubscribedThreadsByChat[chatId];
      if (!byTimestamp) return undefined;
      const timestamps = Object.entries(byTimestamp)
        .filter(([_, subscribed]) => subscribed)
        .map(([threadTimestamp, _]) => Number(threadTimestamp));
      return timestamps;
    })
  ),
  selectIndexByChat: defaultMemoize(
    (
      listMode: ListMode,
      unreadFilter: boolean,
      chatId: string,
      threadTimestamp: number | undefined
    ) =>
      createSelector(selectAll(listMode, unreadFilter), (all) => {
        const i = all.findIndex(
          (r) => r.chatId === chatId && r.threadTimestamp === threadTimestamp
        );
        return i >= 0 ? i : undefined;
      })
  ),
  selectFocusedEditorChatId: (state: RootState) => state.chat.chatStatus.focusedEditorChatId,
  selectLoadState: (listMode: ListMode) => (state: RootState) =>
    selectSlice(state).loadState[listMode],
  selectConfidentialMessagingEnabled: (state: RootState) =>
    // eslint-disable-next-line no-bitwise
    (state.chat.chatStatus.clientRestrictionFlags & FeatureControlBitFlags.disableConfidential) ===
    0,
  selectGroupChatDisabled: (state: RootState) =>
    // eslint-disable-next-line no-bitwise
    (state.chat.chatStatus.clientRestrictionFlags & FeatureControlBitFlags.disableGroupChat) !== 0,
};

export const ChatStatusSelectors = selectors;
export const ChatStatusActions = actions;
