import { combineReducers } from "redux";
import { avPermissionReducer } from "./slices/avPermissionSlice.js";
import { boardReducer } from "./slices/boardSlice.js";
import { cardReducer } from "./slices/cardSlice.js";
import { mediaReplayReducer } from "./slices/mediaReplaySlice.js";
import { playlistReducer } from "./slices/playlistSlice.js";
import { screenShareReducer } from "./slices/screenShareSlice.js";
import { screenReducer } from "./slices/screenSlice.js";
import { streamReducer } from "./slices/streamSlice.js";
import { whiteboardReducer } from "./slices/whiteboardSlice.js";

export const reducer = combineReducers({
  avPermission: avPermissionReducer,
  board: boardReducer,
  card: cardReducer,
  mediaReplay: mediaReplayReducer,
  playlist: playlistReducer,
  screen: screenReducer,
  screenShare: screenShareReducer,
  stream: streamReducer,
  whiteboard: whiteboardReducer,
});
