import { z } from "zod";
import { AccessLinkFloorLocation } from "./types/AccessLinkFloorLocation.js";
import { AccessLinkRoomLocation } from "./types/AccessLinkRoomLocation.js";

/**
 * A specific place in a roam.
 *
 * Unlike `Destination`s, these must be an actual place that an occupant can be in.
 */
export const AccessLinkLocation = z.discriminatedUnion("type", [
  AccessLinkRoomLocation,
  AccessLinkFloorLocation,
]);
export type AccessLinkLocation = z.infer<typeof AccessLinkLocation>;
