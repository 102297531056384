import { combineReducers } from "redux";
import { reducer as chatBookmarkReducer } from "./slices/chatBookmarkSlice.js";
import { reducer as chatDraftReducer } from "./slices/chatDraftSlice.js";
import { reducer as chatReducer } from "./slices/chatSlice.js";
import { reducer as chatStatusReducer } from "./slices/chatStatusSlice.js";
import { reducer as chatTargetReducer } from "./slices/chatTargetSlice.js";
import { reducer as confidentialReducer } from "./slices/confidentialSlice.js";
import { reducer as customEmojiAdmin } from "./slices/customEmojiAdminSlice.js";
import { reducer as customEmoji } from "./slices/customEmojiSlice.js";
import { reducer as inboxReducer } from "./slices/inboxSlice.js";
import { reducer as mapMessageReducer } from "./slices/mapMessageSlice.js";
import { reducer as meetingChatReducer } from "./slices/meetingChatSlice.js";
import { reducer as mentionReducer } from "./slices/mentionSlice.js";
import { reducer as messageReactionReducer } from "./slices/messageReactionSlice.js";
import { reducer as messageReducer } from "./slices/messageSlice.js";
import { reducer as slackGhostGroupReducer } from "./slices/slackGhostGroupSlice.js";
import { reducer as storyReducer } from "./slices/storySlice.js";
import { reducer as teamRoamReducer } from "./slices/teamRoamSlice.js";
import { reducer as threadReducer } from "./slices/threadSlice.js";
import { reducer as typingIndicatorReducer } from "./slices/typingIndicatorSlice.js";

export const reducer = combineReducers({
  chat: chatReducer,
  chatBookmark: chatBookmarkReducer,
  chatDraft: chatDraftReducer,
  chatStatus: chatStatusReducer,
  chatTarget: chatTargetReducer,
  confidential: confidentialReducer,
  customEmoji,
  customEmojiAdmin,
  inbox: inboxReducer,
  mapMessage: mapMessageReducer,
  meetingChat: meetingChatReducer,
  mention: mentionReducer,
  message: messageReducer,
  messageReaction: messageReactionReducer,
  slackGhostGroup: slackGhostGroupReducer,
  story: storyReducer,
  teamRoam: teamRoamReducer,
  thread: threadReducer,
  typingIndicator: typingIndicatorReducer,
});
