import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { applyMarkPatch } from "../../../../shared/helpers/whiteboard.js";
import { cli_wbrd_ApplyPatch } from "../../../../shared/HoloMessages/ClientWhiteboard.js";
import {
  srv_wbrd_AppliedPatches,
  srv_wbrd_Snapshot,
} from "../../../../shared/HoloMessages/ServerWhiteboard.js";
import { Mark, Snapshot } from "../../../../shared/Models/Whiteboard.js";
import { RootState } from "../../../store/reducers.js";

export interface ClientWhiteboard {
  snapshot: Snapshot;
  userTime: number;
  userTimes: Record<string, number>;
}

const slice = createSlice({
  name: "whiteboard",
  initialState: {
    whiteboards: {} as Record<string, ClientWhiteboard>,
  },
  reducers: {
    // UI-initiated actions
    applyPatch: (state, action: PayloadAction<cli_wbrd_ApplyPatch>) => {
      const { boardId, patch } = action.payload;

      if (patch.userTime) {
        const whiteboard = state.whiteboards[boardId];
        if (!whiteboard) {
          throw new Error(`whiteboard ${boardId} not initialized.`);
        }

        whiteboard.userTime = patch.userTime;
      }
    },

    // Server updates
    serverAppliedPatches: (state, action: PayloadAction<srv_wbrd_AppliedPatches>) => {
      const { boardId, patches, seq } = action.payload;

      const whiteboard = state.whiteboards[boardId];
      if (whiteboard) {
        if (whiteboard.snapshot.seq === seq - 1) {
          for (const patch of patches) {
            if (patch.assets) {
              applyMarksPatches(whiteboard.snapshot.assets, patch.assets);
            }
            if (patch.bindings) {
              applyMarksPatches(whiteboard.snapshot.bindings, patch.bindings);
            }
            if (patch.shapes) {
              applyMarksPatches(whiteboard.snapshot.shapes, patch.shapes);
            }
            if (patch.user) {
              whiteboard.snapshot.users[patch.userId] = patch.user;
            } else if (patch.user === null) {
              delete whiteboard.snapshot.users[patch.userId];
            }
            if (patch.userTime) {
              whiteboard.userTimes[patch.userId] = patch.userTime;
            }
            if (patch.viewport) {
              whiteboard.snapshot.viewport = patch.viewport;
            }
          }
        } else {
          throw new Error(
            `tried to apply patches to board ${boardId}, expected seq ${
              whiteboard.snapshot.seq + 1
            } but got ${seq}`
          );
        }

        whiteboard.snapshot.seq = seq;
      }
    },
    serverDeleteBoards: (state, action: PayloadAction<string[]>) => {
      for (const boardId of action.payload) {
        delete state.whiteboards[boardId];
      }
    },
    serverSnapshot: (state, action: PayloadAction<srv_wbrd_Snapshot>) => {
      const { boardId, snapshot } = action.payload;

      state.whiteboards[boardId] = {
        userTime: 0,
        userTimes: {},
        snapshot,
      };
    },
  },
});
export const { actions: WhiteboardActions, reducer: whiteboardReducer } = slice;

export const WhiteboardSelectors = {
  selectAssets: (boardId: string) => (state: RootState) =>
    state.holo.whiteboard.whiteboards[boardId]?.snapshot.assets,
  selectBindings: (boardId: string) => (state: RootState) =>
    state.holo.whiteboard.whiteboards[boardId]?.snapshot.bindings,
  selectIsEmpty: (boardId: string) => (state: RootState) => {
    const whiteboard = state.holo.whiteboard.whiteboards[boardId];

    return !whiteboard || Object.keys(whiteboard.snapshot.shapes).length === 0;
  },
  selectShapes: (boardId: string) => (state: RootState) =>
    state.holo.whiteboard.whiteboards[boardId]?.snapshot.shapes,
  selectSynced: (boardId: string, userId?: string) => (state: RootState) => {
    const whiteboard = state.holo.whiteboard.whiteboards[boardId];

    return whiteboard && (!userId || whiteboard.userTime === (whiteboard.userTimes[userId] ?? 0));
  },
  selectUsers: (boardId: string) => (state: RootState) =>
    state.holo.whiteboard.whiteboards[boardId]?.snapshot.users,
  selectViewport: (boardId: string) => (state: RootState) =>
    state.holo.whiteboard.whiteboards[boardId]?.snapshot.viewport,
};

const applyMarksPatches = (
  marks: Record<string, Mark>,
  patchMarks: Record<string, Partial<Mark | null>>
) => {
  for (const [id, patchMark] of Object.entries(patchMarks)) {
    if (patchMark) {
      const mark = marks[id];
      if (mark) {
        marks[id] = applyMarkPatch(mark, patchMark);
      } else if (patchMark.id) {
        marks[id] = patchMark as Mark;
      } else {
        throw new Error(`patched mark does not contain an id`);
      }
    } else {
      delete marks[id];
    }
  }
};
