export class BaseContainer {
  /**
   *
   * Below is instance storage and methods used to create instances
   *
   * Extend this class for your own containers
   *
   */

  public async initialize() {
    const promises = new Array<Promise<void>>();
    for (const [key, creator] of this.#asyncInstanceCreators) {
      promises.push(
        new Promise((resolve, reject) => {
          creator(this)
            .then((instance) => {
              this.#asyncInstances.set(key, instance);
              resolve();
            })
            .catch((err) => reject(err));
        })
      );
    }
    await Promise.all(promises);
    this.#initialized = true;
  }

  public instances = new Map();
  #keyedFactoryInstances = new Map();
  #asyncInstanceCreators = new Map<string, (container: any) => Promise<any>>();
  #asyncInstances = new Map<string, any>();
  #initialized = false;

  public getInstance<T>(imp: new (container: this, ...args: any[]) => T, ...args: any[]): T {
    const current = this.instances.get(imp);
    if (current) {
      return current as T;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const instance = new imp(this, ...args);
      this.instances.set(imp, instance);
      return instance;
    }
  }

  public getInstanceWithoutInjection<T>(imp: new (...args: any[]) => T, ...args: any[]): T {
    const current = this.instances.get(imp);
    if (current) {
      return current as T;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const instance = new imp(...args);
      this.instances.set(imp, instance);
      return instance;
    }
  }

  public getInstanceFromFactory<T>(factory: (container: this) => T, key?: string): T {
    if (key) {
      const current = this.#keyedFactoryInstances.get(key);
      if (current) {
        return current;
      }
    }
    const current = this.instances.get(factory);
    if (current) {
      return current as T;
    } else {
      const instance = factory(this);
      if (key) {
        this.#keyedFactoryInstances.set(key, instance);
      } else {
        this.instances.set(factory, instance);
      }
      return instance;
    }
  }

  /**
   * If an instance to be supplied in your container must be created
   * asynchronously, register it with this function in the constructor of your
   * container. The user of the container must then call {@link initialize}
   * before using it.
   *
   * @param key a string key that will be used to access the instance when
   * calling {@link #getAsyncInstance}
   * @param creator a function that takes a container (which it need not use)
   * and returns a promise for an instance of type T
   */
  public registerAsyncInstanceCreator<T>(
    key: string,
    creator: (container: this) => Promise<T>
  ): void {
    this.#asyncInstanceCreators.set(key, creator);
  }

  /**
   * For any keys registered with {@link #registerAsyncInstanceCreator},
   * retrieve the instance synchronously with this method by passing in the same
   * string key after {@link initialize} has been called.
   *
   */
  public getAsyncInstance<T>(key: string): T {
    if (!this.#initialized) {
      throw new Error(
        "Container not initialized. If you have any async instances, container.initialize() must complete before accessing them."
      );
    }
    const instance = this.#asyncInstances.get(key);
    if (!instance) {
      throw new Error(
        `Instance key ${key} not found. This is problem with the configuration of the container.`
      );
    }
    return instance;
  }

  /**
   * Use this method to close open connections; db, sockets etc.
   * Useful for closing open handles in tests cleanup.
   */
  public async cleanup(): Promise<void> {
    throw new Error("Implement me");
  }
}
