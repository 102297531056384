/* cspell:ignore agentkeepalive */
import HttpAgent, { HttpsAgent } from "agentkeepalive";
import { AxiosRequestConfig } from "axios";

const httpAgent = new HttpAgent();
const httpsAgent = new HttpsAgent();

/**
 * Base axios configuration for use in server side requests.
 *
 *
 * By default, the default Node.js HTTP/HTTPS agent creates a new TCP connection for every
 * new request. To avoid the cost of establishing a new connection, you can reuse an existing connection.
 *
 * Uses `agentkeepalive` which allows connection reuse. (https://github.com/node-modules/agentkeepalive)
 *
 */
export const serverAxiosConfig: AxiosRequestConfig = {
  httpAgent,
  httpsAgent,
};
