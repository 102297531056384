import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { parseTypeCodeFromQuery } from "../../../shared/helpers/accessLinks.js";
import { LINK_CONFIGURATIONS_BY_TYPE } from "../../../shared/Models/AccessLinks/LinkConfiguration.js";
import { Location } from "../../../shared/Models/Location.js";
import { createDeepEqualSelector } from "../../helpers/redux.js";
import { RootState } from "../../store/reducers.js";

interface RoamCheckinPayload {
  roamId: number;
  hostPersonId?: number;
  accessLinkQuery?: string;
  adHocName?: string;
}

interface RestoreLocationPayload {
  location?: Location;
}

export interface AwayCache {
  roamId?: number;
  hostPersonId?: number;
  accessLinkQuery?: string;
  usedAccessLinkQuery?: string;

  restoreLocation?: Location;
  restoreLocationValidUntil?: number;
}

const initialState: AwayCache = {
  roamId: undefined,
  hostPersonId: undefined,
  accessLinkQuery: undefined,
  usedAccessLinkQuery: undefined,
  restoreLocation: undefined,
  restoreLocationValidUntil: undefined,
};

const slice = createSlice({
  name: "awayCache",
  initialState,
  reducers: {
    roamCheckin: (state, action: PayloadAction<RoamCheckinPayload>) => {
      const { roamId, hostPersonId, accessLinkQuery, adHocName } = action.payload;
      if (roamId !== state.roamId) {
        state.roamId = roamId;
        state.hostPersonId = hostPersonId;
        state.accessLinkQuery = accessLinkQuery;
        state.restoreLocation = undefined;
      }
    },
    setAccessLinkQuery: (state, action: PayloadAction<string>) => {
      const accessLinkQuery = action.payload;
      if (state.accessLinkQuery !== accessLinkQuery) {
        state.accessLinkQuery = accessLinkQuery;
      }
      // Assume that if we're explicitly setting the access link query that we want to reset
      // the used state.
      // This matters in particular for electron—if you try to use the same link twice in a row,
      // the second one won't work since it still matches the used query.
      state.usedAccessLinkQuery = undefined;
    },
    clearAccessLinkQuery: (state) => {
      state.accessLinkQuery = undefined;
      state.usedAccessLinkQuery = undefined;
    },
    setUsedAccessLinkQuery: (state, action: PayloadAction<string | undefined>) => {
      state.usedAccessLinkQuery = action.payload;
    },
    setRestoreLocation: (state, action: PayloadAction<RestoreLocationPayload>) => {
      const { location } = action.payload;

      state.restoreLocation = location;
    },
    setRestoreLocationValidUntil: (state, action: PayloadAction<number>) => {
      state.restoreLocationValidUntil = action.payload;
    },
    clearAwayCache: (state) => {
      state.roamId = undefined;
      state.hostPersonId = undefined;
      state.accessLinkQuery = undefined;
      state.restoreLocation = undefined;
    },
    clearRoamAndHost: (state) => {
      state.roamId = undefined;
      state.hostPersonId = undefined;
    },
    setAwayCache: (_, action: PayloadAction<AwayCache>) => {
      return action.payload;
    },
    setAudienceIfTheater: () => {},
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.awayCache;
const selectAccessLinkQuery = (state: RootState) => selectSlice(state).accessLinkQuery;

export const selectors = {
  selectSlice,
  selectAccessLinkQuery,
  selectUsedAccessLinkQuery: (state: RootState) => selectSlice(state).usedAccessLinkQuery,
  /**
   * Selects an access link configuration based on the current query.
   * Should be identical to the link configuration for an access link object, assuming the access link corresponds to the current query.
   */
  selectAccessLinkQueryConfiguration: createDeepEqualSelector(selectAccessLinkQuery, (query) => {
    const type = parseTypeCodeFromQuery(query);
    if (!type) {
      return undefined;
    }
    return LINK_CONFIGURATIONS_BY_TYPE[type];
  }),
};

export const AwayCacheActions = actions;
export const AwayCacheSelectors = selectors;
