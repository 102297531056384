import { z } from "zod";
import { stringId } from "../Models/zodTypes.js";

export const VirtualShelfItemBounds = z.object({
  x: z.number().nonnegative(),
  y: z.number().nonnegative(),
  width: z.number().nonnegative(),
  height: z.number().nonnegative(),
});
export type VirtualShelfItemBounds = z.infer<typeof VirtualShelfItemBounds>;

export const VirtualShelfRenderInfo = z.object({
  shelfId: stringId(),
  width: z.number().nonnegative(),
  height: z.number().nonnegative(),
  // itemId -> bounding box
  itemBounds: z.record(VirtualShelfItemBounds),
});
export type VirtualShelfRenderInfo = z.infer<typeof VirtualShelfRenderInfo>;
