import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store/reducers.js";

const slice = createSlice({
  name: "floorOccupantCounts",
  initialState: {
    occupantCountsByFloor: {} as { [floorId: number]: number },
  },
  reducers: {
    setOccupantCountsByFloor: (state, action: PayloadAction<{ [floorId: number]: number }>) => {
      state.occupantCountsByFloor = action.payload;
    },
  },
});

export const { actions, reducer } = slice;

export const selectors = {
  selectAnyOccupantOnFloor: (floorId: number) => (state: RootState) => {
    const occupantCount = state.overworld.floorOccupantCounts.occupantCountsByFloor[floorId];
    return occupantCount !== undefined && occupantCount > 0;
  },
};
