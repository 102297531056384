import { combineReducers } from "redux";
import { billingAccountsReducer } from "./slices/billingAccountsSlice.js";
import { invoicesReducer } from "./slices/invoicesSlice.js";
import { productsReducer } from "./slices/productsSlice.js";
import { stripeReducer } from "./slices/stripeSlice.js";
import { subscriptionsReducer } from "./slices/subscriptionsSlice.js";

export const reducer = combineReducers({
  billingAccounts: billingAccountsReducer,
  invoices: invoicesReducer,
  products: productsReducer,
  stripe: stripeReducer,
  subscriptions: subscriptionsReducer,
});
