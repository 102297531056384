import React, { useMemo, useState } from "react";
import { inspect } from "util";
import { v4 as uuidv4 } from "uuid";
import VideoPlayer from "../../client/components/VideoPlayer.js";
import { styled } from "../../client/styles/theme.js";
import { logger } from "../../shared/infra/logger.js";
import { BenchmarkResults } from "./BenchmarkResults.js";
import { IPipelinesContainer } from "./IPipelinesContainer.js";
import { PipelineMetrics } from "./PipelineMetrics.js";
import { PipelineOptions } from "./PipelineOptions.js";

interface Props {
  container: IPipelinesContainer;
}

export const Pipelines: React.FC<Props> = ({ container }: Props) => {
  const [videoStream, setVideoStream] = useState<MediaStream | undefined>();
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [streamId] = useState(uuidv4());
  const pipelineLauncher = useMemo(() => {
    const managedPipeline = container.avPipelines().newVideoPipelineLauncher(
      streamId,
      (newVideoStream) => {
        setVideoStream(newVideoStream.stream);
        setVideoLoaded(true);
        logger.info("Setting video stream");
      },
      ({ rawError, pipelineError }) => {
        logger.error(`rawError: ${inspect(rawError)}, pipelineError ${inspect(pipelineError)}`);
      }
    );
    managedPipeline.setWant(
      {
        deviceIds: [],
        fallback: "last",
      },
      undefined
    );

    return managedPipeline;
  }, [container, streamId]);

  return (
    <Container>
      <VideoPreview>
        {videoStream && (
          <VideoPlayer
            playerContext="CheckAVSettings"
            mediaStream={videoStream}
            mirror={true}
            hidden={!videoLoaded}
            onloadstart={() => setVideoLoaded(false)}
            onloadeddata={() => {
              setVideoLoaded(true);
            }}
            source={"selfPreview"}
          />
        )}
      </VideoPreview>
      <Options>
        <PipelineOptions pipelineLauncher={pipelineLauncher} stream={videoStream} />
      </Options>
      <Metrics>
        <PipelineMetrics pipelineLauncher={pipelineLauncher} streamId={streamId} />
      </Metrics>
      <Benchmarks>
        <BenchmarkResults />
      </Benchmarks>
    </Container>
  );
};

const Container = styled.div``;

const VideoPreview = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;
VideoPreview.displayName = "VideoPreview";

const Options = styled.div`
  position: absolute;
  top: 2em;
  right: 2em;
  width: 20em;
  min-height: 20em;
`;
Options.displayName = "Options";

const Metrics = styled.div`
  position: absolute;
  bottom: 2em;
  right: 2em;
  width: 20em;
`;
Metrics.displayName = "Metrics";

const Benchmarks = styled.div`
  position: absolute;
  bottom: 2em;
  left: 2em;
  width: 20em;
`;
Benchmarks.displayName = "Benchmarks";
