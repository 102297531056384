import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { MeetingLink } from "../../../../shared/Models/AccessLinks/MeetingLink.js";
import { NewMeetingLink } from "../../../../shared/Models/AccessLinks/NewMeetingLink.js";
import { PersonalLink } from "../../../../shared/Models/AccessLinks/PersonalLink.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

const accessLinkAdapter = createEntityAdapter<MeetingLink>();

export interface PersonRoamPayload {
  roamId: number;
  personId: number;
}

export interface AlertHostPayload {
  visitorName: string;
}

export interface SetAllPayload {
  links: MeetingLink[];
}

export interface NewAccessLinkPayload {
  roamId: number;
  personId: number;
  id: string;
}

interface SetEditableStatusPayload {
  linkId: string;
  canEdit: boolean;
}

const slice = createSlice({
  name: "accessLink",
  initialState: {
    accessLinks: accessLinkAdapter.getInitialState(),
    personalLinks: {} as {
      [roamId: number]: PersonalLink;
    },
    editableAccessLinkIds: {} as Record<string, boolean>,
  },
  reducers: {
    resetPersonalLink: (_state, _action: PayloadAction<PersonRoamPayload>) => {},
    revokePersonalLink: (_state, _action: PayloadAction<PersonRoamPayload>) => {},

    setPersonalLink: (state, action: PayloadAction<PersonalLink>) => {
      const { roamId } = action.payload;
      state.personalLinks[roamId] = action.payload;
    },
    removePersonalLink: (state, action: PayloadAction<PersonRoamPayload>) => {
      const { roamId } = action.payload;
      delete state.personalLinks[roamId];
    },

    newMeetingLink: (_state, _action: PayloadAction<NewAccessLinkPayload>) => {},
    updateMeetingLink: (_state, _action: PayloadAction<MeetingLink>) => {},
    deleteMeetingLink: (_state, _action: PayloadAction<string>) => {},
    requestMeetingLink: (_state, _action: PayloadAction<string>) => {},

    addMeetingLink: (_state, _action: PayloadAction<NewMeetingLink>) => {},
    requestAll: (_state, _action: PayloadAction<PersonRoamPayload>) => {},
    setAll: (state, action: PayloadAction<SetAllPayload>) => {
      const { links } = action.payload;
      accessLinkAdapter.setAll(state.accessLinks, links);
    },
    upsertOne: (state, action: PayloadAction<MeetingLink>) => {
      const link = action.payload;

      accessLinkAdapter.setOne(state.accessLinks, link);
    },
    removeOne: (state, action: PayloadAction<string>) => {
      accessLinkAdapter.removeOne(state.accessLinks, action.payload);
    },
    setEditableStatus: (state, action: PayloadAction<SetEditableStatusPayload>) => {
      const { linkId, canEdit } = action.payload;
      state.editableAccessLinkIds[linkId] = canEdit;
    },
  },
});

export const { actions, reducer } = slice;

const adapterSelectors = accessLinkAdapter.getSelectors(
  (state: RootState) => state.world.accessLink.accessLinks
);
const selectSlice = (state: RootState) => state.world.accessLink;
export const selectors = {
  selectMeetingLinkIdsByRoamId: defaultMemoize((roamId?: number) =>
    createDeepEqualSelector(adapterSelectors.selectAll, (meetingLinks) =>
      meetingLinks.filter((l) => l.roamId === roamId).map((l) => l.id)
    )
  ),
  selectMeetingLinksByRoamId: defaultMemoize((roamId?: number) =>
    createDeepEqualSelector(adapterSelectors.selectAll, (meetingLinks) =>
      meetingLinks.filter((l) => l.roamId === roamId)
    )
  ),
  selectCalendarIdsForMeetingLinksMissingData: defaultMemoize((roamId?: number) =>
    createDeepEqualSelector(adapterSelectors.selectAll, (links) => {
      const calendarIds = links
        .filter((l) => l.roamId === roamId)
        .filter((link) => link.extEventType === "Unknown" && link.extCalendarId)
        .map((link) => link.extCalendarId ?? "ERROR");
      return [...new Set(calendarIds)];
    })
  ),
  selectPersonalLinkByRoamId: (roamId?: number) => (state: RootState) =>
    roamId ? selectSlice(state).personalLinks[roamId] : undefined,
  selectAccessLinkById: (linkId?: string) => (state: RootState) =>
    linkId ? selectSlice(state).accessLinks.entities[linkId] : undefined,
  selectEditableStatusById: defaultMemoize((linkId?: string) =>
    createSelector(selectSlice, (slice) => (linkId ? !!slice.editableAccessLinkIds[linkId] : false))
  ),
};

export const AccessLinkSelectors = selectors;
export const AccessLinkActions = actions;
