import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { Item, ItemType } from "../../../../shared/Models/Item.js";
import { createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { RootState } from "../../../store/reducers.js";

export interface StartAddItemPayload {
  itemLocation: ItemLocation;
  windowKey: WindowKey;
}

export interface ItemLocation {
  shelfId: string;
  level?: number;
  position?: number;
}

const slice = createSlice({
  name: "shelfItemCreator",
  initialState: {
    draftItem: undefined as Item | undefined,
    draftLocation: undefined as ItemLocation | undefined,
    selectedItemType: "video" as ItemType,
    errorMessage: undefined as string | undefined,
  },
  reducers: {
    selectItemType: (state, action: PayloadAction<ItemType>) => {
      state.selectedItemType = action.payload;
    },
    startAddItem: (state, action: PayloadAction<StartAddItemPayload>) => {
      state.draftLocation = action.payload.itemLocation;
    },
    setDraftItem: (state, action: PayloadAction<Item>) => {
      state.draftItem = action.payload;
    },
    clearDraftItem: (state) => {
      state.draftItem = undefined;
    },
    clearDraftLocation: (state) => {
      state.draftLocation = undefined;
    },

    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    clearErrorMessage: (state) => {
      state.errorMessage = undefined;
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.things.shelfItemCreator;
export const selectors = {
  selectErrorMessage: (state: RootState) => state.things.shelfItemCreator.errorMessage,
  selectDraftLocation: (state: RootState) => state.things.shelfItemCreator.draftLocation,
  selectDraftItem: (state: RootState) => state.things.shelfItemCreator.draftItem,
  selectDraftItemIfType: defaultMemoize((itemType: string) =>
    createSelector(selectSlice, (slice) => {
      const draftItem = slice.draftItem;
      if (draftItem?.itemType === itemType) {
        return draftItem;
      }
    })
  ),
  selectSelectedItemType: createSelector(selectSlice, (slice) => slice.selectedItemType),
};

export const ShelfItemCreatorActions = actions;
export const ShelfItemCreatorSelectors = selectors;
