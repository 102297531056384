import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Draft } from "immer";
import { defaultMemoize } from "reselect";
import { VisitEndedContent, VisitStartedContent } from "../../../../shared/Models/ChatMessage.js";
import { Occupant, OccupantType } from "../../../../shared/Models/Occupant.js";
import { TypeNarrowError } from "../../../../shared/TypeNarrowError.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface VisitorInfo {
  roamId: number;
  occupantId: string;
  hostId: number;
  name?: string;
  imageUrl?: string;
  accessLinkId?: string;
  accessLinkName?: string;
  occupantType: OccupantType;
  email?: string;
  guestClientUuid?: string;
}

export interface VisitorId {
  roamId: number;
  occupantId: string;
}

export interface VisitorLocation {
  roamId: number;
  occupantId: string;
  sectionId: string;
}

export interface VisitStartedMessagePayload {
  content: VisitStartedContent;
  notificationAllowed: boolean;
  soundAllowed: boolean;
}

const initialState = {
  visitorsByRoam: {} as {
    [roamId: number]: VisitorInfo[];
  },
};
type SliceType = typeof initialState;

const findInfo = (slice: Draft<SliceType>, roamId: number, occupantId: string) => {
  if (roamId in slice.visitorsByRoam) {
    const visitors = slice.visitorsByRoam[roamId];
    if (!visitors) throw new TypeNarrowError();
    return visitors.find((v) => v.occupantId === occupantId);
  }
  return undefined;
};

const addInfo = (state: Draft<SliceType>, info: VisitorInfo) => {
  const { roamId, occupantId } = info;
  const existing = findInfo(state, roamId, occupantId);
  if (existing) {
    return;
  }

  if (roamId in state.visitorsByRoam) {
    const visitors = state.visitorsByRoam[roamId];
    if (!visitors) throw new TypeNarrowError();
    visitors.push(info);
  } else {
    state.visitorsByRoam[roamId] = [info];
  }
};

const clearInfo = (state: Draft<SliceType>) => {
  state.visitorsByRoam = {};
};

const slice = createSlice({
  name: "hosting",
  initialState,
  reducers: {
    moveVisitorToFront: (state, action: PayloadAction<VisitorId>) => {
      const { roamId, occupantId } = action.payload;
      const current = state.visitorsByRoam[roamId];
      if (!current) return;
      const i = current.findIndex((v) => v.occupantId === occupantId);
      if (i > 0) {
        const visitorInfo = current[i];
        if (!visitorInfo) throw new TypeNarrowError();
        current.splice(i, 1);
        current.unshift(visitorInfo);
      }
    },
    ejectOccupantFromRoam: (_state, _action: PayloadAction<string>) => {},

    visitStarted: (state, action: PayloadAction<VisitStartedContent>) => {
      const {
        roamId,
        guestOccupantId,
        guestName,
        guestImageUrl,
        hostId,
        accessLinkId,
        accessLinkName,
        guestOccupantType,
        guestEmail,
        guestClientUuid,
      } = action.payload;

      const info: VisitorInfo = {
        roamId,
        occupantId: guestOccupantId,
        name: guestName,
        imageUrl: guestImageUrl,
        hostId,
        accessLinkId,
        accessLinkName,
        occupantType: guestOccupantType,
        email: guestEmail,
        guestClientUuid,
      };
      addInfo(state, info);
    },
    visitEnded: (state, action: PayloadAction<VisitorId>) => {
      const { roamId, occupantId } = action.payload;

      const current = state.visitorsByRoam[roamId];
      if (current) {
        const i = current.findIndex((v) => v.occupantId === occupantId);
        if (i >= 0) {
          current.splice(i, 1);
        }
      }
    },
    activeVisitors: (state, action: PayloadAction<Occupant[]>) => {
      clearInfo(state);
      for (const occupant of action.payload) {
        if (occupant.hostId) {
          const info: VisitorInfo = {
            roamId: occupant.roamId,
            occupantId: occupant.id,
            name: occupant.name,
            imageUrl: occupant.imageAbsoluteUrl,
            hostId: occupant.hostId,
            email: occupant.email,
            occupantType: occupant.occupantType,
          };
          addInfo(state, info);
        }
      }
    },

    visitStartedMessage: (_state, _action: PayloadAction<VisitStartedMessagePayload>) => {},
    visitEndedMessage: (_state, _action: PayloadAction<VisitEndedContent>) => {},
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.world.hosting;
export const selectors = {
  selectVisitorsByRoam: (roamId?: number) => (state: RootState) =>
    roamId ? selectSlice(state).visitorsByRoam[roamId] : undefined,
  selectVisitorByOccupant: defaultMemoize(
    (roamId?: number, occupantId?: string) =>
      createSelector(selectSlice, (slice) =>
        roamId && occupantId
          ? slice.visitorsByRoam[roamId]?.find((v) => v.occupantId === occupantId)
          : undefined
      ),
    { maxSize: 100 }
  ),
};

export const HostingActions = actions;
export const HostingSelectors = selectors;
