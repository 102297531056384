/**
 * Typescript struggles with some filtering use cases; the
 * workaround is to use a typeguard (like below) and typescript
 * will be able to infer the correct type
 *
 * See: https://github.com/microsoft/TypeScript/issues/20707#issuecomment-351874491
 *
 */
export const notUndefined = <T>(x: T | undefined): x is T => {
  return x !== undefined;
};

/**
 * Returns true if [x] is not undefined and is not null.
 * This is type guarded; if true, the result is guaranteed to be a [T].
 */
export const isDefined = <T>(x: T | undefined | null): x is T => {
  return x !== undefined && x !== null;
};

export const isFulfilled = <T>(x: PromiseSettledResult<T>): x is PromiseFulfilledResult<T> => {
  return x.status === "fulfilled";
};

export const isRejected = <T>(x: PromiseSettledResult<T>): x is PromiseRejectedResult => {
  return x.status === "rejected";
};
