import { EventEmitter } from "events";
import { IVoiceDetector } from "../interfaces/IVoiceDetector.js";

// Once the user stops talking, VoiceDetector will wait this many milliseconds before actually
// sending the voiceStop event
const voiceStopDuration = 300;

export class VoiceDetector extends EventEmitter implements IVoiceDetector {
  private started = false;
  private lastVolume?: number;
  private stopTimeoutId?: ReturnType<typeof setTimeout>;

  public get voiceStarted(): boolean {
    return this.started;
  }

  public onVoiceStart() {
    if (this.stopTimeoutId !== undefined) {
      // onVoiceStop was called, but we still haven't sent the voiceStop event because
      // voiceStopDuration hasn't elapsed. Just clear the timeout that would send it.
      clearTimeout(this.stopTimeoutId);
      this.stopTimeoutId = undefined;
      return;
    }

    this.started = true;
    super.emit("voiceStart");
  }

  public onVoiceStop() {
    if (this.stopTimeoutId === undefined) {
      // After a timeout, send the voiceStop event
      this.stopTimeoutId = setTimeout(() => {
        this.stopTimeoutId = undefined;
        this.started = false;
        this.lastVolume = undefined;
        super.emit("voiceStop");
        super.emit("voiceUpdate", 0);
      }, voiceStopDuration);
    }
  }

  public onVoiceUpdate(volume: number) {
    if (this.started && volume !== this.lastVolume) {
      this.lastVolume = volume;
      super.emit("voiceUpdate", volume);
    }
  }

  public onMaybeVoice() {
    super.emit("maybeVoice");
  }

  public dump(): any {
    return {
      started: this.started,
      lastVolume: this.lastVolume,
      stopTimeoutActive: !!this.stopTimeoutId,
    };
  }
}
