/**
 * An error to throw from the default case of switch statements to ensure all cases have been handled.
 */
export class UnreachableError extends Error {
  constructor(_: never) {
    super("This should be impossible.");

    this.name = "UnreachableError";
  }
}
