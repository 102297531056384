import { VideoPipelineName, VideoPipelineStats } from "../../../../shared/Models/VideoPipeline.js";
import { PipelineError } from "./VideoPipelineError.js";

export interface IVideoPipelineLauncher {
  setWant(
    sourceSpec: VideoSourceSpec | undefined,
    postProcSpec: PostProcVideoSpec | undefined,
    forceReplace?: boolean
  ): void;
  getPipelineError(): PipelineError | undefined;
  close(): void;
  getStats(): VideoPipelineStats | undefined;
  dump(): any;
}

export type PostProcVideoStatus = "loading" | "loaded" | "failed";

export interface VideoFeatureSupport {
  background: boolean;
  touchup: boolean;
}

export interface VideoSourceSpec {
  // Use the first device ID from this list, if any of them are present
  deviceIds: string[];
  // What to do if none of the listed devices are present:
  // auto: Select a device automatically
  // last: Try to use the last device ID specified anyway
  fallback: "auto" | "last";
}

export type VirtbgSpec =
  | {
      mode: "blurred";
      blurLevel: number;
    }
  | {
      mode: "static_image";
      imageSrc: string;
    };

// Types that can be passed to both drawImage() and texImage2D()/texSubImage2D()
export type VirtbgImageSource =
  | HTMLImageElement
  | HTMLVideoElement
  | HTMLCanvasElement
  | OffscreenCanvas
  | ImageBitmap;

const videoPipelineFriendlyNames: Record<VideoPipelineName, string> = {
  tflite: "Legacy TFLite Pipeline",
  mediapipe: "Advanced Blur/Touchup",
  mediapipeNoWorker: "Advanced Blur/Touchup (no web worker)",
  mediapipeMedium: "Standard Blur/Touchup",
  mediapipeMediumNoWorker: "Standard Blur/Touchup (no web worker)",
  mediapipeBasic: "Basic Blur",
  mediapipeBasicNoWorker: "Basic Blur (no web worker)",
};

export const videoPipelineFriendlyName = (pipeline?: string): string => {
  if (!pipeline) {
    return "(None)";
  }
  const res = VideoPipelineName.safeParse(pipeline);
  if (res.success) {
    return videoPipelineFriendlyNames[res.data];
  }
  return pipeline;
};

export interface PostProcVideoSpec {
  pipelineName: VideoPipelineName;
  virtbg?: VirtbgSpec;
  foregroundOverlays?: ImageBitmap[];
  touchupLevel?: number;
  disableInsertableStreams?: boolean;
  debugStage?: number;
}
