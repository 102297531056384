import { z } from "zod";
import { CalendarStatus } from "./CalendarStatus.js";

/**
 * Calendar configuration for a specific roam.
 */
export const RoamCalendarConfig = z.object({
  /** ID of the roam this config is for. */
  roamId: z.number(),
  /**
   * Calendar status for the roam.
   *
   * NOTE(vple):
   *    The naming/typing for this field is somewhat legacy—the `CalendarStatus` type used to be
   *    on `CalendarSettings` itself.
   *    My understanding is that it is a combination of room sync and general integration status.
   *    The usage here is intended for room sync status, but this hasn't been fully migrated; most
   *    places are still probably using the account level status.
   */
  status: CalendarStatus,
});
export type RoamCalendarConfig = z.infer<typeof RoamCalendarConfig>;
