import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  BenchmarkResult,
  BenchmarkResultSuccess,
  VideoPipelineName,
  VideoPipelineStats,
} from "../../../shared/Models/VideoPipeline.js";
import { getErrorMessage } from "../../../shared/helpers/errors.js";
import { logger } from "../../../shared/infra/logger.js";
import benchmark from "../../assets/benchmark.webm";
import { IVideoPipelineCache } from "../video/interfaces/IVideoPipelineCache.js";
import { IVideoPipelineExecutor } from "../video/interfaces/IVideoPipelineExecutor.js";
import { PipelineError } from "../video/interfaces/VideoPipelineError.js";
import { evaluateBenchmark } from "./benchmarkEval.js";
import { IPipelineBenchmark } from "./interfaces/IPipelineBenchmark.js";

interface HTMLVideoElementWithExperimentalFeatures extends HTMLVideoElement {
  captureStream: () => MediaStream;
}

interface Injected {
  videoPipelineCache(): IVideoPipelineCache;
}

export class PipelineBenchmark implements IPipelineBenchmark {
  private video: HTMLVideoElementWithExperimentalFeatures;
  private stream?: MediaStream;
  private executor: IVideoPipelineExecutor;
  private streamId: string;
  private resolver?: (result: BenchmarkResult) => void;
  private completed = false;
  private completionTimeout?: NodeJS.Timeout;
  private statsTimeout?: NodeJS.Timeout;
  private stuckTimeout?: NodeJS.Timeout;
  private stats = new Array<VideoPipelineStats>();
  private loadStartTime?: DOMHighResTimeStamp;
  private benchmarkStartTime?: DOMHighResTimeStamp;
  private loadDuration?: number;

  constructor(
    private container: Injected,
    private pipelineName: VideoPipelineName,
    private maxLengthMs = 8000
  ) {
    // eslint-disable-next-line no-restricted-globals
    this.video = document.createElement("video") as HTMLVideoElementWithExperimentalFeatures;
    this.streamId = uuidv4();
    this.loadStartTime = performance.now();
    this.executor = this.container
      .videoPipelineCache()
      .createExecutorForPipeline(pipelineName, this.streamId, (err) =>
        this.handlePipelineError(err)
      );
  }

  public run(): Promise<BenchmarkResult> {
    if (this.completed || this.resolver) {
      throw new Error("cannot run benchmark a second time");
    }

    const promise = new Promise<BenchmarkResult>((resolve) => {
      this.resolver = resolve;
      this.stuckTimeout = setTimeout(
        () => this.handleFailure(new Error("benchmark failed to complete for unknown reason")),
        this.maxLengthMs + 10000
      );
    });

    this.startBenchmark().catch((err) =>
      this.handleFailure(new Error(`error starting benchmark: ${getErrorMessage(err)}`))
    );

    return promise;
  }

  private async startBenchmark() {
    await this.initVideo();
    if (!this.stream) {
      throw new Error("this.stream undefined after initVideo");
    }
    const output = await this.executor.update(
      {
        pipelineName: this.pipelineName,
        virtbg: { mode: "blurred", blurLevel: 5 },
        touchupLevel: 5,
      },
      this.stream
    );
    if (this.loadStartTime) {
      this.loadDuration = performance.now() - this.loadStartTime;
    }
    this.benchmarkStartTime = performance.now();
    this.statsTimeout = setInterval(() => {
      const currStats = cloneDeep(this.executor.getStats());
      let lastStats: VideoPipelineStats | undefined;
      if (this.stats.length > 0) {
        lastStats = this.stats[this.stats.length - 1];
      }
      if (currStats.timestamp !== lastStats?.timestamp) {
        this.stats.push(currStats);
        const result = evaluateBenchmark(this.pipelineName, this.stats);
        if (result === "notSupported" || result === "supported") {
          this.handleBenchmarkComplete(result);
        }
      }
    }, 1000);
    this.completionTimeout = setTimeout(
      () => this.handleBenchmarkComplete("timeout"),
      this.maxLengthMs
    );
    this.video
      .play()
      .catch((err) => logger.error({ err }, "PipelineBenchmark: error starting video playback"));
  }

  private initVideo(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.video.onloadedmetadata = (ev) => {
        this.stream = this.video.captureStream();
        resolve();
      };
      this.video.onerror = (ev) => reject(ev.toString());
      this.video.autoplay = false;
      this.video.muted = true;
      this.video.loop = true;
      this.video.style.display = "none";
      this.video.playsInline = true;
      this.video.src = benchmark;
    });
  }

  private handleFailure(error: Error) {
    if (this.completed || !this.resolver) {
      return;
    }
    this.completed = true;
    this.resolver({
      status: "failure",
      error: getErrorMessage(error),
      pipelineName: this.pipelineName,
      pipelineVersion: 1, // todo
    });
    this.cleanup();
  }

  private cleanup() {
    if (this.statsTimeout) {
      clearTimeout(this.statsTimeout);
    }
    if (this.completionTimeout) {
      clearTimeout(this.completionTimeout);
    }
    if (this.stuckTimeout) {
      clearTimeout(this.stuckTimeout);
    }
    this.resolver = undefined;
    this.video.pause();
    this.executor?.close().catch((err) => logger.error({ err }, "error closing executor"));
    if (this.stream) {
      for (const t of this.stream?.getTracks() ?? []) {
        this.stream?.removeTrack(t);
      }
      this.completed = true;
    }
  }

  private handleBenchmarkComplete(result: "supported" | "notSupported" | "timeout") {
    if (!this.executor || !this.resolver || this.completed) {
      return;
    }
    this.completed = true;
    const timeTakenMs = this.benchmarkStartTime && performance.now() - this.benchmarkStartTime;

    const supported = result === "supported";

    const pipelineVersion = this.container
      .videoPipelineCache()
      .currentVersionForPipeline(this.pipelineName);
    if (!pipelineVersion) {
      this.handleFailure(new Error("could not determine pipeline version"));
      return;
    }

    const results: BenchmarkResultSuccess = {
      status: "success",
      pipelineName: this.pipelineName,
      pipelineVersion,
      timestamp: Date.now(),
      loadTimeMs: this.loadDuration,
      rawStats: this.stats,
      timeTakenMs,
      supported,
    };
    this.resolver(results);
    this.cleanup();
  }

  private handlePipelineError(err: PipelineError | undefined) {
    // Message is received with err == undefined when there is no error after there previously was
    // one
    if (err !== undefined) {
      this.handleFailure(new Error(`Pipeline error: ${getErrorMessage(err)}`));
    }
  }
}
