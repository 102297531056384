import { Action, Dispatch, MiddlewareAPI } from "@reduxjs/toolkit";
import { TypedActionCreator } from "@reduxjs/toolkit/dist/mapBuilders.js";
import { EventEmitter } from "eventemitter3";
import { Store } from "redux";
import { RoamStoreInit, createClientStore } from "../../store/createClientStore.js";
import { RootState } from "../../store/reducers.js";
import { IRedux, ReduxListener } from "./IRedux.js";

export class Redux<S = RootState> implements IRedux<S> {
  private eventEmitter = new EventEmitter();
  private store: Store<S>;

  private middleware =
    (store: MiddlewareAPI<Dispatch<Action<string>>, S>) =>
    (next: Dispatch<Action<string>>) =>
    (action: Action<string>) => {
      const previousState = store.getState();
      const result = next(action);
      const currentState = store.getState();

      this.eventEmitter.emit(action.type, action, currentState, previousState);

      return result;
    };

  public constructor(storeInit: RoamStoreInit) {
    this.store = createClientStore<S>({
      ...storeInit,
      additionalMiddlewares: [...(storeInit.additionalMiddlewares ?? []), this.middleware],
    });
  }

  public init() {
    // Does nothing.
  }

  public addListener<AC extends TypedActionCreator<string>>(
    actionCreator: AC,
    listener: ReduxListener<ReturnType<AC>, S>
  ) {
    this.eventEmitter.addListener(actionCreator.type, listener);
  }

  public removeListener<AC extends TypedActionCreator<string>>(
    actionCreator: AC,
    listener: ReduxListener<ReturnType<AC>, S>
  ) {
    this.eventEmitter.removeListener(actionCreator.type, listener);
  }

  public get dispatch() {
    return this.store.dispatch;
  }

  public select<T>(selector: (state: S) => T) {
    return selector(this.store.getState());
  }

  public getState() {
    return this.store.getState();
  }

  public getStore() {
    return this.store;
  }
}
