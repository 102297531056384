import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Location } from "../../../../shared/Models/Location.js";
import {
  isMemberOrHasMemberAccess,
  isMemberOrRoamgineer,
  Occupant,
} from "../../../../shared/Models/Occupant.js";
import { WillReturn } from "../../../../shared/Models/Room.js";
import { AnyWorldSelectors } from "../../../anyworld/store/slices/anyWorldSlice.js";
import { createSelector } from "../../../helpers/redux.js";
import { updateInternal, WantHave } from "../../../store/clientConnectionHelpers.js";
import { RootState } from "../../../store/reducers.js";

type RoamCheckInSimple = {
  roamId: number;
  wantSectionId?: undefined;
  wantRoomId?: undefined;
  teleRoomId?: string;
};
type RoamCheckInToSection = {
  roamId: number;
  wantSectionId: string;
  wantRoomId?: undefined;
  teleRoomId?: string;
};
type RoamCheckInToRoom = {
  roamId: number;
  wantSectionId: string;
  wantRoomId: number;
  teleRoomId?: string;
};
type RoamCheckOut = { roamId: undefined; willReturn?: WillReturn; teleRoomId?: undefined };
export type RoamCheckIn =
  | RoamCheckInSimple
  | RoamCheckInToSection
  | RoamCheckInToRoom
  | RoamCheckOut;

type OccupantJwt = {
  jwt: string;
  id: string;
  roamId: number;
};

interface State {
  // follows patterns in docs/clientConnections.md
  wantRoamCheckIn: RoamCheckIn;
  internalWantRoamCheckIn: RoamCheckIn;
  internalHaveRoamCheckIn: RoamCheckIn;

  roamCheckInRetryTime: string | undefined;
  roamCheckInErrorDetail: string | undefined;

  showOnboarding: boolean;
  wantMyLocationAfterOnboarding: Location | undefined;

  activeOccupant: Occupant | undefined;
  occupantJwt: OccupantJwt | undefined;

  leftRoamReason: string | undefined;

  ignoreSoftCheckIn: boolean;
}

const slice = createSlice({
  name: "world",
  initialState: {
    // follows patterns in docs/clientConnections.md
    wantRoamCheckIn: { roamId: undefined },
    internalWantRoamCheckIn: { roamId: undefined } as RoamCheckIn,
    internalHaveRoamCheckIn: { roamId: undefined } as RoamCheckIn,

    roamCheckInRetryTime: undefined as string | undefined,
    roamCheckInErrorDetail: undefined as string | undefined,

    showOnboarding: false,
    wantMyLocationAfterOnboarding: undefined as Location | undefined,

    activeOccupant: undefined as Occupant | undefined,
    occupantJwt: undefined as OccupantJwt | undefined,

    leftRoamReason: undefined as string | undefined,

    ignoreSoftCheckIn: false as boolean,
  },
  reducers: {
    // follows patterns in docs/clientConnections.md
    setWantRoamCheckIn: (state: State, action: PayloadAction<RoamCheckIn>) => {
      state.wantRoamCheckIn = action.payload;
    },
    setInternalRoamCheckIn: (state: State, action: PayloadAction<WantHave<RoamCheckIn>>) => {
      updateInternal(state, "RoamCheckIn", action.payload);
    },

    setRoamCheckInRetryTime: (state: State, action: PayloadAction<string | undefined>) => {
      state.roamCheckInRetryTime = action.payload;
    },
    setRoamCheckInErrorDetail: (state: State, action: PayloadAction<string | undefined>) => {
      state.roamCheckInErrorDetail = action.payload;
    },

    setShowOnboarding: (state: State, action: PayloadAction<boolean>) => {
      state.showOnboarding = action.payload;
    },
    setWantMyLocationAfterOnboarding: (
      state: State,
      action: PayloadAction<Location | undefined>
    ) => {
      state.wantMyLocationAfterOnboarding = action.payload;
    },

    setInternalActiveOccupant: (state: State, action: PayloadAction<Occupant | undefined>) => {
      state.activeOccupant = action.payload;
    },

    setOccupantJwt: (state: State, action: PayloadAction<OccupantJwt | undefined>) => {
      state.occupantJwt = action.payload;
    },

    setLeftRoamReason: (state: State, action: PayloadAction<string | undefined>) => {
      state.leftRoamReason = action.payload;
    },

    setIgnoreSoftCheckIn: (state: State, action: PayloadAction<OccupantJwt>) => {
      state.ignoreSoftCheckIn = true;
      state.occupantJwt = action.payload;
    },
  },
});
export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.world.world;
export const selectors = {
  selectWantRoamCheckIn: (state: RootState) => selectSlice(state).wantRoamCheckIn,
  selectInternalWantRoamCheckIn: (state: RootState) => selectSlice(state).internalWantRoamCheckIn,
  selectInternalHaveRoamCheckIn: (state: RootState) => selectSlice(state).internalHaveRoamCheckIn,
  selectRoamCheckInRetryTime: (state: RootState) => selectSlice(state).roamCheckInRetryTime,
  selectRoamCheckInErrorDetail: (state: RootState) => selectSlice(state).roamCheckInErrorDetail,
  selectActiveOccupantId: (state: RootState) => selectSlice(state).activeOccupant?.id,
  selectActiveOccupantEmail: (state: RootState) => selectSlice(state).activeOccupant?.email,
  selectActiveOccupantType: (state: RootState) => selectSlice(state).activeOccupant?.occupantType,
  selectActiveOccupantVisitorStatus: (state: RootState) =>
    selectSlice(state).activeOccupant?.visitorStatus,
  selectShowOnboarding: (state: RootState) => selectSlice(state).showOnboarding,
  selectWantMyLocationAfterOnboarding: (state: RootState) =>
    selectSlice(state).wantMyLocationAfterOnboarding,
  selectActiveOccupant: (state: RootState) => selectSlice(state).activeOccupant,
  selectOccupantJwt: (state: RootState) => selectSlice(state).occupantJwt,
  selectActiveRoamId: (state: RootState) => selectSlice(state).activeOccupant?.roamId,

  selectActivePersonId: (state: RootState) => selectSlice(state).activeOccupant?.personId,
  selectActivePerson: (state: RootState) => {
    const activePersonId = selectors.selectActivePersonId(state);
    return activePersonId ? state.anyWorld.myPerson.entities?.[activePersonId] : undefined;
  },

  selectActiveOccupantIsMember: (state: RootState) => {
    const activeOccupant = selectors.selectActiveOccupant(state);
    return AnyWorldSelectors.selectRelationshipToRoam(activeOccupant?.roamId)(state) === "member";
  },
  selectActiveOccupantHasMemberAccess: createSelector(selectSlice, (slice) =>
    isMemberOrHasMemberAccess(slice.activeOccupant)
  ),
  selectActiveOccupantIsMemberOrRoamgineer: createSelector(selectSlice, (slice) =>
    isMemberOrRoamgineer(slice.activeOccupant)
  ),
  selectActiveOccupantHasAdminAccess: createSelector(
    selectSlice,
    (slice) =>
      slice.activeOccupant?.occupantType === "Roamgineer" ||
      slice.activeOccupant?.userRole === "Admin"
  ),
  selectActiveOccupantHasFloorAdminAccess: createSelector(
    selectSlice,
    (slice) =>
      slice.activeOccupant?.occupantType === "Roamgineer" ||
      slice.activeOccupant?.userRole === "Admin" ||
      slice.activeOccupant?.canEditFloors
  ),

  selectLeftRoamReason: createSelector(selectSlice, (slice) => slice.leftRoamReason),
  selectIgnoreSoftCheckIn: createSelector(selectSlice, (slice) => slice.ignoreSoftCheckIn),
};
export const WorldSelectors = selectors;
export const WorldActions = actions;
