import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultMemoize } from "reselect";
import { getPodId, getSectionNumberFromPodId } from "../../../../shared/helpers/audiencePods.js";
import { AudienceSection, sectionToId } from "../../../../shared/helpers/sections.js";
import {
  ConversationPlace,
  conversationPlaceFromLocation,
} from "../../../../shared/Models/Conversation.js";
import {
  getPlaceInAuditorium,
  Location,
  locationToRoomId,
} from "../../../../shared/Models/Location.js";
import { isFocusRoomType, Room, RoomType } from "../../../../shared/Models/Room.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { updateInternal, WantHave } from "../../../store/clientConnectionHelpers.js";
import { RootState } from "../../../store/reducers.js";

export type MyLocation = {
  myLocation: Location | undefined;
};

export type VisitorAvailableInfo = {
  sectionId: string;
  roamId: number;
};

export interface MyRoomChangedPayload {
  oldLocation?: Location;
  location?: Location;
  windowKey: WindowKey;
}

export interface MeetingWindowLocation {
  myRoomId: number | undefined;
  myRoomType: RoomType | undefined;
}

interface State {
  // follows patterns in docs/clientConnections.md
  wantMyLocation: MyLocation;
  internalWantMyLocation: MyLocation;
  internalHaveMyLocation: MyLocation;
  internalRenderMyLocation: MyLocation;

  wantAutomatedAuditoriumPlacement: boolean;
  myLocationRetryTime: string | undefined;
  myLocationErrorDetail: string | undefined;
  myLocationRedirectionCount: number;

  showingAudienceSection: AudienceSection | undefined;

  // This is undefined if the user is not available, and it contains a VisitorAvailableInfo if they are
  availableForVisitors: VisitorAvailableInfo | undefined;
  // Cache of the last value of availableForVisitors so we can skip an RPC if we're already in that state
  cachedAvailableForVisitors: VisitorAvailableInfo | undefined;

  isRestoringLocation: boolean;
}

const slice = createSlice({
  name: "myLocation",
  initialState: {
    // follows patterns in docs/clientConnections.md
    wantMyLocation: { myLocation: undefined } as MyLocation,
    internalWantMyLocation: { myLocation: undefined } as MyLocation,
    internalHaveMyLocation: { myLocation: undefined } as MyLocation,
    internalRenderMyLocation: { myLocation: undefined } as MyLocation,

    wantAutomatedAuditoriumPlacement: false,
    myLocationRetryTime: undefined as string | undefined,
    myLocationErrorDetail: undefined as string | undefined,
    myLocationRedirectionCount: 0,

    showingAudienceSection: undefined as AudienceSection | undefined,

    // This is undefined if the user is not available, and it contains a VisitorAvailableInfo if they are
    availableForVisitors: undefined as VisitorAvailableInfo | undefined,
    cachedAvailableForVisitors: undefined as VisitorAvailableInfo | undefined,

    isRestoringLocation: false,
  },
  reducers: {
    // follows patterns in docs/clientConnections.md
    setWantMyLocation: (state: State, action: PayloadAction<MyLocation>) => {
      state.wantMyLocation = action.payload;
    },
    setInternalMyLocation: (state: State, action: PayloadAction<WantHave<MyLocation>>) => {
      updateInternal(state, "MyLocation", action.payload);
    },
    setInternalRenderMyLocation: (state: State, action: PayloadAction<MyLocation>) => {
      state.internalRenderMyLocation = action.payload;
    },

    setWantAutomatedAuditoriumPlacement: (state: State, action: PayloadAction<boolean>) => {
      state.wantAutomatedAuditoriumPlacement = action.payload;
    },
    setMyLocationRetryTime: (state: State, action: PayloadAction<string | undefined>) => {
      state.myLocationRetryTime = action.payload;
    },
    setMyLocationErrorDetail: (state: State, action: PayloadAction<string | undefined>) => {
      state.myLocationErrorDetail = action.payload;
    },
    incrementRedirectionCount: (state: State) => {
      state.myLocationRedirectionCount++;
    },
    resetRedirectionCount: (state: State) => {
      state.myLocationRedirectionCount = 0;
    },

    myRoomChanged: (state: State, action: PayloadAction<MyRoomChangedPayload>) => {
      // can be watched in saga if you only care about rooms changing, not position change
    },

    forceMoved: (_, action: PayloadAction<MyLocation>) => {},

    setShowingAudienceSection: (
      state: State,
      action: PayloadAction<AudienceSection | undefined>
    ) => {
      state.showingAudienceSection = action.payload;
    },

    // *** Visitors ***
    /**
     * Updates the server about the user's availability to their guests.
     *
     * When unavailable, guests may not visit this user.
     *
     * @param action Payload: Where this user can be visited if available, undefined otherwise.
     */
    setAvailableForVisitors: (
      state: State,
      action: PayloadAction<VisitorAvailableInfo | undefined>
    ) => {
      state.availableForVisitors = action.payload;
    },
    setCachedAvailableForVisitors: (
      state: State,
      action: PayloadAction<VisitorAvailableInfo | undefined>
    ) => {
      state.cachedAvailableForVisitors = action.payload;
    },

    setIsRestoringLocation: (state: State, action: PayloadAction<boolean>) => {
      state.isRestoringLocation = action.payload;
    },
  },
});
export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.section.myLocation;
const myLocationSelector = (state: RootState) =>
  selectSlice(state).internalRenderMyLocation?.myLocation;
const currentRoomSelector = createSelector(
  myLocationSelector,
  (state: RootState) => state.section.room.rooms,
  (myLocation, rooms) => {
    const currentRoomId = locationToRoomId(myLocation);
    if (!currentRoomId) return undefined;
    const room = rooms.entities[currentRoomId];
    return room;
  }
);

export const selectors = {
  // follows patterns in docs/clientConnections.md
  selectWantMyLocation: (state: RootState) => selectSlice(state).wantMyLocation,
  selectInternalWantMyLocation: (state: RootState) => selectSlice(state).internalWantMyLocation,
  selectInternalHaveMyLocation: (state: RootState) => selectSlice(state).internalHaveMyLocation,
  selectInternalRenderMyLocation: (state: RootState) => selectSlice(state).internalRenderMyLocation,
  selectWantAutomatedAuditoriumPlacement: (state: RootState) =>
    selectSlice(state).wantAutomatedAuditoriumPlacement,
  selectMyLocationRetryTime: (state: RootState) => selectSlice(state).myLocationRetryTime,
  selectMyLocationErrorDetail: (state: RootState) => selectSlice(state).myLocationErrorDetail,
  selectShowingAudienceSection: (state: RootState) => selectSlice(state).showingAudienceSection,

  selectAvailableForVisitors: (state: RootState) => selectSlice(state).availableForVisitors,
  selectCachedAvailableForVisitors: (state: RootState) =>
    selectSlice(state).cachedAvailableForVisitors,
  selectMySection: (state: RootState) =>
    selectSlice(state).internalRenderMyLocation?.myLocation?.section,
  selectMySectionId: createSelector(selectSlice, (slice) => {
    const section = slice.internalRenderMyLocation?.myLocation?.section;
    return section ? sectionToId(section) : undefined;
  }),
  selectMyLocation: myLocationSelector,
  selectMyLocationKind: (state: RootState) => myLocationSelector(state)?.kind,
  selectMyLocationSubkind: createSelector(myLocationSelector, (myLocation) => {
    if (myLocation?.kind === "RoomLocation") {
      return myLocation.subkind;
    }
  }),
  selectMyFloorId: (state: RootState) =>
    selectSlice(state).internalRenderMyLocation?.myLocation?.section.floorId,
  selectMyRoomId: createSelector(selectSlice, (slice) =>
    locationToRoomId(slice.internalRenderMyLocation?.myLocation)
  ),
  selectWorldRouterLocation: createDeepEqualSelector(
    (state: RootState) => state.section,
    (section) => {
      const roomId = locationToRoomId(section.myLocation?.internalRenderMyLocation?.myLocation);
      const roomType: RoomType | undefined = roomId
        ? section.room.rooms.entities[roomId]?.roomType
        : undefined;
      return {
        myFloorId: section.myLocation?.internalRenderMyLocation?.myLocation?.section.floorId,
        // Set it to undefined if the room isn't a video room
        myFocusRoomType: isFocusRoomType(roomType) ? roomType : undefined,
      };
    }
  ),
  selectMeetingWindowLocation: createDeepEqualSelector(
    (state: RootState) => state.section,
    (section) => {
      const roomId = locationToRoomId(section.myLocation?.internalRenderMyLocation?.myLocation);
      const roomType = roomId ? section.room.rooms.entities[roomId]?.roomType : undefined;
      const windowLoc: MeetingWindowLocation = {
        myRoomId: roomId,
        myRoomType: roomType,
      };
      return windowLoc;
    }
  ),
  selectIsMyRoom: defaultMemoize(
    (roomId?: number) =>
      createSelector(
        selectSlice,
        (slice) =>
          roomId !== undefined &&
          roomId === locationToRoomId(slice.internalRenderMyLocation?.myLocation)
      ),
    { maxSize: 500 }
  ),
  selectIsMyReception: defaultMemoize(
    (floorId: number) =>
      createSelector(
        selectSlice,
        (slice) =>
          floorId === slice.internalRenderMyLocation?.myLocation?.section.floorId &&
          slice.internalRenderMyLocation?.myLocation?.kind === "ReceptionLocation"
      ),
    { maxSize: 25 }
  ),
  selectIsMyFloor: (floorId?: number) => (state: RootState) =>
    floorId !== undefined &&
    floorId === selectSlice(state).internalRenderMyLocation?.myLocation?.section.floorId,
  selectInRoomWhereIAmAssigned: createSelector(
    (state: RootState) => state.section,
    (state: RootState) => state.world,
    (section, world) => {
      const roomId = locationToRoomId(section.myLocation?.internalRenderMyLocation?.myLocation);
      if (!roomId) return false;
      const personId = world.world.activeOccupant?.personId;
      if (!personId) return false;
      const assignedSeats = section.room.rooms.entities[roomId]?.assignedSeats;
      if (!assignedSeats) return false;
      return assignedSeats.some((s) => s.personId === personId);
    }
  ),
  // Does this person have an assigned seat anywhere in this Roam?
  selectHasSeatAssignment: createSelector(
    (state: RootState) => state.section.room.rooms,
    (state: RootState) => state.world.world.activeOccupant,
    (rooms, activeOccupant) => {
      const personId: number | undefined = activeOccupant?.personId;
      if (!personId) return false;

      // Assigned seats are only known for each room. Therefore, we need to loop over
      // each room and see if it has an assigned seat that matches the person ID in question.
      return Object.values(rooms.entities).some((room: Room | undefined) => {
        if (!room) return false;
        if (!room.assignedSeats) return false;
        return room.assignedSeats.some((assignedSeat) => assignedSeat.personId === personId);
      });
    }
  ),
  selectRoomsWithMySeatAssignment: createDeepEqualSelector(
    (state: RootState) => state.section.room.rooms,
    (state: RootState) => state.world.world.activeOccupant,
    (rooms, activeOccupant) => {
      const personId: number | undefined = activeOccupant?.personId;
      if (!personId) return [];

      // Assigned seats are only known for each room. Therefore, we need to loop over
      // each room and see if it has an assigned seat that matches the person ID in question.
      return Object.values(rooms.entities).filter((room: Room | undefined): room is Room => {
        if (!room) return false;
        if (!room.assignedSeats) return false;
        return room.assignedSeats.some((assignedSeat) => assignedSeat.personId === personId);
      });
    }
  ),
  selectAmInAuditorium: createSelector(
    (state: RootState) => state.section,
    (section) => {
      const myLocation = section.myLocation?.internalRenderMyLocation?.myLocation;
      if (myLocation?.kind === "AudienceLocation") {
        return true;
      } else if (myLocation?.kind === "ReceptionLocation") {
        return false;
      } else {
        const roomId = locationToRoomId(myLocation);
        const roomType = roomId ? section.room.rooms.entities[roomId]?.roomType : undefined;
        return roomType === "Auditorium";
      }
    }
  ),
  selectAmOnStage: createSelector(
    (state: RootState) => state.section,
    (section) => {
      const myLocation = section.myLocation?.internalRenderMyLocation?.myLocation;
      if (myLocation?.kind === "RoomLocation") {
        return myLocation.subkind === "StageLocation";
        /*
        const roomId = myLocation.roomId;
        const roomType = roomId ? section.room.rooms.entities[roomId]?.roomType : undefined;
        return roomType === "Auditorium"; */
      } else {
        return false;
      }
    }
  ),
  selectAmBackStage: createSelector(
    (state: RootState) => state.section,
    (section) => {
      const myLocation = section.myLocation?.internalRenderMyLocation?.myLocation;
      if (myLocation?.kind === "RoomLocation") {
        return myLocation.subkind === "BackstageLocation";
      } else {
        return false;
      }
    }
  ),
  selectAtFloorMic: createSelector(
    (state: RootState) => state.section,
    (section) => {
      const myLocation = section.myLocation?.internalRenderMyLocation?.myLocation;
      if (myLocation?.kind === "RoomLocation") {
        return myLocation.subkind === "FloorMicLocation";
      } else {
        return false;
      }
    }
  ),
  selectIsMyPod: defaultMemoize(
    (roomId: number | undefined, podId: number | undefined) =>
      createSelector(
        (state: RootState) => state.section,
        (section) => {
          if (roomId === undefined || podId === undefined) {
            return false;
          }

          const myLocation = section.myLocation?.internalRenderMyLocation?.myLocation;
          if (myLocation === undefined) {
            return false;
          }

          if (myLocation.kind === "AudienceLocation") {
            const sectionNumber = getSectionNumberFromPodId(podId);
            const myPodId = getPodId({
              sectionNumber: myLocation.section.sectionNumber,
              positionNumber: myLocation.positionNumber,
            });

            if (myPodId === undefined) {
              return false;
            }

            const myRoomId = myLocation.section.roomId;
            const mySectionNumber = myLocation.section.sectionNumber;

            return roomId === myRoomId && sectionNumber === mySectionNumber && podId === myPodId;
          }

          return false;
        }
      ),
    { maxSize: 20 }
  ),
  selectMyPodId: createSelector(
    (state: RootState) => state.section.myLocation?.internalRenderMyLocation?.myLocation,
    (myLocation) => {
      if (myLocation?.kind === "AudienceLocation") {
        return getPodId({
          sectionNumber: myLocation.section.sectionNumber,
          positionNumber: myLocation.positionNumber,
        });
      } else {
        return undefined;
      }
    }
  ),
  selectMyPlaceInAuditorium: createSelector(selectSlice, (slice) =>
    getPlaceInAuditorium(slice.internalRenderMyLocation?.myLocation)
  ),

  selectConversationPlace: createDeepEqualSelector(
    (state: RootState) => state.section,
    (section) =>
      conversationPlaceFromLocation(section.myLocation?.internalRenderMyLocation?.myLocation)
  ),

  // Get the ConversationPlace that has the visible board attached to it
  selectBoardPlace: createDeepEqualSelector(
    (state: RootState) => state.section.myLocation?.internalRenderMyLocation?.myLocation,
    (myLocation): ConversationPlace | undefined => {
      if (!myLocation) return undefined;
      if (
        (myLocation.kind === "RoomLocation" && myLocation.subkind === "RoomLocation") ||
        myLocation.kind === "ReceptionLocation"
      ) {
        // Not in an auditorium
        return conversationPlaceFromLocation(myLocation);
      } else {
        const roomId = locationToRoomId(myLocation);
        if (!roomId) return undefined;
        // Definitely in an auditorium
        return {
          kind: "stage",
          floorId: myLocation.section.floorId,
          roomId,
        };
      }
    }
  ),

  selectCurrentRoom: currentRoomSelector,

  selectCurrentRoomAccessMode: (state: RootState) => currentRoomSelector(state)?.accessMode,
  selectCurrentRoomType: (state: RootState) => currentRoomSelector(state)?.roomType,
  selectCurrentRoomName: (state: RootState) => currentRoomSelector(state)?.name,
  selectRedirectionCount: (state: RootState) => selectSlice(state).myLocationRedirectionCount,

  selectIsRestoringLocation: (state: RootState) => selectSlice(state).isRestoringLocation,
};
export const MyLocationSelectors = selectors;
export const MyLocationActions = actions;
