import { IClientStatsConfigurer } from "../../client/store/stats/IClientStatsConfigurer.js";
import { MetricType } from "../../shared/Models/MetricEvent.js";
import { IStats, MetricDef } from "../../shared/infra/stats/IStats.js";

interface Injected {}

export class ClientStatsLocal implements IStats, IClientStatsConfigurer {
  constructor(private container: Injected) {}
  private homeRegion: string | undefined;

  public increment(_metricDef: MetricDef, _count: number): void {}

  public gauge(_metricDef: MetricDef, _value: number): void {}
  public timing(_metricDef: MetricDef, _value: number): void {}

  public setHomeRegion(region: string): void {
    this.homeRegion = region;
  }

  private sendMetricEvent(_type: MetricType, _def: MetricDef, _value: number): void {}
}
