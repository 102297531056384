import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { z } from "zod";
import { logger } from "../../../../shared/infra/logger.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export const PlanetId = z.object({
  orbitNumber: z.number(),
  orbitalPosition: z.number(),
});
export type PlanetId = z.infer<typeof PlanetId>;

export const PlanetRoam = z.object({
  roamId: z.number(),
  planetId: PlanetId,
});
export type PlanetRoam = z.infer<typeof PlanetRoam>;

export interface PlaceRoamPayload {
  roamId: number;
  planetId?: PlanetId;
}

export interface OverworldMapGridId {
  row: number;
  col: number;
}

export const planetCounts = [1, 6, 12, 10];
export const overworldMapGridSize = 3;

// NOTE(jeff): The Overworld map used to be laid out strictly in an orbital
// fashion in a single SVG. Then in October 2022 there was a redesign, with a
// a mostly standard (x, y) grid layout, but using some clever design styles to
// give the appearance that is similar to the prior orbital layout. To avoid
// having to migrate the persisted data from the previous design to the new one,
// this creates a mapping between the old orbital coordinate system and the new
// grid based coordinate system. I tried writing an algorithm to create this
// mapping but was struggling to get it perfect (particularly if we wanted to
// expand the grid size in the future), so I just hardcoded it for now since
// the data is small. Also, the iteration order of the grid is not strictly
// easy to implement in an algorithm, as while iteration typically happens in a
// radial pattern, the first 2 spaces after the center space are directly to
// the left and then directly to the right of the center (not radial). The grid
// pattern has a center coordinate at (0, 0) and moves in a positive and
// negative direction for both the x and y axis. Note that the odd rows do not
// have a 0 column, as that helps create the radial illusion.
const orbitalToGrid = new Map<string, OverworldMapGridId>();
orbitalToGrid.set("1,1", { row: 0, col: 0 });
orbitalToGrid.set("2,1", { row: 0, col: -1 });
orbitalToGrid.set("2,2", { row: 0, col: 1 });
orbitalToGrid.set("2,3", { row: -1, col: -1 });
orbitalToGrid.set("2,4", { row: -1, col: 1 });
orbitalToGrid.set("2,5", { row: 1, col: 1 });
orbitalToGrid.set("2,6", { row: 1, col: -1 });
orbitalToGrid.set("3,1", { row: 0, col: -2 });
orbitalToGrid.set("3,2", { row: -1, col: -2 });
orbitalToGrid.set("3,3", { row: -2, col: -1 });
orbitalToGrid.set("3,4", { row: -2, col: 0 });
orbitalToGrid.set("3,5", { row: -2, col: 1 });
orbitalToGrid.set("3,6", { row: -1, col: 2 });
orbitalToGrid.set("3,7", { row: 0, col: 2 });
orbitalToGrid.set("3,8", { row: 1, col: 2 });
orbitalToGrid.set("3,9", { row: 2, col: 1 });
orbitalToGrid.set("3,10", { row: 2, col: 0 });
orbitalToGrid.set("3,11", { row: 2, col: -1 });
orbitalToGrid.set("3,12", { row: 1, col: -2 });
orbitalToGrid.set("4,1", { row: 0, col: -3 });
orbitalToGrid.set("4,2", { row: -1, col: -3 });
orbitalToGrid.set("4,3", { row: -2, col: -2 });
orbitalToGrid.set("4,4", { row: -2, col: 2 });
orbitalToGrid.set("4,5", { row: -1, col: 3 });
orbitalToGrid.set("4,6", { row: 0, col: 3 });
orbitalToGrid.set("4,7", { row: 1, col: 3 });
orbitalToGrid.set("4,8", { row: 2, col: 2 });
orbitalToGrid.set("4,9", { row: 2, col: -2 });
orbitalToGrid.set("4,10", { row: 1, col: -3 });

export const gridToOrbital = new Map<string, PlanetId>();
for (const [planetIdStr, gridId] of orbitalToGrid.entries()) {
  const arr = planetIdStr.split(",");
  if (!arr[0] || !arr[1]) throw new Error("planetIdStr formatting issue");

  const planetId: PlanetId = {
    orbitNumber: parseInt(arr[0], 10),
    orbitalPosition: parseInt(arr[1], 10),
  };
  gridToOrbital.set(`${gridId.row},${gridId.col}`, planetId);
}

export type SolarSystemMode = "view" | "edit" | "move" | "place";
export type PlanetaryToolbarItem = "none" | "accounts" | "hiddenRoams";

export const sameSlice = (a?: PlanetId, b?: PlanetId) => {
  return a && b && a.orbitNumber === b.orbitNumber && a.orbitalPosition === b.orbitalPosition;
};

const slice = createSlice({
  name: "planet",
  initialState: {
    planetRoams: new Array<PlanetRoam>(),
    solarSystemMode: "view" as SolarSystemMode,
    planetaryToolbarItem: "none" as PlanetaryToolbarItem,
    selectedPlanetId: undefined as PlanetId | undefined,
    mouseOverPlanetId: undefined as PlanetId | undefined,
    actionRoamId: undefined as number | undefined,
    hoverOverPersonId: undefined as number | undefined,
  },
  reducers: {
    setAll: (state, action: PayloadAction<PlanetRoam[]>) => {
      state.planetRoams = action.payload;
    },
    loadPlanetRoams: (_, action: PayloadAction<void>) => {},
    clearSlice: (state, action: PayloadAction<PlanetId>) => {
      const { orbitNumber: orbitNumber, orbitalPosition: orbitalPosition } = action.payload;
      const i = state.planetRoams.findIndex(
        (rr) =>
          rr.planetId.orbitNumber === orbitNumber && rr.planetId.orbitalPosition === orbitalPosition
      );
      if (i >= 0) {
        state.planetRoams.splice(i, 1);
      }
    },
    clearSelectedSlice: (_) => {},
    placeRoam: (state, action: PayloadAction<PlaceRoamPayload>) => {
      let { planetId } = action.payload;
      const { roamId } = action.payload;

      for (const pr of state.planetRoams) {
        if (pr.roamId === roamId) {
          return;
        }
      }

      const roamIdOnPlanet = (planetId: PlanetId) =>
        state.planetRoams.find(
          (planetRoam) =>
            planetRoam.planetId.orbitNumber === planetId.orbitNumber &&
            planetRoam.planetId.orbitalPosition === planetId.orbitalPosition
        );

      if (!planetId) {
        let orbitNumber = 1;
        let orbitalPosition = 1;
        while (roamIdOnPlanet({ orbitNumber, orbitalPosition })) {
          orbitalPosition += 1;
          const planetCountsOrbitNum = planetCounts[orbitNumber - 1];
          if (planetCountsOrbitNum && orbitalPosition > planetCountsOrbitNum) {
            orbitNumber += 1;
            orbitalPosition = 1;
          }
        }
        planetId = { orbitNumber, orbitalPosition };
      }

      if (roamIdOnPlanet(planetId)) {
        logger.error(`cannot place Roam in orbit ${planetId.orbitNumber} `);
      }

      const planetRoam: PlanetRoam = {
        roamId,
        planetId,
      };
      state.planetRoams.push(planetRoam);
    },
    unhideRoam: (_, action: PayloadAction<number>) => {},
    cancelAll: (state) => {
      state.planetaryToolbarItem = "none";
      state.hoverOverPersonId = undefined;
      if (state.solarSystemMode !== "view") {
        state.solarSystemMode = "edit";
        state.selectedPlanetId = undefined;
        state.actionRoamId = undefined;
        state.mouseOverPlanetId = undefined;
      }
    },
    selectSlice: (state, action: PayloadAction<PlanetId>) => {
      state.selectedPlanetId = action.payload;
      state.solarSystemMode = "edit";
    },
    toggleEditMode: (state) => {
      state.planetaryToolbarItem = "none";
      if (state.solarSystemMode === "view") {
        state.solarSystemMode = "edit";
      } else {
        state.solarSystemMode = "view";
        state.selectedPlanetId = undefined;
        state.actionRoamId = undefined;
      }
    },
    toggleAccountList: (state) => {
      if (state.planetaryToolbarItem === "accounts") {
        state.planetaryToolbarItem = "none";
      } else {
        state.planetaryToolbarItem = "accounts";
      }
    },
    toggleHiddenList: (state) => {
      if (state.planetaryToolbarItem === "hiddenRoams") {
        state.planetaryToolbarItem = "none";
      } else {
        state.planetaryToolbarItem = "hiddenRoams";
      }
    },
    endActions: (state) => {
      if (state.solarSystemMode !== "view") {
        state.solarSystemMode = "edit";
        state.actionRoamId = undefined;
      }
      state.planetaryToolbarItem = "none";
    },
    mouseOverSlice: (state, action: PayloadAction<PlanetId>) => {
      const planetId = action.payload;
      state.mouseOverPlanetId = planetId;
    },
    mouseDownSlice: (state, action: PayloadAction<PlanetId>) => {
      state.selectedPlanetId = action.payload;
    },
    mouseUp: (_) => {},
    startMoveRoam: (state, action: PayloadAction<number>) => {
      const roamId = action.payload;
      if (roamId) {
        state.solarSystemMode = "move";
        state.actionRoamId = roamId;
      }
    },
    mouseOutSlice: (state, action: PayloadAction<PlanetId>) => {
      const planetId = action.payload;
      if (sameSlice(planetId, state.mouseOverPlanetId)) {
        state.mouseOverPlanetId = undefined;
      }
    },
    deleteSelection: (_) => {},
    hoverOverPerson: (state, action: PayloadAction<number>) => {
      state.hoverOverPersonId = action.payload;
    },
    hoverOutPerson: (state, action: PayloadAction<number>) => {
      if (state.hoverOverPersonId === action.payload) {
        state.hoverOverPersonId = undefined;
      }
    },
  },
});

export const { actions, reducer } = slice;

const selectSlice = (state: RootState) => state.overworld.planet;
export const selectors = {
  selectPlanetRoams: (state: RootState) => selectSlice(state).planetRoams,
  selectSolarSystemMode: (state: RootState) => selectSlice(state).solarSystemMode,
  selectPlanetaryToolbarItem: (state: RootState) => selectSlice(state).planetaryToolbarItem,
  selectRoamIdOnPlanet: defaultMemoize(
    (planetId: PlanetId) =>
      createSelector(selectSlice, (slice) => {
        if (!planetId) return undefined;
        const planetRoam = slice.planetRoams.find(
          (planetRoam) =>
            planetRoam.planetId.orbitNumber === planetId.orbitNumber &&
            planetRoam.planetId.orbitalPosition === planetId.orbitalPosition
        );
        return planetRoam?.roamId;
      }),
    { equalityCheck: equal, maxSize: 250 }
  ),
  selectMouseOverPlanetId: (state: RootState) => selectSlice(state).mouseOverPlanetId,
  selectSelectedPlanetId: (state: RootState) => selectSlice(state).selectedPlanetId,
  selectActionRoamId: (state: RootState) => selectSlice(state).actionRoamId,
  selectRemoveActionValid: createSelector(selectSlice, (slice) => {
    const selectedPlanetId = slice.selectedPlanetId;
    if (selectedPlanetId) {
      const planetRoam = slice.planetRoams.find(
        (rr) =>
          rr.planetId.orbitNumber === selectedPlanetId.orbitNumber &&
          rr.planetId.orbitalPosition === selectedPlanetId.orbitalPosition
      );
      return !!planetRoam;
    }
  }),
  selectDestinationValid: createSelector(selectSlice, (slice) => {
    if (!slice.mouseOverPlanetId) {
      return false;
    } else {
      return (
        slice.planetRoams.findIndex(
          (planetRoam) =>
            planetRoam.planetId.orbitNumber === slice.mouseOverPlanetId?.orbitNumber &&
            planetRoam.planetId.orbitalPosition === slice.mouseOverPlanetId?.orbitalPosition
        ) < 0
      );
    }
  }),
  selectHoverOverPersonId: (state: RootState) => selectSlice(state).hoverOverPersonId,
};
