import {
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { getSectionNumberFromPodId } from "../../../../shared/helpers/audiencePods.js";
import { maxPodSize, sectionSize } from "../../../../shared/helpers/audienceSections.js";
import { AudienceSection, idToSection } from "../../../../shared/helpers/sections.js";
import { logger } from "../../../../shared/infra/logger.js";
import { Location, locationToRoomId } from "../../../../shared/Models/Location.js";
import { RecordingBotEmailAddress } from "../../../../shared/Models/Recording.js";
import {
  firstBackstageIndex,
  firstFloorMicIndex,
  lastFloorMicIndex,
  maxBackstageParticipants,
  maxStageParticipants,
  positionToRoomSubkind,
  Room,
} from "../../../../shared/Models/Room.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { updateInternal, WantHave } from "../../../store/clientConnectionHelpers.js";
import { RootState } from "../../../store/reducers.js";
import { FloorSelectors } from "../../../world/store/slices/floorSlice.js";
import { selectors as OccupantSelectors } from "../../../world/store/slices/occupantSlice.js";
import { WorldSelectors } from "../../../world/store/slices/worldSlice.js";
import { selectors as MyLocationSelectors } from "../slices/myLocationSlice.js";
import { selectors as RoomSelectors } from "../slices/roomSlice.js";

export interface TryMoveVisitor {
  occupantId: string;
  location: Location;
}

export interface RoomPositionsPayload {
  rooms: RoomPositions;
}

export interface RoomPositions {
  [roomId: number]: string[] | undefined; // OccupantIds or "" for unoccupied
}

export interface ReceptionPositionsPayload {
  floorId: number;
  positions: string[];
}

export type ReceptionPositions = Record<number, string[]>;

export interface AudiencePositions {
  floorId: number;
  roomId: number;
  sectionNumber: number;
  positions: string[];
}

export interface PodPositions {
  floorId: number;
  roomId: number;
  sectionNumber: number;
  positions: {
    [podId: number]: string[];
  };
}

export interface UpdateAudienceSeatMapPreviewPayload {
  [roomId: number]: {
    numSections: number;
    positionNumbers: number[];
  };
}

export interface SeatMapPreview {
  roomId: number;
  numSections: number;
  positionNumbers: number[];
}

export interface RemoveFromRoomPayload {
  roomId: number;
  occupantId: string;
}

export interface LeaveRoomRequest {
  receivedTime: number;
  roomRemovalToken: string;
}

const audiencePositionsAdapter = createEntityAdapter<AudiencePositions>({
  selectId: (positions) => `${positions.roomId} ${positions.sectionNumber}`,
});

const seatPreviewAdapter = createEntityAdapter<SeatMapPreview>({
  selectId: (preview) => preview.roomId,
});

const slice = createSlice({
  name: "location",
  initialState: {
    roomPositions: {} as RoomPositions,
    receptionPositions: {} as ReceptionPositions,
    denseRoomPositions: {} as RoomPositions, // empty spaces removed
    denseReceptionPositions: {} as ReceptionPositions, // empty spaces removed
    lastMoveToFocusRoomId: 0,
    audiencePositions: audiencePositionsAdapter.getInitialState(),
    previews: seatPreviewAdapter.getInitialState(),
    // If you are on stage, this is the section number you are watching within
    // your room. This is kept separate from wantWatchingSectionsForElevator
    // since myLocationSaga.updateWatchSectionsSaga will aggressively stop watching
    // sections, and I didn't want it to interfere with elevator watching.
    wantWatchingSections: [] as string[],
    // Associate a reference count with each section so multiple components can
    // watch the same section simultaneously without interfering with each other.
    // Sections are removed once the reference count decreases to zero.
    wantWatchingSectionsForElevator: {} as { [sectionId: string]: number },
    // Somewhat follows patterns in docs/clientConnections.md
    // Slightly different since wantWatchingSections and wantWatchingSectionsForElevator
    // are combined to create internalWantWatchingSections, but they have different types
    internalWantWatchingSections: [] as string[],
    internalHaveWatchingSections: [] as string[],
    requestedOccupants: {} as { [occupantId: string]: boolean },
    leaveRoomRequest: undefined as LeaveRoomRequest | undefined,
    audienceCollapsed: false,
  },
  reducers: {
    requestFloorPreview: (state, action: PayloadAction<number>) => {},
    requestAudiencePreview: (state, action: PayloadAction<AudienceSection>) => {},

    tryMoveVisitor: (state, action: PayloadAction<TryMoveVisitor>) => {},

    replaceReceptionPositions: (state, action: PayloadAction<ReceptionPositionsPayload>) => {
      const { floorId, positions } = action.payload;
      state.receptionPositions[floorId] = positions;
      state.denseReceptionPositions[floorId] = positions.filter(Boolean);
    },
    replaceRoomPositions: (state, action: PayloadAction<RoomPositionsPayload>) => {
      const { rooms } = action.payload;
      for (const roomIdStr of Object.keys(rooms)) {
        const roomId = parseInt(roomIdStr);
        state.roomPositions[roomId] = rooms[roomId];
        state.denseRoomPositions[roomId] = rooms[roomId]?.filter(Boolean) ?? [];
      }
    },
    updateAudiencePositions: (state, action: PayloadAction<AudiencePositions>) => {
      // Record/update the positions for this audience section
      audiencePositionsAdapter.upsertOne(state.audiencePositions, action.payload);
    },
    updatePodPositions: (state, action: PayloadAction<PodPositions>) => {
      const { floorId, roomId, sectionNumber, positions } = action.payload;

      // Make a copy of the positions, or use an empty array by default
      const audPositions = [
        ...(audiencePositionsDirectSelectors.selectById(
          state.audiencePositions,
          `${roomId} ${sectionNumber}`
        )?.positions || []),
      ];

      for (const positionEntry of Object.entries(positions)) {
        const [podIdStr] = positionEntry;
        let [, podPositions] = positionEntry;
        const podId = parseInt(podIdStr);
        const offset = podId * maxPodSize;

        if (audPositions.length < offset) {
          // Extend to make room
          audPositions.push(
            ...Array.from<string>({ length: offset - audPositions.length }).fill("")
          );
        }
        if (podPositions.length < maxPodSize) {
          // Extend to match the expected length
          podPositions = [
            ...podPositions,
            ...Array.from<string>({ length: maxPodSize - podPositions.length }).fill(""),
          ];
        }
        audPositions.splice(offset, maxPodSize, ...podPositions.slice(0, maxPodSize));
      }

      audiencePositionsAdapter.upsertOne(state.audiencePositions, {
        floorId,
        roomId,
        sectionNumber,
        positions: audPositions,
      });
    },
    updatePreviewFromAudiencePositions: (state, action: PayloadAction<AudiencePositions>) => {
      const { floorId, roomId, sectionNumber, positions } = action.payload;

      // Also update the seat map preview -- we have much more accurate data now!
      let positionNumbers =
        seatPreviewDirectSelectors.selectById(state.previews, roomId)?.positionNumbers || [];
      positionNumbers = positionNumbers.filter(
        (pos) => pos < sectionNumber * sectionSize || pos >= (sectionNumber + 1) * sectionSize
      );
      positions.forEach((occupantId, pos) => {
        if (!occupantId) return;
        positionNumbers.push(pos + sectionNumber * sectionSize);
      });
      seatPreviewAdapter.updateOne(state.previews, { id: roomId, changes: { positionNumbers } });
    },
    removeAllAudiencePositionsExcept: (state, action: PayloadAction<number[]>) => {
      const keepSections = action.payload;
      audiencePositionsDirectSelectors
        .selectAll(state.audiencePositions)
        .forEach((sectionPosition) => {
          if (keepSections.includes(sectionPosition.sectionNumber)) return;

          audiencePositionsAdapter.removeMany(
            state.audiencePositions,
            sectionPosition.positions.filter((occupantId) => occupantId != null)
          );
          audiencePositionsAdapter.removeOne(
            state.audiencePositions,
            `${sectionPosition.sectionNumber} ${sectionPosition.roomId}`
          );
        });
    },
    updateAudienceSeatMapPreview: (
      state,
      action: PayloadAction<UpdateAudienceSeatMapPreviewPayload>
    ) => {
      const previews = action.payload;
      for (const [roomIdStr, { numSections, positionNumbers }] of Object.entries(previews)) {
        const roomId = parseInt(roomIdStr);
        seatPreviewAdapter.upsertOne(state.previews, { roomId, numSections, positionNumbers });
      }
    },
    setLastMoveToFocusRoomId: (state, action: PayloadAction<number>) => {
      state.lastMoveToFocusRoomId = action.payload;
    },
    occupantEnteredFloor: (state, action: PayloadAction<Location>) => {},
    setWatchingSections: (state, action: PayloadAction<string[]>) => {
      state.wantWatchingSections = [...new Set(action.payload)];
    },
    startWatchingSectionsForElevator: (state, action: PayloadAction<string[]>) => {
      for (const sectionId of action.payload) {
        const count = state.wantWatchingSectionsForElevator[sectionId] ?? 0;
        state.wantWatchingSectionsForElevator[sectionId] = count + 1;
      }
    },
    stopWatchingSectionsForElevator: (state, action: PayloadAction<string[]>) => {
      for (const sectionId of action.payload) {
        const count = state.wantWatchingSectionsForElevator[sectionId] ?? 0;
        const newCount = count - 1;
        if (newCount > 0) {
          state.wantWatchingSectionsForElevator[sectionId] = newCount;
        } else {
          delete state.wantWatchingSectionsForElevator[sectionId];
        }
      }
    },
    setInternalWatchingSections: (state, action: PayloadAction<WantHave<string[]>>) => {
      updateInternal(state, "WatchingSections", action.payload);
    },
    ensureHaveWantedWatchingSections: (state, action: PayloadAction<void>) => {},
    forceUpdateWatchingSections: (state, action: PayloadAction<void>) => {},
    markOccupantsRequested: (state, action: PayloadAction<Array<string | undefined>>) => {
      for (const occupantId of action.payload) {
        if (occupantId !== undefined) state.requestedOccupants[occupantId] = true;
      }
    },
    removeFromRoom: (state, action: PayloadAction<RemoveFromRoomPayload>) => {},
    askToLeaveRoom: (state, action: PayloadAction<RemoveFromRoomPayload>) => {},
    setLeaveRoomRequest: (state, action: PayloadAction<LeaveRoomRequest | undefined>) => {
      state.leaveRoomRequest = action.payload;
    },
    stayInRoom: (state) => {},
    setAudienceCollapsed: (state, action: PayloadAction<boolean>) => {
      state.audienceCollapsed = action.payload;
    },
  },
});

export const { actions, reducer } = slice;

const audiencePositionsDirectSelectors = audiencePositionsAdapter.getSelectors();
const audiencePositionsAdapterSelectors = audiencePositionsAdapter.getSelectors(
  (state: RootState) => state.section.location.audiencePositions
);
const seatPreviewDirectSelectors = seatPreviewAdapter.getSelectors();
const seatPreviewAdapterSelectors = seatPreviewAdapter.getSelectors(
  (state: RootState) => state.section.location.previews
);

const locationsForOccupantIds = (
  myLocation: Location | undefined,
  receptionPositions: ReceptionPositions,
  roomPositions: RoomPositions,
  audiencePositions: EntityState<AudiencePositions>,
  rooms: Dictionary<Room>,
  occupantIds: string[]
) => {
  const locationsByOccupant: { [occupantId: string]: Location } = {};
  for (const [floorId, positions] of Object.entries(receptionPositions)) {
    const floorIdNum = parseInt(floorId);
    if (!floorIdNum) {
      logger.warn(`locationsForOccupantIds: invalid floorId ${floorId}`);
      continue;
    }
    if (myLocation?.section.floorId !== floorIdNum) {
      // We don't have accurate data
      continue;
    }

    let positionNumber = 0;
    for (const occupantId of positions) {
      if (occupantIds.includes(occupantId)) {
        locationsByOccupant[occupantId] = {
          kind: "ReceptionLocation",
          occupantId,
          section: {
            kind: "floor",
            floorId: floorIdNum,
          },
          positionNumber,
        };
      }
      positionNumber++;
    }
  }

  for (const roomId of Object.keys(roomPositions)) {
    const roomIdNum = parseInt(roomId);
    if (!roomIdNum) {
      logger.warn(`locationsForOccupantIds: invalid roomId ${roomId}`);
      continue;
    }

    let positionNumber = 0;
    for (const occupantId of roomPositions[roomIdNum] ?? []) {
      if (occupantIds.includes(occupantId)) {
        const room = rooms[roomId];
        if (room && room.floorId === myLocation?.section.floorId) {
          locationsByOccupant[occupantId] = {
            kind: "RoomLocation",
            subkind: positionToRoomSubkind(room.roomType, positionNumber),
            occupantId,
            section: {
              kind: "floor",
              floorId: room.floorId,
            },
            roomId: roomIdNum,
            positionNumber,
          };
        }
      }
      positionNumber++;
    }
  }

  const allAudiencePositions = audiencePositionsDirectSelectors.selectAll(audiencePositions);
  for (const audiencePosition of allAudiencePositions) {
    let positionNumber = 0;
    for (const occupantId of audiencePosition.positions) {
      if (occupantIds.includes(occupantId)) {
        if (audiencePosition.roomId === locationToRoomId(myLocation)) {
          locationsByOccupant[occupantId] = {
            kind: "AudienceLocation",
            occupantId,
            section: {
              kind: "audience",
              floorId: audiencePosition.floorId,
              roomId: audiencePosition.roomId,
              sectionNumber: audiencePosition.sectionNumber,
            },
            positionNumber,
          };
        }
      }
      positionNumber++;
    }
  }

  return locationsByOccupant;
};

export const selectSlice = (state: RootState) => state.section.location;
export const selectors = {
  selectPositionsForRoom: (roomId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      roomId ? slice.roomPositions[roomId] ?? [] : []
    ),
  selectPositionsForStage: defaultMemoize((roomId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      roomId ? slice.roomPositions[roomId]?.slice(0, maxStageParticipants) ?? [] : []
    )
  ),
  selectPositionsForBackstage: defaultMemoize((roomId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      roomId
        ? slice.roomPositions[roomId]?.slice(
            firstBackstageIndex,
            firstBackstageIndex + maxBackstageParticipants
          ) ?? []
        : []
    )
  ),
  selectFloorMicPositions: defaultMemoize((roomId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      roomId ? slice.roomPositions[roomId]?.slice(firstFloorMicIndex, lastFloorMicIndex) ?? [] : []
    )
  ),
  selectNumOccupantsInRoom: defaultMemoize((roomId?: number) =>
    createSelector(
      selectSlice,
      (slice) => (roomId ? slice.denseRoomPositions[roomId] || [] : []).length
    )
  ),
  selectOccupantIdsInRoom: defaultMemoize((roomId?: number) =>
    createDeepEqualSelector(selectSlice, (slice) =>
      roomId ? slice.denseRoomPositions[roomId] || [] : []
    )
  ),
  selectStageHasOpenSpots: defaultMemoize((roomId?: number) =>
    createSelector(
      selectSlice,
      (slice) =>
        (roomId
          ? slice.roomPositions[roomId]?.slice(0, maxStageParticipants).filter(Boolean).length ?? 0
          : 0) < maxStageParticipants
    )
  ),
  selectBackstageHasOpenSpots: defaultMemoize((roomId?: number) =>
    createSelector(
      selectSlice,
      (slice) =>
        (roomId
          ? slice.roomPositions[roomId]
              ?.slice(firstBackstageIndex, firstBackstageIndex + maxBackstageParticipants)
              .filter(Boolean).length ?? 0
          : 0) < maxBackstageParticipants
    )
  ),
  selectAnyOccupantsBackstage: defaultMemoize((roomId?: number) =>
    createSelector(selectSlice, (slice) =>
      roomId
        ? (slice.roomPositions[roomId]
            ?.slice(firstBackstageIndex, firstBackstageIndex + maxBackstageParticipants)
            .filter(Boolean).length ?? 0) > 0
        : false
    )
  ),
  selectRoomOccupantCount: defaultMemoize((roomId?: number) =>
    createSelector(selectSlice, (slice) =>
      roomId
        ? (slice.denseRoomPositions[roomId] ?? []).length +
          (seatPreviewDirectSelectors.selectById(slice.previews, roomId)?.positionNumbers?.length ??
            0)
        : 0
    )
  ),
  selectAudienceOccupantCount: (roomId?: number) => (state: RootState) =>
    roomId
      ? seatPreviewDirectSelectors.selectById(selectSlice(state).previews, roomId)?.positionNumbers
          ?.length ?? 0
      : 0,
  selectAudienceCollapsed: () => (state: RootState) => selectSlice(state).audienceCollapsed,
  selectLocationForOccupant: defaultMemoize((occupantId?: string) =>
    createDeepEqualSelector(
      (state: RootState) => state,
      (state) =>
        occupantId
          ? locationsForOccupantIds(
              MyLocationSelectors.selectMyLocation(state),
              state.section.location.receptionPositions,
              state.section.location.roomPositions,
              state.section.location.audiencePositions,
              RoomSelectors.selectEntities(state),
              [occupantId]
            )?.[occupantId]
          : undefined
    )
  ),
  selectPositionsForReception: (floorId: number) => (state: RootState) =>
    selectSlice(state).receptionPositions[floorId],
  selectAnyOccupantsInReception: defaultMemoize(
    (floorId: number) =>
      createSelector(
        selectSlice,
        (slice) => (slice.denseReceptionPositions[floorId] ?? []).length > 0
      ),
    { maxSize: 25 }
  ),
  selectLastMoveToFocusRoomId: (state: RootState) => selectSlice(state).lastMoveToFocusRoomId,
  selectWantWatchingSections: (state: RootState) => selectSlice(state).wantWatchingSections,
  selectWantWatchingSectionsForElevator: (state: RootState) =>
    selectSlice(state).wantWatchingSectionsForElevator,
  selectWantWatchingSectionsForElevatorSafe: (
    state: RootState
  ): { [sectionId: string]: number } => {
    /**
     * Watched Floors via Elevator is determined "visually".  We calculate which sections
     * to watch if the elevator is visually displaying that section.  We've seen issues where
     * clients are attempting to watch sections even though the client is not longer checked in.
     *
     * This is likely a finnicky issue with react component mount/dismount lifecycle, so we're going
     * to add a safety check to only return sections for the roam you're currently checked in to.
     */
    const sections = selectSlice(state).wantWatchingSectionsForElevator;
    const myLocation = MyLocationSelectors.selectMyLocation(state);
    if (!myLocation) {
      return {};
    }
    const roamId = WorldSelectors.selectActiveRoamId(state);
    if (!roamId) {
      return {};
    }

    const safeSections: { [sectionId: string]: number } = {};

    for (const [k, v] of Object.entries(sections)) {
      const section = idToSection(k);
      if (section?.floorId) {
        const floor = FloorSelectors.selectById(section?.floorId)(state);
        if (floor?.roamId === roamId) {
          safeSections[k] = v;
        }
      }
    }

    return safeSections;
  },
  selectInternalWantWatchingSections: (state: RootState) =>
    selectSlice(state).internalWantWatchingSections,
  selectInternalHaveWatchingSections: (state: RootState) =>
    selectSlice(state).internalHaveWatchingSections,
  selectAudiencePositions: defaultMemoize((roomId: number, section: number) =>
    createSelector(selectSlice, (slice) =>
      audiencePositionsDirectSelectors.selectById(slice.audiencePositions, `${roomId} ${section}`)
    )
  ),
  selectPodPositions: defaultMemoize(
    (roomId: number, podId: number) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        const sectionNumber = getSectionNumberFromPodId(podId);
        const audience = audiencePositionsDirectSelectors.selectById(
          slice.audiencePositions,
          `${roomId} ${sectionNumber}`
        );
        const startPos = podId * maxPodSize - sectionNumber * sectionSize;
        const podPositions = audience?.positions.slice(startPos, startPos + maxPodSize) || [];
        if (podPositions.length < maxPodSize) {
          podPositions.push(
            ...Array.from<string>({ length: maxPodSize - podPositions.length }).fill("")
          );
        }
        return podPositions;
      }),
    { maxSize: 25 }
  ),
  selectAudiencePositionsForRoom: defaultMemoize((roomId: number) =>
    createSelector(selectSlice, (slice) =>
      audiencePositionsDirectSelectors
        .selectAll(slice.audiencePositions)
        .filter((ap) => ap.roomId === roomId)
    )
  ),
  selectAllAudiencePositions: audiencePositionsAdapterSelectors.selectEntities,
  selectPreviewPositions: seatPreviewAdapterSelectors.selectById,
  selectPreviewNumSections: (roomId?: number) => (state: RootState) =>
    (roomId &&
      seatPreviewDirectSelectors.selectById(selectSlice(state).previews, roomId)?.numSections) ||
    1,
  selectPreviewNumSectionsForRooms: defaultMemoize(
    (roomIds: number[]) =>
      createDeepEqualSelector(selectSlice, (slice) => {
        const map = {} as { [roomId in number]: number };
        for (const roomId of roomIds) {
          map[roomId] =
            seatPreviewDirectSelectors.selectById(slice.previews, roomId)?.numSections ?? 1;
        }
        return map;
      }),
    { equalityCheck: equal }
  ),
  selectPreviewSectionPositions: defaultMemoize(
    (roomId: number, section: number) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        seatPreviewDirectSelectors
          .selectById(slice.previews, roomId)
          ?.positionNumbers.filter(
            (pos) => pos >= section * sectionSize && pos < (section + 1) * sectionSize
          )
          .map((pos) => pos - section * sectionSize)
      ),
    { maxSize: 15 }
  ),
  selectAmInRoomPositions: defaultMemoize((roomId?: number) =>
    createSelector(
      (state: RootState) => state,
      (state) => {
        if (!roomId) return false;
        const me = state.world.world.activeOccupant;
        const occupantIds = state.section.location.roomPositions[roomId];
        return Boolean(occupantIds && me?.id && occupantIds.includes(me.id));
      }
    )
  ),
  selectRequestedOccupants: (state: RootState) => selectSlice(state).requestedOccupants,
  // In a meeting room, the recording bot will be an ordinary participant. In an auditorium, the
  // recording bot will be an "invisible observer". Either way, it *will* be somewhere in
  // roomPositions.
  selectIsARecordingBotHere: defaultMemoize((roomId?: number) =>
    createSelector(
      (state: RootState) => (roomId ? state.section.location.roomPositions[roomId] : undefined),
      OccupantSelectors.selectEntities,
      (occupantIds, occupants) => {
        return occupantIds?.some((occupantId) => {
          if (!occupantId) return false;
          const occupant = occupants[occupantId];
          return occupant && occupant.email === RecordingBotEmailAddress;
        });
      }
    )
  ),
  selectLeaveRoomRequest: (state: RootState) => selectSlice(state).leaveRoomRequest,
};

export const LocationSelectors = selectors;
export const LocationActions = actions;
