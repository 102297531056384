import { z } from "zod";
import { UserRole } from "./UserRole.js";
import { numberId } from "./zodTypes.js";

export const PersonStatus = z.enum(["Active", "Archived"]);
export type PersonStatus = z.infer<typeof PersonStatus>;

export const BasePerson = z.object({
  /** The account this person is under. */
  accountId: numberId(),
  /**
   * The Wonder user ID.
   *
   * This will be deprecated soon, once the accounts migration is complete.
   *
   * NOTE:
   *  It says optional but it's currently still required on the DB end.
   *  Db class will autofill this to 0.
   */
  userId: numberId().optional(),
  userRole: UserRole,
  personStatus: PersonStatus,
  name: z.string().optional(),
  email: z
    .string()
    .email()
    .transform((e) => e.toLowerCase()),
  homeRoamId: numberId().optional(),
  homeFloorId: numberId().optional(),
  /**
   * Whether or not this person should have the ability to hop around roams as a roam expert.
   *
   * Intended for Roam HQ employees, akin to Apple store geniuses for Apple.
   *
   * NOTE:
   *  Not actually optional, but this was added later and not all existing code may have been
   *  updated.
   * NOTE: Should default/be treated as false if undefined.
   */
  isRoamgineer: z.boolean().optional(),
  imageName: z.string().optional(), // Used for updating
  audioEntranceName: z.string().optional(), // Used for  updating
  audioExitName: z.string().optional(), // Used for  updating
  canGrantAccessBadge: z.boolean().optional(),
  canEditFloors: z.boolean().optional(),
  /**
   * Whether or not this person is designated as a VIP. Being a VIP will give
   * this person special privileges, e.g., the ability to invite new companies.
   *
   * This field is not optional in the database (defaults to false), but is
   * allowed to be excluded here for backwards compatibility.
   */
  isVip: z.boolean().optional(),
  /**
   * Timestamp of the first time this person checked into a roam under the same account.
   */
  firstCheckIn: z.number().optional(),
  /**
   * Version of the data. Should be incremented in the repository for each update.
   */
  version: z.number().positive().optional(),
});
export type BasePerson = z.infer<typeof BasePerson>;

export const NewPerson = BasePerson.extend({
  homeRoamId: numberId(), // required for any new people
});
export type NewPerson = z.infer<typeof NewPerson>;

export const Person = BasePerson.extend({
  id: numberId(),
  imageName: z.string().optional(), // Used for updating
  audioEntranceName: z.string().optional(), // Used for  updating
  audioExitName: z.string().optional(), // Used for  updating

  // Derived
  // When adding fields, also update People.addDerivedData
  imageAbsoluteUrl: z.string().url().optional(),
  audioEntranceAbsoluteUrl: z.string().url().optional(),
  audioExitAbsoluteUrl: z.string().url().optional(),
});
export type Person = z.infer<typeof Person>;

/**
 * Because there can be multiple people associated with an email address, this model
 * is intended for picking one Person to get account metadata (see crpc_people_RequestMyPeopleByEmails).
 *
 * The current scheme for picking a Person is:
 * If only 1 Person is associated with an email address, use it.
 * If there is more than 1 Person associated with an email address:
 *     If the email is under a verified domain, use the Person under the verified domain's account
 *     Otherwise, pick an arbitrary Person
 */
export const EmailPerson = z.object({
  email: z
    .string()
    .email()
    .transform((e) => e.toLowerCase()),
  name: z.string().optional(), // from the chosen Person
  imageAbsoluteUrl: z.string().url().optional(), // from the chosen Person
  people: z.array(Person), // all of the Persons associated with the email
});
export type EmailPerson = z.infer<typeof EmailPerson>;
