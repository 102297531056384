import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { defaultMemoize } from "reselect";
import { z } from "zod";
import {
  ConversationAVStatus,
  conversationKey,
  ConversationPlace,
} from "../../../../shared/Models/Conversation.js";
import { VirtualShelfRenderInfo } from "../../../../shared/Models/VirtualShelf.js";
import { srv_conv_ParticipantList } from "../../../../shared/SectionMessages/ServerConversation.js";
import { DefaultBackgroundBlurLevel } from "../../../avStream/avStreamShared.js";
import { PlainPipelineError } from "../../../avStream/video/interfaces/VideoPipelineError.js";
import { createDeepEqualSelector, createSelector } from "../../../helpers/redux.js";
import { WindowKey } from "../../../injection/windows/WindowKey.js";
import { ProbeResult } from "../../../rtc/sfu/config.js";
import { updateInternal, WantHave } from "../../../store/clientConnectionHelpers.js";
import { RootState } from "../../../store/reducers.js";
import { selectors as RecordingSelectors } from "../../../world/store/slices/recordingSlice.js";
import { selectors as WorldSelectors } from "../../../world/store/slices/worldSlice.js";
import { selectors as ConversationParticipantSelectors } from "./conversationParticipantSlice.js";
import { selectors as LocationSelectors } from "./locationSlice.js";
import { selectors as MyLocationSelectors } from "./myLocationSlice.js";

export const VideoPriorityScreenshare = 2;
export const VideoPriorityTalkers = 1;
export const VideoPriorityListeners = 0;

export interface AVSettings {
  place: ConversationPlace;
  avStatus: ConversationAVStatus;
}
export interface ConvPlace {
  occupantId: string | undefined;
  place: ConversationPlace | undefined;
}

export interface ConversationInit {
  place: ConversationPlace;
  participantCount: number;
}

export interface ConversationReplicaInit {
  place: ConversationPlace;
}
export interface WonderMediaDeviceInfo {
  deviceId: string;
  groupId: string;
  kind: "videoinput" | "audioinput" | "audiooutput";
  label: string;
  deprioritized?: boolean;
}

export interface RawAVStreamError {
  type: "permission" | "system" | "notfound" | "constraints" | "other";
  message: string;
  constraint?: string;
}

export interface PostProcAVStreamError {
  type: "contextlost" | "other";
  message: string;
}

export interface PostProcVideoRenderError {
  // TODO: Finer-grained errors?
  type: "other";
  message: string;
}

export type StreamType = "camera" | "screenshare";

export interface VideoPriorityGroup {
  group: string; // arbitrary string, but each dispatch fully replaces the group
  priority: number;
  streamType: StreamType;
  occupantIds: string[];
}

export interface VideoPriorityPayload extends VideoPriorityGroup {
  place: ConversationPlace;
}

export interface ClearVideoPriorityPayload {
  place: ConversationPlace;
  group: string;
}

export type VideoPriority = {
  occupantId: string;
  streamType: StreamType;
  priority: number;
};

export type AudioInputLevel = 0 | 1 | 2 | 3 | 4 | 5;

export interface RenderedAudioPlayerInfo {
  ticking: boolean;
}

export interface RenderedAudioPlayerPayload {
  windowKey: WindowKey;
  mediaStreamId: string;
  info?: RenderedAudioPlayerInfo;
}

export const VirtBgMode = z.enum(["blurred", "static_image", "virtualshelf"]);
export type VirtBgMode = z.infer<typeof VirtBgMode>;

const slice = createSlice({
  name: "conversation",
  initialState: {
    // Want and Have Place are about our state as it relates
    // to the section server's understanding of where we are
    wantPlace: { place: undefined } as ConvPlace,
    internalWantPlace: { place: undefined } as ConvPlace,
    internalHavePlace: { place: undefined } as ConvPlace,
    placeRetryTime: undefined as string | undefined,
    receptionTooCrowded: false as boolean,

    internalWantClientDirectPlace: { place: undefined } as ConvPlace,
    internalHaveClientDirectPlace: { place: undefined } as ConvPlace,

    audioMutedNormal: true as boolean,
    audioMutedPtt: true as boolean,
    micRequested: false as boolean,
    speakerRequested: false as boolean,
    speakerOff: true as boolean,
    videoMuted: false as boolean,
    showMyVideo: "show" as "show" | "hide",
    mirrorMyVideo: true as boolean,
    talking: false as boolean,
    leaving: false as boolean,
    virtualShelfRenderInfo: undefined as VirtualShelfRenderInfo | undefined,
    lastSentAVSettings: undefined as AVSettings | undefined,
    mediaDevices: [] as WonderMediaDeviceInfo[],
    audioTrackId: undefined as string | undefined,
    videoTrackId: undefined as string | undefined,
    audioStreamDeviceId: undefined as string | undefined,
    videoStreamDeviceId: undefined as string | undefined,
    audioInputLevel: 0 as AudioInputLevel,
    authTokens: {} as { [conversationKey: string]: string },
    replicaAuthTokens: {} as { [conversationKey: string]: string },
    meetingGuids: {} as { [conversationKey: string]: string },
    hybridGroupId: undefined as string | undefined,
    virtbgMode: undefined as VirtBgMode | undefined,
    virtbgBlurLevel: DefaultBackgroundBlurLevel as number,
    virtbgShelfId: undefined as string | undefined,
    virtbgCustomImageUrl: undefined as string | undefined,
    touchupLevel: 0 as number,
    noiseSuppressionMode: true as boolean,
    expertAudioMode: false as boolean,
    rawAudioError: undefined as RawAVStreamError | undefined,
    rawVideoError: undefined as RawAVStreamError | undefined,
    postProcAudioError: undefined as PostProcAVStreamError | undefined,
    postProcVideoError: undefined as PlainPipelineError | undefined,
    currentAudioTrackConstraints: undefined as MediaTrackConstraints | undefined,
    currentAudioTrackSettings: undefined as MediaTrackSettings | undefined,
    hasCapturedAudioOnce: false as boolean,
    hasCapturedVideoOnce: false as boolean,

    // Same pattern as Place and ClientPlace
    wantReplicaPlace: { place: undefined } as ConvPlace,
    internalWantReplicaPlace: { place: undefined } as ConvPlace,
    internalHaveReplicaPlace: { place: undefined } as ConvPlace,
    replicaPlaceRetryTime: undefined as string | undefined,

    internalWantClientReplicaPlace: { place: undefined } as ConvPlace,
    internalHaveClientReplicaPlace: { place: undefined } as ConvPlace,

    sfuConnectionState: {} as {
      [conversationKey: string]: {
        connected: boolean;
        everConnected: boolean;
        failedAttempts: number;
        lastChangeTime: number;
      };
    },
    lastShowedSfuConnectionErrorModalTime: undefined as number | undefined,

    preferredSFURegions: undefined as ProbeResult[] | undefined,

    // conversationKey -> group -> priority group
    videoPriorities: {} as Record<string, Record<string, VideoPriorityGroup>>,

    // windowKey -> mediaStreamId -> information
    renderedAudioPlayerInfo: {} as Record<WindowKey, Record<string, RenderedAudioPlayerInfo>>,
  },
  reducers: {
    // follows patterns in docs/clientConnections.md
    setWantPlace: (state, action: PayloadAction<ConvPlace>) => {
      state.wantPlace = action.payload;
    },
    setInternalPlace: (state, action: PayloadAction<WantHave<ConvPlace>>) => {
      updateInternal(state, "Place", action.payload);
    },
    setPlaceRetryTime: (state, action: PayloadAction<string | undefined>) => {
      state.placeRetryTime = action.payload;
    },
    setReceptionTooCrowded: (state, action: PayloadAction<boolean>) => {
      state.receptionTooCrowded = action.payload;
    },

    setInternalClientDirectPlace: (state, action: PayloadAction<WantHave<ConvPlace>>) => {
      updateInternal(state, "ClientDirectPlace", action.payload);
    },

    setWantReplicaPlace: (state, action: PayloadAction<ConvPlace>) => {
      state.wantReplicaPlace = action.payload;
    },
    setInternalReplicaPlace: (state, action: PayloadAction<WantHave<ConvPlace>>) => {
      updateInternal(state, "ReplicaPlace", action.payload);
    },
    setReplicaPlaceRetryTime: (state, action: PayloadAction<string | undefined>) => {
      state.replicaPlaceRetryTime = action.payload;
    },

    setInternalClientReplicaPlace: (state, action: PayloadAction<WantHave<ConvPlace>>) => {
      updateInternal(state, "ClientReplicaPlace", action.payload);
    },

    setAudioMutedNormal: (state, action: PayloadAction<boolean>) => {
      state.audioMutedNormal = action.payload;
    },

    setSpeakerOff: (state, action: PayloadAction<boolean>) => {
      state.speakerOff = action.payload;
    },

    pushToTalkChanged: () => {},

    setAudioMutedPtt: (state, action: PayloadAction<boolean>) => {
      state.audioMutedPtt = action.payload;
    },

    setVideoMuted: (state, action: PayloadAction<boolean>) => {
      state.videoMuted = action.payload;
    },

    loadVideoMuted: () => {},

    setShowMyVideo: (state, action: PayloadAction<"show" | "hide">) => {
      state.showMyVideo = action.payload;
    },

    loadShowMyVideo: () => {},

    setMirrorMyVideo: (state, action: PayloadAction<boolean>) => {
      state.mirrorMyVideo = action.payload;
    },

    loadMirrorMyVideo: () => {},

    setMicRequested: (state, action: PayloadAction<boolean>) => {
      state.micRequested = action.payload;
    },

    setSpeakerRequested: (state, action: PayloadAction<boolean>) => {
      state.speakerRequested = action.payload;
    },

    setHybridGroupId: (state, action: PayloadAction<string>) => {
      state.hybridGroupId = action.payload;
    },
    clearHybridGroup: (state) => {
      state.hybridGroupId = undefined;
    },

    setVideoVirtbgMode: (
      state,
      action: PayloadAction<"blurred" | "static_image" | "virtualshelf" | undefined>
    ) => {
      state.virtbgMode = action.payload;
    },
    setVideoVirtbgBlurLevel: (state, action: PayloadAction<number>) => {
      state.virtbgBlurLevel = action.payload;
    },
    setVideoVirtbgShelfId: (state, action: PayloadAction<string | undefined>) => {
      state.virtbgShelfId = action.payload;
    },
    setVideoVirtbgCustomImageUrl: (state, action: PayloadAction<string | undefined>) => {
      state.virtbgCustomImageUrl = action.payload;
    },
    loadVideoVirtbg: () => {},

    setTouchupLevel: (state, action: PayloadAction<number>) => {
      state.touchupLevel = action.payload;
    },
    loadTouchupLevel: () => {},

    setNoiseSuppressionMode: (state, action: PayloadAction<boolean>) => {
      state.noiseSuppressionMode = action.payload;
    },

    setExpertAudioMode: (state, action: PayloadAction<boolean>) => {
      state.expertAudioMode = action.payload;
    },

    setCurrentAudioTrackConstraints: (
      state,
      action: PayloadAction<MediaTrackConstraints | undefined>
    ) => {
      state.currentAudioTrackConstraints = action.payload;
    },

    setCurrentAudioTrackSettings: (
      state,
      action: PayloadAction<MediaTrackSettings | undefined>
    ) => {
      state.currentAudioTrackSettings = action.payload;
    },

    loadAudioProc: () => {},

    setRawAudioError: (state, action: PayloadAction<RawAVStreamError | undefined>) => {
      state.rawAudioError = action.payload;
    },
    setRawVideoError: (state, action: PayloadAction<RawAVStreamError | undefined>) => {
      state.rawVideoError = action.payload;
    },
    setPostProcAudioError: (state, action: PayloadAction<PostProcAVStreamError | undefined>) => {
      state.postProcAudioError = action.payload;
    },
    setPostProcVideoError: (state, action: PayloadAction<PlainPipelineError | undefined>) => {
      state.postProcVideoError = action.payload;
    },

    setHasCapturedAudioOnce: (state) => {
      state.hasCapturedAudioOnce = true;
    },
    setHasCapturedVideoOnce: (state) => {
      state.hasCapturedVideoOnce = true;
    },

    updateSfuConnectionState: (
      state,
      action: PayloadAction<{
        key: string;
        type: "replica" | "direct";
        now: number;
        connected: boolean;
      }>
    ) => {
      const { key, type, now, connected } = action.payload;
      const existingState = state.sfuConnectionState[`${key}-${type}`];
      if (existingState) {
        if (connected !== existingState.connected) {
          existingState.connected = connected;
          existingState.lastChangeTime = now;
        }
        if (connected) {
          existingState.failedAttempts = 0;
          existingState.everConnected = true;
        } else {
          existingState.failedAttempts++;
        }
      } else {
        state.sfuConnectionState[`${key}-${type}`] = {
          connected,
          everConnected: connected,
          failedAttempts: 0,
          lastChangeTime: now,
        };
      }
    },
    clearSfuConnectionState: (
      state,
      action: PayloadAction<{ key: string; type: "replica" | "direct" }>
    ) => {
      const { key, type } = action.payload;
      delete state.sfuConnectionState[`${key}-${type}`];
    },
    showedSfuConnectionStateModal: (state, action: PayloadAction<{ now: number }>) => {
      const { now } = action.payload;
      state.lastShowedSfuConnectionErrorModalTime = now;
    },

    startedTalking: (state) => {
      state.talking = true;
    },
    stoppedTalking: (state) => {
      state.talking = false;
    },
    // true if the occupant is leaving the conversation (used for video fade out)
    setLeaving: (state, action: PayloadAction<boolean>) => {
      state.leaving = action.payload;
    },
    setVirtualShelfRenderInfo: (
      state,
      action: PayloadAction<VirtualShelfRenderInfo | undefined>
    ) => {
      state.virtualShelfRenderInfo = action.payload;
    },
    setLastSentAVSettings: (state, action: PayloadAction<AVSettings | undefined>) => {
      state.lastSentAVSettings = action.payload;
    },
    setMediaDevices: (state, action: PayloadAction<WonderMediaDeviceInfo[]>) => {
      state.mediaDevices = action.payload;
    },
    setAudioTrackId: (state, action: PayloadAction<[string | undefined, ConversationPlace]>) => {
      const [audioTrackId, place] = action.payload;
      if (
        place &&
        state.wantPlace.place &&
        conversationKey(place) === conversationKey(state.wantPlace.place)
      ) {
        state.audioTrackId = audioTrackId;
      }
    },
    clearAudioTrackIdIfCurrently: (state, action: PayloadAction<string>) => {
      if (state.audioTrackId === action.payload) {
        state.audioTrackId = undefined;
      }
    },
    setVideoTrackId: (state, action: PayloadAction<[string | undefined, ConversationPlace]>) => {
      const [videoTrackId, place] = action.payload;
      if (
        place &&
        state.wantPlace.place &&
        conversationKey(place) === conversationKey(state.wantPlace.place)
      ) {
        state.videoTrackId = videoTrackId;
      }
    },
    clearVideoTrackIdIfCurrently: (state, action: PayloadAction<string>) => {
      if (state.videoTrackId === action.payload) {
        state.videoTrackId = undefined;
      }
    },
    setAudioStreamDeviceId: (state, action: PayloadAction<string | undefined>) => {
      state.audioStreamDeviceId = action.payload;
    },
    setVideoStreamDeviceId: (state, action: PayloadAction<string | undefined>) => {
      state.videoStreamDeviceId = action.payload;
    },
    setAudioInputLevel: (state, action: PayloadAction<AudioInputLevel>) => {
      state.audioInputLevel = action.payload;
    },

    setAuthToken: (state, action: PayloadAction<{ key: string; token: string }>) => {
      state.authTokens[action.payload.key] = action.payload.token;
    },
    setReplicaAuthToken: (state, action: PayloadAction<{ key: string; token: string }>) => {
      state.replicaAuthTokens[action.payload.key] = action.payload.token;
    },

    setMeetingGuid: (state, action: PayloadAction<{ key: string; meetingGuid: string }>) => {
      state.meetingGuids[action.payload.key] = action.payload.meetingGuid;
    },

    refreshConversation: (_, action: PayloadAction<{ place: ConversationPlace }>) => {},

    refreshPeople: (_, action: PayloadAction<srv_conv_ParticipantList>) => {},

    updateHybrid: (_, action: PayloadAction<srv_conv_ParticipantList>) => {},

    requestParticipants: (state, action: PayloadAction<ConversationPlace>) => {},

    updateAVSettings: () => {},

    endAllConversations: () => {},

    maybeUpdateStreams: () => {},

    triggerDebugSFUGracefulReconnect: () => {},

    setPreferredSFURegions: (state, action: PayloadAction<ProbeResult[] | undefined>) => {
      state.preferredSFURegions = action.payload;
    },

    setVideoPriorities: (state, action: PayloadAction<VideoPriorityPayload>) => {
      const { place, group, priority, occupantIds, streamType } = action.payload;
      const key = conversationKey(place);
      let convPriorities = state.videoPriorities[key];
      if (!convPriorities) {
        convPriorities = {};
        state.videoPriorities[key] = convPriorities;
      }
      convPriorities[group] = { group, priority, occupantIds, streamType };
    },
    clearVideoPriorities: (state, action: PayloadAction<ClearVideoPriorityPayload>) => {
      const { place, group } = action.payload;
      const key = conversationKey(place);
      const convPriorities = state.videoPriorities[key];
      if (convPriorities) {
        delete convPriorities[group];
      }
    },

    setRenderedAudioPlayerInfo: (state, action: PayloadAction<RenderedAudioPlayerPayload>) => {
      const { windowKey, mediaStreamId, info } = action.payload;
      let infoByStream = state.renderedAudioPlayerInfo[windowKey];
      if (!infoByStream) {
        infoByStream = {};
        state.renderedAudioPlayerInfo[windowKey] = infoByStream;
      }
      if (info) {
        infoByStream[mediaStreamId] = info;
      } else {
        delete infoByStream[mediaStreamId];
      }
    },
  },
});

export const { actions, reducer } = slice;
export const selectSlice = (state: RootState) => state.section.conversation;
export const selectors = {
  selectWantPlace: (state: RootState) => selectSlice(state).wantPlace,
  selectInternalWantPlace: (state: RootState) => selectSlice(state).internalWantPlace,
  selectInternalHavePlace: (state: RootState) => selectSlice(state).internalHavePlace,

  selectPlaceRetryTime: (state: RootState) => selectSlice(state).placeRetryTime,
  selectReceptionTooCrowded: (state: RootState) => selectSlice(state).receptionTooCrowded,

  selectInternalWantClientDirectPlace: (state: RootState) =>
    selectSlice(state).internalWantClientDirectPlace,
  selectInternalHaveClientDirectPlace: (state: RootState) =>
    selectSlice(state).internalHaveClientDirectPlace,
  selectWantReplicaPlace: (state: RootState) => selectSlice(state).wantReplicaPlace,
  selectInternalWantReplicaPlace: (state: RootState) => selectSlice(state).internalWantReplicaPlace,
  selectInternalHaveReplicaPlace: (state: RootState) => selectSlice(state).internalHaveReplicaPlace,
  selectInternalWantClientReplicaPlace: (state: RootState) =>
    selectSlice(state).internalWantClientReplicaPlace,
  selectInternalHaveClientReplicaPlace: (state: RootState) =>
    selectSlice(state).internalHaveClientReplicaPlace,
  selectReplicaPlaceRetryTime: (state: RootState) => selectSlice(state).replicaPlaceRetryTime,

  selectPushToTalk: createSelector(
    (state: RootState) => state,
    (state) => {
      const myLocation = MyLocationSelectors.selectInternalRenderMyLocation(state).myLocation;
      switch (myLocation?.kind) {
        case "AudienceLocation": {
          const occupants = LocationSelectors.selectPositionsForStage(myLocation.section.roomId)(
            state
          );
          return (occupants?.filter((occupantId) => !!occupantId)?.length ?? 0) > 0;
        }
        case "ReceptionLocation":
          return true;
        default:
          return false;
      }
    }
  ),
  selectAudioMutedNormal: (state: RootState) => selectSlice(state).audioMutedNormal,
  selectMicRequested: (state: RootState) => selectSlice(state).micRequested,
  selectSpeakerRequested: (state: RootState) => selectSlice(state).speakerRequested,
  selectAudioMutedPtt: (state: RootState) => selectSlice(state).audioMutedPtt,
  selectSpeakerOff: (state: RootState) => selectSlice(state).speakerOff,
  selectAudioMuted: (state: RootState) => {
    return selectors.selectPushToTalk(state)
      ? selectors.selectAudioMutedPtt(state)
      : selectors.selectAudioMutedNormal(state);
  },
  selectVideoMuted: (state: RootState) => selectSlice(state).videoMuted,
  selectRawAudioError: (state: RootState) => selectSlice(state).rawAudioError,
  selectRawVideoError: (state: RootState) => selectSlice(state).rawVideoError,
  selectPostProcAudioError: (state: RootState) => selectSlice(state).postProcAudioError,
  selectPostProcVideoError: (state: RootState) => selectSlice(state).postProcVideoError,
  selectHasCapturedAudioOnce: (state: RootState) => selectSlice(state).hasCapturedAudioOnce,
  selectHasCapturedVideoOnce: (state: RootState) => selectSlice(state).hasCapturedVideoOnce,
  selectCurrentAudioTrackConstraints: (state: RootState) =>
    selectSlice(state).currentAudioTrackConstraints,
  selectCurrentAudioTrackSettings: (state: RootState) =>
    selectSlice(state).currentAudioTrackSettings,
  selectTalking: (state: RootState) => selectSlice(state).talking,
  selectLeaving: (state: RootState) => selectSlice(state).leaving,
  selectVirtualShelfRenderInfo: (state: RootState) => selectSlice(state).virtualShelfRenderInfo,
  selectLastSentAVSettings: (state: RootState) => selectSlice(state).lastSentAVSettings,
  selectPlace: (state: RootState) => selectSlice(state).internalHavePlace.place,
  selectReplicaPlace: (state: RootState) => selectSlice(state).internalHaveReplicaPlace.place,
  selectSinkId: (myPlace: boolean) => (state: RootState) =>
    myPlace ? state.app.mediaDevices.audioOutput : undefined,
  selectMediaDevices: (state: RootState) => selectSlice(state).mediaDevices,
  selectMediaDevicesOfKind: defaultMemoize(
    (kind: MediaDeviceKind) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        slice.mediaDevices.filter((d) => d.kind === kind)
      ),
    { maxSize: 5 }
  ),
  selectAudioTrackId: (state: RootState) => selectSlice(state).audioTrackId,
  selectVideoTrackId: (state: RootState) => selectSlice(state).videoTrackId,
  selectAudioStreamDeviceId: (state: RootState) => selectSlice(state).audioStreamDeviceId,
  selectVideoStreamDeviceId: (state: RootState) => selectSlice(state).videoStreamDeviceId,
  selectAudioInputLevel: (state: RootState) => selectSlice(state).audioInputLevel,
  selectVideoVirtbgMode: (state: RootState) => selectSlice(state).virtbgMode,
  selectVideoVirtbgBlurLevel: (state: RootState) => selectSlice(state).virtbgBlurLevel,
  selectVideoVirtbgShelfId: (state: RootState) => selectSlice(state).virtbgShelfId,
  selectVideoVirtbgCustomImageUrl: (state: RootState) => selectSlice(state).virtbgCustomImageUrl,
  selectTouchupLevel: (state: RootState) => selectSlice(state).touchupLevel,
  selectNoiseSuppressionMode: (state: RootState) => selectSlice(state).noiseSuppressionMode,
  selectExpertAudioMode: (state: RootState) => selectSlice(state).expertAudioMode,
  selectShowMyVideo: (state: RootState) => selectSlice(state).showMyVideo,
  selectMirrorMyVideo: (state: RootState) => selectSlice(state).mirrorMyVideo,
  selectAuthToken: (conversationKey?: string) => (state: RootState) =>
    conversationKey ? selectSlice(state).authTokens[conversationKey] : undefined,
  selectReplicaAuthToken: (conversationKey?: string) => (state: RootState) =>
    conversationKey ? selectSlice(state).replicaAuthTokens[conversationKey] : undefined,
  selectMeetingGuid: (conversationKey?: string) => (state: RootState) =>
    conversationKey ? selectSlice(state).meetingGuids[conversationKey] : undefined,
  selectSfuConnectionState: defaultMemoize(
    (conversationKey?: string, type?: "replica" | "direct") =>
      createSelector(selectSlice, (slice) =>
        conversationKey && type ? slice.sfuConnectionState[`${conversationKey}-${type}`] : undefined
      ),
    { equalityCheck: equal }
  ),
  selectSfuConnectionStates: defaultMemoize(
    (places: Array<[string, "replica" | "direct"]>) =>
      createDeepEqualSelector(selectSlice, (slice) =>
        places.map(([conversationKey, type]) =>
          conversationKey && type
            ? slice.sfuConnectionState[`${conversationKey}-${type}`]
            : undefined
        )
      ),
    { equalityCheck: equal }
  ),
  selectLastShowedSfuConnectionErrorModalTime: (state: RootState) =>
    selectSlice(state).lastShowedSfuConnectionErrorModalTime,
  selectHybridGroupId: (state: RootState) => selectSlice(state).hybridGroupId,
  selectShouldCaptureAudio: createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      // If your conversation place is a room and you are by yourself don't capture audio unless
      // there is a transcription in progress.
      const place = state.section.conversation.internalHavePlace;

      // check floor mic. only capture the audio if "me" is the first occupant in the floor mic pod
      if (place.place?.kind === "floor_mic") {
        const roomId = place.place.roomId;
        const floorMicPosition = LocationSelectors.selectFloorMicPositions(roomId)(state).find(
          (x) => x !== ""
        );
        const me = WorldSelectors.selectActiveOccupant(state);
        return me?.id === floorMicPosition;
      }

      if (!place?.place) {
        return false;
      }
      if (
        place.place.kind === "stage" ||
        place.place.kind === "backstage" ||
        place.place.kind === "pod"
      ) {
        return true;
      }
      if (ConversationParticipantSelectors.selectCountForPlace(place.place)(state) > 1) {
        return true;
      }
      // Even if we are alone in a room, we want to capture audio if we are explicitly recording a
      // transcription. When this is the case, it will be apparent in the UI for the user to see.
      if (
        place.place.kind === "room" &&
        RecordingSelectors.selectHasOngoingTranscription(place.place.roomId)(state)
      ) {
        return true;
      }
      return false;
    }
  ),
  selectPreferredSFURegions: (state: RootState) => selectSlice(state).preferredSFURegions,
  selectVideoPriorities: (conversationKey: string) =>
    createDeepEqualSelector(selectSlice, (slice) => {
      const convPriorities = slice.videoPriorities[conversationKey];
      if (!convPriorities) return undefined;
      const cameraPriorities: Record<string, number> = {};
      const screensharePriorities: Record<string, number> = {};
      for (const priorityGroup of Object.values(convPriorities)) {
        // The same occupantId shouldn't be represented in multiple groups, but
        // in case it is, the last one we happen to get to wins
        const { occupantIds, streamType, priority, group } = priorityGroup;
        if (streamType === "camera") {
          for (const occupantId of occupantIds) {
            cameraPriorities[occupantId] = priority;
          }
        } else if (streamType === "screenshare") {
          for (const occupantId of occupantIds) {
            screensharePriorities[occupantId] = priority;
          }
        }
      }
      const combined: VideoPriority[] = [
        ...Object.entries(cameraPriorities).map(([occupantId, priority]) => ({
          occupantId,
          priority,
          streamType: "camera" as StreamType,
        })),
        ...Object.entries(screensharePriorities).map(([occupantId, priority]) => ({
          occupantId,
          priority,
          streamType: "screenshare" as StreamType,
        })),
      ];
      return combined;
    }),
};

export const ConversationActions = actions;
export const ConversationSelectors = selectors;
