import equal from "fast-deep-equal/es6/index.js";
import { ChatAddress, ChatTarget } from "../../../shared/Models/ChatAddress.js";
import { Occupant } from "../../../shared/Models/Occupant.js";
import { cleanOptionalEmail } from "../../../shared/helpers/validators.js";
import { logger } from "../../../shared/infra/logger.js";
import {
  NewChat,
  NewOpenChat,
  NewSingleChannelChat,
  NewSingleChat,
  OtherPersonDetails,
  OverworldHostPopup,
  Popup,
  PopupContext,
  PopupIds,
  SocialSuggestionPopup,
} from "../types/Popup.js";

// Determines whether a given anchor is a candidate to host a given popup. To change logic for
// whether a given popup can appear on a given anchor, change this function.
export const popupMatchesIds = (
  p: Popup,
  ids?: PopupIds & {
    // If true, matches any popup.
    anyPopup?: boolean;
  }
): boolean => {
  if (!ids) return false;

  const { ctx } = p;
  const {
    email: rawEmail,
    clientUuid,
    occupantId,
    chatId,
    pendingChatId,
    fixedPopupId,
    anyPopup,
  } = ids;

  if (anyPopup) {
    return true;
  }

  const email = cleanOptionalEmail(rawEmail);
  let address: ChatAddress | undefined;

  if (ctx.fixedPopupId && ctx.fixedPopupId === fixedPopupId) return true;
  if (p.popupId === fixedPopupId) return true;
  if (ctx.chatId && ctx.chatId === chatId) return true;
  if (ctx.pendingChatId && ctx.pendingChatId === pendingChatId) return true;

  if (ctx.popupType === "existingSingleChat") {
    address = ctx.otherAddress;
  } else if ((ctx.popupType === "groupChat" || ctx.popupType === "channel") && ctx.anchorOnSender) {
    address = ctx.anchorOnSender;
  }

  if (address) {
    return (
      (address.targetType === "email" && address.email === email) ||
      (address.targetType === "occupant" && address.occupantId === occupantId) ||
      (address.targetType === "client" && address.clientUuid === clientUuid)
    );
  }

  if (
    ctx.popupType === "newSingleChat" ||
    ctx.popupType === "thisIsYou" ||
    ctx.popupType === "socialSuggestion" ||
    ctx.popupType === "overworldHost"
  ) {
    const targetKey = ctx.targetKey;
    switch (targetKey.targetType) {
      case "email": {
        return targetKey.email === email;
      }
      case "client": {
        return targetKey.clientUuid === clientUuid;
      }
      case "occupant": {
        return targetKey.occupantId === occupantId;
      }
      default: {
        throw new Error("Unsupported!");
      }
    }
  }

  return false;
};

// If the popup context is the kind that has a single other person, extracts the details. Otherwise
// returns undefined.
export const otherPersonFromContext = (ctx?: PopupContext): OtherPersonDetails | undefined => {
  if (
    ctx?.popupType === "newSingleChat" ||
    ctx?.popupType === "existingSingleChat" ||
    ctx?.popupType === "socialSuggestion" ||
    ctx?.popupType === "overworldHost" ||
    ctx?.popupType === "thisIsYou"
  ) {
    const targetContext = ctx.targetContext;
    return {
      displayName: ctx.displayName,
      imageUrl: ctx.imageUrl,
      email: targetContext?.email,
      occupantId: targetContext?.occupantId,
      roamId: targetContext?.roamId,
      personId: targetContext?.personId,
      targetContext,
    };
  }
  return undefined;
};

export const otherPersonWithOccupant = (
  otherPerson?: OtherPersonDetails,
  occupant?: Occupant
): OtherPersonDetails | undefined => {
  const displayName = otherPerson?.displayName ?? occupant?.name;
  if (!displayName) return undefined;

  return {
    displayName,
    imageUrl: otherPerson?.imageUrl ?? occupant?.imageAbsoluteUrl,
    email: cleanOptionalEmail(otherPerson?.email ?? occupant?.email),
    occupantId: otherPerson?.occupantId ?? occupant?.id,
    roamId: otherPerson?.roamId ?? occupant?.roamId,
    personId: otherPerson?.personId ?? occupant?.personId,
    targetContext: otherPerson?.targetContext,
  };
};

export type PopupContextMatchType = "exact" | "chatOnly" | "chatAndThreadOnly" | "none";
export const popupContextsMatch = (
  ctx1: PopupContext,
  ctx2: PopupContext
): PopupContextMatchType => {
  if (ctx1.popupType === "thisIsYou") {
    return ctx2.popupType === "thisIsYou" ? "exact" : "none";
  }

  if (ctx1.fixedPopupId && ctx1.fixedPopupId === ctx2.fixedPopupId) {
    return "exact";
  }

  if (ctx1.pendingChatId && ctx1.pendingChatId === ctx2.pendingChatId) {
    return "exact";
  }

  if (!ctx1.pendingChatId && ctx1.chatId && ctx1.chatId === ctx2.chatId) {
    if (ctx1.popupType === "channelThread" || ctx2.popupType === "channelThread") {
      const thread1 = threadTimestampFromContext(ctx1);
      const thread2 = threadTimestampFromContext(ctx2);

      // channelThread popups only match if both the thread and chat match
      if (thread1 === thread2) {
        return ctx1.focusMessageTimestamp === ctx2.focusMessageTimestamp &&
          ctx1.focusMessageThreadTimestamp === ctx2.focusMessageThreadTimestamp
          ? "exact"
          : "chatAndThreadOnly";
      } else {
        return "none";
      }
    } else if (ctx1.popupType === "channel" || ctx2.popupType === "channel") {
      // Since they have the same chatId, if either one is a channel then both should be. The
      // question is whether they are both showing the main chat or the same thread.
      if (
        ctx1.popupType === "channel" &&
        ctx2.popupType === "channel" &&
        ctx1.newThread?.pendingMessageId === ctx2.newThread?.pendingMessageId &&
        ctx1.openThread?.threadTimestamp === ctx2.openThread?.threadTimestamp
      ) {
        return ctx1.focusMessageTimestamp === ctx2.focusMessageTimestamp &&
          ctx1.focusMessageThreadTimestamp === ctx2.focusMessageThreadTimestamp
          ? "exact"
          : "chatAndThreadOnly";
      } else {
        return "chatOnly";
      }
    } else {
      return ctx1.focusMessageTimestamp === ctx2.focusMessageTimestamp
        ? "exact"
        : "chatAndThreadOnly";
    }
  }

  const conf1 = ctx1.chatType === "confidential";
  const conf2 = ctx2.chatType === "confidential";
  if (
    conf1 === conf2 &&
    "targetKey" in ctx1 &&
    "targetKey" in ctx2 &&
    // Check defined
    ctx1.targetKey &&
    equal(ctx1.targetKey, ctx2.targetKey)
  ) {
    return "exact";
  }

  return "none";
};

const threadTimestampFromContext = (ctx: PopupContext): number | undefined => {
  if (ctx.popupType === "channel") {
    return ctx.openThread?.threadTimestamp;
  }
  if (ctx.popupType === "channelThread") {
    return ctx.threadTimestamp;
  }
  return undefined;
};

const targetFromNewSingleChatContext = (
  ctx: NewSingleChat | SocialSuggestionPopup | OverworldHostPopup
): ChatTarget => {
  const { targetKey, targetContext, imageUrl } = ctx;
  switch (targetKey.targetType) {
    case "email":
      return {
        targetType: "email",
        email: targetKey.email,
        displayName: ctx.displayName,
        displayPersonId: targetContext.personId,
        displayImageUrl: imageUrl,
      };
    case "client":
      return {
        targetType: "client",
        clientUuid: targetKey.clientUuid,
        displayName: ctx.displayName,
        displayImageUrl: imageUrl,
      };
    case "occupant":
      logger.warn("targetFromNewSingleChatContext: Occupant target used");
      return {
        targetType: "occupant",
        occupantId: targetKey.occupantId,
        roamId: targetKey.roamId,
        displayName: ctx.displayName,
        email: targetContext.email,
        displayImageUrl: imageUrl,
      };
    case "bot":
      return {
        targetType: "bot",
        integrationId: targetKey.integrationId,
        roamId: targetKey.roamId,
        displayName: ctx.displayName,
        botCode: targetKey.botCode,
        displayImageUrl: imageUrl,
      };
  }
};

export const targetsFromNewChatContext = (
  ctx:
    | NewSingleChat
    | NewSingleChannelChat
    | NewChat
    | NewOpenChat
    | SocialSuggestionPopup
    | OverworldHostPopup
): ChatTarget[] => {
  switch (ctx.popupType) {
    case "newSingleChat":
    case "socialSuggestion":
    case "overworldHost":
      return [targetFromNewSingleChatContext(ctx)];
    case "newSingleChannelChat":
      return [
        {
          targetType: "standardGroup",
          roamId: ctx.roamId,
          addressId: ctx.addressId,
          displayName: ctx.displayName,
        },
      ];

    case "newOpenChat":
    case "newChat":
      return ctx.selectedTargets;

    default:
      return [];
  }
};
