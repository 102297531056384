import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OAuthProviderId, OAuthStatus } from "../../../../shared/Models/OAuthStatus.js";
import { OAuthAttemptResult } from "../../../../shared/OAuthMessages/ServerOAuth.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface OAuthIds {
  providerId: OAuthProviderId;
  accountId: number;
  personId?: number;
  roamId?: number;
}

export interface StartOAuthFlowPayload extends OAuthIds {
  scenarioId: string;
  authedPersonId: number;
}

export interface OpenOAuthPopupPayload {
  attemptId: string;
  startUrl?: string;
}

export interface AttemptFinishedPayload {
  attemptId: string;
  result: OAuthAttemptResult;
}

const idsToKey = ({ providerId, accountId, personId, roamId }: OAuthIds) =>
  `${providerId}:${accountId}:${personId ?? "none"}:${roamId ?? "none"}`;

const statusAdapter = createEntityAdapter<OAuthStatus>({
  selectId: (status) => idsToKey(status),
});

const slice = createSlice({
  name: "oauth",
  initialState: {
    statuses: statusAdapter.getInitialState(),
    activeAttemptId: undefined as string | undefined,
  },
  reducers: {
    requestStatus: (state, action: PayloadAction<OAuthIds>) => {},
    startOAuthFlow: (state, action: PayloadAction<StartOAuthFlowPayload>) => {},
    unlinkToken: (state, action: PayloadAction<string>) => {},
    cancelOAuthFlow: (state, action: PayloadAction<string>) => {},

    openOAuthPopup: (state, action: PayloadAction<OpenOAuthPopupPayload>) => {
      state.activeAttemptId = action.payload.attemptId;
    },
    setStatus: (state, action: PayloadAction<OAuthStatus>) => {
      const status = action.payload;
      statusAdapter.setOne(state.statuses, status);
    },
    attemptFinished: (state, action: PayloadAction<AttemptFinishedPayload>) => {
      const { attemptId } = action.payload;
      if (state.activeAttemptId === attemptId) {
        state.activeAttemptId = undefined;
      }
    },
  },
});

export const { actions, reducer } = slice;

const adapterSelectors = statusAdapter.getSelectors();
const selectSlice = (state: RootState) => state.anyWorld.oauth;
export const selectors = {
  selectStatus:
    (ids: OAuthIds | undefined) =>
    (state: RootState): OAuthStatus | undefined => {
      if (!ids) {
        return undefined;
      }
      return adapterSelectors.selectById(state.anyWorld.oauth.statuses, idsToKey(ids));
    },
  selectActiveAttemptId: createSelector(selectSlice, (slice) => slice.activeAttemptId),
};
