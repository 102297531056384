import { z } from "zod";
import { stringId } from "../Models/zodTypes.js";
import { logger } from "../infra/logger.js";
import { CURRENT_VERSION } from "../version.js";

export const FlagValue = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.record(z.any()),
  z.array(z.any()),
]);
export type FlagValue = z.infer<typeof FlagValue>;

export const FlagState = z.object({
  key: stringId(),
  value: FlagValue,
});
export type FlagState = z.infer<typeof FlagState>;

export type Flag<T extends FlagValue, U = T> = {
  key: string;
  defaultValue: T;
};

export type SFUFlag<T extends FlagValue, U = T> = Flag<T, U> & {
  /** Specifies whether a client needs to reconnect to the SFU if this flag changes. */
  requireReconnect: boolean;
};

export type MinVersion = number;

export type MinVersionFlag = Flag<MinVersion | boolean, boolean> & {
  type: "minVersion";
};

export type MinVersionSFUFlag = SFUFlag<MinVersion | boolean, boolean> & {
  type: "minVersion";
};

export type ExtractFlagReturnType<F> = F extends MinVersionFlag
  ? boolean
  : F extends SFUFlag<infer T, infer U>
    ? U
    : F extends Flag<infer T, infer U>
      ? U
      : never;

export type FlagWithValue<T extends FlagValue, U = T> = {
  flag: Flag<T, U>;
  value: T | U;
};

export const UnknownFlag = z.object({
  key: z.string(),
  value: FlagValue,
});
export type UnknownFlag = z.infer<typeof UnknownFlag>;

type RolloutPercentage = number;

// Alphabetize this section *******************
export const BANUBA_VIRTBG: Flag<boolean> = {
  key: "banuba-virtbg",
  defaultValue: false,
};

export const CADENCE_ALLOW_EXTERNAL_GLOBAL: Flag<boolean> = {
  key: "CADENCE_ALLOW_EXTERNAL_GLOBAL",
  defaultValue: false,
};

export const CHAT_BLOBS: Flag<boolean> = {
  key: "chat-blobs",
  defaultValue: false,
};

export const CHAT_BOTS: Flag<boolean> = {
  key: "chat-bots",
  defaultValue: false,
};

export const CHAT_IMPROVED_TARGET_SEARCH: MinVersionFlag = {
  type: "minVersion",
  key: "chat-target-search-improvement",
  defaultValue: false,
};

export const CHAT_LEXICAL_DEBUG: Flag<boolean> = {
  key: "chat-lexical-debug",
  defaultValue: false,
};

export const CHAT_TYPING_INDICATOR_ON_MAP: MinVersionFlag = {
  type: "minVersion",
  key: "chat-typing-indicator-on-map-mv",
  defaultValue: false,
};

export const CHAT_ON_MAP_SECONDS_TO_LIVE: Flag<number> = {
  key: "chat-on-map-seconds-to-live",
  defaultValue: 30,
};

export const CHAT_PUSH_V2: Flag<boolean> = {
  key: "chat-push-v2",
  defaultValue: false,
};

export const CHAT_UNFURL_LINKS: MinVersionFlag = {
  type: "minVersion",
  key: "chat-unfurl-links",
  defaultValue: false,
};

export const CHAT_UNSUB_THREAD_START_AT_TOP: Flag<boolean> = {
  key: "chat-unsubscribed-thread-starts-at-top",
  defaultValue: false,
};

export const CLEAR_SRCOBJECT_BEFORE_SETSINKID: Flag<boolean> = {
  key: "clear-srcobject-before-setsinkid",
  defaultValue: false,
};

export const COMPLIANCE_CONTROLS: Flag<boolean> = {
  key: "compliance-controls-20231102",
  defaultValue: false,
};

export const COMPLIANCE_MESSAGE_RETENTION_CONTROL: Flag<boolean> = {
  key: "compliance-message-retention-control",
  defaultValue: false,
};

export const CONFIDENTIAL_IMAGES: Flag<boolean> = {
  key: "confidential-images",
  defaultValue: false,
};

export const CONNECTORS: Flag<boolean> = {
  key: "connectors",
  defaultValue: false,
};

export const DISABLE_DIRTY_RECTS: Flag<boolean> = {
  key: "disable-dirty-rects",
  defaultValue: false,
};

export const FOUNDERS_TOUR: Flag<boolean> = {
  key: "founders-tour",
  defaultValue: false,
};

export const GRACEFUL_MIDDLE_END_RECONNECT_ANDROID: Flag<boolean> = {
  key: "graceful-middle-end-reconnect-android",
  defaultValue: true,
};

export const GROUP_CHAT_NOTIFICATION_PREFS: Flag<boolean> = {
  key: "group-chat-notification-prefs",
  defaultValue: false,
};

export const GUEST_MEETING_CONVERSION: Flag<boolean> = {
  key: "guest-meeting-conversion",
  defaultValue: false,
};

export const INBOX_GUESTS_TAB: Flag<boolean> = {
  key: "inbox-guests-tab",
  defaultValue: false,
};

export const ITEM_SIZE_LIMIT_MB: Flag<number> = {
  key: "item-size-limit-mb",
  defaultValue: 200,
};

export const LIGHT_MODE: MinVersionFlag = {
  type: "minVersion",
  key: "light-mode",
  defaultValue: false,
};

export const LIMIT_CAPTURING_RATE: Flag<boolean> = {
  key: "limit-capturing-rate",
  defaultValue: false,
};

export const MEETING_CENTER: Flag<boolean> = {
  key: "meeting-center",
  defaultValue: false,
};

export const MULTI_SCREENSHARE: MinVersionFlag = {
  type: "minVersion",
  key: "multi-screenshare",
  defaultValue: false,
};

export const MULTIPLE_VIDEO_PIPELINES_ACTIVE: MinVersionFlag = {
  type: "minVersion",
  key: "multiple-video-pipelines-active",
  defaultValue: false,
};

export const MULTIPLE_VIDEO_PIPELINES_PRIORITIES: Flag<string> = {
  key: "multiple-video-pipelines-priorities",
  defaultValue: "mediapipe,mediapipeBasicNoWorker",
};

export const NEW_ACCENTS: Flag<boolean> = {
  key: "new-accents-2023",
  defaultValue: false,
};

export const NEW_MAP: MinVersionFlag = {
  type: "minVersion",
  key: "new-map-2023-mv",
  defaultValue: false,
};

export const NEW_OVERWORLD: Flag<boolean> = {
  key: "new-overworld",
  defaultValue: true,
};

export const OVERWORLD_INFO_BADGE: MinVersionFlag = {
  type: "minVersion",
  key: "overworld-info-badge",
  defaultValue: true,
};

export const PERF_SKIP_SELECTORS_ON_EQUAL_STORE: Flag<boolean> = {
  key: "perf-skip-selectors-on-equal-store",
  defaultValue: false,
};

export const PINNED_CHATS_IN_INBOX: Flag<boolean> = {
  key: "pinned-chats-in-inbox",
  defaultValue: true,
};

export const PROFILE_BADGE_CHAT: MinVersionFlag = {
  type: "minVersion",
  key: "profile-badge-chat",
  defaultValue: true,
};

export const PROFILE_BADGE_MODAL: MinVersionFlag = {
  type: "minVersion",
  key: "profile-badge-modal",
  defaultValue: true,
};

export const ROAM_AUTO_FIX_OCCUPANTS: Flag<boolean> = {
  key: "roam-auto-fix-occupants",
  defaultValue: false,
};

export const ROAM_AUTOBOARDING: Flag<boolean> = {
  key: "roam-autoboarding",
  defaultValue: false,
};

export const ROAM_AVSTATS_NEW2: MinVersionSFUFlag = {
  type: "minVersion",
  key: "roam-sfu-avstats-new2",
  defaultValue: false,
  requireReconnect: false,
};

export const ROAM_AVSTATS_LEFTOVER_BITRATE_TARGET_RATIO: Flag<number> = {
  key: "roam-avstats-leftover-bitrate-target-ratio",
  defaultValue: 0.2,
};

export const ROAM_AVSTATS_MAX_SAVED_STATS_MB: Flag<number> = {
  key: "roam-avstats-max-saved-stats-mb",
  defaultValue: 100,
};

export const ROAM_BIG_MEETING_ROOM: Flag<boolean> = {
  key: "roam-big-meeting-room",
  defaultValue: false,
};

export const ROAM_CHAT_ENFORCE_THREADED_MODE: Flag<boolean> = {
  key: "roam-chat-enforce-threaded-mode",
  defaultValue: true,
};

export const ROAM_CHAT_PUBLIC_GROUPS: Flag<boolean> = {
  key: "roam-chat-public-groups-4",
  defaultValue: false,
};

export const ROAM_CREATE_MEETING_GROUPS: Flag<boolean> = {
  key: "create-meeting-groups-2",
  defaultValue: false,
};

export const ROAM_CULTURE_SURVEY: Flag<boolean> = {
  key: "roam-culture-survey",
  defaultValue: false,
};

export const ROAM_FORCE_SFU_REGION: Flag<string> = {
  key: "roam-force-sfu-region",
  defaultValue: "",
};

export const ROAM_FORCE_TURN: Flag<boolean> = {
  key: "roam-force-turn",
  defaultValue: false,
};

export const ROAM_FULLSCREEN_DOCK_DISMISS_TIMEOUT_SECONDS: Flag<number> = {
  key: "roam-fullscreen-dock-dismiss-timeout-seconds",
  defaultValue: 3,
};

export const ROAM_GIPHY_API_KEY: Flag<string> = {
  key: "giphy-api-key",
  defaultValue: "",
};

export const ROAM_LOCAL_SFU_HOSTNAME: Flag<string> = {
  key: "roam-local-sfu-hostname",
  defaultValue: "localhost",
};

export const ROAM_LOCAL_SFU_REPLICA_PORT: Flag<number> = {
  key: "roam-local-sfu-replica-port",
  defaultValue: 9082,
};

export const ROAM_LOCAL_SFU_ROUTER_PORT: Flag<number> = {
  key: "roam-local-sfu-router-port",
  defaultValue: 9081,
};

export const ROAM_MAX_AUDIO_ROOM_SIZE: Flag<number> = {
  key: "roam-max-audio-room-size",
  defaultValue: 24,
};

export const ROAM_MAX_BIG_MEETING_SIZE: Flag<number> = {
  key: "roam-max-big-meeting-size",
  defaultValue: 50,
};

export const ROAM_MAX_VIDEO_ROOM_SIZE: Flag<number> = {
  key: "roam-max-video-room-size",
  defaultValue: 24,
};

export const ROAM_MEDIA_DEVICE_TOASTS: Flag<boolean> = {
  key: "roam-media-device-toasts",
  defaultValue: false,
};

export const ROAM_NEW_GROUP_FLOW: MinVersionFlag = {
  type: "minVersion",
  key: "roam-new-group-flow-2",
  defaultValue: false,
};

export const ROAM_NFTS: Flag<boolean> = {
  key: "roam-nfts",
  defaultValue: false,
};

export const ROAM_ONLY_SSL_TURN: Flag<boolean> = {
  key: "roam-only-ssl-turn",
  defaultValue: false,
};

export const ROAM_PROMPT_MEETING_FEEDBACK_DISMISS_TIMEOUT_SECONDS: Flag<number> = {
  key: "roam-prompt-meeting-feedback-dismiss-timeout-seconds",
  defaultValue: 180,
};

export const ROAM_PROMPT_MEETING_FEEDBACK_BROADCAST_DEBOUNCE_S: Flag<number> = {
  key: "roam-prompt-meeting-feedback-broadcast-debounce-s",
  defaultValue: 30,
};

export const ROAM_PROMPT_MEETING_FEEDBACK_MINIMUM_MEETING_DURATION_S: Flag<number> = {
  key: "roam-prompt-meeting-feedback-minimum-meeting-duration-s",
  defaultValue: 180,
};

export const ROAM_PROMPT_MEETING_FEEDBACK_MINIMUM_OCCUPANT_PARTICIPATION_TIME_S: Flag<number> = {
  key: "roam-prompt-meeting-feedback-minimum-occupant-participation-time-s",
  defaultValue: 60,
};

export const ROAM_PROMPT_MEETING_FEEDBACK_MIN_AUDITORIUM_PARTICIPATION_TIME_SECONDS: Flag<number> =
  {
    key: "roam-prompt-meeting-feedback-min-auditorium-participation-time-seconds",
    defaultValue: 60,
  };

export const ROAM_SEND_FOMO_EMAILS: Flag<boolean> = {
  key: "roam-send-fomo-emails",
  defaultValue: false,
};

export const ROAM_SEND_SFU_VAD: Flag<boolean> = {
  key: "roam-send-sfu-vad",
  defaultValue: false,
};

export const ROAM_SFU_PROTOCOL_VERSION: Flag<number> = {
  key: "roam-sfu-protocol-version",
  defaultValue: 1,
};

export const ROAM_SFU_FIX_STREAM_SELECTION: MinVersionSFUFlag = {
  type: "minVersion",
  key: "roam-sfu-fix-stream-selection-2",
  defaultValue: false,
  requireReconnect: false,
};

export const ROAM_SLACK_GHOST_GROUPS: MinVersionFlag = {
  type: "minVersion",
  key: "roam-slack-ghost-groups",
  defaultValue: false,
};

export const ROAM_STORIES: MinVersionFlag = {
  type: "minVersion",
  key: "roam-stories",
  defaultValue: false,
};

export const ROAM_SPLIT_AUDIO_VIDEO_TRACKS: SFUFlag<boolean> = {
  key: "roam-split-audio-video-tracks",
  defaultValue: false,
  requireReconnect: false,
};

export const ROAM_TEST_ALTERNATE_LLMS: Flag<boolean> = {
  key: "roam-test-alternate-llms",
  defaultValue: false,
};

export const ROAM_UPGRADE_VISITORS: Flag<boolean> = {
  key: "roam-upgrade-visitors",
  defaultValue: false,
};

export const ROAM_USE_CANARY_SFU: Flag<boolean> = {
  key: "roam-use-canary-sfu",
  defaultValue: false,
};

// NOTE: Only has any effect for local use.
export const ROAM_USE_LOCAL_SFU: Flag<boolean> = {
  key: "roam-use-local-sfu",
  defaultValue: false,
};

// NOTE: Only has any effect for local use.
export const ROAM_USE_LOCAL_SFU_CLIENTSTATS: Flag<boolean> = {
  key: "roam-use-local-sfu-clientstats",
  defaultValue: false,
};

export const ROAM_USE_MEETING_STATS: Flag<boolean> = {
  key: "roam-use-meeting-stats",
  defaultValue: false,
};

export const ROAM_USE_SFU_ICE_RESTART: SFUFlag<boolean> = {
  key: "roam-use-sfu-ice-restart",
  defaultValue: false,
  requireReconnect: false,
};

export const ROAM_VIDEO_FROZEN_THRESHOLD_MS_CAMERA: Flag<number> = {
  key: "roam-video-frozen-threshold-ms-camera",
  defaultValue: 700,
};

export const ROAM_VIDEO_FROZEN_THRESHOLD_MS_SCREENSHARE: Flag<number> = {
  key: "roam-video-frozen-threshold-ms-screenshare",
  defaultValue: 1500,
};

export const ROAM_VIDEO_NON_HLS_LIMIT: Flag<number> = {
  key: "roam-video-non-hls-limit",
  defaultValue: 25,
};

export const ROAMANIAC_BADGE: Flag<boolean> = {
  key: "roamaniac-badge",
  defaultValue: false,
};

export const SFU_ENABLE_SQUELCHING: SFUFlag<boolean> = {
  key: "sfu-enable-squelching",
  defaultValue: false,
  requireReconnect: false,
};

export const SFU_HD_CAMERAS: SFUFlag<RolloutPercentage> = {
  key: "sfu-hd-cameras",
  defaultValue: 0,
  requireReconnect: false,
};

export const SFU_RESOLUTION_CHOICE_THRESHOLD: SFUFlag<number> = {
  key: "sfu-resolution-choice-threshold",
  defaultValue: 0.4,
  requireReconnect: false,
};

export const SFU_RTP_ACK_HEADER: SFUFlag<boolean> = {
  key: "sfu-rtp-ack-header",
  defaultValue: false,
  requireReconnect: false,
};

// NOTE: This is a catch-all for when we want to add a feature flag without a client update.
export const SFU_SERVER_FLAGS: Flag<Record<string, any>> = {
  key: "sfu-server-flags",
  defaultValue: {},
};

export const SFU_SQUELCH_NON_VOICE: SFUFlag<boolean> = {
  key: "sfu-squelch-non-voice",
  defaultValue: false,
  requireReconnect: false,
};

export const SFU_TRANSCRIPTION_SOURCE: SFUFlag<string> = {
  key: "sfu-transcription-source",
  defaultValue: "vosk",
  requireReconnect: false,
};

export const SHARE_SOUND: MinVersionFlag = {
  type: "minVersion",
  key: "share-sound",
  defaultValue: false,
};

export const SOFT_CHECK_IN: Flag<boolean> = {
  key: "soft-check-in",
  defaultValue: false,
};

export const TRANSCRIPTION_SUMMARY: Flag<boolean> = {
  key: "transcription-summary",
  defaultValue: false,
};

export const USE_INTERNAL_ROOMS: Flag<boolean> = {
  key: "use-internal-rooms",
  defaultValue: false,
};

export const VIDEO_HLS_BANDWIDTH: Flag<number> = {
  key: "video-hls-bandwidth",
  defaultValue: 0, // Use the videojs default
};

export const VISITOR_OVERWORLD: Flag<boolean> = {
  key: "visitor-overworld",
  defaultValue: false,
};

export const WINDOWS_ALWAYS_CONTENT_PROTECTION: Flag<boolean> = {
  key: "windows-always-content-protection",
  defaultValue: true,
};

// !!! KEEP FEATURE FLAGS ALPHABETIZED !!!
// You likely don't want to put your new feature flag here at the end
// *******************

export const SFU_ROAM_FLAGS: AnySFUFlag[] = [
  SFU_ENABLE_SQUELCHING,
  SFU_HD_CAMERAS,
  SFU_RESOLUTION_CHOICE_THRESHOLD,
  SFU_RTP_ACK_HEADER,
  SFU_SQUELCH_NON_VOICE,
  SFU_TRANSCRIPTION_SOURCE,

  // NOTE: Not actually used by the SFU, but sent to get in meetingstats/warehouse
  ROAM_AVSTATS_NEW2,
  ROAM_SFU_FIX_STREAM_SELECTION,
  ROAM_SPLIT_AUDIO_VIDEO_TRACKS,
  ROAM_USE_SFU_ICE_RESTART,
];

export const ALL_ROAM_FLAGS: AnyFlag[] = [
  BANUBA_VIRTBG,
  CADENCE_ALLOW_EXTERNAL_GLOBAL,
  CHAT_BLOBS,
  CHAT_BOTS,
  CHAT_IMPROVED_TARGET_SEARCH,
  CHAT_LEXICAL_DEBUG,
  CHAT_TYPING_INDICATOR_ON_MAP,
  CHAT_ON_MAP_SECONDS_TO_LIVE,
  CHAT_PUSH_V2,
  CHAT_UNFURL_LINKS,
  CHAT_UNSUB_THREAD_START_AT_TOP,
  CLEAR_SRCOBJECT_BEFORE_SETSINKID,
  COMPLIANCE_CONTROLS,
  COMPLIANCE_MESSAGE_RETENTION_CONTROL,
  CONFIDENTIAL_IMAGES,
  CONNECTORS,
  DISABLE_DIRTY_RECTS,
  FOUNDERS_TOUR,
  GRACEFUL_MIDDLE_END_RECONNECT_ANDROID,
  GROUP_CHAT_NOTIFICATION_PREFS,
  GUEST_MEETING_CONVERSION,
  FOUNDERS_TOUR,
  INBOX_GUESTS_TAB,
  ITEM_SIZE_LIMIT_MB,
  LIGHT_MODE,
  LIMIT_CAPTURING_RATE,
  MEETING_CENTER,
  MULTI_SCREENSHARE,
  MULTIPLE_VIDEO_PIPELINES_ACTIVE,
  MULTIPLE_VIDEO_PIPELINES_PRIORITIES,
  NEW_ACCENTS,
  NEW_MAP,
  NEW_OVERWORLD,
  OVERWORLD_INFO_BADGE,
  PERF_SKIP_SELECTORS_ON_EQUAL_STORE,
  PINNED_CHATS_IN_INBOX,
  PROFILE_BADGE_CHAT,
  PROFILE_BADGE_MODAL,
  ROAM_AUTOBOARDING,
  ROAM_AVSTATS_NEW2,
  ROAM_AVSTATS_LEFTOVER_BITRATE_TARGET_RATIO,
  ROAM_AVSTATS_MAX_SAVED_STATS_MB,
  ROAM_BIG_MEETING_ROOM,
  ROAM_CHAT_ENFORCE_THREADED_MODE,
  ROAM_CHAT_PUBLIC_GROUPS,
  ROAM_CREATE_MEETING_GROUPS,
  ROAM_CULTURE_SURVEY,
  ROAM_FORCE_SFU_REGION,
  ROAM_FORCE_TURN,
  ROAM_FULLSCREEN_DOCK_DISMISS_TIMEOUT_SECONDS,
  ROAM_GIPHY_API_KEY,
  ROAM_LOCAL_SFU_HOSTNAME,
  ROAM_LOCAL_SFU_REPLICA_PORT,
  ROAM_LOCAL_SFU_ROUTER_PORT,
  ROAM_MAX_AUDIO_ROOM_SIZE,
  ROAM_MAX_BIG_MEETING_SIZE,
  ROAM_MAX_VIDEO_ROOM_SIZE,
  ROAM_MEDIA_DEVICE_TOASTS,
  ROAM_NEW_GROUP_FLOW,
  ROAM_NFTS,
  ROAM_ONLY_SSL_TURN,
  ROAM_PROMPT_MEETING_FEEDBACK_BROADCAST_DEBOUNCE_S,
  ROAM_PROMPT_MEETING_FEEDBACK_DISMISS_TIMEOUT_SECONDS,
  ROAM_PROMPT_MEETING_FEEDBACK_MINIMUM_MEETING_DURATION_S,
  ROAM_PROMPT_MEETING_FEEDBACK_MINIMUM_OCCUPANT_PARTICIPATION_TIME_S,
  ROAM_PROMPT_MEETING_FEEDBACK_MIN_AUDITORIUM_PARTICIPATION_TIME_SECONDS,
  ROAM_SEND_FOMO_EMAILS,
  ROAM_SEND_SFU_VAD,
  ROAM_SFU_PROTOCOL_VERSION,
  ROAM_SLACK_GHOST_GROUPS,
  ROAM_STORIES,
  ROAM_TEST_ALTERNATE_LLMS,
  ROAM_UPGRADE_VISITORS,
  ROAM_USE_CANARY_SFU,
  ROAM_USE_LOCAL_SFU,
  ROAM_USE_LOCAL_SFU_CLIENTSTATS,
  ROAM_USE_MEETING_STATS,
  ROAM_VIDEO_FROZEN_THRESHOLD_MS_CAMERA,
  ROAM_VIDEO_FROZEN_THRESHOLD_MS_SCREENSHARE,
  ROAM_VIDEO_NON_HLS_LIMIT,
  ROAMANIAC_BADGE,
  ...SFU_ROAM_FLAGS,
  SFU_SERVER_FLAGS,
  SFU_TRANSCRIPTION_SOURCE,
  SHARE_SOUND,
  SOFT_CHECK_IN,
  TRANSCRIPTION_SUMMARY,
  USE_INTERNAL_ROOMS,
  VIDEO_HLS_BANDWIDTH,
  VISITOR_OVERWORLD,
  WINDOWS_ALWAYS_CONTENT_PROTECTION,
];

export const MEETING_EXPERIMENT_FLAGS: typeof ALL_ROAM_FLAGS = [
  // Add flags here to have them evaluated by section server when creating a new conversation.
  // Evaluated flags will be sent to clients when they join the conversation.
  // This list is only used by section server. It's fine if old clients aren't in sync;
  // if an unknown flag with sfu- prefix is sent from section server, it'll still get
  // forwarded to the SFU with the meeting override.
  ROAM_AVSTATS_NEW2,
  ROAM_SFU_FIX_STREAM_SELECTION,
  ROAM_SPLIT_AUDIO_VIDEO_TRACKS,
  ROAM_USE_SFU_ICE_RESTART,
];

const flagKeyToFlag = new Map(ALL_ROAM_FLAGS.map((f) => [f.key, f]));

type AnySFUFlag =
  | SFUFlag<string | boolean | number | Record<string, any> | any[]>
  | MinVersionSFUFlag;
type AnyFlag =
  | Flag<string>
  | Flag<number>
  | Flag<boolean>
  | Flag<Record<string, any>>
  | Flag<any[]>
  | MinVersionFlag
  | AnySFUFlag;
type AnyFlagWithValue =
  | FlagWithValue<string>
  | FlagWithValue<number>
  | FlagWithValue<boolean>
  | FlagWithValue<any[]>
  | FlagWithValue<Record<string, any>>
  | FlagWithValue<number | boolean, boolean>; // minimum version flags

export const isBooleanFlag = (input: AnyFlag): input is Flag<boolean> => {
  return typeof input.defaultValue === "boolean";
};

export const isNumberFlag = (input: AnyFlag): input is Flag<number> => {
  return typeof input.defaultValue === "number";
};

export const isStringFlag = (input: AnyFlag): input is Flag<string> => {
  return typeof input.defaultValue === "string";
};

export const isJsonFlag = (input: AnyFlag): input is Flag<Record<string, any>> | Flag<any[]> => {
  return typeof input.defaultValue === "object";
};

export const isSFUFlag = <T extends FlagValue>(flag: Flag<T>): flag is SFUFlag<T> => {
  return (flag as SFUFlag<T>).requireReconnect !== undefined;
};

export const isMinimumVersionFlag = (
  input: AnyFlag
): input is MinVersionFlag | MinVersionSFUFlag => {
  return (
    (typeof input.defaultValue === "number" || typeof input.defaultValue === "boolean") &&
    (input as MinVersionFlag).type === "minVersion"
  );
};

export const parseFlagsFromStrings = (input: Record<string, string>): AnyFlagWithValue[] => {
  const output: AnyFlagWithValue[] = [];
  for (const [key, value] of Object.entries(input)) {
    const flag = flagKeyToFlag.get(key);
    if (flag !== undefined) {
      let parsedFlag: AnyFlagWithValue | undefined;
      if (isMinimumVersionFlag(flag)) {
        let parsedValue: number | boolean | undefined;
        const parsedNum = Number(value);
        if (Number.isFinite(parsedNum)) {
          parsedValue = parsedNum;
        } else {
          parsedValue = parseBoolean(value);
        }
        if (parsedValue !== undefined) {
          parsedFlag = { flag, value: parsedValue };
        }
      } else if (isStringFlag(flag)) {
        parsedFlag = { flag, value };
      } else if (isBooleanFlag(flag)) {
        const parsedBool = parseBoolean(value);
        if (parsedBool !== undefined) {
          parsedFlag = { flag, value: parsedBool };
        }
      } else if (isNumberFlag(flag)) {
        const parsedNum = Number(value);
        if (Number.isFinite(parsedNum)) {
          parsedFlag = { flag, value: parsedNum };
        }
      } else if (isJsonFlag(flag)) {
        try {
          parsedFlag = { flag, value: JSON.parse(value) };
        } catch (err: any) {
          logger.error({ err }, `parsing object-type flag ${value}`);
        }
      }
      if (parsedFlag !== undefined) {
        output.push(parsedFlag);
      }
    }
  }
  return output;
};

export const evaluateFlag = <T extends FlagValue, U extends FlagValue>(
  flag: Flag<T, U>,
  value: FlagValue | undefined,
  defaultValue: U | undefined = undefined
): U | undefined => {
  if (value === undefined) {
    return defaultValue;
  }
  if (isMinimumVersionFlag(flag as AnyFlag)) {
    if (typeof value === "boolean") {
      return value as U;
    }
    let coalescedValue: FlagValue | undefined = value;
    // If we got here, `value` is not a boolean, so it should be a number. If it is not then use the default value instead.
    if (typeof coalescedValue !== "number") {
      coalescedValue = defaultValue;
    }
    // If the new value we have is not a number (e.g. a boolean) simply return the default value
    if (typeof coalescedValue !== "number") {
      return defaultValue;
    }
    if (coalescedValue === 0) {
      // Special case 0 to mean always off.
      return false as unknown as U;
    }
    return (CURRENT_VERSION >= coalescedValue) as U;
  }
  if (typeof value !== typeof flag.defaultValue) {
    return defaultValue;
  }
  if (
    (Array.isArray(value) && !Array.isArray(flag.defaultValue)) ||
    (Array.isArray(flag.defaultValue) && !Array.isArray(value))
  ) {
    // Because "typeof" returns "object" for arrays, we specifically check here if
    // one side is an array and the other isn't.
    return defaultValue;
  }
  return value as unknown as U;
};

export const isSettableFrom = <F extends Flag<any, any>>(input: F, value: unknown): boolean => {
  if (!isValidFlagValue(value)) {
    // If we don't have a valid value at all, the flag should not be consider
    // to be settable from the value.
    // This is important because we type cast "value" after checking isSettableFrom().
    return false;
  }
  if (isMinimumVersionFlag(input)) {
    return typeof value === "number" || typeof value === "boolean";
  }
  if (
    (Array.isArray(value) && !Array.isArray(input.defaultValue)) ||
    (Array.isArray(input.defaultValue) && !Array.isArray(value))
  ) {
    // Because "typeof" returns "object" for arrays, we specifically check here if
    // one side is an array and the other isn't.
    return false;
  }
  return typeof value === typeof input.defaultValue;
};

export const isKnownFlag = (key: string): boolean => {
  return flagKeyToFlag.has(key);
};

export const isValidFlagValue = (maybeFlagValue: unknown): maybeFlagValue is FlagValue => {
  return FlagValue.safeParse(maybeFlagValue).success;
};

const parseBoolean = (input: string): boolean | undefined => {
  switch (input.toLowerCase()) {
    case "t":
    case "true":
    case "1":
      return true;
    case "f":
    case "false":
    case "0":
      return false;
  }
  return undefined;
};

export const isUnknownFlag = (val: unknown): val is UnknownFlag => {
  if (typeof val !== "object") return false;
  const maybeUnknownFlag = val as UnknownFlag;
  if (typeof maybeUnknownFlag.key !== "string") return false;
  if (!isValidFlagValue(maybeUnknownFlag.value)) return false;
  return true;
};
