import equal from "fast-deep-equal/es6";
import { VideoPipelineName, VideoPipelineStats } from "../../../shared/Models/VideoPipeline.js";
import { dumpMediaStream } from "../../../shared/helpers/dumpMediaStream.js";
import { AsyncLock } from "../../../shared/helpers/lock.js";
import { logger } from "../../../shared/infra/logger.js";
import { urlToImageBitmap } from "../../helpers/imagesWorker.js";
import { ClientContainerInfo } from "../../injection/IClientContainer.js";
import { PostProcSyncObjectCache } from "../PostProcObjectCache.js";
import { BackgroundBlurCounts } from "../avStreamShared.js";
import { VideoFrameWatcher } from "./VideoFrameWatcher.js";
import { IVideoPipelineContainer } from "./interfaces/IVideoPipelineContainer.js";
import { IVideoPipelineExecutor } from "./interfaces/IVideoPipelineExecutor.js";
import { PostProcVideoSpec } from "./interfaces/IVideoPipelineLauncher.js";
import {
  PipelineError,
  PipelineSetupError,
  toPipelineError,
} from "./interfaces/VideoPipelineError.js";
import {
  PipelineStatsRawDelta,
  accumulatePipelineStats,
  zeroVideoPipelineStats,
} from "./pipelineStats.js";

/*
 * MainThreadPipelineExecutor runs a video pipeline in the main JavaScript thread. It is passed a
 * pipelineContainerCache that can supply IVideoPipelineContainers with the pipeline that the caller
 * (normally a VideoPipelineLauncher) wishes to have executed.
 *
 * When update() is called, MainThreadPipelineExecutor arranges for the supplied input stream to
 * be sent through the pipeline with either Insertable Streams or the legacy canvas capture approach
 * and the result sent to an outputStream.
 *
 * This is a lightweight wrapper object. If video is muted or postprocessing is not needed it
 * should be destroyed by calling close() and a new one created when video is unmuted or
 * postprocessing is again needed.
 *
 */
export class MainThreadPipelineExecutor implements IVideoPipelineExecutor {
  private spec: PostProcVideoSpec | undefined;
  private inputStream: MediaStream | undefined;
  private closed = false;

  private backgroundImageSrc?: string;
  private backgroundImage?: ImageBitmap;
  private pipelineContainer: IVideoPipelineContainer;

  private usingInsertableStreams: boolean;
  private abortController: AbortController | undefined;
  private insertableStreamsRenderLock = new AsyncLock();
  private videoFrameWatcher: VideoFrameWatcher | undefined;

  private outputStream?: MediaStream;

  private stats: VideoPipelineStats;
  private statsTimeout?: NodeJS.Timeout;
  private pipelineStatsFeedback?: (delta: PipelineStatsRawDelta) => void;

  constructor(
    private containerInfo: ClientContainerInfo,
    private streamId: string,
    private pipelineName: VideoPipelineName,
    private pipelineContainerCache: PostProcSyncObjectCache<IVideoPipelineContainer>,
    private onPipelineError: (err: Error | undefined) => void
  ) {
    this.pipelineContainer = this.pipelineContainerCache.get();
    this.stats = zeroVideoPipelineStats(pipelineName, streamId);

    if (
      typeof MediaStreamTrackProcessor !== "undefined" &&
      typeof MediaStreamTrackGenerator !== "undefined" &&
      typeof TransformStream !== "undefined" &&
      typeof VideoFrame !== "undefined"
    ) {
      this.usingInsertableStreams = true;
    } else {
      logger.info("Browser does not support insertable streams");
      this.usingInsertableStreams = false;

      // If we didn't get a context (e.g. because WebGL isn't supported), don't get a MediaStream
      if (this.pipelineContainer.context) {
        const onScreenCanvas = this.pipelineContainer.onScreenCanvas();
        // If the pipeline is using an offscreen canvas, we don't support using captureStream().
        if (onScreenCanvas) {
          // the 0 is request frame rate; we'll capture frames manually
          this.outputStream = onScreenCanvas.captureStream(0);
        } else {
          throw new PipelineSetupError("attempt to use captureStream method with offscreen canvas");
        }
      }

      this.videoFrameWatcher = new VideoFrameWatcher(this.containerInfo, async () => {
        if (!this.videoFrameWatcher) return;
        await this.renderPipeline(this.videoFrameWatcher.video, () => {
          const outputTrack = this.outputStream?.getVideoTracks()?.[0] as
            | CanvasCaptureMediaStreamTrack
            | undefined;
          if (outputTrack) {
            outputTrack.requestFrame();
          } else {
            throw new PipelineError("Unable to find output stream track");
          }
        });
      });
    }
  }

  private ensureCollectingStats() {
    this.pipelineStatsFeedback = this.pipelineContainer?.pipeline?.statsFeedback;
    if (!this.statsTimeout) {
      this.statsTimeout = setInterval(() => {
        const delta = accumulatePipelineStats(this.stats);
        this.pipelineStatsFeedback?.(delta);
      }, 1000);
    }
  }

  private lastPipelineErrorName: string | undefined;
  private setPipelineError(err: PipelineError | undefined) {
    // Don't send the same error multiple times in a row because it creates noise and doesn't impact
    // upstream error handling
    if (err?.name !== this.lastPipelineErrorName) {
      logger.error({ err }, "video pipeline error");
      this.onPipelineError(err);
    }
    this.lastPipelineErrorName = err?.name;
  }

  private async renderPipeline(src: TexImageSource, requestFrame: () => void) {
    if (this.pipelineContainer.pipeline && this.spec && !this.closed) {
      performance.mark("VideoPipelineRender-start");
      try {
        await this.pipelineContainer.render(src);

        requestFrame();

        // Rendered successfully, so clear any error
        this.setPipelineError(undefined);
      } catch (err: any) {
        this.setPipelineError(toPipelineError(err));
      }

      performance.mark("VideoPipelineRender-end");
      performance.measure(
        "VideoPipelineRender",
        "VideoPipelineRender-start",
        "VideoPipelineRender-end"
      );
    }
  }

  public async update(spec: PostProcVideoSpec, rawStream: MediaStream): Promise<MediaStream> {
    if (this.closed) {
      throw new Error("attempt to update a closed MediaThreadPipelineExecutor");
    }

    if (
      !this.usingInsertableStreams &&
      this.pipelineContainer.context &&
      this.outputStream?.getTracks()[0]?.readyState !== "live"
    ) {
      // If some kind of post-processing was requested, and we're not using insertable streams, and
      // we successfully got a context for the canvas, and the captured stream from the canvas has
      // ended, get a new stream
      const onScreenCanvas = this.pipelineContainer.onScreenCanvas();
      // If the pipeline is using an offscreen canvas, we don't support using captureStream();
      if (onScreenCanvas) {
        this.outputStream = onScreenCanvas.captureStream();
      } else {
        throw new Error("attempt to use captureStream method with offscreen canvas");
      }
    }

    if (equal(spec, this.spec) && rawStream === this.inputStream && this.outputStream) {
      // No modifications to make; just return the output stream
      return this.outputStream;
    }

    if (this.videoFrameWatcher) {
      // Stop processing frames
      await this.videoFrameWatcher?.stop();
    }

    // If we're using insertable streams, then if we'll be replacing the IS pipeline this causes
    // us to tear down the old one, change settings on the WebGL pipeline, and start the new
    // one without any frames coming out from the old stream. If not replacing the pipeline
    // or not using insertable streams, we still get the lock but it's irrelevant.
    await this.insertableStreamsRenderLock.guard(async () => {
      const replaceInsertableStreamsPipeline = rawStream !== this.inputStream;
      if (this.abortController && replaceInsertableStreamsPipeline) {
        // Tear down the entire insertable streams pipeline (see below)
        this.abortController?.abort();
        this.abortController = undefined;
      }

      const oldSpec = this.spec;
      this.spec = spec;
      this.inputStream = rawStream;

      // Update the pipeline as needed
      const segmentationHeight = rawStream?.getVideoTracks()?.[0]?.getSettings().height || 480;
      const segmentationWidth = rawStream?.getVideoTracks()?.[0]?.getSettings().width || 640;

      const pipeline = await this.pipelineContainer.waitForPipeline({
        width: segmentationWidth,
        height: segmentationHeight,
      });

      this.ensureCollectingStats();

      pipeline.setForegroundOverlays(spec.foregroundOverlays || []);
      pipeline.updateTouchUpStrength(spec.touchupLevel ?? 0);
      pipeline.setDebugStage(spec.debugStage);

      if (spec?.virtbg?.mode === "blurred") {
        pipeline.setBackgroundType("blurred");
        // Update the blur level
        const blurCount =
          BackgroundBlurCounts[
            Math.min(Math.max(spec.virtbg.blurLevel, 0), BackgroundBlurCounts.length - 1)
          ];
        pipeline.updateBlurCount(blurCount ?? 1);
      } else if (spec?.virtbg?.mode === "static_image") {
        pipeline.setBackgroundType("static_image");
        if (this.backgroundImageSrc !== spec.virtbg.imageSrc) {
          try {
            this.backgroundImage = await urlToImageBitmap(spec.virtbg.imageSrc);
            this.backgroundImageSrc = spec.virtbg.imageSrc;
            pipeline.setStaticBackground(this.backgroundImage);
          } catch (err: any) {
            logger.error({ err }, "unable to set background image");
          }
        }
      } else {
        pipeline.setBackgroundType("input");
      }

      if (this.usingInsertableStreams && replaceInsertableStreamsPipeline) {
        const inputTrack = rawStream.getVideoTracks()[0];
        if (
          inputTrack &&
          typeof MediaStreamTrackGenerator !== "undefined" &&
          typeof MediaStreamTrackProcessor !== "undefined" &&
          typeof TransformStream !== "undefined"
        ) {
          // NOTE: I couldn't find a way to preserve some of these components rather than recreating
          // them. Cancelling part of the pipeline but leaving the rest running should be possible,
          // but I couldn't get it to work reliably.
          this.abortController = new AbortController();
          const generator = new MediaStreamTrackGenerator({ kind: "video" });

          // NOTE: MediaStreamTrackGenerator.getSettings() does not implement width/height.
          // Add it here, so that down the pipe we can always access the size.
          const origGetSettings = generator.getSettings.bind(generator);
          generator.getSettings = (): MediaTrackSettings => {
            const settings = origGetSettings();
            if ((settings.width ?? 0) === 0 || (settings.height ?? 0) === 0) {
              settings.width = segmentationWidth;
              settings.height = segmentationHeight;
            }
            return settings;
          };

          const transformer = new TransformStream({
            transform: async (
              inputFrame: VideoFrame,
              controller: TransformStreamDefaultController
            ) => {
              await this.insertableStreamsRenderLock.guard(async () => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                await this.renderPipeline(inputFrame as any, () => {
                  if (typeof VideoFrame === "undefined") return;
                  controller.enqueue(
                    new VideoFrame(this.pipelineContainer.canvas, {
                      timestamp: inputFrame.timestamp,
                    })
                  );
                });
                inputFrame.close();
              });
            },
          });
          const processor = new MediaStreamTrackProcessor({ track: inputTrack });
          processor.readable
            .pipeThrough(transformer, { signal: this.abortController?.signal })
            .pipeTo(generator.writable, { signal: this.abortController?.signal })
            .catch((err) => {
              if (err.name !== "AbortError") {
                logger.error({ err }, `Error piping frames`);
              }
            });
          this.outputStream = new MediaStream([generator]);
        }
      } else {
        await this.videoFrameWatcher?.start(rawStream);
      }
    });

    if (!this.outputStream) {
      throw new Error("outputStream not set at end of update");
    }
    return this.outputStream;
  }

  public async close() {
    if (this.closed) return;
    this.closed = true;
    clearTimeout(this.statsTimeout);
    // Stop processing frames
    await this.videoFrameWatcher?.stop();
    if (this.abortController) {
      // Tear down the entire insertable streams pipeline
      await this.insertableStreamsRenderLock.guard(async () => {
        this.abortController?.abort();
        this.abortController = undefined;
      });
    }
    // Destroy the captured stream
    this.outputStream?.getTracks().forEach((t) => t.stop());
    this.pipelineContainerCache.put(this.pipelineContainer);
  }

  public getStats(flush = false) {
    if (flush) {
      accumulatePipelineStats(this.stats);
    }
    return this.stats;
  }

  public dump(): any {
    return {
      inputStream: this.inputStream ? dumpMediaStream(this.inputStream) : undefined,
      outputStream: this.outputStream ? dumpMediaStream(this.outputStream) : undefined,
      pipeline: this.pipelineContainer.dump(),
      spec: this.spec,
      usingInsertableStreams: this.usingInsertableStreams,
    };
  }
}
