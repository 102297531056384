import { compileShader, createProgram, glsl } from "../webglHelper.js";

const vertexShaderSource = glsl`#version 300 es
    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;

    void main() {
      gl_Position = vec4(a_position * vec2(1.0, -1.0), 0, 1);
      v_texCoord = a_texCoord;
    }
  `;
const fragmentShaderSrc = glsl`#version 300 es
    precision highp float;

    in vec2 v_texCoord;
    uniform sampler2D uSourceSampler;
    out vec4 outColor;

    void main() {
      outColor = vec4(texture(uSourceSampler, v_texCoord).rgb, 1.0);
    }
  `;

export interface RenderTextureStage {
  render(texUnit: number): void;
}

export const buildRenderTextureStage = (
  gl: WebGL2RenderingContext,
  positionBuffer: WebGLBuffer,
  texCoordBuffer: WebGLBuffer
): RenderTextureStage => {
  const { width: outputWidth, height: outputHeight } = gl.canvas;

  const vertexShader = compileShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = compileShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSrc);
  const program = createProgram(gl, vertexShader, fragmentShader);
  gl.useProgram(program);

  const uSourceLocation = gl.getUniformLocation(program, "uSourceSampler");
  const texCoordAttributeLocation = gl.getAttribLocation(program, "a_texCoord");
  const positionAttributeLocation = gl.getAttribLocation(program, "a_position");

  const vao = gl.createVertexArray();
  gl.bindVertexArray(vao);

  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.vertexAttribPointer(positionAttributeLocation, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(positionAttributeLocation);

  gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
  gl.vertexAttribPointer(texCoordAttributeLocation, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(texCoordAttributeLocation);

  gl.bindVertexArray(null);

  const render = (texUnit: number) => {
    gl.useProgram(program);

    gl.uniform1i(uSourceLocation, texUnit);
    gl.viewport(0, 0, outputWidth, outputHeight);

    gl.bindVertexArray(vao);
    gl.uniform1i(uSourceLocation, texUnit);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.clearColor(1.0, 1.0, 1.0, 1.0);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  };

  return { render };
};
