import React, { useEffect, useState } from "react";
import { formatDuration } from "../../client/avStream/video/pipelineStats.js";
import Spinner from "../../client/components/Spinner.js";
import { useContainer } from "../../client/hooks/useContainer.js";
import { styled } from "../../client/styles/theme";
import IconCheckmarkBoldExtraSmall from "../../icons/IconCheckmarkBoldExtraSmall.svg";
import IconDismissBoldExtraSmall from "../../icons/IconDismissBoldExtraSmall.svg";
import { BenchmarkResult } from "../../shared/Models/VideoPipeline.js";

export const BenchmarkResults: React.FC = () => {
  const container = useContainer();
  const [results, setResults] = useState<BenchmarkResult[]>([]);
  const [autoSelectInProgress, setAutoSelectInProgress] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const latest = container.pipelineBenchmarkService().getLastResults();
      setResults([...latest.values()]);
      setAutoSelectInProgress(container.pipelineBenchmarkService().getAutoSelectInProgress());
    }, 1000);
    return () => clearInterval(timer);
  }, [container]);

  return (
    <Container>
      <Title>Benchmarks</Title>
      {results.map((r) => (
        <Result key={r.pipelineName} result={r} />
      ))}
      {autoSelectInProgress ? (
        <Spinner />
      ) : (
        <ClickableText onClick={() => container.pipelineBenchmarkService().performAutoSelect(true)}>
          Re-Run
        </ClickableText>
      )}
    </Container>
  );
};

interface ResultProps {
  result: BenchmarkResult;
}

const Result: React.FC<ResultProps> = ({ result }: ResultProps) => {
  return (
    <PipelineResult>
      <PipelineName>{result.pipelineName}</PipelineName>
      {result.status === "success" ? (
        <>
          {result.supported ? (
            <PipelineResult>
              Supported
              <IconCheckmarkBoldExtraSmall />
            </PipelineResult>
          ) : (
            <PipelineResult>
              Not Supported
              <IconDismissBoldExtraSmall />
            </PipelineResult>
          )}
          {<Metric>Time Taken: {formatDuration(result.timeTakenMs)}</Metric>}
          {result.loadTimeMs && <Metric>Load Time: {formatDuration(result.loadTimeMs)}</Metric>}
        </>
      ) : (
        <PipelineResult>
          Not Supported (Failed)
          <IconDismissBoldExtraSmall />
        </PipelineResult>
      )}
    </PipelineResult>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.overlayPrimary};
  color: ${({ theme }) => theme.color.text.primary};
  padding: 1em;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
`;
Container.displayName = "Container";

const Title = styled.div`
  font-size: 1em;
  align-self: center;
`;
Title.displayName = "Title";

const PipelineResult = styled.div`
  margin-top: 1rem;
`;
PipelineResult.displayName = "PipelineResult";

const PipelineName = styled.div``;
PipelineName.displayName = "PipelineName";

const Metric = styled.div``;
Metric.displayName = "Metric";

const ClickableText = styled.div`
  ${({ theme }) => theme.font.caption2};
  margin-left: 0.1rem;
  margin-top: 1rem;
  cursor: pointer;
`;
ClickableText.displayName = "ClickableText";
