import { z } from "zod";
import { MeetingDestination } from "./types/MeetingDestination.js";
import { PersonDestination } from "./types/PersonDestination.js";
import { RoomDestination } from "./types/RoomDestination.js";

/**
 * Represents a place that a user is trying to get to.
 *
 * This does not have to be a "physical" place in a roam (e.g. a room).
 * It can includes places such as "wherever meeting X is being held" or "wherever person
 * X is."
 */
export const Destination = z.union([MeetingDestination, PersonDestination, RoomDestination]);
export type Destination = z.infer<typeof Destination>;
