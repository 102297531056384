import { z } from "zod";
import { Destination } from "../AccessLinks/destinations/Destination.js";
import { AccessLinkLocation } from "../AccessLinks/location/AccessLinkLocation.js";
import { EnterRoamRequestStatus } from "./EnterRoamRequestStatus.js";
import { LobbyGuest } from "./LobbyGuest.js";

/**
 * Internal representation of someone attempting to enter
 */
export const EnterRoamRequest = z.object({
  /** UUID */
  id: z.string(),
  /**
   * The ID of the lobby session corresponding to this request.
   */
  lobbySessionId: z.string(),
  /**
   * ID of the roam that the requester is trying to enter.
   */
  roamId: z.number(),
  status: EnterRoamRequestStatus,
  /**
   * The person requesting to enter.
   *
   * In the future this would likely become a union type.
   */
  requester: LobbyGuest,
  /**
   * The destination that the requester is ultimately trying to reach.
   */
  destination: Destination,
  /**
   * The current location that the requester is trying to enter.
   *
   * This is whatever location was most recently resolved for this request.
   * If undefined, there is no eligible location for the requester to enter.
   * If present, guaranteed to correspond to this request's roam ID.
   *
   * This can change over time (e.g. if the requesters is trying to visit a specific person
   * and they are bouncing around).
   * The RequestEnterRoamService is in charge of keeping this up to date.
   */
  location: AccessLinkLocation.optional(),
  /**
   * Expiration date, expressed as unix timestamp seconds.
   *
   * Requests that are not handled by this time will be automatically canceled.
   *
   * This field is currently not intended to be used outside of the lobby/enter roam service.
   * If you need to determine request status, see `status` instead.
   *
   * NOTE:
   *  This is currently just a mechanism to make sure we don't end up with dangling requests
   *  and is not a super hard cutoff.
   *  The request may not be automatically cancelled exactly at this time.
   *  Additionally, we're a little permissive—the expire date is not explicitly checked
   */
  expires: z.number(),
});
export type EnterRoamRequest = z.infer<typeof EnterRoamRequest>;

/**
 * External representation sent to clients
 */
export const ClientEnterRoamRequest = EnterRoamRequest.pick({
  id: true,
  destination: true,
  location: true,
  requester: true,
});

export type ClientEnterRoamRequest = z.infer<typeof ClientEnterRoamRequest>;
