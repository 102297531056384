import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RaisedHand } from "../../../../shared/Models/RaisedHand.js";
import {
  crpc_raise_LowerHand,
  crpc_raise_RaiseHand,
} from "../../../../shared/SectionMessages/ClientRpcRaiseHand.js";
import { RootState } from "../../../store/reducers.js";

// Section isn't included in the key because the client doesn't care about this.
const raisedHandKey = (raisedHand: Pick<RaisedHand, "roomId" | "occupantId">) =>
  `${raisedHand.roomId}-${raisedHand.occupantId}`;
const raisedHandsAdapter = createEntityAdapter<RaisedHand>({
  selectId: raisedHandKey,
});
const raisedHandsSelectors = raisedHandsAdapter.getSelectors();

export const raiseHandInitialState = () => {
  return {
    raisedHands: raisedHandsAdapter.getInitialState(),
  };
};

const slice = createSlice({
  name: "raiseHand",
  initialState: raiseHandInitialState(),
  reducers: {
    // saga
    raiseHand: (state, action: PayloadAction<Omit<crpc_raise_RaiseHand, "kind">>) => {},
    lowerHand: (state, action: PayloadAction<Omit<crpc_raise_LowerHand, "kind">>) => {},

    // redux
    /**
     * Sets raised hands in a room, clearing any existing raised hands.
     */
    setRoomRaisedHands: (
      state,
      action: PayloadAction<{ roomId: number; raisedHands: RaisedHand[] }>
    ) => {
      const { roomId, raisedHands } = action.payload;

      // Clear old hands
      const allRaisedHands = raisedHandsSelectors.selectAll(state.raisedHands);
      const toClear = allRaisedHands.filter((rh) => rh.roomId === roomId);
      raisedHandsAdapter.removeMany(state.raisedHands, toClear.map(raisedHandKey));

      // Set new hands
      raisedHandsAdapter.setMany(state.raisedHands, raisedHands);
    },
  },
});

export const { actions, reducer } = slice;
export const RaiseHandActions = actions;
export const RaiseHandReducer = reducer;

export const selectors = {
  selectIsHandRaised:
    (roomId: number, occupantId: string) =>
    (state: RootState): boolean => {
      const raisedHand = raisedHandsSelectors.selectById(
        state.section.raiseHand.raisedHands,
        raisedHandKey({ roomId, occupantId })
      );
      return raisedHand !== undefined;
    },
};
export const RaiseHandSelectors = selectors;
