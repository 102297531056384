import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import equal from "fast-deep-equal/es6/index.js";
import { sortBy } from "lodash";
import { defaultMemoize } from "reselect";
import { ExternalSubscriptionDetail } from "../../../../shared/Models/billing/subscriptions/external/ExternalSubscriptionDetail.js";
import { createSelector } from "../../../helpers/redux.js";
import { RootState } from "../../../store/reducers.js";

export interface ReactivateSubscriptionPayload {
  accountId: number;
  subscriptionId: number;
}

export interface SoftCancelSubscriptionPayload {
  accountId: number;
  subscriptionId: number;
}

const subscriptionsAdapter = createEntityAdapter<ExternalSubscriptionDetail>();

const slice = createSlice({
  name: "subscriptions",
  initialState: {
    subscriptions: subscriptionsAdapter.getInitialState(),
  },
  reducers: {
    // Saga
    fetchSubscription: (
      state,
      action: PayloadAction<{ accountId: number; subscriptionId: number }>
    ) => {},
    fetchSubscriptionsByAccountId: (state, action: PayloadAction<number>) => {},
    reactivateSubscription: (state, action: PayloadAction<ReactivateSubscriptionPayload>) => {},
    softCancelSubscription: (state, action: PayloadAction<SoftCancelSubscriptionPayload>) => {},

    // Redux
    setSubscriptions: (state, action: PayloadAction<ExternalSubscriptionDetail[]>) => {
      subscriptionsAdapter.setMany(state.subscriptions, action.payload);
    },
    removeSubscription: (state, action: PayloadAction<number>) => {
      subscriptionsAdapter.removeOne(state.subscriptions, action.payload);
    },
  },
});

export const { actions, reducer } = slice;
export const SubscriptionsActions = actions;
export const subscriptionsReducer = reducer;

const selectSlice = (state: RootState) => state.billing.subscriptions;
const subscriptionsSelectors = subscriptionsAdapter.getSelectors();
export const selectors = {
  selectSubscriptionsByAccountId: defaultMemoize((accountId) =>
    createSelector(
      selectSlice,
      (slice): ExternalSubscriptionDetail[] => {
        const subscriptions = subscriptionsSelectors
          .selectAll(slice.subscriptions)
          .filter((subscription) => subscription.accountId === accountId);
        return subscriptions;
      },
      {
        memoizeOptions: {
          resultEqualityCheck: (a, b) => {
            return equal(
              sortBy(a, (s) => s.id),
              sortBy(b, (s) => s.id)
            );
          },
        },
      }
    )
  ),
};
export const SubscriptionsSelectors = selectors;
